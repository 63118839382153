// This function performs a deep merge of two objects ('first' and 'second').
// It will recursively merge nested objects. If the objects have the same property keys,
// the values from the 'second' object will overwrite those in the 'first'.
// If either 'first' or 'second' are not objects, 'second' is returned as is.

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mergeDeep = (first: any, second: any) => {
  if (typeof first !== 'object' || typeof second !== 'object') {
    return second;
  }
  const output = { ...first };
  for (const key in second) {
    if (Object.prototype.hasOwnProperty.call(second, key)) {
      if (second[key] instanceof Object && key in first) {
        output[key] = mergeDeep(first[key], second[key]);
      } else {
        output[key] = second[key];
      }
    }
  }
  return output;
};
