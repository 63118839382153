import { SVGProps } from 'react';
const SvgLogout = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M6 14.25c0 .416-.334.75-.75.75H3a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h2.25a.749.749 0 1 1 0 1.5H3c-.825 0-1.5.675-1.5 1.5v8c0 .825.675 1.5 1.5 1.5h2.25c.416 0 .75.334.75.75Zm9.797-6.762-3.972-4.25a.75.75 0 1 0-1.092 1.027l2.78 2.985H5.721C5.334 7.25 5 7.588 5 8c0 .413.336.75.722.75h7.762l-2.81 2.986a.749.749 0 0 0 .033 1.06.876.876 0 0 0 .515.204c.2 0 .398-.079.546-.236l3.972-4.25a.7.7 0 0 0 .057-1.026Z"
      fill="#D04A02"
    />
  </svg>
);
export default SvgLogout;
