import { FC } from 'react';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import * as userListActions from '@core/store/user-list-slice';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { SearchParamKey } from '@app/shared/models/search-param-enum';
import { usePaginationHelper } from '@app/core/hooks/customUsePaginationHelper';

interface DeleteUserConfirmModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  email: string;
}

export const DeleteUserConfirmModal: FC<DeleteUserConfirmModalProps> = ({
  isOpen,
  email,
  setIsOpen,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const userList = useAppSelector((state) => state.userList.values);
  const { setSearchParams } = usePaginationHelper();

  const handleUserDelete = async () => {
    await dispatch(userListActions.removeUser({ email }))
      .unwrap()
      .then(() => {
        dispatch(userListActions.fetchUserList());
        if (userList.length === 1) {
          setSearchParams({ key: SearchParamKey.CurrentPage, value: '0' });
        }
        setIsOpen(false);
      });
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      onCloseButtonClick={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      onConfirm={handleUserDelete}
      title={`${email} will be removed from the platform`}
      isOpen={isOpen}
      buttonConfirmLabel="Remove User"
    />
  );
};
