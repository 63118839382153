import { FC } from 'react';
import classNames from 'classnames';
import { Else } from '@app/shared/models/contracts/enums/shared-enums';
import { ConditionalStatementDto } from '@app/shared/models/contracts/project-dto';

interface AllSortedTranchesItem {
  trancheId: string;
  instrumentId: string;
  instrumentNarrative: string;
  ordinaryEquityHurdleValue: number | undefined;
  order: number;
  statements: ConditionalStatementDto[];
  else: keyof typeof Else;
}

interface ParticipationTableRowProps {
  allSortedTranches: AllSortedTranchesItem[];
  renderLabelCellValue: () => JSX.Element;
  renderPreHurdleCellValue: () => JSX.Element;
  renderCellValue: ({ item }: { item: AllSortedTranchesItem }) => JSX.Element;
  labelCellClassnames?: string;
  cellClassnames?: string;
  rowClassnames?: string;
}

export const ParticipationTableRow: FC<ParticipationTableRowProps> = ({
  allSortedTranches,
  renderLabelCellValue,
  renderPreHurdleCellValue,
  renderCellValue,
  labelCellClassnames,

  cellClassnames,
  rowClassnames,
}) => (
  <tr className={rowClassnames}>
    <th
      className={classNames(
        'table-primary__cell--vertical-separator',
        cellClassnames,
        labelCellClassnames
      )}>
      {renderLabelCellValue()}
    </th>
    <td
      className={classNames(
        'table-primary__cell--vertical-separator',
        cellClassnames
        // preHurdleCellClassnames
      )}>
      {renderPreHurdleCellValue()}
    </td>
    {allSortedTranches.map((item, itemIndex) => (
      <td
        key={itemIndex}
        className={classNames('table-primary__cell--vertical-separator', cellClassnames)}>
        {renderCellValue({ item })}
      </td>
    ))}
  </tr>
);
