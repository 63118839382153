import { FC } from 'react';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { requiredValidator } from '@core/validations/hook-forms/validators';
import { numberPattern } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { UseDto } from '@app/shared/models/contracts/project-dto';
import Button from '@app/shared/components/button/Button';
import MinusRoundedSvg from '@app/shared/icons/MinusRounded';
import PlusSvg from '@app/shared/icons/Plus';
import classNames from 'classnames';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';

interface UsesFormSectionProps {
  defaultFieldValue: UseDto;
  onRowDelete: () => void;
}
export const UsesFormSection: FC<UsesFormSectionProps> = ({
  defaultFieldValue,
  onRowDelete,
}): JSX.Element => {
  const { control } = useFormContext();
  const {
    fields: usesFields,
    append: appendUsesField,
    remove: removeUsesField,
  } = useFieldArray({
    name: 'uses',
    control,
  });

  const removeUseRow = (index: number) => (): void => {
    removeUsesField(index);
    onRowDelete();
  };

  return (
    <table className="table-primary table-primary--fluid table-primary--zebra">
      <colgroup>
        <col />
        <col />
        <col />
        <col className="table-primary__col-action" />
      </colgroup>

      <thead>
        <tr>
          <th colSpan={4} className="table-primary__cell--header">
            Uses
          </th>
        </tr>
        <tr>
          <th className="table-primary__cell--header-secondary">Type</th>
          <th className="table-primary__cell--header-secondary">Narrative</th>
          <th className="table-primary__cell--header-secondary table-primary__cell--right">
            Amount
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        {usesFields.map((field, index) => (
          <tr key={field.id}>
            <td className={index === 0 ? 'table-primary__cell--section-start-separator' : ''}>
              <GridTextField
                name={`uses.${index}.type`}
                isInputMasked={false}
                rules={{
                  ...requiredValidator,
                }}
                placeholder="Add type"
              />
            </td>
            <td className={index === 0 ? 'table-primary__cell--section-start-separator' : ''}>
              <GridTextField
                name={`uses.${index}.narrative`}
                isInputMasked={false}
                rules={{
                  ...requiredValidator,
                }}
                placeholder="Add narrative"
              />
            </td>
            <td className={index === 0 ? 'table-primary__cell--section-start-separator' : ''}>
              <GridTextField
                name={`uses.${index}.amount`}
                fieldValueMask={numberPattern}
                placeholder="0"
                alignment={Alignment.Right}
              />
            </td>
            <td
              className={classNames('table-primary__cell--actions', {
                ['table-primary__cell--section-start-separator']: index === 0,
              })}>
              <Button
                appearance={ButtonAppearance.CLEAN}
                size={ButtonSize.TABLE_ACTION}
                title="Delete item"
                onClick={removeUseRow(index)}>
                <MinusRoundedSvg />
              </Button>
            </td>
          </tr>
        ))}
        <tr className="table-primary__row--plain">
          <td colSpan={4} className="table-primary__cell--actions">
            <Button
              appearance={ButtonAppearance.DEFAULT_TERTIARY}
              size={ButtonSize.TABLE_ACTION}
              startIcon={<PlusSvg />}
              onClick={() => appendUsesField(defaultFieldValue)}>
              Add Use
            </Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
