import Tile, { TileType } from '@app/shared/components/tile/Tile';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { FC } from 'react';
import { useDrag } from 'react-dnd';
import styles from '../build-structure-tab/instrument-tile.module.scss';
import classNames from 'classnames';

interface InstrumentTileProps {
  instrumentType: InstrumentType;
  isDisabled: boolean;
  isErfModal?: boolean;
}

const InstrumentTypeToTileType = {
  [InstrumentType.ShareholderLoanNotes]: TileType.ShareholderLoans,
  [InstrumentType.PreferredShares]: TileType.PreferenceShares,
  [InstrumentType.OrdinaryEquity]: TileType.OrdinaryEquity,
};
export const PARENT_CLASSNAME = 'instrument-tile';
const InstrumentTileErf: FC<InstrumentTileProps> = ({
  instrumentType,
  isDisabled,
  isErfModal,
}): JSX.Element => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: instrumentType,
      collect: (monitor) => ({
        isDragging: Boolean(monitor.isDragging()),
      }),
      canDrag: (_) => !isDisabled,
    }),
    [isDisabled]
  );

  return (
    <>
      <div ref={drag} data-testid="instrument-tile" className={styles['wrapper']}>
        <div
          className={classNames(styles[`${PARENT_CLASSNAME}`], {
            [styles[`${PARENT_CLASSNAME}--hidden`]]: isDragging,
          })}>
          <Tile
            type={InstrumentTypeToTileType[instrumentType]}
            text={instrumentType}
            isDragEffectActive={isDragging}
            isDisabled={isDisabled}
            isErfModal={isErfModal}
          />
        </div>
      </div>
    </>
  );
};

export default InstrumentTileErf;
