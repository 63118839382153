import { FC, Fragment } from 'react';
import styles from '@app/modules/projects/inputs/capital-structure/tabs/payout-logic/payout-logic.module.scss';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { OPERATOR_OPTIONS } from '@app/shared/constants/dropdown-values/payout/operator-options';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import FieldRadioGroup from '@app/shared/components/form-controls/field-radio-group/FieldRadioGroup';
import { CONJUNCTION_TYPE_OPTIONS } from '@app/shared/constants/dropdown-values/payout/conjuction-type-options';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { enumKeyByValue, enumToOptions } from '@app/shared/helpers';
import { ConjunctionType, TargetMetric } from '@app/shared/models/contracts/enums/shared-enums';
import { DEFAULT_TRANCHE_CONDITION_TEST_VALUE } from '@app/shared/helpers/create-tranche';
import { ConditionDto, EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { MAX_CONDITIONS_PER_STATEMENT } from '@app/shared/constants/payout-logic';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import PlusSvg from '@app/shared/icons/legacy/PlusSvg';
import Button from '@app/shared/components/button/Button';

import {
  greaterThanOrEqualIfValidator,
  positiveValueIfValidator,
  requiredValidator,
} from '@core/validations/hook-forms/validators';
import {
  multipleFieldFormattingPropsSecondary,
  numericBaseFieldFormattingProps,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import MinusIcon from '@app/shared/icons/MinusIcon';
import { useLocale } from '@app/core/hooks/useLocale';

interface PayoutConditionsProps {
  trancheIndex: number;
  statementIndex: number;
  formSubmitHandler: () => void;
}

const PARENT_CLASSNAME = 'payout-logic';
const PayoutConditions: FC<PayoutConditionsProps> = ({
  trancheIndex,
  statementIndex,
  formSubmitHandler,
}): JSX.Element => {
  const { l } = useLocale();
  const { control, setValue, watch, getValues } = useFormContext<EquityInstrumentDto>();
  const watchTranches = watch('tranches');
  const watchCondition = watch(`tranches.0.statements.0.conjunction`);

  const {
    append: appendCondition,
    remove: removeCondition,
    fields: conditionFields,
  } = useFieldArray({
    name: `tranches.${trancheIndex}.statements.${statementIndex}.conditions`,
    keyName: 'conditionKey',
    control: control,
  });

  const TARGET_METRIC_OPTIONS = enumToOptions(TargetMetric);

  const changeAllTargetMetricValues = (currentConditionIndex: number) => {
    getValues().tranches?.map((trancheField, trancheIndex) => {
      trancheField.statements.map((statementField, statementIndex) => {
        setValue(
          `tranches.${trancheIndex}.statements.${statementIndex}.conditions.${currentConditionIndex}.targetMetric`,
          getValues(`tranches.0.statements.0.conditions.${currentConditionIndex}.targetMetric`)
        );
      });
    });
  };

  const handleTargetMetricChange = async (
    trancheIndex: number,
    statementIndex: number,
    conditionIndex: number
  ) => {
    setValue(
      `tranches.${trancheIndex}.statements.${statementIndex}.conditions.${conditionIndex}.targetMetricTestValue`,
      DEFAULT_TRANCHE_CONDITION_TEST_VALUE
    );

    changeAllTargetMetricValues(conditionIndex);
  };

  const addStatementCondition = async (
    conditionItem: ConditionDto,
    conjunctionFieldName: `tranches.${number}.statements.${number}.conjunction`
  ) => {
    setValue(conjunctionFieldName, enumKeyByValue(ConjunctionType, ConjunctionType.And));
    appendCondition(conditionItem);
    getValues().tranches?.map((trancheField, trancheIndex) => {
      trancheField.statements.map((statementField, statementIndex) => {
        statementField.conditions.map(() => {
          const conditionField = getValues(
            `tranches.${trancheIndex}.statements.${statementIndex}.conditions.0`
          );
          setValue(
            `tranches.${trancheIndex}.statements.${statementIndex}.conjunction`,
            enumKeyByValue(ConjunctionType, ConjunctionType.And)
          );
          setValue(`tranches.${trancheIndex}.statements.${statementIndex}.conditions`, [
            conditionField,
            {
              ...conditionItem,
            },
          ]);
        });
      });
    });
    await formSubmitHandler();
  };

  const getTestValueFieldProps = (
    trancheIndex: number,
    statementIndex: number,
    conditionIndex: number
  ) => {
    const targetMetric =
      watchTranches?.[trancheIndex]?.statements[statementIndex]?.conditions[conditionIndex]
        ?.targetMetric;

    const validationRules = {
      rules: {
        ...requiredValidator,
        ...positiveValueIfValidator(
          true,
          targetMetric,
          (item) => item === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM)
        ),
        ...greaterThanOrEqualIfValidator(
          -100,
          targetMetric,
          (item) => item === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
        ),
      },
    };

    switch (targetMetric) {
      case enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR):
        return {
          ...validationRules,
          ...percentageFieldFormattingProps,
        };
      case enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM):
        return {
          ...validationRules,
          ...multipleFieldFormattingPropsSecondary,
        };
      default:
        return { ...validationRules };
    }
  };

  const removeConditionItem = async (
    conditionItem: number,
    conjunctionFieldName: `tranches.${number}.statements.${number}.conjunction`
  ) => {
    setValue(conjunctionFieldName, undefined);
    removeCondition(conditionItem);
    getValues().tranches?.map((trancheField, trancheIndex) => {
      trancheField.statements.map((statementField, statementIndex) => {
        setValue(`tranches.${trancheIndex}.statements.${statementIndex}.conjunction`, undefined);
        const conditionItem =
          getValues().tranches?.[trancheIndex].statements?.[statementIndex].conditions?.[0];
        if (conditionItem) {
          setValue(`tranches.${trancheIndex}.statements.${statementIndex}.conditions`, [
            conditionItem,
          ]);
        }
      });
    });

    await formSubmitHandler();
  };

  const selectConjunction = async () => {
    getValues().tranches?.map((trancheField, trancheIndex) => {
      trancheField.statements.map((statementField, statementIndex) => {
        setValue(
          `tranches.${trancheIndex}.statements.${statementIndex}.conjunction`,
          getValues(`tranches.0.statements.0.conjunction`)
        );
      });
    });
    await formSubmitHandler();
  };

  return (
    <>
      {conditionFields.map((conditionItem, conditionIndex) => {
        return (
          <Fragment key={statementIndex + 'statement-condition-item' + conditionIndex}>
            <div className={styles[`${PARENT_CLASSNAME}__condition-item`]}>
              <div className={styles[`${PARENT_CLASSNAME}__condition-control-1`]}>
                <FormSelect
                  ariaLabel={l('_SelectorAriaLabel', { label: l('_TargetMetric') })}
                  name={`tranches.${trancheIndex}.statements.${statementIndex}.conditions.${conditionIndex}.targetMetric`}
                  required
                  disabled={statementIndex > 0 || trancheIndex > 0}
                  options={TARGET_METRIC_OPTIONS}
                  onChange={() =>
                    handleTargetMetricChange(trancheIndex, statementIndex, conditionIndex)
                  }
                  isGapless
                />
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__condition-control-2`]}>
                <FormSelect
                  ariaLabel={l('_SelectorAriaLabel', { label: l('_Operator') })}
                  name={`tranches.${trancheIndex}.statements.${statementIndex}.conditions.${conditionIndex}.operator`}
                  required
                  onChange={formSubmitHandler}
                  onBlur={(event) => event.stopPropagation()}
                  options={OPERATOR_OPTIONS}
                  isGapless
                />
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__condition-control-3`]}>
                <FormField
                  {...numericBaseFieldFormattingProps}
                  placeholder="0"
                  name={`tranches.${trancheIndex}.statements.${statementIndex}.conditions.${conditionIndex}.targetMetricTestValue`}
                  required
                  {...getTestValueFieldProps(trancheIndex, statementIndex, conditionIndex)}
                  isGapless
                />
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__radio-group`]}>
                {conditionFields.length > 1 && conditionIndex === 0 && (
                  <FieldRadioGroup
                    key={conditionItem.conditionKey + watchCondition}
                    options={CONJUNCTION_TYPE_OPTIONS}
                    disabled={statementIndex > 0 || trancheIndex > 0}
                    name={`tranches[${trancheIndex}].statements[${statementIndex}].conjunction`}
                    onChange={selectConjunction}
                  />
                )}
                {trancheIndex === 0 && statementIndex === 0 && (
                  <>
                    {conditionFields.length > 1 && conditionIndex > 0 && (
                      <div className={styles[`${PARENT_CLASSNAME}__action-circle`]}>
                        <Button
                          appearance={ButtonAppearance.CLEAN}
                          aria-label={l('_RemoveCondition')}
                          startIcon={<MinusIcon />}
                          onClick={() =>
                            removeConditionItem(
                              conditionIndex,
                              `tranches.${trancheIndex}.statements.${statementIndex}.conjunction`
                            )
                          }
                        />
                      </div>
                    )}
                    {conditionFields?.length < MAX_CONDITIONS_PER_STATEMENT &&
                      trancheIndex === 0 &&
                      statementIndex === 0 &&
                      conditionIndex === 0 && (
                        <div className={styles[`${PARENT_CLASSNAME}__action-circle`]}>
                          <Button
                            appearance={ButtonAppearance.DEFAULT_TERTIARY}
                            size={ButtonSize.TABLE_ACTION}
                            data-testid="add-statement"
                            startIcon={<PlusSvg />}
                            onClick={() =>
                              addStatementCondition(
                                conditionItem,
                                `tranches.${trancheIndex}.statements.${statementIndex}.conjunction`
                              )
                            }
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};
export default PayoutConditions;
