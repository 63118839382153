import { useLocale } from '@app/core/hooks/useLocale';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';

export const CapitalStructureNavigation = () => {
  const { isDevFeatureEnabled } = useDevFeatures();
  const isPwerm2Enabled = isDevFeatureEnabled(DevFeature.PWERM2);
  const isERFEnabled = isDevFeatureEnabled(DevFeature.ERF);
  const { l } = useLocale();

  const navigationItems: NavigationItem[] = [
    {
      label: l('_BuildStructure'),
      route: '../build-structure',
    },
    {
      label: l('_PayoutLogic'),
      route: '../payout-logic',
    },
    {
      label: l('_CapTable'),
      route: '../cap-table',
    },
    {
      label: l('_ParticipationTable'),
      route: '../participation-table',
    },
    {
      label: l('_SourcesAndUses'),
      route: '../sources-and-uses',
    },
  ];

  if (isPwerm2Enabled) {
    navigationItems.splice(3, 0, {
      label: `${l('_CapTable')} v2`,
      route: '../cap-table-2',
    });
    navigationItems.splice(5, 0, {
      label: `${l('_ParticipationTable')} v2`,
      route: '../participation-table-2',
    });
  }

  if (isERFEnabled) {
    navigationItems.splice(1, 0, {
      label: `${l('_BuildStructure')} ERF`,
      route: '../build-structure-erf',
    });
    navigationItems.splice(3, 0, {
      label: `${l('_PayoutLogic')} ERF`,
      route: '../payout-logic-erf',
    });
    navigationItems.splice(6, 0, {
      label: `${l('_CapTable')} ERF`,
      route: '../cap-table-erf',
    });
    navigationItems.splice(9, 0, {
      label: `${l('_ParticipationTable')} ERF`,
      route: '../participation-table-erf',
    });
  }

  return <SecondaryNavigation navigationItems={navigationItems} />;
};
