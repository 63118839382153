import { FC } from 'react';
import TableSectionValuesPerForecast from '../../../components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getCalculatedValueForForecast,
  getCalculatedVariableValue,
  getHistoricalEV,
} from '@app/core/store/project-slice-selectors';
import TableSectionValuesPerMultiple from '../../../components/TableSectionValuesPerMultiple';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  getCalculatedProjectValueInPercent,
  selectCalculatedVariablesResults,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import { enumKeyByValue, useGetCalculatedProjectValue } from '@app/shared/helpers';
import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import {
  getPwermForecastValueDriver,
  getPwermValuationValueDriver,
} from '@app/shared/models/helpers/project-helpers';

export const WaterfallEnterpriseValuePartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const [isEnterpriseValueEVOpen, setIsEnterpriseValueEVOpen] = useToggler();
  const valuationValueDriver = getPwermValuationValueDriver(project);

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Enterprise Value [EV]"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.EnterpriseValueEV)}
          isOpen={isEnterpriseValueEVOpen}
          setIsOpen={setIsEnterpriseValueEVOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isEnterpriseValueEVOpen,
        })}>
        <TableSectionValuesPerForecast
          renderSpacer={false}
          title={valuationValueDriver.narrative}
          renderCell={({ forecast }) => (
            <CellValue
              {...amountRules}
              value={getPwermForecastValueDriver(project, forecast).value}
            />
          )}
          renderAdditionalCell={() => (
            <CellValue
              {...amountRules}
              value={valuationValueDriver.value}
              theme={CellValueTheme.Tertiary}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={`${valuationValueDriver.narrative} YoY growth`}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getCalculatedProjectValue('ExitValueDriverGrowth', {
                  caseId: caseItem.caseId,
                  forecastId: forecast.forecastId,
                })
              )}
            />
          )}
        />
        <TableSectionValuesPerMultiple
          title="Exit EV"
          renderCell={({ caseItem, forecast, multiple }) => (
            <CellValue
              {...amountRules}
              value={getCalculatedVariableValue(
                project,
                calculatedVariables,
                caseItem.caseId,
                forecast.forecastId,
                multiple,
                'Exit EV'
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          renderSpacer={false}
          title="Weighted EV"
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          highlight
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getCalculatedValueForForecast(
                project,
                calculatedVariables,
                caseItem.caseId,
                forecast.forecastId,
                'Weighted EV'
              )}
              strong
            />
          )}
          renderAdditionalCell={() => (
            <CellValue
              {...amountRules}
              value={getHistoricalEV(project)}
              strong
              theme={CellValueTheme.Tertiary}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title="Weighted EV YoY growth"
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getCalculatedProjectValue('WeightedEvGrowth', {
                  caseId: caseItem.caseId,
                  forecastId: forecast.forecastId,
                })
              )}
            />
          )}
        />
      </tbody>
    </>
  );
};

export default WaterfallEnterpriseValuePartialTable;
