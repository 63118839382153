import React, { useEffect, useMemo } from 'react';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectValuedInstruments } from '@app/core/store/project-slice-selectors';
import {
  cloneDeep,
  instrumentByTypeOrTranchesOrDefaultSortFn,
  opmInstrumentByTypeOrDefaultSortFn,
} from '@app/shared/helpers';
import styles from './pwerm-opm-conclusions.module.scss';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  EquityInstrumentDto,
  OverallConclusionsDto,
} from '@app/shared/models/contracts/project-dto';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { FormProvider, useForm } from 'react-hook-form';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import * as projectActions from '@core/store/project-slice';
import { positiveValueValidator } from '@app/core/validations/hook-forms/validators';
import { numericBaseFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { useLocale } from '@app/core/hooks/useLocale';
import { generatePath, Navigate } from 'react-router-dom';
import { RouteConstants } from '../../RouteConstants';

const PARENT_CLASSNAME = 'pwerm-opm-conclusions';

interface FormModel {
  overallConclusions: OverallConclusionsDto[];
}

const PwermOpmConclusions: React.FC = () => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const { details: projectDetails } = project;

  const valuedInstruments = useAppSelector(selectValuedInstruments);
  const sortedValuedInstruments = valuedInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn);
  const shouldShowPerShareValues = useAppSelector(
    (state) => state.uiValues.userSelections.overallConclusions.showPerShareFigures
  );

  const permValuedInstruments = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.valuationInstruments
  );
  const opmValuedInstruments = useAppSelector(
    (state) => state.opmCalculation.riskFreeValues.valuationConclusion || []
  );
  const opmSortedValuedInstruments = useMemo(
    () => [...opmValuedInstruments].sort(opmInstrumentByTypeOrDefaultSortFn),
    [opmValuedInstruments]
  );

  const columnsCount = shouldShowPerShareValues ? 9 : 5;

  const columnNames = [
    { key: 'Aggregate', label: l('_Aggregate') },
    { key: 'PerShare', label: l('_PerShare') },
    { key: 'Aggregate', label: l('_Aggregate') },
    { key: 'PerShare', label: l('_PerShare') },
    { key: 'Aggregate', label: l('_Aggregate') },
    { key: 'PerShare', label: l('_PerShare') },
    { key: 'Aggregate', label: l('_Aggregate') },
    { key: 'PerShare', label: l('_PerShare') },
  ];

  const defaultOverallConclusions = sortedValuedInstruments.map((instrument) => {
    const overallConclusion = project.overallConclusions.find(
      (conclusion) => conclusion.instrumentId === instrument.instrumentId
    );
    return {
      instrumentId: instrument.instrumentId,
      aggregateConcludedMarketValue: overallConclusion?.aggregateConcludedMarketValue ?? null,
      perShareConcludedMarketValue: overallConclusion?.perShareConcludedMarketValue ?? null,
    };
  });

  const formMethods = useForm<FormModel>({
    ...formConfigBase,
    defaultValues: {
      overallConclusions: defaultOverallConclusions,
    },
  });

  const { handleSubmit, getValues, reset, trigger } = formMethods;

  useEffect(() => {
    reset;
  }, [reset]);

  useEffect(() => {
    // resolve concurrency issue
    setTimeout(() => trigger(), 0);
  }, [trigger]);

  if (projectDetails.calcMethod !== 'PWERM_AND_OPM') {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const submitData = async () => {
    const formValues = getValues();
    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          overallConclusions: cloneDeep(formValues.overallConclusions),
        },
      })
    );
  };

  const getRowData = (instrument: EquityInstrumentDto) => {
    const instrumentId = instrument.instrumentId;

    const pwermAggMarketValue = permValuedInstruments[instrumentId].weightedDiscountedFairValue;
    const pwermMarketValuePerShare =
      permValuedInstruments[instrumentId].weightedDiscountedFairValuePerShare;

    const opmInstrument = opmSortedValuedInstruments.find(
      (opmInstrument) => opmInstrument.instrumentId === instrument.instrumentId
    );
    const opmAggMarketValue = opmInstrument?.years[0].marketValue ?? undefined;
    const opmMarketValuePerShare = opmInstrument?.years[0].marketValuePerShare ?? undefined;

    const aggCost = permValuedInstruments[instrumentId].equityInvestment;
    const subscriptionPricePerShare = permValuedInstruments[instrumentId].subscriptionPricePerShare;

    return [
      { value: pwermAggMarketValue, formattingRules: amountRules },
      {
        value: pwermMarketValuePerShare,
        key: 'perShare',
        visibleState: shouldShowPerShareValues,
        formattingRules: amountRules,
      },
      { value: opmAggMarketValue, formattingRules: amountRules },
      {
        value: opmMarketValuePerShare,
        key: 'perShare',
        visibleState: shouldShowPerShareValues,
        formattingRules: amountRules,
      },
      {
        value: 'input',
        name: (index: number) => `overallConclusions[${index}].aggregateConcludedMarketValue`,
      },
      {
        value: 'input',
        key: 'perShare',
        name: (index: number) => `overallConclusions[${index}].perShareConcludedMarketValue`,
        visibleState: shouldShowPerShareValues,
        formattingRules: amountRules,
      },
      { value: aggCost, formattingRules: amountRules },
      {
        value: subscriptionPricePerShare,
        key: 'perShare',
        visibleState: shouldShowPerShareValues,
        formattingRules: amountRules,
      },
    ];
  };

  return (
    <FormProvider {...formMethods}>
      <form onBlur={handleSubmit(submitData, submitData)}>
        <DashboardWidgetContainer
          title={l('_ValuationEstimates')}
          className={
            styles[
              `${PARENT_CLASSNAME}__widget-container-${
                shouldShowPerShareValues ? 'expanded' : 'collapsed'
              }`
            ]
          }>
          <table className="table-primary table-primary--fixed table-primary--secondary-theme">
            <thead>
              <tr>
                <th />
                <th
                  className={`table-primary__cell--header-quinary table-primary__cell--${
                    shouldShowPerShareValues ? 'center' : 'right'
                  }`}
                  colSpan={shouldShowPerShareValues ? 2 : 1}>
                  {l('_PwermMarketValue')}
                </th>
                <th
                  className={`table-primary__cell--header-quinary table-primary__cell--${
                    shouldShowPerShareValues ? 'center' : 'right'
                  }`}
                  colSpan={shouldShowPerShareValues ? 2 : 1}>
                  {l('_OpmMarketValue')}
                </th>
                <th
                  className={`table-primary__cell--header-quinary table-primary__cell--${
                    shouldShowPerShareValues ? 'center' : 'right'
                  }`}
                  colSpan={shouldShowPerShareValues ? 2 : 1}>
                  {l('_ConcludedMarketValue')}
                </th>
                <th
                  className={`table-primary__cell--header-quinary table-primary__cell--${
                    shouldShowPerShareValues ? 'center' : 'right'
                  }`}
                  colSpan={shouldShowPerShareValues ? 2 : 1}>
                  {l('_SubscriptionCost')}
                </th>
              </tr>
              <tr>
                <th className="table-primary__cell--header-quinary">{l('_Instrument')}</th>
                {columnNames.map((column, index) => {
                  if (!shouldShowPerShareValues && column.key === 'PerShare') {
                    return null;
                  }
                  return (
                    <th
                      key={index}
                      className="table-primary__cell--header-quinary table-primary__cell--right">
                      {column.label}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {sortedValuedInstruments.length === 0 && (
                <tr>
                  <td>{EmptyValues.EnDash}</td>
                  {[...Array(columnsCount - 1)].map((_, index) => (
                    <td key={index}>
                      <CellValue
                        value={undefined}
                        theme={CellValueTheme.Secondary}
                        alignment={Alignment.Right}
                      />
                    </td>
                  ))}
                </tr>
              )}
              {sortedValuedInstruments?.map((instrument, index) => {
                const rowData = getRowData(instrument);
                return (
                  <tr key={index} data-testid={`valuation-estimates-row-${index}`}>
                    <td>
                      <span className="heading-2 heading-2--alternate">
                        {instrument.instrumentNarrative}
                      </span>
                    </td>
                    {rowData.map((item, valueIndex) => {
                      if (!shouldShowPerShareValues && item.key === 'perShare') {
                        return null;
                      }
                      return (
                        <td key={valueIndex}>
                          {item.value === 'input' && item.name ? (
                            <GridTextField
                              className={styles[`${PARENT_CLASSNAME}__input`]}
                              name={item.name(index)}
                              data-testid={item.name(index)}
                              placeholder="-"
                              alignment={Alignment.Right}
                              rules={{ ...positiveValueValidator(true) }}
                              showValidationErrorsAsWarnings
                              {...numericBaseFieldFormattingProps}
                            />
                          ) : (
                            <CellValue
                              value={item.value}
                              theme={CellValueTheme.Secondary}
                              {...item.formattingRules}
                            />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DashboardWidgetContainer>
      </form>
    </FormProvider>
  );
};

export default PwermOpmConclusions;
