import { FC } from 'react';
import styles from './dashboard.module.scss';
import { InputSummary } from '@app/modules/projects/dashboard/widgets/input-summary/InputSummary';
import { ValuationConclusion } from './widgets/valuation-conclusion/ValuationConclusions';
import { SimulatedOutcomes } from './widgets/simulated-outcomes/SimulatedOutcomes';
import { SimulatedOutcomesChartTypes } from './opm-charts/opm-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { RouteConstants } from '../RouteConstants';
import { Navigate } from 'react-router-dom';
import { CollarRealWorld } from './widgets/collar-real-world/CollarRealWorld';

const PARENT_CLASSNAME = 'opm-dashboard-grid';

const DashboardOpm: FC = (): JSX.Element => {
  const results = useAppSelector((state) => state.opmCalculation.riskFreeValues);
  const { generatePath } = usePwcNavigate();

  // redirect the user if there is no data
  if (!results || Object.keys(results).length === 0) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div className={styles['input-container']}>
        <InputSummary />
        <CollarRealWorld />
      </div>
      <ValuationConclusion />
      <SimulatedOutcomes chartType={SimulatedOutcomesChartTypes.RiskFreeOutcome} />
    </div>
  );
};

export default DashboardOpm;
