import React from 'react';
import Button from '@app/shared/components/button/Button';
import { EventKey } from '@app/shared/models/contracts/enums/shared-enums';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';

interface EventActionIconProps {
  condition: boolean;
  action: () => void;
  icon: JSX.Element;
  buildStructureEventId: string;
}

const EventActionIcon: React.FC<EventActionIconProps> = ({
  condition,
  action,
  icon,
  buildStructureEventId,
}) => {
  if (buildStructureEventId === EventKey.InitialCapitalStructure || !condition) {
    return null;
  }

  return (
    <Button
      startIcon={icon}
      onClick={action}
      appearance={ButtonAppearance.CLEAN}
      size={ButtonSize.SMALL}
    />
  );
};

export default EventActionIcon;
