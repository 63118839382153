import { useState } from 'react';

const useToggler = (initial = true) => {
  const [defaultState, setIsOpen] = useState<boolean>(initial);

  const toggle = () => {
    setIsOpen((prevState) => !prevState);
  };

  return [defaultState, toggle] as const;
};

export default useToggler;
