import { FC } from 'react';
import OpmTotalEquityChart from '@app/modules/projects/dashboard/widgets/total-equity/opm/OpmTotalEquityChart';

import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useOpmTotalEquityGeneralMetrics } from './useOpmTotalEquityGeneralMetrics';
import { useGetOpmForecasts } from '@app/core/store/opm-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import styles from '@app/modules/projects/outputs/real-world-distributions/real-world-distributions.module.scss';

const OpmTotalEquity: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { getOpmForecasts } = useGetOpmForecasts();
  const forecasts = getOpmForecasts();

  const {
    entryTotalEquityValue,
    equityProceedsWeightedValue,
    exitSlnDebtProceeds,
    exitOrdinaryEquityProceeds,
    sweetEquityPercentageOfOrdinaryEquityProceeds,
  } = useOpmTotalEquityGeneralMetrics(forecasts);

  return (
    <DashboardWidgetContainer
      title={l('_TotalEquityTitle')}
      className={styles['mt-20']}
      isExpanded
      secondaryContent={
        <table className="table-primary table-primary--fixed table-primary--secondary-theme">
          <thead>
            <tr>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '120px' }}>
                {l('_EntryTotalEquity')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '120px' }}>
                {l('_ExitTotalEquity')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '120px' }}>
                {l('_ExitShareholderDebtProceeds')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '140px' }}>
                {l('_ExitOrdinaryEquityProceeds')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '167px' }}>
                {l('_SweetEquityPercentageOfOrdinaryEquityProceeds')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <CellValue
                  {...amountRules}
                  value={entryTotalEquityValue}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...amountRules}
                  value={equityProceedsWeightedValue}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...amountRules}
                  value={exitSlnDebtProceeds}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...amountRules}
                  value={exitOrdinaryEquityProceeds}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...enteredPercentageViewRules}
                  value={sweetEquityPercentageOfOrdinaryEquityProceeds}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
            </tr>
          </tbody>
        </table>
      }>
      {(Boolean(entryTotalEquityValue) || entryTotalEquityValue === 0) &&
        (!Number.isNaN(equityProceedsWeightedValue) || equityProceedsWeightedValue === 0) &&
        (Boolean(exitSlnDebtProceeds) || exitSlnDebtProceeds === 0) &&
        (Boolean(exitOrdinaryEquityProceeds) || exitOrdinaryEquityProceeds === 0) &&
        forecasts.length > 0 && <OpmTotalEquityChart isExpanded forecastData={forecasts} />}
    </DashboardWidgetContainer>
  );
};

export default OpmTotalEquity;
