import { FC } from 'react';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { SUPPORT_EMAIL } from '@app/shared/constants/emails';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import * as notificationActions from '@core/store/notification-slice';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { Trans } from 'react-i18next';
import { Navigate } from 'react-router-dom';

const ErrorContent: FC = (): JSX.Element => {
  const notificationState = useAppSelector((state) => state.notification);
  const { errorCode, errorInstanceId: errorId, message } = notificationState.notification ?? {};
  const dispatch = useAppDispatch();
  const { navigate } = usePwcNavigate();
  const { l } = useLocale();

  // If there is no errorId, no errorCode, and no message, redirect to the home page
  // An example of this occurring is if the user navigates to /error without any query parameters
  if (!errorId && !errorCode && !message) {
    return <Navigate to="/" />;
  }

  const emailMessageSubject = encodeURIComponent(`Equity Reward Analytics system error ${errorId}`);
  const emailMessageBody = encodeURIComponent(
    `Current Page: ${window.location.href}\rError ID: ${errorId}\rStatus Code: ${errorCode}\rMessage: ${message}`
  );

  return (
    <>
      <h1 className="heading-2">Something went wrong</h1>
      <p className="heading-2 heading-2--light">
        <Trans
          i18nKey="_GoBackToTheHomePageAndTryAgain"
          components={[
            <Button
              key="homePageButton"
              ignoreReadOnly
              appearance={ButtonAppearance.LINK}
              onClick={() => {
                navigate('/');
                dispatch(notificationActions.closeNotificationModal());
              }}
            />,
          ]}
        />
      </p>
      {errorId && (
        <>
          <hr className="spacer" />
          <p>
            <Trans
              i18nKey="_PleaseSendTheErrorDetailsToTheDealRewardTeam"
              components={[
                <a
                  key="teamEmailLink"
                  href={`mailto:${SUPPORT_EMAIL}?subject=${emailMessageSubject}&body=${emailMessageBody}`}
                  target="_blank"
                  rel="noopener noreferrer"
                />,
              ]}
            />
          </p>
          <br />
          <p>
            {l('_ErrorInstance')}: {errorId}
          </p>
          <div className="action-container">
            {notificationState.isErrorModalVisible && (
              <Button
                ignoreReadOnly
                appearance={ButtonAppearance.DEFAULT_SECONDARY}
                onClick={() => {
                  dispatch(notificationActions.closeNotificationModal());
                }}>
                {l('_Close')}
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ErrorContent;
