import React, { FC, useEffect, useState } from 'react';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { dateFormatter, formatMinMaxField } from '@app/shared/formatters';
import { enumKeyByValue, sortDictionaryPerInstrumentFn } from '@app/shared/helpers';
import { OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';
import styles from './input-summary.module.scss';
import MinusIcon from '@app/shared/icons/MinusIcon';
import SvgPlus from '@app/shared/icons/Plus';
import { isEmptyObject } from '@app/shared/helpers/is-empty-object';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  yearRules,
  textViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useLocalStateCollapse } from '@app/core/hooks/useLocalStateCollapse';
import { setOpmDashboardInputSummaryTableIsExpanded } from '@app/core/store/ui-values-slice';

export const InputSummary: FC = (): JSX.Element => {
  const inputSummary = useAppSelector((state) => state.opmCalculation.riskFreeValues.inputSummary);
  const opmCalc = useAppSelector((state) => state.opmCalculation);
  const { l } = useLocale();
  const [readyToRender, setReadyToRender] = useState(false);
  const [showDlomSection, setShowDlomSection] = useState<boolean>();
  const storedExpandedState = useAppSelector(
    (state) => state.uiValues.userSelections.opmDashboard.inputSummaryTable.isExpanded
  );
  const project = useAppSelector((state) => state.project.projectDraft);

  // determine if the project is an ERF project
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const capitalStructureId = project.pwermInput.cases[0].capitalStructureId;
  const isErfBuildStructure = buildStructures?.[capitalStructureId].isErf;

  const defaultExpandedState = storedExpandedState ?? false;

  const [showAllInstruments, setShowAllInstruments] = useState(defaultExpandedState);

  useLocalStateCollapse(showAllInstruments, setOpmDashboardInputSummaryTableIsExpanded);

  const sortedInputSummary = sortDictionaryPerInstrumentFn(inputSummary.marketValueDlom);

  const currentValuationType =
    opmCalc?.riskFreeValues?.inputs?.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue)
      ? l('_CurrentEV')
      : l('_CurrentTotalAttributableEquity');

  const currentValuationTypeVolatility =
    opmCalc?.riskFreeValues?.inputs?.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue)
      ? 'EV'
      : OpmSimulatedValue.Equity;

  const yieldTitle =
    opmCalc?.riskFreeValues?.inputs?.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue)
      ? l('_FreeCashFlowYield')
      : l('_DividendYield');

  useEffect(() => {
    if (inputSummary) {
      setReadyToRender(true);
      setShowDlomSection(Object.keys(inputSummary.marketValueDlom).length > 0);
    }
  }, [inputSummary]);

  const selectedCase = project.pwermInput.cases.find(
    (c) => c.caseId === inputSummary.selectedCaseId
  );

  return (
    <DashboardWidgetContainer title={l('_OpmInputSummary')} opm className={styles['full-width']}>
      {readyToRender && (
        <table className={styles['summary-table']}>
          <tbody>
            {isErfBuildStructure && selectedCase && (
              <tr>
                <td className={styles['title']}>{l('_CaseSelected')}</td>
                <td data-testid="current-ev-or-equity">
                  <CellValue
                    value={selectedCase.narrative}
                    className={`${styles['value']}`}
                    {...textViewRules}
                  />
                </td>
              </tr>
            )}
            <tr>
              <td className={styles['title']}>{l('_ValuationDate')}</td>
              <td
                className={`${styles['value']} table-primary__cell--right`}
                data-testid="valuation-date">
                <CellValue
                  value={dateFormatter(inputSummary.valuationDate)}
                  className={`${styles['value']}`}
                  alignment={Alignment.Right}
                />
              </td>
            </tr>
            <tr>
              <td className={styles['title']}>{currentValuationType}</td>
              <td data-testid="current-ev-or-equity">
                <CellValue
                  value={inputSummary.currentEvOrEquity}
                  className={`${styles['value']}`}
                  {...amountRules}
                />
              </td>
            </tr>
            <tr>
              <td className={styles['title']}>{l('_ForecastExitHorizonWeighted')}</td>
              <td
                className={`${styles['value']} table-primary__cell--right`}
                data-testid="forecast-exit-horizon">
                <CellValue
                  value={inputSummary.forecastExitHorizon}
                  className={`${styles['value']}`}
                  alignment={Alignment.Right}
                  {...yearRules}
                />
              </td>
            </tr>
            <tr>
              <td className={styles['title']}>{l('_RiskFreeRate')}</td>
              <td
                className={`${styles['value']} table-primary__cell--right`}
                data-testid="risk-free-rate">
                <CellValue
                  value={formatMinMaxField(inputSummary.riskFreeRate)}
                  className={`${styles['value']}`}
                  {...(typeof formatMinMaxField(inputSummary.riskFreeRate) === 'number'
                    ? enteredPercentageViewRules
                    : {})}
                  alignment={Alignment.Right}
                />
              </td>
            </tr>
            <tr>
              <td className={styles['title']}>
                {l('_ValuationTypeVolatility', { valuationType: currentValuationTypeVolatility })}
              </td>
              <td
                className={`${styles['value']} table-primary__cell--right`}
                data-testid="ev-or-equity-volatility">
                <CellValue
                  value={formatMinMaxField(inputSummary.evOrEquityVolatility)}
                  className={`${styles['value']}`}
                  {...(typeof formatMinMaxField(inputSummary.evOrEquityVolatility) === 'number'
                    ? enteredPercentageViewRules
                    : {})}
                  alignment={Alignment.Right}
                />
              </td>
            </tr>
            <tr>
              <td className={styles['title']}>{yieldTitle}</td>
              <td
                className={`${styles['value']} table-primary__cell--right`}
                data-testid="free-cashflow-yield">
                <CellValue
                  value={formatMinMaxField(inputSummary.financingCosts)}
                  {...(typeof formatMinMaxField(inputSummary.financingCosts) === 'number'
                    ? enteredPercentageViewRules
                    : {})}
                  className={`${styles['value']}`}
                  alignment={Alignment.Right}
                />
              </td>
            </tr>
            <tr>
              <td className={styles['title']}>{l('_TransactionCostWeighted')}</td>
              <td data-testid="transaction-costs">
                <CellValue
                  className={`${styles['value']}`}
                  value={inputSummary.transactionCosts * 100}
                  {...enteredPercentageViewRules}
                />
              </td>
            </tr>
            {inputSummary.ipoDiscount !== null ? (
              <tr>
                <td className={styles['title']}>{l('_IPODiscountWeighted')}</td>
                <td data-testid="ipo-discount">
                  <CellValue
                    className={`${styles['value']}`}
                    value={inputSummary.ipoDiscount * 100}
                    {...enteredPercentageViewRules}
                  />
                </td>
              </tr>
            ) : null}
            {inputSummary.postIpoDlom !== null ? (
              <tr>
                <td className={styles['title']}>{l('_PostIPOLockInDiscountWeighted')}</td>
                <td data-testid="post-ipo-dlom">
                  <CellValue
                    className={`${styles['value']}`}
                    value={inputSummary.postIpoDlom * 100}
                    {...enteredPercentageViewRules}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td className={styles['title']}>{l('_DLOC')}</td>
              <td data-testid="dloc">
                <CellValue
                  className={`${styles['value']}`}
                  value={inputSummary.dloc * 100}
                  {...enteredPercentageViewRules}
                />
              </td>
            </tr>
            {showDlomSection && (
              <>
                <tr>
                  <th className={styles['title']} data-testid="dlom-header">
                    {l('_DLOM')}
                  </th>
                </tr>
                {sortedInputSummary
                  ? Object.keys(sortedInputSummary)
                      .slice(0, showAllInstruments ? undefined : 2)
                      .map((key, mainIndex) => {
                        const instrumentName = sortedInputSummary[key].instrumentNarrative;
                        const trancheCount = Object.keys(sortedInputSummary).length;
                        const showExpander = trancheCount > 2;
                        const secondKeyName = Object.keys(sortedInputSummary)[1];
                        const secondInstrumentWithoutTranches = isEmptyObject(
                          sortedInputSummary[secondKeyName]?.trancheDloms
                        );
                        const shouldDisplayExpand =
                          mainIndex === 1 && showExpander && secondInstrumentWithoutTranches;
                        const marketValueDlom = inputSummary.marketValueDlom[key];

                        return (
                          <React.Fragment key={`fragment-${mainIndex}`}>
                            <tr key={key}>
                              <td
                                className={`${
                                  shouldDisplayExpand
                                    ? styles['dlom-title-primary']
                                    : styles['dlom-title-primary-light']
                                } ${styles['title']} ${styles['no-border']}`}>
                                {instrumentName}
                              </td>
                              <td
                                className={`${
                                  shouldDisplayExpand
                                    ? styles['dlom-value-primary']
                                    : styles['dlom-value-primary-light']
                                } ${styles['no-border']} table-primary__cell--right`}
                                data-testid={`${[key]}-instrument-dlom`}>
                                {marketValueDlom.instrumentDlom !== null ||
                                Object.keys(marketValueDlom.trancheDloms).length === 1 ? (
                                  <CellValue
                                    value={formatMinMaxField(
                                      marketValueDlom.instrumentDlom !== null
                                        ? marketValueDlom.instrumentDlom
                                        : marketValueDlom.trancheDloms[
                                            Object.keys(marketValueDlom.trancheDloms)[0]
                                          ]
                                    )}
                                    className={`${styles['value']}`}
                                    {...(typeof formatMinMaxField(
                                      marketValueDlom.instrumentDlom !== null
                                        ? marketValueDlom.instrumentDlom
                                        : marketValueDlom.trancheDloms[
                                            Object.keys(marketValueDlom.trancheDloms)[0]
                                          ]
                                    ) === 'number'
                                      ? enteredPercentageViewRules
                                      : {})}
                                    alignment={Alignment.Right}
                                  />
                                ) : null}
                              </td>
                            </tr>
                            {shouldDisplayExpand && (
                              <div className={styles['expander']}>
                                <button
                                  aria-label={l('_ToggleSectionCollapse', {
                                    section: l('_Instruments'),
                                  })}
                                  aria-expanded={showAllInstruments}
                                  onClick={() => setShowAllInstruments(!showAllInstruments)}
                                  className={styles['expander-button']}
                                  data-testid="expander-button-instrument">
                                  {showAllInstruments ? (
                                    <MinusIcon data-testid="minus-icon-instrument" />
                                  ) : (
                                    <SvgPlus data-testid="svg-plus-instrument" />
                                  )}
                                </button>
                              </div>
                            )}
                            {marketValueDlom.instrumentDlom === null &&
                              Object.keys(marketValueDlom.trancheDloms).length > 1 &&
                              Object.keys(marketValueDlom.trancheDloms).map(
                                (trancheKey, index, array) => {
                                  const lastTranche = index === array.length - 1;
                                  const shouldDisplayPrimaryUnderline =
                                    mainIndex === 1 && showExpander && lastTranche;
                                  const shouldNotDisplayBorder =
                                    marketValueDlom.instrumentDlom === null;
                                  return (
                                    <tr key={`${key}-${trancheKey}`}>
                                      <td
                                        className={`${
                                          shouldDisplayPrimaryUnderline &&
                                          styles['dlom-title-primary']
                                        } ${styles['dlom-title-tranche']} ${
                                          shouldNotDisplayBorder && styles['no-border']
                                        }`}>{`Tranche ${index + 1}`}</td>
                                      <td
                                        className={`${
                                          shouldDisplayPrimaryUnderline &&
                                          styles['dlom-value-primary']
                                        } ${styles['value']} ${
                                          shouldNotDisplayBorder && styles['no-border']
                                        } table-primary__cell--right`}
                                        data-testid={`${[key]}-tranche-${index + 1}`}>
                                        <CellValue
                                          value={formatMinMaxField(
                                            marketValueDlom.trancheDloms[trancheKey]
                                          )}
                                          className={`${styles['value']}`}
                                          {...(typeof formatMinMaxField(
                                            marketValueDlom.trancheDloms[trancheKey]
                                          ) === 'number'
                                            ? enteredPercentageViewRules
                                            : {})}
                                          alignment={Alignment.Right}
                                        />
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            {mainIndex === 1 &&
                              showExpander &&
                              !secondInstrumentWithoutTranches && (
                                <div className={styles['expander']}>
                                  <button
                                    aria-label={l('_ToggleSectionCollapse', {
                                      section: l('_Instruments'),
                                    })}
                                    aria-expanded={showAllInstruments}
                                    onClick={() => setShowAllInstruments(!showAllInstruments)}
                                    className={styles['expander-button']}
                                    data-testid="expander-button-tranche">
                                    {showAllInstruments ? (
                                      <MinusIcon data-testid="minus-icon-tranche" />
                                    ) : (
                                      <SvgPlus data-testid="svg-plus-tranche" />
                                    )}
                                  </button>
                                </div>
                              )}
                          </React.Fragment>
                        );
                      })
                  : '-'}
              </>
            )}
            <tr>
              <td className={styles['title']}>{l('_NoOfSimulations')}</td>
              <td data-testid="number-of-simulations">
                <CellValue
                  value={inputSummary.numberOfSimulations}
                  className={`${styles['value']}`}
                  {...amountRules}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </DashboardWidgetContainer>
  );
};
