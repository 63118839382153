import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { sumBy } from '@app/shared/helpers/sum-by';
import { getWeightedAverageValuePerCase } from '@app/shared/helpers/get-weighted-value-per-case';

export const getWeightedAverageValueForAllCases = (
  valueSelector: (
    caseItem: CaseDto,
    forecast: ForecastDto,
    multiple: number,
    multipleId: string | undefined
  ) => number,
  cases: CaseDto[]
): number => {
  return sumBy(
    cases,
    (_case) =>
      getWeightedAverageValuePerCase(_case, (caseItem, forecast, multiple, multipleId) =>
        valueSelector(caseItem, forecast, multiple, multipleId)
      ) *
      ((_case.probability ?? 0) / 100)
  );
};
