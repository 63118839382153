import { FC, Fragment, useEffect, useRef } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';
import {
  getCaseValueNetDebtItemsTotal,
  getNetDebtAtExit,
  getSlnAndPrefSharesForecast,
  selectCasesStructure,
  selectSlnAndPrefSharesInstruments,
} from '@core/store/project-slice-selectors';
import {
  cloneDeep,
  instrumentsSortFn,
  isInstrumentOwnerAmountEntered,
  sumBy,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import {
  getCalculatedProjectValueInPercent,
  selectSlnAndPrefSharesForecasts,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { positiveValueValidator } from '@core/validations/hook-forms/validators';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import { CostOfEquityTableHeader } from '@app/modules/projects/outputs/cost-of-equity/components/CostOfEquityTableHeader';
import { ExitMultipleWeightedEVSection } from '@app/modules/projects/outputs/cost-of-equity/components/ExitMultipleWeightedEVSection';
import { FormProvider, useForm } from 'react-hook-form';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import * as projectActions from '@core/store/project-slice';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';
import { setCostOfEquitySelectedTab } from '@app/core/store/ui-values-slice';

interface GetRefProp {
  getRef?: (ref: HTMLTableElement) => void;
}

export const InstrumentLevelData: FC<GetRefProp> = ({ getRef }): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const { instrumentId: currentInstrumentId } = useParams();
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const casesStructure = useAppSelector(selectCasesStructure);
  const project = useAppSelector((state) => state.project.projectDraft);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);
  const calculatedSlnAndPrefSharesForecasts = useAppSelector(selectSlnAndPrefSharesForecasts);

  const currentInstrument = project.equityInstruments.find(
    (instrument) => instrument.instrumentId === currentInstrumentId
  );

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    (instrument) =>
      !currentInstrument ||
      (isInstrumentOwnerAmountEntered(instrument) &&
        (instrument.rank < currentInstrument?.rank || currentInstrument?.rank === 0))
  );

  const isSlnAndPrefSharesInstrument = slnAndPrefSharesInstruments.some(
    (instrument) => instrument.instrumentId === currentInstrumentId
  );

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  const formMethods = useForm<{ cases: CaseDto[] }>({
    ...formConfigBase,
    defaultValues: { cases: casesStructure },
  });

  const { handleSubmit, reset, getValues, trigger } = formMethods;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const submitData = async () => {
    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          pwermInput: {
            ...project.pwermInput,
            cases: [...cloneDeep(getValues().cases)],
          },
        },
      })
    ).unwrap();
  };

  useEffect(() => {
    reset({ cases: [...cloneDeep(getValues().cases)] });
  }, [reset, currentInstrumentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentInstrumentIdRef = useRef(currentInstrumentId);

  useEffect(() => {
    currentInstrumentIdRef.current = currentInstrumentId;
  }, [currentInstrumentId]);

  useEffect(() => {
    return () => {
      dispatch(setCostOfEquitySelectedTab(currentInstrumentIdRef.current));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <div className="main-container-padding-remove">
      <FormProvider {...formMethods}>
        <form onBlur={handleSubmit(submitData, submitData)}>
          <table
            ref={tableRef}
            className="table-primary table-primary--framed table-primary--zebra table-primary--title-column-nowrap table-primary--sticky-first-column">
            <CostOfEquityTableHeader />
            <tbody>
              <ExitMultipleWeightedEVSection />
              <tr>
                <th className="table-primary__cell--header">
                  {l('_IndebtednessRelativeToInstrument')}
                </th>
                <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
              </tr>
              <TableSectionValuesPerForecast
                title="Net Debt"
                renderSpacer={false}
                renderCell={({ forecast }) => (
                  <CellValue {...amountRules} value={getNetDebtAtExit(forecast)} />
                )}
                renderAdditionalCell={({ caseItem }) => (
                  <CellValue
                    {...amountRules}
                    value={getCaseValueNetDebtItemsTotal(caseItem)}
                    theme={CellValueTheme.Tertiary}
                  />
                )}
              />
              {filteredSlnAndPrefSharesInstruments.sort(instrumentsSortFn).map((instrument) => (
                <TableSectionValuesPerForecast
                  renderSpacer={false}
                  key={instrument.instrumentId}
                  title={instrument.instrumentNarrative}
                  renderCell={({ caseItem, forecast }) => (
                    <CellValue
                      {...amountRules}
                      value={
                        getSlnAndPrefSharesForecast(
                          project,
                          calculatedSlnAndPrefSharesForecasts,
                          caseItem.caseId,
                          instrument.instrumentId,
                          forecast.forecastId
                        )?.closingBalance
                      }
                    />
                  )}
                  renderAdditionalCell={() => {
                    const investedAmountPerInstrument = sumBy(
                      instrument.ownership,
                      (owner) => Number(owner.amount) || 0
                    );
                    return (
                      <CellValue
                        {...amountRules}
                        value={investedAmountPerInstrument}
                        theme={CellValueTheme.Tertiary}
                      />
                    );
                  }}
                />
              ))}
              <TableSectionValuesPerForecast
                title="Total indebtedness relative to instrument"
                renderSpacer={false}
                renderCell={({ caseItem, forecast }) => {
                  return (
                    <CellValue
                      {...amountRules}
                      strong
                      value={getCalculatedProjectValue('InstrumentTotalDebt', {
                        caseId: caseItem.caseId,
                        forecastId: forecast.forecastId,
                        instrumentId: currentInstrumentId,
                      })}
                    />
                  );
                }}
                renderAdditionalCell={({ caseItem }) => {
                  const investedAmount = sumBy(filteredSlnAndPrefSharesInstruments, (instrument) =>
                    sumBy(instrument.ownership, (owner) => Number(owner.amount))
                  );

                  const total = getCaseValueNetDebtItemsTotal(caseItem) + investedAmount;
                  return (
                    <CellValue {...amountRules} value={total} theme={CellValueTheme.Tertiary} />
                  );
                }}
                additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                additionalCellClassNames="table-primary__cell--section-start-separator"
              />
              {((currentInstrument && currentInstrument?.tranches?.length) ?? 0) === 0 && (
                <>
                  <TableSectionValuesPerForecast
                    title="Ordinary equity hurdle"
                    renderSpacer={false}
                    renderCell={() => <CellValue {...amountRules} value={0} />}
                  />
                  <TableSectionValuesPerForecast
                    title="Spot gearing"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getCalculatedProjectValue('InstrumentSpotGearing', {
                            caseId: caseItem.caseId,
                            forecastId: forecast.forecastId,
                            instrumentId: currentInstrumentId,
                          })
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title="Average gearing"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getCalculatedProjectValue('InstrumentAverageGearing', {
                            caseId: caseItem.caseId,
                            forecastId: forecast.forecastId,
                            instrumentId: currentInstrumentId,
                          })
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title="Debt beta"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getCalculatedProjectValue('DebtBeta', {
                          caseId: caseItem.caseId,
                          forecastId: forecast.forecastId,
                          instrumentId: currentInstrumentId,
                        })}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title="Equity beta"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getCalculatedProjectValue('EquityBeta', {
                          caseId: caseItem.caseId,
                          forecastId: forecast.forecastId,
                          instrumentId: currentInstrumentId,
                        })}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title="Cost of Equity [spot]"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getCalculatedProjectValue('CostOfEquitySpot', {
                            caseId: caseItem.caseId,
                            forecastId: forecast.forecastId,
                            instrumentId: currentInstrumentId,
                          })
                        )}
                      />
                    )}
                    additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                    additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  />
                  <TableSectionValuesPerForecast
                    title="Discount factor [point]"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getCalculatedProjectValue('DiscountFactorPoint', {
                          caseId: caseItem.caseId,
                          forecastId: forecast.forecastId,
                          instrumentId: currentInstrumentId,
                        })}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title="Discount factor [cumulative]"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getCalculatedProjectValue('DiscountFactorCumulative', {
                          caseId: caseItem.caseId,
                          forecastId: forecast.forecastId,
                          instrumentId: currentInstrumentId,
                        })}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title="Cost of Equity [cumulative]"
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getCalculatedProjectValue('CostOfEquityCumulative', {
                            caseId: caseItem.caseId,
                            forecastId: forecast.forecastId,
                            instrumentId: currentInstrumentId,
                          })
                        )}
                      />
                    )}
                    additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                    additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  />
                </>
              )}
              {(currentInstrument?.tranches?.length ?? 0) > 0 && (
                <>
                  {currentInstrument?.tranches?.map((tranche, index) => (
                    <Fragment key={index}>
                      <tr>
                        <th className="table-primary__cell--header table-primary__cell--header table-primary__cell--header-tertiary">
                          {l('_Tranche')} {index + 1}
                        </th>
                        <th
                          className="table-primary__cell--header table-primary__cell--header table-primary__cell--header-tertiary"
                          colSpan={columnsCount - 1}
                        />
                      </tr>
                      <TableSectionValuesPerForecast
                        title="Ordinary equity hurdle"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...amountRules}
                            value={getCalculatedProjectValue('MinOrdinaryEquityHurdlePerForecast', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: currentInstrumentId,
                              trancheId: tranche.id,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Spot gearing"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValue('InstrumentSpotGearing', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: currentInstrumentId,
                              trancheId: tranche.id,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Average gearing"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getCalculatedProjectValue('InstrumentAverageGearing', {
                                caseId: caseItem.caseId,
                                forecastId: forecast.forecastId,
                                instrumentId: currentInstrumentId,
                                trancheId: tranche.id,
                              })
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Debt beta"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getCalculatedProjectValue('DebtBeta', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: currentInstrumentId,
                              trancheId: tranche.id,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Equity beta"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getCalculatedProjectValue('EquityBeta', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: currentInstrumentId,
                              trancheId: tranche.id,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Cost of Equity [spot]"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getCalculatedProjectValue('CostOfEquitySpot', {
                                caseId: caseItem.caseId,
                                forecastId: forecast.forecastId,
                                instrumentId: currentInstrumentId,
                                trancheId: tranche.id,
                              })
                            )}
                          />
                        )}
                        additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      />
                      <TableSectionValuesPerForecast
                        title="Discount factor [point]"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getCalculatedProjectValue('DiscountFactorPoint', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: currentInstrumentId,
                              trancheId: tranche.id,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Discount factor [cumulative]"
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getCalculatedProjectValue('DiscountFactorCumulative', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: currentInstrumentId,
                              trancheId: tranche.id,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Cost of Equity [cumulative]"
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getCalculatedProjectValue('CostOfEquityCumulative', {
                                caseId: caseItem.caseId,
                                forecastId: forecast.forecastId,
                                instrumentId: currentInstrumentId,
                                trancheId: tranche.id,
                              })
                            )}
                          />
                        )}
                        additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      />
                    </Fragment>
                  ))}
                </>
              )}
              {isSlnAndPrefSharesInstrument && (
                <TableSectionValuesPerForecast
                  title="All in discount rate"
                  renderCell={({ caseItemIndex, forecastIndex, forecast }) => {
                    const allInDiscountRateIndex = forecast.allInDiscountRates.findIndex(
                      (item) => item.instrumentId === currentInstrumentId
                    );

                    return (
                      <GridTextField
                        name={`cases[${caseItemIndex}].forecasts[${forecastIndex}].allInDiscountRates[${allInDiscountRateIndex}].value`}
                        {...percentageFieldFormattingProps}
                        tooltipVariation={GridFieldTooltipVariation.Right}
                        rules={positiveValueValidator(true)}
                      />
                    );
                  }}
                />
              )}
            </tbody>
          </table>
        </form>
      </FormProvider>
    </div>
  );
};
