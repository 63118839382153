import { SVGProps } from 'react';
const SvgSaveAsImage = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M0 .75A.75.75 0 0 1 .75 0h3.5a.75.75 0 1 1 0 1.5H1.5v2.75a.75.75 0 1 1-1.5 0V.75ZM6.25 8a1.751 1.751 0 0 1 3.5 0 1.751 1.751 0 0 1-3.5 0Zm3.169-5c.5 0 .953.297 1.15.756l.103.244H11c1.103 0 2 .897 2 2v4c0 1.103-.897 2-2 2H5c-1.103 0-2-.897-2-2V6c0-1.103.897-2 2-2h.328l.103-.244c.197-.46.647-.756 1.15-.756H9.42ZM6.316 5.5H5c-.275 0-.5.225-.5.5v4c0 .275.225.5.5.5h6c.275 0 .5-.225.5-.5V6c0-.275-.225-.5-.5-.5H9.684l-.428-1H6.744l-.428 1ZM15.25 0a.75.75 0 0 1 .75.75v3.5c0 .416-.334.75-.75.75a.748.748 0 0 1-.75-.75V1.5h-2.75a.75.75 0 1 1 0-1.5h3.5ZM0 11.75a.75.75 0 1 1 1.5 0v2.75h2.75c.416 0 .75.334.75.75s-.334.75-.75.75H.75a.75.75 0 0 1-.75-.75v-3.5ZM11.75 16a.748.748 0 0 1-.75-.75c0-.416.334-.75.75-.75h2.75v-2.75c0-.416.334-.75.75-.75s.75.334.75.75v3.5c0 .416-.334.75-.75.75h-3.5Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgSaveAsImage;
