import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { useGetCalculatedProjectValue } from '@app/shared/helpers';
import { useMemo } from 'react';

export interface BenchmarkingCalculatedValues {
  sponsorInvestment: {
    percentageOfFixedReturnInstrumentWithinStrip: Nullable<number>;
    couponOnPreferredCapitalInstrumentPercentage: Nullable<number>;
  };
  managementParticipation: {
    managementStripPercentage: Nullable<number>;
    managementStripTotalSubscriptionCost: Nullable<number>;
  };
  returnsAnalysis: {
    low: {
      managementMoMSweetOnly: Nullable<number>;
      managementStripReturn: Nullable<number>;
      totalManagementMom: Nullable<number>;
      envyRatio: Nullable<number>;
      envyRatioSweetOnly: Nullable<number>;
    };
    mid: {
      managementMoMSweetOnly: Nullable<number>;
      managementStripReturn: Nullable<number>;
      totalManagementMom: Nullable<number>;
      envyRatio: Nullable<number>;
      envyRatioSweetOnly: Nullable<number>;
    };
    high: {
      managementMoMSweetOnly: Nullable<number>;
      managementStripReturn: Nullable<number>;
      totalManagementMom: Nullable<number>;
      envyRatio: Nullable<number>;
      envyRatioSweetOnly: Nullable<number>;
    };
  };
}

export const useDefaultCalculatedBenchmarking = (): BenchmarkingCalculatedValues => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();

  const calculatedValue = useMemo(() => {
    return {
      sponsorInvestment: {
        percentageOfFixedReturnInstrumentWithinStrip:
          getCalculatedProjectValueInPercent(
            getCalculatedProjectValue('PercentageOfFixedReturnInstrumentWithinStrip')
          ) ?? null,
        couponOnPreferredCapitalInstrumentPercentage:
          getCalculatedProjectValueInPercent(
            getCalculatedProjectValue('CouponOnPreferredCapitalInstrumentPercentage')
          ) ?? null,
      },
      managementParticipation: {
        managementStripPercentage:
          getCalculatedProjectValueInPercent(
            getCalculatedProjectValue('ManagementStripPercentage')
          ) ?? null,
        managementStripTotalSubscriptionCost:
          getCalculatedProjectValue('ManagementStripTotalSubscriptionCost') ?? null,
      },
      returnsAnalysis: {
        low: {
          managementMoMSweetOnly: getCalculatedProjectValue('ManagementMoMSweetOnlyLow') ?? null,
          managementStripReturn: getCalculatedProjectValue('ManagementStripReturnLow') ?? null,
          totalManagementMom: getCalculatedProjectValue('TotalManagementMoMLow') ?? null,
          envyRatio: getCalculatedProjectValue('EnvyRatioLow') ?? null,
          envyRatioSweetOnly: getCalculatedProjectValue('EnvyRatioSweetOnlyLow') ?? null,
        },
        mid: {
          managementMoMSweetOnly: getCalculatedProjectValue('ManagementMoMSweetOnlyMid') ?? null,
          managementStripReturn: getCalculatedProjectValue('ManagementStripReturnMid') ?? null,
          totalManagementMom: getCalculatedProjectValue('TotalManagementMoMMid') ?? null,
          envyRatio: getCalculatedProjectValue('EnvyRatioMid') ?? null,
          envyRatioSweetOnly: getCalculatedProjectValue('EnvyRatioSweetOnlyMid') ?? null,
        },
        high: {
          managementMoMSweetOnly: getCalculatedProjectValue('ManagementMoMSweetOnlyHigh') ?? null,
          managementStripReturn: getCalculatedProjectValue('ManagementStripReturnHigh') ?? null,
          totalManagementMom: getCalculatedProjectValue('TotalManagementMoMHigh') ?? null,
          envyRatio: getCalculatedProjectValue('EnvyRatioHigh') ?? null,
          envyRatioSweetOnly: getCalculatedProjectValue('EnvyRatioSweetOnlyHigh') ?? null,
        },
      },
    };
  }, [getCalculatedProjectValue]);
  return calculatedValue;
};
