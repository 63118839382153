export const MAX_SCALE = 15;

const baseDigitsMaskBlocks = {
  blocks: {
    num: {
      mask: Number,
      signed: true,
      radix: '.',
      thousandsSeparator: ',',
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER,
    },
  },
};

export const percentagePattern = {
  mask: 'num%',
  lazy: false,
  blocks: {
    num: {
      ...baseDigitsMaskBlocks.blocks.num,
      scale: MAX_SCALE,
    },
  },
};

export const multiplierPattern = {
  mask: 'numx',
  lazy: false,
  blocks: {
    num: {
      ...baseDigitsMaskBlocks.blocks.num,
      scale: MAX_SCALE,
      normalizeZeros: true,
    },
  },
};

export const multiplierPatternViewOnly = {
  mask: 'numx',
  lazy: false,
  blocks: {
    num: {
      ...baseDigitsMaskBlocks.blocks.num,
      scale: 1,
      normalizeZeros: true,
      padFractionalZeros: true,
    },
  },
};

export const numberPattern = {
  mask: 'num',
  blocks: {
    num: {
      ...baseDigitsMaskBlocks.blocks.num,
      normalizeZeros: true,
      padFractionalZeros: false,
      scale: MAX_SCALE,
    },
  },
};

export const integerPattern = {
  mask: 'num',
  blocks: {
    num: {
      ...numberPattern.blocks.num,
      scale: 0,
    },
  },
};

export const digitsPattern = {
  mask: /^\d+$/,
};

export const swapMaskForValueDisplay = {
  mask: function () {
    return true;
  },
};
