import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { WATERFALL_SECTION_OPTIONS } from '@app/shared/constants/dropdown-values/waterfall-sections';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { RouteConstants } from '../../RouteConstants';
import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectCasesStructure } from '@app/core/store/project-slice-selectors';
import { Pwerm2WaterfallTableHeader } from './Pwerm2WaterfallTableHeader';
import { Pwerm2WaterfallExitMultiplesPartialTable } from './exit-multiples/Pwerm2WaterfallExitMultiplesPartialTable';
import TableScreenshotAction from '@app/shared/components/table/TableScreenshotAction';
import { removeFileIllegalCharacters } from '@app/shared/helpers/remove-file-illegal-characters';
import { FC, useEffect, useRef } from 'react';
import { Pwerm2WaterfallEnterpriseValuePartialTable } from './enterprise-value/Pwerm2WaterfallEnterpriseValuePartialTable';
import { Pwerm2WaterfallNetExitProceedsPartialTable } from './net-exit-proceeds/Pwerm2WaterfallNetExitProceedsPartialTable';
import { Pwerm2WaterfallTotalEquityProceedsPartialTable } from './total-equity-proceeds/Pwerm2WaterfallTotalEquityProceedsPartialTable';
import { Pwerm2WaterfallShareholderDebtDistributionsPartialTable } from './shareholder-debt-distributions/Pwerm2WaterfallShareholderDebtDistributionsPartialTable';
import { Pwerm2WaterfallOrdinaryEquityPartialTable } from './ordinary-equity/Pwerm2WaterfallOrdinaryEquityPartialTable';
import { Pwerm2WaterfallValuationPartialTable } from './valuation/Pwerm2WaterfallValuationPartialTable';
import { Pwerm2WaterfallCrossCheckPartialTable } from './cross-check/Pwerm2WaterfallCrossCheckPartialTable';
import { Pwerm2WaterfallSponsorReturnPartialTable } from './sponsor-return/Pwerm2WaterfallSponsorReturnPartialTable';
import { useLocale } from '@app/core/hooks/useLocale';
import { useDownloadExcel } from '@app/shared/components/excel-exporter/ExcelExporter';
import { dateFormatter } from '@app/shared/formatters';

interface Pwerm2WaterfallTableProps {
  shouldRenderInnerContent?: boolean;
  excelExportEnabled?: boolean;
  getRef?: (ref: HTMLTableElement) => void;
}

export const Pwerm2WaterfallTable: FC<Pwerm2WaterfallTableProps> = ({
  shouldRenderInnerContent = true,
  excelExportEnabled = false,
  getRef,
}) => {
  const { l } = useLocale();
  const { isDevFeatureEnabled } = useDevFeatures();
  const { generatePath } = usePwcNavigate();
  const tableRef = useRef<HTMLTableElement>(null);
  const casesStructure = useAppSelector(selectCasesStructure);
  const project = useAppSelector((state) => state.project);

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: {
      scrollToSection: null,
    },
  });

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${removeFileIllegalCharacters(
      project.projectDraft?.name
    )}_Waterfall2_${dateFormatter(new Date().toString(), {
      dateStyle: 'short',
    })}`,
    sheet: 'Waterfall PWERM2',
  });

  useEffect(() => {
    if (shouldRenderInnerContent && excelExportEnabled) {
      onDownload();
    }
    // effect tracks only one dependency
  }, [shouldRenderInnerContent]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isDevFeatureEnabled(DevFeature.PWERM2)) {
    const newPath = generatePath(`../${RouteConstants.PwermWaterfall}`);
    return <Navigate to={newPath} />;
  }

  const { handleSubmit, getValues } = formMethods;

  const submitData = () => {
    if (getValues() && getValues().scrollToSection) {
      const scrollToSection = document.getElementById(
        (getValues().scrollToSection ?? '').toString()
      );
      scrollToSection?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  casesStructure.sort((a, b) => a.caseOrder! - b.caseOrder!);

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  return (
    <div className="main-container-padding-remove">
      <div className="main-container__additional-actions">
        <TableScreenshotAction
          tableRef={tableRef}
          testIdString="save-waterfall-image"
          filePrefix={`Waterfall_PWERM_${removeFileIllegalCharacters(project.projectDraft?.name)}_`}
        />
        <FormProvider {...formMethods}>
          <form
            onChange={handleSubmit(submitData, submitData)}
            className="main-container__section-selector">
            <FormSelect
              ariaLabel={l('_SelectorAriaLabel', {
                label: l('_WaterfallSection'),
              })}
              name="scrollToSection"
              required
              options={WATERFALL_SECTION_OPTIONS}
              isGapless
              ignoreReadOnly
            />
          </form>
        </FormProvider>
      </div>
      <table
        ref={tableRef}
        className="table-primary table-primary--zebra table-primary--framed table-primary--title-column-nowrap table-primary--waterfall"
        data-testid="waterfall-table">
        {shouldRenderInnerContent && (
          <>
            <colgroup>
              <col className={styles['labels-col']} />
              <col style={{ minWidth: '80px' }} />
              {[...Array(columnsCount - 2)].map((_, index) => (
                <col key={`col-${index}`} />
              ))}
            </colgroup>
            <Pwerm2WaterfallTableHeader />
            <Pwerm2WaterfallExitMultiplesPartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallEnterpriseValuePartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallNetExitProceedsPartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallTotalEquityProceedsPartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallShareholderDebtDistributionsPartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallOrdinaryEquityPartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallValuationPartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallSponsorReturnPartialTable columnsCount={columnsCount} />
            <Pwerm2WaterfallCrossCheckPartialTable columnsCount={columnsCount} />
          </>
        )}
      </table>
    </div>
  );
};
