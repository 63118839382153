import { FC } from 'react';
import TableSectionValuesPerForecast from '../../../components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getCalculatedVariableValue,
  getNetDebtAtExit,
} from '@app/core/store/project-slice-selectors';
import TableSectionValuesPerMultiple from '../../../components/TableSectionValuesPerMultiple';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectCalculatedVariablesResults } from '@app/core/store/pwerm-calculation-slice-selectors';
import { useKeyMetrics } from '@app/modules/projects/dashboard/widgets/key-metrics/useKeyMetrics';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import { enumKeyByValue } from '@app/shared/helpers';
import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';

export const WaterfallTotalEquityProceedsPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const { getWeightedAverageTotalEquityProceeds } = useKeyMetrics();
  const [isTotalEquityProceedsOpen, setIsTotalEquityProceedsOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Total Equity Proceeds"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.TotalEquityProceeds)}
          isOpen={isTotalEquityProceedsOpen}
          setIsOpen={setIsTotalEquityProceedsOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isTotalEquityProceedsOpen,
        })}>
        <TableSectionValuesPerForecast
          title="(Net Debt) / Net Cash"
          renderCell={({ forecast }) => (
            <CellValue {...amountRules} value={getNetDebtAtExit(forecast) * -1} />
          )}
        />
        <TableSectionValuesPerMultiple
          title="Total equity"
          testId="totalEquity"
          renderCell={({ caseItem, forecast, multiple }) => (
            <CellValue
              {...amountRules}
              value={getCalculatedVariableValue(
                project,
                calculatedVariables,
                caseItem.caseId,
                forecast.forecastId,
                multiple,
                'Total Equity Proceeds'
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title="Weighted total equity"
          highlight
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWeightedAverageTotalEquityProceeds(caseItem, forecast)}
            />
          )}
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
        />
      </tbody>
    </>
  );
};

export default WaterfallTotalEquityProceedsPartialTable;
