import { FC, Fragment } from 'react';
import classNames from 'classnames';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { selectCasesStructure } from '@core/store/project-slice-selectors';

interface TableSpacerRowProps {
  additionalTitleClassNames?: string;
}

const TableSpacerRow: FC<TableSpacerRowProps> = ({ additionalTitleClassNames }): JSX.Element => {
  const casesStructure = useAppSelector(selectCasesStructure);

  return (
    <tr>
      <th
        className={classNames(
          'table-primary__cell--vertical-separator table-primary__cell--section-separator',
          additionalTitleClassNames
        )}
      />
      {casesStructure.map((caseItem, index) => (
        <Fragment key={index + 'spacer'}>
          <td
            className={classNames(
              'table-primary__cell--section-separator table-primary__cell--vertical-separator'
            )}
          />
          <td
            key={index + 'spacer'}
            colSpan={caseItem.forecasts.length}
            className={classNames(
              'table-primary__cell--section-separator table-primary__cell--vertical-separator'
            )}
          />
        </Fragment>
      ))}
    </tr>
  );
};

export default TableSpacerRow;
