import { OpmCalculationResultsDto } from './opm-calculation-results-dto';

export enum ProjectResultType {
  OpmRiskFree = 'OpmRiskFree',
  OpmRealWorld = 'OpmRealWorld',
}

// add additional result types here if needed
export type CalculationResultsDto = Record<
  keyof typeof ProjectResultType,
  OpmCalculationResultsDto
>;
