import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC, Fragment } from 'react';
import TableSectionValuesPerMultiple from '../../../components/TableSectionValuesPerMultiple';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getMultipleById,
  getShareholdingPercentageOfInstrument,
  getTotalOrdinaryEquityProceeds,
  selectOrdinaryEquityInstruments,
} from '@app/core/store/project-slice-selectors';
import TableSectionValuesPerForecast from '../../../components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  getCalculatedProjectResults,
  getCalculatedProjectValueInPercent,
  selectCalculatedPayoutsResults,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { useWeightedAverageCalculator } from '../../../custom-hooks/useWeightedAverageCalculator';
import {
  enumKeyByValue,
  getCalculatedProjectValuesArray,
  instrumentByTypeOrTranchesOrDefaultSortFn,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import {
  Operator,
  PayoutDriver,
  TargetMetric,
  WaterfallSections,
} from '@app/shared/models/contracts/enums/shared-enums';
import {
  calculatedMultipleValueFormatterProps,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { TextTransform } from '@app/shared/enums/text-transform.enum';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import classNames from 'classnames';
import WaterfallTotalPayoutSummaryPartialTable from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/total-payout-summary/WaterfallTotalPayoutSummaryPartialTable';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';

export const WaterfallOrdinaryEquityPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedResults = useAppSelector((state) => state.pwermCalculation);

  const calculatedPayouts = useAppSelector(selectCalculatedPayoutsResults);
  const equityInstruments = useAppSelector(selectOrdinaryEquityInstruments);
  const waterfallPayoutResults = useAppSelector(getCalculatedProjectResults);

  const calculatedWeightedAverage = useWeightedAverageCalculator();
  const [isOrdinaryEquityOpen, setIsOrdinaryEquityOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Ordinary Equity"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.OrdinaryEquity)}
          isOpen={isOrdinaryEquityOpen}
          setIsOpen={setIsOrdinaryEquityOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isOrdinaryEquityOpen,
        })}>
        <TableSectionValuesPerMultiple
          title="Ordinary equity"
          renderCell={({ caseItem, forecast, multiple }) => {
            return (
              <CellValue
                {...amountRules}
                value={getTotalOrdinaryEquityProceeds(
                  project,
                  calculatedPayouts,
                  caseItem.caseId,
                  forecast.forecastId,
                  multiple
                )}
              />
            );
          }}
        />
        <TableSectionValuesPerForecast
          title="Weighted ordinary equity"
          highlight
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              {...amountRules}
              value={calculatedWeightedAverage(
                caseItem.caseId,
                forecast.forecastId,
                ({ caseId, forecastId, multipleId }) => {
                  const multiple = getMultipleById(project, caseId, multipleId)?.multiple;
                  if (!multiple) {
                    return undefined;
                  }

                  return getTotalOrdinaryEquityProceeds(
                    project,
                    calculatedPayouts,
                    caseId,
                    forecastId,
                    multiple
                  );
                }
              )}
            />
          )}
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
        />
        {equityInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn).map((instrument) => (
          <Fragment key={instrument.instrumentId}>
            <tr>
              <th className="table-primary__cell--header-quaternary">
                {instrument.instrumentNarrative}
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-quaternary" />
            </tr>
            {instrument.tranches?.length === 0 && (
              <>
                <TableSectionValuesPerForecast
                  title="% shareholding"
                  renderCell={() => (
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={getShareholdingPercentageOfInstrument(
                        calculatedResults.values,
                        instrument.instrumentId
                      )}
                    />
                  )}
                />
                <TableSectionValuesPerMultiple
                  title="% participation"
                  renderCell={({ caseItem, forecast, multipleId }) => (
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={getCalculatedProjectValueInPercent(
                        getCalculatedProjectValue('InstrumentParticipationPercentage', {
                          caseId: caseItem.caseId,
                          forecastId: forecast.forecastId,
                          instrumentId: instrument.instrumentId,
                          multipleId,
                        })
                      )}
                    />
                  )}
                />
                <TableSectionValuesPerMultiple
                  title="Distributions paid"
                  renderCell={({ caseItem, forecast, multipleId }) => {
                    return (
                      <CellValue
                        {...amountRules}
                        value={getCalculatedProjectValue('InstrumentDistributionsPaid', {
                          caseId: caseItem.caseId,
                          forecastId: forecast.forecastId,
                          instrumentId: instrument.instrumentId,
                          multipleId,
                        })}
                      />
                    );
                  }}
                />
                <TableSectionValuesPerForecast
                  title="Weighted distributions"
                  highlight
                  additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  renderCell={({ caseItem, forecast }) => (
                    <CellValue
                      {...amountRules}
                      strong
                      value={getCalculatedProjectValue(
                        'InstrumentWeightedAveragePaidDistributions',
                        {
                          caseId: caseItem.caseId,
                          forecastId: forecast.forecastId,
                          instrumentId: instrument.instrumentId,
                        }
                      )}
                    />
                  )}
                />
              </>
            )}
            {(instrument.tranches?.length ?? 0) > 0 && (
              <>
                {instrument.tranches!.map((tranche, index) => {
                  const trancheHasIRR = tranche.statements[0].conditions.some(
                    (c) =>
                      c.targetMetric === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
                  );

                  const trancheHasMoM = tranche.statements[0].conditions.some(
                    (c) =>
                      c.targetMetric === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM)
                  );

                  const trancheHasConjunction = Boolean(tranche.statements[0].conjunction);

                  const isIncrementalHurdleUsed =
                    tranche.statements[0].payoutCalculation.payoutDriver ===
                    enumKeyByValue(PayoutDriver, PayoutDriver.Incremental);

                  const formatLessInstitutionalShareholderDebt = (value: number | undefined) => {
                    return value ? value * -1 : value;
                  };

                  return (
                    <Fragment key={tranche.id}>
                      <tr>
                        <th className="table-primary__cell--header-tertiary">
                          Tranche {index + 1}
                        </th>
                        <th
                          colSpan={columnsCount - 1}
                          className="table-primary__cell--header-tertiary"
                        />
                      </tr>
                      {(trancheHasIRR || trancheHasMoM) && (
                        <>
                          <TableSectionValuesPerForecast
                            title="Sponsor cost"
                            renderSpacer={false}
                            renderCell={() => (
                              <CellValue
                                {...amountRules}
                                value={getCalculatedProjectValue('InstitutionCost')}
                              />
                            )}
                          />
                          {trancheHasIRR &&
                            tranche.statements[0].conditions
                              .filter(
                                (x) =>
                                  x.targetMetric ===
                                  enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
                              )
                              .map((condition, conditionIndex) => (
                                <TableSectionValuesPerForecast
                                  key={conditionIndex}
                                  renderSpacer={false}
                                  title={`Sponsor hurdle IRR ${
                                    Operator[condition.operator]
                                  } ${numberValueFormatter({
                                    value: Number(condition.targetMetricTestValue),
                                    ...percentageValueFormatterProps,
                                  })}`}
                                  renderCell={({ caseItem, forecast }) => (
                                    <CellValue
                                      {...amountRules}
                                      value={
                                        getCalculatedProjectValuesArray(
                                          'InstitutionHurdleIRR',
                                          waterfallPayoutResults,
                                          {
                                            caseId: caseItem.caseId,
                                            forecastId: forecast.forecastId,
                                            instrumentId: instrument.instrumentId,
                                            trancheId: tranche.id,
                                          }
                                        )[conditionIndex]?.value
                                      }
                                    />
                                  )}
                                />
                              ))}

                          {trancheHasMoM &&
                            tranche.statements[0].conditions
                              .filter(
                                (x) =>
                                  x.targetMetric ===
                                  enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM)
                              )
                              .map((condition, conditionIndex) => (
                                <TableSectionValuesPerForecast
                                  key={conditionIndex}
                                  renderSpacer={false}
                                  title={`Sponsor hurdle MoM ${
                                    Operator[condition.operator]
                                  } ${numberValueFormatter({
                                    value: Number(condition.targetMetricTestValue),
                                    ...calculatedMultipleValueFormatterProps,
                                  })}`}
                                  renderCell={({ caseItem, forecast }) => (
                                    <CellValue
                                      {...amountRules}
                                      value={
                                        getCalculatedProjectValuesArray(
                                          'InstitutionHurdleMoM',
                                          waterfallPayoutResults,
                                          {
                                            caseId: caseItem.caseId,
                                            forecastId: forecast.forecastId,
                                            instrumentId: instrument.instrumentId,
                                            trancheId: tranche.id,
                                          }
                                        )[conditionIndex]?.value
                                      }
                                    />
                                  )}
                                />
                              ))}

                          {trancheHasConjunction && (
                            <TableSectionValuesPerForecast
                              renderSpacer={false}
                              title="Institution hurdle active"
                              renderCell={({ caseItem, forecast }) => (
                                <CellValue
                                  {...amountRules}
                                  value={getCalculatedProjectValue('InstitutionHurdleActive', {
                                    caseId: caseItem.caseId,
                                    forecastId: forecast.forecastId,
                                    instrumentId: instrument.instrumentId,
                                    trancheId: tranche.id,
                                  })}
                                />
                              )}
                            />
                          )}
                          <TableSectionValuesPerForecast
                            title="Less sponsor debt"
                            renderSpacer={false}
                            renderCell={({ caseItem, forecast }) => (
                              <CellValue
                                {...amountRules}
                                value={formatLessInstitutionalShareholderDebt(
                                  getCalculatedProjectValue('LessInstitutionalShareholderDebt', {
                                    caseId: caseItem.caseId,
                                    forecastId: forecast.forecastId,
                                  })
                                )}
                              />
                            )}
                          />
                          <TableSectionValuesPerForecast
                            title="Hurdle remaining"
                            renderSpacer={false}
                            renderCell={({ caseItem, forecast }) => (
                              <CellValue
                                {...amountRules}
                                value={getCalculatedProjectValue('InstitutionHurdleRemaining', {
                                  caseId: caseItem.caseId,
                                  forecastId: forecast.forecastId,
                                  instrumentId: instrument.instrumentId,
                                  trancheId: tranche.id,
                                })}
                              />
                            )}
                          />
                          <TableSectionValuesPerForecast
                            title="Gross up %"
                            renderCell={({ caseItem, forecast }) => (
                              <CellValue
                                {...enteredPercentageViewRules}
                                value={getCalculatedProjectValueInPercent(
                                  getCalculatedProjectValue('EffectiveGrossUp', {
                                    caseId: caseItem.caseId,
                                    forecastId: forecast.forecastId,
                                    instrumentId: instrument.instrumentId,
                                    trancheId: tranche.id,
                                  })
                                )}
                              />
                            )}
                          />
                        </>
                      )}
                      <TableSectionValuesPerForecast
                        title="Ordinary equity hurdle"
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...amountRules}
                            value={getCalculatedProjectValue('OrdinaryEquityHurdle', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: instrument.instrumentId,
                              trancheId: tranche.id,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerMultiple
                        title="Is hurdle met?"
                        renderCell={({ caseItem, forecast, multipleId }) => {
                          const distributions =
                            getCalculatedProjectValue('TrancheDistributionsPaid', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: instrument.instrumentId,
                              trancheId: tranche.id,
                              multipleId: multipleId,
                            }) ?? 0;

                          const hurdle = getCalculatedProjectValue('OrdinaryEquityHurdle', {
                            caseId: caseItem.caseId,
                            forecastId: forecast.forecastId,
                            instrumentId: instrument.instrumentId,
                            trancheId: tranche.id,
                          });

                          return (
                            <CellValue
                              alignment={Alignment.Right}
                              textTransform={TextTransform.Uppercase}
                              value={hurdle === 0 || distributions > 0}
                            />
                          );
                        }}
                      />
                      {isIncrementalHurdleUsed && (
                        <TableSectionValuesPerMultiple
                          title="Ordinary equity post hurdle"
                          renderCell={({ caseItem, forecast, multipleId }) => (
                            <CellValue
                              {...amountRules}
                              value={getCalculatedProjectValue('OrdinaryEquityPostHurdle', {
                                caseId: caseItem.caseId,
                                forecastId: forecast.forecastId,
                                instrumentId: instrument.instrumentId,
                                trancheId: tranche.id,
                                multipleId: multipleId,
                              })}
                            />
                          )}
                        />
                      )}
                      <TableSectionValuesPerMultiple
                        title="% participation"
                        renderCell={({ caseItem, forecast, multipleId }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getCalculatedProjectValue('TrancheParticipationPercentage', {
                                caseId: caseItem.caseId,
                                forecastId: forecast.forecastId,
                                instrumentId: instrument.instrumentId,
                                trancheId: tranche.id,
                                multipleId,
                              })
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerMultiple
                        title="Distributions paid"
                        renderCell={({ caseItem, forecast, multipleId }) => (
                          <CellValue
                            {...amountRules}
                            value={getCalculatedProjectValue('TrancheDistributionsPaid', {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: instrument.instrumentId,
                              trancheId: tranche.id,
                              multipleId: multipleId,
                            })}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title="Weighted distributions"
                        highlight
                        renderSpacer={false}
                        additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...amountRules}
                            value={getCalculatedProjectValue(
                              'TrancheWeightedAveragePaidDistributions',
                              {
                                caseId: caseItem.caseId,
                                forecastId: forecast.forecastId,
                                instrumentId: instrument.instrumentId,
                                trancheId: tranche.id,
                              }
                            )}
                          />
                        )}
                      />
                      <TableSpacerRow />
                    </Fragment>
                  );
                })}
                {(instrument.tranches?.length ?? 0) > 1 ? (
                  <>
                    <tr>
                      <th className="table-primary__cell--header-tertiary">
                        Total distributions to {instrument.instrumentNarrative}
                      </th>
                      <th
                        className="table-primary__cell--header-tertiary"
                        colSpan={columnsCount - 1}
                      />
                    </tr>
                    <TableSectionValuesPerMultiple
                      title="Total distributions"
                      renderCell={({ caseItem, forecast, multipleId }) => (
                        <CellValue
                          {...amountRules}
                          value={getCalculatedProjectValue('InstrumentDistributionsPaid', {
                            caseId: caseItem.caseId,
                            forecastId: forecast.forecastId,
                            instrumentId: instrument.instrumentId,
                            multipleId: multipleId,
                          })}
                        />
                      )}
                    />
                    <TableSectionValuesPerForecast
                      title="Weighted distributions"
                      highlight
                      additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      renderCell={({ caseItem, forecast }) => (
                        <CellValue
                          {...amountRules}
                          value={getCalculatedProjectValue(
                            'InstrumentWeightedAveragePaidDistributions',
                            {
                              caseId: caseItem.caseId,
                              forecastId: forecast.forecastId,
                              instrumentId: instrument.instrumentId,
                            }
                          )}
                        />
                      )}
                    />
                  </>
                ) : undefined}
              </>
            )}
          </Fragment>
        ))}
        <WaterfallTotalPayoutSummaryPartialTable columnsCount={columnsCount} />
      </tbody>
    </>
  );
};

export default WaterfallOrdinaryEquityPartialTable;
