import { SVGProps } from 'react';
const SvgThreeDots = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 5" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M13.442 2.654a1.904 1.904 0 1 1 3.809 0 1.904 1.904 0 0 1-3.809 0Zm-6.346 0a1.904 1.904 0 1 1 3.809 0 1.904 1.904 0 0 1-3.809 0Zm-2.538 0a1.904 1.904 0 1 1-3.808 0 1.904 1.904 0 0 1 3.808 0Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgThreeDots;
