import { AxiosInstance, AxiosResponse } from 'axios';
import store from '@core/store/store';
import * as notificationActions from '@core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import env from '@environment';
import { StatusCodes } from '@app/shared/enums/status-codes';
import { RootAppRoutes } from '@app/shared/enums/root-app-routes';
import {
  CodedDomainException,
  ErrorMessage,
  FluentValidationErrorData,
  ModelValidationError,
  ErrorWrappedWithValidationResult,
  ResponseWrappedWithValidationResult,
  FluentValidationErrorSeverity,
} from '@app/shared/interfaces/error-message';
import { globalNavigate } from '@app/shared/components/global-navigate/GlobalNavigate';
import i18n from 'i18next';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { error } from 'highcharts';

const getDefaultErrorMessage = (error: ErrorMessage | CodedDomainException) =>
  error.response?.data?.message ?? error.message ?? 'Something went wrong.';

const getErrorMessage = (error: ErrorMessage | ErrorWrappedWithValidationResult) => {
  if (isErrorWrappedWithValidationResult(error)) {
    return filterOutValidationInfoMessages(error.response.data.validation.errors)[0].errorMessage;
  }

  if (isCodedDomainException(error)) {
    return handleCodedDomainException(error);
  }

  if (isModelValidationError(error)) {
    return (
      error.response?.data?.data?.['custom']?.[0] ?? getDefaultErrorMessage(error) // backend returns custom validations under the 'custom' key
    );
  }

  return getDefaultErrorMessage(error);
};

function isModelValidationError(
  error: ErrorMessage | ModelValidationError
): error is ModelValidationError {
  return error.response.data.type === 'PwcDeals.Api.Responses.InvalidModelState';
}

function isErrorWrappedWithValidationResult(
  error: ErrorMessage | ErrorWrappedWithValidationResult
): error is ErrorWrappedWithValidationResult {
  const wrappedError: ErrorWrappedWithValidationResult = error as ErrorWrappedWithValidationResult;
  return Array.isArray(wrappedError.response?.data?.validation?.errors);
}

function isResponseWrappedWithValidationResult(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: AxiosResponse<any, any> | ResponseWrappedWithValidationResult
): response is ResponseWrappedWithValidationResult {
  const wrappedResponse: ResponseWrappedWithValidationResult =
    response as ResponseWrappedWithValidationResult;
  return Array.isArray(wrappedResponse.data?.validation?.errors);
}

const isCodedDomainException = (
  error: ErrorMessage | CodedDomainException
): error is CodedDomainException => {
  return error.response.data.type === 'PwcDeals.Core.Exceptions.CodedDomainException';
};

const handleCodedDomainException = (error: CodedDomainException) => {
  const errorData = error.response.data;
  if (errorData.errorCode === 2012) {
    return i18n.t('_TheCapValueYouProvidedResultsInAllPointsBeingRemovedByCollaring');
  } else if (errorData.errorCode === 2007) {
    return i18n.t('_ParticipationGrossUpsOutOfOrderError');
  } else {
    return i18n.t(`_CodedDomainErrorCodeAndTitle`, {
      ErrorCode: errorData.errorCode,
      ErrorTitle: errorData.title,
    });
  }
};

const filterOutValidationInfoMessages = (errors: FluentValidationErrorData[]) =>
  errors.filter((e) => e.severity !== FluentValidationErrorSeverity.Info);

const dispatchValidationErrors = (calcMethod: CalcMethod, errors: [FluentValidationErrorData]) => {
  const errorList = filterOutValidationInfoMessages(errors).map(
    (individualError: FluentValidationErrorData) => individualError.errorMessage
  );
  const uniqueErrorList = [...new Set(errorList.map((errorMessage) => errorMessage))];

  if (calcMethod === CalcMethod.OPM || calcMethod === CalcMethod.PWERM_AND_OPM) {
    store.dispatch(notificationActions.setOpmErrorMessageList(uniqueErrorList));
  }
  if (calcMethod === CalcMethod.PWERM || calcMethod === CalcMethod.PWERM_AND_OPM) {
    store.dispatch(notificationActions.setPwermErrorMessageList(uniqueErrorList));
  }
};

const registerApiInterceptor = (api: AxiosInstance) => {
  api.interceptors.request.use(
    (request) => {
      return request;
    },
    (error: ErrorMessage) => {
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: getErrorMessage(error),
          errorInstanceId: error.response.data.instance,
          autoHide: true,
        })
      );
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response: AxiosResponse) => {
      if (isResponseWrappedWithValidationResult(response) && !response.data.validation.isValid) {
        store.dispatch(
          notificationActions.showNotificationSnackbar({
            severity: SeverityType.warning,
            message: response.data.validation.errors[0].errorMessage,
            autoHide: true,
            errorCode: response.status,
            errorInstanceId: response.data.instance,
          })
        );
      }

      return response;
    },
    async (error) => {
      // convert from Blob response type if applicable
      if (error.request?.responseType === 'blob' && error.response?.data instanceof Blob) {
        const errorData = JSON.parse(await error.response.data.text());
        error.response.data = errorData;
      }

      setTimeout(() => {
        // timeout is dedicated to delay handleErrorCases and ensure, that navigate will be available outside of the react app, do not remove it.
        // in this case, calls which are triggered before the first render, will be handled properly
        handleErrorCases(error);
      }, 0);

      if (isErrorWrappedWithValidationResult(error)) {
        dispatchValidationErrors(
          error.response.data.calculationMethod,
          error.response.data.validation.errors
        );
      } else if (isModelValidationError(error)) {
        store.dispatch(notificationActions.setOpmErrorMessageList(undefined));
      }

      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.error,
          message: getErrorMessage(error),
          autoHide: true,
          errorCode: error.response.status,
          errorInstanceId: error.response.data.instance,
        })
      );
      return Promise.reject({
        ...error,
        message: getErrorMessage(error),
      });
    }
  );
};

const handleErrorCases = (error: any) => {
  const isProjectDraftLoaded = store.getState().project.projectDraft.id;
  if (error.response.status === StatusCodes.Unauthorized) {
    document.location.href = `${env.apiUrl}/account/login?redirectUri=${document.URL}`;
  } else if (error.response.status === StatusCodes.Forbidden) {
    isProjectDraftLoaded
      ? store.dispatch(notificationActions.showNotificationModal())
      : (store.dispatch(notificationActions.closeNotification()),
        globalNavigate(RootAppRoutes.Forbidden));
  } else if (error.response.status === StatusCodes.NotFound) {
    isProjectDraftLoaded
      ? store.dispatch(notificationActions.showNotificationModal())
      : (store.dispatch(notificationActions.closeNotification()),
        globalNavigate(RootAppRoutes.NotFound));
  } else if (error.response.status === StatusCodes.InternalServerError) {
    isProjectDraftLoaded
      ? store.dispatch(notificationActions.showNotificationModal())
      : (store.dispatch(notificationActions.closeNotification()),
        globalNavigate(RootAppRoutes.Error));
  }
};

export default registerApiInterceptor;
