import { FC, useEffect, useRef } from 'react';
import { selectCasesStructure } from '@core/store/project-slice-selectors';
import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import TableScreenshotAction from '@app/shared/components/table/TableScreenshotAction';
import WaterfallTableHeader from './WaterfallTableHeader';
import WaterfallExitMultiplesPartialTable from './exit-multiples/WaterfallExitMultiplesPartialTable';
import WaterfallEnterpriseValuePartialTable from './enterprise-value/WaterfallEnterpriseValuePartialTable';
import WaterfallNetExitProceedsPartialTable from './net-exit-proceeds/WaterfallNetExitProceedsPartialTable';
import WaterfallTotalEquityProceedsPartialTable from './total-equity-proceeds/WaterfallTotalEquityProceedsPartialTable';
import WaterfallShareholderDebtDistributionsPartialTable from './shareholder-debt-distributions/WaterfallShareholderDebtDistributionsPartialTable';
import WaterfallValuationPartialTable from './valuation/WaterfallValuationPartialTable';
import WaterfallOrdinaryEquityPartialTable from './ordinary-equity/WaterfallOrdinaryEquityPartialTable';
import WaterfallCrossCheckPartialTable from './cross-check/WaterfallCrossCheckPartialTable';
import { removeFileIllegalCharacters } from '@app/shared/helpers/remove-file-illegal-characters';
import { useDownloadExcel } from '@app/shared/components/excel-exporter/ExcelExporter';
import { dateFormatter } from '@app/shared/formatters';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { WATERFALL_SECTION_OPTIONS } from '@app/shared/constants/dropdown-values/waterfall-sections';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { Navigate, generatePath } from 'react-router';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';

interface WaterfallTableProps {
  shouldRenderInnerContent?: boolean;
  excelExportEnabled?: boolean;
  removeStickyColumns?: boolean;
  getRef?: (ref: HTMLTableElement) => void;
}

const WaterfallTable: FC<WaterfallTableProps> = ({
  shouldRenderInnerContent = true,
  excelExportEnabled = false,
  removeStickyColumns = false,
  getRef,
}): JSX.Element => {
  const { l } = useLocale();
  const tableRef = useRef<HTMLTableElement>(null);
  const casesStructure = useAppSelector(selectCasesStructure);
  casesStructure.sort((a, b) => a.caseOrder! - b.caseOrder!);

  const project = useAppSelector((state) => state.project);

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${removeFileIllegalCharacters(project.projectDraft?.name)}_Waterfall_${dateFormatter(
      new Date().toString(),
      {
        dateStyle: 'short',
      }
    )}`,
    sheet: 'Waterfall PWERM',
  });

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: {
      scrollToSection: null,
    },
  });

  const { handleSubmit, getValues } = formMethods;

  useEffect(() => {
    if (shouldRenderInnerContent && excelExportEnabled) {
      onDownload();
    }
    // effect tracks only one dependency
  }, [shouldRenderInnerContent]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitData = () => {
    if (getValues() && getValues().scrollToSection) {
      const scrollToSection = document.getElementById(
        (getValues().scrollToSection ?? '').toString()
      );
      scrollToSection?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (
    project.projectDraft.details.calcMethod === CalcMethod.OPM &&
    location.pathname.includes('waterfall-pwerm')
  ) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <div className="main-container-padding-remove">
      <div className="main-container__additional-actions">
        <TableScreenshotAction
          tableRef={tableRef}
          testIdString="save-waterfall-image"
          filePrefix={`Waterfall_PWERM_${removeFileIllegalCharacters(project.projectDraft?.name)}_`}
        />
        <FormProvider {...formMethods}>
          <form
            onChange={handleSubmit(submitData, submitData)}
            className="main-container__section-selector">
            <FormSelect
              ariaLabel={l('_SelectorAriaLabel', {
                label: l('_WaterfallSection'),
              })}
              name="scrollToSection"
              required
              options={WATERFALL_SECTION_OPTIONS}
              isGapless
              ignoreReadOnly
            />
          </form>
        </FormProvider>
      </div>
      <table
        ref={tableRef}
        className={`table-primary table-primary--zebra table-primary--framed table-primary--title-column-nowrap table-primary--waterfall ${
          removeStickyColumns ? '' : 'table-primary--sticky-first-column'
        }`}
        data-testid="waterfall-table">
        {shouldRenderInnerContent && (
          <>
            <colgroup>
              <col className={styles['labels-col']} />
              <col style={{ minWidth: '80px' }} />
              {[...Array(columnsCount - 2)].map((_, index) => (
                <col key={`col-${index}`} />
              ))}
            </colgroup>
            <WaterfallTableHeader />
            <WaterfallExitMultiplesPartialTable columnsCount={columnsCount} />
            <WaterfallEnterpriseValuePartialTable columnsCount={columnsCount} />
            <WaterfallNetExitProceedsPartialTable columnsCount={columnsCount} />
            <WaterfallTotalEquityProceedsPartialTable columnsCount={columnsCount} />
            <WaterfallShareholderDebtDistributionsPartialTable columnsCount={columnsCount} />
            <WaterfallOrdinaryEquityPartialTable columnsCount={columnsCount} />
            <WaterfallValuationPartialTable columnsCount={columnsCount} />
            <WaterfallCrossCheckPartialTable columnsCount={columnsCount} />
          </>
        )}
      </table>
    </div>
  );
};

export default WaterfallTable;
