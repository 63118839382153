import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';

export const DealThesisSpacerRow: FC = (): JSX.Element => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  return (
    <tr>
      <th className="table-primary__cell--vertical-separator table-primary__cell--section-separator" />
      <td className="table-primary__cell--section-separator" />
      {caseData?.forecasts.map((forecast, index) => (
        <td key={index + 'spacer-row'} className="table-primary__cell--section-separator" />
      ))}
      <td className="table-primary__cell--section-separator" />
    </tr>
  );
};
