import { FC } from 'react';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useFormContext } from 'react-hook-form';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { percentageValidator, requiredValidator } from '@core/validations/hook-forms/validators';
import { getCalculatedValueForForecast } from '@core/store/project-slice-selectors';
import { ProjectDto, ForecastInputDto } from '@app/shared/models/contracts/project-dto';
import { PwermCalculatedVariables } from '@app/shared/models/contracts/pwerm-calculation-results-dto';
import { PredefinedVariableNames } from '@app/shared/models/contracts/enums/shared-enums';
import { useParams } from 'react-router-dom';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { DealThesisSpacerRow } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/DealThesisSpacerRow';
import Button from '@app/shared/components/button/Button';
import SvgFilledRightArrow from '@app/shared/icons/FilledRightArrow';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { DealThesisFormModel } from '@app/modules/projects/inputs/deal-thesis/DealThesisPage';
import styles from '../../deal-thesis-page.module.scss';

interface ExitCostsPartialTableProps {
  handleDataSubmit: () => void;
}

const ExitCostsPartialTable: FC<ExitCostsPartialTableProps> = ({
  handleDataSubmit,
}): JSX.Element => {
  const { caseId } = useParams();
  const formMethods = useFormContext<DealThesisFormModel>();
  const project = useAppSelector((state) => state.project.projectDraft);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const { calculatedVariables } = useAppSelector((state) => state.pwermCalculation.values);
  const calculatedValuesResult = calculatedVariables?.result;
  const getTransactionCostOfEvAndIPODiscountOfEvSumMessage = (
    project: ProjectDto,
    calculatedValuesResult: PwermCalculatedVariables,
    caseId: string,
    forecastId: string
  ) => {
    const value1 = getCalculatedValueForForecast(
      project,
      calculatedValuesResult,
      caseId,
      forecastId,
      PredefinedVariableNames.TransactionCostsOfEV
    );

    const value2 = getCalculatedValueForForecast(
      project,
      calculatedValuesResult,
      caseId,
      forecastId,
      PredefinedVariableNames.IPODiscountOfEV
    );
    const sumOfProperties = value1 + value2;
    return sumOfProperties > 100
      ? `${PredefinedVariableNames.TransactionCostsOfEV} and ${
          PredefinedVariableNames.IPODiscountOfEV
        } add up to ${numberValueFormatter({
          value: sumOfProperties,
          ...percentageValueFormatterProps,
        })}, this must be less than ${numberValueFormatter({
          value: 100,
          ...percentageValueFormatterProps,
        })}.`
      : undefined;
  };

  const maxColspanCount =
    (caseData?.forecasts ? caseData?.forecasts.length : 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const forecastYears = caseData?.forecasts.length ?? 0;

  const populate = (inputField: string, cellIndex: number) => {
    const { ...updatedDealThesisInput } = formMethods.getValues();
    updatedDealThesisInput.forecasts.map((_, index: number) => {
      index >= cellIndex &&
        formMethods.setValue(
          `forecasts.${index}.inputs.${inputField as keyof ForecastInputDto}`,
          updatedDealThesisInput.forecasts[cellIndex].inputs[inputField as keyof ForecastInputDto]
        );
    });

    handleDataSubmit();
  };

  const shouldAutoPopulateFromCell = (index: number): boolean | undefined =>
    index !== forecastYears - 1;

  return (
    <>
      <tbody>
        {caseId && calculatedValuesResult && (
          <>
            <tr>
              <th className="table-primary__cell--header" colSpan={maxColspanCount}>
                Exit Costs
              </th>
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator">Probability of IPO</th>
              <td />
              {caseData?.forecasts.map((forecast, index) => (
                <td key={index + 'propability-ipo'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`forecasts.${index}.inputs.probabilityOfIpo`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...requiredValidator,
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['deal-thesis-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('probabilityOfIpo', index)}
                      data-testid={`populate-probabilityOfIpo-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator">
                Probability of Secondary/Trade sale
              </th>
              <td />
              {caseData?.forecasts.map((forecast, index) => (
                <td key={index + 'trade-sale'}>
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={getCalculatedValueForForecast(
                      project,
                      calculatedValuesResult,
                      caseId,
                      forecast.forecastId,
                      PredefinedVariableNames.ProbabilityOfSecondaryTradeSale
                    )}
                  />
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator">IPO Transaction Costs</th>
              <td />
              {caseData?.forecasts.map((_, index) => (
                <td key={index + 'ipo-transactions'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`forecasts.${index}.inputs.ipoTransactionCosts`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...requiredValidator,
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['deal-thesis-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('ipoTransactionCosts', index)}
                      data-testid={`populate-ipoTransactionCosts-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator">
                Secondary/Trade sale transaction cost
              </th>
              <td />
              {caseData?.forecasts.map((_, index) => (
                <td key={index + 'trade-sale-transactions'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`forecasts.${index}.inputs.tradeSaleTransactionCost`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...requiredValidator,
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['deal-thesis-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('tradeSaleTransactionCost', index)}
                      data-testid={`populate-tradeSaleTransactionCost-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                <strong>Transaction costs (% of EV)</strong>
              </th>
              <td className="table-primary__cell--section-start-separator" />
              {caseData?.forecasts.map((forecast, index) => (
                <td
                  key={index + 'transaction-costs'}
                  className="table-primary__cell--section-start-separator">
                  <CellValue
                    {...enteredPercentageViewRules}
                    warningMessage={getTransactionCostOfEvAndIPODiscountOfEvSumMessage(
                      project,
                      calculatedValuesResult,
                      caseId,
                      forecast.forecastId
                    )}
                    tooltipVariation={GridFieldTooltipVariation.FitContainer}
                    value={getCalculatedValueForForecast(
                      project,
                      calculatedValuesResult,
                      caseId,
                      forecast.forecastId,
                      PredefinedVariableNames.TransactionCostsOfEV
                    )}
                    strong
                  />
                </td>
              ))}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <DealThesisSpacerRow />
            <tr>
              <th className="table-primary__cell--vertical-separator">IPO discount</th>
              <td />
              {caseData?.forecasts.map((forecast, index) => (
                <td key={index + 'ipo-discount'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`forecasts.${index}.inputs.ipoDiscount`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...requiredValidator,
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['deal-thesis-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('ipoDiscount', index)}
                      data-testid={`populate-ipoDiscount-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator">Hypothetical debt at IPO</th>
              <td />
              {caseData?.forecasts.map((_, index) => (
                <td key={index + 'hypohotetical-debt'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`forecasts.${index}.inputs.hypotheticalDebtAtIpo`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...requiredValidator,
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['deal-thesis-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('hypotheticalDebtAtIpo', index)}
                      data-testid={`populate-hypotheticalDebtAtIpo-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator">% of equity sold in IPO</th>
              <td />
              {caseData?.forecasts.map((_, index) => (
                <td key={index + 'percent-of-equity'} className={styles['hoverable-cell']}>
                  <GridTextField
                    className={[
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`forecasts.${index}.inputs.percentOfEquitySoldInIpo`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...requiredValidator,
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['deal-thesis-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('percentOfEquitySoldInIpo', index)}
                      data-testid={`populate-percentOfEquitySoldInIpo-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                <strong>IPO discount (% of EV)</strong>
              </th>
              <td className="table-primary__cell--section-start-separator" />
              {caseData?.forecasts.map((forecast, index) => (
                <td
                  key={index + 'ipo-discount-of-ev'}
                  className="table-primary__cell--section-start-separator">
                  <CellValue
                    {...enteredPercentageViewRules}
                    warningMessage={getTransactionCostOfEvAndIPODiscountOfEvSumMessage(
                      project,
                      calculatedValuesResult,
                      caseId,
                      forecast.forecastId
                    )}
                    tooltipVariation={GridFieldTooltipVariation.FitContainer}
                    strong
                    value={getCalculatedValueForForecast(
                      project,
                      calculatedValuesResult,
                      caseId,
                      forecast.forecastId,
                      PredefinedVariableNames.IPODiscountOfEV
                    )}
                  />
                </td>
              ))}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <DealThesisSpacerRow />
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                Post IPO lock-in discount
              </th>
              <td className="table-primary__cell--section-start-separator" />
              {caseData?.forecasts.map((forecast, index) => (
                <td
                  key={index + 'post-ipo-sale-restrictions'}
                  className={`table-primary__cell--section-start-separator ${styles['hoverable-cell']}`}>
                  <GridTextField
                    className={[
                      shouldAutoPopulateFromCell(index) ? styles[`padding-right-hover`] : '',
                    ]}
                    name={`forecasts.${index}.inputs.dlomForPostIpoSaleRestrictions`}
                    {...percentageFieldFormattingProps}
                    rules={{
                      ...percentageValidator,
                      ...requiredValidator,
                    }}
                    alignment={Alignment.Right}
                  />
                  {shouldAutoPopulateFromCell(index) && (
                    <Button
                      startIcon={<SvgFilledRightArrow />}
                      className={styles['deal-thesis-populate-button']}
                      appearance={ButtonAppearance.CLEAN}
                      size={ButtonSize.FLUID}
                      // stop the onBlur event occurring so we can control when the data is submitted
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => populate('dlomForPostIpoSaleRestrictions', index)}
                      data-testid={`populate-dlomForPostIpoSaleRestrictions-${index}`}
                      autoIconSize
                    />
                  )}
                </td>
              ))}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                Implied equity retained on IPO
              </th>
              <td className="table-primary__cell--section-start-separator" />
              {caseData?.forecasts.map((forecast, index) => (
                <td
                  key={index + 'implied-equity-retained'}
                  className="table-primary__cell--section-start-separator">
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={getCalculatedValueForForecast(
                      project,
                      calculatedValuesResult,
                      caseId,
                      forecast.forecastId,
                      PredefinedVariableNames.ImpliedEquityRetainedOnIPO
                    )}
                  />
                </td>
              ))}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator">
                <strong>Probability Weighted Post IPO lock-in discount</strong>
              </th>
              <td className="table-primary__cell--section-start-separator" />
              {caseData?.forecasts.map((forecast, index) => (
                <td
                  key={index + 'prop-weighted'}
                  className="table-primary__cell--section-start-separator">
                  <CellValue
                    {...enteredPercentageViewRules}
                    strong
                    value={getCalculatedValueForForecast(
                      project,
                      calculatedValuesResult,
                      caseId,
                      forecast.forecastId,
                      PredefinedVariableNames.ProbabilityWeightedDLOMForPostIPOSaleRestrictions
                    )}
                  />
                </td>
              ))}
              <td className="table-primary__cell--section-start-separator" />
            </tr>
            <DealThesisSpacerRow />
          </>
        )}
      </tbody>
    </>
  );
};

export default ExitCostsPartialTable;
