import { SVGProps } from 'react';
const SvgFilledRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 48" fill="none" role="img" {...props}>
    <path
      d="M0 0H30C31.1046 0 32 0.895431 32 2V46C32 47.1046 31.1046 48 30 48H0V0Z"
      fill="#D04A02"
    />
    <path
      d="M22.0958 23.36L16.5758 17.864C16.4798 17.768 16.4318 17.656 16.4318 17.528C16.4318 17.384 16.4798 17.264 16.5758 17.168C16.6718 17.056 16.7838 17 16.9118 17C17.0558 17 17.1758 17.048 17.2718 17.144L23.9918 23.864L17.2718 30.584C17.1758 30.68 17.0558 30.728 16.9118 30.728C16.7838 30.728 16.6718 30.68 16.5758 30.584C16.4798 30.488 16.4318 30.376 16.4318 30.248C16.4318 30.104 16.4798 29.984 16.5758 29.888L22.0958 24.368H8.51181C8.36781 24.368 8.24781 24.32 8.15181 24.224C8.05581 24.128 8.00781 24.016 8.00781 23.888C8.00781 23.744 8.05581 23.624 8.15181 23.528C8.24781 23.416 8.36781 23.36 8.51181 23.36H22.0958Z"
      fill="white"
    />
  </svg>
);
export default SvgFilledRightArrow;
