import { FC, Fragment, useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import * as projectActions from '@core/store/project-slice';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { cloneDeep, enumKeyByValue, groupBy, instrumentOwnersSortFn } from '@app/shared/helpers';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { swapMaskForValueDisplay } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import {
  greaterThanIfValidator,
  positiveValueValidator,
  requiredValidator,
  uniqueNarrativePerEquityInstrumentType,
} from '@core/validations/hook-forms/validators';
import styles from './capital-structure-item-form.module.scss';
import buttonStyles from '@app/shared/components/button/button.module.scss';
import SvgArrowUp from '@app/shared/icons/ArrowUp';
import SvgArrowDown from '@app/shared/icons/ArrowDown';
import Button from '@app/shared/components/button/Button';
import classNames from 'classnames';
import SvgTabArrow from '@app/shared/icons/TabArrow';
import SvgTabArrowActive from '@app/shared/icons/TabArrowActive';
import { Toggle } from '@app/shared/components/toggle/Toggle';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import {
  numericBaseFieldFormattingProps,
  numericBasicFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { setCapitalStructureBuildStructure } from '@app/core/store/ui-values-slice';

interface CapitalStructureItemFormProps {
  equityItem: EquityInstrumentDto;
}

const CapitalStructureItemForm: FC<CapitalStructureItemFormProps> = ({
  equityItem,
}): JSX.Element => {
  const projectDraft = useAppSelector((state) => state.project.projectDraft);
  const storedActiveTabs = useAppSelector(
    (state) => state.uiValues.userSelections.capitalStructure.buildStructureOwnerTabs
  );
  const [currentEquityId, setCurrentEquityId] = useState<string>(equityItem.instrumentId);
  const indexOfInstitutionItem = Object.keys(OwnerType).findIndex(
    (label) => label === enumKeyByValue(OwnerType, OwnerType.Institution)
  );
  const [activeTab, setActiveTab] = useState<number | undefined>(
    storedActiveTabs[currentEquityId] ?? indexOfInstitutionItem
  );
  const dispatch = useAppDispatch();
  const instrumentNarrativeFieldName = 'instrumentNarrative';
  const focusFieldName = `ownership[${indexOfInstitutionItem}].amount`;
  const formMethods = useForm<any>({
    ...formConfigBase,
    defaultValues: {
      ...{ ...equityItem },
    },
  });

  const { trigger } = formMethods;

  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    trigger();
  }, [trigger]);

  const ordinaryEquityKey = enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);

  useEffect(() => {
    trigger();
    if (currentEquityId !== equityItem.instrumentId) {
      setCurrentEquityId(equityItem.instrumentId);
      formMethods.reset(equityItem);
      formMethods.setFocus(focusFieldName);
      setActiveTab(storedActiveTabs[equityItem.instrumentId] ?? indexOfInstitutionItem);
    }
  }, [currentEquityId, equityItem, formMethods]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    formMethods.setFocus(focusFieldName);
  }, [formMethods]); // eslint-disable-line react-hooks/exhaustive-deps

  const normalizeRanks = (
    updatedEquityInstruments: EquityInstrumentDto[]
  ): EquityInstrumentDto[] => {
    const isOrdinaryEquity = equityItem.type === ordinaryEquityKey;

    const equityInstruments = updatedEquityInstruments.filter(
      (instrument) => instrument.type === ordinaryEquityKey
    );
    const slnAndPrefSharesInstruments = updatedEquityInstruments.filter(
      (instrument) => instrument.type !== ordinaryEquityKey
    );

    const rankStartingNumber = 1;
    const instrumentsGroup = isOrdinaryEquity ? equityInstruments : slnAndPrefSharesInstruments;
    const instrumentsGroupById = groupBy(instrumentsGroup, (instrument) => instrument.rank);
    const instrumentsGroupWithResetRanks = Object.values(instrumentsGroupById).flatMap(
      (instrumentsList, index) =>
        instrumentsList.map((instrument) => ({ ...instrument, rank: index + rankStartingNumber }))
    );

    return [
      ...(!isOrdinaryEquity ? equityInstruments : slnAndPrefSharesInstruments),
      ...instrumentsGroupWithResetRanks,
    ];
  };

  const submitForm = async (rankValue?: number) => {
    const updatedEquityInstruments = projectDraft.equityInstruments.map((instrument) => {
      if (instrument.instrumentId !== equityItem.instrumentId) {
        return instrument;
      }

      return {
        ...cloneDeep(formMethods.getValues()),
        rank: rankValue ?? equityItem.rank,
      };
    });

    const updatedProjectDraft = {
      ...projectDraft,
      equityInstruments:
        rankValue || rankValue === 0
          ? normalizeRanks(updatedEquityInstruments)
          : updatedEquityInstruments,
    };

    await dispatch(projectActions.updateProjectDraft({ project: updatedProjectDraft })).unwrap();
  };

  const formSubmitHandler = async () => {
    if (!isReadOnly) {
      await submitForm();
    }
  };

  const handleOpenedTab = (index: number) => {
    index !== activeTab ? setActiveTab(index) : setActiveTab(undefined);
    dispatch(setCapitalStructureBuildStructure({ key: currentEquityId, value: index }));
  };

  const getOwnersBySequence = equityItem.ownership
    .slice()
    .sort((ownerA, ownerB) => instrumentOwnersSortFn(ownerA.owner, ownerB.owner));

  const filterRelevantInstruments = () => {
    const filterCondition =
      equityItem.type === ordinaryEquityKey
        ? (i: EquityInstrumentDto) => i.type === ordinaryEquityKey
        : (i: EquityInstrumentDto) => i.type !== ordinaryEquityKey;

    return projectDraft.equityInstruments
      .filter((i) => i.instrumentId !== equityItem.instrumentId)
      .filter((i) => filterCondition(i));
  };

  const getNextRank = () => {
    const instrumentGroup = filterRelevantInstruments()
      .map((i) => i.rank)
      .filter((r) => r > equityItem.rank);

    if (instrumentGroup.length < 1) {
      return equityItem.rank + 1;
    }

    return Math.min(...instrumentGroup);
  };

  const getPreviousRank = () => {
    const instrumentGroup = filterRelevantInstruments()
      .map((i) => i.rank)
      .filter((r) => r < equityItem.rank);

    if (instrumentGroup.length < 1) {
      return equityItem.rank - 1;
    }

    return Math.max(...instrumentGroup, 0);
  };

  const canIncreaseRank = () => {
    return (
      projectDraft.equityInstruments.length > 0 &&
      filterRelevantInstruments().some((i) => i.rank >= equityItem.rank)
    );
  };

  const canDecreaseRank = () => {
    return (
      projectDraft.equityInstruments.length > 0 &&
      equityItem.rank > 0 &&
      filterRelevantInstruments().some((i) => i.rank <= equityItem.rank)
    );
  };

  const disableIncreaseButton = !canIncreaseRank();
  const disableDecreaseButton = !canDecreaseRank();
  const nextRankValue = getNextRank();
  const previousRankValue = getPreviousRank();

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          data-cy="capital-structure-form"
          onBlur={formMethods.handleSubmit(
            formSubmitHandler as SubmitHandler<EquityInstrumentDto>,
            formSubmitHandler as SubmitErrorHandler<EquityInstrumentDto>
          )}>
          <h2 className="heading-5">Instrument Details</h2>
          <FormField
            name={instrumentNarrativeFieldName}
            label="Instrument Narrative"
            required
            rules={{
              ...requiredValidator,
              ...uniqueNarrativePerEquityInstrumentType(projectDraft.equityInstruments, equityItem),
            }}
            isInputMasked={false}
            fieldValueMask={swapMaskForValueDisplay}
          />
          <div className={styles['controls-container']}>
            {ordinaryEquityKey !== equityItem.type && (
              <div className={styles['control']}>
                <span className={styles['control__label']}>Rank</span>
                <div className={styles['control__item']}>
                  <div className={styles['rank']}>
                    <Button
                      appearance={ButtonAppearance.ROUND}
                      onClick={() => submitForm(nextRankValue)}
                      disabled={disableIncreaseButton}
                      endIcon={<SvgArrowUp className={styles['rank__button-icon']} />}
                      data-testid="rank-up"
                    />
                    <Button
                      appearance={ButtonAppearance.ROUND}
                      onClick={() => submitForm(previousRankValue)}
                      disabled={disableDecreaseButton}
                      endIcon={<SvgArrowDown className={styles['rank__button-icon']} />}
                      data-testid="rank-down"
                    />
                  </div>
                </div>
              </div>
            )}
            {ordinaryEquityKey === formMethods.getValues().type && (
              <div className={styles['control']}>
                <span className={styles['control__label']}>Sweet equity</span>
                <div className={styles['control__item']}>
                  <Toggle
                    name="isSweetEquity"
                    label="Sweet equity"
                    isLabelHidden
                    onChange={() => submitForm()}
                  />
                </div>
              </div>
            )}
            <div className={styles['control']}>
              <span className={styles['control__label']}>Value instrument</span>
              <div className={styles['control__item']}>
                <Toggle name="shouldBeValued" label="Value instrument" isLabelHidden />
              </div>
            </div>
          </div>
          <>
            <div className={classNames(styles['capital-structure-form__title'])}>
              <h2 className="heading-5">Owners</h2>
            </div>
            {getOwnersBySequence.map((item) => {
              const index = equityItem.ownership.findIndex((i) => i.owner === item?.owner);
              return (
                <Fragment key={`cp-item-${index}`}>
                  {item && (
                    <div key={item.owner}>
                      <div className={classNames(styles['capital-structure-form__tab'])}>
                        <Button
                          data-testid="owner-tab"
                          appearance={ButtonAppearance.ACCORDION_TAB}
                          size={ButtonSize.FULL_WIDTH}
                          ignoreReadOnly
                          className={classNames({
                            [buttonStyles['button--accordion-tab-active']]: activeTab === index,
                            [buttonStyles['button--accordion-tab-non-empty']]:
                              Number(equityItem.ownership[index].amount) > 0,
                          })}
                          onClick={() => handleOpenedTab(index)}>
                          {OwnerType[item.owner]}
                          <div className={classNames(styles['capital-structure-form__tab-action'])}>
                            {activeTab === index ? (
                              <SvgTabArrowActive />
                            ) : Number(equityItem.ownership[index].amount) > 0 ? (
                              <div
                                className={classNames(
                                  styles['capital-structure-form__icon-active']
                                )}>
                                <SvgTabArrowActive />
                              </div>
                            ) : (
                              <SvgTabArrow />
                            )}
                          </div>
                        </Button>
                      </div>
                      <div
                        className={classNames(styles['capital-structure-form__tab-content'], {
                          [styles['capital-structure-form__tab-content--open']]:
                            activeTab === index,
                        })}>
                        <FormField
                          name={`ownership[${index}].amount`}
                          label="Amount Invested"
                          data-testid="instrument-amount"
                          placeholder="0"
                          required
                          {...numericBaseFieldFormattingProps}
                          rules={{
                            validate: {
                              isGreaterThanIf: greaterThanIfValidator(
                                0,
                                formMethods.getValues() as EquityInstrumentDto,
                                (i) => i.ownership.every((o) => Number(o.amount ?? 0) <= 0)
                              ).validate,
                              isPositive: positiveValueValidator(false).validate,
                            },
                          }}
                        />
                        <FormField
                          placeholder="0"
                          name={`ownership[${index}].numberOfShares`}
                          {...numericBasicFieldFormattingProps}
                          data-testid="instrument-shares"
                          label="Number of Shares"
                          rules={positiveValueValidator(false)}
                        />
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </>
        </form>
      </FormProvider>
    </>
  );
};

export default CapitalStructureItemForm;
