export {};

declare global {
  interface String {
    /** Removes excessive white spaces, leaving only one  */
    trimMiddle(): string;
  }
}

String.prototype.trimMiddle = function (): string {
  return this.replace(/\s+/g, ' ');
};
