import { FC, useEffect, useState } from 'react';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { cloneDeep } from '@app/shared/helpers';
import { useLocale } from '@app/core/hooks/useLocale';
import { calculateBuildStructure } from '@app/core/store/capital-structure-slice';
import '../event-form-modal-module.scss';
import { CapitalStructureEventFaults } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import {
  CapitalStructureEventSeverity,
  EquityRefinancingMovementType,
  EventKey,
} from '@app/shared/models/contracts/enums/shared-enums';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { mapBuildStructureErrorToMessage } from '../utils/EventBuilderHelpers';
import store from '@app/core/store/store';
import * as notificationActions from '@app/core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';

interface DeleteEventConfirmModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  eventId: string;
  eventNarrative: string;
}

const PARENT_CLASSNAME = 'form';
export const DeleteEventConfirmModal: FC<DeleteEventConfirmModalProps> = ({
  isOpen,
  setIsOpen,
  eventId,
  eventNarrative,
}): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const [faults, setFaults] = useState<CapitalStructureEventFaults[]>([]);

  useEffect(() => {
    setFaults([]);
  }, [isOpen]);

  const handleEventDelete = async (): Promise<void> => {
    // Create a deep clone of the project to avoid mutating the original object
    const updatedProject = cloneDeep(project);

    // Identify all instruments that were added to the event
    const movements =
      updatedProject.capitalStructures[capitalStructureKey].events[eventId].movements;
    const newInstrumentIds = Object.keys(movements).filter(
      (key) => movements[key].movementType === EquityRefinancingMovementType.NewInstrument
    );

    // Remove the new instruments from instrumentDefinitions
    newInstrumentIds.forEach((instrumentId) => {
      Object.values(updatedProject.capitalStructures[capitalStructureKey].events).forEach(
        (event) => {
          const keys = Object.keys(event.movements).filter((id) => id === instrumentId);
          keys.forEach((key) => {
            delete event.movements[key];
          });
        }
      );
      delete updatedProject.capitalStructures[capitalStructureKey].instrumentDefinitions[
        instrumentId
      ];
    });

    // Iterate over each eventSet
    Object.values(updatedProject.capitalStructures[capitalStructureKey].eventSets).forEach(
      (eventSet) => {
        // Filter out the specified event by its ID from the event set's events array
        eventSet.events = eventSet.events.filter((event: string) => event !== eventId);
      }
    );

    // Remove the event from the events object of the capital structure
    delete updatedProject.capitalStructures[capitalStructureKey].events[eventId];

    // Iterate over each event set again to check for empty event sets
    Object.entries(updatedProject.capitalStructures[capitalStructureKey].eventSets).forEach(
      ([id, eventSet]) => {
        // Do not delete the OPM Only event set
        if (id === EventKey.OpmOnly) {
          return;
        }
        // If an event set has no events left, delete the event set
        if (eventSet.events.length === 0) {
          delete updatedProject.capitalStructures[capitalStructureKey].eventSets[id];

          // Update each case that references the deleted event set, setting its eventSetId to null
          updatedProject.pwermInput.cases.forEach((c) => {
            if (c.eventSetId === id) {
              c.eventSetId = null;
            }
          });
        }
      }
    );

    const result = await dispatch(calculateBuildStructure({ project: updatedProject })).unwrap();

    if (result.hasErrors) {
      const errors = result.buildStructure.buildStructures[capitalStructureKey].faults.filter(
        (f) => f.severity === CapitalStructureEventSeverity.Error
      );
      setFaults(errors);
    } else {
      setFaults([]);
      setIsOpen(false);
      store.dispatch(
        notificationActions.showNotificationSnackbar({
          severity: SeverityType.success,
          message: l('_EventDeletedSuccessfully', { eventNarrative }),
          autoHide: true,
        })
      );
    }
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      onCloseButtonClick={() => setIsOpen(false)}
      title={l('_ItemPermanentlyDeleted', { item: eventNarrative })}
      isOpen={isOpen}>
      {faults.length > 0 && (
        <div className={`${PARENT_CLASSNAME}__errors`}>
          {l('_CannotDeleteEventDueToTheFollowingErrors')}
          <ul>
            {faults.map((f) => (
              <li key={f.errorCode}>{mapBuildStructureErrorToMessage(f)}</li>
            ))}
          </ul>
        </div>
      )}
      <div className={`${PARENT_CLASSNAME}__actions`}>
        <Button
          appearance={ButtonAppearance.DEFAULT_SECONDARY}
          data-testid="submit-button"
          onClick={handleEventDelete}>
          {l('_PermanentlyDelete')}
        </Button>
        <Button
          data-testid="cancel-button"
          appearance={ButtonAppearance.DEFAULT_PRIMARY}
          onClick={() => {
            setIsOpen(false);
          }}>
          {l('_Cancel')}
        </Button>
      </div>
    </BaseModal>
  );
};
