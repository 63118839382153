import { FC, PropsWithChildren } from 'react';
import EmptyStateNoListSvg from '@app/shared/icons/legacy/EmptyStateNoListSvg';
import styles from './no-results.module.scss';

const PARENT_CLASSNAME = 'no-results';

interface NoResults {
  message: string;
}

const NoResults: FC<PropsWithChildren<NoResults>> = ({ message }) => {
  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      <div className={styles[`${PARENT_CLASSNAME}__content`]}>
        <div className={styles[`${PARENT_CLASSNAME}__icon`]}>
          <EmptyStateNoListSvg />
        </div>
        <p className={styles[`${PARENT_CLASSNAME}__message`]}> {message}</p>
      </div>
    </div>
  );
};

export default NoResults;
