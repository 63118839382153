import { FC, useRef } from 'react';
import ExitCostsPartialTable from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/exit-costs/ExitCostsPartialTable';
import DealThesisCaseExitMultiplesSection from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/exit-multiples/DealThesisCaseExitMultiplesSection';
import { DealThesisCaseTableHeader } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/DealThesisCaseTableHeader';
import { DealThesisCaseSlnAndPrefShareSection } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/sln-and-pref-share/DealThesisCaseSlnAndPrefShareSection';
import NetDebtPartialTable from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/net-debt/NetDebtPartialTable';
import ExitValueDriverSection from './exit-value-driver/ExitValueDriverSection';
import SlnAndPrefSharesForecastSummarySection from './sln-and-pref-share/SlnAndPrefSharesForecastSummarySection';
import TableScreenshotAction from '@app/shared/components/table/TableScreenshotAction';
import { removeFileIllegalCharacters } from '@app/shared/helpers/remove-file-illegal-characters';
import styles from '../deal-thesis-page.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useParams } from 'react-router-dom';
import OperationalFreeCashFlowSection from './operational-free-cash-flow/OperationalFreeCashFlowSection';
import { enumKeyByValue } from '@app/shared/helpers';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';

interface DealThesisCaseTableProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseTable: FC<DealThesisCaseTableProps> = ({
  handleDataSubmit,
}): JSX.Element => {
  const tableRef = useRef<HTMLTableElement>(null);
  const project = useAppSelector((state) => state.project);
  const { caseId } = useParams();
  const activeCase = project.projectDraft.pwermInput.cases.find(
    (caseItem) => caseItem.caseId === caseId
  );
  const PARENT_CLASSNAME = 'deal-thesis-table';
  const isOpm =
    project.projectDraft.details.calcMethod !== enumKeyByValue(CalcMethod, CalcMethod.PWERM);
  const isEv = project.projectDraft.opmInput.opmSimulatedValue === 'EnterpriseValue';

  return (
    <div className="main-container-padding-remove">
      <div className={styles[`${PARENT_CLASSNAME}__screenshot-action`]}>
        <TableScreenshotAction
          tableRef={tableRef}
          testIdString="save-deal-thesis-image"
          filePrefix={`Deal_thesis_${removeFileIllegalCharacters(
            `${activeCase?.narrative}_${project.projectDraft?.name}`
          )}_`}
        />
      </div>
      <table
        className="table-primary table-primary--zebra table-primary--framed table-primary--reserve-space-bottom"
        ref={tableRef}>
        <DealThesisCaseTableHeader handleDataSubmit={handleDataSubmit} />
        <ExitValueDriverSection />
        <DealThesisCaseExitMultiplesSection onRowsChange={handleDataSubmit} />
        <ExitCostsPartialTable handleDataSubmit={handleDataSubmit} />
        {isOpm && isEv && <OperationalFreeCashFlowSection />}
        <NetDebtPartialTable onRowsChange={handleDataSubmit} />
        <SlnAndPrefSharesForecastSummarySection />
        <DealThesisCaseSlnAndPrefShareSection handleDataSubmit={handleDataSubmit} />
      </table>
    </div>
  );
};
