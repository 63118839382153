import { cloneDeep } from '@app/shared/helpers';
import { DlomDto, ProjectDto, TrancheDto } from '@app/shared/models/contracts/project-dto';

export const missingDlomElements = (project: ProjectDto) => {
  const tranches = project.equityInstruments
    .filter((instrument) => instrument.tranches && instrument.tranches.length > 0)
    .flatMap((instrument) => instrument.tranches);

  if (tranches.length === 0) {
    return [];
  }

  const missingTranchesFromDlom = tranches.filter((tranche) => {
    return project.pwermInput.cases.some((caseItem) => {
      return caseItem.forecasts.some((forecast) => {
        return !forecast.dlom.some((dlomItem) => dlomItem.trancheId === tranche?.id);
      });
    });
  });

  const filteredMissingTranchesFromDlom = missingTranchesFromDlom.filter(
    (tranche): tranche is TrancheDto => tranche !== null && tranche !== undefined
  );

  return filteredMissingTranchesFromDlom;
};

export const setDlomElementsToNull = (missingTranches: TrancheDto[], project: ProjectDto) => {
  const updatedProject = cloneDeep(project);

  const additionalDlomItems: DlomDto[] = missingTranches.map((tranche) => {
    return {
      instrumentId:
        project.equityInstruments.find((instrument) => instrument.tranches?.includes(tranche))
          ?.instrumentId ?? '',
      value: null,
      trancheId: tranche.id,
    };
  });

  updatedProject.pwermInput.cases = project.pwermInput.cases.map((caseItem) => {
    return {
      ...caseItem,
      forecasts: caseItem.forecasts.map((forecastItem) => {
        return {
          ...forecastItem,
          dlom: [...forecastItem.dlom, ...additionalDlomItems],
        };
      }),
    };
  });

  return updatedProject;
};
