import {
  digitsPattern,
  numberPattern,
  percentagePattern,
  swapMaskForValueDisplay,
} from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { multipleFieldBase } from '@app/shared/constants/configs/multipleFieldBase';

export const digitsFieldFormattingProps = {
  isInputMasked: false,
  fieldValueMask: digitsPattern,
  valueAsNumber: false,
};

export const numericBaseFieldFormattingProps = {
  fieldValueMask: numberPattern,
  valueAsNumber: true,
};

export const numericBasicFieldFormattingProps = {
  ...numericBaseFieldFormattingProps,
  decimalPoint: 2,
  isNormalised: true,
};

export const amountBasicFieldFormattingProps = {
  ...numericBaseFieldFormattingProps,
  decimalPoint: 0,
  isNormalised: true,
};

export const percentageFieldFormattingProps = {
  fieldValueMask: percentagePattern,
  decimalPoint: 2,
  isNormalised: true,
  viewValueSuffix: '%',
  placeholder: '0.00%',
  alignment: Alignment.Right,
  valueAsNumber: true,
};

export const multipleFieldFormattingProps = {
  ...multipleFieldBase,
  alignment: Alignment.Right,
};

export const multipleFieldFormattingPropsSecondary = {
  ...multipleFieldBase,
  decimalPoint: 2,
  placeholder: '0.00x',
  alignment: Alignment.Right,
};

export const textFieldFormattingProps = {
  isInputMasked: false,
  fieldValueMask: swapMaskForValueDisplay,
  valueAsNumber: false,
};
