import '@app/shared/extensions/date.extensions';
import { LoadingStatus } from '@app/shared/models/loading-status-enum';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@server/api-config';
import env from '@environment';
import { AppDispatch, RootState } from './store';
import { OpmCalculationType } from './opm-calculation-slice';
import * as UiActions from '@app/core/store/ui-values-slice';
import { AxiosResponse, AxiosResponseHeaders } from 'axios';
import { runClientScheduleExport, runOpmExport } from '../signalr/signalRHubConnection';
import { OperationsStepType } from '@app/shared/models/contracts/operation-progress-update-dto';

export interface ExportOpmState {
  status: LoadingStatus;
}

export const exportOpm = createAsyncThunk<
  void,
  { projectId: number; type: OpmCalculationType },
  { state: RootState; dispatch: AppDispatch }
>('export/opm', async ({ projectId, type }, thunkAPI) => {
  const userId = thunkAPI.getState().userInfo.userInfo.id;
  const project = thunkAPI.getState().project.projectDraft;
  runOpmExport(projectId, userId, project, type);
});

export const exportClientSchedule = createAsyncThunk<
  void,
  { projectId: number },
  { state: RootState; dispatch: AppDispatch }
>('export/clientSchedule', async ({ projectId }, thunkAPI) => {
  const userId = thunkAPI.getState().userInfo.userInfo.id;
  const project = thunkAPI.getState().project.projectDraft;
  runClientScheduleExport(projectId, userId, project);
});

export const downloadExport = createAsyncThunk<
  void,
  number,
  { state: RootState; dispatch: AppDispatch }
>('export/download', async (jobId, thunkAPI) => {
  try {
    const response = await api.get(`${env.apiUrl}/jobs/${jobId}/download`, {
      responseType: 'blob',
    });
    initiateDownload(response);
    thunkAPI.dispatch(
      UiActions.setOperationStepComplete({
        stepType: OperationsStepType.DownloadExport,
        progress: 100,
      })
    );
  } finally {
    thunkAPI.dispatch(UiActions.setDisplayOperationProgress(false));
  }
});

export const exportSlice = createSlice({
  name: 'exportState',
  initialState: {
    status: LoadingStatus.Idle,
  } as ExportOpmState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(exportOpm.pending, (state, _) => {
      state.status = LoadingStatus.Loading;
    });
    builder.addCase(exportClientSchedule.pending, (state, _) => {
      state.status = LoadingStatus.Loading;
    });
  },
});

export default exportSlice.reducer;

const initiateDownload = (response: AxiosResponse) => {
  const responseHeaders: Partial<AxiosResponseHeaders> = response.headers;
  const contentType = responseHeaders.getContentType?.()?.toString();
  const contentDisposition = responseHeaders['content-disposition'];
  const filename = getFilename(contentDisposition);
  const blob = new Blob([response.data], { type: contentType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const getFilename = (contentDisposition: string) => {
  const filenameRegex = /filename="?(.*.xlsx)"?;/;
  const filename = filenameRegex.exec(contentDisposition);
  return filename ? filename[1] : 'file.xlsx';
};
