import {
  Children,
  cloneElement,
  FC,
  HTMLAttributes,
  isValidElement,
  PropsWithChildren,
} from 'react';
import styles from './tooltip.module.scss';
import classNames from 'classnames';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';

export enum TooltipPlacement {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

interface TooltipContentProps extends HTMLAttributes<HTMLElement> {
  content?: string | JSX.Element;
  placement?: TooltipPlacement;
  width?: string;
  tooltipVariation?: GridFieldTooltipVariation;
}

interface ChildWithProps {
  props: ChildProps;
}

interface ChildProps {
  className: string;
}

export const Tooltip: FC<PropsWithChildren<TooltipContentProps>> = ({
  content,
  children,
  placement,
  className,
  style,
  tooltipVariation,
  width,
  ...rest
}): JSX.Element => {
  const childrenWithClassnames = Children.map(children as ChildWithProps[], (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, {
        className: classNames(child.props.className, styles['tooltip__trigger']),
      });
    }
    return child;
  });

  return (
    <div className={classNames(className, styles['tooltip'])} style={style}>
      <>
        {childrenWithClassnames}
        {content === undefined ? (
          <></>
        ) : (
          <div
            style={width ? { maxWidth: width, width: width, minWidth: 0 } : undefined}
            className={classNames(styles['tooltip__content'], {
              [styles[`tooltip__content--${placement}`]]: placement,
              [styles[`tooltip__content--${tooltipVariation}`]]: tooltipVariation,
            })}
            {...rest}>
            {content}
          </div>
        )}
      </>
    </div>
  );
};
