import { FC } from 'react';
import styles from './drag-area.module.scss';
import bgImage from '@assets/images/table-grid-bg.png';
import { NotificationMessage } from '@app/shared/components/notification-message/NotificationMessage';
import classNames from 'classnames';
import Chevron from '@app/shared/icons/Chevron';

interface DragAreaProps {
  children?: JSX.Element;
  notificationMessage?: string;
}

const PARENT_CLASSNAME = 'drag-area';
const DragArea: FC<DragAreaProps> = ({ children, notificationMessage }): JSX.Element => (
  <div className={styles[`${PARENT_CLASSNAME}`]}>
    {notificationMessage && (
      <div className={styles[`${PARENT_CLASSNAME}__notification-message`]}>
        <NotificationMessage>{notificationMessage}</NotificationMessage>
      </div>
    )}
    <div className={styles[`${PARENT_CLASSNAME}__legend-y`]}>
      <div className={styles[`${PARENT_CLASSNAME}__legend-y-arrow`]}>
        <Chevron className={styles[`${PARENT_CLASSNAME}__legend-y-arrow-icon`]} />
      </div>
      <div
        className={classNames([
          styles[`${PARENT_CLASSNAME}__legend-y-item`],
          styles[`${PARENT_CLASSNAME}__legend-y-item--top`],
        ])}>
        Lowest seniority
      </div>
      <div
        className={classNames([
          styles[`${PARENT_CLASSNAME}__legend-y-item`],
          styles[`${PARENT_CLASSNAME}__legend-y-item--bottom`],
        ])}>
        Highest seniority
      </div>
    </div>
    <div className={styles[`${PARENT_CLASSNAME}__axis`]}>
      <div
        className={styles[`${PARENT_CLASSNAME}__grid`]}
        style={{ backgroundImage: `url(${bgImage})` }}>
        {children}
      </div>
    </div>
  </div>
);

export default DragArea;
