import { FC } from 'react';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import * as userListActions from '@core/store/user-list-slice';
import { useAppDispatch } from '@core/hooks/redux-hooks';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import styles from '@app/modules/projects/inputs/project-admin/project-access/project-access.module.scss';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { ROLE_OPTIONS } from '@app/shared/constants/dropdown-values/role-options';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';

interface EditUserModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  email: string;
  role: keyof typeof Role;
}

const PARENT_CLASSNAME = 'edit-user-modal';
const PARENT_FORM_CLASSNAME = 'form';

export const EditUserModal: FC<EditUserModalProps> = ({
  isOpen,
  email,
  setIsOpen,
  role,
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const formMethods = useForm<any>({
    ...formConfigBase,
    defaultValues: {
      role: role,
    },
  });

  const submitForm = async () => {
    await handleUserUpdate();
  };

  const formSubmitHandler = async () => {
    await submitForm();
  };

  const handleUserUpdate = async () => {
    await dispatch(userListActions.updateUser({ email: email, role: formMethods.getValues().role }))
      .unwrap()
      .then(() => {
        setIsOpen(false);
        return dispatch(userListActions.fetchUserList());
      });
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      onCloseButtonClick={() => setIsOpen(false)}
      title="Edit User"
      isOpen={isOpen}>
      <FormProvider {...formMethods}>
        <form
          data-testid="edit-user-form"
          className={styles[`${PARENT_CLASSNAME}__form`]}
          onSubmit={formMethods.handleSubmit(formSubmitHandler)}>
          <FormValue label="Email Address" value={email} />
          <FormSelect name="role" label="User Role" options={ROLE_OPTIONS} />
          <div className={`${PARENT_FORM_CLASSNAME}__actions`}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              data-testid="cancel-button"
              onClick={() => setIsOpen(false)}
              ignoreReadOnly>
              Cancel
            </Button>
            <Button
              type="submit"
              data-testid="submit-button"
              appearance={ButtonAppearance.DEFAULT_PRIMARY}
              ignoreReadOnly>
              Update User
            </Button>
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  );
};
