export const useCSSVariables = () => {
  const styles = getComputedStyle(document.documentElement);

  const colorPrimary = styles.getPropertyValue('--color-primary');
  const colorSecondary = styles.getPropertyValue('--color-secondary');
  const colorTertiary = styles.getPropertyValue('--color-tertiary');
  const colorAccents1 = styles.getPropertyValue('--color-accents-1');
  const colorAccents2 = styles.getPropertyValue('--color-accents-2');
  const colorAccents3 = styles.getPropertyValue('--color-accents-3');
  const colorAccents4 = styles.getPropertyValue('--color-accents-4');
  const colorAccents5 = styles.getPropertyValue('--color-accents-5');
  const colorGrey400 = styles.getPropertyValue('--color-grey400');
  const colorNeutralsB50 = styles.getPropertyValue('--color-neutrals-b50');
  const colorNeutralsB80 = styles.getPropertyValue('--color-neutrals-b80');
  const colorNeutralsB90 = styles.getPropertyValue('--color-neutrals-b90');
  const fontFamilyPrimary = styles.getPropertyValue('--font-family-primary');

  const chartPalette1 = styles.getPropertyValue('--color-chartPalette1');
  const chartPalette2 = styles.getPropertyValue('--color-chartPalette2');
  const chartPalette3 = styles.getPropertyValue('--color-chartPalette3');
  const chartPalette4 = styles.getPropertyValue('--color-chartPalette4');
  const chartPalette5 = styles.getPropertyValue('--color-chartPalette5');
  const chartPalette6 = styles.getPropertyValue('--color-chartPalette6');
  const chartPalette7 = styles.getPropertyValue('--color-chartPalette7');
  const chartPalette8 = styles.getPropertyValue('--color-chartPalette8');
  const chartPalette9 = styles.getPropertyValue('--color-chartPalette9');
  const chartPalette10 = styles.getPropertyValue('--color-chartPalette10');
  const chartPalette11 = styles.getPropertyValue('--color-chartPalette11');
  const chartPalette12 = styles.getPropertyValue('--color-chartPalette12');
  const chartPalette13 = styles.getPropertyValue('--color-chartPalette13');
  const chartPalette14 = styles.getPropertyValue('--color-chartPalette14');

  return {
    colorList: [
      colorPrimary,
      colorSecondary,
      colorTertiary,
      colorAccents1,
      colorAccents2,
      colorAccents3,
      colorAccents4,
      colorAccents5,
      colorNeutralsB50,
      colorNeutralsB80,
      colorNeutralsB90,
    ],
    chartPalette: [
      chartPalette1,
      chartPalette2,
      chartPalette3,
      chartPalette4,
      chartPalette5,
      chartPalette6,
      chartPalette7,
      chartPalette8,
      chartPalette9,
      chartPalette10,
      chartPalette11,
      chartPalette12,
      chartPalette13,
      chartPalette14,
    ],
    colors: {
      colorPrimary,
      colorSecondary,
      colorTertiary,
      colorAccents1,
      colorAccents2,
      colorAccents3,
      colorAccents4,
      colorAccents5,
      colorNeutralsB50,
      colorNeutralsB80,
      colorNeutralsB90,
      colorGrey400,
    },
    font: {
      fontFamilyPrimary,
    },
  };
};
