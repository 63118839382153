import { FC } from 'react';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getCalculatedValueForForecast,
  getHistoricalEV,
  selectCasesStructure,
} from '@core/store/project-slice-selectors';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { selectCalculatedVariablesResults } from '@app/core/store/pwerm-calculation-slice-selectors';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { useLocale } from '@app/core/hooks/useLocale';

export const ExitMultipleWeightedEVSection: FC = (): JSX.Element => {
  const casesStructure = useAppSelector(selectCasesStructure);
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const { l } = useLocale();

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  return (
    <>
      <tr>
        <th className="table-primary__cell--header">
          {l('_EnterpriseValue')} [{l('_EV')}]
        </th>
        <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
      </tr>
      <TableSectionValuesPerForecast
        title="Exit multiple weighted EV"
        renderSpacer={false}
        renderCell={({ caseItem, forecast }) => (
          <CellValue
            {...amountRules}
            strong
            value={getCalculatedValueForForecast(
              project,
              calculatedVariables,
              caseItem.caseId,
              forecast.forecastId,
              'Weighted EV'
            )}
          />
        )}
        renderAdditionalCell={() => (
          <CellValue
            {...amountRules}
            value={getHistoricalEV(project)}
            theme={CellValueTheme.Tertiary}
          />
        )}
        additionalTitleClassNames="table-primary__cell--strong"
      />
      <TableSpacerRow />
    </>
  );
};
