import { useState, useEffect, useCallback } from 'react';
import { useAppSelector } from './redux-hooks';
import { useDispatch } from 'react-redux';
import { setExpandAllState, setTableCollapseState } from '../store/ui-values-slice';
import { OverallExpandState } from '@app/shared/components/expand-all/ExpandAllOpm';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';

const useLocalStorageCollapseState = (key: string, projectId: number) => {
  const dispatch = useDispatch();
  const calcMethod = useAppSelector((state) => state.project.projectDraft.details.calcMethod);
  const expandAllStore = useAppSelector((state) => state.uiValues.expandAll);
  const projectSpecificKey = `${key}-${projectId}`;

  const [state, setState] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(projectSpecificKey);
    return storedValue !== null
      ? JSON.parse(storedValue)
      : calcMethod === CalcMethod.OPM
      ? true
      : false;
  });

  const syncStateWithRedux = useCallback(
    (newState: boolean) => {
      dispatch(setTableCollapseState({ key: projectSpecificKey, value: newState }));
    },
    [dispatch, projectSpecificKey]
  );

  useEffect(() => {
    localStorage.setItem(projectSpecificKey, JSON.stringify(state));
    syncStateWithRedux(state);
    dispatch(setExpandAllState(OverallExpandState.Other));
  }, [dispatch, projectSpecificKey, state, syncStateWithRedux]);

  useEffect(() => {
    if (expandAllStore === OverallExpandState.True) {
      setState(true);
      localStorage.setItem(projectSpecificKey, JSON.stringify(true));
      syncStateWithRedux(true);
    } else if (
      expandAllStore === OverallExpandState.False ||
      expandAllStore === OverallExpandState.Mixed
    ) {
      setState(false);
      localStorage.setItem(projectSpecificKey, JSON.stringify(false));
      syncStateWithRedux(false);
    }
  }, [expandAllStore, projectSpecificKey, syncStateWithRedux]);

  return [state, setState] as const;
};

export default useLocalStorageCollapseState;
