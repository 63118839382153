function numberToLetters(num: number) {
  let letters = '';
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters;
    // eslint-disable-next-line big-number-rules/rounding
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}

export const generateUniqueName = (prefix: string, existingNames: Set<string>) => {
  const existingNamesLowercase = new Set([...existingNames].map((x) => x.toLowerCase()));
  let name = '';
  let index = 0;
  do {
    name = `${prefix} ${numberToLetters(index)}`;
    index++;
  } while (existingNamesLowercase.has(name.toLowerCase()));

  return name;
};
