import { LOCALE } from '@app/shared/constants/locale';
import { INTL_CACHE } from '@app/shared/helpers/intl/intl-cache';

export const commaFormatter = (
  value: number,
  locale = LOCALE,
  isNormalised = false,
  fraction?: number
): string => {
  return INTL_CACHE.formatters
    .getNumberFormat(locale, {
      minimumFractionDigits: isNormalised ? fraction : undefined,
      maximumFractionDigits: fraction,
      trailingZeroDisplay: 'auto',
    })
    .format(value);
};
