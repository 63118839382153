import {
  EquityInstrumentDto,
  ForecastDto,
  TrancheDto,
  PerYearValuesDto,
  MarketValueDlomDto,
  DlomDto,
} from '@app/shared/models/contracts/project-dto';

export const getDlomIndexByTrancheId = (forecast: ForecastDto, trancheItem: TrancheDto) => {
  return forecast.dlom?.findIndex((dlomItem: DlomDto) => {
    return dlomItem.trancheId === trancheItem.id;
  });
};

export const getDlomIndexByInstrumentId = (
  forecast: ForecastDto,
  instrument: EquityInstrumentDto
) => {
  return forecast.dlom?.findIndex((dlomItem: DlomDto) => {
    return dlomItem.instrumentId === instrument.instrumentId;
  });
};

export const getDlomIndexByInstrumentIdForOpm = (
  perYearInput: PerYearValuesDto,
  instrument: EquityInstrumentDto
) => {
  return perYearInput.marketValueDloms?.findIndex((dlomItem: MarketValueDlomDto) => {
    return dlomItem.instrumentId === instrument.instrumentId;
  });
};

export const getDlomIndexByTrancheIdForOpm = (
  perYearInput: PerYearValuesDto,
  trancheItem: TrancheDto,
  instrument: EquityInstrumentDto
) => {
  const instrumentIndex = getDlomIndexByInstrumentIdForOpm(perYearInput, instrument);
  return perYearInput.marketValueDloms[instrumentIndex].trancheDloms?.findIndex((dlomItem) => {
    return dlomItem.trancheId === trancheItem.id;
  });
};
