import { useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from './redux-hooks';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { SimulatedOutcomesChartTypes } from '@app/modules/projects/dashboard/opm-charts/opm-enums';
import { opmInstrumentByTypeOrDefaultSortFn } from '@app/shared/helpers';

export const useLocalStateCollapse = (
  isExpanded: boolean,
  action: ActionCreatorWithPayload<boolean>,
  chartType?: SimulatedOutcomesChartTypes
) => {
  const dispatch = useAppDispatch();
  // using useRef to keep track of the current value of isExpanded, as the useEffect would
  // otherwise not have access to the most recent value of isExpanded
  const isExpandedRef = useRef(isExpanded);

  useEffect(() => {
    isExpandedRef.current = isExpanded;
  }, [isExpanded]);

  // dispatch the action to save the state when the component is unmounted
  useEffect(() => {
    return () => {
      if (chartType === SimulatedOutcomesChartTypes.RiskFreeOutcome || chartType === undefined) {
        dispatch(action(isExpandedRef.current));
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useUpdatedChildRowStates = () => {
  const storedChildRowStates = useAppSelector(
    (state) => state.uiValues.userSelections.opmDashboard.valuationEstimatesTable.childRowStates
  );

  const valuedInstruments = useAppSelector(
    (state) => state.opmCalculation.riskFreeValues.valuationConclusion || []
  );

  const sortedValuedInstruments = useMemo(
    () => [...valuedInstruments].sort(opmInstrumentByTypeOrDefaultSortFn),
    [valuedInstruments]
  );

  // if all stored rows are expanded, then we will expand any new children that have been added
  // since returning to the component, hence checking if all stored rows are expanded
  const areAllStoredChildRowsExpanded =
    Object.values(storedChildRowStates).length > 0 &&
    Object.values(storedChildRowStates).every((instrument) => !instrument);

  // check to see if any stored rows represent instruments that are no longer in the list,
  // and if so, remove them
  const validStoredChildRows = useMemo(() => {
    const newChildRowStates = { ...storedChildRowStates };
    Object.keys(newChildRowStates).forEach((instrumentId) => {
      if (!sortedValuedInstruments.some((instrument) => instrument.instrumentId === instrumentId)) {
        delete newChildRowStates[instrumentId];
      }
    });
    return newChildRowStates;
  }, [storedChildRowStates, sortedValuedInstruments]);

  // add any new instruments to the stored child row states, and if areAllStoredChildRowsExpanded
  // is true, then expand them
  const updatedChildRowStates = useMemo(() => {
    const newChildRowStates = { ...validStoredChildRows };
    sortedValuedInstruments.forEach((instrument) => {
      if (newChildRowStates[instrument.instrumentId] === undefined) {
        newChildRowStates[instrument.instrumentId] = !areAllStoredChildRowsExpanded;
      }
    });
    return newChildRowStates;
  }, [validStoredChildRows, sortedValuedInstruments, areAllStoredChildRowsExpanded]);

  return updatedChildRowStates;
};
