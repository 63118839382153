import { SVGProps } from 'react';
const SvgHouse = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M22.994 12.481c0 .668-.573 1.192-1.222 1.192H20.55l.026 5.945c0 .1-.007.2-.019.3v.598c0 .82-.683 1.484-1.528 1.484h-.61c-.043 0-.085 0-.127-.004-.053.004-.107.004-.16.004h-2.159c-.844 0-1.527-.664-1.527-1.484V17.25c0-.657-.547-1.188-1.223-1.188H10.78c-.677 0-1.223.531-1.223 1.188v3.265c0 .82-.683 1.485-1.528 1.485H5.893a2.67 2.67 0 0 1-.172-.007 1.712 1.712 0 0 1-.137.007h-.611c-.845 0-1.528-.664-1.528-1.484v-4.157c0-.033 0-.07.003-.104v-2.582H2.222c-.687 0-1.222-.52-1.222-1.192 0-.333.115-.63.382-.89l9.794-8.294c.267-.26.573-.297.84-.297.268 0 .573.074.802.26l9.756 8.33c.306.26.458.558.42.891Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgHouse;
