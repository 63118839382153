import { FC } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { RouteConstants } from '../../RouteConstants';

export const OpmWaterfallPage: FC = (): JSX.Element => {
  const storedOpmWaterfallTab = useAppSelector(
    (state) => state.uiValues.userSelections.opmWaterfall.selectedTab
  );
  const { generatePath } = usePwcNavigate();

  const selectedTab = storedOpmWaterfallTab ?? RouteConstants.FirstLastSimulations;

  return <PwcNavigate route={generatePath(selectedTab)} />;
};
