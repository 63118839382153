import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import SvgClone from '@app/shared/icons/Clone';
import { FC, useState } from 'react';
import * as projectActions from '@core/store/project-slice';
import { CopyProjectFormModal } from '@app/modules/projects/inputs/project-admin/copy-project/CopyProjectFormModal';
import { userHasUnsavedChanges } from '@app/core/store/project-slice-selectors';

interface CopyProjectActionProps {
  projectName?: string;
  projectId?: number;
  isSaveBeforeCopy?: boolean;
}

export const CopyProjectAction: FC<CopyProjectActionProps> = ({
  projectName,
  projectId,
  isSaveBeforeCopy = true,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const hasUnsavedChanges = useAppSelector(userHasUnsavedChanges);
  const [isCopyProjectModalOpen, setIsCopyProjectModalOpen] = useState(false);

  const handleMakeCopy = async (): Promise<void> => {
    if (hasUnsavedChanges && isSaveBeforeCopy) {
      await dispatch(projectActions.saveExistingProject())
        .unwrap()
        .then(() => {
          setIsCopyProjectModalOpen(true);
        });
    } else {
      setIsCopyProjectModalOpen(true);
    }
  };

  return (
    <>
      <Button
        startIcon={<SvgClone />}
        onClick={() => handleMakeCopy()}
        appearance={isSaveBeforeCopy ? ButtonAppearance.DEFAULT_TERTIARY : ButtonAppearance.CLEAN}
        ignoreReadOnly>
        Make A Copy
      </Button>
      {isCopyProjectModalOpen && (
        <CopyProjectFormModal
          setIsOpen={setIsCopyProjectModalOpen}
          isOpen={isCopyProjectModalOpen}
          projectId={projectId}
          projectName={projectName}
        />
      )}
    </>
  );
};
