import React, { useEffect } from 'react';
import { Toggle, ToggleSize } from '../toggle/Toggle';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { setOverallConclusionsShowPerShareFigures } from '@app/core/store/ui-values-slice';
import styles from './show-per-share-figures.module.scss';

import { useLocale } from '@app/core/hooks/useLocale';

const ShowPerShareFigures: React.FC = () => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();

  const showPerShareFiguresState = useAppSelector(
    (state) => state.uiValues.userSelections.overallConclusions.showPerShareFigures
  );

  type HeaderFormModel = {
    showPerShareFigures: boolean;
  };

  const formMethods = useForm<HeaderFormModel>({
    ...formConfigBase,
    defaultValues: { showPerShareFigures: showPerShareFiguresState },
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset({ showPerShareFigures: showPerShareFiguresState });
  }, [reset, showPerShareFiguresState]);

  return (
    <FormProvider {...formMethods}>
      <span>
        <Toggle
          name="showPerShareFigures"
          label={l('_ShowPerShareFigures')}
          toggleSize={ToggleSize.Medium}
          className={styles['show-per-share-figures']}
          onChange={(e) => dispatch(setOverallConclusionsShowPerShareFigures(e.target.checked))}
        />
      </span>
    </FormProvider>
  );
};

export default ShowPerShareFigures;
