import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import styles from './exportButtonDropdown.module.scss';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import * as exportSlice from '@app/core/store/export-slice';
import classNames from 'classnames';
import SvgDownload from '@app/shared/icons/Download';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import Button from '@app/shared/components/button/Button';
import { FC } from 'react';
import SvgChevronPrimary from '@app/shared/icons/ChevronPrimary';
import { useLocale } from '@app/core/hooks/useLocale';
import { OpmCalculationType } from '@app/core/store/opm-calculation-slice';

const PARENT_CLASSNAME = 'exportButtonDropdown';

interface ExportButtonDropdownProps {
  pwermExcelExport: () => void;
}

export const ExportButtonDropdown: FC<ExportButtonDropdownProps> = ({ pwermExcelExport }) => {
  const dispatch = useAppDispatch();
  const { projectDraft: projectDraft } = useAppSelector((state) => state.project);
  const { realWorldValues: realWorldValues, riskFreeValues: riskFreeValues } = useAppSelector(
    (state) => state.opmCalculation
  );
  const { l } = useLocale();

  const pwermExportDisabled = projectDraft.details.calcMethod === CalcMethod.OPM;
  const opmRiskNeutralDisabled =
    projectDraft.details.calcMethod === CalcMethod.PWERM ||
    Object.keys(riskFreeValues).length === 0;
  const opmRealWorldDisabled =
    projectDraft.opmInput.realWorldRate === null ||
    projectDraft.details.calcMethod === CalcMethod.PWERM ||
    Object.keys(realWorldValues).length === 0;

  const opmDownload = (type: OpmCalculationType) => {
    dispatch(exportSlice.exportOpm({ projectId: projectDraft.id, type: type }));
  };

  return (
    <DropdownMenu
      useDefaultSvgColour
      dropdownContent={l('_Export')}
      dropdownStartIcon={<SvgDownload />}
      dropdownButtonSize={ButtonSize.SMALL}
      appearance={ButtonAppearance.DEFAULT_SECONDARY}
      menuDropdownClassName="__menu-export"
      dropdownTriggerIcon={<SvgChevronPrimary />}>
      <Button
        data-testid="export-button-dropdown-pwerm"
        disabled={pwermExportDisabled}
        appearance={ButtonAppearance.CLEAN}
        className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
        onClick={pwermExcelExport}>
        {l('_PwermXls')}
      </Button>
      <Button
        data-testid="export-button-dropdown-risk-neutral"
        disabled={opmRiskNeutralDisabled}
        appearance={ButtonAppearance.CLEAN}
        className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
        onClick={() => opmDownload(OpmCalculationType.RiskFree)}>
        {l('_OpmRiskNeutralXlsx')}
      </Button>
      <Button
        data-testid="export-button-dropdown-real-world"
        disabled={opmRealWorldDisabled}
        appearance={ButtonAppearance.CLEAN}
        className={classNames(styles[`${PARENT_CLASSNAME}__dropdown-item`])}
        onClick={() => opmDownload(OpmCalculationType.RealWorld)}>
        {l('_OpmRealWorldXlsx')}
      </Button>
    </DropdownMenu>
  );
};
