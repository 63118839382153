import { AlertColor } from '@mui/material/Alert/Alert';
import { ReactNode } from 'react';
import InfoRoundedSvg from '@app/shared/icons/legacy/InfoRoundedSvg';
import SvgSuccessIcon from '@app/shared/icons/SuccessIcon';
import SvgErrorStatusIcon from '@app/shared/icons/ErrorStatusIcon';
import SvgWarningStatus from '@app/shared/icons/WarningStatus';

export const iconMapping: Record<AlertColor, ReactNode> = {
  error: <SvgErrorStatusIcon />,
  info: <InfoRoundedSvg />,
  success: <SvgSuccessIcon />,
  warning: <SvgWarningStatus />,
};

export enum SeverityType {
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning',
}
