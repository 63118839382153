import React, { FC } from 'react';
import {
  abbreviatedValueFormatter,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import {
  DenominationMultiplier,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue, sumBy, useGetCalculatedProjectValue } from '@app/shared/helpers';
import {
  calculatePercentage,
  getTotalOfEquityByInstrumentTypeAndOwnership,
} from '@core/store/project-slice-selectors';
import {
  CaseDto,
  EquityInstrumentDto,
  ForecastDto,
} from '@app/shared/models/contracts/project-dto';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { getWeightedAverageValueForAllCases } from '@app/shared/helpers/get-weighted-value-for-all-cases';
import { useGetCalculatedValue } from '@app/core/store/pwerm-calculated-values-selectors';

interface InstrumentTooltipProps {
  instrument: EquityInstrumentDto;
  forecastIndex?: number;
  filteredCase?: CaseDto;
  isHovering: boolean;
  forecastData: ForecastDto[];
}

const InstrumentTooltip: FC<InstrumentTooltipProps> = ({
  instrument,
  forecastIndex,
  filteredCase,
  forecastData,
  isHovering,
}): JSX.Element => {
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const equityItems = useAppSelector((state) => state.project.projectDraft.equityInstruments);
  const project = useAppSelector((state) => state.project.projectDraft);
  const { getCalculatedValue } = useGetCalculatedValue();
  if (!isHovering) {
    return <></>;
  }

  const filteredEquityItems = equityItems.filter(
    (equityItem) => equityItem.type === instrument.type
  );

  const totalStructureAmount = !Number.isFinite(forecastIndex)
    ? sumBy(
        [instrument].flatMap((x) => x.ownership),
        (x) => x.amount ?? 0
      )
    : filteredCase
    ? getCalculatedProjectValue('InstrumentWeightedAveragePaidDistributions', {
        caseId: filteredCase ? filteredCase.caseId : undefined,
        forecastId: filteredCase
          ? filteredCase.forecasts[forecastIndex ?? 0]?.forecastId
          : forecastData[forecastIndex ?? 0]?.forecastId,
        instrumentId: instrument.instrumentId,
      })
    : getWeightedAverageValueForAllCases(
        (caseItem) =>
          getCalculatedProjectValue('InstrumentWeightedAveragePaidDistributions', {
            caseId: caseItem.caseId,
            forecastId:
              (forecastIndex || forecastIndex === 0) && caseItem.forecasts[forecastIndex]
                ? caseItem.forecasts[forecastIndex ?? 0].forecastId
                : forecastData[forecastIndex ?? 0].forecastId,
            instrumentId: instrument.instrumentId,
          }) ?? 0,
        project.pwermInput.cases
      );

  const returnInstrumentPayoutProportionInRelationOfInstrumentType = (
    instrument: EquityInstrumentDto,
    caseItem: CaseDto | undefined,
    forecastIndex: number
  ) => {
    const totalPayoutOfSameTypeInstruments = sumBy(filteredEquityItems, (instrument) => {
      return (
        (filteredCase
          ? getCalculatedValue('InstrumentWeightedAveragePaidDistributions', [], {
              caseId: caseItem?.caseId,
              forecastYear: forecastData[forecastIndex].forecastYear,
              instrumentId: instrument.instrumentId,
            })
          : getCalculatedValue('InstrumentWeightedAveragePaidDistributions', ['caseId'], {
              forecastYear: forecastData[forecastIndex].forecastYear,
              instrumentId: instrument.instrumentId,
            }))[0]?.value ?? 0
      );
    });

    const totalInstrumentPayout =
      (filteredCase
        ? getCalculatedValue('InstrumentWeightedAveragePaidDistributions', [], {
            caseId: caseItem?.caseId,
            forecastYear: forecastData[forecastIndex].forecastYear,
            instrumentId: instrument.instrumentId,
          })
        : getCalculatedValue('InstrumentWeightedAveragePaidDistributions', ['caseId'], {
            forecastYear: forecastData[forecastIndex].forecastYear,
            instrumentId: instrument.instrumentId,
          }))[0]?.value ?? 0;

    return (
      (totalPayoutOfSameTypeInstruments
        ? totalInstrumentPayout / totalPayoutOfSameTypeInstruments
        : 0) * 100
    );
  };

  const returnInstrumentAmountByType = (ownersType: OwnerType) => {
    return Number(
      calculatePercentage(
        getTotalOfEquityByInstrumentTypeAndOwnership([instrument]),
        getTotalOfEquityByInstrumentTypeAndOwnership(
          [instrument],
          undefined,
          enumKeyByValue(OwnerType, ownersType)
        )
      ) ?? 0
    );
  };

  const getTotalsByType = (ownerType: OwnerType) => {
    const structureAmount =
      ((totalStructureAmount ?? 0) / 100) * returnInstrumentAmountByType(ownerType);
    return structureAmount === 0
      ? 0
      : abbreviatedValueFormatter({
          value: structureAmount * multiplier,
        });
  };

  const institutionOwnershipTotal = getTotalsByType(OwnerType.Institution);
  const coInvestorOwnershipTotal = getTotalsByType(OwnerType.CoInvestor);
  const managementOwnershipTotal = getTotalsByType(OwnerType.Management);
  const otherOwnershipTotal = getTotalsByType(OwnerType.Other);

  const getPercentageForValueType =
    forecastIndex || forecastIndex === 0
      ? returnInstrumentPayoutProportionInRelationOfInstrumentType(
          instrument,
          filteredCase,
          forecastIndex
        )
      : calculatePercentage(
          getTotalOfEquityByInstrumentTypeAndOwnership(
            equityItems,
            enumKeyByValue(InstrumentType, InstrumentType[instrument.type]),
            undefined,
            'amount'
          ),
          getTotalOfEquityByInstrumentTypeAndOwnership(
            [instrument],
            enumKeyByValue(InstrumentType, InstrumentType[instrument.type]),
            undefined,
            'amount'
          )
        ) ?? 0;

  return (
    <div key={'instrument-tooltip-' + forecastIndex}>
      <h2 className="heading-2 heading-2--alternate">{instrument.instrumentNarrative}</h2>
      <dl className="definition-list">
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>% of {InstrumentType[instrument.type]}</dt>
            <dd>
              {numberValueFormatter({
                value: getPercentageForValueType,
                ...percentageValueFormatterProps,
              })}
            </dd>
          </div>
          {institutionOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>Sponsor</dt>
              <dd>{institutionOwnershipTotal}</dd>
            </div>
          )}
          {coInvestorOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>Co-Investor</dt>
              <dd>{coInvestorOwnershipTotal}</dd>
            </div>
          )}
        </div>
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>{forecastIndex || forecastIndex === 0 ? 'Total proceeds' : 'Initial Amount'}</dt>
            <dd>
              {abbreviatedValueFormatter({
                value: Number(totalStructureAmount) * multiplier,
              })}
            </dd>
          </div>
          {managementOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>Management</dt>
              <dd>{managementOwnershipTotal}</dd>
            </div>
          )}
          {otherOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>Other</dt>
              <dd>{otherOwnershipTotal}</dd>
            </div>
          )}
        </div>
      </dl>
    </div>
  );
};

export default React.memo(InstrumentTooltip);
