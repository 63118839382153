import { OpmInputSummaryRangeDto } from '../models/contracts/opm-calculation-results-dto';
import { yearFormatter } from './date-formatter';
import { toPercentageUsingFormatter } from './number-value-formatter';

// below is used in inputSummary.tsx
export const formatMinMaxField = (data: OpmInputSummaryRangeDto | null): string | number => {
  if (data === null) {
    return 'N/A';
  }
  const { minimum, maximum, dateAtMinimum, dateAtMaximum } = data;
  if (minimum === null || maximum === null) {
    return 'N/A';
  }

  if (minimum === maximum) {
    return minimum * 100;
  }

  return `${toPercentageUsingFormatter(minimum)} [${yearFormatter(
    dateAtMinimum
  )}] - ${toPercentageUsingFormatter(maximum)} [${yearFormatter(dateAtMaximum)}]`;
};
