export const moveCurrentUserToTheTopOfTheList = (data: Partial<any[]>, userEmail: string) => {
  // Move current user to the top of the list
  const sortData = [...data];

  sortData.unshift(
    sortData.splice(
      sortData.findIndex((item) => item?.email === userEmail),
      1
    )[0]
  );

  return sortData;
};
