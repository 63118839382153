import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { enumKeyByValue, instrumentOwnersSortFn, sumBy } from '@app/shared/helpers';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { CapitalStructureCalculationResultDto } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';

export const SummaryShareholderDebt = () => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const opmInput = { ...project.opmInput };
  const capitalStructureData = useAppSelector((state) => state.capitalStructure.values);

  const sectionColCount =
    (opmInput?.perYearInputs.length ?? 0) + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const defaultInstrumentValues = {
    [OwnerType.Institution]: 0,
    [OwnerType.Management]: 0,
    [OwnerType.CoInvestor]: 0,
    [OwnerType.Other]: 0,
  };

  const historicalSummaries: {
    [key in Exclude<InstrumentType, InstrumentType.OrdinaryEquity>]: { [key in OwnerType]: number };
  } = {
    [InstrumentType.ShareholderLoanNotes]: { ...defaultInstrumentValues },
    [InstrumentType.PreferredShares]: { ...defaultInstrumentValues },
  };

  project.equityInstruments.forEach((i) => {
    const instrumentType = InstrumentType[i.type as keyof typeof InstrumentType];

    if (instrumentType === InstrumentType.OrdinaryEquity) {
      return;
    }

    for (const ownership of i.ownership) {
      const ownerType = OwnerType[ownership.owner as keyof typeof OwnerType];
      const amount = Number(ownership.amount);

      historicalSummaries[instrumentType][ownerType]
        ? (historicalSummaries[instrumentType][ownerType] += !Number.isNaN(amount) ? amount : 0)
        : (historicalSummaries[instrumentType][ownerType] = !Number.isNaN(amount) ? amount : 0);
    }
  });

  const renderSummarySection = (
    capitalStructureData: CapitalStructureCalculationResultDto,
    type: keyof typeof InstrumentType,
    historicalSummary: { [key in OwnerType]: number },
    title: string
  ): JSX.Element => {
    return (
      <>
        <tr className="table-primary__row--header">
          <th className="table-primary__cell--header-tertiary" colSpan={sectionColCount}>
            {title}
          </th>
        </tr>
        {Object.keys(historicalSummary)
          .map((x) => {
            return x as OwnerType;
          })
          .filter((ownerType) => Boolean(historicalSummary[ownerType]))
          .sort((a, b) =>
            instrumentOwnersSortFn(enumKeyByValue(OwnerType, a), enumKeyByValue(OwnerType, b))
          )
          .map((ownerType) => (
            <tr key={ownerType}>
              <th
                className={classNames(
                  'table-primary__cell--vertical-separator table-primary__cell--indentation'
                )}>
                {ownerType} Total
              </th>
              <td>
                <CellValue
                  {...amountRules}
                  dataTestId={slugifyStrings(title, ownerType, 'historical-summary-values')}
                  value={historicalSummary[ownerType]}
                />
              </td>
              {project.opmInput.perYearInputs.map((forecast) => (
                <td key={forecast.forecastDate}>
                  <CellValue
                    {...amountRules}
                    dataTestId={slugifyStrings(
                      title,
                      ownerType,
                      'summary-values',
                      forecast.forecastDate ?? ''
                    )}
                    value={
                      capitalStructureData.debtInstrumentsByYear[forecast.forecastDate ?? '']
                        ?.summary[type].perOwner[enumKeyByValue(OwnerType, ownerType)] ?? 0
                    }
                  />
                </td>
              ))}
              <td className={classNames('table-primary__cell--vertical-separator')} />
            </tr>
          ))}

        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--vertical-separator">
            <strong>{title} Total</strong>
          </th>
          <td>
            <CellValue
              {...amountRules}
              dataTestId={slugifyStrings(title, 'history-total')}
              value={sumBy(Object.values(historicalSummary), (i) => i)}
              strong
            />
          </td>
          {project.opmInput.perYearInputs.map((forecast) => (
            <td key={forecast.forecastDate + 'sln-totals-sum'}>
              <CellValue
                {...amountRules}
                dataTestId={slugifyStrings(title, forecast.forecastDate ?? '', 'total')}
                value={
                  capitalStructureData.debtInstrumentsByYear[forecast.forecastDate ?? '']?.summary[
                    type
                  ]?.closingBalance ?? 0
                }
                strong
              />
            </td>
          ))}
          <td />
        </tr>
      </>
    );
  };

  const showSlnSection = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );
  const showPrefSharesSection = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  );

  return (
    <>
      <tr>
        <th
          className="table-primary__cell--header table-primary__cell--header-primary"
          colSpan={sectionColCount}
          data-testid="summary-shareholder-debt">
          {l('_SummaryShareholderDebt')}
        </th>
      </tr>
      {showPrefSharesSection &&
        renderSummarySection(
          capitalStructureData,
          enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
          historicalSummaries[InstrumentType.PreferredShares],
          'Preference Shares'
        )}
      {showSlnSection &&
        renderSummarySection(
          capitalStructureData,
          enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes),
          historicalSummaries[InstrumentType.ShareholderLoanNotes],
          'Shareholder Loan Notes'
        )}
    </>
  );
};
