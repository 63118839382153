import { FC } from 'react';
import TableSectionValuesPerMultiple from '../../../components/TableSectionValuesPerMultiple';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getCaseById, getMultipleProbability } from '@app/core/store/project-slice-selectors';
import TableSectionValuesPerCase from '../../../components/TableSectionValuesPerCase';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';

export const WaterfallExitMultiplesPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const [isExitMultiplesOpen, setIsExitMultiplesOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Exit Multiples"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.ExitMultiples)}
          isOpen={isExitMultiplesOpen}
          setIsOpen={setIsExitMultiplesOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isExitMultiplesOpen,
        })}>
        <TableSectionValuesPerMultiple
          title="Multiple spread"
          renderCell={({ caseItem, forecast, multiple }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={
                getMultipleProbability(project, caseItem.caseId, forecast.forecastId, multiple)
                  ?.probability
              }
            />
          )}
        />
        <TableSectionValuesPerCase
          title="Case probability"
          additionalTitleClassNames="table-primary__cell--strong"
          highlight
          renderCell={({ caseItem }) => (
            <CellValue
              {...enteredPercentageViewRules}
              alignment={Alignment.Center}
              strong
              value={getCaseById(project, caseItem.caseId)?.probability}
            />
          )}
        />
      </tbody>
    </>
  );
};

export default WaterfallExitMultiplesPartialTable;
