import { useEffect } from 'react';
import { useAppDispatch } from './redux-hooks';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

const useSetSelectedTab = (
  route: RouteConstants,
  setAction: ActionCreatorWithPayload<RouteConstants>
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setAction(route));
    };
  }, [dispatch, route, setAction]);
};

export default useSetSelectedTab;
