import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import SvgChevron from '@app/shared/icons/Chevron';
import { FC } from 'react';

interface WaterfallTableExpandableRowProps {
  title: string;
  id: keyof typeof WaterfallSections;
  isOpen: boolean;
  setIsOpen: () => void;
  colCount: number;
  stickyFirstColumn?: boolean;
}

export const WaterfallTableExpandableRow: FC<WaterfallTableExpandableRowProps> = ({
  title,
  id,
  isOpen,
  setIsOpen,
  colCount,
  stickyFirstColumn = true,
}) => {
  return (
    <tr onClick={setIsOpen} tabIndex={0}>
      <th
        colSpan={stickyFirstColumn ? 1 : colCount}
        className="table-primary__cell--header"
        id={id}>
        <span
          className={classNames('table-primary__table-section-indicator', {
            'table-primary__table-section-indicator--rotated': !isOpen,
          })}>
          <SvgChevron className="table-primary__expansion-toggle" />
        </span>
        {title}
      </th>
      {stickyFirstColumn && <th colSpan={colCount - 1} className="table-primary__cell--header" />}
    </tr>
  );
};
