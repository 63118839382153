import { CalcMethod } from '../models/contracts/enums/shared-enums';

export interface ErrorMessage {
  response: {
    data: {
      message: string | undefined;
      type: string | undefined;
      data: { [key: string]: string[] } | undefined;
      instance?: string;
    };
  };
  message: string | undefined;
}

export interface FluentValidationResponse {
  errors: [FluentValidationErrorData];
  isValid: boolean;
}

export interface ErrorWrappedWithValidationResult {
  response: {
    data: {
      result: null;
      validation: FluentValidationResponse;
      calculationMethod: CalcMethod;
    };
  };
}

export interface ResponseWrappedWithValidationResult {
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: any;
    validation: FluentValidationResponse;
    calculationMethod: CalcMethod;
  };
}

export interface FluentValidationErrorData {
  propertyName: string;
  errorMessage: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attemptedValue: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customState: any;
  severity: FluentValidationErrorSeverity;
  errorCode: string;
  formattedMessagePlaceholderValues: {
    ComparisonValue?: number;
    ComparisonProperty?: string;
    PropertyName: string | null;
    PropertyValue: number;
    PropertyPath: string;
  };
}

export enum FluentValidationErrorSeverity {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}

export interface CodedDomainException {
  response: {
    data: {
      type: string;
      errorCode: number;
      title: string;
      message: string;
    };
  };
  message: string;
}

export type ModelValidationError = ErrorMessage;
