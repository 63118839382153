import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import * as projectActions from '@core/store/project-slice';
import { OpmInputTable } from '@app/modules/projects/inputs/opm-inputs/OpmInputTable';
import { cloneDeep, enumKeyByValue } from '@app/shared/helpers';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import useIsReadOnly from '@core/hooks/customUseIsReadOnly';
import {
  CalcMethod,
  EventKey,
  OpmInputDataSource,
  OpmSimulatedValue,
} from '@app/shared/models/contracts/enums/shared-enums';
import { useOpmInputsFromPwerm } from './hooks/useOpmInputsFromPwerm';
import { OpmKeyInputs } from './OpmKeyInputs';
import { useOpmInputsFromCapitalStructure } from './hooks/useOpmInputsFromCapitalStructure';
import {
  DebtItemDto,
  EquityInstrumentDto,
  PerYearValuesDto,
} from '@app/shared/models/contracts/project-dto';
import { Navigate, generatePath } from 'react-router';
import { RouteConstants } from '../../RouteConstants';
import { selectOpmFormValues } from '@app/core/store/project-slice-selectors';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {
  getInitialTotalNetDebt,
  getTotalNetDebtValues,
} from '@app/core/inputs/getOpmInputCalculatedValues';

export interface OpmInputFormModel {
  evValue: number;
  evVolatility: number;
  perYearInputs: PerYearValuesDto[];
  forecastYears: number;
  netDebtItems: DebtItemDto[];
  filteredSlnAndPrefSharesInstruments?: EquityInstrumentDto[];
  initialCashValue: number;
  valuationDateNarrative: string;
  realWorldRate: Nullable<number>;
  controlPremiumForDloc: number;
  initialTotalNetDebt: number;
  marketVolatility: Nullable<number>;
  peerSetVolatility: Nullable<number>;
  selectedEventSetId: Nullable<string>;
  selectedCaseId: Nullable<string>;
}

export const OpmInputsForm = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const savedProject = useAppSelector((state) => state.project.project);
  const isReadOnly = useIsReadOnly();
  const {
    getMaxForecastYearsFromPwerm,
    getAllOpmValuesFromPwerm,
    getControlPremiumForDlocFromPwerm,
  } = useOpmInputsFromPwerm();
  const { getEvOrEquityValueFromCapitalStructure } = useOpmInputsFromCapitalStructure();
  const formValues = useAppSelector(selectOpmFormValues);
  const [forecastYearsChangeInProgress, setForecastYearsChangeInProgress] = useState(false);
  const perYearInputs = formValues.perYearInputs;
  const forecastDates = perYearInputs.map((yi) => yi.forecastDate);
  const isOpmOnlyProject = project.details.calcMethod === CalcMethod.OPM;
  const isEquitySimulation =
    project.opmInput.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.Equity);

  const perYearValuesFromPwerm = useMemo(
    () => getAllOpmValuesFromPwerm(forecastDates),
    [forecastDates, getAllOpmValuesFromPwerm]
  );

  const perYearCalculatedValuesFromPwerm = useMemo(
    () => getTotalNetDebtValues(project, forecastDates),
    [forecastDates, project]
  );

  const evOrEquityValueFromCapitalStructure = useMemo(
    () => getEvOrEquityValueFromCapitalStructure(),
    [getEvOrEquityValueFromCapitalStructure]
  );

  const controlPremiumForDloc = useMemo(
    () => getControlPremiumForDlocFromPwerm(),
    [getControlPremiumForDlocFromPwerm]
  );

  const maxForecastYearsFromPwerm = useMemo(
    () => getMaxForecastYearsFromPwerm(),
    [getMaxForecastYearsFromPwerm]
  );

  const initialTotalNetDebtFromPwerm = useMemo(() => getInitialTotalNetDebt(project), [project]);

  const formMethods = useForm<OpmInputFormModel>({
    ...formConfigBase,
    defaultValues: formValues,
  });

  const { handleSubmit, getValues, reset, trigger } = formMethods;

  const notifyForecastYearsChangeInProgress = (changeInProgress: boolean) => {
    setForecastYearsChangeInProgress(changeInProgress);
  };

  const submitData = useCallback(async () => {
    // don't update the project draft if the number of forecast years is updating,
    // as this is handled separately in OpmKeyInputs
    if (!forecastYearsChangeInProgress) {
      const { ...updatedOpmInput } = {
        ...project.opmInput,
        ...getValues(),
      };

      if (isOpmOnlyProject) {
        updatedOpmInput.initialTotalNetDebt =
          updatedOpmInput.initialCashValue +
          updatedOpmInput.netDebtItems?.reduce(
            (sum, netDebt) => (sum += netDebt.historicalValue ?? 0),
            0
          );
      }

      const initialTotalNetDebt = isOpmOnlyProject
        ? savedProject.opmInput.initialTotalNetDebt
        : initialTotalNetDebtFromPwerm;

      // check if the EV or Equity spot value has been overridden
      updatedOpmInput.evValueSource =
        updatedOpmInput.evValue !== evOrEquityValueFromCapitalStructure
          ? OpmInputDataSource.Override
          : OpmInputDataSource.FromCapitalStructure;

      // check if the controlPremiumForDloc value has been overridden
      updatedOpmInput.controlPremiumForDlocSource =
        updatedOpmInput.controlPremiumForDloc !== controlPremiumForDloc
          ? OpmInputDataSource.Override
          : OpmInputDataSource.FromPWERM;

      // check if the Forecast Years value has been overridden
      updatedOpmInput.forecastYearsSource =
        Number(updatedOpmInput.forecastYears) !== Number(maxForecastYearsFromPwerm)
          ? OpmInputDataSource.Override
          : OpmInputDataSource.FromPWERM;

      // check if the initialTotalNetDebt value has been overridden
      updatedOpmInput.initialTotalNetDebtSource =
        updatedOpmInput.initialTotalNetDebt !== initialTotalNetDebt
          ? OpmInputDataSource.Override
          : OpmInputDataSource.FromPWERM;

      // Check if the selectedCaseId has a value and find the eventSetId for the case
      if (updatedOpmInput.selectedCaseId) {
        const selectedCase = project.pwermInput.cases.find(
          (caseItem) => caseItem.caseId === updatedOpmInput.selectedCaseId
        );
        if (selectedCase) {
          updatedOpmInput.selectedEventSetId = selectedCase.eventSetId ?? EventKey.EmptyEventSet;
        }
      }

      // check to see if any of the inputs have been overridden from the value
      // suggested from PWERM data
      for (let year = 0; year < updatedOpmInput.forecastYears; year++) {
        if (
          (perYearValuesFromPwerm.length > year && perYearValuesFromPwerm[year]) ||
          isOpmOnlyProject
        ) {
          const pwermValues = perYearValuesFromPwerm[year];
          const modelValues = updatedOpmInput.perYearInputs[year];
          const initialYearTotalNetDebt = isOpmOnlyProject
            ? savedProject.opmInput.perYearInputs[year]?.totalNetDebt
            : perYearCalculatedValuesFromPwerm?.[year];

          if (isOpmOnlyProject) {
            modelValues.totalNetDebt =
              Number(modelValues.cashValue) +
              modelValues?.netDebtItems?.reduce((sum, netDebt) => (sum += netDebt.value ?? 0), 0);
          }

          modelValues.ipoProbabilitySource =
            modelValues.ipoProbability !== pwermValues.ipoProbability
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.hypotheticalDebtWhenIpoSource =
            modelValues.hypotheticalDebtWhenIpo !== pwermValues.hypotheticalDebtWhenIpo
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.ipoCostsOfEvSource =
            !isEquitySimulation && modelValues.ipoCostsOfEv !== pwermValues.ipoCostsOfEv
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.secondarySaleCostsOfEvSource =
            !isEquitySimulation &&
            modelValues.secondarySaleCostsOfEv !== pwermValues.secondarySaleCostsOfEv
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.ipoDiscountSource =
            modelValues.ipoDiscount !== pwermValues.ipoDiscount
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.equitySoldInIpoSource =
            modelValues.equitySoldInIpo !== pwermValues.equitySoldInIpo
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.postIpoSaleDlomSource =
            modelValues.postIpoSaleDlom !== pwermValues.postIpoSaleDlom
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.financingCostsSource =
            modelValues.financingCosts !== pwermValues.financingCosts
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.marketValueDloms.forEach((mvd) => {
            const pwermInstrument = pwermValues?.marketValueDloms?.find(
              (pv) => pv.instrumentId === mvd.instrumentId
            );
            mvd.instrumentDlomSource =
              mvd.instrumentDlom !== null && mvd.instrumentDlom !== pwermInstrument?.instrumentDlom
                ? OpmInputDataSource.Override
                : OpmInputDataSource.FromPWERM;
            mvd.trancheDloms.forEach((tr) => {
              const pwermTranche = pwermInstrument?.trancheDloms.find(
                (td) => td.trancheId === tr.trancheId
              );
              tr.dlomSource =
                tr.dlom !== null && tr.dlom !== pwermTranche?.dlom
                  ? OpmInputDataSource.Override
                  : OpmInputDataSource.FromPWERM;
            });
          });

          modelValues.totalNetDebtSource =
            modelValues.totalNetDebt !== initialYearTotalNetDebt
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.operationalFreeCashFlowSource =
            modelValues.operationalFreeCashFlow !== pwermValues.operationalFreeCashFlow
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;

          modelValues.operationalEvSource =
            modelValues.operationalEv !== pwermValues.operationalEv
              ? OpmInputDataSource.Override
              : OpmInputDataSource.FromPWERM;
        }
      }

      delete updatedOpmInput.filteredSlnAndPrefSharesInstruments;
      const { filteredSlnAndPrefSharesInstruments } = getValues();
      if (!isReadOnly) {
        await dispatch(
          projectActions.updateProjectDraft({
            project: {
              ...project,
              opmInput: { ...cloneDeep(updatedOpmInput) },
              equityInstruments: [
                ...project.equityInstruments.filter(
                  (instrument) =>
                    filteredSlnAndPrefSharesInstruments &&
                    !filteredSlnAndPrefSharesInstruments.some(
                      (slnOrPrefShareInstrument) =>
                        instrument.instrumentId === slnOrPrefShareInstrument.instrumentId
                    )
                ),
                ...cloneDeep(filteredSlnAndPrefSharesInstruments ?? []),
              ],
            },
          })
        ).unwrap();
      }
    }
  }, [
    forecastYearsChangeInProgress,
    project,
    getValues,
    isOpmOnlyProject,
    savedProject.opmInput.initialTotalNetDebt,
    savedProject.opmInput.perYearInputs,
    initialTotalNetDebtFromPwerm,
    evOrEquityValueFromCapitalStructure,
    controlPremiumForDloc,
    maxForecastYearsFromPwerm,
    isReadOnly,
    perYearValuesFromPwerm,
    perYearCalculatedValuesFromPwerm,
    isEquitySimulation,
    dispatch,
  ]);

  useDeepCompareEffect(() => {
    // update the form with any changes to the project draft
    // trigger validation after the page has rendered, otherwise
    // it does not display the results correctly
    // do a deep comparison because everytime the inputs are updated,
    // a new object is generated (even if none of the underlying values change)
    reset(formValues);
    setTimeout(() => trigger(), 0);
  }, [formValues]);

  if (project.details.calcMethod === CalcMethod.PWERM) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onBlur={handleSubmit(submitData, submitData)}>
          <OpmKeyInputs
            submitData={submitData}
            notifyForecastYearsChangeInProgress={notifyForecastYearsChangeInProgress}
            formValues={formValues}
          />
          <OpmInputTable submitData={submitData} />
        </form>
      </FormProvider>
    </>
  );
};
