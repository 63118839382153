import { EmptyValues } from '@app/shared/constants/empty-values';
import { FC } from 'react';
import classNames from 'classnames';
import styles from './form-value.module.scss';
import FieldValidationMessage from '@app/shared/components/form-controls/fidl-validation-message/FieldValidationMessage';
import { dateFormatter, numberValueFormatter } from '@app/shared/formatters';

interface FormValueProps {
  decimalPoint?: number;
  dateFormat?: Intl.DateTimeFormatOptions;
  label: string;
  inlineLabel?: boolean;
  value: string | number | undefined | null;
  isGapless?: boolean;
  prefix?: string;
  suffix?: string;
  normalize?: boolean;
  validate?: any[];
  isModalHeaderField?: boolean;
}

export const PARENT_CLASSNAME = 'form-value';
export const FormValue: FC<FormValueProps> = ({
  label,
  inlineLabel = false,
  value,
  isGapless = false,
  isModalHeaderField = false,
  decimalPoint,
  dateFormat,
  prefix,
  suffix,
  normalize,
  validate,
}): JSX.Element => {
  const numberValueFormatterValue = numberValueFormatter({
    value: typeof value == 'number' ? value : Number(value),
    fraction: decimalPoint,
    valuePrefix: prefix,
    valueSuffix: suffix,
    isNormalised: normalize,
  });

  const validationMessage = () => {
    if (validate) {
      return validate.map((validator) => {
        if (validator) {
          return {
            type: 'error',
            message: validator,
          };
        }
      });
    }
    return undefined;
  };

  const validationErrors = validationMessage()?.find((validationError) => validationError?.message);

  const getFormattedValue = (
    value: string | number | undefined | null,
    numberFormatter: ReturnType<typeof numberValueFormatter>
  ): string => {
    const decimalPointProvided = Boolean(decimalPoint) || decimalPoint === 0;
    if ((value || value === 0) && value !== 'Undefined') {
      if (decimalPointProvided) {
        return numberFormatter;
      } else if (dateFormat) {
        return dateFormatter(value.toString(), dateFormat);
      } else {
        return `${prefix ? prefix : ''}${value.toString()}${suffix ? suffix : ''}`;
      }
    }
    return EmptyValues.EnDash;
  };

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--gapless`]]: isGapless,
        [styles[`${PARENT_CLASSNAME}--inline-label`]]: inlineLabel,
        [styles[`${PARENT_CLASSNAME}--inline-modal-header`]]: isModalHeaderField,
      })}>
      {label && (
        <div
          className={classNames({
            [styles[`${PARENT_CLASSNAME}__label-container`]]: !inlineLabel,
          })}>
          <label
            className={classNames(styles[`${PARENT_CLASSNAME}__label`], {
              [styles[`${PARENT_CLASSNAME}__label--inline`]]: inlineLabel,
            })}
            htmlFor={`inputId-${label}`}>
            {label}
          </label>
        </div>
      )}
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__container`], {
          [styles[`${PARENT_CLASSNAME}__container--inline`]]: inlineLabel,
        })}>
        <input
          id={`inputId-${label}`}
          className={classNames(styles[`${PARENT_CLASSNAME}__control`])}
          type="text"
          value={getFormattedValue(value, numberValueFormatterValue)}
          disabled
        />
      </div>
      {validationErrors && (
        <div className={classNames(styles[`${PARENT_CLASSNAME}__validation-message`])}>
          <FieldValidationMessage error={validationErrors} />
        </div>
      )}
    </div>
  );
};
