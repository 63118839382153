import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

const ExclamationTriangleSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon stroke="transparent" viewBox="0 0 24 24" {...props}>
      <path
        clipRule="evenodd"
        d="M12.8944 4.46361C12.495 3.80995 11.539 3.82977 11.167 4.49942L2.82536 19.5144C2.45506 20.1809 2.93703 21 3.69951 21H21.2169C21.9979 21 22.4774 20.1449 22.0702 19.4785L12.8944 4.46361Z"
        fillRule="evenodd"
        opacity="0.3"
      />
      <rect x="11" y="9" width="2" height="7" rx="1" />
      <rect x="11" y="17" width="2" height="2" rx="1" />
    </SvgIcon>
  );
};

export default memo(ExclamationTriangleSvg);
