// @ts-nocheck
import Highcharts from 'highcharts';

// allows plotBands to be added to the series group so that the zIndex can be set
// relative to the series. See https://github.com/highcharts/highcharts/issues/3321
Highcharts.wrap(Highcharts.PlotLineOrBand.prototype, 'render', function (proceed) {
  const chart = this.axis.chart;

  proceed.call(this);

  if (!chart.seriesGroup) {
    chart.seriesGroup = chart.renderer.g('series-group').attr({ zIndex: 3 }).add();
  }

  if (this.svgElem.parentGroup !== chart.seriesGroup) {
    this.svgElem.attr({ zIndex: this.options.zIndex }).add(chart.seriesGroup);
  }
  return this;
});
