import {
  CaseDto,
  DebtItemDto,
  EquityInstrumentDto,
  ForecastDto,
  ForecastInputDto,
  MultipleDto,
  OwnershipDto,
  PerYearValuesDto,
  ProjectDto,
} from '@app/shared/models/contracts/project-dto';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@core/store/store';
import {
  PwermCalculatedPayouts,
  CalculatedSlnAndPrefSharesForecasts,
  PwermCalculatedVariables,
  PwermCalculationResultsDto,
  PwermPredefinedVariables,
} from '@app/shared/models/contracts/pwerm-calculation-results-dto';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue, sumBy } from '@app/shared/helpers';
import equal from 'fast-deep-equal';
import { OpmInputFormModel } from '@app/modules/projects/inputs/opm-inputs/OpmInputs';
import {
  isBenchmarkingOnlyProject,
  setBenchmarkingCalculatedValuesToNull,
} from '@app/modules/benchmarking/benchmarking-helpers';

export interface CaseStructure {
  caseId: string;
  narrative: string;
  forecasts: ForecastDto[];
  multiples: MultipleDto[];
  probability: number;
}

const selectRawProject = (state: RootState) => state.project.project;
const selectProjectDraft = (state: RootState) => state.project.projectDraft;
const selectProject = (state: RootState) => state.project;

export const userHasUnsavedChanges = createSelector(
  [selectRawProject, selectProjectDraft],
  (project, projectDraft) => {
    if (project.status === 'Closed') {
      return false;
    }

    // if this is not a benchmarking only project, set the calculated values to null
    // for the comparison, as we may have some projects with values set and want to
    // silently migrate them to the new structure (i.e. the user is not prompted that
    // they have changes to save, but the changes will get persisted if/when they save)
    if (!isBenchmarkingOnlyProject(projectDraft)) {
      project = setBenchmarkingCalculatedValuesToNull(project);
      projectDraft = setBenchmarkingCalculatedValuesToNull(projectDraft);
    }

    return !equal(project, projectDraft);
  }
);

export const selectAllMultipleValues = createSelector([selectProject], (project) => {
  return [
    ...new Set(
      project.projectDraft.pwermInput.cases
        .map((x) => x.multiples.map((multiple) => Number(multiple.multiple)))
        .flat()
    ),
  ].sort((a, b) => Number(a) - Number(b));
});

export const selectCasesStructure = createSelector([selectProject], (project) => {
  function mapForecasts(forecasts: ForecastDto[]) {
    return forecasts
      .slice()
      .sort((a, b) => new Date(a.forecastYear).getTime() - new Date(b.forecastYear).getTime());
  }

  return project.projectDraft.pwermInput.cases.map((c) => ({
    ...c,
    forecasts: mapForecasts(c.forecasts),
  }));
});

export const selectSlnAndPrefSharesInstruments = createSelector([selectProject], (state) =>
  state.projectDraft.equityInstruments.filter(
    (instrument) =>
      instrument.type !== enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
  )
);

export const selectSlnInstruments = createSelector([selectProject], (state) =>
  state.projectDraft.equityInstruments.filter(
    (instrument) =>
      instrument.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  )
);

export const selectPrefShareInstruments = createSelector([selectProject], (state) =>
  state.projectDraft.equityInstruments.filter(
    (instrument) =>
      instrument.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  )
);

export const selectOrdinaryEquityInstruments = createSelector([selectProject], (state) =>
  state.projectDraft.equityInstruments.filter(
    (instrument) =>
      instrument.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
  )
);

export const selectCapitalStructures = createSelector(
  [selectProject],
  (state) => state.projectDraft.capitalStructures
);

export const selectCapitalStructure = createSelector(
  [selectCapitalStructures],
  (capitalStructures) => Object.values(capitalStructures)[0]
);

export const selectInstrumentsFromCapitalStructure = createSelector(
  [selectCapitalStructure],
  (capitalStructure) => {
    return Object.entries(capitalStructure.instrumentDefinitions).map(
      ([instrumentId, instrument]) => ({
        instrumentId,
        ...instrument,
      })
    );
  }
);

export const selectOrdinaryEquityInstrumentsFromCapitalStructure = createSelector(
  [selectInstrumentsFromCapitalStructure],
  (instruments) => {
    return instruments.filter(
      (instrument) =>
        instrument.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
    );
  }
);

export const selectValuedInstruments = createSelector([selectProject], (state) =>
  state.projectDraft.equityInstruments.filter((instrument) => instrument.shouldBeValued)
);

export const selectOpmFormValues = createSelector(
  [selectProjectDraft, selectSlnAndPrefSharesInstruments],
  (project, filteredSlnAndPrefSharesInstruments): OpmInputFormModel => {
    const opmInput = project.opmInput;
    return {
      forecastYears: opmInput.forecastYears ?? 3,
      evValue: opmInput.evValue ?? 0,
      evVolatility: opmInput.evVolatility ?? 0,
      initialCashValue: opmInput.initialCashValue ?? 0,
      netDebtItems: opmInput.netDebtItems,
      filteredSlnAndPrefSharesInstruments: filteredSlnAndPrefSharesInstruments,
      controlPremiumForDloc: opmInput.controlPremiumForDloc ?? 0,
      realWorldRate: opmInput.realWorldRate,
      valuationDateNarrative: opmInput.valuationDateNarrative,
      perYearInputs: opmInput.perYearInputs,
      initialTotalNetDebt: opmInput.initialTotalNetDebt ?? 0,
      marketVolatility: opmInput.marketVolatility,
      peerSetVolatility: opmInput.peerSetVolatility,
      selectedEventSetId: opmInput.selectedEventSetId,
      selectedCaseId: opmInput.selectedCaseId,
    };
  }
);

export function getCaseById(project: ProjectDto, caseId: string) {
  return project.pwermInput.cases.find((x) => x.caseId === caseId);
}

export function getForecastById(project: ProjectDto, caseId: string, forecastId: string) {
  return getCaseById(project, caseId)?.forecasts.find((x) => x.forecastId === forecastId);
}

export function getShareholdingPercentageOfInstrument(
  calculationValues: PwermCalculationResultsDto,
  instrumentId: string
) {
  return sumBy(
    calculationValues.calculatedProject?.calculatedResults?.filter(
      (waterfallPayoutResult) =>
        waterfallPayoutResult.name === 'ShareholdingOrdinaryEquity' &&
        waterfallPayoutResult.instrumentId === instrumentId
    ),
    (item) => item.value * 100
  );
}

export function getMultipleById(project: ProjectDto, caseId: string, multipleId: string) {
  return getCaseById(project, caseId)?.multiples?.find((x) => x.multipleId === multipleId);
}

export const getMultipleValue = ({
  project,
  caseItem,
  multipleId,
}: {
  project: ProjectDto;
  caseItem: CaseDto;
  multipleId: string;
}) => getMultipleById(project, caseItem.caseId, multipleId)?.multiple;

export function getMultipleProbability(
  project: ProjectDto,
  caseId: string,
  forecastId: string,
  multipleValue: number
) {
  const caseItem = getCaseById(project, caseId);
  const multiple = caseItem?.multiples.find((x) => Number(x.multiple) === Number(multipleValue));

  if (!multiple) {
    return null;
  }

  return getForecastById(project, caseId, forecastId)?.multipleProbabilities.find(
    (x) => x.multipleId === multiple.multipleId
  );
}

export function getSlnAndPrefSharesForecast(
  project: ProjectDto,
  slnAndPrefSharesForecasts: CalculatedSlnAndPrefSharesForecasts,
  caseId: string,
  instrumentId: string,
  forecastId: string
) {
  const forecastDate = getForecastById(project, caseId, forecastId)?.forecastYear;

  if (!forecastDate) {
    return undefined;
  }

  const forecast = slnAndPrefSharesForecasts?.[caseId]?.[instrumentId]?.[forecastDate];
  return forecast ?? undefined;
}

export function getCalculatedVariableValue(
  project: ProjectDto,
  calculated: PwermCalculatedVariables,
  caseId: string,
  forecastId: string,
  multipleValue: number,
  inputName: keyof PwermPredefinedVariables
) {
  const forecastDate = getForecastById(project, caseId, forecastId)?.forecastYear;

  if (!forecastDate) {
    return undefined;
  }

  return calculated[caseId]?.[forecastDate]?.[multipleValue]?.[inputName];
}

export function getTotalOrdinaryEquityProceeds(
  project: ProjectDto,
  calculated: PwermCalculatedPayouts,
  caseId: string,
  forecastId: string,
  multipleValue: number
) {
  const forecastDate = getForecastById(project, caseId, forecastId)?.forecastYear;

  if (!forecastDate) {
    return null;
  }

  return calculated[caseId]?.[forecastDate]?.[multipleValue]?.totalOrdinaryEquityProceeds;
}

export function getCalculatedPayoutValue(
  project: ProjectDto,
  calculated: PwermCalculatedPayouts,
  caseId: string,
  forecastId: string,
  multipleValue: number,
  instrumentId: string
) {
  const forecastDate = getForecastById(project, caseId, forecastId)?.forecastYear;

  if (!forecastDate || !multipleValue) {
    return undefined;
  }

  return calculated[caseId]?.[forecastDate]?.[multipleValue]?.payouts[instrumentId];
}

export const getTotalOfEquityByInstrumentTypeAndOwnership = (
  items: EquityInstrumentDto[],
  instrumentType?: keyof typeof InstrumentType,
  ownership?: keyof typeof OwnerType,
  propertyName: keyof OwnershipDto = 'amount'
): number | null => {
  let amount: null | number = null;

  const filteredItems = instrumentType
    ? items.filter((equityItem: EquityInstrumentDto) => equityItem.type === instrumentType)
    : items;

  Object.keys(filteredItems).map((_, index) => {
    const equityOwnershipItem = filteredItems[index].ownership;
    Object.keys(equityOwnershipItem).map((_, indexSecondary) => {
      if (ownership ? equityOwnershipItem[indexSecondary].owner === ownership : true) {
        if (equityOwnershipItem[indexSecondary][propertyName]) {
          const currentValue = Number(equityOwnershipItem[indexSecondary][propertyName]);
          amount ? (amount += currentValue) : (amount = currentValue);
        }
      }
    });
  });

  return amount;
};

export const calculatePercentage = (totalAmount: number | null, partialAmount: number | null) => {
  return totalAmount && partialAmount ? (100 / totalAmount) * partialAmount : null;
};

export function getInputValue<T extends keyof ForecastInputDto>(
  project: ProjectDto,
  caseId: string,
  forecastId: string,
  inputName: T
): ForecastInputDto[T] | undefined {
  return getForecastById(project, caseId, forecastId)?.inputs[inputName];
}

export function getCalculatedValueForForecast(
  project: ProjectDto,
  calculated: PwermCalculatedVariables,
  caseId: string,
  forecastId: string,
  inputName: keyof PwermPredefinedVariables
) {
  const _case = getCaseById(project, caseId);

  if (!_case) {
    return NaN;
  }

  const multiple = Number(_case.multiples[0].multiple);

  const forecast = getForecastById(project, caseId, forecastId);
  if (forecast === undefined) {
    return NaN;
  }

  return Number(calculated[caseId]?.[forecast?.forecastYear]?.[multiple]?.[inputName]);
}

export function getHistoricalEV(project: ProjectDto) {
  return (
    project.sourcesAndUses.currentValuation ?? project.sourcesAndUses.dayOneTransactionPurchasePrice
  );
}

function getForecastNetDebtItemsTotal(forecast: ForecastDto) {
  return forecast.netDebtItems.reduce((acc, item) => {
    acc = Number(acc) + Number(item.value);

    return acc;
  }, 0);
}

function getCaseHistoricalValueNetDebtItemsTotal(caseItem?: CaseDto) {
  return caseItem?.netDebtItems.reduce((acc, item) => {
    if (item && item.historicalValue) {
      acc = acc + Number(item.historicalValue);
    }
    return acc;
  }, 0);
}

export function getCaseValueNetDebtItemsTotal(caseItem?: CaseDto) {
  return (
    Number(getCaseHistoricalValueNetDebtItemsTotal(caseItem)) +
    Number(caseItem?.historicalCashValue)
  );
}

export function getNetDebtAtExit(forecast: ForecastDto) {
  return forecast.cashValue !== null
    ? forecast.cashValue + getForecastNetDebtItemsTotal(forecast)
    : NaN;
}

export const getTotalMultipleProbabilityPerForecast = (
  project: ProjectDto,
  caseId: string,
  forecastId: string
) => {
  const forecast = getForecastById(project, caseId, forecastId);
  return sumBy(forecast?.multipleProbabilities ?? [], (mp) => Number(mp.probability));
};

export const getMultipleIndex = (forecast: ForecastDto, multipleItem: MultipleDto) => {
  return forecast.multipleProbabilities.findIndex((multipleProbability) => {
    return multipleProbability.multipleId === multipleItem.multipleId;
  });
};

export const getNetDebtIndex = (forecast: ForecastDto, netDebtItem: DebtItemDto) => {
  return forecast.netDebtItems.findIndex((netDebtItemForecast) => {
    return netDebtItemForecast.netDebtItemId === netDebtItem.netDebtItemId;
  });
};

export const getOpmNetDebtIndex = (yearInputs: PerYearValuesDto, netDebtItem: DebtItemDto) => {
  return yearInputs.netDebtItems.findIndex((netDebtItemForecast) => {
    return netDebtItemForecast.netDebtItemId === netDebtItem.netDebtItemId;
  });
};

export const getExitMultiplesWithNonZeroProbability = (
  forecast: ForecastDto,
  caseItem: CaseDto
) => {
  const exitMultipleIdsWithNonZeroProbability = forecast.multipleProbabilities
    .filter((mp) => mp.probability! > 0)
    .map((multiple) => multiple.multipleId!);
  return caseItem.multiples
    .filter((mutliple) => exitMultipleIdsWithNonZeroProbability.includes(mutliple.multipleId))
    .map((multiple) => multiple.multiple!);
};
