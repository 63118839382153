import { FC, useEffect, useRef } from 'react';
import { removeFileIllegalCharacters } from '@app/shared/helpers/remove-file-illegal-characters';
import { dateFormatter } from '@app/shared/formatters';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { useDownloadExcel } from '@app/shared/components/excel-exporter/ExcelExporter';
import { ParticipationTable2 } from './ParticipationTable2';

interface ParticipationTableExcelExportProps {
  shouldRenderInnerContent?: boolean;
  excelExportEnabled?: boolean;
}

export const ParticipationTableExcelExport2: FC<ParticipationTableExcelExportProps> = ({
  shouldRenderInnerContent,
  excelExportEnabled = false,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project);
  const caseItemWithMaxForecasts = getCaseWithMostForecasts(
    project.projectDraft.pwermInput.cases,
    true
  );
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${removeFileIllegalCharacters(
      project.projectDraft?.name
    )}_Participation_table2_${dateFormatter(new Date().toString(), {
      dateStyle: 'short',
    })}`,
    sheet: 'Participation Table',
  });

  useEffect(() => {
    if (shouldRenderInnerContent && excelExportEnabled) {
      onDownload();
    }
    // effect tracks only one dependency
  }, [shouldRenderInnerContent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <table ref={tableRef}>
      <>
        {shouldRenderInnerContent &&
          caseItemWithMaxForecasts.forecasts
            .slice(0)
            .reverse()
            .map((forecastItem) => {
              return (
                <ParticipationTable2
                  key={forecastItem.forecastId}
                  shouldRenderStandaloneTable={false}
                  injectedForecastId={forecastItem.forecastId}
                />
              );
            })}
      </>
    </table>
  );
};
