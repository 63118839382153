import React, { useEffect, useState } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectMaintenanceModeSettings } from '@app/core/store/ui-values-slice-selectors';
import { dateFormatter } from '@app/shared/formatters';
import SvgWarning from '@app/shared/icons/Warning';
import styles from './under-maintenance.module.scss';
import SvgXMark from '@app/shared/icons/XMark';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  calculateTimeDifferenceInHours,
  isTimeInPast,
} from '@app/shared/helpers/maintenance/date-time-calculations';

const PARENT_CLASSNAME = 'maintenance-card';

const MaintenanceCard: React.FC = () => {
  const maintenanceModeSettings = useAppSelector(selectMaintenanceModeSettings);
  const { l } = useLocale();

  const [closeMaintenanceNotice, setCloseMaintenanceNotice] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const closeMaintenanceNoticeHandler = () => {
    setCloseMaintenanceNotice(true);
  };

  const formattedStartDate = maintenanceModeSettings.start
    ? dateFormatter(maintenanceModeSettings.start, {
        dateStyle: 'full',
      })
    : l('_NotAvailable');

  const formattedStartTime = maintenanceModeSettings.start
    ? dateFormatter(maintenanceModeSettings.start, {
        timeStyle: 'long',
      })
    : l('_NotAvailable');

  const shouldNotRenderMaintenanceNotice =
    maintenanceModeSettings.start === null ||
    maintenanceModeSettings.end === null ||
    closeMaintenanceNotice ||
    isTimeInPast(currentDateTime, new Date(maintenanceModeSettings.start)) ||
    isTimeInPast(currentDateTime, new Date(maintenanceModeSettings.end)) ||
    calculateTimeDifferenceInHours(currentDateTime, new Date(maintenanceModeSettings.start)) <= 2; // 2 hours

  useEffect(() => {
    if (shouldNotRenderMaintenanceNotice) {
      return;
    }
    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // update every minute

    return () => clearInterval(interval);
  }, [shouldNotRenderMaintenanceNotice]);

  if (shouldNotRenderMaintenanceNotice) {
    return null;
  }

  return (
    <>
      <Card
        hasShadow
        cornersVariation={CardCornersVariation.Secondary}
        variation={CardVariation.TertiarySpacing}
        className={styles[`${PARENT_CLASSNAME}__card`]}>
        <div className={styles[`${PARENT_CLASSNAME}__card-contents`]}>
          <h2 className={styles[`${PARENT_CLASSNAME}__card-header`]}>
            {l(`_MaintenanceNotice`)}
            <SvgWarning className={styles[`${PARENT_CLASSNAME}__icons`]} />
          </h2>
          <Button
            appearance={ButtonAppearance.CLEAN}
            size={ButtonSize.SMALL}
            onClick={closeMaintenanceNoticeHandler}>
            <SvgXMark className={styles[`${PARENT_CLASSNAME}__icons`]} />
          </Button>
        </div>
        <div className={styles['text-content']}>
          <p>
            {l('_MaintenanceCardMessage', {
              formattedStartDate: formattedStartDate,
              formattedStartTime: formattedStartTime,
            })}
          </p>
          {maintenanceModeSettings.message && <p>{maintenanceModeSettings.message}</p>}
        </div>
      </Card>
    </>
  );
};

export default MaintenanceCard;
