import { FC } from 'react';
import { StatusPage } from './StatusPage';
import ErrorContent from '@app/shared/components/status-pages/status-contents/error/ErrorContent';

const ErrorPage: FC = (): JSX.Element => {
  return (
    <StatusPage>
      <ErrorContent />
    </StatusPage>
  );
};

export default ErrorPage;
