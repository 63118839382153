import { SVGProps } from 'react';
const SvgEventCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#clip0_2255_14985)">
      <path
        d="M16 7.95333V15.1667C16 15.9044 15.4044 16.5 14.6667 16.5H1.33333C0.597778 16.5 0 15.9044 0 15.1667L0 1.83333C0 1.09778 0.597778 0.5 1.33333 0.5H11.4133V1.5H1.33333C1.14889 1.5 1 1.64889 1 1.83333V15.1667C1 15.3511 1.14889 15.5 1.33333 15.5H14.6667C14.8511 15.5 15 15.3511 15 15.1667V7.95333H16ZM15.6911 3.43111L14.9844 2.72444L7.05111 10.6578L4.32222 7.92889L3.61556 8.63556L7.05111 12.0711L15.6911 3.43333V3.43111Z"
        fill="#D04A02"
      />
    </g>
    <defs>
      <clipPath id="clip0_2255_14985">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgEventCopy;
