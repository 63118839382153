import { FC, Fragment } from 'react';
import styles from '../../widgets/total-equity/total-equity-chart.module.scss';
import classNames from 'classnames';
import { enumKeyByValue, groupBy } from '@app/shared/helpers';
import {
  CapitalStructure,
  Instrument,
} from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import {
  CaseDto,
  EquityInstrumentDto,
  ForecastDto,
} from '@app/shared/models/contracts/project-dto';
import {
  abbreviatedValueFormatter,
  amountValueFormatterProps,
  numberValueFormatter,
} from '@app/shared/formatters';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { calculateCapitalStructureDimensions } from '@app/modules/projects/inputs/capital-structure/capital-structure-block-size-calculator';
import {
  MAX_COLLAPSED_ITEMS,
  MAX_LABEL_ITEMS,
} from '@app/modules/projects/dashboard/widgets/total-equity/constants';
import SvgPlus from '@app/shared/icons/Plus';
import MinusIcon from '@app/shared/icons/MinusIcon';
import { labelTopValue } from '@app/shared/helpers/labeling-helpers';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import {
  Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockOwnershipDto,
  Pwerm2CalculationDashboardTotalEquityChartYearRankDto,
} from '@app/shared/models/contracts/pwerm2-calculation-results-dto';
import Pwerm2InstrumentBlockComponent from './Pwerm2InstrumentBlock';
import Pwerm2NetDebtColumn from './Pwerm2NetDebtTooltip';
import { useLocale } from '@app/core/hooks/useLocale';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';

const PARENT_CLASSNAME = 'total-equity-chart';

interface TotalEquityChartProps {
  isExpanded: boolean;
  filteredCase?: CaseDto;
  forecastData: ForecastDto[];
  setIsExpanded: (isExpanded: boolean) => void;
}

const Pwerm2TotalEquityChartPwerm: FC<TotalEquityChartProps> = ({
  isExpanded,
  setIsExpanded,
  forecastData,
  filteredCase,
}): JSX.Element => {
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.totalEquity
  );
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const caseItemWithMaxForecasts = getCaseWithMostForecasts(
    getCasesWithNonZeroProbability(project.pwermInput.cases)
  );
  const netDebtRank = -1;

  const chartForecastsCount = isExpanded
    ? forecastData.length
    : forecastData.length > MAX_COLLAPSED_ITEMS
    ? MAX_COLLAPSED_ITEMS
    : forecastData.length;

  const findNetDebtRank = (
    ranks: Record<string, Pwerm2CalculationDashboardTotalEquityChartYearRankDto>
  ): number => {
    const foundNetDebtRank = Object.values(ranks).find((rank) => rank.rank === netDebtRank);
    return foundNetDebtRank ? foundNetDebtRank.totalProceeds : 0;
  };

  const getCaseOrProjectNetDebt = (forecastCount: number): number => {
    const yearsArray = filteredCase
      ? Object.values(results.cases[filteredCase.caseId].chart.years)
      : Object.values(results.weighted.chart.years);

    const ranks = yearsArray[forecastCount]?.ranks;
    return ranks ? findNetDebtRank(ranks) : 0;
  };

  const createStructureForInstruments = <T extends Instrument>(instruments: T[]) =>
    calculateCapitalStructureDimensions(instruments, 100, 100, 0, 0, false);

  const createStructureInstruments = (
    amount: (instrument: EquityInstrumentDto) => number,
    proportionOfType: (instrument: EquityInstrumentDto) => number,
    proceedsByOwner: (
      instrument: EquityInstrumentDto
    ) => Pwerm2CalculationDashboardTotalEquityChartYearInstrumentBlockOwnershipDto
  ) =>
    project.equityInstruments.map((x) => ({
      ...x,
      amount: amount(x),
      proportionOfType: proportionOfType(x),
      proceedsByOwner: proceedsByOwner(x),
    }));

  const getCaseWeightedStructure = (
    ranks: Dictionary<Pwerm2CalculationDashboardTotalEquityChartYearRankDto>
  ) => {
    const distributions = Object.values(ranks).map((rank) => {
      return rank.instruments.map((instrument) => {
        return {
          instrumentId: instrument.id,
          value: instrument.totalProceeds,
          type: instrument.type,
          rank: rank.rank,
          proportionOfType: instrument.proportionOfType,
          proceedsByOwner: instrument.proceedsByOwner,
        };
      });
    });

    const distributionsByInstrument = groupBy(distributions.flat(), (x) => x.instrumentId);

    // removing net debt
    Object.keys(distributionsByInstrument).forEach((key) => {
      const instrument = distributionsByInstrument[key];
      const hasNetDebt = instrument.some((x) => x.rank === netDebtRank);

      if (hasNetDebt) {
        delete distributionsByInstrument[key];
      }
    });

    return createStructureForInstruments(
      createStructureInstruments(
        (x) => distributionsByInstrument[x.instrumentId]?.[0]?.value || 0,
        (x) => distributionsByInstrument[x.instrumentId]?.[0]?.proportionOfType || 0,
        (x) => distributionsByInstrument[x.instrumentId]?.[0]?.proceedsByOwner || {}
      )
    );
  };

  const getCaseWeightedStructureForForecastStack = (forecastYear: string, caseId?: string) => {
    const ranks = caseId
      ? results.cases[caseId].chart.years[forecastYear].ranks
      : results.weighted.chart.years[forecastYear].ranks;

    return getCaseWeightedStructure(ranks);
  };

  const getCaseWeightedStructureForEntryStack = (caseId?: string) => {
    const ranks = caseId
      ? results.cases[caseId].chart.entry.ranks
      : results.weighted.chart.entry.ranks;

    return getCaseWeightedStructure(ranks);
  };

  const renderRanks = (
    structure: CapitalStructure<EquityInstrumentDto>,
    forecastIndex?: number
  ) => {
    return structure.ranks.map((rank, index) => {
      return (
        <Fragment key={`instrument-${index}`}>
          <div
            key={'rank-block-' + index}
            style={{ height: rank.height + '%' }}
            className={classNames(styles['graph__rank-block'], {
              [styles['graph__rank-block--expanded']]: isExpanded,
            })}>
            {rank.instrumentBlocks.map((instrument, index) => {
              return (
                <Pwerm2InstrumentBlockComponent
                  key={'instrument-block-component' + index + instrument.instrumentId}
                  forecastIndex={forecastIndex}
                  instrument={instrument}
                  chartForecastsCount={chartForecastsCount}
                />
              );
            })}
          </div>
        </Fragment>
      );
    });
  };

  const totalInvestments = filteredCase
    ? results.cases[filteredCase.caseId].entryTotalEquity
    : results.weighted.entryTotalEquity;

  const stacksPerForecast = forecastData.map((f) =>
    filteredCase
      ? getCaseWeightedStructureForForecastStack(f.forecastYear, filteredCase.caseId)
      : getCaseWeightedStructureForForecastStack(f.forecastYear)
  );

  const entryStackStructure = filteredCase
    ? getCaseWeightedStructureForEntryStack(filteredCase.caseId)
    : getCaseWeightedStructureForEntryStack();

  const totalEquityPerForecast = filteredCase
    ? Object.values(results.cases[filteredCase.caseId].chart.years).map((x) => x.totalProceeds)
    : Object.values(results.weighted.chart.years).map((x) => x.totalProceeds);

  const getInitialNetDebtValue = () => {
    const ranks = filteredCase
      ? results.cases[filteredCase.caseId].chart.entry.ranks
      : results.weighted.chart.entry.ranks;

    return ranks ? findNetDebtRank(ranks) : 0;
  };

  const getMaxDebtValue = () => {
    const getMaxValue = (caseItem: CaseDto) =>
      Math.max(...caseItem.forecasts.map((_, i) => Number(getCaseOrProjectNetDebt(i))));

    if (filteredCase) {
      return getMaxValue(filteredCase);
    }

    return getMaxValue(caseItemWithMaxForecasts);
  };

  const maxEquity = Math.max(totalInvestments, ...totalEquityPerForecast);

  const calculatedLabelTopValue = labelTopValue(maxEquity);

  const createDebtTooltip = (debtAmount: string | undefined) => {
    return (
      <div>
        <h2 className="heading-2 heading-2--alternate">{l('_NetDebt')}</h2>
        <dl className="definition-list">
          <dt>{l('_TotalNetDebtTitle')}</dt>
          <dd>
            {numberValueFormatter({ value: Number(debtAmount), ...amountValueFormatterProps })}
          </dd>
        </dl>
      </div>
    );
  };

  const getMaxNetDebtAxisCount = () => {
    // eslint-disable-next-line big-number-rules/rounding
    const maxNetDebtLabels = Math.ceil(
      Math.max(getMaxDebtValue(), getInitialNetDebtValue()) /
        (calculatedLabelTopValue / MAX_LABEL_ITEMS)
    );
    return maxNetDebtLabels > MAX_LABEL_ITEMS ? MAX_LABEL_ITEMS : maxNetDebtLabels;
  };

  const shlInstrumentsExist = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );

  const prefShareInstrumentsExist = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  );

  const ordEquityInstrumentsExist = project.equityInstruments.some(
    (i) =>
      i.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && !i.isSweetEquity
  );

  const sweetEquityInstrumentsExist = project.equityInstruments.some(
    (i) =>
      i.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && i.isSweetEquity
  );

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div className={styles[`${PARENT_CLASSNAME}__legend`]}>
        <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
          <span
            className={classNames([
              styles[`${PARENT_CLASSNAME}__icon`],
              styles[`${PARENT_CLASSNAME}__icon--net-debt`],
            ])}
          />
          {l('_NetDebt')}
        </Button>
        {shlInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--sln`],
              ])}
            />
            {InstrumentType.ShareholderLoanNotes}
          </Button>
        )}
        {prefShareInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--pref`],
              ])}
            />
            {InstrumentType.PreferredShares}
          </Button>
        )}
        {ordEquityInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--ord`],
              ])}
            />
            {InstrumentType.OrdinaryEquity}
          </Button>
        )}
        {sweetEquityInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--sweet`],
              ])}
            />
            {l('_SweetEquityTitle')}
          </Button>
        )}
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__chart`]}>
        {[...Array(MAX_LABEL_ITEMS)].map((_, i) => (
          <div
            key={`grid-line-${i}`}
            className={classNames([
              styles[`${PARENT_CLASSNAME}__grid-line`],
              styles[`${PARENT_CLASSNAME}__grid-line--${i + 1}`],
            ])}
            data-grid-value={abbreviatedValueFormatter({
              value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * (i + 1),
              ...amountValueFormatterProps,
            })}
          />
        ))}
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
              [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
            })}
            style={{
              height: (100 / calculatedLabelTopValue) * totalInvestments + '%',
            }}>
            {renderRanks(entryStackStructure)}
          </div>
          {[...Array(chartForecastsCount)].map((_, i) => (
            <Fragment key={'instrument-wrapper-' + i}>
              <div
                className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
                  [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
                })}
                style={{
                  height: (100 / calculatedLabelTopValue) * totalEquityPerForecast[i] + '%',
                }}>
                {renderRanks(stacksPerForecast[i], i)}
              </div>
              {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                <div
                  className={classNames(styles['graph__expander-container'], {
                    [styles['graph__expander-container--secondary']]: !isExpanded,
                  })}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <div
        className={classNames([
          styles[`${PARENT_CLASSNAME}__chart`],
          styles[`${PARENT_CLASSNAME}__chart--alternate`],
          styles[`${PARENT_CLASSNAME}__chart--${getMaxNetDebtAxisCount()}`],
        ])}>
        {[...Array(getMaxNetDebtAxisCount() + 1)].map((_, i) => (
          <Fragment key={`grid-line-debt-${i}`}>
            <div
              className={classNames([
                styles[`${PARENT_CLASSNAME}__grid-line`],
                styles[`${PARENT_CLASSNAME}__grid-line--${i}`],
                styles[`${PARENT_CLASSNAME}__grid-line--${i}-negative`],
              ])}
              data-grid-value={abbreviatedValueFormatter({
                value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * -i,
                ...amountValueFormatterProps,
              })}
            />
          </Fragment>
        ))}
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
              [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
            })}>
            <div
              className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
                [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
              })}
              style={{
                height:
                  (100 / ((calculatedLabelTopValue / MAX_LABEL_ITEMS) * getMaxNetDebtAxisCount())) *
                    getInitialNetDebtValue() +
                  '%',
              }}>
              <Tooltip
                key="debt-tooltip"
                placement={TooltipPlacement.Right}
                content={createDebtTooltip(getInitialNetDebtValue()?.toString())}
                className={styles[`${PARENT_CLASSNAME}__debt-column`]}>
                <div className={styles[`${PARENT_CLASSNAME}__tooltip-placeholder`]} />
              </Tooltip>
            </div>
            <div className={styles[`${PARENT_CLASSNAME}__column-legend`]}>{l('_Entry')}</div>
          </div>
          {[...Array(chartForecastsCount)].map((_, i) => (
            <Fragment key={'debt-item-' + i}>
              <Pwerm2NetDebtColumn
                maxNetDebtAxisCount={getMaxNetDebtAxisCount()}
                isExpanded={isExpanded}
                chartForecastsCount={chartForecastsCount}
                forecastYear={forecastData[i]?.forecastYear}
                columnIndex={i}
                netDebt={Number(getCaseOrProjectNetDebt(i))}
                tooltipContent={createDebtTooltip(getCaseOrProjectNetDebt(i)?.toString())}
                labelTopValue={calculatedLabelTopValue}
              />
              {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                <div
                  className={classNames(styles['graph__expander-container'], {
                    [styles['graph__expander-container--secondary']]: !isExpanded,
                  })}>
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className={styles['graph__expander']}>
                    {isExpanded ? <MinusIcon /> : <SvgPlus />}
                  </button>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Pwerm2TotalEquityChartPwerm;
