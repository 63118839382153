import { DenominationMultiplier } from '@app/shared/models/contracts/enums/shared-enums';
import {
  OpmDistributionYearPlotDictionaryDto,
  OpmStackedDistributionSummaryData,
  OpmStackedDistributionYearPlotDictionaryDto,
} from '@app/shared/models/contracts/opm-calculation-results-dto';

export const updateDistributionChartData = (
  selectedDistributionData: OpmDistributionYearPlotDictionaryDto,
  denomination: keyof typeof DenominationMultiplier
) => {
  Object.keys(selectedDistributionData).forEach((year) => {
    const selectedYear = selectedDistributionData[year];
    const multiplier = DenominationMultiplier[denomination];
    const updatedCollaringFloor =
      selectedYear.collaringFloor === null ? null : selectedYear.collaringFloor * multiplier;
    const updatedCollaringCeiling =
      selectedYear.collaringCeiling === null ? null : selectedYear.collaringCeiling * multiplier;
    const updatedDefaultViewMinimum = selectedYear.defaultViewMinimum * multiplier;
    const updatedDefaultViewMaximum = selectedYear.defaultViewMaximum * multiplier;
    const updatedFirstQuartile = selectedYear.firstQuartile * multiplier;
    const updatedThirdQuartile = selectedYear.thirdQuartile * multiplier;
    const updatedDataPoints = selectedYear.dataPoints.map((point) => {
      return {
        ...point,
        x: Number(point.x * multiplier),
        simulatedValue: Number(point.simulatedValue * multiplier),
        totalEquity: Number(point.totalEquity * multiplier),
        totalShareholderDebt: Number(point.totalShareholderDebt * multiplier),
        totalOrdinaryEquity: Number(point.totalOrdinaryEquity * multiplier),
        instrumentPresentValue:
          point.instrumentPresentValue === null
            ? null
            : Number(point.instrumentPresentValue * multiplier),
        instrumentProceeds:
          point.instrumentProceeds === null ? null : Number(point.instrumentProceeds * multiplier),
      };
    });
    const updatedMeanValue = {
      ...selectedYear.meanValue,
      simulatedValue: Number(selectedYear.meanValue.simulatedValue * multiplier),
      totalEquityProceeds: Number(selectedYear.meanValue.totalEquityProceeds * multiplier),
      totalOrdinaryEquity: Number(selectedYear.meanValue.totalOrdinaryEquity * multiplier),
      totalShareholderDebt: Number(selectedYear.meanValue.totalShareholderDebt * multiplier),
      instrumentPresentValue:
        selectedYear.meanValue.instrumentPresentValue === null
          ? null
          : Number(selectedYear.meanValue.instrumentPresentValue * multiplier),
      instrumentProceeds:
        selectedYear.meanValue.instrumentProceeds === null
          ? null
          : Number(selectedYear.meanValue.instrumentProceeds * multiplier),
    };

    selectedDistributionData[year] = {
      ...selectedYear,
      dataPoints: updatedDataPoints,
      collaringFloor: updatedCollaringFloor,
      collaringCeiling: updatedCollaringCeiling,
      defaultViewMinimum: updatedDefaultViewMinimum,
      defaultViewMaximum: updatedDefaultViewMaximum,
      firstQuartile: updatedFirstQuartile,
      thirdQuartile: updatedThirdQuartile,
      meanValue: updatedMeanValue,
    };
  });
  return selectedDistributionData;
};

export const updateStackedDistributionChartData = (
  selectedDistributionData: OpmStackedDistributionYearPlotDictionaryDto,
  denomination: keyof typeof DenominationMultiplier
) => {
  Object.keys(selectedDistributionData).forEach((year) => {
    const selectedYear = selectedDistributionData[year];
    const multiplier = DenominationMultiplier[denomination];

    const updatedDataPoints = selectedYear.dataPoints.map((point) => {
      return {
        ...point,
        enterpriseValue: Number(point.enterpriseValue * multiplier),
        netDebt: Number(point.netDebt * multiplier),
        netExitProceeds: Number(point.netExitProceeds * multiplier),
        ordinaryEquity: Number(point.ordinaryEquity * multiplier),
        preferenceShares: Number(point.preferenceShares * multiplier),
        shareholderLoanNotes: Number(point.shareholderLoanNotes * multiplier),
        sweetEquity: Number(point.sweetEquity * multiplier),
        totalEquityProceeds: Number(point.totalEquityProceeds * multiplier),
        instrumentDistributions: point.instrumentDistributions.map((instrument) => {
          return {
            ...instrument,
            distributionAmount: Number(instrument.distributionAmount * multiplier),
          };
        }),
      };
    });

    const updateSummaryData = (
      summaryData: OpmStackedDistributionSummaryData,
      multiplier: number
    ): OpmStackedDistributionSummaryData => {
      return {
        ...summaryData,
        enterpriseValue: Number(summaryData.enterpriseValue * multiplier),
        equity: Number(summaryData.equity * multiplier),
        totalValuedInstrumentProceeds: Number(
          summaryData.totalValuedInstrumentProceeds * multiplier
        ),
        instruments: Object.keys(summaryData.instruments).reduce(
          (updatedInstruments: { [key: string]: number }, key) => {
            updatedInstruments[key] = summaryData.instruments[key] * multiplier;
            return updatedInstruments;
          },
          {}
        ),
      };
    };

    const updatedFirstQuartile = updateSummaryData(selectedYear.firstQuartile, multiplier);
    const updatedMeanValue = updateSummaryData(selectedYear.meanValue, multiplier);
    const updatedThirdQuartile = updateSummaryData(selectedYear.thirdQuartile, multiplier);

    selectedDistributionData[year] = {
      ...selectedYear,
      dataPoints: updatedDataPoints,
      firstQuartile: updatedFirstQuartile,
      meanValue: updatedMeanValue,
      thirdQuartile: updatedThirdQuartile,
    };
  });
  return selectedDistributionData;
};
