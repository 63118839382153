import i18next, { InitOptions } from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { resources, supportedLocales } from './translations';

export const i18nSetup = () => {
  const initOptions: InitOptions = {
    resources: resources,
    fallbackLng: 'en',
    load: 'all',
    supportedLngs: supportedLocales,
    defaultNS: 'translations',
  };

  i18next.use(initReactI18next).use(languageDetector).init(initOptions);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const l = (key: translationKeys, options?: any): string =>
  i18next.t(key, { ...options, returnDetails: false }) as string;

export type translationKeys = keyof typeof resources.en.translations;
