import { FC, Fragment } from 'react';
import classNames from 'classnames';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useShouldHighlight } from '../custom-hooks/useShouldHighlight';
import { selectCasesStructure } from '@app/core/store/project-slice-selectors';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';

type TableSectionValuesPerForecastProps = {
  title: string;
  renderCell: (props: { caseItem: CaseDto }) => JSX.Element;
  renderAdditionalCell?: (props: { caseItem: CaseDto }) => JSX.Element;
  highlight?: boolean;
  additionalTitleClassNames?: string;
  additionalCellClassNames?: string;
  additionalSpacerTitleClassNames?: string;
  renderSpacer?: boolean;
  testId?: string;
};

const TableSectionValuesPerCase: FC<TableSectionValuesPerForecastProps> = ({
  title,
  renderCell,
  renderAdditionalCell,
  highlight,
  additionalTitleClassNames,
  additionalCellClassNames,
  additionalSpacerTitleClassNames,
  renderSpacer = true,
  testId,
}) => {
  const casesStructure = useAppSelector(selectCasesStructure);

  const shouldHighlightCell = useShouldHighlight();

  return (
    <>
      <tr>
        <th
          className={classNames(
            'table-primary__cell--vertical-separator',
            additionalTitleClassNames
          )}>
          {title && title}
        </th>
        {casesStructure.map((caseItem, index) => (
          <Fragment key={index}>
            <td
              className={classNames(
                'table-primary__cell--vertical-separator',
                additionalCellClassNames
              )}>
              {renderAdditionalCell !== undefined ? renderAdditionalCell({ caseItem }) : undefined}
            </td>
            <td
              key={index}
              data-testid={testId ? `${testId}-c${index}` : undefined}
              colSpan={caseItem.forecasts.length}
              className={classNames(
                'table-primary__cell--vertical-separator',
                additionalCellClassNames,
                {
                  'table-primary__cell--highlight':
                    highlight && shouldHighlightCell(caseItem.caseId),
                }
              )}>
              {renderCell({ caseItem })}
            </td>
          </Fragment>
        ))}
      </tr>
      {renderSpacer && (
        <TableSpacerRow additionalTitleClassNames={additionalSpacerTitleClassNames} />
      )}
    </>
  );
};

export default TableSectionValuesPerCase;
