import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ITelemetryItem } from '@microsoft/applicationinsights-core-js';
import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import store from '@app/core/store/store';

enum AppInsightsCustomProperties {
  VERSION_TAG = 'versionTag',
  BRANCH_NAME = 'branchName',
  COMMIT_ID = 'commitId',
}

const excludedDomainTracking = [
  'browser-intake-datadoghq.eu',
  'browser-intake-datadoghq.com',
  'browser-intake-us3-datadoghq.com',
  'browser-intake-us5-datadoghq.com',
  'browser-intake-ddog-gov.com',
  'browser-intake-ap1-datadoghq.com',
];

export const loadAppInsights = () => {
  const uiState = store.getState().uiValues;
  if (uiState.appInsightsConnectionString) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: uiState.appInsightsConnectionString,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        correlationHeaderExcludedDomains: excludedDomainTracking,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        distributedTracingMode: DistributedTracingModes.W3C,
      },
    });

    const telemetryInitializer = (envelope: ITelemetryItem) => {
      const telemetryItem = envelope;
      // Setting custom fields in monitoring
      if (telemetryItem.baseData) {
        telemetryItem.baseData.properties = { ...telemetryItem.baseData.properties };
        telemetryItem.baseData.properties[AppInsightsCustomProperties.VERSION_TAG] =
          uiState.versionTag;
        telemetryItem.baseData.properties[AppInsightsCustomProperties.COMMIT_ID] = uiState.commitId;
        telemetryItem.baseData.properties[AppInsightsCustomProperties.BRANCH_NAME] =
          uiState.branchName;
      }
    };

    appInsights.loadAppInsights().addTelemetryInitializer(telemetryInitializer);
    appInsights.trackPageView();
  }
};
