import { FC } from 'react';
import styles from './dashboard-header.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { dateFormatter } from '@app/shared/formatters';
import CurrencyLabel from '@app/shared/components/currency-label/CurrencyLabel';
import { useLocale } from '@app/core/hooks/useLocale';

interface DashboardHeaderProps {
  isChartScaled?: boolean;
}

const PARENT_CLASSNAME = 'header';
export const DashboardHeader: FC<DashboardHeaderProps> = ({
  isChartScaled = false,
}): JSX.Element => {
  const {
    projectDraft: { name, valuationDate },
  } = useAppSelector((state) => state.project);
  const { l } = useLocale();
  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      <dl className={styles[`${PARENT_CLASSNAME}__item-list`]}>
        <dt className={styles[`${PARENT_CLASSNAME}__item-label`]}>Project name</dt>
        <dd className={styles[`${PARENT_CLASSNAME}__item-value`]}>{name}</dd>
        <dt className={styles[`${PARENT_CLASSNAME}__item-label`]}>{l('_ValuationDate')}</dt>
        <dd className={styles[`${PARENT_CLASSNAME}__item-value`]}>
          {dateFormatter(valuationDate.toString(), { dateStyle: 'short' })}
        </dd>
      </dl>
      <CurrencyLabel isChartScaled={isChartScaled} />
    </div>
  );
};
