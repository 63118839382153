import type { SVGProps } from 'react';
const SvgCalculator = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="#fff"
      d="M16 0v13.632c0 .203-.059.39-.176.56a.984.984 0 0 1-.464.368 20.256 20.256 0 0 1-7.28 1.392 21.23 21.23 0 0 1-7.408-1.376A1.023 1.023 0 0 1 0 13.728V0h16Zm-.992.992H.992v12.64c2.325.875 4.688 1.317 7.088 1.328a19.51 19.51 0 0 0 6.528-1.184l.4-.144V.992ZM9.664 11.536c.181 0 .336.064.464.192a.609.609 0 0 1 .208.464.66.66 0 0 1-.208.48.632.632 0 0 1-.464.192.632.632 0 0 1-.464-.192.687.687 0 0 1-.192-.48c0-.181.064-.336.192-.464a.632.632 0 0 1 .464-.192Zm-6.656 0c.181 0 .336.064.464.192a.632.632 0 0 1 .192.464.687.687 0 0 1-.192.48.632.632 0 0 1-.464.192.687.687 0 0 1-.48-.192.687.687 0 0 1-.192-.48c0-.181.064-.336.192-.464a.687.687 0 0 1 .48-.192Zm3.328 0c.181 0 .336.064.464.192a.609.609 0 0 1 .208.464.66.66 0 0 1-.208.48.632.632 0 0 1-.464.192.687.687 0 0 1-.48-.192.687.687 0 0 1-.192-.48c0-.181.064-.336.192-.464a.687.687 0 0 1 .48-.192Zm6.672 0c.181 0 .336.064.464.192a.632.632 0 0 1 .192.464.687.687 0 0 1-.192.48.632.632 0 0 1-.464.192.687.687 0 0 1-.48-.192.687.687 0 0 1-.192-.48c0-.181.064-.336.192-.464a.687.687 0 0 1 .48-.192Zm-3.344-3.12c.181 0 .336.07.464.208a.609.609 0 0 1 .208.464c0 .181-.07.336-.208.464a.632.632 0 0 1-.464.192.632.632 0 0 1-.464-.192.632.632 0 0 1-.192-.464c0-.181.064-.336.192-.464a.609.609 0 0 1 .464-.208Zm-6.656 0c.181 0 .336.07.464.208a.632.632 0 0 1 .192.464.632.632 0 0 1-.192.464.632.632 0 0 1-.464.192.687.687 0 0 1-.48-.192.632.632 0 0 1-.192-.464c0-.181.064-.336.192-.464a.66.66 0 0 1 .48-.208Zm3.328 0c.181 0 .336.07.464.208a.609.609 0 0 1 .208.464c0 .181-.07.336-.208.464a.632.632 0 0 1-.464.192.687.687 0 0 1-.48-.192.632.632 0 0 1-.192-.464c0-.181.064-.336.192-.464a.66.66 0 0 1 .48-.208Zm6.672 0c.181 0 .336.07.464.208a.632.632 0 0 1 .192.464.632.632 0 0 1-.192.464.632.632 0 0 1-.464.192.687.687 0 0 1-.48-.192.632.632 0 0 1-.192-.464c0-.181.064-.336.192-.464a.66.66 0 0 1 .48-.208Zm.496-6.512v4.224H2.4V1.904h11.104Zm-1.008 1.008H3.408v2.224h9.088V2.912Z"
    />
  </svg>
);
export default SvgCalculator;
