import { FC, ReactNode } from 'react';
import styles from './dropdown-menu.module.scss';
import Chevron from '@app/shared/icons/Chevron';
import classNames from 'classnames';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { useDropdown } from '@app/shared/components/dropdown/useDropdown';
import { ChevronVariation } from '@app/shared/components/dropdown/DropdownEnums';

interface DropdownMenuProps {
  children?: ReactNode | undefined;
  chevronVariation?: ChevronVariation;
  dropdownContent?: ReactNode | undefined;
  dropdownTriggerIcon?: ReactNode | undefined;
  ignoreReadOnly?: boolean;
  expandWithContent?: boolean;
  borderless?: boolean;
  disabled?: boolean;
  appearance?: ButtonAppearance;
  buttonClassName?: string;
  menuDropdownClassName?: string;
  useDefaultSvgColour?: boolean;
  dropdownStartIcon?: JSX.Element;
  dropdownButtonSize?: ButtonSize;
  ariaLabel?: string;
}

export const DropdownMenu: FC<DropdownMenuProps> = ({
  children,
  ariaLabel,
  dropdownContent,
  dropdownTriggerIcon,
  chevronVariation,
  ignoreReadOnly = false,
  borderless,
  expandWithContent,
  disabled,
  appearance = ButtonAppearance.CLEAN,
  buttonClassName,
  menuDropdownClassName = '__menu',
  useDefaultSvgColour = false,
  dropdownStartIcon,
  dropdownButtonSize,
  ...restProps
}) => {
  const PARENT_CLASSNAME = 'dropdown-menu';
  const { ref, state, toggle } = useDropdown({});

  return (
    <div
      ref={ref}
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--open`]]: state.open,
        [styles[`${PARENT_CLASSNAME}--open-custom-dropdown`]]: state.open,
      })}
      {...restProps}>
      <Button
        onClick={toggle}
        aria-label={ariaLabel}
        appearance={appearance}
        ignoreReadOnly={ignoreReadOnly}
        disabled={disabled}
        className={buttonClassName}
        startIcon={dropdownStartIcon}
        size={dropdownButtonSize}>
        {dropdownContent}
        {dropdownTriggerIcon && useDefaultSvgColour ? (
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__custom-icon`], {
              [styles[`${PARENT_CLASSNAME}__custom-icon--down`]]: state.open,
              [styles[`${PARENT_CLASSNAME}__chevrom--custom`]]: state.open && dropdownTriggerIcon,
            })}>
            {dropdownTriggerIcon}
          </div>
        ) : dropdownTriggerIcon && !useDefaultSvgColour ? (
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__chevron`], {
              [styles[`${PARENT_CLASSNAME}__chevron--down`]]: state.open,
              [styles[`${PARENT_CLASSNAME}__chevron--custom`]]: state.open && dropdownTriggerIcon,
            })}>
            {dropdownTriggerIcon}
          </div>
        ) : (
          <Chevron
            className={classNames(styles[`${PARENT_CLASSNAME}__chevron`], {
              [styles[`${PARENT_CLASSNAME}__chevron--${chevronVariation}`]]: chevronVariation,
              [styles[`${PARENT_CLASSNAME}__chevron--down`]]: state.open,
              [styles[`${PARENT_CLASSNAME}__chevron--custom`]]: state.open && dropdownTriggerIcon,
            })}
          />
        )}
      </Button>
      <ul
        className={classNames([styles[`${PARENT_CLASSNAME}${menuDropdownClassName}`]], {
          [styles[`${PARENT_CLASSNAME}__menu--expand-with-content`]]: expandWithContent,
          [styles[`${PARENT_CLASSNAME}__menu--custom-dropdown`]]: dropdownTriggerIcon,
          [styles[`${PARENT_CLASSNAME}__menu--borderless`]]: borderless,
        })}>
        {children}
      </ul>
    </div>
  );
};
