import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { getForecastById, selectCasesStructure } from '@app/core/store/project-slice-selectors';
import CurrencyLabel from '@app/shared/components/currency-label/CurrencyLabel';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { Fragment } from 'react';
import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import TableSectionValuesPerForecast from '../components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  enteredPercentageViewRules,
  monthDayCellViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { useLocale } from '@app/core/hooks/useLocale';

export const Pwerm2WaterfallTableHeader = () => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const casesStructure = useAppSelector(selectCasesStructure);

  return (
    <thead className="table-primary__sticky-section">
      <tr>
        <th className="table-primary__cell--vertical-separator">
          <CurrencyLabel />
        </th>
        {casesStructure.map((caseItem, index) => (
          <Fragment key={index}>
            <th className="table-primary__cell--vertical-separator" />
            <th
              colSpan={caseItem.forecasts.length}
              className="table-primary__cell--header-tertiary table-primary__cell--center table-primary__cell--vertical-separator">
              <span className={styles['case-name']}>
                {caseItem.narrative} {' - '}
                {numberValueFormatter({
                  value: Number(caseItem.probability),
                  ...percentageValueFormatterProps,
                })}
              </span>
            </th>
          </Fragment>
        ))}
      </tr>
      <TableSectionValuesPerForecast
        title={l('_ExitDate')}
        renderAdditionalCell={() => (
          <CellValue
            value={project.valuationDate}
            {...monthDayCellViewRules}
            theme={CellValueTheme.Tertiary}
          />
        )}
        renderCell={({ caseItem, forecast }) => (
          <CellValue
            value={getForecastById(project, caseItem.caseId, forecast.forecastId)?.forecastYear}
            {...monthDayCellViewRules}
          />
        )}
        renderSpacer={false}
      />
      <TableSectionValuesPerForecast
        title={l('_ExitProbability')}
        renderCell={({ caseItem, forecast }) => (
          <CellValue
            value={getForecastById(project, caseItem.caseId, forecast.forecastId)?.probability}
            {...enteredPercentageViewRules}
          />
        )}
        renderSpacer={false}
      />
    </thead>
  );
};
