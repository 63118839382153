import FormField, {
  FormFieldProps,
} from '@app/shared/components/form-controls/form-field/FormField';
import { ChangeEvent, FC, useState } from 'react';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { useFormContext } from 'react-hook-form';
import XMark from '@app/shared/icons/XMark';
import MagnifyingGlass from '@app/shared/icons/MagnifyingGlass';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import styles from '@app/shared/components/form-controls/search-field/search-field.module.scss';
import { SEARCH_MIN_CHAR_COUNT } from '@app/shared/constants/configs/search';

interface SearchFieldProps extends FormFieldProps {
  onClear?: () => void;
}

const PARENT_CLASSNAME = 'search-field';
export const SearchField: FC<SearchFieldProps> = ({ name, onChange, onClear, ...rest }) => {
  const [isMessageVisible, setIsMessageVisible] = useState(false);

  const formMethods = useFormContext();

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <FormField
        name={name}
        aria-label="Search all projects"
        aria-describedby={`${name}-message`}
        {...textFieldFormattingProps}
        shouldTrimOnBlur
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.value.length > 0 && event.target.value.length < SEARCH_MIN_CHAR_COUNT) {
            setIsMessageVisible(true);
          } else {
            setIsMessageVisible(false);
          }

          onChange && onChange(event);
        }}
        onFocus={(event) => {
          if (event.target.value.length > 0 && event.target.value.length < SEARCH_MIN_CHAR_COUNT) {
            setIsMessageVisible(true);
          }
        }}
        onBlur={() => setIsMessageVisible(false)}
        prefix={() => <MagnifyingGlass />}
        suffix={
          formMethods.getValues(name).length
            ? () => (
                <Button
                  appearance={ButtonAppearance.CLEAN}
                  className={styles[`${PARENT_CLASSNAME}__suffix-button`]}
                  size={ButtonSize.FLUID}
                  onClick={() => {
                    formMethods.resetField(name, { defaultValue: '' });
                    onClear && onClear();
                  }}>
                  <XMark />
                </Button>
              )
            : undefined
        }
        isGapless
        {...rest}
      />

      {isMessageVisible && (
        <div id={`${name}-message`} className={styles[`${PARENT_CLASSNAME}__message`]}>
          Type at least 3 characters to search
        </div>
      )}
    </div>
  );
};
