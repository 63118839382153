import { createIntl, createIntlCache } from '@formatjs/intl';
import { LOCALE } from '@app/shared/constants/locale';

export const intlCache = createIntlCache();

export const INTL_CACHE = createIntl(
  {
    locale: LOCALE,
  },
  intlCache
);
