import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

const InfoRoundedSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon stroke="transparent" viewBox="0 0 20 20" {...props}>
      <path
        d="M0 10C-4.82823e-07 4.47715 4.47715 4.82823e-07 10 0C15.5228 -4.82823e-07 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 4.82823e-07 15.5228 0 10Z"
        opacity="0.3"
      />
      <path d="M9 8C9 7.44772 9.44771 7 10 7C10.5523 7 11 7.44772 11 8V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V8Z" />
      <path d="M9 5C9 4.44772 9.44771 4 10 4C10.5523 4 11 4.44772 11 5C11 5.55229 10.5523 6 10 6C9.44771 6 9 5.55229 9 5Z" />
    </SvgIcon>
  );
};

export default memo(InfoRoundedSvg);
