import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

const CalendarSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <rect x="2" y="3.5" width="20" height="20" rx="1.5" />
      <path
        d="M4 6.5H20V20C20 20.8284 19.3284 21.5 18.5 21.5H5.5C4.67157 21.5 4 20.8284 4 20V6.5Z"
        fill="white"
      />
      <rect x="5" y="0.5" width="3" height="4" rx="1" />
      <rect x="16" y="0.5" width="3" height="4" rx="1" />
    </SvgIcon>
  );
};

export default memo(CalendarSvg);
