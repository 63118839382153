import { configureStore, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import pwermCalculationSlice, { calculate } from './pwerm-calculation-slice';
import pwerm2CalculationSlice, { pwerm2Calculate } from './pwerm2-calculation-slice';
import notificationSlice from './notification-slice';
import projectListSlice, {
  copyProject,
  deleteProject,
  fetchProjectList,
  saveNewProject,
} from './project-list-slice';
import projectSlice, {
  deleteExistingProject,
  discardChanges,
  fetchAndRecalculate,
  fetchProjectDraftById,
  fetchProjectUserList,
  fetchProjectResults,
  removeUsersAccessFromProject,
  giveUserAccessToProject,
  saveExistingProject,
  updateProjectDraft,
  validateProject,
} from './project-slice';
import uiValuesSlice, {
  decreaseObtrusiveRequestsInProgressCount,
  decreaseUnobtrusiveRequestsInProgressCount,
  increaseObtrusiveRequestsInProgressCount,
  increaseUnobtrusiveRequestsInProgressCount,
} from '@core/store/ui-values-slice';
import userInfoSlice, { fetchUserInfo } from '@core/store/user-info.slice';
import userListSlice, { fetchUserList, removeUser, updateUser } from '@core/store/user-list-slice';
import opmCalculationSlice, { calculateOpmSampleWaterfall } from './opm-calculation-slice';
import capitalStructureSlice from './capital-structure-slice';
import exportSlice from './export-slice';

const listenerMiddleware = createListenerMiddleware();

const blockingActions = [
  fetchProjectList,
  updateUser,
  saveNewProject,
  copyProject,
  deleteProject,
  fetchProjectDraftById,
  removeUsersAccessFromProject,
  giveUserAccessToProject,
  fetchProjectUserList,
  saveExistingProject,
  deleteExistingProject,
  fetchUserInfo,
  validateProject,
  fetchUserList,
  removeUser,
  calculateOpmSampleWaterfall,
];
const nonBlockingActions = [
  fetchAndRecalculate,
  discardChanges,
  fetchProjectResults,
  updateProjectDraft,
  calculate,
  pwerm2Calculate,
];

listenerMiddleware.startListening({
  matcher: isAnyOf(...blockingActions.map((action) => action.pending)),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(increaseObtrusiveRequestsInProgressCount());
  },
});
listenerMiddleware.startListening({
  matcher: isAnyOf(
    ...blockingActions.map((action) => action.fulfilled),
    ...blockingActions.map((action) => action.rejected)
  ),
  effect: (action, listenerApi) => {
    listenerApi.dispatch(decreaseObtrusiveRequestsInProgressCount());
  },
});

listenerMiddleware.startListening({
  matcher: isAnyOf(...nonBlockingActions.map((action) => action.pending)),
  effect: async (action, listenerApi) => {
    listenerApi.dispatch(increaseUnobtrusiveRequestsInProgressCount());
  },
});
listenerMiddleware.startListening({
  matcher: isAnyOf(
    ...nonBlockingActions.map((action) => action.fulfilled),
    ...nonBlockingActions.map((action) => action.rejected)
  ),
  effect: (action, listenerApi) => {
    listenerApi.dispatch(decreaseUnobtrusiveRequestsInProgressCount());
  },
});

const store = configureStore({
  reducer: {
    pwermCalculation: pwermCalculationSlice,
    pwerm2Calculation: pwerm2CalculationSlice,
    opmCalculation: opmCalculationSlice,
    notification: notificationSlice,
    project: projectSlice,
    projectList: projectListSlice,
    uiValues: uiValuesSlice,
    userInfo: userInfoSlice,
    userList: userListSlice,
    capitalStructure: capitalStructureSlice,
    exportOpm: exportSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
