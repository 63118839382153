import { FC, Fragment } from 'react';
import styles from './total-equity-chart.module.scss';
import classNames from 'classnames';
import { enumKeyByValue, groupBy, sumBy } from '@app/shared/helpers';
import {
  CapitalStructure,
  Instrument,
} from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import {
  CaseDto,
  EquityInstrumentDto,
  ForecastDto,
} from '@app/shared/models/contracts/project-dto';
import { abbreviatedValueFormatter, amountValueFormatterProps } from '@app/shared/formatters';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { getCaseProbabilityWeightedValue } from '@app/shared/helpers/get-weighted-values';
import {
  getCalculatedValueForForecast,
  getCaseValueNetDebtItemsTotal,
  getNetDebtAtExit,
} from '@core/store/project-slice-selectors';
import { selectCalculatedVariablesResults } from '@app/core/store/pwerm-calculation-slice-selectors';
import { calculateCapitalStructureDimensions } from '@app/modules/projects/inputs/capital-structure/capital-structure-block-size-calculator';
import {
  MAX_COLLAPSED_ITEMS,
  MAX_LABEL_ITEMS,
} from '@app/modules/projects/dashboard/widgets/total-equity/constants';
import SvgPlus from '@app/shared/icons/Plus';
import MinusIcon from '@app/shared/icons/MinusIcon';
import { useGetCalculatedValue } from '@app/core/store/pwerm-calculated-values-selectors';
import { labelTopValue } from '@app/shared/helpers/labeling-helpers';
import InstrumentBlockComponent from '@app/modules/projects/dashboard/widgets/total-equity/InstrumentBlock';
import NetDebtColumn from '@app/modules/projects/dashboard/widgets/total-equity/NetDebtTooltip';
import {
  DenominationMultiplier,
  InstrumentType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';

const PARENT_CLASSNAME = 'total-equity-chart';

interface TotalEquityChartProps {
  isExpanded: boolean;
  filteredCase?: CaseDto;
  forecastData: ForecastDto[];
  setIsExpanded: (isExpanded: boolean) => void;
}

const TotalEquityChartPwerm: FC<TotalEquityChartProps> = ({
  isExpanded,
  setIsExpanded,
  forecastData,
  filteredCase,
}): JSX.Element => {
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const caseItemWithMaxForecasts = getCaseWithMostForecasts(project.pwermInput.cases);

  const chartForecastsCount = isExpanded
    ? forecastData.length
    : forecastData.length > MAX_COLLAPSED_ITEMS
    ? MAX_COLLAPSED_ITEMS
    : forecastData.length;

  const getCaseOrProjectNetDebt = (forecastCount?: number) => {
    return filteredCase
      ? getNetDebtAtExit(filteredCase?.forecasts[forecastCount ?? 0])
      : getCalculatedValueForForecast(
          project,
          calculatedVariables,
          caseItemWithMaxForecasts.caseId,
          caseItemWithMaxForecasts.forecasts[forecastCount ?? 0]?.forecastId,
          'Case weighted net debt'
        );
  };

  const { getCalculatedValue } = useGetCalculatedValue();

  const createStructureForInstruments = <T extends Instrument>(instruments: T[]) =>
    calculateCapitalStructureDimensions(instruments, 100, 100, 0, 0, false);

  const createStructureInstruments = (amount: (instrument: EquityInstrumentDto) => number) =>
    project.equityInstruments.map((x) => ({
      ...x,
      amount: amount(x),
    }));

  const getCaseWeightedStructureForForecastStack = (forecastYear: string, caseId?: string) => {
    const distributions = getCalculatedValue(
      'InstrumentWeightedAveragePaidDistributions',
      caseId ? [] : ['caseId'],
      { forecastYear, ...(caseId && { caseId: caseId }) }
    );

    const distributionsByInstrument = groupBy(distributions, (x) => x.instrumentId!);

    return createStructureForInstruments(
      createStructureInstruments((x) => distributionsByInstrument[x.instrumentId]?.[0]?.value || 0)
    );
  };

  const renderRanks = (
    structure: CapitalStructure<EquityInstrumentDto>,
    forecastIndex?: number
  ) => {
    return structure.ranks.map((rank, index) => {
      return (
        <Fragment key={`instrument-${index}`}>
          <div
            key={'rank-block-' + index}
            style={{ height: rank.height + '%' }}
            className={classNames(styles['graph__rank-block'], {
              [styles['graph__rank-block--expanded']]: isExpanded,
            })}>
            {rank.instrumentBlocks.map((instrument, index) => {
              return (
                <InstrumentBlockComponent
                  key={'instrument-block-component' + index + instrument.instrumentId}
                  filteredCase={filteredCase}
                  forecastData={forecastData}
                  forecastIndex={forecastIndex}
                  instrument={instrument}
                  chartForecastsCount={chartForecastsCount}
                />
              );
            })}
          </div>
        </Fragment>
      );
    });
  };

  const entryStackStructure = createStructureForInstruments(
    createStructureInstruments((x) => sumBy(x.ownership, (c) => Number(c.amount) || 0))
  );

  const totalInvestments = sumBy(
    project.equityInstruments.flatMap((x) => x.ownership),
    (x) => x.amount ?? 0
  );

  const stacksPerForecast = forecastData.map((f) =>
    filteredCase
      ? getCaseWeightedStructureForForecastStack(f.forecastYear, filteredCase.caseId)
      : getCaseWeightedStructureForForecastStack(f.forecastYear)
  );
  const totalEquityPerForecast = stacksPerForecast.map((x) =>
    sumBy(
      x.ranks.flatMap((r) => r.instrumentBlocks),
      (i) => i.amount
    )
  );

  const getNetDebtValue = () => {
    return Number(
      filteredCase
        ? getCaseValueNetDebtItemsTotal(filteredCase)
        : getCaseProbabilityWeightedValue({
            project,
            getValue: ({ caseItem }) => {
              return getCaseValueNetDebtItemsTotal(caseItem);
            },
          })
    );
  };

  const getMaxDebtValue = () => {
    const getMaxValue = (caseItem: CaseDto) =>
      Math.max(...caseItem.forecasts.map((_, i) => Number(getCaseOrProjectNetDebt(i))));

    if (filteredCase) {
      return getMaxValue(filteredCase);
    }

    return getMaxValue(caseItemWithMaxForecasts);
  };

  const maxEquity = Math.max(totalInvestments, ...totalEquityPerForecast);

  const calculatedLabelTopValue = labelTopValue(maxEquity);

  const createDebtTooltip = (debtAmount: string | undefined) => {
    return (
      <div>
        <h2 className="heading-2 heading-2--alternate">Net Debt</h2>
        <dl className="definition-list">
          <dt>Total Net Debt</dt>
          <dd>
            {abbreviatedValueFormatter({
              value: Number(debtAmount) * multiplier,
            })}
          </dd>
        </dl>
      </div>
    );
  };

  const getMaxNetDebtAxisCount = () => {
    const maxNetDebtLabels = Math.ceil(
      getMaxDebtValue() / (calculatedLabelTopValue / MAX_LABEL_ITEMS)
    );
    return maxNetDebtLabels > MAX_LABEL_ITEMS ? MAX_LABEL_ITEMS : maxNetDebtLabels;
  };

  const shlInstrumentsExist = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );

  const prefShareInstrumentsExist = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  );

  const ordEquityInstrumentsExist = project.equityInstruments.some(
    (i) =>
      i.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && !i.isSweetEquity
  );

  const sweetEquityInstrumentsExist = project.equityInstruments.some(
    (i) =>
      i.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && i.isSweetEquity
  );

  return (
    <div aria-hidden className={styles[PARENT_CLASSNAME]}>
      <div className={styles[`${PARENT_CLASSNAME}__legend`]}>
        <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
          <span
            className={classNames([
              styles[`${PARENT_CLASSNAME}__icon`],
              styles[`${PARENT_CLASSNAME}__icon--net-debt`],
            ])}
          />
          Net Debt
        </Button>
        {shlInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--sln`],
              ])}
            />
            {InstrumentType.ShareholderLoanNotes}
          </Button>
        )}
        {prefShareInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--pref`],
              ])}
            />
            {InstrumentType.PreferredShares}
          </Button>
        )}
        {ordEquityInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--ord`],
              ])}
            />
            {InstrumentType.OrdinaryEquity}
          </Button>
        )}
        {sweetEquityInstrumentsExist && (
          <Button appearance={ButtonAppearance.CLEAN} ignoreReadOnly>
            <span
              className={classNames([
                styles[`${PARENT_CLASSNAME}__icon`],
                styles[`${PARENT_CLASSNAME}__icon--sweet`],
              ])}
            />
            Sweet Equity
          </Button>
        )}
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__chart`]}>
        {[...Array(MAX_LABEL_ITEMS)].map((_, i) => (
          <div
            key={`grid-line-${i}`}
            className={classNames([
              styles[`${PARENT_CLASSNAME}__grid-line`],
              styles[`${PARENT_CLASSNAME}__grid-line--${i + 1}`],
            ])}
            data-grid-value={abbreviatedValueFormatter({
              value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * (i + 1) * multiplier,
              ...amountValueFormatterProps,
            })}
          />
        ))}
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
              [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
            })}
            style={{
              height: (100 / calculatedLabelTopValue) * totalInvestments + '%',
            }}>
            {renderRanks(entryStackStructure)}
          </div>
          {[...Array(chartForecastsCount)].map((_, i) => (
            <Fragment key={'instrument-wrapper-' + i}>
              <div
                className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
                  [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
                })}
                style={{
                  height: (100 / calculatedLabelTopValue) * totalEquityPerForecast[i] + '%',
                }}>
                {renderRanks(stacksPerForecast[i], i)}
              </div>
              {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                <div
                  className={classNames(styles['graph__expander-container'], {
                    [styles['graph__expander-container--secondary']]: !isExpanded,
                  })}
                />
              )}
            </Fragment>
          ))}
        </div>
      </div>
      <div
        className={classNames([
          styles[`${PARENT_CLASSNAME}__chart`],
          styles[`${PARENT_CLASSNAME}__chart--alternate`],
          styles[`${PARENT_CLASSNAME}__chart--${getMaxNetDebtAxisCount()}`],
        ])}>
        {[...Array(getMaxNetDebtAxisCount() + 1)].map((_, i) => (
          <Fragment key={`grid-line-debt-${i}`}>
            <div
              className={classNames([
                styles[`${PARENT_CLASSNAME}__grid-line`],
                styles[`${PARENT_CLASSNAME}__grid-line--${i}`],
                styles[`${PARENT_CLASSNAME}__grid-line--${i}-negative`],
              ])}
              data-grid-value={abbreviatedValueFormatter({
                value: (calculatedLabelTopValue / MAX_LABEL_ITEMS) * -i * multiplier,
                ...amountValueFormatterProps,
              })}
            />
          </Fragment>
        ))}
        <div className={styles[`${PARENT_CLASSNAME}__chart-inner`]}>
          <div
            className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
              [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
            })}>
            <div
              className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
                [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
              })}
              style={{
                height:
                  (100 / ((calculatedLabelTopValue / MAX_LABEL_ITEMS) * getMaxNetDebtAxisCount())) *
                    getNetDebtValue() +
                  '%',
              }}>
              <Tooltip
                key="debt-tooltip"
                placement={TooltipPlacement.Right}
                content={createDebtTooltip(getNetDebtValue()?.toString())}
                className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
                  [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
                })}>
                <div className={styles[`${PARENT_CLASSNAME}__tooltip-placeholder`]} />
              </Tooltip>
            </div>
            <div className={styles[`${PARENT_CLASSNAME}__column-legend`]}>Entry</div>
          </div>
          {[...Array(chartForecastsCount)].map((_, i) => (
            <Fragment key={'debt-item-' + i}>
              <NetDebtColumn
                maxNetDebtAxisCount={getMaxNetDebtAxisCount()}
                isExpanded={isExpanded}
                chartForecastsCount={chartForecastsCount}
                forecastYear={forecastData[i]?.forecastYear}
                columnIndex={i}
                netDebt={Number(getCaseOrProjectNetDebt(i))}
                tooltipContent={createDebtTooltip(getCaseOrProjectNetDebt(i)?.toString())}
                labelTopValue={calculatedLabelTopValue}
              />
              {i === MAX_COLLAPSED_ITEMS - 1 && forecastData.length > MAX_COLLAPSED_ITEMS && (
                <div
                  className={classNames(styles['graph__expander-container'], {
                    [styles['graph__expander-container--secondary']]: !isExpanded,
                  })}>
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className={styles['graph__expander']}>
                    {isExpanded ? <MinusIcon /> : <SvgPlus />}
                  </button>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TotalEquityChartPwerm;
