import { SVGProps } from 'react';
const SvgSpinner = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10ZM4 12a8 8 0 1 0 16 0 8 8 0 0 0-16 0Z"
      fill="#F3F3F3"
    />
    <path d="M12 2a10 10 0 0 1 10 10h-2a7.999 7.999 0 0 0-8-8V2Z" fill="currentColor" />
  </svg>
);
export default SvgSpinner;
