/* eslint-disable @typescript-eslint/no-explicit-any */
type NestedObject = { [key: string]: any };

// This function is an alternative to setValue (which only allows for one value at a time),
// as it takes an object (`obj`) and form methods (`formMethods`),
// and sets the form values based on the object's properties.
// For nested objects, it will recurse and use dot-notation to set the form values.
export const setMultipleFormValues = (obj: NestedObject, formMethods: any, prefix = '') => {
  Object.entries(obj).forEach(([key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key; // Construct the new key based on prefix
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      // If the value is a nested object, dig deeper
      setMultipleFormValues(value, formMethods, newKey);
    } else {
      // If the value is not an object or is an array, set the form value
      formMethods.setValue(newKey, value);
    }
  });
};
