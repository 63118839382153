import { enumKeyByValue } from '@app/shared/helpers';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from './redux-hooks';

const useIsReadOnly = () => {
  const projectStatus = useAppSelector((state) => state.project.project.status);
  const location = useLocation();

  return (
    location.pathname.includes('/projects/') &&
    projectStatus === enumKeyByValue(ProjectStatus, ProjectStatus.Closed)
  );
};

export default useIsReadOnly;
