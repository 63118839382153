import classNames from 'classnames';
import DotsSvg from '@app/shared/icons/legacy/DotsSvg';
import styles from './tile.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';

export enum TileType {
  ShareholderLoans = 'shareholder-loans',
  PreferenceShares = 'preference-shares',
  OrdinaryEquity = 'ordinary-equity',
}

export enum TilePosition {
  Top = 'top',
  Bottom = 'bottom',
}

interface TileProps {
  text: string;
  isDisabled?: boolean;
  isDragEffectActive?: boolean;
  type: TileType;
  isErfModal?: boolean;
}

const PARENT_CLASSNAME = 'tile-container';
const SVG_CLASS_NAME = 'dot-svg';
const SWEET_EQUITY_CLASS_NAME = 'sweet-equity';

const Tile = ({
  text,
  type,
  isDragEffectActive,
  isDisabled,
  isErfModal,
}: TileProps): JSX.Element => {
  const { l } = useLocale();

  const shouldRenderSweetEquity = type === TileType.OrdinaryEquity;

  const renderTile = (tileType: string, tileText: string, tilePosition: TilePosition) => {
    return (
      <div
        className={classNames(styles[PARENT_CLASSNAME], {
          [styles[`${PARENT_CLASSNAME}--items`]]: !shouldRenderSweetEquity,
          [styles[`${PARENT_CLASSNAME}--items--mini`]]: shouldRenderSweetEquity,
          [styles[`${PARENT_CLASSNAME}--items--mini--${tilePosition}`]]: shouldRenderSweetEquity,
          [styles[`${PARENT_CLASSNAME}--${tileType}`]]: tileType,
          [styles[`${PARENT_CLASSNAME}--active`]]: isDragEffectActive,
          [styles[`${PARENT_CLASSNAME}--disabled`]]: isDisabled,
          [styles[`${PARENT_CLASSNAME}--erf`]]: isErfModal,
        })}>
        <div className={styles[`${PARENT_CLASSNAME}__name`]}>{tileText}</div>
      </div>
    );
  };

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--group`]]: true,
      })}>
      <div>
        {renderTile(type, text, TilePosition.Top)}
        {type === TileType.OrdinaryEquity &&
          renderTile(SWEET_EQUITY_CLASS_NAME, l('_SweetEquityTitle'), TilePosition.Bottom)}
      </div>
      <DotsSvg className={classNames(styles[SVG_CLASS_NAME])} />
    </div>
  );
};

export default Tile;
