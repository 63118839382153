import React, { useEffect } from 'react';
import { Toggle, ToggleSize } from '../toggle/Toggle';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './highlight-editable-cells.module.scss';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { setHighlightEditableCells } from '@app/core/store/ui-values-slice';
import { useLocale } from '@app/core/hooks/useLocale';

const HighlightEditableCells: React.FC = () => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();

  const highlightEditableCellsState = useAppSelector(
    (state) => state.uiValues.highlightEditableCells
  );

  type HeaderFormModel = {
    highlightEditableCells: boolean;
  };

  const formMethods = useForm<HeaderFormModel>({
    ...formConfigBase,
    defaultValues: { highlightEditableCells: highlightEditableCellsState },
  });

  const { reset } = formMethods;

  useEffect(() => {
    reset({ highlightEditableCells: highlightEditableCellsState });
  }, [reset, highlightEditableCellsState]);

  return (
    <FormProvider {...formMethods}>
      <span>
        <Toggle
          name="highlightEditableCells"
          label={l('_HighlightEditableCells')}
          toggleSize={ToggleSize.Medium}
          className={styles['highlight-editable-cells']}
          onChange={(e) => dispatch(setHighlightEditableCells(e.target.checked))}
          data-testid="highlight-editable-cells-toggle"
        />
      </span>
    </FormProvider>
  );
};

export default HighlightEditableCells;
