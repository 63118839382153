import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import { CapitalStructureCapTableInstrumentDto } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';

export const getEquityInstrumentCountByType = (
  equityItems: (EquityInstrumentDto | CapitalStructureCapTableInstrumentDto)[],
  instrumentType: InstrumentType
): number => {
  return equityItems.filter((item) => item.type === enumKeyByValue(InstrumentType, instrumentType))
    .length;
};
