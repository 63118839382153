import { FC, SelectHTMLAttributes } from 'react';
import { FormControlPropsBase } from '@app/shared/components/form-controls/shared-types';
import classNames from 'classnames';
import styles from './grid-select-field.module.scss';
import { useController } from 'react-hook-form';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { SelectOption } from '@app/shared/models/option';
import { useAppSelector } from '@app/core/hooks/redux-hooks';

interface GridSelectFieldProps
  extends SelectHTMLAttributes<HTMLSelectElement>,
    FormControlPropsBase {
  name: string;
  options: SelectOption[];
  ignoreReadOnly?: boolean;
  disabled?: boolean;
}

const PARENT_CLASSNAME = 'select-field';

export const GridSelectField: FC<GridSelectFieldProps> = ({
  name,
  defaultValue,
  options,
  ignoreReadOnly = false,
  disabled = false,
  ...rest
}): JSX.Element => {
  const { field } = useController({ name, defaultValue });

  const isReadOnly = useIsReadOnly();
  const shouldHighlight = useAppSelector((state) => state.uiValues.highlightEditableCells);

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--highlight`]]: shouldHighlight,
      })}>
      <select {...field} {...rest} disabled={disabled || (!ignoreReadOnly && isReadOnly)}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.viewValue}
          </option>
        ))}
      </select>
    </div>
  );
};
