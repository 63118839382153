import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { cloneDeep, instrumentByTypeOrTranchesOrDefaultSortFn } from '@app/shared/helpers';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import {
  SecondaryNavigationItemVariation,
  SecondaryNavigationVariation,
} from '@app/shared/components/secondary-navigation/secondary-navigation-enums';
import { RouteConstants } from '../../RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';

export const CostOfEquity2Navigation = () => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();

  const instrumentTabs = cloneDeep(project.equityInstruments)
    .sort(instrumentByTypeOrTranchesOrDefaultSortFn)
    .filter((instrument) => instrument.shouldBeValued)
    .map<NavigationItem>((instrument) => {
      return {
        label: instrument.instrumentNarrative || EmptyValues.EnDash,
        route: `../${RouteConstants.InstrumentById2}`,
        params: { instrumentId: instrument.instrumentId },
      };
    });

  const navigationItems: NavigationItem[] = [
    {
      label: l('_SummaryAndHighLevelData'),
      route: `../${RouteConstants.Summary2}`,
      variation: SecondaryNavigationItemVariation.NoTrim,
    },
    ...instrumentTabs,
    {
      label: l('_TotalAttributableEquity'),
      route: `../${RouteConstants.TotalEquityCrossCheck2}`,
      variation: SecondaryNavigationItemVariation.NoTrim,
    },
  ];

  return (
    <SecondaryNavigation
      navigationItems={navigationItems}
      variation={SecondaryNavigationVariation.Scrollable}
    />
  );
};
