import { FC, useEffect, useRef } from 'react';
import { removeFileIllegalCharacters } from '@app/shared/helpers/remove-file-illegal-characters';
import { dateFormatter } from '@app/shared/formatters';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { useDownloadExcel } from '@app/shared/components/excel-exporter/ExcelExporter';
import { ParticipationTableErf } from './ParticipationTableErf';
import { useLocale } from '@app/core/hooks/useLocale';
import { EventKey } from '@app/shared/models/contracts/enums/shared-enums';

interface ParticipationTableExcelExportProps {
  shouldRenderInnerContent?: boolean;
  excelExportEnabled?: boolean;
}

export const ParticipationTableExcelExportErf: FC<ParticipationTableExcelExportProps> = ({
  shouldRenderInnerContent,
  excelExportEnabled = false,
}): JSX.Element => {
  const projectDraft = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();
  const uniqueEventSetIds = Array.from(
    new Set(projectDraft.pwermInput.cases.map((item) => item.eventSetId))
  );
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `${removeFileIllegalCharacters(
      projectDraft?.name
    )}_Participation_tableERF_${dateFormatter(new Date().toString(), {
      dateStyle: 'short',
    })}`,
    sheet: l('_ParticipationTable'),
  });

  useEffect(() => {
    if (shouldRenderInnerContent && excelExportEnabled) {
      onDownload();
    }
    // effect tracks only one dependency
  }, [shouldRenderInnerContent]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <table ref={tableRef}>
      <>
        {shouldRenderInnerContent &&
          uniqueEventSetIds.flatMap((eventSetId) => {
            const casesWithSameEventSet = projectDraft.pwermInput.cases.filter(
              (item) => item.eventSetId === eventSetId
            );

            const caseInEventSetWithMostForecasts = getCaseWithMostForecasts(
              casesWithSameEventSet,
              true
            );
            return caseInEventSetWithMostForecasts.forecasts
              .slice(0)
              .reverse()
              .map((forecastItem) => {
                return (
                  <ParticipationTableErf
                    key={forecastItem.forecastId}
                    shouldRenderStandaloneTable={false}
                    injectedForecastYear={forecastItem.forecastYear}
                    injectedEventSetId={eventSetId ?? EventKey.EmptyEventSet}
                  />
                );
              });
          })}
      </>
    </table>
  );
};
