import { EquityItem } from '@app/modules/projects/inputs/capital-structure/CapitalStructureItemFormErf';
import {
  CapitalStructureBuildStructureDto,
  CapitalStructureEventDto,
  CapitalStructureEventSetDto,
  CapitalStructureInstrumentDefinitionDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';

export const getEventInstrumentsFromBuildStructure = (
  buildStructure: Dictionary<CapitalStructureBuildStructureDto>,
  eventSetId: string,
  eventId: string
): EquityItem[] => {
  const eventSet = Object.values(buildStructure)[0].eventSets[eventSetId];
  const instrumentDefinitions = Object.values(buildStructure)[0].instrumentDefinitions;
  const event = eventSet.events.find((event) => event.id === eventId);

  if (!event) {
    return [];
  }

  const instruments = event.ranks.flatMap((rank) =>
    Object.entries(rank.instruments).map(([instrumentId, instrument]) => ({
      ...instrument,
      instrumentId,
    }))
  );

  return instruments.map((instrument) => {
    return {
      ...instrument,
      ...instrumentDefinitions[instrument.instrumentId],
      ownership: Object.keys(OwnerType).map((owner) => {
        return {
          owner: owner as keyof typeof OwnerType,
          amount: instrument.ownership[owner]?.amount ?? null,
          numberOfShares: instrument.ownership[owner]?.numberOfShares ?? null,
        };
      }),
    };
  });
};

export const getEventInstrumentsFromEvent = (
  event: CapitalStructureEventDto,
  instrumentDefinitions: Dictionary<CapitalStructureInstrumentDefinitionDto>
): EquityItem[] => {
  const instruments = event.ranks.flatMap((rank) =>
    Object.entries(rank.instruments).map(([instrumentId, instrument]) => ({
      ...instrument,
      instrumentId,
    }))
  );

  return instruments.map((instrument) => {
    return {
      ...instrument,
      ...instrumentDefinitions[instrument.instrumentId],
      ownership: Object.keys(OwnerType).map((owner) => {
        return {
          owner: owner as keyof typeof OwnerType,
          amount: instrument.ownership[owner]?.amount ?? null,
          numberOfShares: instrument.ownership[owner]?.numberOfShares ?? null,
        };
      }),
    };
  });
};

export const getDuplicateGroups = (eventSets: [string, CapitalStructureEventSetDto][]) => {
  const processedEventSetIds = new Set<string>();
  const groups: string[][] = [];

  const eventSetMap = Object.fromEntries(eventSets);

  const traverseEventSet = (eventSetId: string, currentGroup: string[]) => {
    processedEventSetIds.add(eventSetId);
    currentGroup.push(eventSetId);

    for (const duplicateId of eventSetMap[eventSetId].duplicates) {
      if (!processedEventSetIds.has(duplicateId)) {
        traverseEventSet(duplicateId, currentGroup);
      }
    }
  };

  for (const [eventSetId] of eventSets) {
    if (!processedEventSetIds.has(eventSetId)) {
      const currentGroup: string[] = [];
      traverseEventSet(eventSetId, currentGroup);
      groups.push(currentGroup);
    }
  }

  return groups;
};

export const getInstrumentMovementTotals = (
  buildStructure: Dictionary<CapitalStructureBuildStructureDto>,
  capitalStructureKey: string,
  eventSetId: string,
  eventId: string,
  instrumentId: string,
  instrumentType: keyof typeof InstrumentType
) => {
  return buildStructure[capitalStructureKey].eventSets[eventSetId].events.find(
    (e) => e.id === eventId
  )?.movements.adjustments.instrumentTypeSummary[instrumentType]?.totalInstrument[instrumentId]
    ?.total;
};

export const getInstrumentOwnerMovement = (
  buildStructure: Dictionary<CapitalStructureBuildStructureDto>,
  capitalStructureKey: string,
  eventSetId: string,
  eventId: string,
  instrumentId: string,
  instrumentType: keyof typeof InstrumentType,
  ownerType: keyof typeof OwnerType
) => {
  return buildStructure[capitalStructureKey].eventSets[eventSetId].events.find(
    (e) => e.id === eventId
  )?.movements.adjustments.instrumentTypeSummary[instrumentType]?.totalInstrument[instrumentId]
    ?.totalOwnershipType[ownerType];
};
