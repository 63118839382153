import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';

export const instrumentTypeToName = (instrumentType: InstrumentType) => {
  switch (instrumentType) {
    case InstrumentType.ShareholderLoanNotes:
      return 'SHL';
    case InstrumentType.PreferredShares:
      return 'Pref Shares';
    default:
      return instrumentType;
  }
};
