import { FC, useEffect, useRef, useState } from 'react';
import {
  CalcMethod,
  EventKey,
  InstrumentType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import styles from '../build-structure-tab/build-structure.module.scss';
import CapitalStructureErf from './CapitalStructureErf';
import DragAreaErf from '@app/shared/components/drag-area/DragAreaErf';
import UnrelatedEventErf from './UnrelatedEventErf';
import {
  CapitalStructureEventDto,
  EventSetSequenceDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import InstrumentTileErf from './InstrumentTileErf';
import { MAX_INSTRUMENT_COUNT } from '@app/shared/constants/capital-structure';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';

const PARENT_CLASSNAME = 'build-structure';

const BuildStructureContentErf: FC = (): JSX.Element | null => {
  const { caseId } = useParams();
  const isReadOnly = useIsReadOnly();
  const project = useAppSelector((state) => state.project.projectDraft);
  const hideUnrelatedEventsState = useAppSelector((state) => state.uiValues.hideUnrelatedEvents);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const capitalStructureId = project.pwermInput.cases[0].capitalStructureId;
  const instrumentDefinitions = buildStructures?.[capitalStructureId]?.instrumentDefinitions;
  const selectedCaseId = caseId ?? project.pwermInput.cases[0].caseId;
  const buildStructuresEventSetIds =
    buildStructures && Object.keys(buildStructures?.[capitalStructureId]?.eventSets);
  const isErfProject = buildStructures?.[capitalStructureId]?.isErf;
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;
  const opmOnlyEventSetExists = Object.keys(
    project.capitalStructures[capitalStructureId].eventSets
  ).includes(EventKey.OpmOnly);

  let eventSetId: string;

  if (isOpmOnly) {
    eventSetId = opmOnlyEventSetExists ? EventKey.OpmOnly : EventKey.EmptyEventSet;
  } else {
    eventSetId =
      project.pwermInput.cases.find((item) => item.caseId === selectedCaseId)?.eventSetId ??
      EventKey.EmptyEventSet;
  }

  const dragAreaHeightRef = useRef<HTMLDivElement>(null);

  const [selectedEvents, setSelectedEvents] = useState<CapitalStructureEventDto[]>(
    buildStructures?.[capitalStructureId]?.eventSets[eventSetId]?.events ?? []
  );
  const [selectedEventSetSequence, setSelectedEventSetSequence] = useState<
    Dictionary<EventSetSequenceDto>
  >({});
  const [readyToRender, setReadyToRender] = useState(false);
  const [dragAreaHeight, setDragAreaHeight] = useState(0);

  useEffect(() => {
    const updateHeight = () => {
      if (dragAreaHeightRef.current) {
        setDragAreaHeight(dragAreaHeightRef.current.offsetHeight);
      }
    };

    updateHeight();

    window.addEventListener('resize', updateHeight);

    setTimeout(() => {
      updateHeight();
    }, 0);

    return () => window.removeEventListener('resize', updateHeight);
  }, [readyToRender]);

  useEffect(() => {
    if (!buildStructures) {
      return;
    }
    const eventSetId = isOpmOnly
      ? opmOnlyEventSetExists
        ? EventKey.OpmOnly
        : undefined
      : project.pwermInput.cases.find((item) => item.caseId === selectedCaseId)?.eventSetId;

    if (eventSetId && buildStructuresEventSetIds.includes(eventSetId)) {
      setSelectedEvents(buildStructures?.[capitalStructureId]?.eventSets?.[eventSetId].events);
      setSelectedEventSetSequence(
        buildStructures?.[capitalStructureId]?.eventSets?.[eventSetId].sequence
      );
    } else {
      setSelectedEvents(
        buildStructures?.[capitalStructureId]?.eventSets?.[EventKey.EmptyEventSet].events
      );
      setSelectedEventSetSequence(
        buildStructures?.[capitalStructureId]?.eventSets?.[EventKey.EmptyEventSet].sequence
      );
    }
    if (buildStructures) {
      setReadyToRender(true);
    }
    // above changes required based on buildStructures updates, but not immediately upon projectDraft updates,
    // as the eventSetId is updated on the case, which is not part of the buildStructures state
    // TODO: consider refactoring to make direct calls to buildStructure, and update case eventSetId after receiving response
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildStructures, caseId]);

  if (!buildStructures) {
    return null;
  }
  const shouldDisableTiles =
    Object.keys(instrumentDefinitions).length >= MAX_INSTRUMENT_COUNT || isReadOnly;

  // TODO: DragAreaErf notificationMessage
  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      {readyToRender && (
        <>
          {!isErfProject && (
            <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
              {Object.entries(InstrumentType).map(([key, value]) => (
                <InstrumentTileErf
                  key={key}
                  instrumentType={value}
                  isDisabled={shouldDisableTiles}
                />
              ))}
            </div>
          )}
          <div
            className={
              isErfProject
                ? styles[`${PARENT_CLASSNAME}__area-erf`]
                : styles[`${PARENT_CLASSNAME}__area`]
            }>
            <DragAreaErf dragAreaHeightRef={dragAreaHeightRef}>
              <>
                {selectedEvents &&
                  selectedEventSetSequence &&
                  Object.values(selectedEventSetSequence).map((event, index) => {
                    const isEventSetUsedInMultipleCases =
                      project.pwermInput.cases.filter((c) => c.eventSetId === eventSetId).length >
                      1;
                    const isEventUsedInMultipleEventSets = Object.keys(
                      project.capitalStructures[capitalStructureId].eventSets
                    )
                      .filter((id) => id !== eventSetId)
                      .some((id) =>
                        project.capitalStructures[capitalStructureId].eventSets[id].events.includes(
                          event.eventId
                        )
                      );
                    const shouldDisplayUnlinkButton =
                      isEventSetUsedInMultipleCases || isEventUsedInMultipleEventSets;
                    const selectedEventSetLength = Object.values(selectedEvents).length;
                    const isLastEventRelated = hideUnrelatedEventsState
                      ? event.eventId ===
                          Object.values(selectedEvents)[selectedEventSetLength - 1].id &&
                        index !== 0
                      : index === Object.values(selectedEventSetSequence).length - 1 &&
                        index !== 0 &&
                        event.relevant;
                    return event.relevant ? (
                      <CapitalStructureErf
                        key={event.eventId}
                        buildStructureEvent={selectedEvents.find((e) => e.id === event.eventId)!}
                        instrumentDefinitions={instrumentDefinitions}
                        isLastEventRelated={isLastEventRelated}
                        dragAreaHeight={dragAreaHeight}
                        shouldDisplayUnlinkButton={shouldDisplayUnlinkButton}
                        eventSetId={eventSetId}
                      />
                    ) : !hideUnrelatedEventsState ? (
                      <UnrelatedEventErf
                        key={event.eventId}
                        eventId={event.eventId}
                        dragAreaHeight={dragAreaHeight}
                      />
                    ) : null;
                  })}
              </>
            </DragAreaErf>
          </div>
        </>
      )}
    </div>
  );
};

export default BuildStructureContentErf;
