import { useLocale } from '@app/core/hooks/useLocale';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  shortDateViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto } from '@app/shared/models/contracts/pwerm2-input-calculation-results-dto';
import { FC } from 'react';
import { translationKeys } from 'src/locale/setupI18n';
import styles from './deal-thesis-corkscrew-modal.module.scss';
import classNames from 'classnames';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  Currency,
  Denomination,
  InterestPaymentType,
  ViewChangeType,
} from '@app/shared/models/contracts/enums/shared-enums';
import SvgArrowLeft from '@app/shared/icons/ArrowLeft';
import SvgArrowRight from '@app/shared/icons/ArrowRight';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import {
  mapCouponTypeInterestToTranslation,
  mapInterestToTranslation,
} from '../../deal-thesis-utils/CouponInterestTranslationHelper';

interface CorkscrewModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
  instrumentId: string;
  instrumentNarrative: string;
  eventData: Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto[];
  shouldHidePrevious: boolean;
  shouldHideNext: boolean;
  handleCorkscrewForecastChange: (
    instrumentId: string,
    changeType: keyof typeof ViewChangeType
  ) => void;
}

export const CorkscrewModal: FC<CorkscrewModalProps> = ({
  setIsOpen,
  isOpen,
  instrumentId,
  instrumentNarrative,
  eventData,
  shouldHidePrevious,
  shouldHideNext,
  handleCorkscrewForecastChange,
}): JSX.Element => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const currency = project.details.currency;
  const denomination = project.details.denomination;
  const currencyDenomination = `${Currency[currency]}${Denomination[denomination]}`;
  const numberOfEvents = eventData.length;

  const createTable = () => {
    const shouldDisplayCouponType = eventData.every((event) => {
      const isCashPayOnly = event.interestPaymentType === InterestPaymentType.CashPay;
      const isCashPayAndPIK =
        event.interestPaymentType === InterestPaymentType.PaymentInKindAndCashPay &&
        event.cashPayProportionPc === 100;
      return !isCashPayOnly && !isCashPayAndPIK;
    });
    const hasNonPIKEvents = eventData.some(
      (event) => event.interestPaymentType !== InterestPaymentType.PaymentInKind
    );
    const periodEndYear = eventData[0]?.periodEnd.split('-')[0];
    return (
      <table className={styles['corkscrew-table']}>
        <tbody>
          <tr>
            <th />
            <td colSpan={numberOfEvents}>
              <div className={classNames(styles['year-selector'], styles['text'])}>
                {!shouldHidePrevious ? (
                  <Button
                    startIcon={<SvgArrowLeft />}
                    appearance={ButtonAppearance.CLEAN}
                    autoIconSize
                    onClick={() =>
                      handleCorkscrewForecastChange(instrumentId, ViewChangeType.Previous)
                    }
                  />
                ) : (
                  <div />
                )}
                <span>
                  <b>{periodEndYear}</b>
                </span>
                {!shouldHideNext ? (
                  <Button
                    startIcon={<SvgArrowRight />}
                    appearance={ButtonAppearance.CLEAN}
                    autoIconSize
                    onClick={() => handleCorkscrewForecastChange(instrumentId, ViewChangeType.Next)}
                  />
                ) : (
                  <div />
                )}
              </div>
            </td>
          </tr>
          {createTableRow(l('_EventName'), 'eventNarrative', true, false, false, true)}
          {createTableRow(l('_PeriodStart'), 'periodStart', false, true)}
          {createTableRow(l('_PeriodEnd'), 'periodEnd', false, true)}
          <tr>
            <td colSpan={numberOfEvents + 1} className={styles['section-separator']}>
              <hr />
            </td>
          </tr>
          {createTableRow(l('_OpeningBalance'), 'openingBalance', true)}
          {createTableRow(l('_AdditionOrRedemption'), 'additions')}
          {createTableRow(l('_AccruedInterest'), 'accruedInterest')}
          {createTableRow(l('_ClosingBalance'), 'closingBalance', true)}
          <tr>
            <td colSpan={numberOfEvents + 1} className={styles['section-separator']}>
              <hr />
            </td>
          </tr>
          {createTableRow(l('_CouponPc'), 'couponPc', false, false, true)}
          {createTableRow(
            l('_InterestPaymentType'),
            'interestPaymentType',
            false,
            false,
            false,
            true
          )}
          {shouldDisplayCouponType &&
            createTableRow(l('_CouponType'), 'couponType', false, false, false, true)}
          <tr>
            <td colSpan={numberOfEvents + 1} className={styles['section-separator']}>
              <hr />
            </td>
          </tr>
          {hasNonPIKEvents &&
            createTableRow(l('_CashPayProportionPc'), 'cashPayProportionPc', false, false, true)}
          {hasNonPIKEvents &&
            createTableRow(
              l('_CashPayInterestCurrencyDenomination', {
                currencyDenomination,
              }),
              'cashPayInterest'
            )}
        </tbody>
      </table>
    );
  };

  const createTableRow = (
    title: translationKeys,
    key: keyof Pwerm2InputYearDebtInstrumentCalculationResultsPeriodByEventDto,
    isBold = false,
    isDate = false,
    isPercentage = false,
    isText = false
  ) => {
    return (
      <tr>
        <th className={classNames(styles['text'], { [styles['text--bold']]: isBold })}>{title}</th>
        {eventData.map((event) => {
          return (
            <td
              className={classNames(styles['text'], { [styles['text--bold']]: isBold })}
              key={event.eventNarrative}>
              <CellValue
                value={
                  key === 'couponType'
                    ? mapCouponTypeInterestToTranslation(event.couponType)
                    : key === 'interestPaymentType'
                    ? mapInterestToTranslation(event.interestPaymentType)
                    : event[key]
                }
                {...(isText || isDate ? {} : amountRules)}
                {...(isPercentage ? enteredPercentageViewRules : {})}
                {...(isDate ? shortDateViewRules : {})}
                alignment={Alignment.Center}
                allowEmptyValues
              />
            </td>
          );
        })}
      </tr>
    );
  };

  return (
    <BaseModal
      size={ModalSize.Large}
      title={l('_InstrumentBalanceSummary', { instrumentNarrative })}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      onRequestClose={() => setIsOpen(false)}
      onCloseButtonClick={() => setIsOpen(false)}>
      {createTable()}
    </BaseModal>
  );
};
