import * as utils from './utils/GenerateTable';

import { UseExcel, ExcelReturn } from './interfaces/use-excel';
import { Context } from './interfaces/context';

function valEnv(): boolean {
  if (!document) {
    return false;
  }
  return true;
}

function download(fileName: string, context: Context): boolean {
  const element = window.document.createElement('a');
  const data = utils.format(utils.template, context);
  const blob = new Blob([data], { type: utils.fileMimeType });
  element.href = URL.createObjectURL(blob);
  element.download = fileName;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
  return true;
}

function getTable(currentTableRef?: any, tablePayload?: utils.ITablePayload) {
  if (currentTableRef) {
    const cloneTable = currentTableRef.cloneNode(true);
    return cloneTable.outerHTML;
  }
  if (tablePayload) {
    return utils.GenerateTable(tablePayload);
  }
}

function handleDownload(
  {
    fileName,
    sheet,
    tablePayload,
  }: { fileName: string; sheet: string; tablePayload?: utils.ITablePayload },
  currentTableRef?: HTMLElement
) {
  if (!valEnv()) {
    return false;
  }

  const table = getTable(currentTableRef, tablePayload);
  const context: Context = {
    worksheet: sheet || 'Worksheet',
    table,
  };

  return download(fileName, context);
}

function excel({ currentTableRef, filename, sheet }: UseExcel): ExcelReturn {
  function onDownload(): boolean {
    if (!valEnv()) {
      return false;
    }

    const table = getTable(currentTableRef);
    const fileName = `${filename}.xls`;

    const context: Context = {
      worksheet: sheet ?? 'Worksheet',
      table,
    };

    return download(fileName, context);
  }

  return { onDownload };
}

export { excel, handleDownload as downloadExcel };
