import { Snackbar as MuiSnackbar, SnackbarProps as MuiSnackbarProps } from '@mui/material';
import { FC, useEffect } from 'react';
import classNames from 'classnames';
import * as workerTimers from 'worker-timers';
import { CloseReason, SNACKBAR_AUTOHIDE_TIME } from './snackbar-types';

interface SnackbarProps extends MuiSnackbarProps {
  dontHide?: boolean;
  fitContent?: boolean;
  isStatic?: boolean;
  removeGutter?: boolean;
}

const Snackbar: FC<SnackbarProps> = ({
  fitContent,
  children,
  dontHide,
  isStatic,
  removeGutter,
  onClose,
  ...props
}): JSX.Element => {
  useEffect(() => {
    let timeoutId: number;

    if (!dontHide && onClose) {
      timeoutId = workerTimers.setTimeout(() => {
        onClose(new Event(''), CloseReason.timeout);
      }, SNACKBAR_AUTOHIDE_TIME);
    }

    return () => {
      if (timeoutId) {
        workerTimers.clearTimeout(timeoutId);
      }
    };
  }, [dontHide, onClose]);

  return (
    <MuiSnackbar
      classes={{
        root: classNames({
          'MuiSnackbar--static': isStatic,
          'MuiSnackbar--no-gutter': removeGutter,
          'MuiSnackbar--fit-content': fitContent,
        }),
      }}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      onClose={onClose}
      {...props}>
      {children}
    </MuiSnackbar>
  );
};

export default Snackbar;
