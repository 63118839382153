import { SVGProps } from 'react';
const SvgSuccessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M11 22c6.076 0 11-4.924 11-11S17.076 0 11 0 0 4.924 0 11s4.924 11 11 11Zm4.855-13.02-5.5 5.5a1.027 1.027 0 0 1-1.456 0l-2.75-2.75a1.03 1.03 0 0 1 1.457-1.457l2.019 2.02 4.77-4.773a1.03 1.03 0 0 1 1.457 1.457l.003.003Z"
      fill="#4B973E"
    />
  </svg>
);
export default SvgSuccessIcon;
