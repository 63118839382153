export interface OperationProgressUpdateDto {
  description: string;
  progress: number;
  steps: OperationStepProgressUpdateDto[];
}

export interface OperationStepProgressUpdateDto {
  description: string;
  details: string;
  complete: boolean;
  type: OperationsStepType;
}

export enum OperationsStepType {
  Initialisation = 'Initialisation',
  Simulations = 'Simulations',
  ResultGeneration = 'ResultGeneration',
  ExportGeneration = 'ExportGeneration',
  DownloadExport = 'DownloadExport',
  Completion = 'Completion',
}
