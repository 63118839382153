import { SVGProps } from 'react';
const SvgClone = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M2.75 15.27h7.292c.286 0 .52-.234.52-.52v-2.083h1.563v2.083a2.085 2.085 0 0 1-2.083 2.083H2.75A2.084 2.084 0 0 1 .667 14.75V7.458c0-1.149.932-2.083 2.083-2.083h2.083v1.562H2.75a.522.522 0 0 0-.52.521v7.292c0 .286.232.52.52.52ZM5.875 2.25c0-1.15.934-2.083 2.083-2.083h7.292c1.149 0 2.083.932 2.083 2.083v7.292c0 1.149-.934 2.083-2.083 2.083H7.958a2.085 2.085 0 0 1-2.083-2.083V2.25Zm2.083 7.813h7.292c.286 0 .52-.235.52-.521V2.25a.522.522 0 0 0-.52-.52H7.958a.522.522 0 0 0-.52.52v7.292c0 .286.234.52.52.52Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgClone;
