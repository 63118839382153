import { SVGProps } from 'react';
const SvgNext = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M7.707 8.74c.39-.41.39-1.074 0-1.483L1.708.973a.97.97 0 0 0-1.415 0c-.39.41-.39 1.074 0 1.483L5.586 8l-5.29 5.544c-.39.41-.39 1.073 0 1.482a.97.97 0 0 0 1.415 0l6-6.283-.004-.003Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgNext;
