import { FC } from 'react';
import classNames from 'classnames';
import { CapitalStructureParticipationTableTierDto } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';

interface AllSortedTranchesItem extends CapitalStructureParticipationTableTierDto {
  instrumentId: string;
  instrumentNarrative: string;
  trancheIndex: number;
}

interface ParticipationTableRowProps {
  allSortedTranches: AllSortedTranchesItem[];
  renderLabelCellValue: () => JSX.Element;
  renderPreHurdleCellValue: () => JSX.Element;
  renderCellValue: ({ item }: { item: AllSortedTranchesItem }) => JSX.Element;
  labelCellClassnames?: string;
  cellClassnames?: string;
  rowClassnames?: string;
}

export const ParticipationTableRowErf: FC<ParticipationTableRowProps> = ({
  allSortedTranches,
  renderLabelCellValue,
  renderPreHurdleCellValue,
  renderCellValue,
  labelCellClassnames,
  cellClassnames,
  rowClassnames,
}) => (
  <tr className={rowClassnames}>
    <th
      className={classNames(
        'table-primary__cell--vertical-separator',
        cellClassnames,
        labelCellClassnames
      )}>
      {renderLabelCellValue()}
    </th>
    <td className={classNames('table-primary__cell--vertical-separator', cellClassnames)}>
      {renderPreHurdleCellValue()}
    </td>
    {allSortedTranches.map((item, itemIndex) => (
      <td
        key={itemIndex}
        className={classNames('table-primary__cell--vertical-separator', cellClassnames)}>
        {renderCellValue({ item })}
      </td>
    ))}
  </tr>
);
