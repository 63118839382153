import { numberValueFormatter } from '@app/shared/formatters/number-value-formatter';

export const formatUnfocusedInputValue = (
  stringValue: number | undefined,
  decimalPoint: number | undefined,
  viewValuePrefix: string | undefined,
  viewValueSuffix: string | undefined,
  isNormalised = false
) => {
  if (stringValue || stringValue === 0) {
    return numberValueFormatter({
      value: stringValue,
      fraction: decimalPoint,
      valuePrefix: viewValuePrefix,
      valueSuffix: viewValueSuffix,
      isNormalised,
    });
  }
};
