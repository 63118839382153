import { FC } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { useParams } from 'react-router-dom';
import { InstrumentDefinitionDto } from '@app/shared/models/contracts/project-dto';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import CellValue from '@app/shared/components/cell-value/CellValue';
import styles from './ordinary-equity-add-redemption-section.module.scss';
import classNames from 'classnames';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { InstrumentType, TooltipWidth } from '@app/shared/models/contracts/enums/shared-enums';
import Button from '@app/shared/components/button/Button';
import SvgWarningStatus from '@app/shared/icons/WarningStatus';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { enumKeyByValue } from '@app/shared/helpers';

const OrdinaryEquityAdditionRedemptionSection: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructures = project.capitalStructures;
  const capitalStructureId = Object.keys(capitalStructures)[0];
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);

  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);

  const ordinaryEquityInstruments: { [key: string]: InstrumentDefinitionDto } = Object.entries(
    capitalStructures[capitalStructureId].instrumentDefinitions
  )
    .filter(
      ([_, value]) => value.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
    )
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as { [key: string]: InstrumentDefinitionDto });

  // TO BE REPLACED ON HOW ITS BEING DONE ON OPM CASE SELECTOR
  const capitalStructureKey = Object.keys(buildStructures)?.[0];
  const isErfProject = buildStructures?.[capitalStructureKey].isErf;
  if (!isErfProject) {
    return <></>;
  }

  const caseResults = results.cases[caseId!];
  const caseForecasts = Object.entries(caseResults.forecastDates).map(([forecastId, value]) => ({
    forecastId,
    ...value,
  }));

  const maxColspanCount =
    (caseForecasts ? caseForecasts.length : 0) + DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;
  return (
    <tbody>
      <tr>
        <th className="table-primary__cell--header" colSpan={maxColspanCount}>
          {l('_OrdinaryEquityInstruments')}
        </th>
      </tr>
      <tr className="table-primary__row--header">
        <th
          className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator"
          colSpan={maxColspanCount}>
          <strong>{l('_AdditionOrRedemption')}</strong>
        </th>
      </tr>
      {Object.entries(ordinaryEquityInstruments).map(([instrumentId, instrument]) => (
        <tr key={instrumentId}>
          <th className="table-primary__cell--vertical-separator">
            {instrument.instrumentNarrative}
          </th>
          <td />
          {caseForecasts.map((forecast, index) => {
            const needsTooltip =
              forecast.ordinaryInstruments[instrumentId]?.periodByEvent.filter(
                (event) => event.additions !== 0
              ).length > 1;

            return (
              <td
                key={`oe-forecast-${instrumentId}-${index}`}
                className={classNames('', {
                  [styles['non-existent-instrument-value']]:
                    forecast.ordinaryInstruments &&
                    instrumentId in forecast.ordinaryInstruments &&
                    forecast.ordinaryInstruments[instrumentId]?.periodTotal == null,
                })}>
                {forecast.ordinaryInstruments &&
                  instrumentId in forecast.ordinaryInstruments &&
                  forecast.ordinaryInstruments[instrumentId]?.periodTotal !== null &&
                  forecast.ordinaryInstruments[instrumentId]?.periodTotal?.additions !== 0 && (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <CellValue
                          {...amountRules}
                          alignment={Alignment.Right}
                          value={forecast.ordinaryInstruments[instrumentId].periodTotal?.additions}
                        />
                        {needsTooltip && (
                          <Tooltip
                            placement={TooltipPlacement.Top}
                            width={`${TooltipWidth.OrdinaryEquityDealThesisSection}px`}
                            content={
                              <>
                                <table>
                                  <thead>
                                    <tr className="table-primary__row--plain">
                                      <th>
                                        <strong>{l('_EventDate')}</strong>
                                      </th>
                                      <th>
                                        <strong>{l('_AdditionOrRedemption')}</strong>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {forecast.ordinaryInstruments[instrumentId].periodByEvent.map(
                                      (event) => {
                                        if (event.additions === 0) {
                                          return null;
                                        }
                                        return (
                                          <tr
                                            className="table-primary--no-__row--plain"
                                            key={`oe-forecast-${instrumentId}-${event.date}`}>
                                            <td>{event.date}</td>
                                            <td>
                                              <CellValue
                                                alignment={Alignment.Right}
                                                {...amountRules}
                                                value={event.additions}
                                              />
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )}
                                    <tr>
                                      <th className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                                        <strong>Total</strong>
                                      </th>
                                      <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
                                        <CellValue
                                          alignment={Alignment.Right}
                                          {...amountRules}
                                          value={
                                            forecast.ordinaryInstruments[instrumentId]?.periodTotal
                                              ?.additions
                                          }
                                          strong
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </>
                            }>
                            <Button
                              className={styles['tooltip-button']}
                              startIcon={<SvgWarningStatus />}
                              appearance={ButtonAppearance.CLEAN}
                              size={ButtonSize.SMALL}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </>
                  )}
              </td>
            );
          })}
          <td />
        </tr>
      ))}
      <tr className="table-primary__row--plain">
        <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
          <strong>{l('_Total')}</strong>
        </th>
        <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator" />
        {caseForecasts.map((forecast, index) => {
          const totalAdditions = forecast.ordinaryInstruments
            ? Object.values(forecast.ordinaryInstruments).reduce((acc, value) => {
                if (value.periodTotal !== null && value.periodTotal.additions !== 0) {
                  acc += value.periodTotal.additions;
                }
                return acc;
              }, 0)
            : null;
          return (
            <td
              className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator"
              key={`oe-forecast-total-${index}`}>
              <CellValue
                {...amountRules}
                alignment={Alignment.Right}
                value={totalAdditions !== 0 ? totalAdditions : null}
                strong
              />
            </td>
          );
        })}
      </tr>
    </tbody>
  );
};

export default OrdinaryEquityAdditionRedemptionSection;
