import { SVGProps } from 'react';
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}>
    <path d="m8.829 3.657-.258.53H5.143C4.51 4.188 4 4.718 4 5.375c0 .657.51 1.188 1.143 1.188h13.714c.632 0 1.143-.531 1.143-1.188 0-.657-.51-1.188-1.143-1.188H15.43l-.258-.53A1.135 1.135 0 0 0 14.15 3h-4.3c-.432 0-.829.252-1.021.657ZM18.857 7.75H5.143L5.9 20.33c.057.939.807 1.67 1.71 1.67h8.78c.903 0 1.653-.731 1.71-1.67l.757-12.58Z" />
  </svg>
);
export default SvgTrash;
