import { SVGProps } from 'react';
const SvgXMark = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M15.607 13.957a1.195 1.195 0 0 1 0 1.693 1.203 1.203 0 0 1-1.698 0L8.001 9.713l-5.95 5.935a1.203 1.203 0 0 1-1.698 0 1.195 1.195 0 0 1 0-1.693l5.953-5.933L.35 2.043a1.195 1.195 0 0 1 0-1.693 1.203 1.203 0 0 1 1.698 0L8 6.332 13.95.397a1.203 1.203 0 0 1 1.698 0 1.195 1.195 0 0 1 0 1.693L9.696 8.022l5.91 5.935Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgXMark;
