import { SVGProps } from 'react';
const SvgAnalysis = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="25"
    viewBox="0 0 22 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}>
    <path
      d="M17.1667 18.5967C19.3133 16.7033 20.67 13.9233 20.67 10.83C20.6733 5.13333 16.0733 0.5 10.4233 0.5C4.77334 0.5 0.17334 5.13333 0.17334 10.83C0.17334 16.5267 4.77001 21.16 10.4233 21.16C12.4933 21.16 14.42 20.5367 16.0333 19.4667L20.7933 24.5L21.8267 23.5233L17.17 18.5933L17.1667 18.5967ZM10.4533 1.92C15.36 1.92 19.3367 5.89667 19.3367 10.8033C19.3367 11.73 19.1933 12.6267 18.93 13.4667H16.66V7.73H12.9767V5.07667H7.96667V9.66667H4.30001V13.4633H1.97667C1.71334 12.6233 1.57001 11.7267 1.57001 10.8C1.57001 5.89333 5.54667 1.91667 10.4533 1.91667V1.92ZM15.3467 9.04667V13.4667H12.9933V9.04667H15.3467ZM11.6367 6.42V13.4667H9.31334V6.42H11.6367ZM7.97001 10.9667V13.4633H5.60334V10.9667H7.97001ZM10.4567 19.6833C7.05667 19.6833 4.10334 17.77 2.61001 14.9633H18.3C16.8067 17.77 13.8533 19.6833 10.4533 19.6833H10.4567Z"
      fill="#D04A02"
    />
  </svg>
);
export default SvgAnalysis;
