import React, { ReactElement, RefObject, useEffect, useState } from 'react';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import html2canvas from 'html2canvas';
import { dateFormatter } from '@app/shared/formatters';
import SaveAsImage from '@app/shared/icons/SaveAsImage';
import { useLocale } from '@app/core/hooks/useLocale';

type TableScreenshotActionProps = {
  tableRef: RefObject<HTMLTableElement>;
  testIdString: string;
  filePrefix: string;
};

const TableScreenshotAction: React.FC<TableScreenshotActionProps> = ({
  tableRef,
  testIdString,
  filePrefix,
}): ReactElement => {
  const { l } = useLocale();
  const [tableElement, setTableElement] = useState(tableRef?.current);

  useEffect(() => {
    setTableElement(tableRef?.current);
  }, [tableRef]);

  const generateAndSaveWaterfallImage = () => {
    if (tableElement !== null) {
      tableElement?.classList.add('table-primary--screenshot');
      html2canvas(tableElement).then((canvas) => {
        const a = document.createElement('a');
        // toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
        a.href = canvas.toDataURL();
        a.download = `${filePrefix}${dateFormatter(new Date().toString(), {
          dateStyle: 'short',
        })}.png`;
        a.click();
      });
      tableElement?.classList.remove('table-primary--screenshot');
    }
  };

  return (
    <Button
      data-testid={testIdString}
      onClick={generateAndSaveWaterfallImage}
      appearance={ButtonAppearance.DEFAULT_TERTIARY}
      size={ButtonSize.SMALL}
      startIcon={<SaveAsImage />}
      ignoreReadOnly
      disabled={tableElement === null}>
      {l('_SaveAsImage')}
    </Button>
  );
};

export default TableScreenshotAction;
