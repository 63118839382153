import React from 'react';
import { IMask } from 'react-imask';

export const handleOnCopy = (
  event: React.ClipboardEvent<IMask.MaskElement | HTMLTextAreaElement | HTMLInputElement>,
  value: string
) => {
  event.preventDefault();
  event.clipboardData?.setData('text/plain', value);
};
