import { useLocale } from '@app/core/hooks/useLocale';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { toPercentageUsingFormatter } from '@app/shared/formatters';
import { enumKeyByValue } from '@app/shared/helpers';
import { InstrumentType, OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';
import { OpmStackedDistributionPlotDataPointDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { formatNumber } from '../format-helpers';
import ExitEquityChart from './ExitEquityChart';
import {
  DistributionComparator,
  ExitEquityInstrumentTableSection,
} from './ExitEquityInstrumentTableSection';
import styles from '../opm-charts.module.scss';
import { EmptyValues } from '@app/shared/constants/empty-values';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@app/core/hooks/redux-hooks';

interface ExitEquityModalProps {
  selectedPoint: OpmStackedDistributionPlotDataPointDto | undefined;
  isOpen: boolean;
  onClose: () => void;
}

export const ExitEquityModal: FC<ExitEquityModalProps> = ({ selectedPoint, isOpen, onClose }) => {
  const opmCalc = useAppSelector((state) => state.opmCalculation);

  const { l } = useLocale();

  const netDebt = Number(selectedPoint?.netDebt ?? 0);

  // this will be true if there is net cash (-ve debt)
  const hasNetCash = netDebt < 0;
  const distributionComparator = hasNetCash
    ? DistributionComparator.TotalEquityProceeds
    : DistributionComparator.NetExitProceeds;
  const isEquitySimulation =
    opmCalc?.riskFreeValues?.inputs?.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.Equity);

  const slnInstrumentDistributions = useMemo(() => {
    if (selectedPoint) {
      return selectedPoint.instrumentDistributions.filter(
        (i) =>
          i.instrumentType === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
      );
    }
  }, [selectedPoint]);

  const prefInstrumentDistributions = useMemo(() => {
    if (selectedPoint) {
      return selectedPoint.instrumentDistributions.filter(
        (i) => i.instrumentType === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
      );
    }
  }, [selectedPoint]);

  const ordEquityInstrumentDistributions = useMemo(() => {
    if (selectedPoint) {
      return selectedPoint.instrumentDistributions.filter(
        (i) => i.instrumentType === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
      );
    }
  }, [selectedPoint]);

  const getFormattedNetDebtPercentageOfNetExitProceeds = () => {
    // if we have a negative percentage (i.e. net cash), display as a dash
    const percentage = selectedPoint?.netDebtPercentageOfNetExitProceeds ?? 0;

    return hasNetCash ? EmptyValues.EnDash : toPercentageUsingFormatter(percentage);
  };

  return (
    <BaseModal
      size={ModalSize.Large}
      isOpen={isOpen}
      onCloseButtonClick={onClose}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}>
      <>
        <div>
          <h2>{l('_ExitCapitalStructure')}</h2>
          <div className={styles['table-flex']}>
            <div aria-hidden>
              {selectedPoint && <ExitEquityChart selectedPoint={selectedPoint} />}
            </div>
            <div>
              <h3>
                {isEquitySimulation ? l('_EquityNetOfExitCosts') : l('_EvNetOfExitCosts')}:{' '}
                {formatNumber(selectedPoint?.netExitProceeds, 0, true)}
              </h3>
              <table className="table-primary" style={{ borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th
                      data-testid="component-header"
                      className="table-primary__cell--header-quinary table-primary"
                      style={{ minWidth: '120px', paddingLeft: '0' }}>
                      {l('_Component')}
                    </th>
                    <th
                      data-testid="dist-header"
                      className="table-primary__cell--header-quinary table-primary__cell--right"
                      style={{ minWidth: '120px' }}>
                      {l('_Proceeds')}
                    </th>
                    <th
                      data-testid="dist-pct-header"
                      className="table-primary__cell--header-quinary table-primary__cell--right"
                      style={{ minWidth: '120px' }}>
                      {hasNetCash || isEquitySimulation
                        ? l('_NetEquityPercentage')
                        : l('_NetEvPercentage')}
                    </th>
                    <th
                      data-testid="mom-header"
                      className="table-primary__cell--header-quinary table-primary__cell--right"
                      style={{ minWidth: '120px' }}>
                      {l('_MoM')}
                    </th>
                    <th
                      data-testid="irr-header"
                      className="table-primary__cell--header-quinary table-primary__cell--right"
                      style={{ minWidth: '120px' }}>
                      {l('_IRR')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isEquitySimulation && (
                    <tr>
                      <td
                        className="table-primary"
                        style={{ paddingLeft: '0', verticalAlign: 'middle' }}>
                        <Button
                          style={{ height: '0px', fontWeight: '400' }}
                          appearance={ButtonAppearance.CLEAN}
                          ignoreReadOnly>
                          <span
                            className={classNames([styles['icon'], styles['icon--net-debt']])}
                          />
                          {l('_NetDebtOrNetCashNegative')}
                        </Button>
                      </td>
                      <td data-testid="net-debt">
                        <CellValue value={selectedPoint?.netDebt} {...amountRules} isAbbreviated />
                      </td>
                      <td data-testid="net-debt-pct">
                        <CellValue
                          value={getFormattedNetDebtPercentageOfNetExitProceeds()}
                          alignment={Alignment.Right}
                        />
                      </td>
                      <td className="table-primary__cell--right" />
                      <td className="table-primary__cell--right" />
                    </tr>
                  )}
                  {slnInstrumentDistributions && slnInstrumentDistributions.length > 0 && (
                    <ExitEquityInstrumentTableSection
                      instrumentType={InstrumentType.ShareholderLoanNotes}
                      instrumentDistributions={slnInstrumentDistributions}
                      displayInstrumentReturns={false}
                      distributionComparator={distributionComparator}
                    />
                  )}
                  {prefInstrumentDistributions && prefInstrumentDistributions.length > 0 && (
                    <ExitEquityInstrumentTableSection
                      instrumentType={InstrumentType.PreferredShares}
                      instrumentDistributions={prefInstrumentDistributions}
                      displayInstrumentReturns={false}
                      distributionComparator={distributionComparator}
                    />
                  )}
                  {ordEquityInstrumentDistributions &&
                    ordEquityInstrumentDistributions.length > 0 && (
                      <ExitEquityInstrumentTableSection
                        instrumentType={InstrumentType.OrdinaryEquity}
                        instrumentDistributions={ordEquityInstrumentDistributions}
                        displayInstrumentReturns
                        distributionComparator={distributionComparator}
                      />
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    </BaseModal>
  );
};
