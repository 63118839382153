import { FC } from 'react';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import BaseModal from '@app/shared/components/modal/BaseModal';
import ErrorContent from '@app/shared/components/status-pages/status-contents/error/ErrorContent';
import * as notificationActions from '@app/core/store/notification-slice';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import NoAccessContent from '@app/shared/components/status-pages/status-contents/no-access/NoAccessContent';
import NotFoundContent from '@app/shared/components/status-pages/status-contents/not-found/NotFoundContent';
import { StatusCodes } from '@app/shared/enums/status-codes';

const ErrorHandlingModal: FC = (): JSX.Element => {
  const notificationState = useAppSelector((state) => state.notification);
  const dispatch = useAppDispatch();
  const shouldShowModalActions =
    notificationState.notification?.errorCode !== StatusCodes.NotFound &&
    notificationState.notification?.errorCode !== StatusCodes.InternalServerError;
  const renderSwitch = (errorCode: number) => {
    switch (errorCode) {
      case StatusCodes.InternalServerError:
        return <ErrorContent />;
      case StatusCodes.NotFound:
        return <NotFoundContent />;
      case StatusCodes.Forbidden:
        return <NoAccessContent />;
      default:
        return <ErrorContent />;
    }
  };

  return (
    <BaseModal
      size={ModalSize.Small}
      ignoreReadOnly
      {...(shouldShowModalActions
        ? {
            buttonCancelLabel: 'Close',
          }
        : {})}
      {...(shouldShowModalActions
        ? {
            onCancel: () => {
              dispatch(notificationActions.closeNotificationModal());
            },
          }
        : {})}
      onCloseButtonClick={() => {
        dispatch(notificationActions.closeNotificationModal());
      }}
      isOpen={notificationState.isErrorModalVisible}>
      {notificationState.notification?.errorCode &&
        renderSwitch(notificationState.notification?.errorCode)}
    </BaseModal>
  );
};

export default ErrorHandlingModal;
