import { CapitalStructureEventFaults } from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { BuildStructureErrorCodes } from '@app/shared/models/contracts/enums/shared-enums';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import { t } from 'i18next';
import { translationKeys } from '@locale/setupI18n';

export const findSelectedCases = (
  project: ProjectDto,
  eventId: string,
  capitalStructureKey: string
) => {
  const selectedEventSetIds = Object.entries(
    project.capitalStructures[capitalStructureKey].eventSets
  )
    .filter(([_, eventSet]) => eventSet.events.includes(eventId))
    .map(([eventSetId, _]) => eventSetId);
  const selectedCasesIds = project.pwermInput.cases
    .filter((cases) => selectedEventSetIds.includes(cases.eventSetId ?? ''))
    .map((cases) => cases.caseId);
  return selectedCasesIds;
};

const errorMessages: { [key: number]: translationKeys } = {
  [BuildStructureErrorCodes.CSB_MISC_ERROR]: '_MiscError',
  [BuildStructureErrorCodes.CSB_TOO_MANY_EVENTSETS]: '_TooManyEventSets',
  [BuildStructureErrorCodes.CSB_TOO_MANY_EVENTS]: '_TooManyEvents',
  [BuildStructureErrorCodes.CSB_EVENTSET_NOT_FOUND]: '_EventSetNotFound',
  [BuildStructureErrorCodes.CSB_NO_STRUCTURE_FOR_DATE]: '_NoStructureForDate',
  [BuildStructureErrorCodes.CSB_DEF_INSTRUMENT_NARRATIVE_NOT_UNIQUE]:
    '_InstrumentNarrativeNotUnique',
  [BuildStructureErrorCodes.CSB_DEF_ORDINARY_RANK_ON_NON_ORDINARY]: '_OrdinaryRankOnNonOrdinary',
  [BuildStructureErrorCodes.CSB_DEF_RANK_OUT_OF_RANGE]: '_RankOutOfRange',
  [BuildStructureErrorCodes.CSB_DEF_VALUED_SET_ON_INSTRUMENT_NOT_PRESENT_AT_INVESTMENT]:
    '_ValuedSetOnInstrumentNotPresentAtInvestment',
  [BuildStructureErrorCodes.CSB_DEF_TOO_MANY_INSTRUMENTS]: '_TooManyInstruments',
  [BuildStructureErrorCodes.CSB_DEF_TOO_MANY_TRANCHES]: '_TooManyTranches',
  [BuildStructureErrorCodes.CSB_DEF_INSTRUMENT_NARRATIVE_TOO_LONG]: '_InstrumentNarrativeTooLong',
  [BuildStructureErrorCodes.CSB_INITIAL_INVESTED_MISSING_OR_INVALID]:
    '_InitialInvestedMissingOrInvalid',
  [BuildStructureErrorCodes.CSB_INITIAL_SHARES_MISSING_OR_INVALID]:
    '_InitialSharesMissingOrInvalid',
  [BuildStructureErrorCodes.CSB_INITIAL_REFERENCE_NON_EXISTENT_DEF]:
    '_InitialReferenceNonExistentDef',
  [BuildStructureErrorCodes.CSB_INITIAL_INVESTED_AND_SHARES_VALUES_NOT_PROVIDED]:
    '_InitialInvestedAndSharesValuesNotProvided',
  [BuildStructureErrorCodes.CSB_EVENTSET_REFERENCE_NON_EXISTENT_EVENT]:
    '_EventSetReferenceNonExistentEvent',
  [BuildStructureErrorCodes.CSB_EVENTSET_HAS_NO_EVENTS]: '_EventSetHasNoEvents',
  [BuildStructureErrorCodes.CSB_EVENTSET_CONTAINS_DUPLICATE_EVENT_DATES]:
    '_EventSetContainsDuplicateEventDates',
  [BuildStructureErrorCodes.CSB_EVENTSET_TOO_MANY_EVENTS]: '_EventSetTooManyEvents',
  [BuildStructureErrorCodes.CSB_EVENTSET_DUPLICATE_EVENT_REFERENCES]:
    '_EventSetDuplicateEventReferences',
  [BuildStructureErrorCodes.CSB_EVENT_INSTRUMENT_NO_MOVEMENT]: '_EventInstrumentNoMovement',
  [BuildStructureErrorCodes.CSB_EVENT_NO_INSTRUMENTS]: '_EventNoInstruments',
  [BuildStructureErrorCodes.CSB_EVENT_REFERENCE_NON_EXISTENT_INSTRUMENT_DEF]:
    '_EventReferenceNonExistentInstrumentDef',
  [BuildStructureErrorCodes.CSB_EVENT_DATE_PREDATES_INVESTMENT]: '_EventDatePredatesInvestment',
  [BuildStructureErrorCodes.CSB_EVENT_NARRATIVE_NOT_UNIQUE]: '_EventNarrativeNotUnique',
  [BuildStructureErrorCodes.CSB_EVENT_NARRATIVE_TOO_LONG]: '_EventNarrativeTooLong',
  [BuildStructureErrorCodes.CSB_EVENT_LEAVES_NO_ORDINARY_IN_STRUCTURE]:
    '_EventLeavesNoOrdinaryInStructure',
  [BuildStructureErrorCodes.CSB_EVENT_DATE_TOO_FAR_BEFORE_VALUATION_DATE]:
    '_EventDateCannotBeMoreThanXYearsBeforeValuationDate',
  [BuildStructureErrorCodes.CSB_MOVEMENT_NEW_INSTRUMENT_ALREADY_EXISTS]:
    '_MovementNewInstrumentAlreadyExists',
  [BuildStructureErrorCodes.CSB_MOVEMENT_REDEMPTION_ON_NON_EXISTENT_INSTRUMENT]:
    '_MovementRedemptionOnNonExistentInstrument',
  [BuildStructureErrorCodes.CSB_MOVEMENT_VALUE_REDEMPTION_RESULTS_NEGATIVE_VALUE]:
    '_MovementValueRedemptionResultsNegativeValue',
  [BuildStructureErrorCodes.CSB_MOVEMENT_QUANTITY_REDEMPTION_RESULTS_NEGATIVE_SHARES]:
    '_MovementQuantityRedemptionResultsNegativeShares',
  [BuildStructureErrorCodes.CSB_MOVEMENT_COUPON_UPDATE_ON_INSTRUMENT_NOT_PRESENT]:
    '_MovementCouponUpdateOnInstrumentNotPresent',
  [BuildStructureErrorCodes.CSB_MOVEMENT_UPDATE_ON_NON_EXISTENT_INSTRUMENT_IMPLICIT_ADD]:
    '_MovementUpdateOnNonExistentInstrumentImplicitAdd',
  [BuildStructureErrorCodes.CSB_MOVEMENT_HAS_NO_EFFECT]: '_MovementHasNoEffect',
  [BuildStructureErrorCodes.CSB_MOVEMENT_COUPON_UPDATE_ON_NON_COUPONABLE_INSTRUMENT]:
    '_MovementCouponUpdateOnNonCouponableInstrument',
  [BuildStructureErrorCodes.CSB_MOVEMENT_CAUSES_OVERFLOW]: '_MovementCausesOverflow',
  [BuildStructureErrorCodes.CSB_MOVEMENT_COUPON_DATE_PREDATES_PREVIOUS]:
    '_MovementCouponDatePredatesPrevious',
  [BuildStructureErrorCodes.CSB_MOVEMENT_SPECIFIES_BOTH_ADJUSTMENT_AND_ABSOLUTE_VALUES]:
    '_MovementSpecifiesBothAdjustmentAndAbsoluteValues',
  [BuildStructureErrorCodes.CSB_MOVEMENT_ADJUSTMENT_CAUSES_FULL_REDEMPTION]:
    '_MovementAdjustmentCausesFullRedemption',
  [BuildStructureErrorCodes.CSB_MOVEMENT_NEW_INSTRUMENT_INITIAL_VALUE_NOT_PROVIDED]:
    '_MovementNewInstrumentInitialValueNotProvided',
  [BuildStructureErrorCodes.CSB_MOVEMENT_NEW_INSTRUMENT_INITIAL_SHARES_NOT_PROVIDED]:
    '_MovementNewInstrumentInitialSharesNotProvided',
  [BuildStructureErrorCodes.CSB_MOVEMENT_VALUE_NOT_PROVIDED_FOR_OWNER]:
    '_MovementValueNotProvidedForOwner',
};

export const mapBuildStructureErrorToMessage = (
  error: CapitalStructureEventFaults,
  context: {
    caseNarrative?: string;
    eventDate?: string;
    maxYearsBetweenEventsAndValuation?: number;
  } = {}
): string => {
  const { caseNarrative, eventDate, maxYearsBetweenEventsAndValuation } = context;
  const errorCode = error.errorCode;

  if (errorMessages[errorCode]) {
    return t(errorMessages[errorCode], {
      caseNarrative,
      date: eventDate,
      maxYearsBetweenEventsAndValuation,
    });
  }

  return t('_GenericErrorMessage');
};
