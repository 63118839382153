import { FC, useEffect, useState } from 'react';
import { getCaseById } from '@core/store/project-slice-selectors';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import {
  amountRules,
  enteredPercentageViewRules,
  multiplierRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { DefaultDashboardDropdownValues } from '@app/modules/projects/dashboard/widgets/default-dashboard-dropdown-values-enum';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useEVGeneralMetrics } from '@app/modules/projects/dashboard/widgets/ev-evolution/useEVGeneralMetrics';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ToggleWidget } from '@app/shared/enums/expand-collapse';
import { EVEvolutionChart } from '@app/modules/projects/dashboard/widgets/ev-evolution/EVEvolutionChart';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import {
  setPwermDashboardEvolutionChartIsExpanded,
  setPwermDashboardEvolutionChartSelectedCase,
} from '@app/core/store/ui-values-slice';
import { useLocalStateCollapse } from '@app/core/hooks/useLocalStateCollapse';
import { useLocale } from '@app/core/hooks/useLocale';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';

export const EvEvolution: FC = (): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const storedCase = useAppSelector(
    (state) => state.uiValues.userSelections.pwermDashboard.evEvolutionChart.selectedCase
  );
  const storedExpandedState = useAppSelector(
    (state) => state.uiValues.userSelections.pwermDashboard.evEvolutionChart.isExpanded
  );

  const defaultExpandedState = storedExpandedState ?? false;
  const [isExpanded, setIsExpanded] = useState(defaultExpandedState);

  useLocalStateCollapse(isExpanded, setPwermDashboardEvolutionChartIsExpanded);

  const caseOptions = [
    { value: DefaultDashboardDropdownValues.AllCases, viewValue: 'All cases' },
    ...getCasesWithNonZeroProbability(sortedProjectByCase.pwermInput.cases).map((caseItem) => ({
      value: caseItem.caseId,
      viewValue: caseItem.narrative,
    })),
    { value: DefaultDashboardDropdownValues.WeightedAverage, viewValue: 'Weighted Average' },
  ];

  const isStoredCaseValid = storedCase && caseOptions.some((option) => option.value === storedCase);

  const defaultViewOption = isStoredCaseValid
    ? storedCase
    : DefaultDashboardDropdownValues.AllCases;

  const formMethods = useForm<{ viewOption: string }>({
    ...formConfigBase,
    defaultValues: {
      viewOption: defaultViewOption,
    },
  });

  const { watch, setValue } = formMethods;
  const selectedViewOption = watch('viewOption');

  useEffect(() => {
    if (selectedViewOption !== storedCase) {
      dispatch(setPwermDashboardEvolutionChartSelectedCase(selectedViewOption));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewOption]);

  const selectedCase = getCaseById(project, selectedViewOption);

  const isSpecificCaseSelected = !(
    selectedViewOption === DefaultDashboardDropdownValues.AllCases ||
    selectedViewOption === DefaultDashboardDropdownValues.WeightedAverage
  );

  const forecastsCount = isSpecificCaseSelected
    ? selectedCase?.forecasts.length
    : getCaseWithMostForecasts(sortedProjectByCase.pwermInput.cases).forecasts.length;

  const {
    currentEV,
    currentValueDriverMultiple,
    multipleForecastProbabilityWeightedMultiple,
    multipleForecastCaseProbabilityWeightedMultiple,
    multipleForecastProbabilityWeightedExitEV,
    multipleForecastCaseProbabilityWeightedExitEV,
    selectedCaseCAGR,
    caseProbabilityWeightedCAGR,
  } = useEVGeneralMetrics(selectedCase);

  const valueDriverName = getPwermValuationValueDriver(project).narrative;

  const data = [
    {
      label: 'Current EV',
      value: currentEV,
      format: amountRules,
    },
    {
      label: `Current ${valueDriverName} Multiple`,
      value: currentValueDriverMultiple,
      format: multiplierRules,
    },
    {
      label: `Exit ${valueDriverName} Multiple`,
      value: isSpecificCaseSelected
        ? multipleForecastProbabilityWeightedMultiple
        : multipleForecastCaseProbabilityWeightedMultiple,
      format: multiplierRules,
    },
    {
      label: 'Exit EV',
      value: isSpecificCaseSelected
        ? multipleForecastProbabilityWeightedExitEV
        : multipleForecastCaseProbabilityWeightedExitEV,
      format: amountRules,
    },
    {
      label: `${forecastsCount} Year EV CAGR`,
      value: isSpecificCaseSelected ? selectedCaseCAGR : caseProbabilityWeightedCAGR,
      format: enteredPercentageViewRules,
    },
  ];

  return (
    <DashboardWidgetContainer
      title="EV Evolution"
      isExpanded={isExpanded}
      actions={
        <FormProvider {...formMethods}>
          <form className="dashboard-widget-controls">
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              onClick={() => setIsExpanded(!isExpanded)}
              className="dashboard-widget-controls__button"
              ignoreReadOnly>
              {isExpanded ? ToggleWidget.Collapse : ToggleWidget.Expand}
            </Button>
            <div className="dashboard-widget-controls__item">
              <FormSelect
                ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartView') })}
                name="viewOption"
                required
                options={caseOptions}
                isGapless
                ignoreReadOnly
              />
            </div>
          </form>
        </FormProvider>
      }
      secondaryContent={
        <table className="table-primary table-primary--fixed table-primary--secondary-theme">
          <thead>
            <tr>
              {data.map((item, index) => (
                <th key={index} className="table-primary__cell--header-quinary">
                  {item.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {data.map((item, index) => (
                <td key={index}>
                  <CellValue
                    value={item.value}
                    {...item.format}
                    className="heading-2 heading-2--alternate"
                    alignment={Alignment.Left}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      }>
      <EVEvolutionChart
        selectedCase={selectedCase}
        selectedViewOption={selectedViewOption}
        setSelectedViewOption={setValue}
        isExpanded={isExpanded}
      />
    </DashboardWidgetContainer>
  );
};
