import { Option } from '@app/shared/models/option';
import { EMPTY_DROPDOWN_VALUE } from '@app/shared/constants/dropdown-values/empty-select-option';

interface OverrideValues {
  overrideValue?: string;
  overrideStringValue?: string;
}

export const enumToOptions = <T extends Record<string, string>>(
  enumeration: T,
  overrideValues?: OverrideValues[],
  includeEmptyValueAtStart?: boolean,
  customEmptyOptionValue?: Option
): Option[] => {
  const valueOptions: Option[] = [];

  Object.entries(enumeration).map(([key, value]) => {
    const overrideMatch = overrideValues?.find((item) => item.overrideValue === value);
    valueOptions.push({
      viewValue: overrideValues ? overrideMatch?.overrideStringValue ?? value : value,
      value: key,
    });
  });

  if (includeEmptyValueAtStart) {
    valueOptions.unshift(customEmptyOptionValue ?? EMPTY_DROPDOWN_VALUE);
  }

  return valueOptions;
};
