import { StatusPage } from '@app/shared/components/status-pages/StatusPage';
import styles from './under-maintenance.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectMaintenanceModeSettings } from '@app/core/store/ui-values-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';

const PARENT_CLASSNAME = 'under-maintenance-page';

const UnderMaintenancePage: React.FC = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const { l } = useLocale();
  const maintenanceModeSettings = useAppSelector(selectMaintenanceModeSettings);

  useEffect(() => {
    if (!maintenanceModeSettings.active) {
      window.location.href = '/';
    }
  }, [maintenanceModeSettings.active, location.pathname, navigate]);

  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      <StatusPage>
        <p className="message">{l('_MaintenancePageMessage')}</p>
      </StatusPage>
    </div>
  );
};

export default UnderMaintenancePage;
