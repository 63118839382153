import { CaseDto } from '../../models/contracts/project-dto';
import { v4 as uuidv4 } from 'uuid';
import { getOldNewIdPairs } from '@app/shared/helpers';

export const cloneCase = (caseToClone: CaseDto): CaseDto => {
  const oldNewMultipleIdPairs = getOldNewIdPairs(caseToClone.multiples, (m) => m.multipleId);
  const oldNewNetDebtItemIdPairs = getOldNewIdPairs(
    caseToClone.netDebtItems,
    (m) => m.netDebtItemId
  );

  return {
    ...caseToClone,
    caseId: uuidv4(),
    multiples: [
      ...caseToClone.multiples.map((item) => ({
        ...item,
        multipleId: oldNewMultipleIdPairs[item.multipleId],
      })),
    ],
    netDebtItems: [
      ...caseToClone.netDebtItems.map((item) => ({
        ...item,
        netDebtItemId: oldNewNetDebtItemIdPairs[item.netDebtItemId],
      })),
    ],
    forecasts: [
      ...caseToClone.forecasts.map((item) => ({
        ...item,
        forecastId: uuidv4(),
        multipleProbabilities: [
          ...item.multipleProbabilities.map((multipleProbability) => ({
            ...multipleProbability,
            multipleId: oldNewMultipleIdPairs[multipleProbability.multipleId],
          })),
        ],
        netDebtItems: [
          ...item.netDebtItems.map((netDebtItem) => ({
            ...netDebtItem,
            netDebtItemId: oldNewNetDebtItemIdPairs[netDebtItem.netDebtItemId],
          })),
        ],
        inputs: { ...item.inputs, inputId: uuidv4() },
      })),
    ],
  };
};
