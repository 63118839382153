import EmptyStateNoListSvg from '@app/shared/icons/legacy/EmptyStateNoListSvg';
import { FC } from 'react';
import styles from './capital-structure.module.scss';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import CapitalStructureItemForm from '@app/modules/projects/inputs/capital-structure/CapitalStructureItemForm';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import Button from '@app/shared/components/button/Button';
import * as projectActions from '@core/store/project-slice';
import Sidebar, { SidebarVariation } from '@app/shared/components/sidebar/Sidebar';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { getProjectDraftWithRemovedInstrument } from '@app/shared/helpers';
import SvgTrash from '@app/shared/icons/Trash';

const PARENT_CLASSNAME = 'capital-structure';

const CapitalStructureSidebar: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const activeInstrumentId = useAppSelector((state) => state.uiValues.activeInstrument);
  const equityInstruments = useAppSelector((state) => state.project.projectDraft.equityInstruments);
  const equityItem: EquityInstrumentDto | undefined = equityInstruments.find(
    (x) => x.instrumentId === activeInstrumentId?.instrumentId
  );

  const dispatch = useAppDispatch();

  const removeInstrument = async (instrumentId: string | undefined) => {
    if (instrumentId) {
      await dispatch(
        projectActions.updateProjectDraft({
          project: getProjectDraftWithRemovedInstrument({ draft: project, instrumentId }),
        })
      );

      dispatch(uiValuesSlice.actions.setActiveInstrument(undefined));
    }
  };

  return (
    <>
      {equityItem && (
        <Sidebar
          data-testid="instrument-details-sidebar"
          actions={
            <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
              <Button
                onClick={() => removeInstrument(activeInstrumentId?.instrumentId)}
                size={ButtonSize.FULL_WIDTH}
                startIcon={<SvgTrash />}
                appearance={ButtonAppearance.DEFAULT_SECONDARY}>
                Delete Instrument
              </Button>
            </div>
          }>
          <CapitalStructureItemForm equityItem={equityItem} />
        </Sidebar>
      )}
      {!activeInstrumentId && (
        <Sidebar sidebarVariation={SidebarVariation.Empty} data-testid="instrument-details-sidebar">
          <EmptyStateNoListSvg className={styles[`${PARENT_CLASSNAME}__icon`]} />
          <span>You haven’t selected anything yet</span>
        </Sidebar>
      )}
    </>
  );
};

export default CapitalStructureSidebar;
