import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { Legend } from '@app/shared/components/legend/Legend';
import styles from './build-structure-legend.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';

export const BuildStructureLegend = (): JSX.Element | null => {
  const { l } = useLocale();
  return (
    <>
      <div className={styles['legend']}>
        <div className={styles['legend-group']}>
          <Legend legendText={InstrumentType.ShareholderLoanNotes} />
          <Legend
            className={styles['legend-group__item']}
            legendText={InstrumentType.PreferredShares}
          />
          <Legend
            className={styles['legend-group__item']}
            legendText={InstrumentType.OrdinaryEquity}
          />
          <Legend className={styles['legend-group__item']} legendText={l('_SweetEquityTitle')} />
        </div>
        <div className={styles['legend-group__last']}>
          <div className={styles['instrument-wrapper']}>
            <div className={styles['instrument__added-instrument']}>{l('_AddedInstrument')}</div>
            <div className={styles['instrument__amended-instrument']}>
              {l('_AmendedInstrument')}
            </div>
            <div className={styles['instrument__redeemed-instrument']}>
              {l('_RedeemedInstrument')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
