import { FC, useEffect } from 'react';
import styles from './dev-feature-setup.module.scss';
import { Card } from '@app/shared/components/card/Card';
import { DevFeatureList, FeaturesConfig, useDevFeatures } from '@core/utils/dev-feature';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { Toggle } from '@app/shared/components/toggle/Toggle';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';

export const DevFeatureSetup: FC = (): JSX.Element => {
  const { availableFeatures, isDevFeatureEnabled, updateFeaturesStatus } = useDevFeatures();

  const setAllFeaturesStatus = (
    acc: FeaturesConfig,
    feature: DevFeatureList,
    isEnabled: boolean
  ) => ({
    ...acc,
    [feature]: isEnabled,
  });

  const defaultValues = availableFeatures
    ? availableFeatures.reduce(
        (acc, feature) => setAllFeaturesStatus(acc, feature, isDevFeatureEnabled(feature)),
        {} as FeaturesConfig
      )
    : undefined;

  const formMethods = useForm<FeaturesConfig>({
    ...formConfigBase,
    defaultValues,
  });

  const { watch, reset } = formMethods;

  const enableAllFeatures = () =>
    reset(
      availableFeatures.reduce(
        (acc, feature) => setAllFeaturesStatus(acc, feature, true),
        {} as FeaturesConfig
      )
    );

  const resetEnabledFeatures = () =>
    reset(
      availableFeatures.reduce(
        (acc, feature) => setAllFeaturesStatus(acc, feature, false),
        {} as FeaturesConfig
      )
    );

  useEffect(() => {
    const subscription = watch((value) => updateFeaturesStatus(value as FeaturesConfig));

    return () => subscription.unsubscribe();
  }, [updateFeaturesStatus, watch]);

  return (
    <div className={styles['container']}>
      <Card hasShadow>
        <h1>Enable features under development flag</h1>
        <hr />
        <div className={styles['container__message']}>
          Please note that upon enabling the feature currently under development, you may experience
          some bugs within the application.
        </div>
        <FormProvider {...formMethods}>
          <form>
            <ul className={styles['features-list']}>
              {availableFeatures.length === 0 && (
                <>
                  <br />
                  <div>Currently no feature flags are available.</div>
                </>
              )}
              {availableFeatures.map((feature, index) => (
                <li key={index} className={styles['features-list__item']}>
                  <Toggle name={feature} label={feature} />
                </li>
              ))}
            </ul>
            <div className={styles['container__controls']}>
              <Button
                appearance={ButtonAppearance.DEFAULT_PRIMARY}
                onClick={enableAllFeatures}
                disabled={availableFeatures.length === 0}
                className={styles['container__controls-item']}>
                Enable All
              </Button>
              <Button
                appearance={ButtonAppearance.DEFAULT_SECONDARY}
                onClick={resetEnabledFeatures}
                disabled={availableFeatures.length === 0}
                className={styles['container__controls-item']}>
                Reset
              </Button>
            </div>
          </form>
        </FormProvider>
      </Card>
    </div>
  );
};
