import { IMaskInputProps } from 'react-imask/dist/mixin';
import { IMask } from 'react-imask';
import { EmptyValues } from '@app/shared/constants/empty-values';
import styles from './cell-value.module.scss';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import {
  abbreviatedValueFormatter,
  dateFormatter,
  numberValueFormatter,
} from '@app/shared/formatters';
import { Alignment } from '@app/shared/enums/alignment.enum';
import ExclamationMarkCircled from '@app/shared/icons/ExclamationMarkCircled';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { TextTransform } from '@app/shared/enums/text-transform.enum';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';

interface CellValueProps extends HTMLAttributes<HTMLElement> {
  alignment?: Alignment;
  theme?: CellValueTheme;
  textTransform?: TextTransform;
  dateFormat?: Intl.DateTimeFormatOptions;
  decimalPoint?: number;
  errorMessage?: string;
  warningMessage?: string;
  formatPattern?: IMaskInputProps;
  normalize?: boolean;
  prefix?: string;
  strong?: boolean;
  suffix?: string;
  tooltipVariation?: GridFieldTooltipVariation;
  value: string | number | boolean | undefined | null;
  dataTestId?: string;
  required?: boolean;
  isAbbreviated?: boolean;
  allowEmptyValues?: boolean;
}

const PARENT_CLASSNAME = 'cell-value';
const CellValue = ({
  alignment,
  theme,
  textTransform,
  className,
  dateFormat,
  decimalPoint,
  errorMessage,
  warningMessage,
  formatPattern,
  normalize,
  prefix,
  strong,
  suffix,
  tooltipVariation,
  value,
  dataTestId = 'cell-value',
  required,
  isAbbreviated = false,
  allowEmptyValues = false,
}: CellValueProps): JSX.Element => {
  const numberValueFormatterValue = numberValueFormatter({
    value: typeof value == 'number' ? value : Number(value),
    fraction: decimalPoint,
    valuePrefix: prefix,
    valueSuffix: suffix,
    isNormalised: normalize,
  });

  const numberTitleValueFormatterValue = numberValueFormatter({
    value: typeof value == 'number' ? value : Number(value),
    fraction: 6,
    valuePrefix: prefix,
    valueSuffix: suffix,
    isNormalised: normalize,
  });

  const getFormattedValue = (
    value: string | number | undefined | null,
    numberFormatter: ReturnType<typeof numberValueFormatter>
  ): string => {
    const decimalPointProvided = Boolean(decimalPoint) || decimalPoint === 0;
    if ((value || value === 0) && value !== 'Undefined') {
      if (formatPattern) {
        const formattedValue = decimalPointProvided ? numberFormatter : value;
        return IMask.pipe(formattedValue.toString(), formatPattern);
      } else if (decimalPointProvided) {
        return numberFormatter;
      } else if (dateFormat) {
        return dateFormatter(value.toString(), dateFormat);
      } else {
        return `${prefix ? prefix : ''}${value.toString()}${suffix ? suffix : ''}`;
      }
    }
    if (allowEmptyValues) {
      return '';
    }
    return EmptyValues.EnDash;
  };

  const formattedValue = isAbbreviated
    ? abbreviatedValueFormatter({ value: Number(value) })
    : typeof value === 'boolean'
    ? value.toString()
    : getFormattedValue(value, numberValueFormatterValue);

  const formattedTitleValue =
    typeof value === 'boolean'
      ? value.toString()
      : getFormattedValue(value, numberTitleValueFormatterValue);

  return (
    <span
      className={classNames(
        styles[PARENT_CLASSNAME],
        {
          [styles[`${PARENT_CLASSNAME}--${alignment}`]]: alignment,
          [styles[`${PARENT_CLASSNAME}--${theme}-theme`]]: theme,
          [styles[`${PARENT_CLASSNAME}--${textTransform}`]]: textTransform,
          [styles[`${PARENT_CLASSNAME}--error`]]: errorMessage,
          [styles[`${PARENT_CLASSNAME}--warning`]]: warningMessage,
          [styles[`${PARENT_CLASSNAME}--strong`]]: strong,
        },
        className
      )}
      title={formattedTitleValue}>
      <ExclamationMarkCircled className={styles[`${PARENT_CLASSNAME}__icon`]} />
      <span className={classNames(styles[`${PARENT_CLASSNAME}__value`])} data-testid={dataTestId}>
        {formattedValue}
        {required ? <sup className={styles['required-field']}>*</sup> : ''}
      </span>
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__tooltip-content`], {
          [styles[`${PARENT_CLASSNAME}--${alignment}`]]: alignment,
          [styles[`${PARENT_CLASSNAME}__tooltip-content--${tooltipVariation}`]]: tooltipVariation,
        })}>
        {errorMessage ?? warningMessage}
      </div>
    </span>
  );
};

export default CellValue;
