import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import * as projectActions from '@core/store/project-slice';
import { DragAndDropSecondaryNavigation } from '@app/shared/components/secondary-navigation/DragAndDropSecondaryNavigation';
import styles from './deal-thesis-page.module.scss';

export const DealThesisPageNavigation = () => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const dispatch = useAppDispatch();

  const updateCaseOrder = async (navigationItems: NavigationItem[]) => {
    const updatedCases = project.pwermInput.cases.map((obj) => {
      const item = navigationItems.find((item) => item.params!.caseId === obj.caseId);
      if (item) {
        return { ...obj, caseOrder: item.order };
      }
      return obj;
    });

    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          pwermInput: {
            ...project.pwermInput,
            cases: updatedCases,
          },
        },
      })
    );
  };

  const navigationItems: NavigationItem[] =
    project.pwermInput.cases?.map((item) => ({
      label: item.narrative || EmptyValues.EnDash,
      route: '../:caseId',
      params: { caseId: item.caseId },
      order: item.caseOrder,
      additionalData:
        item.probability || item.probability === 0
          ? numberValueFormatter({ value: item.probability, ...percentageValueFormatterProps })
          : EmptyValues.EnDash,
    })) || [];

  navigationItems.sort((a, b) => a.order! - b.order!);

  return (
    <div className={styles['case-selector-height']}>
      <DragAndDropSecondaryNavigation
        navigationItems={navigationItems}
        updateCaseOrder={updateCaseOrder}
      />
    </div>
  );
};
