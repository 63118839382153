import { useLocale } from '@app/core/hooks/useLocale';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import {
  calculatedMultipleValueFormatterProps,
  numberValueFormatter,
} from '@app/shared/formatters';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { OpmInstrumentDistributionResultsDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import classNames from 'classnames';
import { FC, Fragment } from 'react';
import styles from '../opm-charts.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';

export enum DistributionComparator {
  NetExitProceeds = 'NetExitProceeds',
  TotalEquityProceeds = 'TotalEquityProceeds',
}

interface ExitEquityInstrumentTableSectionProps {
  instrumentType: InstrumentType;
  instrumentDistributions: OpmInstrumentDistributionResultsDto[];
  displayInstrumentReturns: boolean;
  distributionComparator: DistributionComparator;
}

export const ExitEquityInstrumentTableSection: FC<ExitEquityInstrumentTableSectionProps> = ({
  instrumentType,
  instrumentDistributions,
  displayInstrumentReturns,
  distributionComparator,
}) => {
  const { l } = useLocale();

  const getTitle = () => {
    switch (instrumentType) {
      case InstrumentType.ShareholderLoanNotes:
        return l('_ShareholderLoanNotesTitle');
      case InstrumentType.PreferredShares:
        return l('_PreferredShares');
      case InstrumentType.OrdinaryEquity:
        return l('_OrdinaryEquity');
      default:
        return 'Unknown';
    }
  };

  const getIconClassSuffix = (instrument: OpmInstrumentDistributionResultsDto) => {
    switch (instrumentType) {
      case InstrumentType.ShareholderLoanNotes:
        return 'sln';
      case InstrumentType.PreferredShares:
        return 'pref';
      case InstrumentType.OrdinaryEquity:
        return instrument.instrumentIsSweetEquity ? 'sweet' : 'ord';
      default:
        return '';
    }
  };

  const getDistributionPercentage = (instrument: OpmInstrumentDistributionResultsDto) => {
    switch (distributionComparator) {
      case DistributionComparator.NetExitProceeds:
        return instrument.distributionPercentageOfNetExitProceeds;
      case DistributionComparator.TotalEquityProceeds:
        return instrument.distributionPercentageOfTotalEquityProceeds;
      default:
        return 0;
    }
  };

  return (
    <>
      <tr style={{ borderBottom: '1px solid #d1d1d1' }}>
        <td
          style={{ paddingLeft: '0' }}
          className={styles['table-subtitle']}
          data-testid="instrument-title">
          {getTitle()}
        </td>
      </tr>
      {instrumentDistributions.map((instrument) => (
        <Fragment key={instrument.instrumentId}>
          <tr data-testid={`instrument-${instrument.instrumentId}`}>
            <td className="table-primary" style={{ paddingLeft: '0', verticalAlign: 'middle' }}>
              <Button
                style={{ height: '0px', fontWeight: '400' }}
                appearance={ButtonAppearance.CLEAN}
                ignoreReadOnly>
                <span
                  className={classNames([
                    styles['icon'],
                    styles[`icon--${getIconClassSuffix(instrument)}`],
                  ])}
                />
                {instrument.instrumentNarrative ?? ''}
              </Button>
            </td>
            <td data-testid={`instrument-dist-${instrument.instrumentId}`}>
              <CellValue value={instrument.distributionAmount} {...amountRules} isAbbreviated />
            </td>
            <td data-testid={`instrument-dist-pct-${instrument.instrumentId}`}>
              <CellValue
                value={getDistributionPercentage(instrument) * 100}
                {...enteredPercentageViewRules}
              />
            </td>
            <td data-testid={`instrument-mom-${instrument.instrumentId}`}>
              {displayInstrumentReturns && (
                <CellValue
                  value={numberValueFormatter({
                    value: instrument.instrumentMoM,
                    ...calculatedMultipleValueFormatterProps,
                  })}
                  alignment={Alignment.Right}
                />
              )}
            </td>
            <td data-testid={`instrument-irr-${instrument.instrumentId}`}>
              {displayInstrumentReturns && (
                <CellValue value={instrument.instrumentIRR * 100} {...enteredPercentageViewRules} />
              )}
            </td>
          </tr>
        </Fragment>
      ))}
    </>
  );
};
