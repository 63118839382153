import { SVGProps } from 'react';
const SvgInformation = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M8 0.5C9.088 0.5 10.128 0.708 11.12 1.124C12.0693 1.52933 12.912 2.10533 13.648 2.852C14.3947 3.588 14.9707 4.43067 15.376 5.38C15.792 6.372 16 7.412 16 8.5C16 9.588 15.792 10.628 15.376 11.62C14.9707 12.5693 14.3947 13.412 13.648 14.148C12.912 14.8947 12.0693 15.4707 11.12 15.876C10.128 16.292 9.088 16.5 8 16.5C6.912 16.5 5.872 16.292 4.88 15.876C3.93067 15.4707 3.08267 14.8947 2.336 14.148C1.6 13.412 1.02933 12.5693 0.624 11.62C0.208 10.628 0 9.588 0 8.5C0 7.412 0.208 6.372 0.624 5.38C1.02933 4.43067 1.6 3.588 2.336 2.852C3.08267 2.10533 3.93067 1.52933 4.88 1.124C5.872 0.708 6.912 0.5 8 0.5ZM8 4.004C7.73333 4.004 7.49867 4.1 7.296 4.292C7.104 4.484 7.008 4.71867 7.008 4.996C7.008 5.27333 7.104 5.51333 7.296 5.716C7.488 5.908 7.72267 6.004 8 6.004C8.27733 6.004 8.512 5.908 8.704 5.716C8.90667 5.51333 9.008 5.27333 9.008 4.996C9.008 4.71867 8.90667 4.484 8.704 4.292C8.512 4.1 8.27733 4.004 8 4.004ZM7.008 12.996H9.008V8.004H7.008V12.996Z"
      fill="#FFB600"
    />
  </svg>
);
export default SvgInformation;
