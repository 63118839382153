import { LOCALE } from '@app/shared/constants/locale';
import { INTL_CACHE } from '@app/shared/helpers/intl/intl-cache';

export const dateFormatter = (
  date?: number | string | Date,
  formatOptions?: Intl.DateTimeFormatOptions
): string => {
  return INTL_CACHE.formatters
    .getDateTimeFormat(LOCALE, { ...formatOptions, timeZone: 'Europe/London' })
    .format(new Date(date ? date : ''));
};

export const yearFormatter = (date?: Nullable<number | string | Date>): string => {
  return date ? dateFormatter(date, { year: 'numeric' }) : 'N/A';
};
