import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { percentageValidator, requiredValidator } from '@core/validations/hook-forms/validators';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import {
  amountBasicFieldFormattingProps,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { YES_NO_OPTIONS } from '@app/shared/constants/dropdown-values/yes-no-options';
import { INVESTMENT_METRIC_OPTIONS } from '@app/shared/constants/dropdown-values/benchmark/investment-metric-options';
import { INVESTMENT_HORIZON_OPTIONS } from '@app/shared/constants/dropdown-values/benchmark/investment-horizon-options';
import { useFormContext } from 'react-hook-form';
import { BenchmarkingDto } from '@app/shared/models/contracts/project-dto';
import { InvestmentMetric, ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  currencyDenominationPair,
  enumKeyByValue,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import {
  amountRules,
  calculatedMultiplierRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';

const DealMetrics: FC = (): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const { watch } = useFormContext<BenchmarkingDto>();
  const investmentMetric = watch('dealMetrics.investmentMetric');
  const {
    storeBenchmarkingData,
    status,
    details: { currency, denomination },
    sourcesAndUses: { dayOneTransactionPurchasePrice },
  } = useAppSelector((state) => state.project.projectDraft);
  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>Deal Metrics</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label={`Entry Enterprise Value (${currencyDenominationPair(currency, denomination)})`}
            value={dayOneTransactionPurchasePrice}
            {...amountRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={`LTM ${
              investmentMetric !== null ? InvestmentMetric[investmentMetric] : '{metric}'
            } Multiple`}
            value={getCalculatedProjectValue('LTMMetricMultiple')}
            {...calculatedMultiplierRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormDatePicker
            name="dealMetrics.debtMaturityDate"
            label="Debt Maturity Date"
            isGapless
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="dealMetrics.investmentMetric"
            label="Investment Metric"
            options={INVESTMENT_METRIC_OPTIONS}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label={`Total Equity Funding (${currencyDenominationPair(currency, denomination)})`}
            value={getCalculatedProjectValue('TotalAmountInvested')}
            {...amountRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="dealMetrics.investmentHorizonYears"
            label="Investment Horizon (Years)"
            options={INVESTMENT_HORIZON_OPTIONS}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name="dealMetrics.entryOfTarget"
            label={`Entry ${
              investmentMetric !== null ? InvestmentMetric[investmentMetric] : '{metric}'
            } of Target (${currencyDenominationPair(currency, denomination)})`}
            placeholder="0"
            {...amountBasicFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="dealMetrics.thirdPartyLeveragePercentOfEV"
            label="Third Party Leverage (% of EV)"
            rules={{
              ...(shouldFieldsBeRequired && requiredValidator),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="dealMetrics.investorMonitoringExitFees"
            label="Investor / Monitoring / Exit Fees?"
            options={YES_NO_OPTIONS}
            isGapless
          />
        </div>
      </div>
    </Card>
  );
};

export default DealMetrics;
