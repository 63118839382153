import '@app/shared/extensions/date.extensions';
import { LoadingStatus } from '@app/shared/models/loading-status-enum';
import { ProjectSummaryDto } from '@app/shared/models/contracts/project-summary-dto';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@server/api-config';
import { AppDispatch } from './store';
import env from '@environment';
import * as notificationActions from '@core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import { CopyProjectDto } from '@app/shared/models/contracts/copy-project-dto';
import { PagedResult } from '@app/shared/models/pagination';

export interface ProjectListState {
  status: LoadingStatus;
  values: PagedResult<ProjectSummaryDto>;
}

export const fetchProjectList = createAsyncThunk<
  PagedResult<ProjectSummaryDto>,
  { offset?: number; limit?: number; filterMyProjects?: boolean; search?: Nullable<string> }
>('projectList/fetchProjects', async ({ offset, limit, filterMyProjects, search }, thunkAPI) => {
  const response = await api.get<PagedResult<ProjectSummaryDto>>(
    `${env.apiUrl}/projects?offset=${offset}&limit=${limit}&onlyMyProjects=${filterMyProjects}${
      search ? '&search=' + encodeURIComponent(search) : ''
    }`
  );
  thunkAPI.dispatch(uiValuesSlice.actions.setIsLoggedIn());
  return response.data;
});

export const saveNewProject = createAsyncThunk<number, ProjectDto, { dispatch: AppDispatch }>(
  'project/saveNewProject',
  async (project: ProjectDto, thunkAPI) => {
    const saveResponse = await api.post<number>(`${env.apiUrl}/projects`, project);

    await thunkAPI.dispatch(
      notificationActions.showNotificationSnackbar({
        severity: SeverityType.success,
        message: `${project.name} was successfully created.`,
        autoHide: true,
      })
    );

    return saveResponse.data;
  }
);

export const copyProject = createAsyncThunk<number, CopyProjectDto, { dispatch: AppDispatch }>(
  'project/copyProject',
  async (copyProject: CopyProjectDto, thunkAPI) => {
    const saveResponse = await api.post<number>(`${env.apiUrl}/projects/copy`, copyProject);
    thunkAPI.dispatch(
      notificationActions.showNotificationSnackbar({
        severity: SeverityType.success,
        message: `${copyProject.copyName} has been created.`,
        autoHide: true,
      })
    );

    return saveResponse.data;
  }
);

export const deleteProject = createAsyncThunk<void, number, { dispatch: AppDispatch }>(
  'project/deleteProject',
  async (projectId: number, thunkAPI) => {
    await api.delete(`${env.apiUrl}/projects/${projectId}`);
    thunkAPI.dispatch(
      notificationActions.showNotificationSnackbar({
        message: 'Project successfully deleted.',
        severity: SeverityType.success,
        autoHide: true,
      })
    );
    return;
  }
);

export const projectListSlice = createSlice({
  name: 'projectList',
  initialState: {
    status: LoadingStatus.Idle,
    values: {} as PagedResult<ProjectSummaryDto>,
  } as ProjectListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectList.pending, (state, _) => {
        state.status = LoadingStatus.Loading;
      })
      .addCase(fetchProjectList.fulfilled, (state, action) => {
        state.values = action.payload;
        state.status = LoadingStatus.Idle;
      })
      .addCase(fetchProjectList.rejected, (state, _) => {
        state.status = LoadingStatus.Failed;
      });
  },
});

export default projectListSlice.reducer;
