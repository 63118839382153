import { generatePath, useMatches, useNavigate } from 'react-router-dom';
import { Params } from '@remix-run/router';
import { ReactNode } from 'react';

export function usePwcNavigate() {
  const navigate = useNavigate();

  return {
    generatePath,
    navigate,
  };
}

export const usePwcMatches = () => {
  return useMatches() as {
    id: string;
    pathname: string;
    params: Params;
    data: unknown;
    handle?: {
      crumb?: { title: () => string | ReactNode };
    };
  }[];
};
