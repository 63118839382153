import { FC } from 'react';
import styles from './drag-area-erf.module.scss';
import bgImage from '@assets/images/table-grid-bg.png';
import { NotificationMessage } from '@app/shared/components/notification-message/NotificationMessage';
import classNames from 'classnames';
import Chevron from '@app/shared/icons/Chevron';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { BuildStructureLegend } from '@app/modules/projects/inputs/capital-structure/tabs/build-structure-erf/legend/BuildStructureLegend';
import { useLocale } from '@app/core/hooks/useLocale';
interface DragAreaProps {
  dragAreaHeightRef?: React.RefObject<HTMLDivElement>;
  children?: JSX.Element;
  notificationMessage?: string;
}

const PARENT_CLASSNAME = 'drag-area';
const DragAreaErf: FC<DragAreaProps> = ({
  children,
  notificationMessage,
  dragAreaHeightRef,
}): JSX.Element => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const isErfProject = buildStructures?.[project.pwermInput.cases[0].capitalStructureId]?.isErf;

  return (
    <div
      className={classNames([
        styles[`${PARENT_CLASSNAME}`],
        isErfProject
          ? styles[`${PARENT_CLASSNAME}__equity-refinancing-area`]
          : styles[`${PARENT_CLASSNAME}__original-area`],
      ])}>
      {isErfProject && <BuildStructureLegend />}
      {notificationMessage && (
        <div className={styles[`${PARENT_CLASSNAME}__notification-message`]}>
          <NotificationMessage>{notificationMessage}</NotificationMessage>
        </div>
      )}
      <div
        className={classNames([
          styles[`${PARENT_CLASSNAME}__legend-y`],
          isErfProject
            ? styles[`${PARENT_CLASSNAME}__legend-y-equity-refinancing`]
            : styles[`${PARENT_CLASSNAME}__legend-y-original`],
        ])}>
        <div className={styles[`${PARENT_CLASSNAME}__legend-y-arrow`]}>
          <Chevron className={styles[`${PARENT_CLASSNAME}__legend-y-arrow-icon`]} />
        </div>
        <div
          className={classNames([
            styles[`${PARENT_CLASSNAME}__legend-y-item`],
            styles[`${PARENT_CLASSNAME}__legend-y-item--top`],
          ])}>
          {l('_LowestSeniority')}
        </div>
        <div
          className={classNames([
            styles[`${PARENT_CLASSNAME}__legend-y-item`],
            styles[`${PARENT_CLASSNAME}__legend-y-item--bottom`],
          ])}>
          {l('_HighestSeniority')}
        </div>
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__axis`]}>
        <div
          className={styles[`${PARENT_CLASSNAME}__grid`]}
          style={{ backgroundImage: `url(${bgImage})` }}
          ref={dragAreaHeightRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DragAreaErf;
