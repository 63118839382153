import { useMemo } from 'react';
import { MAX_PAGE_ITEMS } from '@app/modules/projects/dashboard/widgets/total-equity/constants';
import { getPaginationRange } from '@app/shared/helpers/get-pagination-range';
import { ELLIPSIS } from '@app/shared/constants/ellipsis';

export const usePagination = (
  totalCount: number,
  pageSize: number,
  currentPage: number,
  siblingCount = 2
) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + MAX_PAGE_ITEMS;
    const minimumItemsToShow = MAX_PAGE_ITEMS - 2;
    if (totalPageNumbers >= totalPageCount) {
      return getPaginationRange(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;
    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = minimumItemsToShow + 2 * siblingCount;
      const leftRange = getPaginationRange(1, leftItemCount);

      return [...leftRange, ELLIPSIS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = minimumItemsToShow * siblingCount;
      const rightRange = getPaginationRange(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, ELLIPSIS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = getPaginationRange(leftSiblingIndex + +2, rightSiblingIndex);
      return [firstPageIndex, ELLIPSIS, ...middleRange, ELLIPSIS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};
