import { Card } from '@app/shared/components/card/Card';
import styles from './notification-message.module.scss';
import SvgWarning from '@app/shared/icons/Warning';
import classNames from 'classnames';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { CardVariation } from '@app/shared/components/card/card-enums';
import { iconMapping } from '@app/shared/mui-components/alert/AlertTypes';
import { NotificationSize } from '@app/shared/components/notification-message/notification-enums';

interface NotificationMessageProps extends HTMLAttributes<HTMLElement> {
  type?: 'success' | 'info' | 'warning' | 'error';
  optionalSpacing?: CardVariation;
  hasShadow?: boolean;
  fullWidth?: boolean;
  size?: NotificationSize;
}

const PARENT_CLASSNAME = 'notification-message';

export const NotificationMessage: FC<PropsWithChildren<NotificationMessageProps>> = ({
  type = 'error',
  children,
  fullWidth,
  hasShadow = true,
  optionalSpacing,
  size,
  ...rest
}) => {
  return (
    <Card
      data-testid="notification"
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--${size}`]]: size,
        [styles[`${PARENT_CLASSNAME}--full-width`]]: fullWidth,
      })}
      variation={optionalSpacing ?? CardVariation.SecondarySpacing}
      hasShadow={hasShadow}
      {...rest}>
      <div className={classNames(styles[`${PARENT_CLASSNAME}__container`])}>
        {type && <div className={styles[`${PARENT_CLASSNAME}__icon`]}>{iconMapping[type]}</div>}
        {!type && (
          <SvgWarning
            className={classNames([
              styles[`${PARENT_CLASSNAME}__icon`],
              styles[`${PARENT_CLASSNAME}__icon--${type}`],
            ])}
          />
        )}
      </div>
      {children}
    </Card>
  );
};
