import { FC, Fragment, useEffect, useState } from 'react';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import {
  EquityInstrumentDto,
  EquityRefinancingEventMovementDto,
  InstrumentCoupon,
  InstrumentDefinitionDto,
  InstrumentOwnershipDto,
  OwnershipDto,
} from '@app/shared/models/contracts/project-dto';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import {
  Currency,
  CouponType,
  EquityRefinancingMovementType,
  EventChangedType,
  EventKey,
  InstrumentType,
  OwnerType,
  OwnershipSplitType,
} from '@app/shared/models/contracts/enums/shared-enums';
import * as capitalStructureActions from '@core/store/capital-structure-slice';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import {
  cloneDeep,
  enumKeyByValue,
  enumToOptions,
  getProjectDraftWithRemovedInstrument,
  instrumentOwnersSortFn,
} from '@app/shared/helpers';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { swapMaskForValueDisplay } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import {
  greaterThanIfValidator,
  positiveValueValidator,
  requiredValidator,
  uniqueNarrativePerEquityInstrumentType2,
} from '@core/validations/hook-forms/validators';
import styles from './capital-structure-item-form.module.scss';
import buttonStyles from '@app/shared/components/button/button.module.scss';
import SvgArrowUp from '@app/shared/icons/ArrowUp';
import SvgArrowDown from '@app/shared/icons/ArrowDown';
import Button from '@app/shared/components/button/Button';
import classNames from 'classnames';
import SvgTabArrow from '@app/shared/icons/TabArrow';
import SvgTabArrowActive from '@app/shared/icons/TabArrowActive';
import { Toggle } from '@app/shared/components/toggle/Toggle';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import {
  numericBaseFieldFormattingProps,
  numericBasicFieldFormattingProps,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { setCapitalStructureBuildStructure, uiValuesSlice } from '@app/core/store/ui-values-slice';
import {
  CapitalStructureInstrumentDefinitionDto,
  CapitalStructureRankInstrumentDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import {
  getEventInstrumentsFromBuildStructure,
  getInstrumentMovementTotals,
  getInstrumentOwnerMovement,
} from '@app/core/store/capital-structure-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import SvgTrash from '@app/shared/icons/Trash';
import { calculateBuildStructure } from '@core/store/capital-structure-slice';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';

export type EquityItem = Omit<CapitalStructureRankInstrumentDto, 'ownership'> &
  CapitalStructureInstrumentDefinitionDto & {
    instrumentId: string;
    ownership: OwnershipDto[];
    totalValue?: Nullable<number>;
    totalShares?: Nullable<number>;
    proRataAmount?: Nullable<number>;
    proRataShares?: Nullable<number>;
    currentActiveCoupon?: InstrumentCoupon;
  };

interface CapitalStructureItemFormProps {
  equityItem: EquityItem;
  eventSetId: string;
}

const CapitalStructureItemFormErf: FC<CapitalStructureItemFormProps> = ({
  equityItem,
  eventSetId,
}): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const projectDraft = useAppSelector((state) => state.project.projectDraft);
  const currencyValue = Currency[projectDraft.details.currency];
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const activeSelectedInstrument = useAppSelector((state) => state.uiValues.activeInstrument);
  const COUPON_TYPE_OPTIONS = enumToOptions(CouponType);
  const eventInstruments = getEventInstrumentsFromBuildStructure(
    buildStructures,
    eventSetId,
    activeSelectedInstrument?.eventId ?? ''
  );
  const capitalStructureKey = Object.keys(buildStructures)?.[0];
  const isErfProject = buildStructures?.[capitalStructureKey].isErf;
  const projectOwnershipSplit =
    projectDraft.capitalStructures[capitalStructureKey].events[
      activeSelectedInstrument?.eventId ?? ''
    ]?.movements[activeSelectedInstrument?.instrumentId ?? '']?.ownershipSplit ??
    OwnershipSplitType.ProRata;

  const isInstrumentAddedOnThisEvent =
    (equityItem.changeFlags & EventChangedType.InstrumentAdded) ===
    EventChangedType.InstrumentAdded;

  const isEvent = !(activeSelectedInstrument?.eventId === EventKey.InitialCapitalStructure);
  const ordinaryEquityKey = enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);
  const isOrdinaryEquity = equityItem.type === ordinaryEquityKey;

  const storedActiveTabs = useAppSelector(
    (state) => state.uiValues.userSelections.capitalStructure.buildStructureOwnerTabs
  );
  const indexOfInstitutionItem = Object.keys(OwnerType).findIndex(
    (label) => label === enumKeyByValue(OwnerType, OwnerType.Institution)
  );

  const [isMounted, setIsMounted] = useState(false);
  const [currentEquityId, setCurrentEquityId] = useState<string>(equityItem.instrumentId);
  const [selectedRadio, setSelectedRadio] = useState<OwnershipSplitType>(projectOwnershipSplit);
  const [activeTab, setActiveTab] = useState<number | undefined>(
    storedActiveTabs[currentEquityId] ?? indexOfInstitutionItem
  );

  const capitalStructures = projectDraft.capitalStructures;
  const capitalStructureId = Object.keys(capitalStructures)[0];
  const initialValues = capitalStructures[capitalStructureId].initialValues;
  const instrumentDefinitions = capitalStructures[capitalStructureId].instrumentDefinitions;

  const instrumentNarrativeFieldName = 'instrumentNarrative';
  const focusFieldName = `instrumentNarrative`;
  const formMethods = useForm<any>({
    ...formConfigBase,
    mode: 'onChange',
    defaultValues: {
      ...{ ...equityItem },
    },
  });

  const isProRata = selectedRadio === OwnershipSplitType.ProRata;

  const { trigger, reset, setFocus } = formMethods;

  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      formMethods.handleSubmit(
        formSubmitHandler as SubmitHandler<EquityInstrumentDto>,
        formSubmitHandler as SubmitErrorHandler<EquityInstrumentDto>
      )();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRadio]);

  useEffect(() => {
    if (currentEquityId !== equityItem.instrumentId) {
      setCurrentEquityId(equityItem.instrumentId);
      reset(equityItem);
      setFocus(focusFieldName);
      setActiveTab(storedActiveTabs[equityItem.instrumentId] ?? indexOfInstitutionItem);
    }
    trigger();
  }, [currentEquityId, equityItem, formMethods, trigger]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFocus(focusFieldName);
  }, [formMethods]); // eslint-disable-line react-hooks/exhaustive-deps

  const normalizeRanks = (instrumentDefinitions: Record<string, InstrumentDefinitionDto>) => {
    const instruments = Object.values(instrumentDefinitions);
    const sortedInstruments = instruments.sort((a, b) => a.rank - b.rank);

    // Creating a mapping of old ranks to new ranks
    let currentRank = 1;
    const rankMapping = new Map<number, number>();
    sortedInstruments.forEach((instrument) => {
      if (instrument.type !== ordinaryEquityKey) {
        if (!rankMapping.has(instrument.rank)) {
          rankMapping.set(instrument.rank, currentRank);
          currentRank++;
        }
      }
    });

    // Applying the new ranks to the instruments
    sortedInstruments.forEach((instrument) => {
      if (instrument.type !== ordinaryEquityKey) {
        instrument.rank = rankMapping.get(instrument.rank)!;
      }
    });

    // Creating the result object
    const sortedInstrumentDefinitions = sortedInstruments.reduce((acc, instrument) => {
      const key = Object.keys(instrumentDefinitions).find(
        (k) => instrumentDefinitions[k] === instrument
      );
      if (key) {
        acc[key] = instrument;
      }
      return acc;
    }, {} as Record<string, InstrumentDefinitionDto>);

    return sortedInstrumentDefinitions;
  };

  const removeInstrument = async (instrumentId: string | undefined) => {
    if (instrumentId) {
      await dispatch(
        calculateBuildStructure({
          project: getProjectDraftWithRemovedInstrument({ draft: projectDraft, instrumentId }),
          ignoreErrors: true,
        })
      );

      dispatch(
        uiValuesSlice.actions.setActiveInstrument({
          instrumentId: undefined,
        })
      );
    }
  };

  const submitEventForm = async (rankValue?: number) => {
    if (!activeSelectedInstrument?.eventId || !activeSelectedInstrument?.instrumentId) {
      return null;
    }

    const movements = cloneDeep({
      ...projectDraft.capitalStructures[capitalStructureKey].events[
        activeSelectedInstrument?.eventId
      ].movements,
    }) as Record<string, EquityRefinancingEventMovementDto>;

    // Removing any previous movements in the instrument in case the user wants to cancel them
    // Except when the instrument is added on this event, otherwise it would just vanish from the page.
    if (
      !isInstrumentAddedOnThisEvent &&
      Object.prototype.hasOwnProperty.call(movements, activeSelectedInstrument.instrumentId)
    ) {
      delete movements[activeSelectedInstrument.instrumentId];
    }

    if (isInstrumentAddedOnThisEvent) {
      movements[activeSelectedInstrument.instrumentId].ownerMovements = {};
    }

    const updatedMovements = formMethods
      .getValues()
      .ownership.filter((o: OwnershipDto) => o.amount !== null || o.numberOfShares !== null);

    const formValues = formMethods.getValues();
    const couponValue = formValues.coupon.value;
    const couponStartDate = formValues.coupon.date;
    const couponType = formValues.coupon.type || CouponType.CompoundInterest;
    const cashPayProportion = formValues.coupon.cashPayProportion / 100;

    let updatedInstrumentMovement = movements;

    if (!isInstrumentAddedOnThisEvent) {
      updatedInstrumentMovement = {
        ...movements,
        [activeSelectedInstrument.instrumentId]: {
          ...movements[activeSelectedInstrument.instrumentId],
          ownerMovements: {},
          ownershipSplit: selectedRadio,
          movementType: EquityRefinancingMovementType.ValueAdjustment,
        },
      };
    }

    if (updatedMovements.length > 0 || couponValue != null) {
      const ownerMovements = updatedMovements.reduce(
        (acc: Record<string, InstrumentOwnershipDto>, ownership: OwnershipDto) => {
          return {
            ...acc,
            [ownership.owner]: {
              amount: ownership.amount,
              numberOfShares: ownership.numberOfShares,
            },
          };
        },
        {}
      );

      updatedInstrumentMovement = {
        ...movements,
        [activeSelectedInstrument.instrumentId]: {
          coupon:
            couponValue != null && couponStartDate != null
              ? {
                  value: couponValue / 100,
                  date: couponStartDate,
                  type: couponType,
                  cashPayProportion: cashPayProportion,
                }
              : undefined,
          movementType: isInstrumentAddedOnThisEvent
            ? EquityRefinancingMovementType.NewInstrument
            : EquityRefinancingMovementType.ValueAdjustment,
          ownerMovements,
          ownershipSplit: selectedRadio,
        },
      };
    }

    if (formValues.proRataAmount || formValues.proRataShares) {
      const movement =
        updatedInstrumentMovement[activeSelectedInstrument.instrumentId]?.ownerMovements ?? {};
      updatedInstrumentMovement = {
        ...updatedInstrumentMovement,
        [activeSelectedInstrument.instrumentId]: {
          ...updatedInstrumentMovement[activeSelectedInstrument.instrumentId],
          proRataMovement: {
            amount: formValues.proRataAmount,
            numberOfShares: formValues.proRataShares,
          },
          movementType: isInstrumentAddedOnThisEvent
            ? EquityRefinancingMovementType.NewInstrument
            : EquityRefinancingMovementType.ValueAdjustment,
          ownerMovements: movement,
          ownershipSplit: selectedRadio,
        },
      };
    }

    const updatedProjectDraft = {
      ...projectDraft,
      capitalStructures: {
        ...projectDraft.capitalStructures,
        [capitalStructureKey]: {
          ...projectDraft.capitalStructures[capitalStructureKey],
          events: {
            ...projectDraft.capitalStructures[capitalStructureKey].events,
            [activeSelectedInstrument?.eventId]: {
              ...projectDraft.capitalStructures[capitalStructureKey].events[
                activeSelectedInstrument?.eventId
              ],
              movements: updatedInstrumentMovement,
            },
          },
          instrumentDefinitions: {
            ...projectDraft.capitalStructures[capitalStructureKey].instrumentDefinitions,
            [activeSelectedInstrument?.instrumentId]: {
              ...projectDraft.capitalStructures[capitalStructureKey].instrumentDefinitions[
                activeSelectedInstrument.instrumentId
              ],
              isSweetEquity: formMethods.getValues().isSweetEquity ?? equityItem.isSweetEquity,
              rank: rankValue ?? equityItem.rank,
              instrumentNarrative: formMethods.getValues().instrumentNarrative,
            },
          },
        },
      },
    };

    await dispatch(
      capitalStructureActions.calculateBuildStructure({
        project: updatedProjectDraft,
        ignoreErrors: true,
      })
    );
  };

  const submitOriginalStructureForm = async (rankValue?: number) => {
    const formValues = formMethods.getValues();
    const couponValue = formValues.coupon?.value ?? 0;
    const couponStartDate = formValues.coupon?.date ?? projectDraft.investmentDate;
    const updatedInitialValues = {
      ...initialValues,
      [equityItem.instrumentId]: {
        ...initialValues[equityItem.instrumentId],
        coupon:
          instrumentDefinitions[equityItem.instrumentId].type !== ordinaryEquityKey
            ? {
                value: couponValue / 100,
                date: couponStartDate,
                type: formValues.coupon?.type,
                cashPayProportion: formValues.coupon?.cashPayProportion / 100,
              }
            : undefined,
        ownership: formMethods
          .getValues()
          .ownership.reduce(
            (acc: Record<string, InstrumentOwnershipDto>, ownership: OwnershipDto) => {
              if (ownership.amount !== null || ownership.numberOfShares !== null) {
                return {
                  ...acc,
                  [ownership.owner]: {
                    amount: ownership.amount,
                    numberOfShares: ownership.numberOfShares,
                  },
                };
              }
              return acc;
            },
            {}
          ),
      },
    };

    const updatedInstrumentDefinitions = {
      ...instrumentDefinitions,
      [equityItem.instrumentId]: {
        ...instrumentDefinitions[equityItem.instrumentId],
        instrumentNarrative: formValues.instrumentNarrative,
        shouldBeValued: formValues.shouldBeValued,
        isSweetEquity: formValues.isSweetEquity,
        rank: rankValue ?? equityItem.rank,
      },
    };

    // Support for PWERM2 and OPM Calculations.
    // Should be removed once we have moved the calculations considering Capital Structure
    const updatedEquityInstruments = projectDraft.equityInstruments.map((instrument) => {
      if (instrument.instrumentId !== equityItem.instrumentId) {
        return instrument;
      }
      return {
        ...instrument,
        instrumentId: instrument.instrumentId,
        rank: instrument.type === ordinaryEquityKey ? 0 : rankValue ?? equityItem.rank,
        instrumentNarrative: formValues.instrumentNarrative,
        couponValue:
          formValues.coupon?.value != null &&
          instrumentDefinitions[equityItem.instrumentId].type !== ordinaryEquityKey
            ? formValues.coupon?.value
            : null,
        couponStartDate:
          instrumentDefinitions[equityItem.instrumentId].type !== ordinaryEquityKey
            ? formValues.coupon?.date
            : null,
        ownership: formValues.ownership.map((o: OwnershipDto) => ({
          owner: o.owner,
          amount: o.amount ? o.amount : null,
          numberOfShares: o.numberOfShares ? o.numberOfShares : null,
        })),
        isSweetEquity:
          InstrumentType[instrument.type] === InstrumentType.OrdinaryEquity
            ? formValues.isSweetEquity
            : null,
        shouldBeValued: formValues.shouldBeValued,
      };
    });

    const updatedProjectDraft = {
      ...projectDraft,
      capitalStructures: {
        ...capitalStructures,
        [capitalStructureId]: {
          ...capitalStructures[capitalStructureId],
          initialValues: updatedInitialValues,
          instrumentDefinitions: updatedInstrumentDefinitions,
        },
      },
      equityInstruments: updatedEquityInstruments,
    };

    const updatedProjectDraftWithNormalizedRanks = {
      ...updatedProjectDraft,
      capitalStructures: {
        ...updatedProjectDraft.capitalStructures,
        [capitalStructureId]: {
          ...updatedProjectDraft.capitalStructures[capitalStructureId],
          instrumentDefinitions: normalizeRanks(cloneDeep(updatedInstrumentDefinitions)),
        },
      },
    };
    await dispatch(
      capitalStructureActions.calculateBuildStructure({
        project: updatedProjectDraftWithNormalizedRanks,
      })
    );
  };

  const submitForm = async (rankValue?: number) => {
    isEvent ? submitEventForm(rankValue) : submitOriginalStructureForm(rankValue);
  };

  const formSubmitHandler = async () => {
    if (!isReadOnly) {
      await submitForm();
    }
  };

  const handleOpenedTab = (index: number) => {
    index !== activeTab ? setActiveTab(index) : setActiveTab(undefined);
    dispatch(setCapitalStructureBuildStructure({ key: currentEquityId, value: index }));
  };

  const getOwnersBySequence = equityItem.ownership
    .slice()
    .sort((ownerA, ownerB) => instrumentOwnersSortFn(ownerA.owner, ownerB.owner));

  const filterRelevantInstruments = () => {
    const filterCondition =
      equityItem.type === ordinaryEquityKey
        ? (i: EquityItem) => i.type === ordinaryEquityKey
        : (i: EquityItem) => i.type !== ordinaryEquityKey;

    return eventInstruments
      .filter((i) => i.instrumentId !== equityItem.instrumentId)
      .filter((i) => filterCondition(i));
  };

  const getNextRank = () => {
    const instrumentGroup = filterRelevantInstruments()
      .map((i) => i.rank)
      .filter((r) => r > equityItem.rank);

    if (instrumentGroup.length < 1) {
      return equityItem.rank + 1;
    }

    return Math.min(...instrumentGroup);
  };

  const getPreviousRank = () => {
    const instrumentGroup = filterRelevantInstruments()
      .map((i) => i.rank)
      .filter((r) => r < equityItem.rank);

    if (instrumentGroup.length < 1) {
      return equityItem.rank - 1;
    }

    return Math.max(...instrumentGroup, 0);
  };

  const canIncreaseRank = () => {
    return (
      eventInstruments.length > 0 &&
      filterRelevantInstruments().some((i) => i.rank >= equityItem.rank)
    );
  };

  const canDecreaseRank = () => {
    return (
      eventInstruments.length > 0 &&
      equityItem.rank > 0 &&
      filterRelevantInstruments().some((i) => i.rank <= equityItem.rank)
    );
  };

  const disableIncreaseButton = !canIncreaseRank();
  const disableDecreaseButton = !canDecreaseRank();
  const nextRankValue = getNextRank();
  const previousRankValue = getPreviousRank();

  const isEventWithoutAddedInstrument = isEvent && !isInstrumentAddedOnThisEvent;
  const isNotEventWithExistingInstrument =
    !isErfProject || !isEvent || (isEvent && isInstrumentAddedOnThisEvent);

  return (
    <>
      <FormProvider {...formMethods}>
        <form
          data-cy="capital-structure-form"
          className={isErfProject ? styles['capital-structure-form__modal'] : ''}
          onBlur={(event) => {
            // Check if the blurred element is not a radio button to avoid double submission
            if (event.target.type !== 'radio') {
              formMethods.handleSubmit(
                formSubmitHandler as SubmitHandler<EquityInstrumentDto>,
                formSubmitHandler as SubmitErrorHandler<EquityInstrumentDto>
              )();
            }
          }}>
          <h2 className="heading-5">
            {isErfProject ? l('_EditInstrument') : l('_InstrumentDetails')}
          </h2>
          <FormField
            name={instrumentNarrativeFieldName}
            label={l('_InstrumentNarrative')}
            required
            rules={{
              ...requiredValidator,
              ...uniqueNarrativePerEquityInstrumentType2(eventInstruments, equityItem),
            }}
            isInputMasked={false}
            inlineLabel={isErfProject}
            fieldValueMask={swapMaskForValueDisplay}
          />
          {!isOrdinaryEquity && isErfProject && (
            <>
              <FormField
                name="coupon.value"
                label={l('_CouponPc')}
                data-testid="instrument-coupon-amount"
                required
                inlineLabel={isErfProject}
                {...{
                  ...percentageFieldFormattingProps,
                  placeholder:
                    isEvent &&
                    equityItem.currentActiveCoupon &&
                    equityItem.currentActiveCoupon.value
                      ? new Intl.NumberFormat('default', {
                          style: 'percent',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(equityItem.currentActiveCoupon.value)
                      : percentageFieldFormattingProps.placeholder,
                }}
                rules={{
                  validate: {
                    isPositive: positiveValueValidator(true).validate,
                  },
                }}
              />
              <FormDatePicker
                name="coupon.date"
                label={l('_CouponStartDate')}
                disabled={isEvent && equityItem.coupon?.value === null}
                required
                data-testid="instrument-coupon-start-date"
                inlineLabel={isErfProject}
                rules={{}}
              />
              <FormSelect
                ariaLabel={l('_SelectorAriaLabel', { label: l('_CouponType') })}
                name="coupon.type"
                disabled={isEvent && equityItem.coupon?.value === null}
                required
                inlineLabel={isErfProject}
                label={l('_CouponType')}
                onChange={() => submitForm()}
                options={COUPON_TYPE_OPTIONS}
                className={styles['control__select']}
              />
              <FormField
                name="coupon.cashPayProportion"
                label={l('_CashPayProportion')}
                disabled={isEvent && equityItem.coupon?.value === null}
                data-testid="instrument-cash-pay-proportion"
                required
                inlineLabel={isErfProject}
                {...percentageFieldFormattingProps}
                rules={{
                  validate: {
                    isPositive: positiveValueValidator(true).validate,
                  },
                }}
              />
            </>
          )}
          <div className={styles['controls-container']}>
            {ordinaryEquityKey !== equityItem.type &&
              (!isEvent || isInstrumentAddedOnThisEvent) && (
                <div className={styles['control']}>
                  <span
                    className={
                      isErfProject ? styles['control__label-erf'] : styles['control__label']
                    }>
                    {l('_Rank')}
                  </span>
                  <div className={styles['control__item']}>
                    <div className={styles['rank']}>
                      <Button
                        appearance={ButtonAppearance.ROUND}
                        onClick={() => submitForm(nextRankValue)}
                        disabled={disableIncreaseButton}
                        endIcon={<SvgArrowUp className={styles['rank__button-icon']} />}
                        data-testid="rank-up"
                      />
                      <Button
                        appearance={ButtonAppearance.ROUND}
                        onClick={() => submitForm(previousRankValue)}
                        disabled={disableDecreaseButton}
                        endIcon={<SvgArrowDown className={styles['rank__button-icon']} />}
                        data-testid="rank-down"
                      />
                    </div>
                  </div>
                </div>
              )}
            {ordinaryEquityKey === formMethods.getValues().type &&
              (!isEvent || isInstrumentAddedOnThisEvent) && (
                <div className={styles['control']}>
                  <span
                    className={
                      isErfProject ? styles['control__label-erf'] : styles['control__label']
                    }>
                    {l('_SweetEquity')}
                  </span>
                  <div className={styles['control__item']}>
                    <Toggle
                      name="isSweetEquity"
                      label={l('_SweetEquity')}
                      isLabelHidden
                      onChange={() => submitForm()}
                    />
                  </div>
                </div>
              )}
            {!isEvent && (
              <div className={styles['control']}>
                <span
                  className={
                    isErfProject ? styles['control__label-erf'] : styles['control__label']
                  }>
                  {l('_ValueInstrument')}
                </span>
                <div className={styles['control__item']}>
                  <Toggle name="shouldBeValued" label={l('_ValueInstrument')} isLabelHidden />
                </div>
              </div>
            )}
          </div>
          {isEventWithoutAddedInstrument && (
            <>
              <div className={classNames(styles['capital-structure-form__title'])}>
                <h2 className="heading-2">{l('_OwnershipSplit')}</h2>
              </div>
              <RadioGroup row key="expandType" name="expandType" value={selectedRadio}>
                <div className={styles['input-radio-section']}>
                  <FormControlLabel
                    value={OwnershipSplitType.ProRata}
                    control={<Radio />}
                    label={l('_ProRataSplit')}
                    onChange={() => setSelectedRadio(OwnershipSplitType.ProRata)}
                  />
                  <FormControlLabel
                    value={OwnershipSplitType.Manual}
                    control={<Radio />}
                    label={l('_ManualEntry')}
                    onChange={() => setSelectedRadio(OwnershipSplitType.Manual)}
                  />
                </div>
              </RadioGroup>
            </>
          )}
          <>
            {isEventWithoutAddedInstrument && (
              <>
                {isOrdinaryEquity && isProRata && (
                  <>
                    <FormValue
                      label={l('_NoOfSharesOutstanding')}
                      value={equityItem.totalShares}
                      inlineLabel={isErfProject}
                      {...numericBasicFieldFormattingProps}
                    />
                    <FormField
                      name="proRataShares"
                      label={l('_AdditionOrBuyBackNoOfShares')}
                      data-testid="instrument-prorata-shares"
                      placeholder="0"
                      required={false}
                      inlineLabel={isErfProject}
                      {...numericBaseFieldFormattingProps}
                    />
                    <FormField
                      name="proRataAmount"
                      label={l('_AdditionOrBuyBackCurrency', { currency: currencyValue })}
                      data-testid="instrument-prorata-amount"
                      placeholder="0"
                      required={false}
                      inlineLabel={isErfProject}
                      {...numericBaseFieldFormattingProps}
                    />
                  </>
                )}

                {isOrdinaryEquity && !isProRata && (
                  <>
                    <FormValue
                      label={l('_NoOfSharesOutstanding')}
                      value={equityItem.totalShares}
                      inlineLabel={isErfProject}
                      {...numericBasicFieldFormattingProps}
                    />
                    <FormValue
                      label={l('_AdditionOrBuyBackNoOfShares')}
                      value={
                        getInstrumentMovementTotals(
                          buildStructures,
                          capitalStructureKey,
                          eventSetId,
                          activeSelectedInstrument!.eventId!,
                          activeSelectedInstrument!.instrumentId!,
                          equityItem.type
                        )?.numberOfShares
                      }
                      inlineLabel={isErfProject}
                      {...numericBasicFieldFormattingProps}
                    />
                    <FormValue
                      label={l('_AdditionOrBuyBackCurrency', { currency: currencyValue })}
                      value={
                        getInstrumentMovementTotals(
                          buildStructures,
                          capitalStructureKey,
                          eventSetId,
                          activeSelectedInstrument!.eventId!,
                          activeSelectedInstrument!.instrumentId!,
                          equityItem.type
                        )?.amount
                      }
                      inlineLabel={isErfProject}
                      {...numericBasicFieldFormattingProps}
                    />
                  </>
                )}

                {!isOrdinaryEquity && isProRata && (
                  <>
                    <FormValue
                      label={l('_AmountOutstanding')}
                      value={equityItem.totalValue}
                      inlineLabel={isErfProject}
                      {...numericBasicFieldFormattingProps}
                    />
                    <FormField
                      name="proRataAmount"
                      label={l('_AdditionOrRedemption')}
                      data-testid="instrument-prorata-amount"
                      placeholder="0"
                      required={false}
                      inlineLabel={isErfProject}
                      {...numericBaseFieldFormattingProps}
                    />
                  </>
                )}

                {!isOrdinaryEquity && !isProRata && (
                  <>
                    <FormValue
                      label={l('_AmountOutstanding')}
                      value={equityItem.totalValue}
                      inlineLabel={isErfProject}
                      {...numericBasicFieldFormattingProps}
                    />
                    <FormValue
                      label={l('_AdditionOrRedemption')}
                      value={
                        getInstrumentMovementTotals(
                          buildStructures,
                          capitalStructureKey,
                          eventSetId,
                          activeSelectedInstrument!.eventId!,
                          activeSelectedInstrument!.instrumentId!,
                          equityItem.type
                        )?.amount
                      }
                      inlineLabel={isErfProject}
                      {...numericBasicFieldFormattingProps}
                    />
                  </>
                )}
              </>
            )}
            {isNotEventWithExistingInstrument && (
              <div className={classNames(styles['capital-structure-form__title'])}>
                <h2 className={!isErfProject ? 'heading-5' : 'heading-2'}>{l('_Owners')}</h2>
              </div>
            )}
            {getOwnersBySequence.map((item, index) => {
              return (
                <Fragment key={`cp-item-${index + 1}`}>
                  {item && (
                    <div key={item.owner}>
                      <div className={classNames(styles['capital-structure-form__tab'])}>
                        <Button
                          data-testid="owner-tab"
                          appearance={ButtonAppearance.ACCORDION_TAB}
                          size={ButtonSize.FULL_WIDTH}
                          ignoreReadOnly
                          className={classNames({
                            [buttonStyles['button--accordion-tab-active']]: activeTab === index + 1,
                            [buttonStyles['button--accordion-tab-non-empty']]:
                              Number(equityItem.ownership[index].amount) > 0 ||
                              Number(equityItem.ownership[index].currentAmount) > 0,
                          })}
                          onClick={() => handleOpenedTab(index + 1)}>
                          {OwnerType[item.owner]}
                          <div className={classNames(styles['capital-structure-form__tab-action'])}>
                            {activeTab === index + 1 ? (
                              <SvgTabArrowActive />
                            ) : Number(equityItem.ownership[index].currentAmount) > 0 ||
                              Number(equityItem.ownership[index].amount) > 0 ? (
                              <div
                                className={classNames(
                                  styles['capital-structure-form__icon-active']
                                )}>
                                <SvgTabArrowActive />
                              </div>
                            ) : (
                              <SvgTabArrow />
                            )}
                          </div>
                        </Button>
                      </div>
                      <div
                        className={classNames(styles['capital-structure-form__tab-content'], {
                          [styles['capital-structure-form__tab-content--open']]:
                            activeTab === index + 1,
                        })}>
                        {isEventWithoutAddedInstrument &&
                          (isOrdinaryEquity ? (
                            <FormValue
                              label={l('_NoOfSharesOutstanding')}
                              value={equityItem.ownership[index].currentNumberOfShares}
                              inlineLabel={isErfProject}
                              {...numericBasicFieldFormattingProps}
                            />
                          ) : (
                            <FormValue
                              label={l('_AmountOutstanding')}
                              value={equityItem.ownership[index].currentAmount}
                              inlineLabel={isErfProject}
                              {...numericBasicFieldFormattingProps}
                            />
                          ))}
                        {isEventWithoutAddedInstrument && isOrdinaryEquity && (
                          <>
                            {isProRata ? (
                              <FormValue
                                label={l('_AdditionOrBuyBackNoOfShares')}
                                value={
                                  getInstrumentOwnerMovement(
                                    buildStructures,
                                    capitalStructureKey,
                                    eventSetId,
                                    activeSelectedInstrument!.eventId!,
                                    activeSelectedInstrument!.instrumentId!,
                                    equityItem.type,
                                    item.owner
                                  )?.numberOfShares
                                }
                                inlineLabel={isErfProject}
                                {...numericBasicFieldFormattingProps}
                              />
                            ) : (
                              <FormField
                                placeholder="0"
                                name={`ownership[${index}].numberOfShares`}
                                {...numericBasicFieldFormattingProps}
                                data-testid="instrument-shares"
                                label={l('_AdditionOrBuyBackNoOfShares')}
                                inlineLabel={isErfProject}
                              />
                            )}
                          </>
                        )}
                        {isEventWithoutAddedInstrument && isProRata ? (
                          <FormValue
                            value={
                              getInstrumentOwnerMovement(
                                buildStructures,
                                capitalStructureKey,
                                eventSetId,
                                activeSelectedInstrument!.eventId!,
                                activeSelectedInstrument!.instrumentId!,
                                equityItem.type,
                                item.owner
                              )?.amount
                            }
                            label={
                              isOrdinaryEquity
                                ? l('_AdditionOrBuyBackCurrency', { currency: currencyValue })
                                : l('_AdditionOrRedemption')
                            }
                            inlineLabel={isErfProject}
                            {...numericBasicFieldFormattingProps}
                          />
                        ) : (
                          <FormField
                            name={`ownership[${index}].amount`}
                            label={
                              isEventWithoutAddedInstrument
                                ? isOrdinaryEquity
                                  ? l('_AdditionOrBuyBackCurrency', { currency: currencyValue })
                                  : l('_AdditionOrRedemption')
                                : l('_AmountInvested')
                            }
                            data-testid="instrument-amount"
                            placeholder="0"
                            inlineLabel={isErfProject}
                            {...numericBaseFieldFormattingProps}
                            rules={
                              isEventWithoutAddedInstrument
                                ? undefined
                                : {
                                    validate: {
                                      isGreaterThanIf: greaterThanIfValidator(
                                        0,
                                        formMethods.getValues() as EquityInstrumentDto,
                                        (i) => i.ownership.every((o) => Number(o.amount ?? 0) <= 0)
                                      ).validate,
                                      isPositive: positiveValueValidator(false).validate,
                                    },
                                  }
                            }
                          />
                        )}
                        {!isEventWithoutAddedInstrument && (
                          <FormField
                            placeholder="0"
                            name={`ownership[${index}].numberOfShares`}
                            {...numericBasicFieldFormattingProps}
                            data-testid="instrument-shares"
                            label={l('_NumberOfShares')}
                            inlineLabel={isErfProject}
                            rules={positiveValueValidator(false)}
                          />
                        )}
                        {isEventWithoutAddedInstrument && !isOrdinaryEquity && !isProRata && (
                          // ! This will be replaced with an input for the closing number once backend is implemented
                          <FormField
                            placeholder="0"
                            name={`ownership[${index}].numberOfShares`}
                            {...numericBasicFieldFormattingProps}
                            data-testid="instrument-shares"
                            label="(Temp) +/- number of shares"
                            inlineLabel={isErfProject}
                          />
                        )}
                        {/* TODO: introduce in the frontend inputs for closing numbers, once backend is implemented */}
                      </div>
                    </div>
                  )}
                </Fragment>
              );
            })}
          </>
          {isErfProject && (
            <Button
              onClick={() => removeInstrument(activeSelectedInstrument?.instrumentId)}
              size={ButtonSize.FULL_WIDTH}
              startIcon={<SvgTrash />}
              appearance={ButtonAppearance.DEFAULT_SECONDARY}>
              {l('_DeleteInstrument')}
            </Button>
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default CapitalStructureItemFormErf;
