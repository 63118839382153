export const mapAsRecord = <TItem, TKey extends string | number | symbol, TValue>(
  items: TItem[],
  mapper: (item: TItem) => TValue,
  keyMapper: (item: TItem) => TKey
): Record<TKey, TValue> => {
  return items.reduce((acc, item) => {
    acc[keyMapper(item)] = mapper(item);
    return acc;
  }, {} as Record<TKey, TValue>);
};
