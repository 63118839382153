import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

const DotsSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon stroke="transparent" viewBox="0 0 12 16" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 13.7996C3.6 14.7937 2.79411 15.5996 1.8 15.5996C0.805887 15.5996 -3.52265e-08 14.7937 -7.86805e-08 13.7996C-1.22135e-07 12.8055 0.805887 11.9996 1.8 11.9996C2.79411 11.9996 3.6 12.8055 3.6 13.7996ZM3.6 7.79961C3.6 8.79372 2.79411 9.59961 1.8 9.59961C0.805887 9.59961 -3.52256e-08 8.79372 -7.86796e-08 7.79961C-1.22134e-07 6.8055 0.805887 5.99961 1.8 5.99961C2.79411 5.99961 3.6 6.8055 3.6 7.79961ZM3.6 1.79961C3.6 2.79372 2.79411 3.59961 1.8 3.59961C0.805887 3.59961 -3.52247e-08 2.79372 -7.86788e-08 1.79961C-1.22133e-07 0.805498 0.805887 -0.000389134 1.8 -0.000389178C2.79411 -0.000389221 3.6 0.805498 3.6 1.79961Z"
        fill="#8A94A6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0004 13.7996C12.0004 14.7937 11.1945 15.5996 10.2004 15.5996C9.2063 15.5996 8.40042 14.7937 8.40042 13.7996C8.40042 12.8055 9.2063 11.9996 10.2004 11.9996C11.1945 11.9996 12.0004 12.8055 12.0004 13.7996ZM12.0004 7.79961C12.0004 8.79372 11.1945 9.59961 10.2004 9.59961C9.20628 9.59961 8.40039 8.79372 8.40039 7.79961C8.40039 6.8055 9.20628 5.99961 10.2004 5.99961C11.1945 5.99961 12.0004 6.8055 12.0004 7.79961ZM12.0004 1.79961C12.0004 2.79372 11.1945 3.59961 10.2004 3.59961C9.20628 3.59961 8.40039 2.79372 8.40039 1.79961C8.40039 0.805498 9.20628 -0.000389134 10.2004 -0.000389178C11.1945 -0.000389221 12.0004 0.805498 12.0004 1.79961Z"
        fill="#8A94A6"
      />
    </SvgIcon>
  );
};

export default memo(DotsSvg);
