import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import {
  SecondaryNavigationItemVariation,
  SecondaryNavigationVariation,
} from '@app/shared/components/secondary-navigation/secondary-navigation-enums';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { RouteConstants } from '../../RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';
import { useAppSelector } from '@app/core/hooks/redux-hooks';

export const OpmWaterfallNavigation = () => {
  const { l } = useLocale();
  const waterfallIterations = useAppSelector((state) => state.uiValues.opmWaterfallIterations ?? 5);

  const navigationItems: NavigationItem[] = [
    {
      label: l('_FirstNLastN', { n: waterfallIterations }),
      route: `../${RouteConstants.FirstLastSimulations}`,
      variation: SecondaryNavigationItemVariation.NoTrim,
    },
    {
      label: l('_SampleWaterfall'),
      route: `../${RouteConstants.SampleWaterfall}`,
      variation: SecondaryNavigationItemVariation.NoTrim,
    },
  ];

  return (
    <SecondaryNavigation
      navigationItems={navigationItems}
      variation={SecondaryNavigationVariation.Scrollable}
    />
  );
};
