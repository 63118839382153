import { MAX_ITEMS_PER_LIST } from '@app/shared/constants/configs/pagination';
import { pageIsOutOfRange } from '@app/shared/helpers/pagination/page-out-of-range';
import { PagedResult } from '@app/shared/models/pagination';
import { SearchParamKey } from '@app/shared/models/search-param-enum';
import { useSearchParams } from 'react-router-dom';

export const usePaginationHelper = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = Number(searchParams.get(SearchParamKey.CurrentPage));

  const checkIfItemsAreOutOfRange = (items: PagedResult<unknown>) => {
    const maxPages = Math.ceil(Number(items.metadata?.total ?? 0) / MAX_ITEMS_PER_LIST);

    const pageOutOfRange = pageIsOutOfRange(
      currentPage,
      Number(items.metadata?.total),
      MAX_ITEMS_PER_LIST
    );

    if (items && (pageOutOfRange || currentPage + 1 > (maxPages ?? 0))) {
      searchParams.set(SearchParamKey.CurrentPage, '0');
      setSearchParams(searchParams);
    }
  };

  const initializePagination = () => {
    if (!currentPage || currentPage === 0) {
      searchParams.set(SearchParamKey.CurrentPage, '0');
      setSearchParams(searchParams);
    }
  };

  const getPagedRequestParams = (items: PagedResult<unknown>) => {
    const pageOutOfRange = pageIsOutOfRange(
      currentPage,
      Number(items.metadata?.total),
      MAX_ITEMS_PER_LIST
    );

    return {
      offset: pageOutOfRange
        ? 0
        : Number(searchParams.get(SearchParamKey.CurrentPage)) * MAX_ITEMS_PER_LIST,
      limit: MAX_ITEMS_PER_LIST,
    };
  };

  const setSearchParamsCustom = (...params: { key: SearchParamKey; value: string | null }[]) => {
    params.forEach(({ key, value }) =>
      value === null ? searchParams.delete(key) : searchParams.set(key, value)
    );
    setSearchParams(searchParams);
  };

  return {
    checkIfItemsAreOutOfRange,
    initializePagination,
    getPagedRequestParams,
    searchParams,
    setSearchParams: setSearchParamsCustom,
  };
};
