import { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.633 7.683a1.5 1.5 0 0 0-.02-2.101l-3.525-3.525a1.5 1.5 0 0 0-2.133.012l-.932.953 5.78 5.523.83-.862Zm-2.918 3.032-9.89 10.28a1.5 1.5 0 0 1-.815.437l-4.422.798a1.5 1.5 0 0 1-1.742-1.743l.802-4.446a1.5 1.5 0 0 1 .404-.782l9.992-10.215 5.671 5.671Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEdit;
