import styles from './breadcrumbs.module.scss';
import PwcNavLink from '@app/shared/routing/PwcNavLink';
import SvgHouse from '@app/shared/icons/House';
import SvgChevron from '@app/shared/icons/Chevron';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { usePwcMatches } from '@core/hooks/routing-hooks';

const PARENT_CLASSNAME = 'breadcrumbs';

export const Breadcrumbs: FC = (): JSX.Element => {
  const matches = usePwcMatches();

  const crumbs = matches
    .filter((match) => Boolean(match.handle?.crumb?.title))
    .map((match) => ({ ...match.handle!.crumb!, pathname: match.pathname }));

  const renderContent = ({
    crumb,
    index,
  }: {
    crumb: { title: () => string | ReactNode };
    index: number;
  }) => (
    <>
      {index === 0 ? (
        <SvgHouse
          className={classNames(styles[`${PARENT_CLASSNAME}__icon`], {
            [styles[`${PARENT_CLASSNAME}__icon--home`]]: index === 0,
          })}
        />
      ) : (
        <SvgChevron
          className={classNames(styles[`${PARENT_CLASSNAME}__icon`], {
            [styles[`${PARENT_CLASSNAME}__icon--secondary`]]: crumbs.length - 1 === index,
          })}
        />
      )}
      <span className={styles[`${PARENT_CLASSNAME}__text`]}>{crumb.title()}</span>
    </>
  );

  return (
    <>
      {crumbs.length - 1 !== 0 && (
        <nav>
          <ul className={styles[PARENT_CLASSNAME]}>
            {crumbs.map((crumb, index) => (
              <li key={index}>
                <PwcNavLink className={styles[`${PARENT_CLASSNAME}__link`]} route={crumb.pathname}>
                  {renderContent({ crumb, index })}
                </PwcNavLink>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  );
};
