import React, { FC } from 'react';
import {
  abbreviatedValueFormatter,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import {
  DenominationMultiplier,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue, sumBy } from '@app/shared/helpers';
import {
  calculatePercentage,
  getTotalOfEquityByInstrumentTypeAndOwnership,
} from '@core/store/project-slice-selectors';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { OpmForecastDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import { useLocale } from '@app/core/hooks/useLocale';

interface OpmInstrumentTooltipProps {
  instrument: EquityInstrumentDto;
  forecastIndex?: number;
  isHovering: boolean;
  forecastData: OpmForecastDto[];
}

const OpmInstrumentTooltip: FC<OpmInstrumentTooltipProps> = ({
  instrument,
  forecastIndex,
  forecastData,
  isHovering,
}): JSX.Element => {
  const { l } = useLocale();
  const equityItems = useAppSelector((state) => state.project.projectDraft.equityInstruments);
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const instrumentDistributions = forecastData[forecastIndex ?? 0].instrumentDistributions;
  const getInstrumentDistribution = (instrumentId: string) =>
    instrumentDistributions.find((ins) => ins.instrumentId === instrumentId)?.distributionAmount ??
    0;
  if (!isHovering) {
    return <></>;
  }

  const filteredEquityItems = equityItems.filter(
    (equityItem) => equityItem.type === instrument.type
  );

  const totalStructureAmount = !Number.isFinite(forecastIndex)
    ? sumBy(
        [instrument].flatMap((x) => x.ownership),
        (x) => x.amount ?? 0
      )
    : getInstrumentDistribution(instrument.instrumentId);

  const returnInstrumentPayoutProportionInRelationOfInstrumentType = (
    instrument: EquityInstrumentDto
  ) => {
    const totalPayoutOfSameTypeInstruments = sumBy(filteredEquityItems, (instrument) =>
      getInstrumentDistribution(instrument.instrumentId)
    );

    const totalInstrumentPayout = getInstrumentDistribution(instrument.instrumentId);

    return (
      (totalPayoutOfSameTypeInstruments
        ? totalInstrumentPayout / totalPayoutOfSameTypeInstruments
        : 0) * 100
    );
  };

  const returnInstrumentAmountByType = (ownersType: OwnerType) => {
    return Number(
      calculatePercentage(
        getTotalOfEquityByInstrumentTypeAndOwnership([instrument]),
        getTotalOfEquityByInstrumentTypeAndOwnership(
          [instrument],
          undefined,
          enumKeyByValue(OwnerType, ownersType)
        )
      ) ?? 0
    );
  };

  const getTotalsByType = (ownerType: OwnerType) => {
    const structureAmount =
      ((totalStructureAmount ?? 0) / 100) * returnInstrumentAmountByType(ownerType);
    return structureAmount === 0
      ? 0
      : abbreviatedValueFormatter({
          value: structureAmount * multiplier,
        });
  };

  const institutionOwnershipTotal = getTotalsByType(OwnerType.Institution);
  const coInvestorOwnershipTotal = getTotalsByType(OwnerType.CoInvestor);
  const managementOwnershipTotal = getTotalsByType(OwnerType.Management);
  const otherOwnershipTotal = getTotalsByType(OwnerType.Other);

  const getPercentageForValueType =
    forecastIndex || forecastIndex === 0
      ? returnInstrumentPayoutProportionInRelationOfInstrumentType(instrument)
      : calculatePercentage(
          getTotalOfEquityByInstrumentTypeAndOwnership(
            equityItems,
            enumKeyByValue(InstrumentType, InstrumentType[instrument.type]),
            undefined,
            'amount'
          ),
          getTotalOfEquityByInstrumentTypeAndOwnership(
            [instrument],
            enumKeyByValue(InstrumentType, InstrumentType[instrument.type]),
            undefined,
            'amount'
          )
        ) ?? 0;

  return (
    <div key={'instrument-tooltip-' + forecastIndex}>
      <h2 className="heading-2 heading-2--alternate">{instrument.instrumentNarrative}</h2>
      <dl className="definition-list">
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>{l('_PercentageOf', { name: InstrumentType[instrument.type] })}</dt>
            <dd>
              {numberValueFormatter({
                value: getPercentageForValueType,
                ...percentageValueFormatterProps,
              })}
            </dd>
          </div>
          {institutionOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_Sponsor')}</dt>
              <dd>{institutionOwnershipTotal}</dd>
            </div>
          )}
          {coInvestorOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_CoInvestor')}</dt>
              <dd>{coInvestorOwnershipTotal}</dd>
            </div>
          )}
        </div>
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>{forecastIndex || forecastIndex === 0 ? 'Total proceeds' : 'Initial Amount'}</dt>
            <dd>
              {abbreviatedValueFormatter({
                value: Number(totalStructureAmount) * multiplier,
              })}
            </dd>
          </div>
          {managementOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_Management')}</dt>
              <dd>{managementOwnershipTotal}</dd>
            </div>
          )}
          {otherOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_Other')}</dt>
              <dd>{otherOwnershipTotal}</dd>
            </div>
          )}
        </div>
      </dl>
    </div>
  );
};

export default React.memo(OpmInstrumentTooltip);
