import styles from '../../opm-summary-waterfall.module.scss';
import { SelectOption } from '@app/shared/models/option';
import { GridSelectField } from '@app/shared/components/grid-controls/grid-select-field/GridSelectField';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { FC } from 'react';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { positiveValueValidator } from '@app/core/validations/hook-forms/validators';
import { numericBaseFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { useLocale } from '@app/core/hooks/useLocale';
import { useAppSelector, useAppDispatch } from '@app/core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import { OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';
import {
  setOpmWaterfallSampleWaterfallSelectedInstrument,
  setOpmWaterfallSampleWaterfallSelectedYear,
} from '@core/store/ui-values-slice';
import { useFormContext } from 'react-hook-form';

interface SampleWaterfallInputsProps {
  instrumentOptions: SelectOption[];
  yearOptions: SelectOption[];
  shouldDisplayOutputs: boolean | undefined;
}

const SampleWaterfallInputs: FC<SampleWaterfallInputsProps> = ({
  instrumentOptions,
  yearOptions,
  shouldDisplayOutputs,
}): JSX.Element => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const opmCalc = useAppSelector((state) => state.opmCalculation);
  const { setValue } = useFormContext();
  const exitValueType =
    opmCalc?.riskFreeValues?.inputs?.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue)
      ? l('_ExitEV')
      : l('_ExitEquity');

  const handleYearSelectChange = (val: string) => {
    dispatch(setOpmWaterfallSampleWaterfallSelectedYear(val));
    setValue('yearSelect', val);
  };

  const handleInstrumentSelectChange = (val: string) => {
    dispatch(setOpmWaterfallSampleWaterfallSelectedInstrument(val));
    setValue('instrumentSelect', val);
  };

  return (
    <>
      <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
        <tr>
          <th colSpan={2} className={styles['section-header']}>
            {l('_Inputs')}
          </th>
        </tr>
        <tr>
          <td>{l('_Instrument')}</td>
          <td>
            <GridSelectField
              aria-label={l('_SelectorAriaLabel', { label: l('_Instrument') })}
              name="instrumentSelect"
              options={instrumentOptions}
              data-testid="instrument-select"
              onChange={(e) => handleInstrumentSelectChange(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>{l('_Year')}</td>
          <td>
            <GridSelectField
              aria-label={l('_SelectorAriaLabel', { label: l('_Year') })}
              name="yearSelect"
              options={yearOptions}
              data-testid="year-select"
              onChange={(e) => handleYearSelectChange(e.target.value)}
            />
          </td>
        </tr>
        <tr>
          <td>{exitValueType}</td>
          <td>
            <GridTextField
              name="enterpriseValue"
              data-testid="enterprise-value"
              alignment={Alignment.Right}
              rules={{ ...positiveValueValidator(true) }}
              {...numericBaseFieldFormattingProps}
              placeholder="0"
            />
          </td>
        </tr>
        {shouldDisplayOutputs && (
          <tr>
            <th colSpan={2} className={styles['section-header']}>
              {l('_Calculations')}
            </th>
          </tr>
        )}
      </thead>
    </>
  );
};

export default SampleWaterfallInputs;
