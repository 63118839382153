import { FC, Fragment } from 'react';
import styles from '@app/modules/projects/inputs/capital-structure/tabs/payout-logic/payout-logic.module.scss';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { OPERATOR_OPTIONS } from '@app/shared/constants/dropdown-values/payout/operator-options';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import FieldRadioGroup from '@app/shared/components/form-controls/field-radio-group/FieldRadioGroup';
import { CONJUNCTION_TYPE_OPTIONS } from '@app/shared/constants/dropdown-values/payout/conjuction-type-options';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { enumKeyByValue, enumToOptions } from '@app/shared/helpers';
import { ConjunctionType, TargetMetric } from '@app/shared/models/contracts/enums/shared-enums';
import { DEFAULT_TRANCHE_CONDITION_TEST_VALUE } from '@app/shared/helpers/create-tranche';
import { ConditionDto, InstrumentDefinitionDto } from '@app/shared/models/contracts/project-dto';
import { MAX_CONDITIONS_PER_STATEMENT } from '@app/shared/constants/payout-logic';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import PlusSvg from '@app/shared/icons/legacy/PlusSvg';
import Button from '@app/shared/components/button/Button';

import {
  greaterThanOrEqualIfValidator,
  positiveValueIfValidator,
  requiredValidator,
} from '@core/validations/hook-forms/validators';
import {
  multipleFieldFormattingPropsSecondary,
  numericBaseFieldFormattingProps,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import MinusIcon from '@app/shared/icons/MinusIcon';
import { useLocale } from '@app/core/hooks/useLocale';

interface PayoutConditionsProps {
  trancheIndex: number;
  formSubmitHandler: () => void;
}

const PARENT_CLASSNAME = 'payout-logic';
const PayoutConditionsErf: FC<PayoutConditionsProps> = ({
  trancheIndex,
  formSubmitHandler,
}): JSX.Element => {
  const { l } = useLocale();
  const { control, setValue, watch, getValues } = useFormContext<InstrumentDefinitionDto>();
  const watchTranches = watch('payoutLogic');
  const watchCondition = watch(`payoutLogic.0.conjunction`);

  const {
    append: appendCondition,
    remove: removeCondition,
    fields: conditionFields,
  } = useFieldArray({
    name: `payoutLogic.${trancheIndex}.conditions`,
    keyName: 'conditionKey',
    control: control,
  });

  const TARGET_METRIC_OPTIONS = enumToOptions(TargetMetric);

  const changeAllTargetMetricValues = (currentConditionIndex: number) => {
    getValues().payoutLogic?.map((_, trancheIndex) => {
      setValue(
        `payoutLogic.${trancheIndex}.conditions.${currentConditionIndex}.targetMetric`,
        getValues(`payoutLogic.0.conditions.${currentConditionIndex}.targetMetric`)
      );
    });
  };

  const handleTargetMetricChange = async (trancheIndex: number, conditionIndex: number) => {
    setValue(
      `payoutLogic.${trancheIndex}.conditions.${conditionIndex}.targetMetricTestValue`,
      DEFAULT_TRANCHE_CONDITION_TEST_VALUE
    );

    changeAllTargetMetricValues(conditionIndex);
  };

  const addStatementCondition = async (
    conditionItem: ConditionDto,
    conjunctionFieldName: `payoutLogic.${number}.conjunction`
  ) => {
    setValue(conjunctionFieldName, enumKeyByValue(ConjunctionType, ConjunctionType.And));
    appendCondition(conditionItem);
    getValues().payoutLogic?.map((trancheField, trancheIndex) => {
      trancheField.conditions.map(() => {
        const conditionField = getValues(`payoutLogic.${trancheIndex}.conditions.0`);
        setValue(
          `payoutLogic.${trancheIndex}.conjunction`,
          enumKeyByValue(ConjunctionType, ConjunctionType.And)
        );
        setValue(`payoutLogic.${trancheIndex}.conditions`, [
          conditionField,
          {
            ...conditionItem,
          },
        ]);
      });
    });
    await formSubmitHandler();
  };

  const getTestValueFieldProps = (trancheIndex: number, conditionIndex: number) => {
    const targetMetric = watchTranches?.[trancheIndex]?.conditions[conditionIndex]?.targetMetric;

    const validationRules = {
      rules: {
        ...requiredValidator,
        ...positiveValueIfValidator(
          true,
          targetMetric,
          (item) => item === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM)
        ),
        ...greaterThanOrEqualIfValidator(
          -100,
          targetMetric,
          (item) => item === enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
        ),
      },
    };

    switch (targetMetric) {
      case enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR):
        return {
          ...validationRules,
          ...percentageFieldFormattingProps,
        };
      case enumKeyByValue(TargetMetric, TargetMetric.InstitutionalMoM):
        return {
          ...validationRules,
          ...multipleFieldFormattingPropsSecondary,
        };
      default:
        return { ...validationRules };
    }
  };

  const removeConditionItem = async (
    conditionItem: number,
    conjunctionFieldName: `payoutLogic.${number}.conjunction`
  ) => {
    setValue(conjunctionFieldName, undefined);
    removeCondition(conditionItem);
    getValues().payoutLogic?.map((_, trancheIndex) => {
      setValue(`payoutLogic.${trancheIndex}.conjunction`, undefined);
      const conditionItem = getValues().payoutLogic?.[trancheIndex].conditions?.[0];
      if (conditionItem) {
        setValue(`payoutLogic.${trancheIndex}.conditions`, [conditionItem]);
      }
    });

    await formSubmitHandler();
  };

  const selectConjunction = async () => {
    getValues().payoutLogic?.map((_, trancheIndex) => {
      setValue(`payoutLogic.${trancheIndex}.conjunction`, getValues(`payoutLogic.0.conjunction`));
    });
    await formSubmitHandler();
  };

  return (
    <>
      {conditionFields.map((conditionItem, conditionIndex) => {
        return (
          <Fragment key={'condition-item' + conditionIndex}>
            <div className={styles[`${PARENT_CLASSNAME}__condition-item`]}>
              <div className={styles[`${PARENT_CLASSNAME}__condition-control-1`]}>
                <FormSelect
                  ariaLabel={l('_SelectorAriaLabel', { label: l('_TargetMetric') })}
                  name={`payoutLogic.${trancheIndex}.conditions.${conditionIndex}.targetMetric`}
                  required
                  disabled={trancheIndex > 0}
                  options={TARGET_METRIC_OPTIONS}
                  onChange={() => handleTargetMetricChange(trancheIndex, conditionIndex)}
                  isGapless
                />
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__condition-control-2`]}>
                <FormSelect
                  ariaLabel={l('_SelectorAriaLabel', { label: l('_Operator') })}
                  name={`payoutLogic.${trancheIndex}.conditions.${conditionIndex}.operator`}
                  required
                  onChange={formSubmitHandler}
                  onBlur={(event) => event.stopPropagation()}
                  options={OPERATOR_OPTIONS}
                  isGapless
                />
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__condition-control-3`]}>
                <FormField
                  {...numericBaseFieldFormattingProps}
                  placeholder="0"
                  name={`payoutLogic.${trancheIndex}.conditions.${conditionIndex}.targetMetricTestValue`}
                  required
                  {...getTestValueFieldProps(trancheIndex, conditionIndex)}
                  isGapless
                />
              </div>
              <div className={styles[`${PARENT_CLASSNAME}__radio-group`]}>
                {conditionFields.length > 1 && conditionIndex === 0 && (
                  <FieldRadioGroup
                    key={conditionItem.conditionKey + watchCondition}
                    options={CONJUNCTION_TYPE_OPTIONS}
                    disabled={trancheIndex > 0}
                    name={`payoutLogic.${trancheIndex}.conjunction`}
                    onChange={selectConjunction}
                  />
                )}
                {trancheIndex === 0 && (
                  <>
                    {conditionFields.length > 1 && conditionIndex > 0 && (
                      <div className={styles[`${PARENT_CLASSNAME}__action-circle`]}>
                        <Button
                          appearance={ButtonAppearance.CLEAN}
                          aria-label={l('_RemoveCondition')}
                          startIcon={<MinusIcon />}
                          onClick={() =>
                            removeConditionItem(
                              conditionIndex,
                              `payoutLogic.${trancheIndex}.conjunction`
                            )
                          }
                        />
                      </div>
                    )}
                    {conditionFields?.length < MAX_CONDITIONS_PER_STATEMENT &&
                      trancheIndex === 0 &&
                      conditionIndex === 0 && (
                        <div className={styles[`${PARENT_CLASSNAME}__action-circle`]}>
                          <Button
                            appearance={ButtonAppearance.DEFAULT_TERTIARY}
                            size={ButtonSize.TABLE_ACTION}
                            data-testid="add-statement"
                            startIcon={<PlusSvg />}
                            onClick={() =>
                              addStatementCondition(
                                conditionItem,
                                `payoutLogic.${trancheIndex}.conjunction`
                              )
                            }
                          />
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};
export default PayoutConditionsErf;
