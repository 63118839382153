import { SVGProps } from 'react';
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 133 50" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M22.235 50H.562v-3.144l4.966-1.254V7.79H0V4.523L13.262.502h3.425V5.03A20.214 20.214 0 0 1 28.208.68c7.385 0 12.748 6.788 12.748 16.139a17.255 17.255 0 0 1-18.652 18.159 52.275 52.275 0 0 1-5.652-.315l.062 10.96 5.521 1.253V50Zm-1.63-42.21a27.01 27.01 0 0 0-3.33.315l-.506.062-.055 22.653c.626.057 1.255.082 1.884.075 6.905 0 10.556-4.322 10.556-12.5 0-6.837-3.035-10.605-8.55-10.605Zm96.53 27.763a16.58 16.58 0 0 1-12.693-4.72 17.121 17.121 0 0 1-4.898-12.425 17.806 17.806 0 0 1 12.15-17.136 17.81 17.81 0 0 1 7.386-.839c7.775 0 13.015 3.487 13.015 8.672a5.596 5.596 0 0 1-6.165 5.645 10.115 10.115 0 0 1-5.397-1.63l.027-9.015c-5.754.952-8.672 5.2-8.672 12.625s3.959 12.5 9.857 12.5a24.74 24.74 0 0 0 10.618-3.513v6.028a32.56 32.56 0 0 1-15.228 3.808Zm-59.876-.438h-8.576V8.03l-6.59-.685V4.269L54.15 1.317h4.59v22.05l13.323-22.05h8.035v25.564l8.419-11.24V2.446A6.466 6.466 0 0 1 93.352.433a5.754 5.754 0 0 1 5.904 5.72c0 3.206-1.91 5.905-6.966 12.248L79.344 35.108h-9.357V14.127L57.232 35.108l.027.007Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgLogo;
