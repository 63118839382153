import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot, Root } from 'react-dom/client';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Provider } from 'react-redux';
import { StrictMode } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import './styles.scss';
import globalTheme from '@styles/theme/global-theme';
import store from '@core/store/store';
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
  ReactPlugin,
} from '@microsoft/applicationinsights-react-js';
import { setAppRoutes } from '@app/Routes';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { GlobalLoader } from '@app/shared/layout/global-loader/GlobalLoader';
import api from '@server/api-config';
import registerApiInterceptor from '@server/register-api-interceptor';

const reactPlugin = new ReactPlugin();
const root: Root = createRoot(document.getElementById('root')!);

// Register Axios interceptors only if there are no registered ones
if (!api.interceptors.response.handlers.length && !api.interceptors.request.handlers.length) {
  registerApiInterceptor(api);
}

const router = createBrowserRouter(setAppRoutes());

root.render(
  <StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={(<></>) as any}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={globalTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Provider store={store}>
                <GlobalLoader />
                <DndProvider backend={HTML5Backend}>
                  <RouterProvider router={router} />
                </DndProvider>
              </Provider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  </StrictMode>
);
