import { SVGProps } from 'react';
const SvgChevronSidebar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}>
    <path
      d="M12.2084 0.644C12.1124 0.548 11.9951 0.5 11.8564 0.5C11.7178 0.5 11.6004 0.548 11.5044 0.644L3.64844 8.5L11.5044 16.356C11.6004 16.452 11.7178 16.5 11.8564 16.5C11.9951 16.5 12.1124 16.452 12.2084 16.356C12.3044 16.26 12.3524 16.1427 12.3524 16.004C12.3524 15.8653 12.3044 15.748 12.2084 15.652L5.05644 8.5L12.2084 1.348C12.3044 1.252 12.3524 1.13467 12.3524 0.995999C12.3524 0.857333 12.3044 0.74 12.2084 0.644Z"
      fill="#D04A02"
    />
  </svg>
);

export default SvgChevronSidebar;
