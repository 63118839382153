import { Components, Theme } from '@mui/material';
import { typographySize } from '@styles/theme/typography';
import palette from '@styles/theme/palette/palette';

const formControlLabel: Components<Theme> = {
  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        marginLeft: '6px',
        fontSize: typographySize.secondary,
        color: palette.text?.text1,
      },
    },
  },
};

export default formControlLabel;
