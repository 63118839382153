import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { enumKeyByValue, instrumentOwnersSortFn, sumBy } from '@app/shared/helpers';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { DealThesisSpacerRow } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/DealThesisSpacerRow';
import classNames from 'classnames';

const SlnAndPrefSharesForecastSummarySection: FC = (): JSX.Element => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedValues = useAppSelector((state) => state.pwermCalculation.values);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId)!;

  const sectionColCount =
    (caseData?.forecasts ? caseData?.forecasts.length : 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const summaries: {
    [key in Exclude<InstrumentType, InstrumentType.OrdinaryEquity>]: {
      [forecastYear: string]: { [key in OwnerType]: number };
    };
  } = {
    [InstrumentType.ShareholderLoanNotes]: {},
    [InstrumentType.PreferredShares]: {},
  };

  const defaultInstrumentValues = {
    [OwnerType.Institution]: 0,
    [OwnerType.Management]: 0,
    [OwnerType.CoInvestor]: 0,
    [OwnerType.Other]: 0,
  };

  calculatedValues.slnAndPrefSharesForecasts?.result[caseData?.caseId] &&
    Object.entries(calculatedValues.slnAndPrefSharesForecasts?.result[caseData?.caseId]).forEach(
      ([instrumentId, values]) => {
        const instrument = project.equityInstruments.find((i) => i.instrumentId === instrumentId);

        if (!instrument) {
          return;
        }

        const instrumentType = InstrumentType[instrument.type as keyof typeof InstrumentType];

        if (
          instrumentType === InstrumentType.ShareholderLoanNotes ||
          instrumentType === InstrumentType.PreferredShares
        ) {
          Object.entries(values).forEach(([forecastYear, data]) => {
            for (const owner of Object.values(OwnerType)) {
              if (!summaries[instrumentType][forecastYear]) {
                summaries[instrumentType][forecastYear] = { ...defaultInstrumentValues };
              }
              summaries[instrumentType][forecastYear][owner] +=
                data.perOwner[enumKeyByValue(OwnerType, owner)];
            }
          });
        }
      }
    );

  const historicalSummaries: {
    [key in Exclude<InstrumentType, InstrumentType.OrdinaryEquity>]: { [key in OwnerType]: number };
  } = {
    [InstrumentType.ShareholderLoanNotes]: { ...defaultInstrumentValues },
    [InstrumentType.PreferredShares]: { ...defaultInstrumentValues },
  };

  project.equityInstruments.forEach((i) => {
    const instrumentType = InstrumentType[i.type as keyof typeof InstrumentType];

    if (instrumentType === InstrumentType.OrdinaryEquity) {
      return;
    }

    for (const ownership of i.ownership) {
      const ownerType = OwnerType[ownership.owner as keyof typeof OwnerType];
      const amount = Number(ownership.amount);

      historicalSummaries[instrumentType][ownerType]
        ? (historicalSummaries[instrumentType][ownerType] += !Number.isNaN(amount) ? amount : 0)
        : (historicalSummaries[instrumentType][ownerType] = !Number.isNaN(amount) ? amount : 0);
    }
  });

  const renderSummarySection = (
    summary: {
      [forecastYear: string]: { [key in OwnerType]: number };
    },
    historicalSummary: { [key in OwnerType]: number },
    title: string
  ): JSX.Element => {
    return (
      <>
        <tbody>
          <tr className="table-primary__row--header">
            <th
              className="table-primary__cell--section-start-separator table-primary__cell--header-secondary"
              colSpan={sectionColCount}>
              {title}
            </th>
          </tr>
          {Object.keys(historicalSummary)
            .map((x) => {
              return x as OwnerType;
            })
            .filter((ownerType) => Boolean(historicalSummary[ownerType]))
            .sort((a, b) =>
              instrumentOwnersSortFn(enumKeyByValue(OwnerType, a), enumKeyByValue(OwnerType, b))
            )
            .map((ownerType, index) => (
              <tr key={ownerType}>
                <th
                  className={classNames('table-primary__cell--vertical-separator', {
                    'table-primary__cell--section-start-separator': index === 0,
                  })}>
                  {ownerType} Total
                </th>
                <td
                  className={classNames({
                    'table-primary__cell--section-start-separator': index === 0,
                  })}>
                  <CellValue {...amountRules} value={historicalSummary[ownerType]} />
                </td>
                {caseData?.forecasts.map((forecast) => (
                  <td
                    key={forecast.forecastId}
                    className={classNames({
                      'table-primary__cell--section-start-separator': index === 0,
                    })}>
                    <CellValue
                      {...amountRules}
                      value={
                        summary[forecast.forecastYear]
                          ? summary[forecast.forecastYear][ownerType]
                          : undefined
                      }
                    />
                  </td>
                ))}
                <td
                  className={classNames('table-primary__cell--vertical-separator', {
                    'table-primary__cell--section-start-separator': index === 0,
                  })}
                />
              </tr>
            ))}

          <tr className="table-primary__row--plain">
            <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
              <strong>{title} Total</strong>
            </th>
            <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator">
              <CellValue
                {...amountRules}
                value={sumBy(Object.values(historicalSummary), (i) => i)}
                strong
              />
            </td>
            {caseData?.forecasts.map((forecast) => (
              <td
                className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator"
                key={forecast.forecastId + 'sln-totals-sum'}>
                <CellValue
                  {...amountRules}
                  value={
                    summary[forecast.forecastYear]
                      ? sumBy(Object.values(summary[forecast.forecastYear]), (i) => i)
                      : undefined
                  }
                  strong
                />
              </td>
            ))}
            <td className="table-primary__cell--section-start-separator table-primary__cell--section-end-separator" />
          </tr>
          <DealThesisSpacerRow />
        </tbody>
      </>
    );
  };

  const showSlnSection = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
  );
  const showPrefSharesSection = project.equityInstruments.some(
    (i) => i.type === enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
  );

  const showShareholderDebtSection = showSlnSection || showPrefSharesSection;

  return (
    <>
      {showShareholderDebtSection && (
        <tr>
          <th
            className="table-primary__cell--header table-primary__cell--header-alternate"
            colSpan={sectionColCount}>
            Summary of Shareholder Debt
          </th>
        </tr>
      )}
      {showSlnSection &&
        renderSummarySection(
          summaries[InstrumentType.ShareholderLoanNotes],
          historicalSummaries[InstrumentType.ShareholderLoanNotes],
          'Shareholder Loan Notes'
        )}
      {showPrefSharesSection &&
        renderSummarySection(
          summaries[InstrumentType.PreferredShares],
          historicalSummaries[InstrumentType.PreferredShares],
          'Preference Shares'
        )}
    </>
  );
};

export default SlnAndPrefSharesForecastSummarySection;
