import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { requiredValidator } from '@app/core/validations/hook-forms/validators';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { numericBaseFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { DealThesisSpacerRow } from '@app/modules/projects/inputs/deal-thesis/deal-thesis-case-table/DealThesisSpacerRow';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { useGetCalculatedProjectValue } from '@app/shared/helpers';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';

const ExitValueDriverSection: FC = (): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  const maxColspanCount =
    (caseData?.forecasts ? caseData?.forecasts.length : 0) +
    DEAL_THESIS_TABLE_HEADER_ADDITIONAL_COL_COUNT;

  const valueDriverName = getPwermValuationValueDriver(project).narrative;
  const valueDriverMetricId = project.pwermInput.valueDriverActiveMetric;

  return (
    <>
      <tbody>
        <tr>
          <th className="table-primary__cell--header" colSpan={maxColspanCount}>
            Exit Value Driver
          </th>
        </tr>
        <tr className="table-primary__row--plain">
          <th className="table-primary__cell--vertical-separator">
            <GridTextField
              name="exitValueDriverLabel"
              placeholder="Exit value driver"
              isInputMasked={false}
              rules={{
                ...requiredValidator,
              }}
            />
          </th>
          <td>
            <GridTextField
              name="historicalExitValueDriver"
              alignment={Alignment.Right}
              {...numericBaseFieldFormattingProps}
              placeholder="0"
            />
          </td>
          {caseData?.forecasts.map((_, index) => (
            <td key={index + 'exit-driver'}>
              <GridTextField
                name={`forecasts.${index}.metricValues.${valueDriverMetricId}`}
                alignment={Alignment.Right}
                {...numericBaseFieldFormattingProps}
                placeholder="0"
                rules={{
                  ...requiredValidator,
                }}
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">{valueDriverName} YoY growth</th>
          <td />
          {caseData?.forecasts.map((forecast, index) => (
            <td key={index + 'exit-driver-yoy-growth'}>
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('ExitValueDriverGrowth', {
                    caseId: caseData.caseId,
                    forecastId: forecast.forecastId,
                  })
                )}
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">{valueDriverName} CAGR</th>
          <td />
          {caseData?.forecasts.map((forecast, index) => (
            <td key={index + 'exit-driver-cagr'}>
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('ExitValueDriverCagr', {
                    caseId: caseData.caseId,
                    forecastId: forecast.forecastId,
                  })
                )}
              />
            </td>
          ))}
          <td />
        </tr>
      </tbody>
      <DealThesisSpacerRow />
    </>
  );
};

export default ExitValueDriverSection;
