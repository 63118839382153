import styles from './valuation-conclusion.module.scss';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';

const PlaceholderValuationInstrument = (): JSX.Element => {
  const dash = '–';
  const testIdPrefix = 'placeholder';

  return (
    <>
      <tr>
        <td
          data-testid={slugifyStrings(testIdPrefix, 'title')}
          className={styles[`opm-instrument-header-placeholder`]}>
          <div className={styles[`opm-valuation-placeholder-spacer`]} />
          <CellValue
            value={dash}
            className={styles[`opm-valuation-placeholder-text`]}
            alignment={Alignment.Left}
          />
        </td>
        <td />
        <td>
          <CellValue
            value={dash}
            alignment={Alignment.Right}
            className={styles[`opm-valuation-placeholder-text`]}
            dataTestId={slugifyStrings(testIdPrefix, 'avg-present-value')}
          />
        </td>
        <td>
          <CellValue
            value={dash}
            alignment={Alignment.Right}
            className={styles[`opm-valuation-placeholder-text`]}
            dataTestId={slugifyStrings(testIdPrefix, 'avg-minority-discounts')}
          />
        </td>
        <td>
          <CellValue
            value={dash}
            alignment={Alignment.Right}
            className={styles[`opm-valuation-placeholder-text`]}
            dataTestId={slugifyStrings(testIdPrefix, 'avg-market-value')}
          />
        </td>
        <td>
          <CellValue
            value={dash}
            alignment={Alignment.Right}
            className={styles[`opm-valuation-placeholder-text`]}
            dataTestId={slugifyStrings(testIdPrefix, 'avg-market-value-per-share')}
          />
        </td>
      </tr>
    </>
  );
};

export default PlaceholderValuationInstrument;
