import { SVGProps } from 'react';
const SvgPrevious = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M.293 7.26c-.39.41-.39 1.074 0 1.483l5.999 6.284a.97.97 0 0 0 1.415 0c.39-.41.39-1.074 0-1.483L2.414 8l5.29-5.544c.39-.41.39-1.073 0-1.483a.97.97 0 0 0-1.415 0l-6 6.284.004.003Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPrevious;
