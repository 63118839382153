import { CaseDto, ForecastDto, ProjectDto } from '@app/shared/models/contracts/project-dto';
import { OpmForecastDto } from '../models/contracts/opm-calculation-results-dto';
import { conditionalConvertNanToZero } from './middlewares';

export const getMultipleProbabilityWeightedValue = ({
  caseItem,
  forecast,
  getValue,
}: {
  caseItem: CaseDto;
  forecast: ForecastDto;
  getValue: (props: {
    caseItem: CaseDto;
    forecast: ForecastDto;
    multipleId: string;
  }) => number | null | undefined;
}) =>
  forecast.multipleProbabilities.reduce((multipleWeightedValue, multipleItem) => {
    const value = getValue({
      caseItem,
      forecast,
      multipleId: multipleItem.multipleId,
    });

    if (value === undefined || value === null || multipleItem.probability === null) {
      return NaN;
    }

    return multipleWeightedValue + (multipleItem.probability / 100) * value;
  }, 0);

export const getForecastProbabilityWeightedValue = ({
  caseItem,
  getValue,
}: {
  caseItem: CaseDto;
  getValue: (props: { caseItem: CaseDto; forecast: ForecastDto }) => number | null | undefined;
}) =>
  caseItem.forecasts.reduce((forecastWeightedValue, forecast) => {
    const value = getValue({
      caseItem,
      forecast,
    });

    if (value === undefined || value === null || forecast.probability === null) {
      return NaN;
    }

    return forecastWeightedValue + (forecast.probability / 100) * value;
  }, 0);

export const getOpmForecastProbabilityWeightedValue = ({
  forecasts,
  getValue,
}: {
  forecasts: OpmForecastDto[];
  getValue: (props: {
    forecasts: OpmForecastDto[];
    forecast: OpmForecastDto;
  }) => number | null | undefined;
}) =>
  forecasts.reduce((forecastWeightedValue, forecast) => {
    const value = getValue({
      forecasts,
      forecast,
    });

    if (value === undefined || value === null || forecast.probability === null) {
      return NaN;
    }

    return forecastWeightedValue + forecast.probability * value;
  }, 0);

export const getCaseProbabilityWeightedValue = ({
  project,
  getValue,
  convertNanToZero = false,
}: {
  project: ProjectDto;
  getValue: (props: { caseItem: CaseDto }) => number | null | undefined;
  convertNanToZero?: boolean;
}) =>
  project.pwermInput.cases.reduce((caseWeightedValue, caseItem) => {
    const value = getValue({
      caseItem,
    });

    if (value === undefined || value === null || caseItem.probability === null) {
      return NaN;
    }

    // replace any NaN values coming from individual cases so the case-weighted average is not affected
    const boundedValue = conditionalConvertNanToZero(convertNanToZero, value);
    return caseWeightedValue + (caseItem.probability / 100) * boundedValue;
  }, 0);
