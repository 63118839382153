/* eslint-disable react/no-unused-prop-types */ // there's some dodgy typing going on here, title is used but via TabTitle which has its own type which matches this by chance. Not sure about name though.
import { ReactElement } from 'react';
import styles from './tabPane.module.scss';

type Props = {
  children: ReactElement | ReactElement[];
  title: string;
  name: string;
};

const TabPane = ({ children }: Props): JSX.Element => (
  <div className={styles.threeColumnsGrid}>{children}</div>
);

export default TabPane;
