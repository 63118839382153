import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { useGetCalculatedProjectValue } from '@app/shared/helpers';
import {
  selectCalculatedVariablesResults,
  selectTimesToExit,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { getWeightedAverageValueForAllCases } from '@app/shared/helpers/get-weighted-value-for-all-cases';
import { getMultipleWeightedTotalEquityProceeds } from '@app/shared/helpers/predefined-variables/get-multiple-weighted-total-equity-proceeds';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';

export const useKeyMetrics = () => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const timesToExit = useAppSelector(selectTimesToExit);

  const caseWithMostForecasts = getCaseWithMostForecasts(project.pwermInput.cases, true);

  const getInstitutionalMoneyMultiple = (
    caseItem: CaseDto,
    forecast: ForecastDto,
    multipleId: string | undefined
  ) => {
    return getCalculatedProjectValue('SponsorMoM', {
      caseId: caseItem.caseId,
      forecastId: forecast.forecastId,
      multipleId: multipleId,
    });
  };

  const getInstitutionalIRR = (
    caseItem: CaseDto,
    forecast: ForecastDto,
    multipleId: string | undefined
  ) => {
    const irr = getCalculatedProjectValue('SponsorIRR', {
      caseId: caseItem.caseId,
      forecastId: forecast.forecastId,
      multipleId: multipleId,
    });

    return irr === undefined ? undefined : irr * 100;
  };

  const getCostOfTotalEquity = (forecast: ForecastDto) => {
    const matchingForecast = caseWithMostForecasts.forecasts.find(
      (f) => f.forecastYear === forecast.forecastYear
    );

    if (!matchingForecast) {
      return undefined;
    }

    return getCalculatedProjectValue('CaseWeightedCostOfEquityCumulative', {
      forecastId: matchingForecast.forecastId,
    });
  };

  const getDiscountFactor = (forecast: ForecastDto) => {
    const costOfTotalEquity = getCostOfTotalEquity(forecast) ?? 0;
    return 1 / Math.pow(1 + costOfTotalEquity, timesToExit[forecast.forecastYear]);
  };

  const totalEquityInvestment = getCalculatedProjectValue('TotalAmountInvested');

  const getWeightedAverageTotalEquityProceeds = (caseItem: CaseDto, forecast: ForecastDto) =>
    getMultipleWeightedTotalEquityProceeds(project, calculatedVariables, caseItem, forecast);

  const getDiscountedProceeds = (caseItem: CaseDto, forecast: ForecastDto) =>
    getMultipleWeightedTotalEquityProceeds(project, calculatedVariables, caseItem, forecast) *
    getDiscountFactor(forecast);

  const getVarianceToCurrentMarketValueInPercent = () => {
    if (!totalEquityInvestment) {
      return undefined;
    }

    const marketValueOfTotalEquityAllCases = getWeightedAverageValueForAllCases(
      (caseItem, forecast) =>
        getWeightedAverageTotalEquityProceeds(caseItem, forecast) * getDiscountFactor(forecast),
      project.pwermInput.cases
    );

    return (marketValueOfTotalEquityAllCases / totalEquityInvestment - 1) * 100;
  };

  return {
    getInstitutionalMoneyMultiple,
    getInstitutionalIRR,
    getDiscountFactor,
    getWeightedAverageTotalEquityProceeds,
    getDiscountedProceeds,
    totalEquityInvestment,
    varianceToCurrentMarketValueInPercent: getVarianceToCurrentMarketValueInPercent(),
  };
};
