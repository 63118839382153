import { FC } from 'react';
import styles from '../../opm-summary-waterfall.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  OpmFirstLastWaterfallDictionaryDto,
  OpmFirstLastWaterfallDto,
  FirstLastSummaryIndexDictionaryDto,
  FirstLastTranche,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import TrancheCalculations from '../tranche-calculations/TrancheCalculations';
import { translationKeys } from '@locale/setupI18n';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import classNames from 'classnames';

type ValueKeys = Omit<FirstLastTranche, 'conditions'>;

interface InstrumentCalculationsProps {
  opmWaterfall: OpmFirstLastWaterfallDictionaryDto;
  selectedInstrument: string;
  selectedYear: string;
  selectedInstrumentViewValue: string;
}

const InstrumentCalculations: FC<InstrumentCalculationsProps> = ({
  opmWaterfall,
  selectedInstrument,
  selectedYear,
  selectedInstrumentViewValue,
}): JSX.Element => {
  const { l } = useLocale();
  const interpolationOption = {
    escapeValue: false,
  };

  const waterfallIterations = useAppSelector((state) => state.uiValues.opmWaterfallIterations ?? 5);
  const titleColSpan = waterfallIterations * 2 + 1;
  const firstSimulation = Object.keys(opmWaterfall[selectedYear].first)[0];

  const instrument =
    opmWaterfall[selectedYear].first[firstSimulation].instruments[selectedInstrument];

  const instrumentHasTranches = instrument.numberOfPayoutLogicTranches > 0;
  const instrumentHasDloc =
    instrument.tranches[Object.keys(instrument.tranches)[0]].dlocPc !== null;

  const createRow = (label: translationKeys, valueKey: keyof ValueKeys, isPercentage = false) => {
    return (
      <tr>
        <td
          className={classNames(styles['border-right'])}
          data-testid={`${valueKey}-instrument-title`}>
          {l(label)}
        </td>
        {(['first', 'last'] as (keyof OpmFirstLastWaterfallDto)[]).map((section, outerIndex) =>
          Object.entries(opmWaterfall[selectedYear][section]).map(([key, _], innerIndex) => {
            const firstTrancheName = Object.keys(
              (opmWaterfall[selectedYear][section] as FirstLastSummaryIndexDictionaryDto)[key]
                .instruments[selectedInstrument].tranches
            )[0];
            const firstTranche = (
              opmWaterfall[selectedYear][section] as FirstLastSummaryIndexDictionaryDto
            )[key].instruments[selectedInstrument].tranches[firstTrancheName];
            const value = firstTranche[valueKey];

            return (
              <td
                key={`${section}-${key}`}
                className={classNames({
                  [styles['border-right']]:
                    outerIndex === 0 && innerIndex + 1 === waterfallIterations,
                })}
                data-testid={`${valueKey}-instrument-simulation-${key}`}>
                <CellValue
                  value={isPercentage && typeof value === 'number' ? value * 100 : value}
                  {...(isPercentage ? enteredPercentageViewRules : amountRules)}
                />
              </td>
            );
          })
        )}
      </tr>
    );
  };

  return (
    <>
      <tr>
        <th
          colSpan={titleColSpan}
          className={styles['section-header']}
          data-testid="instrument-title">
          {selectedInstrumentViewValue}
        </th>
      </tr>
      {instrumentHasTranches ? (
        <TrancheCalculations
          opmWaterfall={opmWaterfall}
          selectedInstrument={selectedInstrument}
          selectedInstrumentViewValue={selectedInstrumentViewValue}
          selectedYear={selectedYear}
        />
      ) : (
        <>
          {createRow('_PcParticipationTitle', 'trancheParticipationPc', true)}
          {createRow('_DistributionsPaid', 'trancheDistribution')}
          {createRow('_PostIPOLockInDiscount', 'postIpoDlomPc', true)}
          {createRow(
            l('_NetProceedsToInstrumentName', {
              instrument: selectedInstrumentViewValue,
              interpolation: interpolationOption,
            }),
            'netDistribution'
          )}
          {createRow('_RiskFreeRate', 'riskFreeRate', true)}
          {createRow(
            l('_PVOf', {
              Instrument: selectedInstrumentViewValue,
              interpolation: interpolationOption,
            }),
            'fairValue'
          )}
          {instrumentHasDloc && createRow('_DLOC', 'dlocPc', true)}
          {createRow('_DLOM', 'dlomPc', true)}
        </>
      )}
      <tr>
        <td
          className={classNames(
            {
              [styles['border-cell-top']]: instrumentHasTranches,
            },
            {
              [styles['bold-text']]: instrumentHasTranches,
            },
            styles['border-right']
          )}
          data-testid="mV-instrument-title">
          {l('_MVOf', {
            Instrument: selectedInstrumentViewValue,
            interpolation: interpolationOption,
          })}
        </td>
        {Object.entries(opmWaterfall[selectedYear].first).map(([key, _], index) => {
          const marketValue = (
            opmWaterfall[selectedYear].first as FirstLastSummaryIndexDictionaryDto
          )[key].instruments[selectedInstrument].discountedFairValue;
          return (
            <td
              key={`first-instrument-${key}`}
              className={classNames(
                { [styles['border-cell-top']]: instrumentHasTranches },
                { [styles['border-right']]: index + 1 === waterfallIterations }
              )}
              data-testid={`mV-instrument-simulation-${key}`}>
              <CellValue value={marketValue} alignment={Alignment.Right} {...amountRules} />
            </td>
          );
        })}
        {Object.entries(opmWaterfall[selectedYear].last).map(([key, _]) => {
          const marketValue = (
            opmWaterfall[selectedYear].last as FirstLastSummaryIndexDictionaryDto
          )[key].instruments[selectedInstrument].discountedFairValue;
          return (
            <td
              key={`last-instrument-${key}`}
              className={instrumentHasTranches ? styles['border-cell-top'] : ''}
              data-testid={`mV-instrument-simulation-${key}`}>
              <CellValue value={marketValue} alignment={Alignment.Right} {...amountRules} />
            </td>
          );
        })}
      </tr>
    </>
  );
};

export default InstrumentCalculations;
