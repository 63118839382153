export const readOnlyGreaterThanValidator = (minimumValue: number, value: number) => {
  return minimumValue < value ? null : `Value must be greater than ${minimumValue}.`;
};

export const readOnlyMinMaxValidator = (minValue: number, maxValue: number, value: number) => {
  return (value >= minValue && value <= maxValue) || !value
    ? null
    : `Field value must be between ${minValue} and ${maxValue}.`;
};

export const readOnlyPercentageValidator = (value: number) => {
  return readOnlyMinMaxValidator(0, 100, value);
};
