import Highcharts from 'highcharts';

Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    numericSymbols: ['K', 'M', 'B', 'T', 'P', 'E'],
  },
});

// replace showResetZoom with an empty function, so we can render our
// own reset zoom button
// eslint-disable-next-line @typescript-eslint/no-empty-function
Highcharts.Chart.prototype.showResetZoom = function () {};
