import React, { useEffect, useState } from 'react';
import { Toggle, ToggleSize } from '../toggle/Toggle';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './expandall.module.scss';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { clearTableCollapseStates, setExpandAllState } from '@app/core/store/ui-values-slice';
import { useDispatch } from 'react-redux';
import { useLocale } from '@app/core/hooks/useLocale';

export enum OverallExpandState {
  True = 'true',
  False = 'false',
  Mixed = 'mixed',
  Other = 'other',
}

enum NumberOfTables {
  Three = 3,
}

const ExpandAllOpm: React.FC = () => {
  const dispatch = useDispatch();
  const expandedTables = useAppSelector((state) => state.uiValues.opmTableCollapseStates);
  const { l } = useLocale();

  const [expandAll, setExpandAll] = useState<OverallExpandState>(OverallExpandState.Other);

  useEffect(() => {
    let result: OverallExpandState;

    if (Object.keys(expandedTables).length < NumberOfTables.Three) {
      result = OverallExpandState.False;
    } else {
      const values = Object.values(expandedTables);
      const allTrue = values.every((val) => val === true);
      const allFalse = values.every((val) => val === false);

      if (allTrue) {
        result = OverallExpandState.True;
      } else if (allFalse) {
        result = OverallExpandState.False;
      } else {
        result = OverallExpandState.Mixed;
      }
    }
    setExpandAll(result);
  }, [expandedTables]);

  useEffect(() => {
    return () => {
      dispatch(clearTableCollapseStates());
    };
  }, [dispatch]);

  const formMethods = useForm({
    ...formConfigBase,
  });

  const handleChange = () => {
    let newState: OverallExpandState;
    switch (expandAll) {
      case OverallExpandState.True:
        newState = OverallExpandState.False;
        break;
      default:
        newState = OverallExpandState.True;
    }
    setExpandAll(newState);
    dispatch(setExpandAllState(newState));
  };

  let forceChecked: boolean | null = null;
  if (expandAll === OverallExpandState.True) {
    forceChecked = true;
  } else if (expandAll === OverallExpandState.False || expandAll === OverallExpandState.Mixed) {
    forceChecked = false;
  }

  return (
    <FormProvider {...formMethods}>
      <Toggle
        name="expandAll"
        label={l('_ExpandAllRows')}
        toggleSize={ToggleSize.Medium}
        className={styles['expand-all']}
        forceChecked={forceChecked}
        onChange={handleChange}
        ignoreReadOnly
        data-testid="expand-all-toggle"
      />
    </FormProvider>
  );
};

export default ExpandAllOpm;
