import { ProjectDto } from '@app/shared/models/contracts/project-dto';

export const setBenchmarkingCalculatedValuesToNull = (project: ProjectDto): ProjectDto => {
  if (!project.benchmarking) {
    return project;
  }

  return {
    ...project,
    benchmarking: {
      ...project.benchmarking,
      sponsorInvestment: {
        ...project.benchmarking.sponsorInvestment,
        percentageOfFixedReturnInstrumentWithinStrip: null,
        couponOnPreferredCapitalInstrumentPercentage: null,
      },
      managementParticipation: {
        ...project.benchmarking.managementParticipation,
        managementStripPercentage: null,
        managementStripTotalSubscriptionCost: null,
      },
      returnsAnalysis: {
        ...project.benchmarking.returnsAnalysis,
        low: {
          ...project.benchmarking.returnsAnalysis.low,
          managementMoMSweetOnly: null,
          managementStripReturn: null,
          totalManagementMom: null,
          envyRatio: null,
          envyRatioSweetOnly: null,
        },
        mid: {
          ...project.benchmarking.returnsAnalysis.mid,
          managementMoMSweetOnly: null,
          managementStripReturn: null,
          totalManagementMom: null,
          envyRatio: null,
          envyRatioSweetOnly: null,
        },
        high: {
          ...project.benchmarking.returnsAnalysis.high,
          managementMoMSweetOnly: null,
          managementStripReturn: null,
          totalManagementMom: null,
          envyRatio: null,
          envyRatioSweetOnly: null,
        },
      },
    },
  };
};

export const isBenchmarkingOnlyProject = (project: ProjectDto): boolean =>
  project.benchmarking?.benchmarkingOnly ?? false;
