// A queue of tasks. Each task is a function that returns a Promise
export const taskQueue: (() => Promise<void>)[] = [];

// A flag to indicate whether the queue is currently being processed
let isProcessingQueue = false;

/*
  Asynchronously processes tasks in the task queue.
 
  This function checks if the queue is currently being processed. If it is, the function returns immediately.
  If the queue is not being processed, the function sets `isProcessingQueue` to `true` and starts processing the tasks.
 
  The function processes tasks in a loop. In each iteration of the loop, it removes the last task from the queue and executes it.
  The queue is then cleared to ensure that only the most recently added task is processed.
  If a task is found, the function waits for it to complete before moving on to the next iteration.
 
  After all tasks have been processed, the function sets `isProcessingQueue` to `false` to indicate that the queue is no longer being processed.
*/
export const processLastInQueue = async () => {
  if (isProcessingQueue) {
    return;
  }
  isProcessingQueue = true;
  while (taskQueue.length > 0) {
    const task = taskQueue.pop(); // Take the last task from the queue
    taskQueue.length = 0; // Clear the queue
    if (task) {
      await task();
    }
  }
  isProcessingQueue = false;
};
