import { forwardRef, InputHTMLAttributes } from 'react';
import styles from './input.module.scss';
import classNames from 'classnames';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { GridFieldVariation } from '@app/shared/components/grid-controls/grid-field-options';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  alignment?: Alignment;
  variation?: GridFieldVariation;
  className?: string;
  suffix?: () => JSX.Element;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { hasError = false, alignment, variation, className, suffix: Suffix, ...rest }: InputProps,
  ref
) {
  const PARENT_CLASSNAME = 'input';

  return (
    <div className={styles[`${PARENT_CLASSNAME}-container`]}>
      <input
        ref={ref}
        {...rest}
        className={classNames(className, styles[PARENT_CLASSNAME], {
          [styles[`${PARENT_CLASSNAME}--has-error`]]: hasError,
          [styles[`${PARENT_CLASSNAME}--has-suffix`]]: Boolean(Suffix),
          [styles[`${PARENT_CLASSNAME}--${alignment}`]]: alignment,
          [styles[`${PARENT_CLASSNAME}--${variation}`]]: variation,
        })}
      />
      {Suffix && (
        <div className={styles[`${PARENT_CLASSNAME}-container__suffix`]}>
          <Suffix />
        </div>
      )}
    </div>
  );
});
