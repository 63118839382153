import styles from './secondary-navigation.module.scss';
import { SecondaryNavigationItem } from '@app/shared/components/secondary-navigation/SecondaryNavigationItem';
import classNames from 'classnames';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { SecondaryNavigationVariation } from '@app/shared/components/secondary-navigation/secondary-navigation-enums';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { setCapitalStructurePayoutLogicTabsHorizontalScrollPosition } from '@app/core/store/ui-values-slice';

interface SecondaryNavigationProps {
  navigationItems: NavigationItem[];
  variation?: SecondaryNavigationVariation;
}

export const SecondaryNavigation = ({ navigationItems, variation }: SecondaryNavigationProps) => {
  const dispatch = useAppDispatch();
  const storedScrollPosition = useAppSelector(
    (state) =>
      state.uiValues.userSelections.capitalStructure.payoutLogic.tabsHorizontalScrollPosition
  );
  const isPayoutLogicSubTabs = variation === SecondaryNavigationVariation.PayoutLogicSubTabs;
  const navigationContainer = useRef<HTMLUListElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollPositionRef = useRef(0);

  useEffect(() => {
    scrollPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  const handleScroll = () => {
    setScrollPosition(navigationContainer.current?.scrollLeft ?? 0);
  };

  useEffect(() => {
    if (storedScrollPosition && isPayoutLogicSubTabs && navigationContainer.current) {
      navigationContainer.current.scrollTo(storedScrollPosition, 0);
    }

    return () => {
      if (isPayoutLogicSubTabs) {
        dispatch(
          setCapitalStructurePayoutLogicTabsHorizontalScrollPosition(
            scrollPositionRef?.current || 0
          )
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul
      ref={navigationContainer}
      data-testid={
        isPayoutLogicSubTabs ? 'secondary-navigation-payout-logic' : 'secondary-navigation'
      }
      className={classNames(styles['secondary-navigation'], {
        [styles[`secondary-navigation--${variation}`]]: variation,
      })}
      onScroll={handleScroll}>
      {navigationItems.map((item, index) => (
        <li key={index} className={styles['secondary-navigation__item-container']}>
          <SecondaryNavigationItem
            route={item.route}
            params={item.params}
            additionalData={item.additionalData}
            variation={item.variation}
            isHighlighted={item.isHighlighted}>
            {item.label}
          </SecondaryNavigationItem>
        </li>
      ))}
    </ul>
  );
};
