import { FC, PropsWithChildren } from 'react';
import { Card } from '@app/shared/components/card/Card';
import classNames from 'classnames';
import styles from './dashboard-widget-container.module.scss';
import { CardCornersVariation } from '@app/shared/components/card/card-enums';

export const DashboardWidgetContainer: FC<
  PropsWithChildren<{
    title: string;
    subtitle?: string;
    isExpanded?: boolean;
    actions?: JSX.Element;
    secondaryContent?: JSX.Element;
    className?: string;
    opm?: boolean;
    isActionVisbile?: boolean;
  }>
> = ({
  title,
  subtitle,
  isExpanded = false,
  actions,
  secondaryContent,
  children,
  className,
  opm = false,
  isActionVisbile = true,
}): JSX.Element => {
  const PARENT_CLASSNAME = opm ? 'widget-opm' : 'widget';
  return (
    <div
      className={classNames(styles[`${PARENT_CLASSNAME}`], className, {
        [styles[`${PARENT_CLASSNAME}--full-width`]]: isExpanded,
      })}>
      <Card
        hasShadow
        cornersVariation={CardCornersVariation.Secondary}
        className={classNames(styles[`${PARENT_CLASSNAME}__card`], className)}>
        <div className={styles[`${PARENT_CLASSNAME}__header-container`]}>
          <div className={styles[`${PARENT_CLASSNAME}__header`]}>
            <div>
              <h3 className={styles[`${PARENT_CLASSNAME}__title`]}>{title}</h3>
              {subtitle && <p className={styles[`${PARENT_CLASSNAME}__subtitle`]}>{subtitle}</p>}
            </div>
            {actions && isActionVisbile && (
              <div className={classNames({ [styles[`${PARENT_CLASSNAME}__opm-controls`]]: opm })}>
                {actions}
              </div>
            )}
          </div>
        </div>
        {secondaryContent && (
          <div className={styles[`${PARENT_CLASSNAME}__secondary-content`]}>{secondaryContent}</div>
        )}
        {children}
      </Card>
    </div>
  );
};
