import { SVGProps } from 'react';
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 1a1 1 0 1 0-2 0v6h-6a1 1 0 0 0 0 2h6v6a1 1 0 1 0 2 0V9h6a1 1 0 1 0 0-2h-6V1Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgPlus;
