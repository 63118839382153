import { FC } from 'react';
import TableSectionValuesPerForecast from '../../../components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getCalculatedValueForForecast,
  getCalculatedVariableValue,
} from '@app/core/store/project-slice-selectors';
import TableSectionValuesPerMultiple from '../../../components/TableSectionValuesPerMultiple';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectCalculatedVariablesResults } from '@app/core/store/pwerm-calculation-slice-selectors';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import { enumKeyByValue } from '@app/shared/helpers';
import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';

export const WaterfallNetExitProceedsPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const [isNetExitProceedsOpen, setIsNetExitProceedsOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Net Exit Proceeds"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.NetExitProceeds)}
          isOpen={isNetExitProceedsOpen}
          setIsOpen={setIsNetExitProceedsOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isNetExitProceedsOpen,
        })}>
        <TableSectionValuesPerForecast
          title="Transaction costs"
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              value={getCalculatedValueForForecast(
                project,
                calculatedVariables,
                caseItem.caseId,
                forecast.forecastId,
                'Transaction costs (% of EV)'
              )}
              {...enteredPercentageViewRules}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title="IPO discount"
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              value={getCalculatedValueForForecast(
                project,
                calculatedVariables,
                caseItem.caseId,
                forecast.forecastId,
                'IPO discount (% of EV)'
              )}
              {...enteredPercentageViewRules}
            />
          )}
        />
        <TableSectionValuesPerMultiple
          title="Net exit proceeds"
          renderCell={({ caseItem, forecast, multiple }) => (
            <CellValue
              {...amountRules}
              value={getCalculatedVariableValue(
                project,
                calculatedVariables,
                caseItem.caseId,
                forecast.forecastId,
                multiple,
                'Net Exit Proceeds'
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title="Gross proceeds"
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          highlight
          renderCell={({ forecast, caseItem }) => (
            <CellValue
              value={getCalculatedValueForForecast(
                project,
                calculatedVariables,
                caseItem.caseId,
                forecast.forecastId,
                'Gross proceeds'
              )}
              {...amountRules}
              strong
            />
          )}
        />
      </tbody>
    </>
  );
};

export default WaterfallNetExitProceedsPartialTable;
