import { useAppSelector } from '@app/core/hooks/redux-hooks';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { FC } from 'react';
import {
  greaterThanValidator,
  percentageValidator,
  requiredValidator,
} from '@core/validations/hook-forms/validators';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import {
  CalcMethod,
  OpmInputDataSource,
  OpmSimulatedValue,
} from '@app/shared/models/contracts/enums/shared-enums';
import styles from '../opm-inputs.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { sumBy } from '@app/shared/helpers';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { EmptyValues } from '@app/shared/constants/empty-values';
import SvgRevert from '@app/shared/icons/Revert';
import { PerYearValuesDto } from '@app/shared/models/contracts/project-dto';
import { useOpmInputsFromPwerm } from '../hooks/useOpmInputsFromPwerm';
import { useFormContext } from 'react-hook-form';
import { OpmInputFormModel } from '../OpmInputs';
import { numberCalculatedViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { calculateYearFrac360 } from '@app/core/utils/year-frac-360';

interface OpmInputTableBodynProps {
  submitData: () => void;
}

type RevertableFields = {
  financingCosts: number;
};

const OpmInputTableBody: FC<OpmInputTableBodynProps> = ({ submitData }): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const opmInput = { ...project.opmInput };
  const { l } = useLocale();
  const shouldGetValuesFromPwerm = project.details.calcMethod !== CalcMethod.OPM;
  const opmPwermValues = useOpmInputsFromPwerm();
  const formMethods = useFormContext<OpmInputFormModel>();

  const exitHorizonProbabilitiesTotal = opmInput
    ? sumBy(opmInput?.perYearInputs, (perYearInput) => Number(perYearInput.exitHorizonProbability))
    : 0;

  const exitHorizonProbabilitiesTotalWarningVal = !Number.isNaN(exitHorizonProbabilitiesTotal)
    ? numberValueFormatter({
        value: exitHorizonProbabilitiesTotal,
        ...percentageValueFormatterProps,
      })
    : EmptyValues.EnDash;

  const exitHorizonProbabilitiesTotalWarningMessage =
    exitHorizonProbabilitiesTotal !== 100
      ? l('_ExitHorizonProbabilitiesTotalWarningVal', {
          value: exitHorizonProbabilitiesTotalWarningVal,
        })
      : undefined;

  const displayRevert = (rowSource: keyof PerYearValuesDto) => {
    if (shouldGetValuesFromPwerm) {
      return opmInput.perYearInputs.some((yi) => yi[rowSource] === OpmInputDataSource.Override);
    }
  };

  const isEquityCalculation = project.opmInput.opmSimulatedValue === OpmSimulatedValue.Equity;

  const handleRevert = (propertyName: keyof RevertableFields) => {
    opmInput.perYearInputs.forEach((yi, index) => {
      const valueToSet = opmPwermValues.getOpmValuesFromPwerm(yi.forecastDate)?.[propertyName];
      formMethods.setValue(`perYearInputs.${index}.${propertyName}`, valueToSet);
    });
    submitData();
  };

  return (
    <>
      <tbody>
        <tr className="table-primary__sticky-section-rows table-primary__sticky-section-rows--opm-input-row">
          <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
            <CellValue
              value={l('_TimeToExit')}
              tooltipVariation={GridFieldTooltipVariation.FitContainer}
            />
          </th>
          <td />
          {opmInput.perYearInputs.map((input, index) => (
            <td
              key={index + 'yearsToExit'}
              className={index === 0 ? styles[`padding-right-index0`] : ''}>
              <CellValue
                value={calculateYearFrac360(
                  new Date(project.valuationDate),
                  new Date(input.forecastDate ?? '')
                )}
                alignment={Alignment.Right}
                {...numberCalculatedViewRules}
                normalize
                dataTestId={`perYearInputs.${index}.yearsToExit`}
              />
            </td>
          ))}
          <td />
        </tr>
        <tr className="table-primary__sticky-section-rows table-primary__sticky-section-rows--additional-sticky-row">
          <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
            <CellValue
              value={l('_ExitProbabilityTitle')}
              tooltipVariation={GridFieldTooltipVariation.FitContainer}
              errorMessage={exitHorizonProbabilitiesTotalWarningMessage}
              data-testid="exit-horizon-probability"
              required
            />
          </th>
          <td />
          {opmInput.perYearInputs.map((_, index) => (
            <td
              key={index + 'exitHorizonProbability'}
              className={index === 0 ? styles[`padding-right-index0`] : ''}>
              <GridTextField
                name={`perYearInputs.${index}.exitHorizonProbability`}
                {...percentageFieldFormattingProps}
                rules={{
                  ...percentageValidator,
                  ...requiredValidator,
                }}
                alignment={Alignment.Right}
                data-testid={`perYearInputs.${index}.exitHorizonProbability`}
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
            {l('_RiskFreeRate')}
            <sup className={styles['required-field']}>*</sup>
          </th>
          <td />
          {opmInput.perYearInputs.map((_, index) => (
            <td
              key={index + 'riskFreeRate'}
              className={index === 0 ? styles[`padding-right-index0`] : ''}>
              <GridTextField
                name={`perYearInputs.${index}.riskFreeRate`}
                {...percentageFieldFormattingProps}
                alignment={Alignment.Right}
                rules={{
                  ...requiredValidator,
                  ...greaterThanValidator(-100),
                }}
              />
            </td>
          ))}
          <td />
        </tr>
        <tr>
          <th
            className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}
            data-testid="header-financingCosts">
            {isEquityCalculation ? l('_DividendYield') : l('_FreeCashFlowYield')}
            {displayRevert('financingCostsSource') && !isEquityCalculation && (
              <SvgRevert
                // stop the onBlur event occurring so we can control when the data is submitted
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => handleRevert('financingCosts')}
                className={styles['revert-button']}
              />
            )}
          </th>
          <td />
          {opmInput.perYearInputs.map((_, index) => (
            <td
              key={index + 'financingCosts'}
              className={index === 0 ? styles[`padding-right-index0`] : ''}>
              <GridTextField
                className={
                  shouldGetValuesFromPwerm &&
                  !isEquityCalculation &&
                  opmInput.perYearInputs[index].financingCostsSource === OpmInputDataSource.Override
                    ? styles['revertable-value']
                    : ''
                }
                name={`perYearInputs.${index}.financingCosts`}
                data-testid={`perYearInputs.${index}.financingCosts`}
                {...percentageFieldFormattingProps}
                rules={{
                  ...requiredValidator,
                }}
                alignment={Alignment.Right}
              />
            </td>
          ))}
          <td />
        </tr>
      </tbody>
    </>
  );
};

export default OpmInputTableBody;
