import axios, { AxiosRequestConfig } from 'axios';

// This is just a base interceptor.
// Will need to adjust this handling once we start using actual api.

// In DEV env - interceptor will always trigger twice because we are using <StrictMode> in main.tsx. This double triggering won't happen in PROD.
// Need to align if we want to keep this <StrictMode> enabled: https://reactjs.org/docs/strict-mode.html

const config: AxiosRequestConfig = {
  baseURL: 'urlName',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
};

const api = axios.create(config);
export default api;
