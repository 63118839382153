import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { MinorityDiscountsDto } from '@app/shared/models/contracts/project-dto';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as projectActions from '@core/store/project-slice';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { Card } from '@app/shared/components/card/Card';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import styles from './minority-discounts.module.scss';
import MinorityDiscountsTable from '@app/modules/projects/inputs/valuation/minority-discounts/minority-discounts-table/MinorityDiscountsTable';
import NoResults from '@app/shared/components/no-results/NoResults';
import { equityInstrumentsSortWithTranchesFn } from '@app/shared/helpers';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { Navigate, generatePath } from 'react-router';
import { useLocale } from '@app/core/hooks/useLocale';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';
import { setValuationInputsSelectedTab } from '@app/core/store/ui-values-slice';

const PARENT_CLASSNAME = 'minority-discounts';

const MinorityDiscounts: FC = (): JSX.Element => {
  useSetSelectedTab(RouteConstants.MinorityDiscounts, setValuationInputsSelectedTab);

  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const filteredInstruments = project.equityInstruments
    .filter((instrument) => instrument.shouldBeValued)
    .sort(equityInstrumentsSortWithTranchesFn);
  const { minorityDiscounts } = project.valuationInputs;

  const formMethods = useForm<MinorityDiscountsDto>({
    defaultValues: {
      dloc: minorityDiscounts.dloc,
      marketVolatility: minorityDiscounts.marketVolatility,
      peerSetVolatility: minorityDiscounts.peerSetVolatility,
    },
  });
  const { handleSubmit, getValues } = formMethods;

  const { l } = useLocale();

  const submitData = async () => {
    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          valuationInputs: {
            ...project.valuationInputs,
            minorityDiscounts: { ...getValues() },
          },
        },
      })
    ).unwrap();
  };

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <>
      <FormProvider {...formMethods}>
        <form onBlur={handleSubmit(submitData, submitData)}>
          <Card className={styles[PARENT_CLASSNAME]}>
            <div className={styles[`${PARENT_CLASSNAME}__row`]}>
              <FormField
                name="dloc"
                label="Control premium for DLOC"
                isGapless
                {...percentageFieldFormattingProps}
              />
            </div>
            <div className={styles[`${PARENT_CLASSNAME}__volatility-header`]}>
              <div className={styles[`${PARENT_CLASSNAME}__volatility-text`]}>
                {l('_Volatility')}
              </div>

              <div className={styles[`${PARENT_CLASSNAME}__row`]}>
                <FormField
                  name="marketVolatility"
                  label={l('_MarketVolatility')}
                  isGapless
                  {...percentageFieldFormattingProps}
                  data-testid="marketVolatility"
                />
                <FormField
                  name="peerSetVolatility"
                  label={l('_PeerSetVolatility')}
                  isGapless
                  {...percentageFieldFormattingProps}
                  data-testid="peerSetVolatility"
                />
              </div>
            </div>
          </Card>
        </form>
      </FormProvider>
      <>
        {filteredInstruments.length ? (
          <div className="main-container-padding-remove">
            <MinorityDiscountsTable />
          </div>
        ) : (
          <NoResults message="Add instruments, and they’ll automatically show here for you to add DLOM information." />
        )}
      </>
    </>
  );
};

export default MinorityDiscounts;
