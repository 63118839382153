import { FC, ReactNode } from 'react';
import { Card } from '@app/shared/components/card/Card';
import styles from '@app/shared/components/status-pages/status-page.module.scss';
import SvgStatusPageIllustration from '@app/shared/icons/StatusPageIllustration';
import { CardCornersVariation } from '@app/shared/components/card/card-enums';

interface StatusPageProps {
  children?: ReactNode;
}

const PARENT_CLASSNAME = 'status-page';
export const StatusPage: FC<StatusPageProps> = ({ children }): JSX.Element => {
  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      <div className={styles[`${PARENT_CLASSNAME}__card-wrapper`]}>
        <Card hasShadow cornersVariation={CardCornersVariation.Primary}>
          {children}
        </Card>
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__illustration`]}>
        <div className={styles[`${PARENT_CLASSNAME}__illustration-inner`]}>
          <SvgStatusPageIllustration />
        </div>
      </div>
    </div>
  );
};
