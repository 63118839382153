import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import { YES_NO_OPTIONS } from '@app/shared/constants/dropdown-values/yes-no-options';
import { VESTING_CONDITION_OPTIONS } from '@app/shared/constants/dropdown-values/vesting-condition-options';
import { ESG_TERMS_OPTIONS } from '@app/shared/constants/dropdown-values/esg-term-options';

const VestingAndLiquidity: FC = (): JSX.Element => {
  return (
    <Card>
      <h3>Vesting &amp; Liquidity</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.vestingCondition"
            label="Vesting Condition"
            options={VESTING_CONDITION_OPTIONS}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.anyLiquidityProvisions"
            label="Any Liquidity Provisions? (Put/call)"
            options={YES_NO_OPTIONS}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.esgTermsAttachedToSweetEquity"
            label="ESG Terms Attached to Sweet Equity?"
            options={YES_NO_OPTIONS}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.doesVestingAccelerateOnExitOrChangeOfControl"
            label="Does Vesting Accelerate on Exit or Change Of Control?"
            options={YES_NO_OPTIONS}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormDatePicker
            name="vestingAndLiquidity.dateOfLiquidityProvisions"
            label="Date of Liquidity Provisions"
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="vestingAndLiquidity.esgTermsInherentOrVestingRelated"
            label="ESG Terms Inherent or Vesting Related?"
            options={ESG_TERMS_OPTIONS}
            isGapless
          />
        </div>
      </div>
    </Card>
  );
};

export default VestingAndLiquidity;
