import { FC, InputHTMLAttributes, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import classNames from 'classnames';
import { useController } from 'react-hook-form';
import {
  MAX_SCALE,
  swapMaskForValueDisplay,
} from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';
import FieldLabel from '@app/shared/components/form-controls/field-label/FieldLabel';
import FieldValidationMessage from '@app/shared/components/form-controls/fidl-validation-message/FieldValidationMessage';
import { FormControlPropsBase } from '../shared-types';
import styles from '../form-field.module.scss';
import { formatUnfocusedInputValue } from '@app/shared/formatters/format-unfocused-input-value';
import { returnFormattedInputValue } from '@app/shared/components/form-controls/field-helpers';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { useSelectOnFocus } from '@app/modules/projects/outputs/custom-hooks/useSelectOnFocus';
import '@app/shared/extensions/string.extensions';
import { handleOnCopy } from '@app/shared/formatters/handleOnCopy';

export interface FormFieldProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'>,
    FormControlPropsBase {
  name: string;
  onChange?: any;
  staticPositionedCell?: boolean;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  inlineLabel?: boolean;
  isModalHeaderField?: boolean;
  prefix?: () => JSX.Element;
  suffix?: () => JSX.Element;
}

export const PARENT_CLASSNAME = 'form-field';
const FormField: FC<FormFieldProps> = ({
  defaultValue,
  rightAlign,
  isInputMasked = true,
  isNormalised,
  shouldTrimOnBlur = false,
  labelIcon,
  rules,
  name,
  label,
  inlineLabel = false,
  isModalHeaderField = false,
  type = 'text',
  fieldValueMask,
  decimalPoint,
  prefix: Prefix,
  suffix: Suffix,
  viewValuePrefix,
  viewValueSuffix,
  staticPositionedCell,
  required,
  valueAsNumber,
  isGapless = false,
  ignoreReadOnly = false,
  disabled = false,
  onBlur,
  onFocus,
  ...rest
}): JSX.Element => {
  const {
    field: { value: fieldValue, onChange: fieldOnChange, ref },
    fieldState: { error },
  } = useController({ name, rules, defaultValue });

  const [inputValue, setInputValue] = useState<string | number>(fieldValue);
  const [isFocused, setFocus] = useState(false);

  const { onBlur: fieldOnBlur, onKeyDown, registerSelectAll } = useSelectOnFocus(isFocused);

  const isReadOnly = useIsReadOnly();

  useEffect(() => {
    setInputValue(fieldValue);
    fieldOnChange(returnFormattedInputValue(fieldValue, valueAsNumber));
  }, [fieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInputValue = (inputValue: string | number) => {
    // Return input value immediately if input masking is not enabled
    if (false || !isInputMasked) {
      return inputValue;
    }

    // Determine if the input value is in scientific notation
    const isScientificNotation =
      typeof inputValue === 'number' && inputValue.toString().includes('e');

    // If input is focused and in scientific notation, format to MAX_SCALE decimal places
    if (isFocused && isScientificNotation) {
      return `${viewValuePrefix ?? ''}${inputValue?.toFixed(MAX_SCALE)}${viewValueSuffix ?? ''}`;
    }

    // If input is focused and not in scientific notation, return the input value as a string
    if (isFocused) {
      return inputValue?.toString();
    }

    if (isInputMasked && inputValue !== null && inputValue?.toString() !== '') {
      // If input is not focused and in scientific notation, format to provided decimal places
      if (isScientificNotation) {
        return `${viewValuePrefix ?? ''}${inputValue?.toFixed(decimalPoint ?? 0)}${
          viewValueSuffix ?? ''
        }`;
        // Handle formatting for unfocused input that is not in scientific notation
      } else {
        return formatUnfocusedInputValue(
          Number(inputValue),
          decimalPoint,
          viewValuePrefix,
          viewValueSuffix,
          isNormalised
        );
      }
    }
  };

  return (
    <>
      <div
        className={classNames(styles[PARENT_CLASSNAME], {
          [styles[`${PARENT_CLASSNAME}--relative`]]: staticPositionedCell,
          [styles[`${PARENT_CLASSNAME}--gapless`]]: isGapless,
          [styles[`${PARENT_CLASSNAME}--inline-label`]]: inlineLabel,
        })}>
        {label && (
          <div className={classNames({ [styles[`${PARENT_CLASSNAME}__label`]]: !inlineLabel })}>
            <FieldLabel
              name={name}
              required={required ?? (rules && 'required' in rules)}
              inlineLabel={inlineLabel}
              isModalHeaderField={isModalHeaderField}>
              {labelIcon}
              {label}
            </FieldLabel>
          </div>
        )}
        <div
          className={classNames(styles[`${PARENT_CLASSNAME}__container`], {
            [styles[`${PARENT_CLASSNAME}__container--has-prefix`]]: Prefix,
            [styles[`${PARENT_CLASSNAME}__container--has-suffix`]]: Suffix,
            [styles[`${PARENT_CLASSNAME}__container--inline`]]: inlineLabel,
          })}>
          {Prefix && (
            <div className={styles[`${PARENT_CLASSNAME}__control-prefix`]}>
              <Prefix />
            </div>
          )}
          {/* @ts-ignore: datepicker vs Imask component, properties which are added are compatible */}
          <IMaskInput
            {...(isFocused ? { ...fieldValueMask } : { ...swapMaskForValueDisplay })}
            type={type}
            onAccept={(value: unknown) => {
              if (isFocused && typeof value === 'string' && value !== '-0') {
                setInputValue(shouldTrimOnBlur ? value : value.trim().trimMiddle());
                fieldOnChange(
                  returnFormattedInputValue(
                    shouldTrimOnBlur ? value : value.trim().trimMiddle(),
                    valueAsNumber
                  )
                );
              }
            }}
            onChange={(value: unknown) => {
              if (isFocused && typeof value === 'string') {
                setInputValue(value);
                fieldOnChange(returnFormattedInputValue(value, valueAsNumber));
              }
            }}
            id={name}
            unmask={Boolean(fieldValueMask)}
            className={classNames(styles[`${PARENT_CLASSNAME}__control`], {
              [styles[`${PARENT_CLASSNAME}__control--right`]]: rightAlign,
              [styles[`${PARENT_CLASSNAME}__control--error`]]: error?.message,
            })}
            onKeyDown={onKeyDown}
            inputRef={(r) => {
              ref(r);
              if (r) {
                registerSelectAll(() => r.select(0, r.value.length));
              }
            }}
            onFocus={(event) => {
              setFocus(true);
              // @ts-ignore
              onFocus && onFocus(event);
            }}
            onBlur={(event) => {
              if (shouldTrimOnBlur) {
                setInputValue(fieldValue.trim());
                fieldOnChange(returnFormattedInputValue(fieldValue.trim(), valueAsNumber));
              }

              setFocus(false);
              fieldOnBlur();
              // @ts-ignore
              onBlur && onBlur(event);
            }}
            onCopy={(event) => handleOnCopy(event, inputValue.toString())}
            {...rest}
            value={handleInputValue(inputValue)}
            disabled={disabled || (!ignoreReadOnly && isReadOnly)}
          />
          {Suffix && (
            <div className={styles[`${PARENT_CLASSNAME}__control-suffix`]}>
              <Suffix />
            </div>
          )}
          {error &&
            (!isModalHeaderField ? (
              <div className={classNames(styles[`${PARENT_CLASSNAME}__validation-message`])}>
                <FieldValidationMessage error={error} />
              </div>
            ) : (
              <div className={styles[`${PARENT_CLASSNAME}__validation-message--inline`]}>
                <FieldValidationMessage error={error} />
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default FormField;
