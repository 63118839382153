import React from 'react';
import styles from '../build-structure-tab/capital-structure.module.scss';
import { StructureDimension } from '@app/shared/models/contracts/enums/shared-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { useLocale } from '@app/core/hooks/useLocale';
import classNames from 'classnames';

interface UnrelatedEventProps {
  eventId: string;
  dragAreaHeight: number;
}

const UnrelatedEventErf: React.FC<UnrelatedEventProps> = ({ eventId, dragAreaHeight }) => {
  const { l } = useLocale();
  const navigate = useNavigate();
  const project = useAppSelector((state) => state.project.projectDraft);
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const relatedEventSetKeys = Object.keys(
    project.capitalStructures[capitalStructureKey].eventSets
  ).filter((key) =>
    project.capitalStructures[capitalStructureKey].eventSets[key].events.some(
      (event) => event === eventId
    )
  );
  const relatedCases = relatedEventSetKeys.flatMap((key) => {
    const caseItems = project.pwermInput.cases.filter((item) => item.eventSetId === key);
    return caseItems.map((c) => {
      return {
        narrative: c?.narrative,
        caseId: c?.caseId,
      };
    });
  });
  const eventName = project.capitalStructures[capitalStructureKey].events[eventId].narrative;
  const eventDate = project.capitalStructures[capitalStructureKey].events[eventId].eventDate;

  const handleCaseClick = (caseId: string | undefined) => {
    if (caseId !== undefined) {
      navigate(`../${caseId}`);
    }
  };

  return (
    <div
      className={classNames(styles['graph__wrapper-erf'], styles['unrelated-event'])}
      style={{
        width: `${StructureDimension.Width}px`,
        flex: `0 0 ${StructureDimension.Width}px`,
      }}>
      <div className={styles['graph__instrument-title']}>{eventName}</div>
      <div className={styles['unrelated-event-text']}>
        {l('_UnrelatedEventsInformation')}
        <ul
          className={styles['case-select-text']}
          style={{ height: `${dragAreaHeight - StructureDimension.ErfFixedHeightElements}px` }}>
          {relatedCases.map((item, index) => (
            <li key={index} onClick={() => handleCaseClick(item.caseId)}>
              {item.narrative}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles['graph__date']}>{eventDate}</div>
    </div>
  );
};

export default UnrelatedEventErf;
