import { OpmValuationConclusionInstrumentResultDto } from '@app/shared/models/contracts/opm-calculation-results-dto';

export const weightedPresentValue = (instrument: OpmValuationConclusionInstrumentResultDto) => {
  let weightedSum = 0;

  for (const year of instrument.years) {
    const presentValue = year.presentValue;
    const exitProbability = year.exitProbability;

    weightedSum += presentValue * exitProbability;
  }

  return weightedSum;
};

export const weightedMinorityDiscounts = (
  instrument: OpmValuationConclusionInstrumentResultDto
) => {
  let weightedSum = 0;

  for (const year of instrument.years) {
    const minorityDiscount = year.minorityDiscounts;
    const exitProbability = year.exitProbability;

    weightedSum += minorityDiscount * exitProbability;
  }

  return convertDecimalToPercentage(weightedSum);
};

export const weightedMarketValue = (instrument: OpmValuationConclusionInstrumentResultDto) => {
  let weightedSum = 0;

  for (const year of instrument.years) {
    const marketValue = year.marketValue;
    const exitProbability = year.exitProbability;

    weightedSum += marketValue * exitProbability;
  }

  return weightedSum;
};

export const weightedMarketValuePerShare = (
  instrument: OpmValuationConclusionInstrumentResultDto
) => {
  let weightedSum = 0;

  for (const year of instrument.years) {
    const marketValuePerShare = year.marketValuePerShare;
    const exitProbability = year.exitProbability;

    weightedSum += marketValuePerShare * exitProbability;
  }

  return weightedSum;
};

export const convertDecimalToPercentage = (value: number): number => value * 100;
