import { FC, SelectHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import FieldValidationMessage from '@app/shared/components/form-controls/fidl-validation-message/FieldValidationMessage';
import { PARENT_CLASSNAME } from '@app/shared/components/form-controls/form-field/FormField';
import FieldLabel from '@app/shared/components/form-controls/field-label/FieldLabel';
import { FormControlPropsBase } from '@app/shared/components/form-controls/shared-types';
import styles from '../form-field.module.scss';
import { SelectOption } from '@app/shared/models/option';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { useLocale } from '@app/core/hooks/useLocale';

interface FormSelectProps extends SelectHTMLAttributes<HTMLSelectElement>, FormControlPropsBase {
  options: SelectOption[];
  name: string;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  ariaLabel?: string;
  inlineLabel?: boolean;
}

export const FormSelect: FC<FormSelectProps> = ({
  name,
  defaultValue,
  onChange,
  label,
  labelIcon,
  options,
  rules,
  required,
  rightAlign,
  isGapless = false,
  ignoreReadOnly = false,
  disabled = false,
  ariaLabel = undefined,
  inlineLabel = false,
  ...rest
}) => {
  const { l } = useLocale();
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, defaultValue });

  const isReadOnly = useIsReadOnly();

  const ariaLabelProps = ariaLabel
    ? { 'aria-label': ariaLabel }
    : label
    ? { 'aria-label': l('_SelectorAriaLabel', { label }) }
    : {};

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--gapless`]]: isGapless,
        [styles[`${PARENT_CLASSNAME}--inline-label`]]: inlineLabel,
      })}>
      {label && (
        <div className={classNames({ [styles[`${PARENT_CLASSNAME}__label`]]: !inlineLabel })}>
          <FieldLabel
            name={name}
            inlineLabel={inlineLabel}
            required={required ?? (rules && 'required' in rules)}>
            {labelIcon}
            {label}
          </FieldLabel>
        </div>
      )}
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__container`], {
          [styles[`${PARENT_CLASSNAME}__container--inline`]]: inlineLabel,
        })}>
        <select
          {...field}
          {...rest}
          {...ariaLabelProps}
          onChange={(event) => {
            const isNumeric = /^-?\d*\.?\d+$/.test(event.target.value);
            if (event.target.value === '') {
              field.onChange(null);
              onChange && onChange(event);
              return;
            }
            isNumeric
              ? field.onChange(Number(event.target.value))
              : field.onChange(event.target.value);
            onChange && onChange(event);
          }}
          value={field.value ?? ''}
          className={classNames(
            styles[`${PARENT_CLASSNAME}__control`],
            styles[`${PARENT_CLASSNAME}__control--select`],
            {
              [styles[`${PARENT_CLASSNAME}__control--right`]]: rightAlign,
              [styles[`${PARENT_CLASSNAME}__control--error`]]: error?.message,
            }
          )}
          disabled={disabled || (!ignoreReadOnly && isReadOnly)}>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.viewValue}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <div className={classNames(styles[`${PARENT_CLASSNAME}__validation-message`])}>
          <FieldValidationMessage error={error} />
        </div>
      )}
    </div>
  );
};
