import { FC } from 'react';
import { StatusPage } from './StatusPage';
import RedirectContent from './status-contents/redirect/RedirectContent';

const RedirectPage: FC = (): JSX.Element => {
  return (
    <StatusPage>
      <RedirectContent />
    </StatusPage>
  );
};

export default RedirectPage;
