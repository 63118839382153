import { FC } from 'react';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { selectCasesStructure } from '@core/store/project-slice-selectors';
import { useAppSelector } from '@core/hooks/redux-hooks';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getCaseSummariesCaseInitial,
  getCaseSummariesCaseYear,
} from '@app/core/store/pwerm2-calculation-slice-selectors';

export const ExitMultipleWeightedEVSection2: FC = (): JSX.Element => {
  const casesStructure = useAppSelector(selectCasesStructure);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  return (
    <>
      <tr>
        <th className="table-primary__cell--header">
          {l('_EnterpriseValue')} [{l('_EV')}]
        </th>
        <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
      </tr>
      <TableSectionValuesPerForecast
        title={l('_ExitMultipleWeightedEv')}
        renderSpacer={false}
        renderCell={({ caseItem, forecast }) => (
          <CellValue
            {...amountRules}
            strong
            value={getCaseSummariesCaseYear(
              results,
              caseItem.caseId,
              forecast.forecastId,
              (year) => year.exitValue
            )}
          />
        )}
        renderAdditionalCell={({ caseItem }) => (
          <CellValue
            {...amountRules}
            value={getCaseSummariesCaseInitial(
              results,
              caseItem.caseId,
              (caseItem) => caseItem.exitValue
            )}
            theme={CellValueTheme.Tertiary}
          />
        )}
        additionalTitleClassNames="table-primary__cell--strong"
      />
      <TableSpacerRow />
    </>
  );
};
