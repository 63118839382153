import { UserSummaryDto } from '@app/shared/models/contracts/user-sumarry-dto';
import { LoadingStatus } from '@app/shared/models/loading-status-enum';
import { uiValuesSlice } from '@core/store/ui-values-slice';
import env from '@environment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@server/api-config';
import { AppDispatch, RootState } from './store';
import * as notificationActions from '@core/store/notification-slice';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';

export interface UserListState {
  status: LoadingStatus;
  values: UserSummaryDto[];
}

export const fetchUserList = createAsyncThunk('userList/fetchUsers', async (_, thunkAPI) => {
  const response = await api.get<UserSummaryDto[]>(`${env.apiUrl}/users`);
  thunkAPI.dispatch(uiValuesSlice.actions.setIsLoggedIn());
  return response.data;
});

export const removeUser = createAsyncThunk<
  void,
  { email: string },
  { state: RootState; dispatch: AppDispatch }
>('userList/remove', async ({ email }, thunkAPI) => {
  await api.delete(`${env.apiUrl}/users/${email}`);

  thunkAPI.dispatch(
    notificationActions.showNotificationSnackbar({
      severity: SeverityType.success,
      message: `User removed from platform successfully.`,
      autoHide: true,
    })
  );
});

export const updateUser = createAsyncThunk<
  void,
  { email: string; role: keyof typeof Role },
  { state: RootState; dispatch: AppDispatch }
>('userList/update', async ({ email, role }, thunkAPI) => {
  await api.put(`${env.apiUrl}/users/${email}`, JSON.stringify({ role: role }));

  thunkAPI.dispatch(
    notificationActions.showNotificationSnackbar({
      severity: SeverityType.success,
      message: `User updated successfully.`,
      autoHide: true,
    })
  );
});

export const userListSlice = createSlice({
  name: 'userList',
  initialState: {
    status: LoadingStatus.Idle,
    values: [],
  } as UserListState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserList.pending, (state, _) => {
        state.status = LoadingStatus.Loading;
      })
      .addCase(fetchUserList.fulfilled, (state, action) => {
        state.values = action.payload;
        state.status = LoadingStatus.Idle;
      })
      .addCase(fetchUserList.rejected, (state, _) => {
        state.status = LoadingStatus.Failed;
      });
  },
});

export default userListSlice.reducer;
