import { ForecastDto, ProjectDto } from '../contracts/project-dto';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPwermValuationValueDriver = (project: ProjectDto) => {
  const activeMetric = project.pwermInput.valueDriverActiveMetric;
  const metricDefinition = project.pwermInput.metricDefinitions[activeMetric];
  const metricValue = project.pwermInput.valuationDateMetrics[activeMetric];
  return { narrative: metricDefinition.narrative, value: metricValue };
};

export const getPwermForecastValueDriver = (project: ProjectDto, forecast: ForecastDto) => {
  const activeMetric = project.pwermInput.valueDriverActiveMetric;
  const metricDefinition = project.pwermInput.metricDefinitions[activeMetric];
  const metricValue = forecast.metricValues[activeMetric];
  return { narrative: metricDefinition.narrative, value: metricValue };
};

export const getPrimaryCapitalStructure = (project: ProjectDto) => {
  const firstId = Object.keys(project.capitalStructures)[0];
  const structure = project.capitalStructures[firstId];
  return { id: firstId, ...structure };
};
