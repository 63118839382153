import { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { Pwerm2WaterfallPartialTableProps } from '../pwerm2-waterfall-shared';
import { enumKeyByValue } from '@app/shared/helpers';
import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import TableSectionValuesPerMultiple from '../../components/TableSectionValuesPerMultiple';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getWaterfallCaseValue,
  getWaterfallCaseYearMultipleValue,
  getWaterfallCaseYearValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';

export const Pwerm2WaterfallEnterpriseValuePartialTable: FC<Pwerm2WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const [isEnterpriseValueEVOpen, setIsEnterpriseValueEVOpen] = useToggler();
  const { l } = useLocale();
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const valuationValueDriver = getPwermValuationValueDriver(project);

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_EnterpriseValueEV')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.EnterpriseValueEV)}
          isOpen={isEnterpriseValueEVOpen}
          setIsOpen={setIsEnterpriseValueEVOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isEnterpriseValueEVOpen,
        })}>
        <TableSectionValuesPerForecast
          renderSpacer={false}
          title={valuationValueDriver.narrative}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.valueDriver
              )}
            />
          )}
          renderAdditionalCell={({ caseItem }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseValue(
                results,
                caseItem.caseId,
                (caseItem) => caseItem.valueDriverInitial
              )}
              theme={CellValueTheme.Tertiary}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_ExitDriverYoYGrowth', { driverName: valuationValueDriver.narrative })}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getWaterfallCaseYearValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  (year) => year.valueDriverGrowth
                ) as Nullable<number>
              )}
            />
          )}
        />
        <TableSectionValuesPerMultiple
          title={l('_ExitEV')}
          renderCell={({ caseItem, forecast, multipleId }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearMultipleValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                multipleId!,
                (multiple) => multiple.exitEv
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          renderSpacer={false}
          title={l('_WeightedEV')}
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          highlight
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.weightedExitEv
              )}
              strong
            />
          )}
          renderAdditionalCell={({ caseItem }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseValue(
                results,
                caseItem.caseId,
                (caseItem) => caseItem.weightedExitEvInitial
              )}
              strong
              theme={CellValueTheme.Tertiary}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_WeightedEVYoYGrowth')}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getWaterfallCaseYearValue(
                  results,
                  caseItem.caseId,
                  forecast.forecastId,
                  (year) => year.weightedExitEvGrowth
                ) as Nullable<number>
              )}
            />
          )}
        />
      </tbody>
    </>
  );
};
