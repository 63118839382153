import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { calculateOpm } from '@app/core/store/opm-calculation-slice';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '../button/button-enums';
import Calculator from '@app/shared/icons/Calculator';
import CalculatorDark from '@app/shared/icons/CalculatorDark';
import styles from './runOpmButton.module.scss';
import loaderStyles from '@app/shared/components/loader/loader.module.scss';
import classNames from 'classnames';
import * as projectActions from '@core/store/project-slice';
import CalculatorInactive from '@app/shared/icons/CalculatorInactive';
import Spinner from '@app/shared/icons/Spinner';

const PARENT_CLASSNAME = 'run-opm-button';

export const RunOpmButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const {
    projectDraft: projectDraft,
    opmNeedsRecalculated: opmNeedsRecalculated,
    isOpmValid: isOpmValid,
  } = useAppSelector((state) => state.project);
  const opmCalcRunning = useAppSelector((state) => state.uiValues.operationStatus.isOpmCalcRunning);
  const handleRunOpmClick = async () => {
    if (opmCalcRunning) {
      return;
    }
    try {
      await dispatch(calculateOpm());
    } finally {
      const opmRequiredProjectInputs = await projectActions.createOpmProjectInputObject(
        projectDraft
      );
      dispatch(
        projectActions.projectSlice.actions.setOriginalOpmProjectState(opmRequiredProjectInputs)
      );
      // Temporarily removed in 28140, to be added back in 28410
      // dispatch(projectActions.projectSlice.actions.updateOpmNeedsRecalculated(false));
    }
  };

  return (
    <Button
      className={
        opmNeedsRecalculated
          ? classNames(styles[`${PARENT_CLASSNAME}__run-opm-button--primary`])
          : classNames(styles[`${PARENT_CLASSNAME}__run-opm-button--secondary`])
      }
      data-testid="run-opm-button"
      appearance={
        !isOpmValid
          ? ButtonAppearance.DEFAULT_INACTIVE
          : opmNeedsRecalculated
          ? ButtonAppearance.DEFAULT_PRIMARY
          : ButtonAppearance.DEFAULT_SECONDARY
      }
      size={ButtonSize.SMALL}
      startIcon={
        opmCalcRunning ? undefined : !isOpmValid ? (
          <CalculatorInactive />
        ) : opmNeedsRecalculated ? (
          <Calculator />
        ) : (
          <CalculatorDark />
        )
      }
      onClick={handleRunOpmClick}
      disabled={!isOpmValid}>
      {opmCalcRunning ? (
        <Spinner className={`${loaderStyles['loader']} ${loaderStyles['loader--size-small']}`} />
      ) : (
        l('_RunOpm')
      )}
    </Button>
  );
};
