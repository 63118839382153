import { FC } from 'react';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import * as notificationActions from '@core/store/notification-slice';
import { useAppDispatch } from '@core/hooks/redux-hooks';
import { usePwcNavigate } from '@core/hooks/routing-hooks';

const NotFoundContent: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { navigate } = usePwcNavigate();

  return (
    <>
      <h1 className="heading-2">That page could not be found</h1>
      <p className="heading-2 heading-2--light">Please try the following options:</p>
      <ul className="list-primary">
        <li>Check the URL you entered to make sure it is correct</li>
        <li>Go back to the previous page</li>
      </ul>
      <p>
        If you can’t get to where you’re trying to go, you can go back to the&#160;
        <Button
          appearance={ButtonAppearance.LINK}
          onClick={() => {
            navigate('/');
            dispatch(notificationActions.closeNotificationModal());
          }}>
          home page
        </Button>
      </p>
    </>
  );
};

export default NotFoundContent;
