import { FC, Fragment, useEffect, useRef } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';
import {
  selectCasesStructure,
  selectSlnAndPrefSharesInstruments,
} from '@core/store/project-slice-selectors';
import { cloneDeep, debtInstrumentsSortFn } from '@app/shared/helpers';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { positiveValueValidator } from '@core/validations/hook-forms/validators';
import { Navigate, generatePath, useParams } from 'react-router-dom';
import { CostOfEquityTableHeader } from '@app/modules/projects/outputs/cost-of-equity/components/CostOfEquityTableHeader';
import { ExitMultipleWeightedEVSection } from '@app/modules/projects/outputs/cost-of-equity/components/ExitMultipleWeightedEVSection';
import { FormProvider, useForm } from 'react-hook-form';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import * as projectActions from '@core/store/project-slice';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';
import { setCostOfEquitySelectedTab } from '@app/core/store/ui-values-slice';
import {
  getInstrumentCaseInitialDebtInstrument,
  getInstrumentCaseYearDebtInstrument,
  getInstrumentCaseYearFirstTranche,
  getInstrumentCaseYearTranche,
  getInstrumentsCaseInitial,
  getInstrumentsCaseYear,
} from '@app/core/store/pwerm2-calculation-slice-selectors';

interface GetRefProp {
  getRef?: (ref: HTMLTableElement) => void;
}

export const InstrumentLevelData2: FC<GetRefProp> = ({ getRef }): JSX.Element => {
  const { instrumentId: currentInstrumentId } = useParams();
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const casesStructure = useAppSelector(selectCasesStructure);
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);

  const currentInstrument = project.equityInstruments.find(
    (instrument) => instrument.instrumentId === currentInstrumentId
  );

  const getDebtInstruments = () => {
    return results.costOfEquity.instruments[currentInstrument!.instrumentId].cases[
      casesStructure[0].caseId
    ].initial.debtInstruments;
  };

  const filteredSlnAndPrefSharesInstruments = Object.entries(getDebtInstruments()).map(
    ([instrumentId, data]) => ({
      instrumentId,
      ...data,
    })
  );

  const isSlnAndPrefSharesInstrument = slnAndPrefSharesInstruments.some(
    (instrument) => instrument.instrumentId === currentInstrumentId
  );

  const columnsCount =
    casesStructure.length + casesStructure.flatMap((caseItem) => caseItem.forecasts).length + 1;

  const formMethods = useForm<{ cases: CaseDto[] }>({
    ...formConfigBase,
    defaultValues: { cases: casesStructure },
  });

  const { handleSubmit, reset, getValues, trigger } = formMethods;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const submitData = async () => {
    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          pwermInput: {
            ...project.pwermInput,
            cases: [...cloneDeep(getValues().cases)],
          },
        },
      })
    ).unwrap();
  };

  useEffect(() => {
    reset({ cases: [...cloneDeep(getValues().cases)] });
  }, [reset, currentInstrumentId]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentInstrumentIdRef = useRef(currentInstrumentId);

  useEffect(() => {
    currentInstrumentIdRef.current = currentInstrumentId;
  }, [currentInstrumentId]);

  useEffect(() => {
    return () => {
      dispatch(setCostOfEquitySelectedTab(currentInstrumentIdRef.current));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  return (
    <div className="main-container-padding-remove">
      <FormProvider {...formMethods}>
        <form onBlur={handleSubmit(submitData, submitData)}>
          <table
            ref={tableRef}
            className="table-primary table-primary--framed table-primary--zebra table-primary--title-column-nowrap table-primary--sticky-first-column">
            <CostOfEquityTableHeader />
            <tbody>
              <ExitMultipleWeightedEVSection />
              <tr>
                <th className="table-primary__cell--header">
                  {l('_IndebtednessRelativeToInstrument')}
                </th>
                <th className="table-primary__cell--header" colSpan={columnsCount - 1} />
              </tr>
              <TableSectionValuesPerForecast
                title={l('_NetDebt')}
                renderSpacer={false}
                renderCell={({ forecast, caseItem }) => (
                  <CellValue
                    {...amountRules}
                    value={getInstrumentsCaseYear(
                      results,
                      currentInstrumentId!,
                      caseItem.caseId,
                      forecast.forecastId,
                      (year) => year.totalNetDebt
                    )}
                  />
                )}
                renderAdditionalCell={({ caseItem }) => (
                  <CellValue
                    {...amountRules}
                    value={getInstrumentsCaseInitial(
                      results,
                      currentInstrumentId!,
                      caseItem.caseId,
                      (year) => year.totalNetDebt
                    )}
                    theme={CellValueTheme.Tertiary}
                  />
                )}
              />
              {filteredSlnAndPrefSharesInstruments.sort(debtInstrumentsSortFn).map((instrument) => (
                <TableSectionValuesPerForecast
                  renderSpacer={false}
                  key={instrument.instrumentId}
                  title={instrument.instrumentNarrative}
                  renderCell={({ caseItem, forecast }) => (
                    <CellValue
                      {...amountRules}
                      value={getInstrumentCaseYearDebtInstrument(
                        results,
                        currentInstrumentId!,
                        caseItem.caseId,
                        forecast.forecastId,
                        instrument.instrumentId,
                        (year) => year.valueCarriedForward
                      )}
                    />
                  )}
                  renderAdditionalCell={({ caseItem }) => (
                    <CellValue
                      {...amountRules}
                      value={getInstrumentCaseInitialDebtInstrument(
                        results,
                        currentInstrumentId!,
                        caseItem.caseId,
                        instrument.instrumentId,
                        (year) => year.valueCarriedForward
                      )}
                      theme={CellValueTheme.Tertiary}
                    />
                  )}
                />
              ))}
              <TableSectionValuesPerForecast
                title={l('_TotalIndebtednessRelativeToInstrument')}
                renderSpacer={false}
                renderCell={({ caseItem, forecast }) => {
                  return (
                    <CellValue
                      {...amountRules}
                      strong
                      value={getInstrumentsCaseYear(
                        results,
                        currentInstrumentId!,
                        caseItem.caseId,
                        forecast.forecastId,
                        (year) => year.totalInstrumentIndebtedness
                      )}
                    />
                  );
                }}
                renderAdditionalCell={({ caseItem }) => (
                  <CellValue
                    {...amountRules}
                    value={getInstrumentsCaseInitial(
                      results,
                      currentInstrumentId!,
                      caseItem.caseId,
                      (year) => year.totalInstrumentIndebtedness
                    )}
                    theme={CellValueTheme.Tertiary}
                  />
                )}
                additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                additionalCellClassNames="table-primary__cell--section-start-separator"
              />
              {((currentInstrument && currentInstrument?.tranches?.length) ?? 0) === 0 && (
                <>
                  <TableSectionValuesPerForecast
                    title={l('_OrdinaryEquityHurdle')}
                    renderSpacer={false}
                    renderCell={() => <CellValue {...amountRules} value={0} />}
                  />
                  <TableSectionValuesPerForecast
                    title={l('_SpotGearing')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getInstrumentCaseYearFirstTranche(
                            results,
                            currentInstrumentId!,
                            caseItem.caseId,
                            forecast.forecastId,
                            (tranche) => tranche.spotGearing
                          )
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title={l('_AverageGearing')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getInstrumentCaseYearFirstTranche(
                            results,
                            currentInstrumentId!,
                            caseItem.caseId,
                            forecast.forecastId,
                            (tranche) => tranche.averageGearing
                          )
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title={l('_DebtBeta')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getInstrumentCaseYearFirstTranche(
                          results,
                          currentInstrumentId!,
                          caseItem.caseId,
                          forecast.forecastId,
                          (tranche) => tranche.debtBeta
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title={l('_EquityBeta')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getInstrumentCaseYearFirstTranche(
                          results,
                          currentInstrumentId!,
                          caseItem.caseId,
                          forecast.forecastId,
                          (tranche) => tranche.equityBeta
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title={l('_CostOfEquitySpot')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getInstrumentCaseYearFirstTranche(
                            results,
                            currentInstrumentId!,
                            caseItem.caseId,
                            forecast.forecastId,
                            (tranche) => tranche.spotCostOfEquity
                          )
                        )}
                      />
                    )}
                    additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                    additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  />
                  <TableSectionValuesPerForecast
                    title={l('_DiscountFactorPoint')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getInstrumentCaseYearFirstTranche(
                          results,
                          currentInstrumentId!,
                          caseItem.caseId,
                          forecast.forecastId,
                          (tranche) => tranche.pointDiscountFactor
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title={l('_DiscountFactorCumulative')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...numberCalculatedViewRules}
                        normalize
                        value={getInstrumentCaseYearFirstTranche(
                          results,
                          currentInstrumentId!,
                          caseItem.caseId,
                          forecast.forecastId,
                          (tranche) => tranche.cumulativeDiscountFactor
                        )}
                      />
                    )}
                  />
                  <TableSectionValuesPerForecast
                    title={l('_CostOfEquityCumulative')}
                    renderSpacer={false}
                    renderCell={({ caseItem, forecast }) => (
                      <CellValue
                        {...enteredPercentageViewRules}
                        value={getCalculatedProjectValueInPercent(
                          getInstrumentCaseYearFirstTranche(
                            results,
                            currentInstrumentId!,
                            caseItem.caseId,
                            forecast.forecastId,
                            (tranche) => tranche.cumulativeCostOfEquity
                          )
                        )}
                      />
                    )}
                    additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                    additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                  />
                </>
              )}
              {(currentInstrument?.tranches?.length ?? 0) > 0 && (
                <>
                  {currentInstrument?.tranches?.map((tranche, index) => (
                    <Fragment key={index}>
                      <tr>
                        <th className="table-primary__cell--header table-primary__cell--header table-primary__cell--header-tertiary">
                          {l('_Tranche')} {index + 1}
                        </th>
                        <th
                          className="table-primary__cell--header table-primary__cell--header table-primary__cell--header-tertiary"
                          colSpan={columnsCount - 1}
                        />
                      </tr>
                      <TableSectionValuesPerForecast
                        title={l('_OrdinaryEquityHurdle')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...amountRules}
                            value={getInstrumentCaseYearTranche(
                              results,
                              currentInstrumentId!,
                              caseItem.caseId,
                              forecast.forecastId,
                              tranche.id,
                              (tranche) => tranche.ordinaryEquityHurdle
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_SpotGearing')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getInstrumentCaseYearTranche(
                                results,
                                currentInstrumentId!,
                                caseItem.caseId,
                                forecast.forecastId,
                                tranche.id,
                                (tranche) => tranche.spotGearing
                              )
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_AverageGearing')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getInstrumentCaseYearTranche(
                                results,
                                currentInstrumentId!,
                                caseItem.caseId,
                                forecast.forecastId,
                                tranche.id,
                                (tranche) => tranche.averageGearing
                              )
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_DebtBeta')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getInstrumentCaseYearTranche(
                              results,
                              currentInstrumentId!,
                              caseItem.caseId,
                              forecast.forecastId,
                              tranche.id,
                              (tranche) => tranche.debtBeta
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_EquityBeta')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getInstrumentCaseYearTranche(
                              results,
                              currentInstrumentId!,
                              caseItem.caseId,
                              forecast.forecastId,
                              tranche.id,
                              (tranche) => tranche.equityBeta
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_CostOfEquitySpot')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getInstrumentCaseYearTranche(
                                results,
                                currentInstrumentId!,
                                caseItem.caseId,
                                forecast.forecastId,
                                tranche.id,
                                (tranche) => tranche.spotCostOfEquity
                              )
                            )}
                          />
                        )}
                        additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      />
                      <TableSectionValuesPerForecast
                        title={l('_DiscountFactorPoint')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getInstrumentCaseYearTranche(
                              results,
                              currentInstrumentId!,
                              caseItem.caseId,
                              forecast.forecastId,
                              tranche.id,
                              (tranche) => tranche.pointDiscountFactor
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_DiscountFactorCumulative')}
                        renderSpacer={false}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...numberCalculatedViewRules}
                            normalize
                            value={getInstrumentCaseYearTranche(
                              results,
                              currentInstrumentId!,
                              caseItem.caseId,
                              forecast.forecastId,
                              tranche.id,
                              (tranche) => tranche.cumulativeDiscountFactor
                            )}
                          />
                        )}
                      />
                      <TableSectionValuesPerForecast
                        title={l('_CostOfEquityCumulative')}
                        renderCell={({ caseItem, forecast }) => (
                          <CellValue
                            {...enteredPercentageViewRules}
                            value={getCalculatedProjectValueInPercent(
                              getInstrumentCaseYearTranche(
                                results,
                                currentInstrumentId!,
                                caseItem.caseId,
                                forecast.forecastId,
                                tranche.id,
                                (tranche) => tranche.cumulativeCostOfEquity
                              )
                            )}
                          />
                        )}
                        additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                        additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
                      />
                    </Fragment>
                  ))}
                </>
              )}
              {isSlnAndPrefSharesInstrument && (
                <TableSectionValuesPerForecast
                  title={l('_AllInDiscountRate')}
                  key={currentInstrumentId}
                  renderCell={({ caseItemIndex, forecastIndex, forecast }) => {
                    const allInDiscountRateIndex = forecast.allInDiscountRates.findIndex(
                      (item) => item.instrumentId === currentInstrumentId
                    );

                    return (
                      <GridTextField
                        name={`cases[${caseItemIndex}].forecasts[${forecastIndex}].allInDiscountRates[${allInDiscountRateIndex}].value`}
                        {...percentageFieldFormattingProps}
                        tooltipVariation={GridFieldTooltipVariation.Right}
                        rules={positiveValueValidator(true)}
                      />
                    );
                  }}
                />
              )}
            </tbody>
          </table>
        </form>
      </FormProvider>
    </div>
  );
};
