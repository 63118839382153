import { commaFormatter, fractionFormatter } from './index';

interface NumberValueFormatterProps {
  value: number;
  fraction?: number;
  valuePrefix?: string;
  valueSuffix?: string;
  isNormalised?: boolean;
}

export const percentageValueFormatterProps: Omit<NumberValueFormatterProps, 'value'> = {
  fraction: 2,
  valueSuffix: '%',
  isNormalised: true,
};

export const multipleValueFormatterProps: Omit<NumberValueFormatterProps, 'value'> = {
  fraction: 1,
  valueSuffix: 'x',
  isNormalised: true,
};

export const calculatedMultipleValueFormatterProps: Omit<NumberValueFormatterProps, 'value'> = {
  fraction: 2,
  valueSuffix: 'x',
  isNormalised: true,
};

export const amountValueFormatterProps: Omit<NumberValueFormatterProps, 'value'> = {
  fraction: 0,
};

export const calculatedAmountValueFormatterProps: Omit<NumberValueFormatterProps, 'value'> = {
  fraction: 2,
  isNormalised: true,
};

export const numberValueFormatter = ({
  value,
  fraction,
  valuePrefix = '',
  valueSuffix = '',
  isNormalised = false,
}: NumberValueFormatterProps): string => {
  const valueFraction = fractionFormatter(value, fraction ?? 0);
  if (Math.sign(value) === -1) {
    return `(${valuePrefix}${commaFormatter(
      Number(valueFraction.substring(1)),
      undefined,
      isNormalised,
      fraction
    )})${valueSuffix}`;
  }
  return `${valuePrefix}${commaFormatter(
    Number(valueFraction),
    undefined,
    isNormalised,
    fraction
  )}${valueSuffix}`;
};

export const toPercentageUsingFormatter = (num: Nullable<number>) => {
  return numberValueFormatter({
    ...percentageValueFormatterProps,
    value: (num ?? 0) * 100,
  });
};

export const abbreviatedValueFormatter = ({
  value,
  valuePrefix = '',
  valueSuffix = '',
  fraction = 3,
}: NumberValueFormatterProps): string => {
  const abbreviateNumber = (num: number) => {
    const absNum = Math.abs(num);
    const numStr = absNum.toFixed(3);

    if (absNum >= 1e12) {
      return commaFormatter(Number((absNum / 1e12).toFixed(fraction))) + 'tn';
    } else if (absNum >= 1e9) {
      return `${(absNum / 1e9).toFixed(fraction)}bn`;
    } else if (absNum >= 1e6) {
      return `${(absNum / 1e6).toFixed(fraction)}m`;
    } else if (absNum >= 1e3) {
      return `${(absNum / 1e3).toFixed(fraction)}k`;
    } else {
      return commaFormatter(Number(numStr), undefined, false);
    }
  };

  let formattedValue = abbreviateNumber(value);

  // if the value is in the thousands, we only need to look back two characters
  const offset = /k$/.test(formattedValue) ? 2 : 3;

  if (
    formattedValue.charAt(formattedValue.length - offset) === '0' &&
    /[kbtm]n?$/.test(formattedValue)
  ) {
    // Remove trailing zeroes from decimals and unnecessary decimal points before denomination
    formattedValue = formattedValue.replace(/(\.\d*?[1-9])0*|\.(0+)(?=[kbtm]?n?)/g, '$1');
  }

  return Math.sign(value) === -1
    ? `${valuePrefix}(${formattedValue})${valueSuffix}`
    : `${valuePrefix}${formattedValue}${valueSuffix}`;
};

export const abbreviatedValueFormatterLegacy = ({
  value,
  valuePrefix = '',
  valueSuffix = '',
}: NumberValueFormatterProps): string => {
  const abbreviateNumber = (num: number) => {
    const absNum = Math.abs(num);
    const numStr = absNum.toFixed(0);

    if (absNum >= 1e12) {
      return `${(absNum / 1e12).toFixed(1)}T`;
    } else if (absNum >= 1e9) {
      return `${(absNum / 1e9).toFixed(1)}B`;
    } else if (absNum >= 1e6) {
      return `${(absNum / 1e6).toFixed(1)}M`;
    } else if (absNum >= 1e3) {
      return `${(absNum / 1e3).toFixed(0)}K`;
    } else {
      return commaFormatter(Number(numStr), undefined, false);
    }
  };

  let formattedValue = abbreviateNumber(value);

  if (formattedValue.charAt(formattedValue.length - 2) === '0') {
    formattedValue = formattedValue.replace(/\.0([KM]*)$/, '$1');
  }

  return Math.sign(value) === -1
    ? `${valuePrefix}(${formattedValue})${valueSuffix}`
    : `${valuePrefix}${formattedValue}${valueSuffix}`;
};
