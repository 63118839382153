import { useEffect, useRef, useState } from 'react';

export const useDropdown = ({ onClickOutside }: { onClickOutside?: () => void }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({
    open: false,
  });

  const toggle = () => {
    setState({
      open: !state.open,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (state.open && ref.current && !ref.current.contains(event.target as Node)) {
        setState({
          open: false,
        });

        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [onClickOutside, state]);

  return {
    ref,
    toggle,
    state,
  };
};
