import { distinct, enumKeyByValue, groupBy, mapAsRecord, sumBy } from '@app/shared/helpers';

import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import {
  CapitalStructure,
  CapitalStructure2,
  Instrument,
  RankBlock,
  RankBlock2,
} from './capital-structure-types';
import {
  CapitalStructureRankDto,
  CapitalStructureRankInstrumentDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';

const flattenInstrumentRanks = <TInstrument extends Instrument>(instruments: TInstrument[]) => {
  const isSlnOrPref = (instrument: Instrument) =>
    instrument.type !== enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);

  const maxSlnAndPrefsRank = Math.max(...instruments.filter(isSlnOrPref).map((x) => x.rank), 0);

  return instruments.map((x) =>
    x.type === enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
      ? { ...x, rank: x.rank + maxSlnAndPrefsRank + 1 }
      : x
  );
};

const getTotalInvestedPerRank = <TInstrument extends Instrument>(instruments: TInstrument[]) =>
  instruments.reduce((p, c) => {
    p[c.rank] = p[c.rank] || 0;
    p[c.rank] += c.amount;
    return p;
  }, {} as Record<number, number>);

const calculateMaxStructureHeight = <TInstrument extends Instrument>(
  instruments: TInstrument[],
  structureHeight: number
) => {
  const ranks = Object.keys(
    instruments.reduce((p, c) => {
      p[c.rank] = true;
      return p;
    }, {} as Record<number, boolean>)
  );

  const ranksCount = ranks.length;

  return (ranksCount < 4 ? ranksCount * 0.25 : 1) * structureHeight;
};

/**
 * Ensures that there are no dimensions that are less than provided @param min in a way that retains total dimensions sum
 * Example: given [100, 100, 10] and 20 as min, running returned function through every item would result in [95, 95, 20]
 * @param dimensions list of dimensions to adjust between
 * @param min minimum allowed dimension
 * @returns function that when given a dimension adjusts it to correct value
 */
const getDimensionsAdjuster = (dimensions: number[], min: number) => {
  const totalAdjusted = dimensions.reduce(
    (sum, dimension) => sum + (dimension < min ? min - dimension : 0),
    0
  );

  const total = dimensions.reduce((sum, v) => (v > min ? sum + v : sum), 0);

  const adjustmentRatio = 1 - totalAdjusted / total;

  return (dimension: number) => {
    return dimension >= min ? Math.max(dimension * adjustmentRatio, min) : min;
  };
};

const calculateBlockDimensionsIgnoringMinimalDimensions = <TInstrument extends Instrument>(
  instruments: TInstrument[],
  structureHeight: number,
  structureWidth: number
) => {
  const totalInvested = sumBy(instruments, (i) => i.amount);

  const rankTotals = getTotalInvestedPerRank(instruments);
  const numberOfRanks = Object.keys(rankTotals).length;

  const getRankHeightProportion = (rank: number) =>
    totalInvested === 0 || totalInvested === undefined
      ? 1 / numberOfRanks
      : rankTotals[rank] / totalInvested;

  const getInstrumentWidthProportion = (instrument: Instrument) =>
    rankTotals[instrument.rank] === 0 ? 1 : instrument.amount / rankTotals[instrument.rank];

  const ranks = distinct(instruments.map((x) => x.rank)).sort((a, b) => b - a);

  const rankHeights = mapAsRecord(
    ranks,
    (rank) => getRankHeightProportion(rank) * structureHeight,
    (rank) => rank
  );

  const instrumentWidths = mapAsRecord(
    instruments,
    (instrument) => getInstrumentWidthProportion(instrument) * structureWidth,
    (instrument) => instrument.instrumentId
  );

  return {
    rankHeights,
    instrumentWidths,
  };
};

const calculateBlockDimensionsIgnoringMinimalDimensions2 = (
  ranks: CapitalStructureRankDto[],
  structureHeight: number,
  structureWidth: number
) => {
  const rankHeights = mapAsRecord(
    ranks,
    (rank) => rank.proportionOfTotal * structureHeight,
    (rank) => rank.rank
  );

  const instruments = ranks.flatMap((rank) =>
    Object.entries(rank.instruments).map(([instrumentId, instrument]) => ({
      ...instrument,
      instrumentId,
    }))
  );
  const instrumentWidths = mapAsRecord(
    instruments,
    (instrument) => instrument.proportionOfRank * structureWidth,
    (instrument) => instrument.instrumentId
  );

  return {
    rankHeights,
    instrumentWidths,
  };
};

export const calculateCapitalStructureDimensions = <TInstrument extends Instrument>(
  instruments: TInstrument[],
  structureHeight: number,
  structureWidth: number,
  minHeight: number,
  minWidth: number,
  adjustStructureHeightBasedOnRankCount = true
) => {
  const flatRankInstruments = flattenInstrumentRanks(instruments);

  const maxStructureHeight = adjustStructureHeightBasedOnRankCount
    ? calculateMaxStructureHeight(flatRankInstruments, structureHeight)
    : structureHeight;

  const { instrumentWidths, rankHeights } = calculateBlockDimensionsIgnoringMinimalDimensions(
    flatRankInstruments,
    maxStructureHeight,
    structureWidth
  );

  const adjustRankHeight = getDimensionsAdjuster(Object.values(rankHeights), minHeight);

  const instrumentsPerRank = Object.entries(groupBy(flatRankInstruments, (i) => i.rank))
    .map(([rank, instruments]) => [Number(rank), instruments] as [number, typeof instruments])
    .sort((a, b) => b[0] - a[0]);

  const rankBlocks = instrumentsPerRank.map(([rank, rankInstruments]) => {
    const rankInstrumentWidths = Object.values(
      rankInstruments.map((instrument) => instrumentWidths[instrument.instrumentId])
    );

    const adjustInstrumentWidth = getDimensionsAdjuster(rankInstrumentWidths, minWidth);

    const instrumentBlocks = rankInstruments.map((instrument) => ({
      ...instrument,
      width: adjustInstrumentWidth(instrumentWidths[instrument.instrumentId]),
    }));

    return {
      rank: rankInstruments[0].rank,
      instrumentBlocks: instrumentBlocks,
      height: adjustRankHeight(rankHeights[rank]),
    } as RankBlock<TInstrument>;
  });

  return {
    maxHeight: maxStructureHeight,
    maxWidth: structureWidth,
    ranks: rankBlocks,
  } as CapitalStructure<TInstrument>;
};

export const calculateCapitalStructureDimensions2 = (
  ranks: CapitalStructureRankDto[],
  structureHeight: number,
  structureWidth: number,
  minHeight: number,
  minWidth: number,
  adjustStructureHeightBasedOnRankCount = true
) => {
  const ranksCount = ranks.length;

  const maxStructureHeight = adjustStructureHeightBasedOnRankCount
    ? (ranksCount < 4 ? ranksCount * 0.25 : 1) * structureHeight
    : structureHeight;

  const { instrumentWidths, rankHeights } = calculateBlockDimensionsIgnoringMinimalDimensions2(
    ranks,
    maxStructureHeight,
    structureWidth
  );

  const adjustRankHeight = getDimensionsAdjuster(Object.values(rankHeights), minHeight);

  const rankBlocks = ranks.map((rank) => {
    const rankInstrumentWidths = Object.keys(rank.instruments).map(
      (instrumentId) => instrumentWidths[instrumentId]
    );

    const adjustInstrumentWidth = getDimensionsAdjuster(rankInstrumentWidths, minWidth);

    const instrumentBlocks = Object.entries(rank.instruments).map(([instrumentId, instrument]) => ({
      ...instrument,
      instrumentId,
      width: adjustInstrumentWidth(instrumentWidths[instrumentId]),
    }));

    return {
      rank: rank.rank,
      instrumentBlocks: instrumentBlocks,
      height: adjustRankHeight(rankHeights[rank.rank]),
    } as RankBlock2<CapitalStructureRankInstrumentDto>;
  });

  return {
    maxHeight: maxStructureHeight,
    maxWidth: structureWidth,
    ranks: rankBlocks,
  } as CapitalStructure2<CapitalStructureRankInstrumentDto>;
};
