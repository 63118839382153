import styles from './sidebar.module.scss';
import classNames from 'classnames';
import { ReactNode } from 'react';

export enum SidebarVariation {
  Empty = 'empty',
}

export enum SidebarModifier {
  Primary = 'primary',
}

interface SidebarProps {
  children: ReactNode;
  actions?: ReactNode;
  sidebarModifier?: SidebarModifier;
  sidebarVariation?: SidebarVariation;
  isErfModal?: boolean;
}

const PARENT_CLASSNAME = 'sidebar';

const Sidebar = ({
  children,
  sidebarVariation,
  sidebarModifier,
  actions,
  isErfModal = false,
  ...rest
}: SidebarProps): JSX.Element => {
  return (
    <div
      className={classNames({
        [styles[PARENT_CLASSNAME]]: !isErfModal,
        [styles[`${PARENT_CLASSNAME}--erf`]]: isErfModal,
        [styles[`${PARENT_CLASSNAME}--with-actions`]]: actions,
        [styles[`${PARENT_CLASSNAME}--${sidebarModifier}`]]: sidebarModifier,
      })}
      {...rest}>
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__content`], {
          [styles[`${PARENT_CLASSNAME}__content--${sidebarVariation}`]]: sidebarVariation,
        })}>
        {children}
      </div>
      {actions}
    </div>
  );
};

export default Sidebar;
