import { SVGProps } from 'react';
const SvgRevert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    style={{ width: '16px', height: '12.35px' }}
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    {...props}>
    <path
      d="M8.41002 13.4461L8.40985 13.4462C7.66913 13.8819 6.86762 14.1 6.00715 14.1L3 14.1C2.84934 14.1 2.62111 14.146 2.43343 14.2812C2.25085 14.4126 2.1 14.6326 2.1 15C2.1 15.2325 2.15784 15.4002 2.23945 15.5227C2.32149 15.6457 2.43158 15.729 2.54472 15.7856C2.65825 15.8423 2.77299 15.8712 2.86019 15.8857C2.90355 15.893 2.93947 15.8965 2.96416 15.8983C2.97648 15.8992 2.98596 15.8996 2.9921 15.8998L2.99874 15.9L3 15.9L3.00007 15.9L3.00015 15.9L3.00021 15.9L6.00715 15.9C7.19475 15.9 8.29725 15.6007 9.31649 15.0018L9.31705 15.0015C10.3153 14.4235 11.1027 13.6361 11.6806 12.6379L11.6809 12.6373C12.2798 11.6182 12.5791 10.5104 12.5791 9.312C12.5791 8.1244 12.2799 7.0219 11.6809 6.00266L11.6806 6.0021C11.1027 5.00389 10.3153 4.21646 9.31704 3.63854L9.31649 3.63822C8.29739 3.03937 7.18958 2.74 5.99115 2.74L4.16715 2.74L3.92573 2.74L4.09644 2.56929L5.02346 1.64226C5.19739 1.45825 5.28315 1.24312 5.28315 0.992C5.28315 0.753063 5.19847 0.543316 5.02441 0.358738C4.83983 0.184678 4.63008 0.0999998 4.39115 0.0999998C4.14003 0.0999998 3.9249 0.185763 3.74088 0.359686L0.46857 3.632L3.74186 6.90529C3.92647 7.0899 4.14131 7.18 4.39115 7.18C4.62918 7.18 4.83897 7.09075 5.02444 6.90529C5.19724 6.73249 5.28315 6.52345 5.28315 6.272C5.28315 6.02055 5.19724 5.81151 5.02444 5.63871L4.09644 4.71071L3.92573 4.54L4.16715 4.54L5.99115 4.54C6.86205 4.54 7.66893 4.75797 8.40985 5.19381L8.41002 5.19391C9.12855 5.61849 9.70066 6.1906 10.1252 6.90913L10.1253 6.9093C10.5611 7.65002 10.7791 8.45153 10.7791 9.312C10.7791 10.1829 10.5612 10.9898 10.1253 11.7307L10.1252 11.7309C9.70066 12.4494 9.12855 13.0215 8.41002 13.4461Z"
      fill="#D04A02"
      stroke="white"
      strokeWidth="0.2"
    />
  </svg>
);
export default SvgRevert;
