export enum CardVariation {
  SecondarySpacing = 'secondary-spacing',
  TertiarySpacing = 'tertiary-spacing',
  NoSpacing = 'no-spacing',
  PayoutLogicSubTabs = 'payout-logic-sub-tabs',
}

export enum CardCornersVariation {
  Primary = 'primary',
  Secondary = 'secondary',
}
