import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation/components';

const datePickerComponent: PickerComponents = {
  MuiPickersDay: {
    defaultProps: {
      disableRipple: true,
    },
  },
};

export default datePickerComponent;
