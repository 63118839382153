import { CaseDto, ForecastDto, ProjectDto } from '@app/shared/models/contracts/project-dto';
import { getMultipleProbabilityWeightedValue } from '@app/shared/helpers/get-weighted-values';
import { getCalculatedVariableValue } from '@core/store/project-slice-selectors';
import { PwermCalculatedVariables } from '@app/shared/models/contracts/pwerm-calculation-results-dto';

export const getMultipleWeightedTotalEquityProceeds = (
  project: ProjectDto,
  calculatedVariables: PwermCalculatedVariables,
  caseItem: CaseDto,
  forecast: ForecastDto
) => {
  return getMultipleProbabilityWeightedValue({
    caseItem: caseItem,
    forecast: forecast,
    getValue: ({ caseItem, forecast, multipleId }) => {
      const multiple = caseItem.multiples.find((m) => m.multipleId === multipleId);

      if (!multiple || !multiple.multiple) {
        return undefined;
      }

      const result = getCalculatedVariableValue(
        project,
        calculatedVariables,
        caseItem.caseId,
        forecast.forecastId,
        multiple.multiple,
        'Total Equity Proceeds'
      );

      return result === undefined ? undefined : Number(result);
    },
  });
};
