import { SVGProps } from 'react';
const SvgTabArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M1.75 1 5.5 5l3.75-4"
      stroke="#8A94A6"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTabArrow;
