import React, { FC } from 'react';
import classNames from 'classnames';
import { MAX_LABEL_ITEMS } from '@app/modules/projects/dashboard/widgets/total-equity/constants';
import styles from './total-equity-chart.module.scss';
import { Tooltip, TooltipPlacement } from '@app/shared/components/tooltip/Tooltip';
import { dateFormatter } from '@app/shared/formatters';

const PARENT_CLASSNAME = 'total-equity-chart';

interface NetDebtColumnProps {
  isExpanded: boolean;
  labelTopValue: number;
  forecastYear: string;
  maxNetDebtAxisCount: number;
  columnIndex: number;
  netDebt: number;
  chartForecastsCount: number;
  tooltipContent: string | JSX.Element;
}

const NetDebtColumn: FC<NetDebtColumnProps> = ({
  isExpanded,
  labelTopValue,
  forecastYear,
  columnIndex,
  chartForecastsCount,
  netDebt,
  maxNetDebtAxisCount,
  tooltipContent,
}): JSX.Element => {
  return (
    <div
      className={classNames(styles[`${PARENT_CLASSNAME}__column`], {
        [styles[`${PARENT_CLASSNAME}__column--expanded`]]: isExpanded,
      })}>
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
          [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
        })}
        style={{
          height: (100 / ((labelTopValue / MAX_LABEL_ITEMS) * maxNetDebtAxisCount)) * netDebt + '%',
        }}>
        <Tooltip
          key={'debt-tooltip' + columnIndex}
          placement={
            chartForecastsCount - 1 === columnIndex ? TooltipPlacement.Left : TooltipPlacement.Top
          }
          content={tooltipContent}
          className={classNames(styles[`${PARENT_CLASSNAME}__debt-column`], {
            [styles[`${PARENT_CLASSNAME}__debt-column--expanded`]]: isExpanded,
          })}>
          <div className={styles[`${PARENT_CLASSNAME}__tooltip-placeholder`]} />
        </Tooltip>
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__column-legend`]}>
        {dateFormatter(forecastYear, { year: 'numeric' })}
      </div>
    </div>
  );
};

export default React.memo(NetDebtColumn);
