import api from '@server/api-config';
import env from '@environment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PwermCalculationResultsDto } from '@app/shared/models/contracts/pwerm-calculation-results-dto';
import { AppDispatch, RootState } from './store';

export interface PwermCalculationState {
  projectId?: number;
  values: PwermCalculationResultsDto;
}

export const calculate = createAsyncThunk<
  { calculations: PwermCalculationResultsDto; projectId: number },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculate', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;

  const results = await api.post<PwermCalculationResultsDto>(
    `${env.apiUrl}/calculate`,
    JSON.stringify(project)
  );

  return { calculations: results.data, projectId: project.id };
});

export const pwermCalculationSlice = createSlice({
  name: 'calculation',
  initialState: { values: {} } as PwermCalculationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(calculate.fulfilled, (state, action) => {
      state.projectId = action.payload.projectId;
      state.values = {
        ...action.payload.calculations,
      };
    });
  },
});

export default pwermCalculationSlice.reducer;
