import EntryPageRoutesWrapper from '@app/shared/components/entry-page-routes-wrapper/EntryPageRoutesWrapper';
import { FC } from 'react';
import { UserList } from '@app/modules/users/user-list/UserList';

const Users: FC = (): JSX.Element => {
  return (
    <EntryPageRoutesWrapper>
      <UserList />
    </EntryPageRoutesWrapper>
  );
};

export default Users;
