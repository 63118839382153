import { multiplierPattern } from '@app/shared/components/grid-controls/grid-text-field/grid-cell-value-patterns';

export const multipleFieldBase = {
  fieldValueMask: multiplierPattern,
  decimalPoint: 2,
  isNormalised: true,
  viewValueSuffix: 'x',
  placeholder: '0.00x',
  valueAsNumber: true,
};
