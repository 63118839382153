import { CaseDto } from '@app/shared/models/contracts/project-dto';
import { getCasesWithNonZeroProbability } from './get-cases-with-non-zero-probability';

export const getCaseWithMostForecasts = (caseItems: CaseDto[], includeAllCases = false) => {
  let cases = includeAllCases ? caseItems : getCasesWithNonZeroProbability(caseItems);

  if (cases.length === 0) {
    cases = caseItems;
  }

  return cases.reduce((caseWithMostForecasts, caseItem) =>
    caseWithMostForecasts.forecasts.length >= caseItem.forecasts.length
      ? caseWithMostForecasts
      : caseItem
  );
};
