import { FC } from 'react';
import styles from './pagination.module.scss';
import SvgPrevious from '@app/shared/icons/Previous';
import SvgNext from '@app/shared/icons/Next';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import Button from '@app/shared/components/button/Button';
import { PaginationMetadataDto } from '@app/shared/models/pagination';
import { useSearchParams } from 'react-router-dom';
import { MAX_ITEMS_PER_LIST } from '@app/shared/constants/configs/pagination';
import { usePagination } from '@core/hooks/usePagination';
import { ELLIPSIS } from '@app/shared/constants/ellipsis';
import { SearchParamKey } from '@app/shared/models/search-param-enum';
import { useLocale } from '@app/core/hooks/useLocale';

interface TablePaginationProps {
  paginationInfo: PaginationMetadataDto;
}

const PARENT_CLASSNAME = 'pagination';
const TablePagination: FC<TablePaginationProps> = ({ paginationInfo }): JSX.Element => {
  const { l } = useLocale();
  const [searchParams, setSearchParams] = useSearchParams();
  const totalPages = [...Array(Math.ceil(paginationInfo.total / MAX_ITEMS_PER_LIST))];
  const currentPage = Number(searchParams.get(SearchParamKey.CurrentPage));
  const startIndex = currentPage * MAX_ITEMS_PER_LIST + 1;
  const endIndex = Math.min((currentPage + 1) * MAX_ITEMS_PER_LIST, paginationInfo.total);

  const handleClick = (index: number, blockCondition: boolean, forward?: boolean) => {
    if (blockCondition && forward ? totalPages.length !== index : currentPage + 1 !== index) {
      searchParams.set(SearchParamKey.CurrentPage, (forward ? index : index - 1).toString());
      setSearchParams(searchParams);
    }
  };

  const paginationRange = usePagination(paginationInfo.total, MAX_ITEMS_PER_LIST, currentPage);

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
        <ul className={styles[`${PARENT_CLASSNAME}__action-list`]}>
          <li className={styles[`${PARENT_CLASSNAME}__action-previous`]}>
            <Button
              appearance={ButtonAppearance.LINK}
              aria-label={l('_PreviousPage')}
              disabled={currentPage === 0}
              onClick={() => handleClick(currentPage, currentPage !== 0)}>
              <SvgPrevious width="8px" height="14px" />
            </Button>
          </li>
          {paginationRange?.map((pageItem, index) => {
            if (pageItem === ELLIPSIS) {
              return (
                <li
                  className={styles[`${PARENT_CLASSNAME}__ellipsis`]}
                  key={'page-' + pageItem + index}>
                  &hellip;
                </li>
              );
            }

            return (
              <li key={'page-' + pageItem}>
                <Button
                  appearance={ButtonAppearance.CLEAN}
                  active={pageItem === currentPage + 1}
                  onClick={() => handleClick(Number(pageItem), true)}>
                  {pageItem}
                </Button>
              </li>
            );
          })}
          <li className={styles[`${PARENT_CLASSNAME}__action-next`]}>
            <Button
              onClick={() =>
                handleClick(
                  Number(currentPage) + 1,
                  currentPage + 1 !== totalPages.length + 1,
                  true
                )
              }
              appearance={ButtonAppearance.LINK}
              aria-label={l('_NextPage')}
              disabled={currentPage + 1 === totalPages.length}>
              <SvgNext width="8px" height="14px" />
            </Button>
          </li>
        </ul>
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__info`]}>
        {startIndex} - {endIndex} of {paginationInfo.total}
      </div>
    </div>
  );
};

export default TablePagination;
