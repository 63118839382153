import { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { useEVGeneralMetrics } from '@app/modules/projects/dashboard/widgets/ev-evolution/useEVGeneralMetrics';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import {
  getCalculatedValueForForecast,
  getCalculatedVariableValue,
  getMultipleById,
  getExitMultiplesWithNonZeroProbability,
} from '@core/store/project-slice-selectors';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { selectCalculatedVariablesResults } from '@app/core/store/pwerm-calculation-slice-selectors';
import {
  abbreviatedValueFormatter,
  calculatedMultipleValueFormatterProps,
  dateFormatter,
  numberValueFormatter,
} from '@app/shared/formatters';
import { groupBy } from '@app/shared/helpers';
import { DefaultDashboardDropdownValues } from '@app/modules/projects/dashboard/widgets/default-dashboard-dropdown-values-enum';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { useWeightedAverageCalculator } from '@app/modules/projects/outputs/custom-hooks/useWeightedAverageCalculator';
import { UseFormSetValue } from 'react-hook-form';
import { useCSSVariables } from '@core/hooks/useCSSVariables';
import styles from './ev-evolution-chart.module.scss';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { axisLabelFormatter } from '../../opm-charts/format-helpers';
import { DenominationMultiplier } from '@app/shared/models/contracts/enums/shared-enums';
import {
  getPwermForecastValueDriver,
  getPwermValuationValueDriver,
} from '@app/shared/models/helpers/project-helpers';

HighchartsMore(Highcharts);

interface EVEvolutionChartProps {
  selectedCase?: CaseDto;
  selectedViewOption: string;
  setSelectedViewOption: UseFormSetValue<{ viewOption: string }>;
  isExpanded: boolean;
}

interface EVChartTooltipData {
  exitValueDriverName: string;
  currentEV?: string;
  exitValueDriver: number;
  weightedMultipleAverage: number;
  lowEV: number;
  highEV: number;
}

interface CustomHCPoint<T> extends Highcharts.Point {
  custom: T;
}

const legendBase: Highcharts.LegendOptions = {
  align: 'left',
  alignColumns: false,
  verticalAlign: 'top',
  margin: 44,
  itemMarginTop: 16,
  symbolPadding: 8,
  symbolHeight: 16,
  symbolWidth: 16,
  symbolRadius: 8,
  itemDistance: 32,
  itemStyle: {
    fontSize: '1.4rem',
    fontWeight: '500',
  },
  x: -10,
};

export const EVEvolutionChart: FC<EVEvolutionChartProps> = ({
  selectedCase,
  selectedViewOption,
  setSelectedViewOption,
  isExpanded,
}): JSX.Element => {
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);

  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const calculatedWeightedAverage = useWeightedAverageCalculator();
  const { currentEV, currentValueDriverMultiple } = useEVGeneralMetrics(selectedCase);
  const {
    colorList: colors,
    font: { fontFamilyPrimary },
  } = useCSSVariables();
  const [
    colorPrimary,
    colorSecondary,
    colorTertiary,
    colorAccents1,
    colorAccents2,
    colorAccents3,
    colorAccents4,
    colorAccents5,
    colorNeutralsB50,
    colorNeutralsB80,
    colorNeutralsB90,
  ] = colors;

  const chartColors = [
    colorPrimary,
    colorSecondary,
    colorTertiary,
    colorAccents1,
    colorAccents2,
    colorAccents4,
  ];

  const categories = [
    'Current',
    ...getCaseWithMostForecasts(sortedProjectByCase.pwermInput.cases).forecasts.map((forecast) =>
      dateFormatter(forecast.forecastYear, { year: 'numeric' })
    ),
  ];

  const [previousLegendItemId, setPreviousLegendItemId] = useState<string>();

  // required so that the previousLegendItemId is in sync with the selectedViewOption
  // if the user updates their selection from the dropdown, rather than clicking on the legend
  useEffect(() => {
    setPreviousLegendItemId(selectedViewOption);
  }, [selectedViewOption]);

  const getLowestForecastExitEV = (
    caseItem: CaseDto,
    forecast: ForecastDto,
    exitMultiplesWithNonZeroProbability: number[]
  ) => {
    return Number(
      getCalculatedVariableValue(
        sortedProjectByCase,
        calculatedVariables,
        caseItem.caseId,
        forecast.forecastId,
        Math.min(...exitMultiplesWithNonZeroProbability),
        'Exit EV'
      )
    );
  };

  const getHighestForecastExitEV = (
    caseItem: CaseDto,
    forecast: ForecastDto,
    exitMultiplesWithNonZeroProbability: number[]
  ) => {
    return Number(
      getCalculatedVariableValue(
        sortedProjectByCase,
        calculatedVariables,
        caseItem.caseId,
        forecast.forecastId,
        Math.max(...exitMultiplesWithNonZeroProbability),
        'Exit EV'
      )
    );
  };

  const valueDriverName = getPwermValuationValueDriver(project).narrative;

  const allForecasts = sortedProjectByCase.pwermInput.cases.reduce((acc, caseItem) => {
    return [
      ...acc,
      ...caseItem.forecasts.map((forecast) => {
        const exitMultiplesWithNonZeroProbability = getExitMultiplesWithNonZeroProbability(
          forecast,
          caseItem
        );
        return {
          caseId: caseItem.caseId,
          caseProbability: caseItem.probability,
          forecastYear: forecast.forecastYear,
          y:
            getCalculatedValueForForecast(
              sortedProjectByCase,
              calculatedVariables,
              caseItem.caseId,
              forecast.forecastId,
              'Weighted EV'
            ) * multiplier,
          custom: {
            exitValueDriverName: valueDriverName,
            exitValueDriver:
              (getPwermForecastValueDriver(sortedProjectByCase, forecast).value ?? NaN) *
              multiplier,
            weightedMultipleAverage:
              calculatedWeightedAverage(
                caseItem.caseId,
                forecast.forecastId,
                ({ caseId, multipleId }) =>
                  getMultipleById(sortedProjectByCase, caseId, multipleId)?.multiple
              ) ?? NaN,
            lowEV:
              getLowestForecastExitEV(caseItem, forecast, exitMultiplesWithNonZeroProbability) *
              multiplier,
            highEV:
              getHighestForecastExitEV(caseItem, forecast, exitMultiplesWithNonZeroProbability) *
              multiplier,
          },
        };
      }),
    ];
  }, [] as { caseId: string; caseProbability: Nullable<number>; forecastYear: string; y: number; custom: EVChartTooltipData }[]);
  const allForecastsByForecastYear = groupBy(allForecasts, (forecast) => forecast.forecastYear);
  const allForecastsByCaseId = groupBy(allForecasts, (forecast) => forecast.caseId);

  const getCaseWeightedValue = (
    items: {
      caseId: string;
      caseProbability: Nullable<number>;
      forecastYear: string;
      y: number;
      custom: EVChartTooltipData;
    }[],
    getValue: (item: {
      caseId: string;
      caseProbability: Nullable<number>;
      forecastYear: string;
      y: number;
      custom: EVChartTooltipData;
    }) => number
  ) =>
    items.reduce((acc, item) => {
      if (item.caseProbability === null) {
        return NaN;
      }

      return acc + (item.caseProbability / 100) * getValue(item);
    }, 0);

  const valuationValueDriver = getPwermValuationValueDriver(project);

  const startingPoint: Highcharts.PointOptionsObject = {
    y: currentEV * multiplier,
    custom: {
      exitValueDriverName: valuationValueDriver.narrative,
      exitValueDriver: (valuationValueDriver.value ?? NaN) * multiplier,
      weightedMultipleAverage: currentValueDriverMultiple,
      currentEV: currentEV * multiplier,
    },
  };

  const weightedAverageData = Object.values(allForecastsByForecastYear).map((items) => {
    return {
      y: getCaseWeightedValue(items, (item) => item.y),
      custom: {
        exitValueDriverName: valuationValueDriver.narrative,
        exitValueDriver: getCaseWeightedValue(items, (item) => item.custom.exitValueDriver),
        weightedMultipleAverage: getCaseWeightedValue(
          items,
          (item) => item.custom.weightedMultipleAverage
        ),
        lowEV: Math.min(...items.map((item) => item.custom.lowEV)),
        highEV: Math.max(...items.map((item) => item.custom.highEV)),
      },
    };
  });
  const weightedAverageRanges = weightedAverageData.map((item) => [
    item.custom?.lowEV,
    item.custom?.highEV,
  ]);

  const rangeConfigBase: Highcharts.SeriesOptionsType = {
    type: 'arearange',
    enableMouseTracking: false,
    linkedTo: ':previous',
    lineWidth: 0,
    opacity: 0.2,
    zIndex: 0,
    showInLegend: false,
    marker: {
      enabled: false,
    },
  };

  const allSeries: Highcharts.SeriesOptionsType[] = [
    ...getCasesWithNonZeroProbability(sortedProjectByCase.pwermInput.cases).flatMap(
      (caseItem, index): Highcharts.SeriesOptionsType[] => {
        return [
          {
            id: caseItem.caseId,
            name: caseItem.narrative,
            type: 'line',
            data: [
              startingPoint,
              ...allForecastsByCaseId[caseItem.caseId].map((forecastData) => ({
                y: forecastData.y,
                custom: {
                  ...forecastData.custom,
                },
              })),
            ],
            color: chartColors[index],
          },
          {
            ...rangeConfigBase,
            id: `${caseItem.caseId}-range`,
            name: `${caseItem.narrative} EV range`,
            data: [
              [currentEV, currentEV],
              ...allForecastsByCaseId[caseItem.caseId].map((forecastData) => [
                forecastData.custom.lowEV,
                forecastData.custom.highEV,
              ]),
            ],
            color: chartColors[index],
            visible: false,
          },
        ];
      }
    ),
    {
      id: DefaultDashboardDropdownValues.WeightedAverage,
      name: 'Weighted average',
      type: 'line',
      dashStyle: 'LongDash',
      color: colorNeutralsB50,
      data: [startingPoint, ...weightedAverageData],
    },
    {
      ...rangeConfigBase,
      id: `${DefaultDashboardDropdownValues.WeightedAverage}-range`,
      name: 'Weighted average range',
      color: colorNeutralsB50,
      data: [[currentEV, currentEV], ...weightedAverageRanges],
      visible: false,
    },
  ];

  const onLegendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction = (
    event: Highcharts.SeriesLegendItemClickEventObject
  ) => {
    if (event.target.chart.series.every((item) => !item.visible)) {
      setSelectedViewOption('viewOption', DefaultDashboardDropdownValues.AllCases);
      return false;
    }
    if (previousLegendItemId === event.target.userOptions.id) {
      setSelectedViewOption('viewOption', DefaultDashboardDropdownValues.AllCases);
      setPreviousLegendItemId(undefined);
      return false;
    }
    event.target.chart.series.forEach((item) => {
      if (item.userOptions.id === event.target.userOptions.id) {
        setSelectedViewOption(
          'viewOption',
          event.target.userOptions.id ?? DefaultDashboardDropdownValues.AllCases
        );
        return false;
      }
    });
    return false;
  };

  function tooltipFormatter(this: Highcharts.TooltipFormatterContextObject) {
    const { custom: data } = this.point as CustomHCPoint<EVChartTooltipData>;
    const formattedData = {
      exitValueDriverName: data.exitValueDriverName ?? EmptyValues.EnDash,
      exitValueDriver: Number.isFinite(data.exitValueDriver)
        ? abbreviatedValueFormatter({
            value: data.exitValueDriver,
            fraction: 3,
          })
        : EmptyValues.EnDash,
      weightedMultipleAverage: Number.isFinite(data.weightedMultipleAverage)
        ? numberValueFormatter({
            value: data.weightedMultipleAverage,
            ...calculatedMultipleValueFormatterProps,
          })
        : EmptyValues.EnDash,
      currentEV: data.currentEV
        ? abbreviatedValueFormatter({
            value: Number(data.currentEV),
            fraction: 3,
          })
        : EmptyValues.EnDash,
      lowEV: Number.isFinite(data.lowEV)
        ? abbreviatedValueFormatter({
            value: data.lowEV,
            fraction: 3,
          })
        : EmptyValues.EnDash,
      highEV: Number.isFinite(data.highEV)
        ? abbreviatedValueFormatter({
            value: data.highEV,
            fraction: 3,
          })
        : EmptyValues.EnDash,
    };
    return `
        <div class=${styles['ev-evolution-point']}>
         <dl class=${styles['ev-evolution-point__list']}>
           <dt class=${styles['ev-evolution-point__label']}>
             ${formattedData?.exitValueDriverName}
           </dt>
           <dd class=${styles['ev-evolution-point__value']}>
             ${formattedData?.exitValueDriver}
           </dd>
           <dt
             class="${styles['ev-evolution-point__label']} ${
      styles['ev-evolution-point__label--spacer']
    }">
             Multiple
           </dt>
           <dd class=${styles['ev-evolution-point__value']}>
             ${formattedData?.weightedMultipleAverage}
           </dd>
           ${
             formattedData.currentEV !== EmptyValues.EnDash
               ? `
               <dt class="${styles['ev-evolution-point__label']} ${styles['ev-evolution-point__label--spacer']}">
                  Current EV
                </dt>
                <dd class=${styles['ev-evolution-point__value']}>
                  ${formattedData?.currentEV}
                </dd>
              `
               : ''
           }
           ${
             formattedData.lowEV !== EmptyValues.EnDash &&
             formattedData.highEV !== EmptyValues.EnDash
               ? `
                <dt class="${styles['ev-evolution-point__label']} ${styles['ev-evolution-point__label--spacer']}">
                  EV Spread
                <dt/>
                <dd class=${styles['ev-evolution-point__value']}>Low: <span>${formattedData?.lowEV}</span></dd>
                <dd class=${styles['ev-evolution-point__value']}>High: <span>${formattedData?.highEV}</span></dd>
              `
               : ''
           }
         </dl>
        </div>
      `;
  }

  const tooltipBase: Highcharts.TooltipOptions = {
    useHTML: true,
    formatter: tooltipFormatter,
    backgroundColor: '#fff',
    borderColor: '#fff',
    borderRadius: 8,
    shadow: false,
    padding: 0,
  };

  const chartComponent = useRef<HighchartsReact.RefObject>(null);
  const chart = chartComponent?.current?.chart;
  const chartOptions = useMemo<Highcharts.Options>(
    () => ({
      accessibility: { enabled: false },
      chart: {
        animation: true,
        reflow: true,
        styledMode: false,
        style: { fontFamily: fontFamilyPrimary },
        spacingTop: 22,
        events: {
          load: function () {
            const maxSeriesDataCount = this.series.reduce((acc, item) => {
              if (item.data.length >= acc) {
                acc = item.data.length - 1;
              }

              return acc;
            }, 0);

            this.series.forEach((item) => item.xAxis.setExtremes(0, maxSeriesDataCount));
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        ...legendBase,
        itemStyle: {
          ...legendBase.itemStyle,
          color: colorNeutralsB90,
        },
      },
      colors: [...chartColors, colorAccents3, colorAccents5, colorNeutralsB50],
      xAxis: {
        title: {
          text: 'Forecast Years',
          margin: 16,
          style: {
            color: '#7d7d7d',
            fontSize: '1.4rem',
          },
        },
        categories: categories,
        crosshair: {
          width: 1,
          color: colorPrimary,
        },
        labels: {
          style: {
            color: colorNeutralsB80,
            fontSize: '1.4rem',
            fontWeight: '500',
          },
          y: 30,
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          formatter: axisLabelFormatter,
          style: {
            color: colorNeutralsB80,
            fontSize: '1.4rem',
            fontWeight: '500',
          },
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: onLegendItemClick,
          },
        },
        line: {
          marker: {
            symbol: 'circle',
            radius: 8,
            states: {
              hover: {
                fillColor: colorPrimary,
              },
            },
          },
          lineWidth: 2,
        },
      },
      tooltip: { ...tooltipBase },
      series: allSeries,
    }),
    // Added only essential variables, which can affect actual rendering
    [previousLegendItemId, categories, allSeries] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setTimeout(() => {
      chart?.reflow();
    }, 150);
  }, [chart, isExpanded]);

  useEffect(() => {
    const chartSeriesIds = chart?.series?.map((item) => item.options.id);

    switch (selectedViewOption) {
      case DefaultDashboardDropdownValues.AllCases:
        chart?.series?.forEach((item) => {
          if (item.type === 'arearange') {
            item.setVisible(false);
            return;
          }
          item.setVisible(true);
        });

        break;
      case DefaultDashboardDropdownValues.WeightedAverage:
        chartSeriesIds?.forEach((seriesId) => {
          if (!seriesId) {
            return;
          }

          const series = chart?.get(seriesId) as Highcharts.Series;
          if (
            seriesId === DefaultDashboardDropdownValues.WeightedAverage ||
            seriesId === `${DefaultDashboardDropdownValues.WeightedAverage}-range`
          ) {
            series.setVisible(true);
            return;
          }
          series.setVisible(false);
        });

        break;
      default:
        chartSeriesIds?.forEach((seriesId) => {
          if (!seriesId) {
            return;
          }

          const series = chart?.get(seriesId) as Highcharts.Series;
          if (seriesId === selectedViewOption || seriesId === `${selectedViewOption}-range`) {
            series.setVisible(true);
            return;
          }
          series.setVisible(false);
        });
    }
  }, [chart, selectedViewOption, previousLegendItemId]);

  return <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={chartOptions} />;
};
