import React from 'react';
import HideUnrelatedEvents from './HideUnrelatedEvents';
import styles from '../../build-structure-tab/build-structure.module.scss';
import { CaseNavigationControls } from '../../../controls/CaseNavigationControls';

const BuildStructureControls: React.FC = () => {
  return (
    <div className={styles['controls']}>
      <CaseNavigationControls />
      <HideUnrelatedEvents />
    </div>
  );
};

export default BuildStructureControls;
