import { useAppSelector } from '@core/hooks/redux-hooks';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

export const CaseNavigationControls = () => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);

  const selectedCase = project.pwermInput.cases.find((item) => item.caseId === caseId);
  const selectedEventSetId = selectedCase?.eventSetId;

  const navigationItems: NavigationItem[] = useMemo(
    () =>
      project.pwermInput.cases
        .map((item) => ({
          label: item.narrative || EmptyValues.EnDash,
          route: ':caseId',
          params: { caseId: item.caseId },
          order: item.caseOrder,
          isHighlighted:
            selectedEventSetId === null
              ? item.eventSetId === null
              : item.eventSetId === selectedEventSetId,
        }))
        .sort((a, b) => a.order! - b.order!) || [],
    [project.pwermInput.cases, selectedEventSetId]
  );

  navigationItems.sort((a, b) => a.order! - b.order!);

  return (
    <div>
      <SecondaryNavigation navigationItems={navigationItems} />
    </div>
  );
};
