import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { Country } from '@app/shared/models/contracts/enums/shared-enums';

const TaxJurisdictions: FC = (): JSX.Element => {
  const { mainTaxJurisdiction, secondaryTaxJurisdiction } = useAppSelector(
    (state) => state.project.projectDraft.details
  );

  return (
    <Card>
      <h3>Tax Jurisdictions</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label="Main Tax Jurisdiction"
            value={mainTaxJurisdiction ? Country[mainTaxJurisdiction] : ''}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label="Secondary Tax Jurisdiction"
            value={secondaryTaxJurisdiction ? Country[secondaryTaxJurisdiction] : ''}
            isGapless
          />
        </div>
        <div className="form-row__column" />
      </div>
    </Card>
  );
};

export default TaxJurisdictions;
