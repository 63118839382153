import { groupBy } from '@app/shared/helpers';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { PwermCalculatedResultDto } from '@app/shared/models/contracts/pwerm-calculation-results-dto';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';

type PwermCalculatedResultDtoWithMultipleValue = Omit<
  PwermCalculatedResultDto,
  'name' | 'multipleId' | 'forecastId'
> & {
  multipleValue: number | null;
  forecastYear: string | null;
};

function getForecastYearById(project: ProjectDto, forecastId: string) {
  return project.pwermInput.cases
    .flatMap((x) => x.forecasts)
    .find((x) => x.forecastId === forecastId)?.forecastYear;
}

function getMultipleProbabilityByValue(
  project: ProjectDto,
  forecastYear: string,
  multipleValue: number
) {
  const c = project.pwermInput.cases.find((x) =>
    x.forecasts.some((f) => f.forecastYear === forecastYear)
  );

  const multipleId = c?.multiples.find((x) => x.multiple === multipleValue)?.multipleId;

  return (
    project.pwermInput.cases
      .flatMap((x) => x.forecasts)
      .find((x) => x.forecastYear === forecastYear)
      ?.multipleProbabilities.find((x) => x.multipleId === multipleId)?.probability ?? 0
  );
}

function getMultipleValueById(project: ProjectDto, multipleId: string) {
  return project.pwermInput.cases
    .flatMap((x) => x.multiples)
    .find((x) => x.multipleId === multipleId)?.multiple;
}

function getForecastProbability(project: ProjectDto, caseId: string, forecastYear: string) {
  return (
    project.pwermInput.cases
      .find((x) => x.caseId === caseId)
      ?.forecasts.find((x) => x.forecastYear === forecastYear)?.probability ?? 0
  );
}

function getCaseProbability(project: ProjectDto, caseId: string) {
  return project.pwermInput.cases.find((x) => x.caseId === caseId)?.probability ?? 0;
}

function filterCalculatedResults<T extends PwermCalculatedResultDtoWithMultipleValue>(
  calculatedResults: T[],
  selectBy: {
    caseId?: string;
    forecastYear?: string;
    instrumentId?: string;
    trancheId?: string;
    multipleValue?: number;
  }
): T[] {
  return calculatedResults.filter(
    (r) =>
      (selectBy.caseId === undefined || r.caseId === undefined || r.caseId === selectBy.caseId) &&
      (selectBy.forecastYear === undefined ||
        r.forecastYear === null ||
        r.forecastYear === selectBy.forecastYear) &&
      (selectBy.instrumentId === undefined ||
        r.instrumentId === undefined ||
        r.instrumentId === selectBy.instrumentId) &&
      (selectBy.trancheId === undefined ||
        r.trancheId === undefined ||
        r.trancheId === selectBy.trancheId) &&
      (selectBy.multipleValue === undefined ||
        r.multipleValue === null ||
        r.multipleValue === selectBy.multipleValue)
  );
}

type WeightedDataDimension = 'multipleValue' | 'caseId' | 'forecastYear';
const allDimensions = ['multipleValue', 'caseId', 'forecastYear'] as WeightedDataDimension[];

function probabilityWeightBy(
  project: ProjectDto,
  res: PwermCalculatedResultDtoWithMultipleValue[],
  by: WeightedDataDimension
) {
  const otherDimensions = allDimensions.filter((x) => x !== by);

  const grouped = groupBy(
    res,
    (result) =>
      otherDimensions.reduce((val, dim) => val + result[dim], '') +
      result.instrumentId +
      result.trancheId
  );

  return Object.values(grouped).map((x) => ({
    ...x[0],
    value: x.reduce((val, res) => {
      let dimensionProbability = 0;

      switch (by) {
        case 'caseId':
          dimensionProbability =
            res.caseId !== undefined ? getCaseProbability(project, res.caseId) : 0;
          break;
        case 'forecastYear':
          dimensionProbability =
            res.forecastYear !== null && res.caseId !== undefined
              ? getForecastProbability(project, res.caseId, res.forecastYear)
              : 0;
          break;
        case 'multipleValue':
          dimensionProbability =
            res.forecastYear !== null && res.multipleValue !== null
              ? getMultipleProbabilityByValue(project, res.forecastYear, res.multipleValue)
              : 0;
          break;
      }

      return val + res.value * (dimensionProbability / 100);
    }, 0),
    [by]: null,
  }));
}

export function getCalculatedValue(
  project: ProjectDto,
  calculatedResults: PwermCalculatedResultDto[],
  name: PwermCalculatedResultDto['name'],
  weightedBy: WeightedDataDimension[] = [],
  selectBy?: {
    caseId?: string;
    forecastYear?: string;
    instrumentId?: string;
    trancheId?: string;
    multipleValue?: number;
  }
) {
  let filteredResults: PwermCalculatedResultDtoWithMultipleValue[] = calculatedResults
    .filter((x) => x.name === name)
    .map((x) => {
      const { multipleId, forecastId, ...toCopy } = x;

      return {
        ...toCopy,
        multipleValue:
          multipleId !== undefined ? getMultipleValueById(project, multipleId) ?? null : null,
        forecastYear:
          forecastId !== undefined ? getForecastYearById(project, forecastId) ?? null : null,
      };
    });

  if (selectBy !== undefined) {
    filteredResults = filterCalculatedResults(filteredResults, selectBy);
  }

  const weightingOrder: Record<WeightedDataDimension, number> = {
    multipleValue: 1,
    forecastYear: 2,
    caseId: 3,
  };

  return weightedBy
    .sort((a, b) => weightingOrder[a] - weightingOrder[b])
    .reduce((res, by) => probabilityWeightBy(project, res, by), filteredResults);
}

export function useGetCalculatedValue() {
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedProjectId = useAppSelector((state) => state.pwermCalculation.projectId);
  const calculatedResults = useAppSelector(
    (state) => state.pwermCalculation.values.calculatedProject.calculatedResults
  );

  const calculationsLoaded = project.id === calculatedProjectId;

  return {
    calculationsLoaded,
    getCalculatedValue: (
      name: PwermCalculatedResultDto['name'],
      weightedBy: WeightedDataDimension[] = [],
      selectBy?: {
        caseId?: string;
        forecastYear?: string;
        instrumentId?: string;
        trancheId?: string;
        multipleValue?: number;
      }
    ) =>
      calculationsLoaded
        ? getCalculatedValue(project, calculatedResults, name, weightedBy, selectBy)
        : [], // Do not show or try to show values from other project calculations
  };
}
