import {
  getCalculatedValueForForecast,
  getHistoricalEV,
  getMultipleValue,
} from '@core/store/project-slice-selectors';
import { useAppSelector } from '@core/hooks/redux-hooks';
import {
  getCaseProbabilityWeightedValue,
  getForecastProbabilityWeightedValue,
  getMultipleProbabilityWeightedValue,
} from '@app/shared/helpers/get-weighted-values';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { selectCalculatedVariablesResults } from '@app/core/store/pwerm-calculation-slice-selectors';
import { getPwermValuationValueDriver } from '@app/shared/models/helpers/project-helpers';

export const useEVGeneralMetrics = (selectedCase?: CaseDto) => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);

  const getMultipleProbabilityWeightedMultiple = ({
    caseItem,
    forecast,
  }: {
    caseItem: CaseDto;
    forecast: ForecastDto;
  }) =>
    getMultipleProbabilityWeightedValue({
      caseItem,
      forecast,
      getValue: ({ caseItem, multipleId }) => getMultipleValue({ project, caseItem, multipleId }),
    });

  const getForecastProbabilityWeightedMultiple = ({ caseItem }: { caseItem: CaseDto }) => {
    return getForecastProbabilityWeightedValue({
      caseItem,
      getValue: getMultipleProbabilityWeightedMultiple,
    });
  };
  const getForecastProbabilityWeightedExitEV = ({ caseItem }: { caseItem: CaseDto }) =>
    getForecastProbabilityWeightedValue({
      caseItem,
      getValue: ({ forecast }) =>
        getCalculatedValueForForecast(
          project,
          calculatedVariables,
          caseItem.caseId,
          forecast.forecastId,
          'Weighted EV'
        ),
    });

  const currentEV = Number(getHistoricalEV(project));

  const valuationDateValueDriver = getPwermValuationValueDriver(project).value;

  const currentValueDriverMultiple =
    Number.isFinite(currentEV) && Number.isFinite(valuationDateValueDriver)
      ? currentEV! / valuationDateValueDriver!
      : undefined;
  const multipleForecastProbabilityWeightedMultiple = selectedCase
    ? getForecastProbabilityWeightedMultiple({
        caseItem: selectedCase,
      })
    : undefined;
  const multipleForecastCaseProbabilityWeightedMultiple = getCaseProbabilityWeightedValue({
    project,
    getValue: getForecastProbabilityWeightedMultiple,
  });
  const multipleForecastProbabilityWeightedExitEV = selectedCase
    ? getForecastProbabilityWeightedExitEV({
        caseItem: selectedCase,
      })
    : undefined;
  const multipleForecastCaseProbabilityWeightedExitEV = getCaseProbabilityWeightedValue({
    project,
    getValue: getForecastProbabilityWeightedExitEV,
  });
  const getCaseCAGR = (caseItem?: CaseDto) => {
    if (!caseItem) {
      return undefined;
    }

    const forecastYearsCount = caseItem.forecasts.length;
    const lastForecast = caseItem.forecasts[forecastYearsCount - 1];
    const futureEV = getCalculatedValueForForecast(
      project,
      calculatedVariables,
      caseItem.caseId,
      lastForecast.forecastId,
      'Weighted EV'
    );
    if (!(Number.isFinite(Number(futureEV)) && currentEV)) {
      return undefined;
    }

    return (Math.pow(futureEV / currentEV!, 1 / forecastYearsCount) - 1) * 100;
  };
  const selectedCaseCAGR = getCaseCAGR(selectedCase);
  const caseProbabilityWeightedCAGR = getCaseProbabilityWeightedValue({
    project,
    getValue: ({ caseItem }) => getCaseCAGR(caseItem),
  });

  return {
    currentEV,
    currentValueDriverMultiple,
    multipleForecastProbabilityWeightedMultiple,
    multipleForecastCaseProbabilityWeightedMultiple,
    multipleForecastProbabilityWeightedExitEV,
    multipleForecastCaseProbabilityWeightedExitEV,
    selectedCaseCAGR,
    caseProbabilityWeightedCAGR,
  };
};
