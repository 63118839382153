import { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M21.12 10.256L12.864 1.97598C12.72 1.83198 12.648 1.65598 12.648 1.44798C12.648 1.23998 12.72 1.06398 12.864 0.91998C13.008 0.77598 13.184 0.703979 13.392 0.703979C13.6 0.703979 13.776 0.77598 13.92 0.91998L24 11L13.92 21.08C13.776 21.224 13.6 21.296 13.392 21.296C13.184 21.296 13.008 21.224 12.864 21.08C12.72 20.936 12.648 20.76 12.648 20.552C12.648 20.344 12.72 20.168 12.864 20.024L21.12 11.744H0.744C0.536 11.744 0.36 11.672 0.216 11.528C0.072 11.384 0 11.208 0 11C0 10.792 0.072 10.616 0.216 10.472C0.36 10.328 0.536 10.256 0.744 10.256H21.12Z"
      fill="#D04A02"
    />
  </svg>
);
export default SvgArrowRight;
