import { FC, Fragment, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  monthDayCellViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import classNames from 'classnames';
import {
  getCalculatedProjectValueInPercent,
  selectCalculatedVariablesResults,
  selectTimesToExit,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { ForecastDto } from '@app/shared/models/contracts/project-dto';
import { getCalculatedValueForForecast } from '@app/core/store/project-slice-selectors';
import { getHistoricalEV } from '@core/store/project-slice-selectors';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { useGetCalculatedProjectValue } from '@app/shared/helpers';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { Navigate, generatePath } from 'react-router';
import { useLocale } from '@app/core/hooks/useLocale';
import { setCostOfEquitySelectedTab } from '@app/core/store/ui-values-slice';

interface GetRefProp {
  getRef?: (ref: HTMLTableElement) => void;
}

const TotalEquityCrossCheck: FC<GetRefProp> = ({ getRef }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedTimesToExit = useAppSelector(selectTimesToExit);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const { l } = useLocale();
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (tableRef.current && getRef) {
      getRef(tableRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setCostOfEquitySelectedTab(RouteConstants.TotalEquityCrossCheck));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const caseItem = getCaseWithMostForecasts(project.pwermInput.cases, true);

  const colCount = 2 + caseItem.forecasts.length;

  const renderRow = ({
    title,
    renderCellValue,
    renderAdditionalCellValue,
    extraClassNames,
    additionalTitleClassNames,
  }: {
    title: string;
    renderCellValue: (forecast: ForecastDto) => JSX.Element;
    renderAdditionalCellValue?: (forecast: ForecastDto) => JSX.Element;
    extraClassNames?: string;
    additionalTitleClassNames?: string;
  }) => (
    <tr>
      <th
        className={classNames(
          'table-primary__cell--vertical-separator',
          extraClassNames,
          additionalTitleClassNames
        )}>
        {title}
      </th>
      {caseItem?.forecasts.map((forecast, index) => (
        <Fragment key={caseItem.caseId + forecast.forecastId}>
          {index === 0 ? (
            renderAdditionalCellValue ? (
              <td
                className={classNames('table-primary__cell--vertical-separator', extraClassNames)}>
                {renderAdditionalCellValue(forecast)}
              </td>
            ) : (
              <td
                className={classNames('table-primary__cell--vertical-separator', extraClassNames)}
              />
            )
          ) : undefined}
          <td
            className={classNames(extraClassNames, {
              'table-primary__cell--vertical-separator': index === caseItem.forecasts.length - 1,
            })}>
            {renderCellValue(forecast)}
          </td>
        </Fragment>
      ))}
    </tr>
  );

  const exitDateRow = renderRow({
    title: 'Exit date',
    renderCellValue: (forecast) => (
      <CellValue value={forecast.forecastYear} {...monthDayCellViewRules} />
    ),
    renderAdditionalCellValue: () => (
      <CellValue
        value={project.valuationDate}
        {...monthDayCellViewRules}
        theme={CellValueTheme.Tertiary}
      />
    ),
  });

  return (
    <div className="main-container-padding-remove">
      <table
        ref={tableRef}
        className="table-primary table-primary--framed table-primary--zebra table-primary--title-column-nowrap table-primary--sticky-first-column">
        <colgroup>
          <col className={styles['labels-col']} />
          <col />
          {caseItem?.forecasts.map((forecast, index) => (
            <Fragment key={'colspan' + caseItem.caseId + forecast.forecastId + index}>
              <col />
            </Fragment>
          ))}
        </colgroup>
        <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
          <tr>
            <th className="table-primary__cell--vertical-separator" />
            <th className="table-primary__cell--vertical-separator" />
            <th
              colSpan={caseItem.forecasts.length}
              className="table-primary__cell--header-tertiary table-primary__cell--center table-primary__cell--vertical-separator">
              <span className={styles['case-name']}>Across Cases</span>
            </th>
          </tr>
          {exitDateRow}
          <tr>
            <th className="table-primary__cell--vertical-separator">Time to exit</th>
            {caseItem?.forecasts.map((forecast, index) => (
              <Fragment key={'time-to-exit' + caseItem.caseId + forecast.forecastId + index}>
                {index === 0 && <td className="table-primary__cell--vertical-separator" />}
                <td
                  className={classNames({
                    'table-primary__cell--vertical-separator':
                      index === caseItem.forecasts.length - 1,
                  })}>
                  <CellValue
                    value={calculatedTimesToExit?.[forecast.forecastYear]}
                    {...numberCalculatedViewRules}
                    normalize
                  />
                </td>
              </Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="table-primary__cell--header">
              {l('_EnterpriseValue')} [{l('_EV')}]
            </th>
            <td className="table-primary__cell--header" colSpan={colCount - 1} />
          </tr>
          {renderRow({
            title: 'Case weighted EV',
            renderCellValue: (forecast) => (
              <CellValue
                strong
                value={getCalculatedValueForForecast(
                  project,
                  calculatedVariables,
                  caseItem.caseId,
                  forecast.forecastId,
                  'Case weighted EV'
                )}
                {...amountRules}
              />
            ),
            renderAdditionalCellValue: (_forecast) => (
              <CellValue
                {...amountRules}
                value={getHistoricalEV(project)}
                theme={CellValueTheme.Tertiary}
              />
            ),
            additionalTitleClassNames: 'table-primary__cell--strong',
          })}
          <tr>
            <th className="table-primary__cell--vertical-separator table-primary__cell--section-separator" />
            <td className="table-primary__cell--vertical-separator table-primary__cell--section-separator" />
            {caseItem?.forecasts.map((forecast, index) => (
              <Fragment key={'colspan' + caseItem.caseId + forecast.forecastId + index}>
                <td className="table-primary__cell--section-separator" />
              </Fragment>
            ))}
          </tr>
          <tr>
            <th className="table-primary__cell--header">{l('_NetDebt')}</th>
            <td className="table-primary__cell--header" colSpan={colCount - 1} />
          </tr>
          {renderRow({
            title: 'Case weighted net debt',
            renderCellValue: (forecast) => (
              <CellValue
                value={getCalculatedValueForForecast(
                  project,
                  calculatedVariables,
                  caseItem.caseId,
                  forecast.forecastId,
                  'Case weighted net debt'
                )}
                {...amountRules}
              />
            ),
          })}
          {renderRow({
            title: 'Spot gearing',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('CaseWeightedSpotGearing', {
                    forecastId: forecast.forecastId,
                  })
                )}
              />
            ),
          })}
          {renderRow({
            title: 'Average gearing',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('CaseWeightedAverageGearing', {
                    forecastId: forecast.forecastId,
                  })
                )}
              />
            ),
            extraClassNames:
              'table-primary__cell--strong table-primary__cell--section-start-separator',
          })}
          {renderRow({
            title: 'Debt beta',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getCalculatedProjectValue('CaseWeightedDebtBeta', {
                  forecastId: forecast.forecastId,
                })}
              />
            ),
          })}
          {renderRow({
            title: 'Equity beta',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getCalculatedProjectValue('CaseWeightedEquityBeta', {
                  forecastId: forecast.forecastId,
                })}
              />
            ),
          })}
          {renderRow({
            title: 'Cost of Equity [spot]',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('CaseWeightedCostOfEquitySpot', {
                    forecastId: forecast.forecastId,
                  })
                )}
              />
            ),
            extraClassNames:
              'table-primary__cell--strong table-primary__cell--section-start-separator',
          })}
          {renderRow({
            title: 'Discount factor [point]',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getCalculatedProjectValue('CaseWeightedDiscountFactorPoint', {
                  forecastId: forecast.forecastId,
                })}
              />
            ),
          })}
          {renderRow({
            title: 'Discount factor [cumulative]',
            renderCellValue: (forecast) => (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={getCalculatedProjectValue('CaseWeightedDiscountFactorCumulative', {
                  forecastId: forecast.forecastId,
                })}
              />
            ),
          })}
          {renderRow({
            title: 'Cost of Equity [cumulative]',
            renderCellValue: (forecast) => (
              <CellValue
                {...enteredPercentageViewRules}
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('CaseWeightedCostOfEquityCumulative', {
                    forecastId: forecast.forecastId,
                  })
                )}
              />
            ),
            extraClassNames:
              'table-primary__cell--strong table-primary__cell--section-start-separator',
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TotalEquityCrossCheck;
