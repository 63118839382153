import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  greaterDateThanValidator,
  greaterOrEqualDateThanValidator,
  maxLengthValidator,
  requiredValidator,
  uniqueEventNarrative,
} from '@app/core/validations/hook-forms/validators';
import FormCheckbox from '@app/shared/components/form-controls/form-checkbox/FormCheckbox';
import FormDatePicker from '@app/shared/components/form-controls/form-date-picker/FormDatePicker';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { FC } from 'react';

const NewEventFormElements: FC = (): JSX.Element => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const maxEventNameLength = useAppSelector(
    (state) => state.uiValues.maxRefinancingEventNameLength
  );
  const maxYearsBetweenEventsAndValuation = useAppSelector(
    (state) => state.uiValues.maxYearsBetweenEventsAndValuation
  );
  const options = project.pwermInput.cases.map((cases) => ({
    viewValue: cases.narrative,
    value: cases.caseId,
  }));
  const events = project.capitalStructures[project.pwermInput.cases[0].capitalStructureId].events;
  const isOpmOnly = project.details.calcMethod === CalcMethod.OPM;
  const earliestAllowedEventDate = new Date(project.valuationDate).addYears(
    -maxYearsBetweenEventsAndValuation
  );

  return (
    <>
      <FormField
        name="narrative"
        label={l('_EventNarrative')}
        placeholder={l('_Narrative')}
        {...textFieldFormattingProps}
        rules={{
          ...requiredValidator,
          ...uniqueEventNarrative(events),
          ...maxLengthValidator(maxEventNameLength),
        }}
      />
      <FormDatePicker
        data-testid="eventDate"
        name="eventDate"
        label={l('_EventDate')}
        rules={{
          ...requiredValidator,
          validate: {
            greaterDateThanInvestmentDate: greaterDateThanValidator(
              project.investmentDate,
              l('_EventDateMustBeGreaterThanInvestmentDate')
            ).validate,
            notTooFarBeforeValuationDate: greaterOrEqualDateThanValidator(
              earliestAllowedEventDate.toISOString(),
              l('_EventDateCannotBeMoreThanXYearsBeforeValuationDate', {
                maxYearsBetweenEventsAndValuation: maxYearsBetweenEventsAndValuation,
              })
            ).validate,
          },
        }}
      />
      {!isOpmOnly && (
        <FormCheckbox
          name="selectedCases"
          label={l('_SelectCases')}
          rules={{
            ...requiredValidator,
          }}
          options={options}
        />
      )}
    </>
  );
};

export default NewEventFormElements;
