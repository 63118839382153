import { SVGProps } from 'react';
const SvgTabArrowActive = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M9.25 5 5.5 1 1.75 5"
      stroke="#252525"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgTabArrowActive;
