import * as notificationActions from '@app/core/store/notification-slice';
import * as PwermCalculationActions from '@app/core/store/pwerm-calculation-slice';
import * as Pwerm2CalculationActions from '@app/core/store/pwerm2-calculation-slice';
import * as CapitalStructureActions from '@app/core/store/capital-structure-slice';
import * as OpmCalculationActions from '@app/core/store/opm-calculation-slice';
import { opmCalculationSlice } from '@app/core/store/opm-calculation-slice';
import { LoadingStatus } from '@app/shared/models/loading-status-enum';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from '@server/api-config';
import env from '@environment';
import { AppDispatch, RootState } from './store';
import { ErrorMessageModel } from '../utils/error-handling';
import { SeverityType } from '@app/shared/mui-components/alert/AlertTypes';
import equal from 'fast-deep-equal/es6/react';
import { enumKeyByValue } from '@app/shared/helpers';
import {
  CalcMethod,
  EventKey,
  ProjectStatus,
} from '@app/shared/models/contracts/enums/shared-enums';
import { setIsSaving, uiValuesSlice } from '@core/store/ui-values-slice';
import { ProjectAccessUserDto } from '@app/shared/models/contracts/project-access-user-dto';
import { OpmRequiredProjectInputs } from '@app/shared/models/contracts/opm-required-project-inputs';
import { CapitalStructureRequiredInputs } from '@app/shared/models/contracts/capital-structure-required-inputs';
import { updateProjectOpmInputs } from '@app/core/inputs/updateProjectOpmInputs';
import { CalculationResultsDto } from '@app/shared/models/contracts/calculation-results-dto';
import { processLastInQueue, taskQueue } from '../utils/task-queue';
import {
  isBenchmarkingOnlyProject,
  setBenchmarkingCalculatedValuesToNull,
} from '@app/modules/benchmarking/benchmarking-helpers';
import { saveProject } from '../signalr/signalRHubConnection';
import { DevFeature, isDevFeatureEnabled } from '../utils/dev-feature';
import { missingDlomElements, setDlomElementsToNull } from '@app/modules/dlom/dlom-helpers';
import { createOpmOnlyEventSet } from '../inputs/updateProjectCapitalStructures';

export interface ProjectState {
  status: LoadingStatus;
  project: ProjectDto;
  projectDraft: ProjectDto;
  opmNeedsRecalculated: boolean;
  isOpmValid: boolean;
  originalOpmProjectState: OpmRequiredProjectInputs;
  originalCapitalStructureInputs: CapitalStructureRequiredInputs;
}

export const fetchProjectDraftById = createAsyncThunk<
  ProjectDto,
  number,
  { state: RootState; rejectValue: ErrorMessageModel; dispatch: AppDispatch }
>('project/fetchProjectDraftById', async (id: number, thunkAPI) => {
  const response = await api.get<ProjectDto>(`${env.apiUrl}/projects/${id}`);
  await thunkAPI.dispatch(uiValuesSlice.actions.setIsLoggedIn());
  return response.data;
});

export const fetchProjectUserList = createAsyncThunk<
  ProjectAccessUserDto[],
  number,
  { state: RootState; rejectValue: ErrorMessageModel; dispatch: AppDispatch }
>('project/fetchProjectUserListById', async (id: number) => {
  const response = await api.get<ProjectAccessUserDto[]>(`${env.apiUrl}/projects/${id}/users`);
  return response.data;
});

export const fetchProjectResults = createAsyncThunk<
  void,
  number,
  { state: RootState; rejectValue: ErrorMessageModel; dispatch: AppDispatch }
>('project/fetchResults', async (id: number, thunkAPI) => {
  const response = await api.get<CalculationResultsDto>(`${env.apiUrl}/projects/${id}/results`);

  // add other results handlers in here as needed
  thunkAPI.dispatch(OpmCalculationActions.loadOpmResults(response.data));
});

export const projectHeartBeat = createAsyncThunk<
  string[],
  { projectId: number },
  { state: RootState; rejectValue: ErrorMessageModel; dispatch: AppDispatch }
>('project/getHeartBeat', async ({ projectId }, _) => {
  const response = await api.post<{ viewers: string[] }>(
    `${env.apiUrl}/projects/${projectId}/viewers/heartbeat`
  );
  return response.data.viewers;
});

export const makeUserInactiveInProjectScope = createAsyncThunk<
  void,
  { projectId: number },
  { state: RootState; rejectValue: ErrorMessageModel; dispatch: AppDispatch }
>('project/removeFromActiveUsersInAProjectList', async ({ projectId }, _) => {
  const response = await api.delete<void>(`${env.apiUrl}/projects/${projectId}/viewers/me`);
  return response.data;
});

export const saveExistingProject = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('project/saveExistingProject', async (_, thunkAPI) => {
  let projectDraft = thunkAPI.getState().project.projectDraft;
  const userId = thunkAPI.getState().userInfo.userInfo.id;
  if (projectDraft.details.calcMethod !== CalcMethod.PWERM && !projectDraft.opmInput.randomSeed) {
    projectDraft = {
      ...projectDraft,
      opmInput: {
        ...projectDraft.opmInput,
        // eslint-disable-next-line big-number-rules/rounding
        randomSeed: Math.floor(Math.random() * 10000),
      },
    };
  }
  thunkAPI.dispatch(setIsSaving(true));

  saveProject(projectDraft.id, userId, projectDraft);
});

export const saveComplete = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('project/saveComplete', async (_, thunkAPI) => {
  const projectDraft = thunkAPI.getState().project.projectDraft;
  thunkAPI.dispatch(
    notificationActions.showNotificationSnackbar({
      message: 'Project successfully saved.',
      severity: SeverityType.success,
      autoHide: true,
    })
  );
  await thunkAPI.dispatch(fetchAndRecalculate(projectDraft.id)).unwrap();
});

export const validateProject = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('project/validateProject', async (_, thunkAPI) => {
  const projectDraft = thunkAPI.getState().project.projectDraft;
  await api.post(
    `${env.apiUrl}/projects/${projectDraft.id}/validate`,
    JSON.stringify(projectDraft)
  );
});

export const validateOpmInputs = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('project/validateOpmInputs', async (_, thunkAPI) => {
  const projectDraft = await thunkAPI.getState().project.projectDraft;
  try {
    await api.post(`${env.apiUrl}/validate/opm`, JSON.stringify(projectDraft));
    thunkAPI.dispatch(projectSlice.actions.updateIsOpmValid(true));
  } catch {
    thunkAPI.dispatch(projectSlice.actions.updateIsOpmValid(false));
  }
});

export const deleteExistingProject = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('project/deleteExistingProject', async (_, thunkAPI) => {
  const projectDraft = thunkAPI.getState().project.projectDraft;
  await api.delete(`${env.apiUrl}/projects/${projectDraft.id}`);
  await thunkAPI.dispatch(discardChanges());
  thunkAPI.dispatch(
    notificationActions.showNotificationSnackbar({
      message: 'Project successfully deleted.',
      severity: SeverityType.success,
      autoHide: true,
    })
  );
});

export const removeUsersAccessFromProject = createAsyncThunk<
  void,
  { projectId: number; removeUsers: ProjectAccessUserDto[] },
  { state: RootState; dispatch: AppDispatch }
>('project/removeUsersAccessFromProject', async ({ projectId, removeUsers }, thunkAPI) => {
  await api.post(`${env.apiUrl}/projects/${projectId}/remove-access`, removeUsers);
  thunkAPI.dispatch(
    notificationActions.showNotificationSnackbar({
      message: 'User removed from project successfully.',
      severity: SeverityType.success,
      autoHide: true,
    })
  );
});

export const giveUserAccessToProject = createAsyncThunk<
  void,
  { projectId: number; addUsers: ProjectAccessUserDto[] },
  { state: RootState; dispatch: AppDispatch }
>('project/giveUserAccessToProject', async ({ projectId, addUsers }, thunkAPI) => {
  await api.post(`${env.apiUrl}/projects/${projectId}/give-access`, addUsers);
  thunkAPI.dispatch(
    notificationActions.showNotificationSnackbar({
      message: 'User added to project successfully.',
      severity: SeverityType.success,
      autoHide: true,
    })
  );
});

export const fetchAndRecalculate = createAsyncThunk<
  void,
  number,
  { state: RootState; dispatch: AppDispatch }
>('project/fetchAndRecalculate', async (projectId: number, thunkAPI) => {
  // Temporarily removed in 28140, to be added back in 28410
  // thunkAPI.dispatch(projectSlice.actions.updateOpmNeedsRecalculated(false));
  let project = await thunkAPI.dispatch(fetchProjectDraftById(projectId)).unwrap();
  const calcMethod = thunkAPI.getState().project.projectDraft.details.calcMethod;
  const [, , id] = location.pathname.split('/');
  thunkAPI.dispatch(
    projectSlice.actions.updateCapitalStructureOriginalInputs({
      equityInstruments: project.equityInstruments,
      forecastYears: project.opmInput.forecastYears,
      yearEndDate: project.yearEndDate,
      capitalStructures: project.capitalStructures,
      investmentDate: project.investmentDate,
      valuationDate: project.valuationDate,
    })
  );

  // in some projects, the dlom properties are missing, so we set them to null
  if (missingDlomElements(project).length > 0) {
    project = setDlomElementsToNull(missingDlomElements(project), project);
    thunkAPI.dispatch(projectSlice.actions.updateProjectDraftInternal(project));
  }

  await thunkAPI.dispatch(PwermCalculationActions.calculate());
  if (isDevFeatureEnabled(DevFeature.PWERM2)) {
    await thunkAPI.dispatch(Pwerm2CalculationActions.pwerm2CalculateInputs());
    await thunkAPI.dispatch(Pwerm2CalculationActions.pwerm2Calculate());
  }

  if (Number(id) !== projectId) {
    thunkAPI.dispatch(CapitalStructureActions.resetCapitalStructureResults());
    thunkAPI.dispatch(opmCalculationSlice.actions.resetOpmResults());
  }

  thunkAPI.dispatch(CapitalStructureActions.calculateCapitalStructure());
  if (isDevFeatureEnabled(DevFeature.ERF)) {
    thunkAPI.dispatch(CapitalStructureActions.calculateCapitalStructureErf());
    await thunkAPI.dispatch(
      CapitalStructureActions.calculateBuildStructure({ ignoreErrors: true })
    );
  }

  if (calcMethod !== CalcMethod.PWERM) {
    const updatedProject = updateProjectOpmInputs(
      thunkAPI.getState().project.projectDraft,
      thunkAPI.getState().pwermCalculation.values
    );
    thunkAPI.dispatch(projectSlice.actions.updateProjectDraftInternal(updatedProject));
    thunkAPI.dispatch(validateOpmInputs());

    // at the moment, we only store results for OPM calculations
    thunkAPI.dispatch(fetchProjectResults(projectId));
  }
});

export const discardChanges = createAsyncThunk<
  void,
  void,
  { state: RootState; dispatch: AppDispatch }
>('project/discardChanges', (_, thunkAPI) => {
  thunkAPI.dispatch(
    projectSlice.actions.updateProjectDraftInternal(thunkAPI.getState().project.project)
  );
});

let cachedProjectDraft: ProjectDto | undefined;
export const updateProjectDraft = createAsyncThunk<
  void,
  { project: ProjectDto; shouldRunBuildStructure?: boolean },
  { state: RootState; dispatch: AppDispatch }
>('project/updateProjectDraft', async ({ project, shouldRunBuildStructure = true }, thunkAPI) => {
  if (
    thunkAPI.getState().project.project.status ===
    enumKeyByValue(ProjectStatus, ProjectStatus.Closed)
  ) {
    thunkAPI.dispatch(
      notificationActions.showNotificationSnackbar({
        message: `Projects with '${ProjectStatus.Closed}' status cannot be updated.`,
        severity: SeverityType.error,
        autoHide: true,
      })
    );
    throw new Error(`Projects with '${ProjectStatus.Closed}' status cannot be updated.`);
  }

  // we set the calculated values to null in the projectDraft so that they will be
  // re-calculated in the backend and always up-to-date
  if (!isBenchmarkingOnlyProject(project)) {
    project = setBenchmarkingCalculatedValuesToNull(project);
  }
  const hasProjectDraftChanged = !equal(project, cachedProjectDraft);
  if (hasProjectDraftChanged) {
    const hasCalcMethodChanged = isDevFeatureEnabled(DevFeature.ERF)
      ? project.details.calcMethod !== cachedProjectDraft?.details.calcMethod
      : false;
    const capitalStructureId = project?.pwermInput?.cases?.[0]?.capitalStructureId;
    const isErfProject =
      project.details.calcMethod === CalcMethod.OPM
        ? Object.keys(project.capitalStructures[capitalStructureId]?.eventSets ?? {}).length > 0
        : Object.keys(project.capitalStructures[capitalStructureId]?.eventSets ?? {}).filter(
            (eventSets) => eventSets !== EventKey.OpmOnly
          ).length > 0;
    const hasErfProjectCalcMethodChanged = isErfProject && hasCalcMethodChanged;

    cachedProjectDraft = project;

    // Handle impact of changing calc method on project on eventSet's and opmInput
    if (hasErfProjectCalcMethodChanged && project.details.calcMethod === CalcMethod.OPM) {
      project = createOpmOnlyEventSet(project);
    } else if (
      hasErfProjectCalcMethodChanged &&
      project.details.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)
    ) {
      project = {
        ...project,
        opmInput: {
          ...project.opmInput,
          selectedCaseId: project.pwermInput.cases[0].caseId,
          selectedEventSetId: project.pwermInput.cases[0].eventSetId
            ? project.pwermInput.cases[0].eventSetId
            : EventKey.EmptyEventSet,
        },
      };
    }

    thunkAPI.dispatch(projectSlice.actions.updateProjectDraftInternal(project));
    thunkAPI.dispatch(checkIfOpmNeedsRecalculated(project));
    taskQueue.push(async () => {
      await thunkAPI.dispatch(
        checkIfCapitalStructureNeedsRecalculation({
          project: project,
          shouldRunBuildStructure,
          hasCalcMethodChanged,
        })
      );
      await thunkAPI.dispatch(PwermCalculationActions.calculate());
      if (isDevFeatureEnabled(DevFeature.PWERM2)) {
        await thunkAPI.dispatch(Pwerm2CalculationActions.pwerm2CalculateInputs());
        await thunkAPI.dispatch(Pwerm2CalculationActions.pwerm2Calculate());
      }
      if (project.details.calcMethod !== CalcMethod.PWERM) {
        const updatedProject = updateProjectOpmInputs(
          thunkAPI.getState().project.projectDraft,
          thunkAPI.getState().pwermCalculation.values
        );
        const mergedProject: ProjectDto = {
          ...thunkAPI.getState().project.projectDraft,
          opmInput: {
            ...updatedProject.opmInput,
          },
        };
        thunkAPI.dispatch(projectSlice.actions.updateProjectDraftInternal(mergedProject));
        thunkAPI.dispatch(validateOpmInputs());
      }
    });
    await processLastInQueue();
  }
});

export const checkIfCapitalStructureNeedsRecalculation = createAsyncThunk<
  void,
  {
    project: ProjectDto;
    shouldRunBuildStructure: boolean;
    hasCalcMethodChanged: boolean;
  },
  { state: RootState; dispatch: AppDispatch }
>(
  'project/checkIfCapitalStructureNeedsRecalculation',
  async ({ project, shouldRunBuildStructure, hasCalcMethodChanged = false }, thunkAPI) => {
    const capitalStructureInputs = createCapitalStructureInputObject(project);
    if (
      !equal(capitalStructureInputs, thunkAPI.getState().project.originalCapitalStructureInputs) ||
      hasCalcMethodChanged
    ) {
      thunkAPI.dispatch(
        projectSlice.actions.updateCapitalStructureOriginalInputs(capitalStructureInputs)
      );
      thunkAPI.dispatch(CapitalStructureActions.calculateCapitalStructure());
      if (isDevFeatureEnabled(DevFeature.ERF)) {
        thunkAPI.dispatch(CapitalStructureActions.calculateCapitalStructureErf());
        shouldRunBuildStructure &&
          (await thunkAPI.dispatch(
            CapitalStructureActions.calculateBuildStructure({
              ignoreErrors: true,
              updateInternally: true,
            })
          ));
      }
    }
  }
);

export const checkIfOpmNeedsRecalculated = createAsyncThunk<
  void,
  ProjectDto,
  { state: RootState; dispatch: AppDispatch }
>('project/checkIfOpmNeedsRecalculated', async (project, thunkAPI) => {
  thunkAPI.dispatch(projectSlice.actions.updateOpmNeedsRecalculated(true));
  // Temporarily removed in 28140, to be added back in 28410
  // const opmRequiredProjectInputs = await createOpmProjectInputObject(project);
  // if (!equal(opmRequiredProjectInputs, thunkAPI.getState().project.originalOpmProjectState)) {
  //   thunkAPI.dispatch(projectSlice.actions.updateOpmNeedsRecalculated(true));
  // } else {
  //   thunkAPI.dispatch(projectSlice.actions.updateOpmNeedsRecalculated(false));
  // }
});

export const projectSlice = createSlice({
  name: 'project',
  initialState: {
    status: LoadingStatus.Idle,
    project: {},
    projectDraft: {},
    opmNeedsRecalculated: true,
  } as ProjectState,
  reducers: {
    updateProjectDraftInternal: (state, action: PayloadAction<ProjectDto>) => {
      state.projectDraft = action.payload;
    },
    updateOpmNeedsRecalculated: (state, action: PayloadAction<boolean>) => {
      state.opmNeedsRecalculated = action.payload;
    },
    setOriginalOpmProjectState: (state, action: PayloadAction<OpmRequiredProjectInputs>) => {
      state.originalOpmProjectState = action.payload;
    },
    updateIsOpmValid: (state, action: PayloadAction<boolean>) => {
      state.isOpmValid = action.payload;
    },
    updateCapitalStructureOriginalInputs: (
      state,
      action: PayloadAction<CapitalStructureRequiredInputs>
    ) => {
      state.originalCapitalStructureInputs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectDraftById.pending, (state, _) => {
        state.status = LoadingStatus.Loading;
      })
      .addCase(fetchProjectDraftById.fulfilled, (state, action) => {
        state.projectDraft = {
          ...action.payload,
          pwermInput: {
            ...action.payload.pwermInput,
            cases: [
              ...action.payload.pwermInput.cases.map((_case) => ({
                ..._case,
                forecasts: _case.forecasts.sort((a, b) =>
                  a.forecastYear.localeCompare(b.forecastYear)
                ),
                multiples: _case.multiples.sort((a, b) => Number(a.multiple) - Number(b.multiple)),
              })),
            ],
          },
          equityInstruments: [
            ...action.payload.equityInstruments.map((instrument) => {
              return {
                ...instrument,
                tranches:
                  instrument?.tranches !== null
                    ? instrument?.tranches
                        ?.map((tranche) => ({
                          ...tranche,
                          statements: tranche.statements.sort(
                            (a, b) => Number(a.order) - Number(b.order)
                          ),
                        }))
                        .sort((a, b) => Number(a.order) - Number(b.order))
                    : null,
              };
            }),
          ],
        };
        state.project = state.projectDraft;
        state.status = LoadingStatus.Idle;
        cachedProjectDraft = state.projectDraft;
      })
      .addCase(fetchProjectDraftById.rejected, (state, _) => {
        state.status = LoadingStatus.Failed;
      });
  },
});

export default projectSlice.reducer;

export const createOpmProjectInputObject = (project: ProjectDto) => {
  const opmProjectInputObject: OpmRequiredProjectInputs = {
    valuationDate: project.valuationDate,
    yearEndDate: project.yearEndDate,
    investmentDate: project.investmentDate,
    denomination: project.details.denomination,
    equityInstruments: project.equityInstruments,
    opmInput: project.opmInput,
  };
  return opmProjectInputObject;
};

export const createCapitalStructureInputObject = (project: ProjectDto) => {
  const capitalStructureDebtInstruments: CapitalStructureRequiredInputs = {
    equityInstruments: project.equityInstruments,
    forecastYears: project.opmInput.forecastYears,
    yearEndDate: project.yearEndDate,
    capitalStructures: project.capitalStructures,
    investmentDate: project.investmentDate,
    valuationDate: project.valuationDate,
  };
  return capitalStructureDebtInstruments;
};
