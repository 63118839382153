import { enumKeyByValue } from '@app/shared/helpers';
import { OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import { getTotalOfEquityByInstrumentTypeAndOwnership } from '../store/project-slice-selectors';

export const getOpmInputsFromCapitalStructure = (project: ProjectDto) => {
  const getEvOrEquityValueFromCapitalStructure = () => {
    const isEquitySimulation =
      project.opmInput.opmSimulatedValue ===
      enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.Equity);

    if (isEquitySimulation) {
      if (
        project.sourcesAndUses.updatedSpotEquity &&
        project.sourcesAndUses.updatedSpotEquity > 0
      ) {
        return project.sourcesAndUses.updatedSpotEquity;
      }

      return getTotalOfEquityByInstrumentTypeAndOwnership(project.equityInstruments) ?? 0;
    }

    if (project.sourcesAndUses.currentValuation && project.sourcesAndUses.currentValuation > 0) {
      return project.sourcesAndUses.currentValuation;
    }

    return project.sourcesAndUses.dayOneTransactionPurchasePrice ?? 0;
  };

  return {
    getEvOrEquityValueFromCapitalStructure,
  };
};
