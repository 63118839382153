import { FC, ReactNode } from 'react';
import styles from './field-label.module.scss';
import classNames from 'classnames';

const PARENT_CLASSNAME = 'field-label';

interface FieldLabelProps {
  children: ReactNode;
  name?: string;
  required?: boolean;
  inlineLabel?: boolean;
  isModalHeaderField?: boolean;
}

const FieldLabel: FC<FieldLabelProps> = ({
  children,
  name,
  required,
  inlineLabel = false,
  isModalHeaderField = false,
}): JSX.Element => {
  return (
    <label
      className={classNames(styles[`${PARENT_CLASSNAME}`], {
        [styles[`${PARENT_CLASSNAME}--inline-label`]]: inlineLabel && !isModalHeaderField,
        [styles[`${PARENT_CLASSNAME}--modal-header-inline-label`]]: isModalHeaderField,
      })}
      htmlFor={name}>
      {children}
      {required && <sup className={styles[`${PARENT_CLASSNAME}__indicator`]}>*</sup>}
    </label>
  );
};

export default FieldLabel;
