import styles from './loader.module.scss';
import { FC } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { LinearProgress } from '@mui/material';
import SvgCheck from '@app/shared/icons/Check';

const PARENT_CLASSNAME = 'loader';

export const ProgressLoader: FC = () => {
  const operationProgress = useAppSelector((state) => state.uiValues.operationProgress);
  const { progress, description, steps } = operationProgress ?? {
    progress: 0,
    description: '',
  };

  const formatProgressDetails = (details: string) => {
    if (typeof details === 'string' && details.includes('/') && !details.includes('Year')) {
      const [numerator, denominator] = details.split('/');
      const formattedNumerator = Number(numerator).toLocaleString();
      const formattedDenominator = Number(denominator).toLocaleString();
      return `${formattedNumerator} / ${formattedDenominator}`;
    }
    return details;
  };

  return (
    <>
      <div className={styles[`${PARENT_CLASSNAME}--progress`]}>
        <h3 className={styles[`${PARENT_CLASSNAME}--progress--title`]}>{description}</h3>
        {description && (
          <LinearProgress
            variant="determinate"
            value={progress}
            className={styles[`${PARENT_CLASSNAME}--progress--linear`]}
          />
        )}
        <div className={styles[`${PARENT_CLASSNAME}--progress--steps`]}>
          {steps?.map((s, i, arr) => {
            const isPreviousStepComplete = i === 0 || arr[i - 1].complete;
            const isCurrentStepInProgress = !s.complete && isPreviousStepComplete;
            const colorClass = isCurrentStepInProgress
              ? styles[`${PARENT_CLASSNAME}--in--progress--text`]
              : styles[`${PARENT_CLASSNAME}--not--in--progress--text`];

            return (
              <div className={styles[`${PARENT_CLASSNAME}--individual--step`]} key={s.description}>
                <div
                  className={
                    s.complete
                      ? styles[`${PARENT_CLASSNAME}--check--icon--visible`]
                      : styles[`${PARENT_CLASSNAME}--check--icon--hidden`]
                  }>
                  <div className={styles[`${PARENT_CLASSNAME}--check--icon`]}>
                    <SvgCheck />
                  </div>
                </div>
                <p className={colorClass}>
                  {s.description}
                  <span className={styles[`${PARENT_CLASSNAME}--progress--details--text`]}>
                    {formatProgressDetails(s.details)}
                  </span>
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
