import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../../locale/setupI18n';

// This is basically just a wrapper around the react-i18next hook but with added "type" safety.
export const useLocale = (): { l: typeof localise; i18n: typeof i18n } => {
  const { t, i18n } = useTranslation();

  const localise = (keys: translationKeys | translationKeys[], opts?: any): any => {
    if (opts === undefined) {
      return t(keys);
    } else {
      return t(keys, opts);
    }
  };

  return {
    l: localise,
    i18n,
  };
};
