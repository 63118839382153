import { FC, Fragment } from 'react';
import TableSectionValuesPerForecast from '../../../components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  decimalCalculatedViewRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getCalculatedValueForForecast,
  selectValuedInstruments,
} from '@app/core/store/project-slice-selectors';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  getCalculatedProjectValueInPercent,
  selectCalculatedVariablesResults,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import {
  enumKeyByValue,
  instrumentByTypeOrTranchesOrDefaultSortFn,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import {
  InstrumentType,
  PredefinedVariableNames,
  WaterfallSections,
} from '@app/shared/models/contracts/enums/shared-enums';
import { EquityInstrumentDto, TrancheDto } from '@app/shared/models/contracts/project-dto';
import {
  getDlomIndexByInstrumentId,
  getDlomIndexByTrancheId,
} from '@app/shared/helpers/equity-instruments/get-equity-instrument-index';
import TableSectionValuesPerCase from '../../../components/TableSectionValuesPerCase';
import { Alignment } from '@app/shared/enums/alignment.enum';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';

export const WaterfallValuationPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);
  const valuedInstruments = useAppSelector(selectValuedInstruments);

  const ordinaryEquityKey = enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity);
  const [isValuationOpen, setIsValuationOpen] = useToggler();

  const renderValuationSection = (
    instrument: EquityInstrumentDto,
    tranche: TrancheDto | undefined,
    trancheIndex: number | undefined
  ) => (
    <>
      <TableSectionValuesPerForecast
        title="Weighted proceeds"
        additionalTitleClassNames="table-primary__cell--strong"
        renderSpacer={false}
        highlight
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            className="table-primary__cell--strong"
            {...amountRules}
            value={
              tranche
                ? getCalculatedProjectValue('TrancheWeightedAveragePaidDistributions', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                    trancheId: tranche.id,
                  })
                : getCalculatedProjectValue('InstrumentWeightedAveragePaidDistributions', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                  })
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Post IPO DLOM"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedValueForForecast(
              project,
              calculatedVariables,
              caseItem.caseId,
              forecast.forecastId,
              PredefinedVariableNames.ProbabilityWeightedDLOMForPostIPOSaleRestrictions
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Net exit weighted proceeds"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={
              tranche
                ? getCalculatedProjectValue('NetExitWeightedProceedsPerTranche', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                    trancheId: tranche.id,
                  })
                : getCalculatedProjectValue('NetExitWeightedProceedsPerInstrument', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                  })
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={instrument.type === ordinaryEquityKey ? 'Cost of equity' : 'Discount rate'}
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={
              instrument.type === ordinaryEquityKey
                ? getCalculatedProjectValueInPercent(
                    getCalculatedProjectValue('CostOfEquityCumulative', {
                      caseId: caseItem.caseId,
                      forecastId: forecast.forecastId,
                      instrumentId: instrument.instrumentId,
                      trancheId: tranche?.id,
                    })
                  )
                : getCalculatedProjectValueInPercent(
                    getCalculatedProjectValue('ValuationDiscountRate', {
                      caseId: caseItem.caseId,
                      forecastId: forecast.forecastId,
                      instrumentId: instrument.instrumentId,
                    })
                  )
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Discount factor"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...decimalCalculatedViewRules}
            value={
              tranche
                ? getCalculatedProjectValue('ValuationDiscountFactorPerTranche', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                    trancheId: tranche?.id,
                  })
                : getCalculatedProjectValue('ValuationDiscountFactorPerInstrument', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                  })
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={`PV of ${instrument.instrumentNarrative}${
          tranche ? ` Tranche ${trancheIndex}` : ''
        }`}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={
              tranche
                ? getCalculatedProjectValue('ValuationPresentValuePerTranche', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                    trancheId: tranche?.id,
                  })
                : getCalculatedProjectValue('ValuationPresentValuePerInstrument', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                  })
            }
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Minority discounts"
        renderSpacer={false}
        additionalTitleClassNames="table-primary__cell--strong"
        renderCell={() => <CellValue value={' '} />}
      />
      {instrument.type === ordinaryEquityKey && (
        <TableSectionValuesPerForecast
          title="DLOC"
          renderSpacer={false}
          renderCell={() => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(getCalculatedProjectValue('DLOC'))}
            />
          )}
        />
      )}
      <TableSectionValuesPerForecast
        title="DLOM"
        renderCell={({ forecast }) => {
          const dlomIndex = tranche
            ? getDlomIndexByTrancheId(forecast, tranche)
            : getDlomIndexByInstrumentId(forecast, instrument);
          const dlom = forecast.dlom[dlomIndex]?.value;
          return <CellValue {...enteredPercentageViewRules} value={dlom} />;
        }}
      />
      <TableSectionValuesPerForecast
        title="Post discount PV"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={
              tranche
                ? getCalculatedProjectValue('ValuationPostDiscountPresentValuePerTranche', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                    trancheId: tranche?.id,
                  })
                : getCalculatedProjectValue('ValuationPostDiscountPresentValuePerInstrument', {
                    caseId: caseItem.caseId,
                    forecastId: forecast.forecastId,
                    instrumentId: instrument.instrumentId,
                  })
            }
          />
        )}
      />
      <TableSectionValuesPerCase
        title={`MV of ${instrument.instrumentNarrative}${
          tranche ? ` Tranche ${trancheIndex}` : ''
        }`}
        renderSpacer={false}
        renderCell={({ caseItem }) => {
          return (
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={
                tranche
                  ? getCalculatedProjectValue('ForecastWeightedValuationMarketValuePerTranche', {
                      caseId: caseItem.caseId,
                      instrumentId: instrument.instrumentId,
                      trancheId: tranche?.id,
                    })
                  : getCalculatedProjectValue('ForecastWeightedValuationMarketValuePerInstrument', {
                      caseId: caseItem.caseId,
                      instrumentId: instrument.instrumentId,
                    })
              }
            />
          );
        }}
        additionalTitleClassNames="table-primary__cell--strong"
        additionalCellClassNames="table-primary__cell--strong"
      />
      <tr>
        <th className="table-primary__cell--vertical-separator table-primary__cell--background-secondary table-primary__cell--strong">
          Weighted average market value
        </th>
        <td className="table-primary__cell--vertical-separator table-primary__cell--background-secondary" />
        <td colSpan={columnsCount - 2} className="table-primary__cell--background-secondary">
          <CellValue
            {...amountRules}
            alignment={Alignment.Center}
            strong
            value={
              tranche
                ? getCalculatedProjectValue('CaseWeightedAverageValuationMarketValuePerTranche', {
                    instrumentId: instrument.instrumentId,
                    trancheId: tranche?.id,
                  })
                : getCalculatedProjectValue(
                    'CaseWeightedAverageValuationMarketValuePerInstrument',
                    {
                      instrumentId: instrument.instrumentId,
                    }
                  )
            }
          />
        </td>
      </tr>
    </>
  );

  const renderValuationInstrumentTotalSection = (instrument: EquityInstrumentDto) => (
    <>
      <tr>
        <td colSpan={columnsCount} className="table-primary__cell--section-separator" />
      </tr>
      <tr>
        <th className="table-primary__cell--header-tertiary">
          Total value of {instrument.instrumentNarrative}
        </th>
        <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
      </tr>
      <TableSectionValuesPerForecast
        title="Total proceeds across tranches"
        additionalTitleClassNames="table-primary__cell--strong"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            className="table-primary__cell--strong"
            {...amountRules}
            value={getCalculatedProjectValue('InstrumentWeightedAveragePaidDistributions', {
              caseId: caseItem.caseId,
              forecastId: forecast.forecastId,
              instrumentId: instrument.instrumentId,
            })}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Post IPO DLOM"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedValueForForecast(
              project,
              calculatedVariables,
              caseItem.caseId,
              forecast.forecastId,
              PredefinedVariableNames.ProbabilityWeightedDLOMForPostIPOSaleRestrictions
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Net exit weighted proceeds"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={getCalculatedProjectValue('NetExitWeightedProceedsPerInstrument', {
              caseId: caseItem.caseId,
              forecastId: forecast.forecastId,
              instrumentId: instrument.instrumentId,
            })}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Implied discount rate across tranches"
        renderSpacer={false}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(
              getCalculatedProjectValue('ValuationImpliedDiscountRateAcrossTranches', {
                caseId: caseItem.caseId,
                forecastId: forecast.forecastId,
                instrumentId: instrument.instrumentId,
              })
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={`PV of ${instrument.instrumentNarrative}`}
        renderCell={({ forecast, caseItem }) => (
          <CellValue
            {...amountRules}
            value={getCalculatedProjectValue('ValuationPresentValuePerInstrument', {
              caseId: caseItem.caseId,
              forecastId: forecast.forecastId,
              instrumentId: instrument.instrumentId,
            })}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Implied minority discounts"
        renderSpacer={false}
        additionalTitleClassNames="table-primary__cell--strong"
        renderCell={() => <CellValue value={' '} />}
      />
      <TableSectionValuesPerForecast
        title="DLOC"
        renderSpacer={false}
        renderCell={() => (
          <CellValue
            {...enteredPercentageViewRules}
            value={getCalculatedProjectValueInPercent(getCalculatedProjectValue('DLOC'))}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="DLOM"
        renderCell={({ forecast, caseItem }) => {
          return (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                getCalculatedProjectValue('ValuationDlom', {
                  caseId: caseItem.caseId,
                  forecastId: forecast.forecastId,
                  instrumentId: instrument.instrumentId,
                })
              )}
            />
          );
        }}
      />
      <TableSectionValuesPerCase
        renderSpacer={false}
        title={`MV of ${instrument.instrumentNarrative}`}
        renderCell={({ caseItem }) => {
          return (
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={getCalculatedProjectValue(
                'ForecastWeightedValuationMarketValuePerInstrument',
                {
                  caseId: caseItem.caseId,
                  instrumentId: instrument.instrumentId,
                }
              )}
            />
          );
        }}
        additionalTitleClassNames="table-primary__cell--strong"
        additionalCellClassNames="table-primary__cell--strong"
      />
      <tr>
        <th className="table-primary__cell--vertical-separator table-primary__cell--background-secondary table-primary__cell--strong">
          Weighted average market value
        </th>
        <td className="table-primary__cell--vertical-separator table-primary__cell--background-secondary" />
        <td colSpan={columnsCount - 2} className="table-primary__cell--background-secondary">
          <CellValue
            {...amountRules}
            strong
            alignment={Alignment.Center}
            value={getCalculatedProjectValue(
              'CaseWeightedAverageValuationMarketValuePerInstrument',
              {
                instrumentId: instrument.instrumentId,
              }
            )}
          />
        </td>
      </tr>
    </>
  );

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Valuation"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.Valuation)}
          isOpen={isValuationOpen}
          setIsOpen={setIsValuationOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isValuationOpen,
        })}>
        {valuedInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn).map((instrument) => (
          <Fragment key={`valuation-item-instrument-${instrument.instrumentId}`}>
            <tr>
              <th className="table-primary__cell--header-quaternary">
                {instrument.instrumentNarrative}
              </th>
              <th className="table-primary__cell--header-quaternary" colSpan={columnsCount - 1} />
            </tr>

            {(instrument.type !== ordinaryEquityKey ||
              (instrument.type === ordinaryEquityKey && !instrument.tranches?.length)) && (
              <>{renderValuationSection(instrument, undefined, undefined)}</>
            )}

            {Boolean(instrument.tranches?.length) && (
              <>
                {instrument.tranches!.map((tranche, index) => {
                  return (
                    <Fragment key={`valuation-item-tranche-${tranche.id}`}>
                      {index !== 0 && <TableSpacerRow />}
                      <tr>
                        <th className="table-primary__cell--header-tertiary">
                          Tranche {index + 1}
                        </th>
                        <th
                          className="table-primary__cell--header-tertiary"
                          colSpan={columnsCount - 1}
                        />
                      </tr>
                      {renderValuationSection(instrument, tranche, index + 1)}
                    </Fragment>
                  );
                })}
                {Boolean(instrument.tranches?.length && instrument.tranches?.length > 1) && (
                  <>{renderValuationInstrumentTotalSection(instrument)}</>
                )}
              </>
            )}
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--background-tertiary table-primary__cell--strong">
                Value per share
              </th>
              <td className="table-primary__cell--vertical-separator table-primary__cell--background-tertiary" />
              <td
                colSpan={columnsCount - 2}
                className="table-primary__cell--background-tertiary table-primary__cell--strong">
                <CellValue
                  {...decimalCalculatedViewRules}
                  alignment={Alignment.Center}
                  value={getCalculatedProjectValue('ValuationValuePerShare', {
                    instrumentId: instrument.instrumentId,
                  })}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={columnsCount} className="table-primary__cell--section-separator" />
            </tr>
          </Fragment>
        ))}
      </tbody>
    </>
  );
};

export default WaterfallValuationPartialTable;
