import { useRef } from 'react';

export const useSelectOnFocus = (isFocused: boolean) => {
  const inputRef = useRef<(() => void) | null>(null);
  const shouldSelect = useRef(true);

  if (isFocused && shouldSelect.current && inputRef.current !== null) {
    inputRef.current();
  }
  return {
    registerSelectAll: (selectAll: () => void) => (inputRef.current = selectAll),
    onKeyDown: () => (shouldSelect.current = false),
    onBlur: () => (shouldSelect.current = true),
  };
};
