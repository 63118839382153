import { FC } from 'react';
import classNames from 'classnames';
import styles from './field-validation-message.module.scss';
import { FieldError } from 'react-hook-form/dist/types';

interface FieldValidationMessageProps {
  error?: FieldError;
}

const FieldValidationMessage: FC<FieldValidationMessageProps> = ({ error }): JSX.Element => {
  const PARENT_CLASSNAME = 'field-validation-message';

  return (
    <>
      <div className={classNames(styles[`${PARENT_CLASSNAME}`])}>{error?.message}</div>
    </>
  );
};

export default FieldValidationMessage;
