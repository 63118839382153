import { PaletteOptions } from '@mui/material';
import styleVariables from '@styles/core/_exported-variables.module.scss';

const palette: PaletteOptions = {
  common: {
    outline: styleVariables.outline,
  },
  primary: {
    main: styleVariables.primaryColor,
  },
  secondary: {
    main: styleVariables.secondaryColor,
  },
  error: {
    main: styleVariables.errorColor,
    light: styleVariables.errorLightColor,
  },
  success: {
    main: styleVariables.successColor,
    light: styleVariables.successLightColor,
  },
  warning: {
    main: styleVariables.warningColor,
  },
  info: {
    main: styleVariables.infoColor,
    light: styleVariables.infoLightColor,
  },
  text: {
    primary: styleVariables.text2,
    text1: styleVariables.text1,
    text2: styleVariables.text2,
    text3: styleVariables.text3,
    text4: styleVariables.text4,
    text5: styleVariables.text5,
    disabled: styleVariables.text3,
  },
  background: {
    background100: styleVariables.background100,
    background200: styleVariables.background200,
    background300: styleVariables.background300,
    background400: styleVariables.background400,
    background500: styleVariables.background500,
    background600: styleVariables.background600,
    default: styleVariables.whiteColor,
  },
  grey: {
    grey100: styleVariables.grey100,
    grey200: styleVariables.grey200,
    grey300: styleVariables.grey300,
    grey400: styleVariables.grey400,
    grey500: styleVariables.grey500,
    grey600: styleVariables.grey600,
  },
  transparent: {
    fullyTransparent: 'transparent',
    primaryTransparent10: styleVariables.primaryTransparent10,
    primaryTransparent20: styleVariables.primaryTransparent20,
    secondaryTransparent10: styleVariables.secondaryTransparent10,
    secondaryTransparent20: styleVariables.secondaryTransparent20,
  },
  lights: {
    primaryLight100: styleVariables.primaryLight100,
    primaryLight200: styleVariables.primaryLight200,
    secondaryLight100: styleVariables.secondaryLight100,
    secondaryLight200: styleVariables.secondaryLight200,
    white: styleVariables.whiteColor,
  },
  darks: {
    black: styleVariables.blackColor,
    secondaryDark100: styleVariables.secondaryDark100,
    dark100: styleVariables.dark100,
  },
  brandPrimary: {
    grenadier: styleVariables.grenadier,
    primary: styleVariables.primary,
    rose: styleVariables.rose,
    thunderbird: styleVariables.thunderbird,
    lightning: styleVariables.lightning,
    forestGreen: styleVariables.forestGreen,
    fuchsia: styleVariables.fuchsia,
    teal: styleVariables.teal,
    blue: styleVariables.blue,
  },
  brandSecondary: {
    cinnabar: styleVariables.cinnabar,
    tuscany: styleVariables.tuscany,
    cherry: styleVariables.cherry,
    bronze: styleVariables.bronze,
    rouge: styleVariables.rouge,
    hippiePink: styleVariables.hippiePink,
    tosca: styleVariables.tosca,
    buccaneer: styleVariables.buccaneer,
    hippieBlue: styleVariables.hippieBlue,
    atoll: styleVariables.atoll,
    eden: styleVariables.eden,
    nileBlue: styleVariables.nileBlue,
    wisteria: styleVariables.wisteria,
    violetPurple: styleVariables.violetPurple,
    trendyPink: styleVariables.trendyPink,
    mulledWine: styleVariables.mulledWine,
    jasper: styleVariables.jasper,
    roofTerracotta: styleVariables.roofTerracotta,
    redRobin: styleVariables.redRobin,
    metallicCooper: styleVariables.metallicCooper,
    sunglow: styleVariables.sunglow,
    goldenGrass: styleVariables.goldenGrass,
    reefGold: styleVariables.reefGold,
    rawUmber: styleVariables.rawUmber,
    fruitSalad: styleVariables.fruitSalad,
    greenApple: styleVariables.greenApple,
    greenPea: styleVariables.greenPea,
    pine: styleVariables.pine,
  },
  dataVisualization: {
    sealBrown: styleVariables.sealBrown,
    brown: styleVariables.brown,
    burntOrange: styleVariables.burntOrange,
    darkOrange: styleVariables.darkOrange,
    salmon: styleVariables.salmon,
    darkCoral: styleVariables.darkCoral,
    violinBrown: styleVariables.violinBrown,
    clay: styleVariables.clay,
    tangerine: styleVariables.tangerine,
    fire: styleVariables.fire,
    latte: styleVariables.latte,
    granola: styleVariables.granola,
    medallion: styleVariables.medallion,
    merigold: styleVariables.merigold,
    honey: styleVariables.honey,
    blond: styleVariables.blond,
    ruby: styleVariables.ruby,
    crimson: styleVariables.crimson,
    redRose: styleVariables.redRose,
    fireOpal: styleVariables.fireOpal,
    babyPink: styleVariables.babyPink,
    plum: styleVariables.plum,
    englishRed: styleVariables.englishRed,
    punch: styleVariables.punch,
    pinkRose: styleVariables.pinkRose,
    flamingo: styleVariables.flamingo,
    charcoal: styleVariables.charcoal,
    shadow: styleVariables.shadow,
    fossil: styleVariables.fossil,
    cloud: styleVariables.cloud,
    flashWhite: styleVariables.flashWhite,
    violet: styleVariables.violet,
    blueViolet: styleVariables.blueViolet,
    amethyst: styleVariables.amethyst,
    iris: styleVariables.iris,
    lavender: styleVariables.lavender,
    azure: styleVariables.azure,
    cobalt: styleVariables.cobalt,
    cerulean: styleVariables.cerulean,
    sky: styleVariables.sky,
    arctic: styleVariables.arctic,
    basil: styleVariables.basil,
    emerald: styleVariables.emerald,
    shamrock: styleVariables.shamrock,
    kiwi: styleVariables.kiwi,
    lime: styleVariables.lime,
  },
  formulas: {
    bool: styleVariables.formulaBool,
    variable: styleVariables.formulaVariable,
    function: styleVariables.formulaFunction,
    paren: styleVariables.formulaParen,
    number: styleVariables.formulaNumber,
    operators: styleVariables.formulaOperators,
    matchingParen: styleVariables.formulaMatchingParen,
    nonMatchingParen: styleVariables.formulaNonMatchingParen,
  },
};

export default palette;
