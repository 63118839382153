import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import {
  OpmFirstLastWaterfallDictionaryDto,
  FirstLastTranche,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import React, { FC } from 'react';
import styles from '../../opm-summary-waterfall.module.scss';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useLocale } from '@app/core/hooks/useLocale';
import { translationKeys } from '@locale/setupI18n';
import {
  calculatedMultipleValueFormatterProps,
  numberValueFormatter,
  toPercentageUsingFormatter,
} from '@app/shared/formatters';
import { enumKeyByValue } from '@app/shared/helpers';
import { Operator, TargetMetric } from '@app/shared/models/contracts/enums/shared-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import classNames from 'classnames';

type ValueKeys = Omit<FirstLastTranche, 'conditions'>;

interface TrancheCalculationsProps {
  opmWaterfall: OpmFirstLastWaterfallDictionaryDto;
  selectedInstrument: string;
  selectedYear: string;
  selectedInstrumentViewValue: string;
}

const TrancheCalculations: FC<TrancheCalculationsProps> = ({
  opmWaterfall,
  selectedInstrument,
  selectedYear,
  selectedInstrumentViewValue,
}): JSX.Element => {
  const { l } = useLocale();
  const interpolationOption = {
    escapeValue: false,
  };

  const waterfallIterations = useAppSelector((state) => state.uiValues.opmWaterfallIterations ?? 5);
  const titleColSpan = waterfallIterations * 2 + 1;

  const createRow = (
    valueKey: keyof ValueKeys,
    labelKey: translationKeys,
    trancheIndex: number,
    upper = false,
    percentage = false
  ) => {
    const tranches = opmWaterfall[selectedYear].first[1].instruments[selectedInstrument].tranches;
    const trancheNames = Object.keys(tranches);

    if (trancheNames.length === 0 || tranches[trancheNames[trancheIndex]][valueKey] === null) {
      return null;
    }

    const tranche = tranches[trancheNames[trancheIndex]];
    const hasMultipleConditions = tranche.conditions.length > 1;

    if (valueKey === 'activeHurdle' && !hasMultipleConditions) {
      return null;
    }

    const firstValues = Object.entries(opmWaterfall[selectedYear].first).map(([key, _]) => {
      return opmWaterfall[selectedYear].first[key].instruments[selectedInstrument].tranches[
        trancheNames[trancheIndex]
      ][valueKey];
    });

    const lastValues = Object.entries(opmWaterfall[selectedYear].last).map(([key, _]) => {
      return opmWaterfall[selectedYear].last[key].instruments[selectedInstrument].tranches[
        trancheNames[trancheIndex]
      ][valueKey];
    });

    return (
      <tr
        key={`tranche-row-${trancheIndex}-${valueKey}`}
        data-testid={`${valueKey}-tranche${trancheIndex + 1}-title`}>
        <td className={classNames(styles['border-right'])}>{l(labelKey)}</td>
        {[...firstValues, ...lastValues].map((value, index) => (
          <td
            key={`value-${trancheIndex}-${index}`}
            className={classNames({
              [styles['border-right']]: index + 1 === waterfallIterations,
            })}
            data-testid={`${valueKey}-tranche${trancheIndex + 1}-simulation-${index}`}>
            <CellValue
              className={upper ? styles['upper-text'] : ''}
              value={percentage && typeof value === 'number' ? value * 100 : value}
              {...(percentage ? enteredPercentageViewRules : amountRules)}
            />
          </td>
        ))}
      </tr>
    );
  };

  return (
    <>
      {Object.entries(
        opmWaterfall[selectedYear].first[1].instruments[selectedInstrument].tranches
      ).map((data, trancheIndex) => {
        const tranche = data[1];
        const hasDloc = tranche.dlocPc !== null;

        return (
          <React.Fragment key={`tranche-${trancheIndex}`}>
            <tr>
              <th
                colSpan={titleColSpan}
                className={styles['tranche-header']}
                data-testid={`tranche-title-${trancheIndex}`}>
                {`${l('_Tranche')} ${trancheIndex + 1}`}
              </th>
            </tr>

            {createRow('sponsorInvestment', '_SponsorInvestment', trancheIndex)}

            {Object.entries(
              opmWaterfall[selectedYear].first[1].instruments[selectedInstrument].tranches
            )[trancheIndex][1].conditions.map((condition, conditionIndex) => {
              const firstValues = Object.entries(opmWaterfall[selectedYear].first).map(
                ([key, _]) => {
                  return Object.entries(
                    opmWaterfall[selectedYear].first[key].instruments[selectedInstrument].tranches
                  )[trancheIndex][1].conditions;
                }
              );
              const lastValues = Object.entries(opmWaterfall[selectedYear].last).map(([key, _]) => {
                return Object.entries(
                  opmWaterfall[selectedYear].last[key].instruments[selectedInstrument].tranches
                )[trancheIndex][1].conditions;
              });

              return (
                <tr key={`condition-${conditionIndex}`}>
                  <td
                    className={styles['border-right']}
                    data-testid={`condition${conditionIndex + 1}-tranche${trancheIndex + 1}-title`}>
                    {`${l('_SponsorHurdle')} ${condition.metric.replace('Institutional', '')}${' '}
                    ${
                      condition.operator === enumKeyByValue(Operator, Operator.GreaterThan)
                        ? '>'
                        : condition.operator === enumKeyByValue(Operator, Operator.LessThan)
                        ? '<'
                        : condition.operator ===
                          enumKeyByValue(Operator, Operator.GreaterThanOrEqual)
                        ? '>='
                        : condition.operator === enumKeyByValue(Operator, Operator.LessThanOrEqual)
                        ? '<='
                        : condition.operator
                    }${' '}
                    ${
                      condition.metric ===
                      enumKeyByValue(TargetMetric, TargetMetric.InstitutionalIRR)
                        ? toPercentageUsingFormatter(condition.testValue)
                        : numberValueFormatter({
                            value: condition.testValue,
                            ...calculatedMultipleValueFormatterProps,
                          })
                    }`}
                  </td>
                  {firstValues.map((value, index) => {
                    return (
                      <td
                        key={`condition-${conditionIndex}-${index}`}
                        className={classNames({
                          [styles['border-right']]: index + 1 === waterfallIterations,
                        })}
                        data-testid={`condition${conditionIndex + 1}-tranche${
                          trancheIndex + 1
                        }-simulation-${index}`}>
                        <CellValue
                          value={value[conditionIndex].equivalentHurdleValue}
                          alignment={Alignment.Right}
                          {...amountRules}
                        />
                      </td>
                    );
                  })}
                  {lastValues.map((value, index) => {
                    return (
                      <td
                        key={`condition-${conditionIndex}-${index}`}
                        data-testid={`condition${conditionIndex + 1}-tranche${
                          trancheIndex + 1
                        }-simulation-${index + 5}`}>
                        <CellValue
                          value={value[conditionIndex].equivalentHurdleValue}
                          alignment={Alignment.Right}
                          {...amountRules}
                        />
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            {createRow('activeHurdle', '_InstitutionHurdleActive', trancheIndex)}
            {createRow('lessSponsorDebt', '_SponsorDebt', trancheIndex)}
            {createRow('hurdleRemaining', '_HurdleRemaining', trancheIndex)}
            {createRow('grossUp', '_GrossUpPercent', trancheIndex, false, true)}
            {createRow('ordinaryEquityHurdle', '_OrdinaryEquityHurdle', trancheIndex)}
            {createRow('isHurdleMet', '_IsHurdleMet', trancheIndex, true)}
            {createRow('ordinaryEquityPostHurdle', '_OrdinaryEquityPostHurdle', trancheIndex)}
            {createRow(
              'trancheParticipationPc',
              '_PcParticipationTitle',
              trancheIndex,
              false,
              true
            )}
            {createRow('trancheDistribution', '_DistributionsPaid', trancheIndex)}
            {createRow('postIpoDlomPc', '_PostIPOLockInDiscount', trancheIndex, false, true)}
            {createRow(
              'netDistribution',
              l('_NetProceedsToInstrumentTrancheN', {
                instrument: selectedInstrumentViewValue,
                n: trancheIndex + 1,
                interpolation: interpolationOption,
              }),
              trancheIndex
            )}
            {createRow('riskFreeRate', '_RiskFreeRate', trancheIndex, false, true)}
            {createRow(
              'fairValue',
              l('_PvOfInstrumentTrancheN', {
                instrument: selectedInstrumentViewValue,
                n: trancheIndex + 1,
                interpolation: interpolationOption,
              }),
              trancheIndex
            )}
            {hasDloc && createRow('dlocPc', '_DLOC', trancheIndex, false, true)}
            {createRow('dlomPc', '_DLOM', trancheIndex, false, true)}
            {createRow(
              'discountedFairValue',
              l('_MvOfInstrumentTrancheN', {
                instrument: selectedInstrumentViewValue,
                n: trancheIndex + 1,
                interpolation: interpolationOption,
              }),
              trancheIndex
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default TrancheCalculations;
