export enum RouteConstants {
  BenchmarkingInputs = 'benchmarking-inputs',
  BuildStructure = 'build-structure',
  BuildStructureErf = 'build-structure-erf',
  CapitalStructure = 'capital-structure',
  CapmAssumptions = 'capm-assumptions',
  CapTable = 'cap-table',
  CapTable2 = 'cap-table-2',
  CapTableErf = 'cap-table-erf',
  CostOfEquity = 'cost-of-equity',
  CostOfEquity2 = 'cost-of-equity-2',
  CostOfEquityComparator = 'cost-of-equity-comparator',
  DashboardOpm = 'dashboard-opm',
  DashboardPwerm = 'dashboard-pwerm',
  DashboardPwerm2 = 'dashboard-pwerm-2',
  DealThesis = 'deal-thesis',
  DealThesis2 = 'deal-thesis-2',
  MinorityDiscounts = 'minority-discounts',
  OpmInputs = 'opm-inputs',
  ParticipationTable = 'participation-table',
  ParticipationTable2 = 'participation-table-2',
  ParticipationTableErf = 'participation-table-erf',
  PayoutLogic = 'payout-logic',
  PayoutLogicErf = 'payout-logic-erf',
  ProjectDetails = 'project-details',
  PwermWaterfall = 'waterfall-pwerm',
  Pwerm2Waterfall = 'waterfall-pwerm-2',
  PwermComparator = 'waterfall-comparator',
  RealWorldDistributions = 'real-world-distributions',
  SourcesAndUses = 'sources-and-uses',
  Summary = 'summary',
  Summary2 = 'summary-2',
  SummaryComparator = 'summary-comparator',
  TotalEquityCrossCheck = 'total-equity-cross-check',
  TotalEquityCrossCheck2 = 'total-equity-cross-check-2',
  TotalEquityCrossCheckComparator = 'total-equity-cross-check-comparator',
  ValuationInputs = 'valuation-inputs',
  ValuationSummary = 'valuation-summary',
  OpmWaterfall = 'waterfall-opm',
  FirstLastSimulations = 'first-last-simulations',
  SampleWaterfall = 'sample-waterfall',
  InstrumentById = 'instrument/:instrumentId',
  InstrumentById2 = 'instrument-2/:instrumentId',
  InstrumentByIdComparator = 'instrument-comparator/:instrumentId',
  PwermOpmConclusions = 'pwerm-opm-overall-conclusions',
}
