import SvgChevron from '@app/shared/icons/Chevron';
import { useEffect, useState } from 'react';
import styles from './valuation-conclusion.module.scss';
import { OpmValuationConclusionInstrumentResultDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import { slugifyStrings } from '@app/shared/helpers/formatting/slugify';
import {
  weightedMarketValue,
  weightedMarketValuePerShare,
  weightedMinorityDiscounts,
  weightedPresentValue,
  convertDecimalToPercentage,
} from './calculations/weightedValues';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { Alignment } from '@app/shared/enums/alignment.enum';
import {
  amountRules,
  decimalCalculatedViewRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';

interface ValuationConclusionProps {
  instrument: OpmValuationConclusionInstrumentResultDto;
  isCollapsed: boolean;
  allRowsCollapsed: boolean;
  onChildRowStateChange: (isRowCollapsed: boolean) => void;
}

const ValuationInstrument = ({
  instrument,
  isCollapsed,
  allRowsCollapsed,
  onChildRowStateChange,
}: ValuationConclusionProps): JSX.Element => {
  const [isRowCollapsed, setIsRowCollapsed] = useState<boolean>(isCollapsed);

  useEffect(() => {
    setIsRowCollapsed(isCollapsed);
  }, [isCollapsed]);

  const toggleCollapse = () => {
    const newIsRowCollapsed = !isRowCollapsed;
    setIsRowCollapsed(newIsRowCollapsed);
    onChildRowStateChange(newIsRowCollapsed);
  };

  return (
    <>
      <tr key={instrument.instrumentId} onClick={toggleCollapse}>
        <td
          className={styles['opm-instrument-header']}
          data-testid={slugifyStrings(instrument.instrumentNarrative, 'title')}>
          <div
            className={styles['expand-collapse-chevron']}
            data-testid={slugifyStrings(instrument.instrumentNarrative, 'expand-button')}>
            {isRowCollapsed ? (
              <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-collapsed`]} />
            ) : (
              <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-expanded`]} />
            )}
          </div>
          <CellValue
            value={instrument.instrumentNarrative}
            className={
              allRowsCollapsed
                ? styles[`opm-valuation-header-collapsed`]
                : styles[`opm-valuation-header-expanded`]
            }
            alignment={Alignment.Right}
          />
        </td>
        <td />
        <td>
          <CellValue
            value={weightedPresentValue(instrument)}
            alignment={Alignment.Right}
            className={styles[`opm-valuation-value`]}
            {...amountRules}
            dataTestId={slugifyStrings(instrument.instrumentNarrative, 'avg-present-value')}
          />
        </td>
        <td>
          <CellValue
            value={weightedMinorityDiscounts(instrument)}
            className={styles[`opm-valuation-value`]}
            {...enteredPercentageViewRules}
            dataTestId={slugifyStrings(instrument.instrumentNarrative, 'avg-minority-discounts')}
          />
        </td>
        <td>
          <CellValue
            value={weightedMarketValue(instrument)}
            alignment={Alignment.Right}
            className={styles[`opm-valuation-value`]}
            {...amountRules}
            dataTestId={slugifyStrings(instrument.instrumentNarrative, 'avg-market-value')}
          />
        </td>
        <td>
          <CellValue
            value={weightedMarketValuePerShare(instrument)}
            alignment={Alignment.Right}
            className={styles[`opm-valuation-value`]}
            {...decimalCalculatedViewRules}
            dataTestId={slugifyStrings(
              instrument.instrumentNarrative,
              'avg-market-value-per-share'
            )}
          />
        </td>
      </tr>
      {!isRowCollapsed &&
        instrument.years.map((value, index) => (
          <tr key={index}>
            <td className={styles['pl-45']}>
              <CellValue
                value={new Date(value.date.toString()).toLocaleDateString('en-US', {
                  month: 'short',
                  year: '2-digit',
                })}
                className={styles[`opm-valuation-expanded-value`]}
                dataTestId={slugifyStrings(instrument.instrumentNarrative, value.date)}
              />
            </td>
            <td>
              <CellValue
                value={convertDecimalToPercentage(value.exitProbability)}
                className={styles[`opm-valuation-expanded-value`]}
                {...enteredPercentageViewRules}
                dataTestId={slugifyStrings(
                  instrument.instrumentNarrative,
                  value.date,
                  'exit-probability'
                )}
              />
            </td>
            <td>
              <CellValue
                value={value.presentValue}
                className={styles[`opm-valuation-expanded-value`]}
                alignment={Alignment.Right}
                {...amountRules}
                dataTestId={slugifyStrings(
                  instrument.instrumentNarrative,
                  value.date,
                  'present-value'
                )}
              />
            </td>
            <td>
              <CellValue
                value={convertDecimalToPercentage(value.minorityDiscounts)}
                className={styles[`opm-valuation-expanded-value`]}
                {...enteredPercentageViewRules}
                dataTestId={slugifyStrings(
                  instrument.instrumentNarrative,
                  value.date,
                  'minority-discounts'
                )}
              />
            </td>
            <td>
              <CellValue
                value={value.marketValue}
                className={styles[`opm-valuation-expanded-value`]}
                alignment={Alignment.Right}
                {...amountRules}
                dataTestId={slugifyStrings(
                  instrument.instrumentNarrative,
                  value.date,
                  'market-value'
                )}
              />
            </td>
            <td>
              <CellValue
                value={Number(value.marketValuePerShare)}
                className={styles[`opm-valuation-expanded-value`]}
                alignment={Alignment.Right}
                {...decimalCalculatedViewRules}
                dataTestId={slugifyStrings(
                  instrument.instrumentNarrative,
                  value.date,
                  'market-value-per-share'
                )}
              />
            </td>
          </tr>
        ))}
    </>
  );
};

export default ValuationInstrument;
