import { useAppSelector } from '@core/hooks/redux-hooks';
import { useEffect, useRef, useState } from 'react';
import { LOADER_TIMEOUT } from '@app/shared/constants/duration';

export enum LoaderType {
  Blocking,
  NonBlocking,
}

export const useLoader = (type: LoaderType) => {
  const timeoutRef = useRef<number>();

  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const obtrusiveRequestsCount = useAppSelector(
    (state) => state.uiValues.obtrusiveRequestsInProgressCount
  );
  const unObtrusiveRequestsCount = useAppSelector(
    (state) => state.uiValues.unobtrusiveRequestsInProgressCount
  );

  const isAnyRequestInProgress =
    (type === LoaderType.Blocking ? obtrusiveRequestsCount : unObtrusiveRequestsCount) > 0;

  useEffect(() => {
    if (isAnyRequestInProgress) {
      timeoutRef.current = window.setTimeout(() => {
        setIsLoaderVisible(true);
      }, LOADER_TIMEOUT);

      return () => {
        setIsLoaderVisible(false);
        window.clearTimeout(timeoutRef.current);
      };
    }
  }, [isAnyRequestInProgress]);

  return isLoaderVisible;
};
