import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';

export const getWeightedAverageValuePerCase = (
  caseItem: CaseDto,
  valueSelector: (
    caseItem: CaseDto,
    forecast: ForecastDto,
    multiple: number,
    multipleId: string | undefined
  ) => number
) => {
  let valueForCase = 0;

  for (const forecast of caseItem.forecasts) {
    let valueForForecast = 0;

    for (const multiple of caseItem.multiples) {
      const multipleProbability = forecast.multipleProbabilities.find(
        (p) => p.multipleId === multiple.multipleId
      );

      if (multiple.multiple && multipleProbability) {
        valueForForecast +=
          valueSelector(caseItem, forecast, multiple.multiple, multiple.multipleId) *
          ((multipleProbability.probability ?? 0) / 100);
      }
    }

    valueForCase += valueForForecast * ((forecast.probability ?? 0) / 100);
  }

  return valueForCase;
};
