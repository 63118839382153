import { FC, useEffect } from 'react';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { selectValuedInstruments } from '@core/store/project-slice-selectors';
import {
  instrumentByTypeOrTranchesOrDefaultSortFn,
  mapAsRecord,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import {
  getCaseProbabilityWeightedValue,
  getForecastProbabilityWeightedValue,
} from '@app/shared/helpers/get-weighted-values';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import styles from './valuation-estimates-by-instrument.module.scss';
import classNames from 'classnames';
import useCostOfEquityCalculations from '@app/shared/hooks/useCostOfEquityCalculations';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { setPwermDashboardValuationEstimatesTableSelectedInstrument } from '@app/core/store/ui-values-slice';
import { useLocale } from '@app/core/hooks/useLocale';

export const ValuationEstimatesByInstrument: FC = (): JSX.Element => {
  const { l } = useLocale();
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const storedInstrument = useAppSelector(
    (state) =>
      state.uiValues.userSelections.pwermDashboard.valuationEstimatesTable.selectedInstrument
  );

  const valuedInstruments = useAppSelector(selectValuedInstruments);
  const sortedValuedInstruments = valuedInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn);

  const instrumentOptions = [
    ...sortedValuedInstruments.map((instrument) => ({
      value: instrument.instrumentId,
      viewValue: instrument.instrumentNarrative,
    })),
  ];

  const isStoredInstrumentValid = sortedValuedInstruments.some(
    (instrument) => instrument.instrumentId === storedInstrument
  );

  const defaultViewOption = isStoredInstrumentValid
    ? storedInstrument
    : instrumentOptions[0]?.value;

  const formMethods = useForm<{ viewOption: string }>({
    ...formConfigBase,
    defaultValues: {
      viewOption: defaultViewOption,
    },
  });

  const { watch } = formMethods;
  const selectedViewOption = watch('viewOption');

  useEffect(() => {
    if (selectedViewOption !== storedInstrument) {
      dispatch(setPwermDashboardValuationEstimatesTableSelectedInstrument(selectedViewOption));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViewOption]);

  const selectedInstrument =
    sortedValuedInstruments.find((instrument) => instrument.instrumentId === selectedViewOption) ??
    sortedValuedInstruments[0];

  const { getCaseWeightedAverageCostOfEquity, getCaseCostOfEquity } = useCostOfEquityCalculations({
    selectedInstrument,
  });

  const { cases } = sortedProjectByCase.pwermInput;
  const getInstrumentData = (caseItem: CaseDto) => {
    const netExitWeightedProceedsPerInstrument = getForecastProbabilityWeightedValue({
      caseItem,
      getValue: ({ caseItem, forecast }) =>
        getCalculatedProjectValue('NetExitWeightedProceedsPerInstrument', {
          caseId: caseItem.caseId,
          forecastId: forecast.forecastId,
          instrumentId: selectedInstrument?.instrumentId,
        }),
    });

    const forecastWeightedValuationPresentValue = getForecastProbabilityWeightedValue({
      caseItem,
      getValue: ({ caseItem, forecast }) =>
        getCalculatedProjectValue('ValuationPresentValuePerInstrument', {
          caseId: caseItem.caseId,
          forecastId: forecast.forecastId,
          instrumentId: selectedInstrument?.instrumentId,
        }),
    });

    const pv = getForecastProbabilityWeightedValue({
      caseItem,
      getValue: ({ caseItem, forecast }) =>
        getCalculatedProjectValue('ValuationPresentValuePerInstrument', {
          caseId: caseItem.caseId,
          forecastId: forecast.forecastId,
          instrumentId: selectedInstrument?.instrumentId,
        }),
    });

    const forecastWeightedValuationMarketValue = getCalculatedProjectValue(
      'ForecastWeightedValuationMarketValuePerInstrument',
      {
        caseId: caseItem.caseId,
        instrumentId: selectedInstrument?.instrumentId,
      }
    );

    const minorityDiscounts =
      pv === 0 ? NaN : (1 - (forecastWeightedValuationMarketValue ?? NaN) / pv) * 100;

    return [
      {
        value: netExitWeightedProceedsPerInstrument,
        formattingRules: amountRules,
      },
      {
        value: getCalculatedProjectValueInPercent(getCaseCostOfEquity(caseItem)),
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: forecastWeightedValuationPresentValue,
        formattingRules: amountRules,
      },
      {
        value: minorityDiscounts,
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: forecastWeightedValuationMarketValue,
        formattingRules: amountRules,
      },
    ];
  };

  const dataByCaseId = mapAsRecord(
    cases,
    (item) => getInstrumentData(item),
    (item) => item.caseId
  );

  const caseWeightedAverageCostOfEquity = getCalculatedProjectValueInPercent(
    getCaseWeightedAverageCostOfEquity()
  );

  const weightedAverageRowData = [
    {
      value: getCaseProbabilityWeightedValue({
        project,
        getValue: ({ caseItem }) => dataByCaseId[caseItem.caseId][0].value,
        convertNanToZero: true,
      }),
      formattingRules: amountRules,
    },
    {
      value: caseWeightedAverageCostOfEquity,
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: getCaseProbabilityWeightedValue({
        project,
        getValue: ({ caseItem }) => dataByCaseId[caseItem.caseId][2].value,
        convertNanToZero: true,
      }),
      formattingRules: amountRules,
    },
    {
      value: getCaseProbabilityWeightedValue({
        project,
        getValue: ({ caseItem }) => dataByCaseId[caseItem.caseId][3].value,
        convertNanToZero: true,
      }),
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: getCalculatedProjectValue('CaseWeightedAverageValuationMarketValuePerInstrument', {
        instrumentId: selectedInstrument?.instrumentId,
      }),
      formattingRules: amountRules,
    },
  ];

  return (
    <DashboardWidgetContainer
      title="Valuation Estimates by Instrument"
      actions={
        <FormProvider {...formMethods}>
          <form className="dashboard-widget-controls">
            <div className="dashboard-widget-controls__item">
              <FormSelect
                ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartView') })}
                name="viewOption"
                required
                options={instrumentOptions}
                disabled={instrumentOptions.length === 0}
                isGapless
                ignoreReadOnly
              />
            </div>
          </form>
        </FormProvider>
      }>
      <table className="table-primary table-primary--fixed table-primary--secondary-theme">
        <thead>
          <tr>
            <th className="table-primary__cell--header-quinary">Case</th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Net Exit Weighted Proceeds
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Exit Weighted Cost of Equity
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Present Value
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Minority Discounts
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              Market Value
            </th>
          </tr>
        </thead>
        <tbody>
          {getCasesWithNonZeroProbability(cases).map((caseItem, index) => {
            return (
              <tr key={index}>
                <td>
                  <span className="heading-2 heading-2--alternate">{caseItem.narrative}</span>
                </td>
                {dataByCaseId[caseItem.caseId].map((item, valueIndex) => (
                  <td key={valueIndex}>
                    <CellValue
                      value={item.value}
                      theme={CellValueTheme.Secondary}
                      className={classNames({
                        [styles['market-value-cell']]:
                          dataByCaseId[caseItem.caseId].length - 1 === valueIndex,
                      })}
                      {...item.formattingRules}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <th className="table-primary__cell--header-quinary">Case Weighted Average</th>
            {weightedAverageRowData.map((item, index) => (
              <td key={index}>
                <CellValue
                  value={item.value}
                  theme={CellValueTheme.Secondary}
                  className={classNames(styles['weighted-value-cell'], {
                    [styles['market-value-cell']]: weightedAverageRowData.length - 1 === index,
                  })}
                  {...item.formattingRules}
                />
              </td>
            ))}
          </tr>
        </tfoot>
      </table>
    </DashboardWidgetContainer>
  );
};
