import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { FC, useState } from 'react';
import { ProjectAccessModal } from '@app/modules/projects/inputs/project-admin/project-access/ProjectAccessModal';
import SvgUserAccess from '@app/shared/icons/UserAccess';
import { useAppSelector } from '@core/hooks/redux-hooks';

interface ProjectAccessActionProps {
  projectName?: string;
  projectId?: number;
  userHasAccess?: boolean;
  standalone?: boolean;
}

export const ProjectAccessAction: FC<ProjectAccessActionProps> = ({
  projectName,
  projectId,
  userHasAccess,
  standalone,
}): JSX.Element => {
  const projectList = useAppSelector((state) => state.projectList.values);
  const filteredProject = projectList.data?.find((project) => project.id === projectId);
  const userAccess = userHasAccess ?? filteredProject?.hasAccess;
  const [isProjectAccessModalVisible, setIsProjectAccessModalVisible] = useState(false);

  return (
    <>
      <Button
        startIcon={<SvgUserAccess />}
        onClick={() => setIsProjectAccessModalVisible(true)}
        appearance={standalone ? ButtonAppearance.DEFAULT_TERTIARY : ButtonAppearance.CLEAN}
        ignoreReadOnly>
        Project Access
      </Button>
      {isProjectAccessModalVisible && (
        <ProjectAccessModal
          setIsOpen={setIsProjectAccessModalVisible}
          isOpen={isProjectAccessModalVisible}
          projectName={projectName}
          projectId={projectId}
          userHasAccess={userAccess}
        />
      )}
    </>
  );
};
