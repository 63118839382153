import { Components, Theme } from '@mui/material';
import palette from '@styles/theme/palette/palette';
import { RemSpacing } from '@styles/theme/spacing';
import shape, { focusOutline } from '@styles/theme/shape';
import { typographyFont, typographySize, typographyWeight } from '@styles/theme/typography';
import DropdownSvg from '@app/shared/icons/legacy/DropdownSvg';

export enum TextFieldVariant {
  filled = 'filled',
  outlined = 'outlined',
}

const textFieldComponent: Components<Theme> = {
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        backgroundColor: palette.grey?.grey100,
        border: `1px solid ${palette.lights.secondaryLight100}`,
        borderRadius: shape.borderRadius4,
        color: palette.text?.text1,
        fontSize: typographySize.secondary,
        fontWeight: typographyWeight.medium,
        lineHeight: 'unset',
        padding: 0,
        ':hover': {
          backgroundColor: palette.background?.background200,
          borderColor: palette.primary?.main,
        },
        ':active': {
          borderColor: palette.text?.text2,
        },
        ':focus-within': {
          backgroundColor: palette.lights.white,
          outline: focusOutline,
        },
        '&.Mui-disabled': {
          backgroundColor: palette.lights.white,
          borderColor: palette.grey?.grey300,
        },
        '&.Mui-error': {
          backgroundColor: palette.lights.white,
          borderColor: palette.error?.main,
        },
      },
      input: {
        padding: `1.1rem ${RemSpacing.spacer8}`,
        height: 'auto',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        border: `1px solid ${palette.transparent.fullyTransparent}`,
        color: palette.text?.text1,
        fontFamily: typographyFont.georgia,
        fontSize: typographySize.medium,
        fontWeight: typographyWeight.bold,
        lineHeight: 'inherit',
        ':hover': {
          backgroundColor: palette.background?.background200,
        },
        '&.Mui-disabled': {
          backgroundColor: palette.transparent.fullyTransparent,
        },
        '&.Mui-focused': {
          backgroundColor: palette.lights.white,
          outline: focusOutline,
        },
        '&.Mui-error': {
          backgroundColor: palette.lights.white,
          borderColor: palette.error?.main,
        },
      },
      input: {
        height: 'inherit',
        padding: `0.7rem ${RemSpacing.spacer8} 0.55rem`,
      },
      notchedOutline: {
        display: 'none',
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      IconComponent: DropdownSvg,
    },
    styleOverrides: {
      select: {
        backgroundColor: palette.background?.background200,
        borderRadius: shape.borderRadius4,
        ':hover, :focus': {
          backgroundColor: palette.lights.white,
          '~ .MuiSvgIcon-root': {
            color: palette.primary?.main,
          },
        },
        '&.Mui-disabled': {
          backgroundColor: palette.lights.white,
          cursor: 'not-allowed',
        },
        '~ .MuiSvgIcon-root': {
          pointerEvents: 'none',
          position: 'absolute',
          right: '3px',
        },
      },
      icon: {
        transition: 'transform .25s',
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      error: false,
      shrink: true,
    },
    styleOverrides: {
      root: {
        color: palette.text?.text1,
        fontSize: typographySize.secondary,
        fontWeight: typographyWeight.medium,
        position: 'relative',
        top: '-3px',
        transform: 'scale(1)',
        '&.Mui-focused': {
          color: palette.text?.text2,
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      positionStart: {
        marginRight: 0,
        marginTop: '0!important',
        paddingLeft: RemSpacing.spacer8,
      },
      positionEnd: {
        marginLeft: 0,
        paddingRight: RemSpacing.spacer8,
      },
    },
  },
};

export default textFieldComponent;
