import { FC } from 'react';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { useDropdown } from '@app/shared/components/dropdown/useDropdown';
import classNames from 'classnames';
import styles from './project-status-selection-widget.module.scss';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import { useController } from 'react-hook-form';
import { enumKeyByValue } from '@app/shared/helpers';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import SvgChevron from '@app/shared/icons/Chevron';

const PARENT_CLASSNAME = 'project-status-widget';
export const ProjectStatusSelectionWidget: FC = () => {
  const {
    field: { value: fieldValue, onChange: fieldOnChange },
  } = useController({ name: 'status' });

  const { ref, state, toggle } = useDropdown({});

  const getPillTheme = (status: ProjectStatus) =>
    status === ProjectStatus.Draft
      ? PillTheme.Secondary
      : status === ProjectStatus.Closed
      ? PillTheme.Tertiary
      : undefined;

  const options = [
    {
      status: ProjectStatus.Draft,
      title: 'Mark as Draft',
      subtitle: 'Analysis and numbers in progress',
    },
    {
      status: ProjectStatus.Finalised,
      title: 'Mark as Finalised',
      subtitle: 'Conclusion agreed with Client',
    },
    {
      status: ProjectStatus.Closed,
      title: 'Mark as Closed',
      subtitle: 'You can’t edit a project once it’s closed',
    },
  ];

  const handleStatusSelect = (status: ProjectStatus) => {
    fieldOnChange(enumKeyByValue(ProjectStatus, status));
    toggle();
  };

  return (
    <div>
      <div
        ref={ref}
        className={classNames(styles[PARENT_CLASSNAME], {
          [styles[`${PARENT_CLASSNAME}--open`]]: state.open,
        })}>
        <Button onClick={toggle} appearance={ButtonAppearance.CLEAN} size={ButtonSize.FLUID}>
          <Pill
            theme={getPillTheme(ProjectStatus[fieldValue as keyof typeof ProjectStatus])}
            suffix={() => <SvgChevron className={styles[`${PARENT_CLASSNAME}__icon`]} />}>
            {ProjectStatus[fieldValue as keyof typeof ProjectStatus]}
          </Pill>
        </Button>
        <div className={styles[`${PARENT_CLASSNAME}__options-list-container`]}>
          <Card
            hasShadow
            variation={CardVariation.NoSpacing}
            cornersVariation={CardCornersVariation.Primary}>
            <ul className={styles[`${PARENT_CLASSNAME}__options-list`]}>
              {options.map((item, index) => (
                <li key={index} className={styles[`${PARENT_CLASSNAME}__options-list-item`]}>
                  <Button
                    appearance={ButtonAppearance.CLEAN}
                    size={ButtonSize.FLUID}
                    onClick={() => {
                      handleStatusSelect(item.status);
                    }}>
                    <div className={styles[`${PARENT_CLASSNAME}__options-list-item-content`]}>
                      <div
                        className={
                          styles[`${PARENT_CLASSNAME}__options-list-item-content-pill-container`]
                        }>
                        <Pill theme={getPillTheme(item.status)} size={PillSize.Small}>
                          {item.status}
                        </Pill>
                      </div>
                      <div className={styles[`${PARENT_CLASSNAME}__options-list-item-text`]}>
                        <span className={styles[`${PARENT_CLASSNAME}__options-list-item-title`]}>
                          {item.title}
                        </span>
                        <span className={styles[`${PARENT_CLASSNAME}__options-list-item-subtitle`]}>
                          {item.subtitle}
                        </span>
                      </div>
                    </div>
                  </Button>
                </li>
              ))}
            </ul>
          </Card>
        </div>
      </div>
    </div>
  );
};
