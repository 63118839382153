import { UserInfoDto } from '@app/shared/models/contracts/userinfo-dto';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@server/api-config';
import env from '@environment';

export interface UserInfoState {
  userInfo: UserInfoDto;
}

export const fetchUserInfo = createAsyncThunk('userInfo/fetchUserInfo', async (_) => {
  const response = await api.get<UserInfoDto>(`${env.apiUrl}/account/userinfo`);
  return response.data;
});

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: {
    userInfo: {
      id: 0,
      role: '',
      fullUserName: '',
      email: '',
    },
  } as UserInfoState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.fulfilled, (state, payload) => {
      state.userInfo = payload.payload;
    });
  },
});

export default userInfoSlice.reducer;
