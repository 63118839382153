import { Navigate, NavigateProps } from 'react-router-dom';
import { usePwcNavigate } from '@core/hooks/routing-hooks';

export type PwcNavigateProps = Omit<NavigateProps, 'to'> & {
  route: string;
  params?: Record<string, string>;
};

const PwcNavigate = ({ route, params, ...rest }: PwcNavigateProps) => {
  const { generatePath } = usePwcNavigate();

  return <Navigate {...rest} to={generatePath(route, params)} />;
};

export default PwcNavigate;
