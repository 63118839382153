import { forwardRef, Ref } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { usePwcNavigate } from '@core/hooks/routing-hooks';

export type PwcNavLinkProps = Omit<LinkProps, 'to'> & {
  route: string;
  params?: Record<string, string>;
};

const PwcLink = (
  { route, params, children, ...rest }: PwcNavLinkProps,
  ref?: Ref<HTMLAnchorElement>
) => {
  const { generatePath } = usePwcNavigate();

  return (
    <Link ref={ref} {...rest} to={generatePath(route, params)}>
      {children}
    </Link>
  );
};

export default forwardRef(PwcLink);
