import { getForecastById } from '@app/core/store/project-slice-selectors';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { MultipleProbabilityDto } from '@app/shared/models/contracts/project-dto';

export function useWeightedAverageCalculator() {
  const project = useAppSelector((state) => state.project.projectDraft);
  return function (
    caseId: string,
    forecastId: string,
    getInputValue: (props: {
      caseId: string;
      forecastId: string;
      multipleId: string;
    }) => number | undefined | null
  ) {
    const forecast = getForecastById(project, caseId, forecastId);

    if (!forecast) {
      return undefined;
    }

    return forecast.multipleProbabilities.reduce(
      (result: number | undefined, multipleProbability: MultipleProbabilityDto) => {
        if (result === undefined) {
          return undefined;
        }

        const inputValue = getInputValue({
          caseId,
          forecastId,
          multipleId: multipleProbability.multipleId,
        });
        if (inputValue === undefined || inputValue === null) {
          return undefined;
        }

        return (
          result +
          ((multipleProbability.probability ? multipleProbability.probability : 0) / 100) *
            inputValue
        );
      },
      0
    );
  };
}
