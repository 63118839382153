import { FC } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { RouteConstants } from '../../RouteConstants';

export const ValuationInputsPage: FC = (): JSX.Element => {
  const storedValuationInputsTab = useAppSelector(
    (state) => state.uiValues.userSelections.valuationInputs.selectedTab
  );
  const { generatePath } = usePwcNavigate();

  const selectedTab = storedValuationInputsTab ?? RouteConstants.CapmAssumptions;

  return <PwcNavigate route={generatePath(selectedTab)} />;
};
