import { RootAppRoutes } from '@app/shared/enums/root-app-routes';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import env from '@environment';
import { useLocale } from '@app/core/hooks/useLocale';

const RedirectContent: FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { l } = useLocale();

  useEffect(() => {
    if (location.pathname !== `/${RootAppRoutes.Redirect}`) {
      navigate(RootAppRoutes.Redirect);
    }
  }, [navigate]);

  return (
    <>
      <h1 className="heading-2">{l('_RedirectHeader')}</h1>
      <p>
        {l('_RedirectText')}
        <a href={env.redirectUrl}>here.</a>
      </p>
      <br />
      <p>
        {l('_RedirectNewUrlText')}
        <br />
        <a href={env.redirectUrl}>{env.redirectUrl}</a>
        <br />
        <br />
      </p>
      <p>
        {l('_RedirectSaveBookmarks')}
        <br />
        <br />
      </p>
      <p>
        {l('_RedirectContactInformation')}
        <a href="mailto:uk_equityrewardanalytics_support@pwc.com">
          uk_equityrewardanalytics_support@pwc.com.
        </a>
      </p>
    </>
  );
};

export default RedirectContent;
