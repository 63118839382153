import { FC } from 'react';
import { StatusPage } from './StatusPage';
import NoAccessContent from '@app/shared/components/status-pages/status-contents/no-access/NoAccessContent';

const NoAccessPage: FC = (): JSX.Element => {
  return (
    <StatusPage>
      <NoAccessContent />
    </StatusPage>
  );
};

export default NoAccessPage;
