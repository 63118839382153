import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import ValuationInstrument from './ValuationInstrument';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import styles from './valuation-conclusion.module.scss';
import { opmInstrumentByTypeOrDefaultSortFn } from '@app/shared/helpers';
import PlaceholderValuationInstrument from './PlaceholderValuationInstrument';
import { setOpmDashboardValuationEstimatesTableChildRowStates } from '@app/core/store/ui-values-slice';
import { useUpdatedChildRowStates } from '@app/core/hooks/useLocalStateCollapse';

export type ChildRowState = {
  [instrumentId: string]: boolean;
};

export const ValuationConclusion: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const valuedInstruments = useAppSelector(
    (state) => state.opmCalculation.riskFreeValues.valuationConclusion || []
  );
  const sortedValuedInstruments = useMemo(
    () => [...valuedInstruments].sort(opmInstrumentByTypeOrDefaultSortFn),
    [valuedInstruments]
  );

  const updatedChildRowStates = useUpdatedChildRowStates();

  const [childRowStates, setChildRowStates] = useState<ChildRowState>(updatedChildRowStates);

  const childRowStatesRef = useRef(childRowStates);

  useEffect(() => {
    childRowStatesRef.current = childRowStates;
  }, [childRowStates]);

  useEffect(() => {
    return () => {
      dispatch(setOpmDashboardValuationEstimatesTableChildRowStates(childRowStatesRef.current));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const anyInstruments = sortedValuedInstruments?.length > 0;

  const allRowsCollapsed = Object.values(childRowStates).every((isCollapsed) => isCollapsed);
  const allRowsExpanded = Object.values(childRowStates).every((isCollapsed) => !isCollapsed);

  const showExitProbability = anyInstruments && !allRowsCollapsed;

  const handleChildRowChange = (instrumentId: string, isCollapsed: boolean) => {
    const newChildStates = { ...childRowStates, [instrumentId]: isCollapsed };
    setChildRowStates(newChildStates);
  };

  const toggleAllRows = () => {
    const newChildStates = { ...childRowStates };
    const shouldExpand = !allRowsExpanded;
    Object.keys(newChildStates).forEach((instrumentId) => {
      newChildStates[instrumentId] = !shouldExpand;
    });
    setChildRowStates(newChildStates);
  };

  const conditionalExpandButton = anyInstruments ? (
    <Button
      appearance={ButtonAppearance.DEFAULT_SECONDARY}
      className="dashboard-widget-controls__button"
      onClick={toggleAllRows}
      data-testid="valuation-conclusion-expand-collapse-all-button"
      ignoreReadOnly>
      {allRowsCollapsed ? l('_ExpandAll') : allRowsExpanded ? l('_CollapseAll') : l('_ExpandAll')}
    </Button>
  ) : undefined;

  return (
    <DashboardWidgetContainer
      title={l('_ValuationEstimates')}
      opm
      actions={conditionalExpandButton}
      secondaryContent={
        <table className="table-primary table-primary--opm-tr-padding table-primary--fixed table-primary--secondary-theme">
          <thead>
            <tr>
              <th
                className={`${styles['mw-120']} table-primary__cell--header-quinary table-primary__cell--opm-instrument-title`}>
                {l('_Instrument')}
              </th>
              <th
                className={`${styles['mw-120']} table-primary__cell--header-quinary table-primary__cell--right table-primary__cell--opm-instrument-title`}>
                {showExitProbability ? l('_ExitProbabilityTitle') : ' '}
              </th>
              <th
                className={`${styles['mw-120']} table-primary__cell--header-quinary table-primary__cell--right table-primary__cell--opm-instrument-title`}>
                {l('_AggregatePresentValue')}
              </th>
              <th
                className={`${styles['mw-140']} table-primary__cell--header-quinary table-primary__cell--right table-primary__cell--opm-instrument-title`}>
                {l('_MinorityDiscounts')}
              </th>
              <th
                className={`${styles['mw-167']} table-primary__cell--header-quinary table-primary__cell--right table-primary__cell--opm-instrument-title`}>
                {l('_AggregateMarketValue')}
              </th>
              <th
                className={`${styles['mw-167-market-value']} table-primary__cell--header-quinary table-primary__cell--right table-primary__cell--opm-instrument-title`}>
                {l('_MarketValuePerShare')}
              </th>
            </tr>
          </thead>
          <tbody>
            {anyInstruments ? (
              sortedValuedInstruments?.map((instrument, index) => (
                <ValuationInstrument
                  key={index}
                  instrument={instrument}
                  isCollapsed={childRowStates[instrument.instrumentId]}
                  allRowsCollapsed={allRowsCollapsed}
                  onChildRowStateChange={(isCollapsed) =>
                    handleChildRowChange(instrument.instrumentId, isCollapsed)
                  }
                />
              ))
            ) : (
              <PlaceholderValuationInstrument />
            )}
          </tbody>
        </table>
      }
    />
  );
};
