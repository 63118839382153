import { SVGProps } from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M8.5 2c-2.058 0-3.705.931-4.905 2.039C2.404 5.137 1.607 6.452 1.23 7.356a.8.8 0 0 0 0 .622c.377.903 1.174 2.218 2.365 3.316 1.2 1.108 2.847 2.04 4.904 2.04s3.705-.932 4.904-2.04c1.191-1.1 1.988-2.413 2.368-3.316a.799.799 0 0 0 0-.622c-.38-.904-1.177-2.22-2.368-3.317C12.203 2.931 10.556 2 8.499 2Zm3.665 5.667c0 2.01-1.642 3.643-3.666 3.643S4.833 9.678 4.833 7.667s1.642-3.643 3.666-3.643 3.666 1.632 3.666 3.643ZM8.5 6.047c0 .894-.73 1.62-1.63 1.62-.292 0-.567-.076-.804-.213-.005.071-.01.14-.01.213a2.437 2.437 0 0 0 2.444 2.428 2.437 2.437 0 0 0 2.444-2.428 2.437 2.437 0 0 0-2.658-2.419c.135.235.214.509.214.8Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEye;
