import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import TableSectionValuesPerMultiple from '../../../components/TableSectionValuesPerMultiple';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  calculatedMultiplierRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import TableSectionValuesPerCase from '../../../components/TableSectionValuesPerCase';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { getWeightedAverageValuePerCase } from '@app/shared/helpers/get-weighted-value-per-case';
import { getWeightedAverageValueForAllCases } from '@app/shared/helpers/get-weighted-value-for-all-cases';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import {
  getCalculatedPayoutValue,
  selectSlnAndPrefSharesInstruments,
} from '@app/core/store/project-slice-selectors';
import {
  enumKeyByValue,
  isInstrumentOwnerAmountEntered,
  sumBy,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import {
  getCalculatedProjectValueInPercent,
  selectCalculatedPayoutsResults,
  selectTimesSinceInvestment,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { OwnerType, WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import TableSectionValuesPerForecast from '../../../components/TableSectionValuesPerForecast';
import { useKeyMetrics } from '@app/modules/projects/dashboard/widgets/key-metrics/useKeyMetrics';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';

export const WaterfallCrossCheckPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedPayouts = useAppSelector(selectCalculatedPayoutsResults);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);
  const timesSinceInvestment = useAppSelector(selectTimesSinceInvestment);

  const caseWithMostForecasts = getCaseWithMostForecasts(project.pwermInput.cases, true);

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    isInstrumentOwnerAmountEntered
  );

  const {
    getInstitutionalMoneyMultiple,
    getInstitutionalIRR,
    getDiscountFactor,
    getWeightedAverageTotalEquityProceeds,
    getDiscountedProceeds,
    totalEquityInvestment,
    varianceToCurrentMarketValueInPercent,
  } = useKeyMetrics();

  const getShareholderDebtReturn = (caseItem: CaseDto, forecast: ForecastDto, multiple: number) => {
    return filteredSlnAndPrefSharesInstruments.length
      ? sumBy(filteredSlnAndPrefSharesInstruments, (instrument) =>
          Number(
            getCalculatedPayoutValue(
              project,
              calculatedPayouts,
              caseItem.caseId,
              forecast.forecastId,
              multiple,
              instrument.instrumentId
            )?.perOwner[enumKeyByValue(OwnerType, OwnerType.Institution)]
          )
        )
      : undefined;
  };

  const getOrdinaryEquityReturn = (
    caseItem: CaseDto,
    forecast: ForecastDto,
    multipleId: string | undefined
  ) => {
    return getCalculatedProjectValue('InstitutionOrdinaryEquityReturn', {
      caseId: caseItem.caseId,
      forecastId: forecast.forecastId,
      multipleId: multipleId,
    });
  };

  const getTotalReturn = (
    caseItem: CaseDto,
    forecast: ForecastDto,
    multiple: number,
    multipleId: string | undefined
  ) => {
    return (
      (getShareholderDebtReturn(caseItem, forecast, multiple) ?? 0) +
      (getOrdinaryEquityReturn(caseItem, forecast, multipleId) ?? 0)
    );
  };

  const getCostOfTotalEquity = (forecast: ForecastDto) => {
    const matchingForecast = caseWithMostForecasts.forecasts.find(
      (f) => f.forecastYear === forecast.forecastYear
    );

    if (!matchingForecast) {
      return undefined;
    }

    return getCalculatedProjectValue('CaseWeightedCostOfEquityCumulative', {
      forecastId: matchingForecast.forecastId,
    });
  };
  const [isSponsorReturnTableOpen, setIsSponsorReturnTableOpen] = useToggler();
  const [isTotalEquityDCFCrossCheckOpen, setIsTotalEquityDCFCrossCheckOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Sponsor Return"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.SponsorReturn)}
          isOpen={isSponsorReturnTableOpen}
          setIsOpen={setIsSponsorReturnTableOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isSponsorReturnTableOpen,
        })}>
        <TableSectionValuesPerMultiple
          title="Shareholder Debt"
          renderCell={({ caseItem, forecast, multiple }) => {
            return (
              <CellValue
                {...amountRules}
                value={getShareholderDebtReturn(caseItem, forecast, multiple)}
              />
            );
          }}
        />
        <TableSectionValuesPerMultiple
          title="Ordinary equity"
          renderCell={({ caseItem, forecast, multipleId }) => {
            return (
              <CellValue
                {...amountRules}
                value={getOrdinaryEquityReturn(caseItem, forecast, multipleId)}
              />
            );
          }}
        />
        <TableSectionValuesPerMultiple
          title="Total return"
          renderCell={({ caseItem, forecast, multiple, multipleId }) => {
            return (
              <CellValue
                {...amountRules}
                value={getTotalReturn(caseItem, forecast, multiple, multipleId)}
              />
            );
          }}
        />
        <TableSectionValuesPerCase
          title="Sponsor return"
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...amountRules}
                alignment={Alignment.Center}
                value={getWeightedAverageValuePerCase(
                  caseItem,
                  (caseItem, forecast, multiple, multipleId) =>
                    getTotalReturn(caseItem, forecast, multiple, multipleId)
                )}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            Sponsor return (all cases)
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={getWeightedAverageValueForAllCases(
                (caseItem, forecast, multiple, multipleId) =>
                  getTotalReturn(caseItem, forecast, multiple, multipleId),
                project.pwermInput.cases
              )}
            />
          </td>
        </tr>
        <TableSpacerRow />

        <tr>
          <th className="table-primary__cell--header-tertiary">Sponsor Investment</th>
          <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
        </tr>
        <TableSectionValuesPerForecast
          title="Investment cost"
          renderSpacer={false}
          renderCell={() => {
            return (
              <CellValue {...amountRules} value={getCalculatedProjectValue('InstitutionCost')} />
            );
          }}
        />
        <TableSectionValuesPerForecast
          title="Investment date"
          renderSpacer={false}
          renderCell={() => (
            <CellValue
              alignment={Alignment.Right}
              dateFormat={{ day: '2-digit', month: '2-digit', year: '2-digit' }}
              value={project.investmentDate}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title="Investment horizon"
          renderCell={({ forecast }) => {
            return (
              <CellValue
                {...numberCalculatedViewRules}
                normalize
                value={Math.max(0, timesSinceInvestment[forecast.forecastYear])}
              />
            );
          }}
        />
        <tr>
          <th className="table-primary__cell--header-tertiary">Sponsor MoM and IRR</th>
          <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
        </tr>
        <TableSectionValuesPerMultiple
          title="MoM"
          renderCell={({ caseItem, forecast, multipleId }) => (
            <CellValue
              {...calculatedMultiplierRules}
              value={getInstitutionalMoneyMultiple(caseItem, forecast, multipleId)}
            />
          )}
        />
        <TableSectionValuesPerCase
          title="Case MoM"
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...calculatedMultiplierRules}
                alignment={Alignment.Center}
                value={getCalculatedProjectValue('SponsorCaseMoM', {
                  caseId: caseItem.caseId,
                })}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            Weighted MoM (all cases)
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...calculatedMultiplierRules}
              alignment={Alignment.Center}
              value={getCalculatedProjectValue('WeightedMoMAllCases')}
            />
          </td>
        </tr>
        <TableSpacerRow />
        <TableSectionValuesPerMultiple
          title="IRR"
          renderCell={({ caseItem, forecast, multipleId }) => {
            return (
              <CellValue
                {...enteredPercentageViewRules}
                value={getInstitutionalIRR(caseItem, forecast, multipleId)}
              />
            );
          }}
        />
        <TableSectionValuesPerCase
          title="Case IRR"
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...enteredPercentageViewRules}
                alignment={Alignment.Center}
                value={getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('SponsorCaseIRR', {
                    caseId: caseItem.caseId,
                  })
                )}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            Weighted IRR (all cases)
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...enteredPercentageViewRules}
              alignment={Alignment.Center}
              value={getCalculatedProjectValueInPercent(
                getCalculatedProjectValue('WeightedIRRAllCases')
              )}
            />
          </td>
        </tr>
        <TableSpacerRow />
      </tbody>
      <tbody>
        <WaterfallTableExpandableRow
          title="Total Equity DCF Cross Check"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.TotalEquityDCFCrossCheck)}
          isOpen={isTotalEquityDCFCrossCheckOpen}
          setIsOpen={setIsTotalEquityDCFCrossCheckOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isTotalEquityDCFCrossCheckOpen,
        })}>
        <TableSectionValuesPerForecast
          title="Weighted total equity"
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWeightedAverageTotalEquityProceeds(caseItem, forecast)}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title="Cost of total equity"
          renderSpacer={false}
          renderCell={({ forecast }) => {
            const value = getCostOfTotalEquity(forecast) ?? 0;
            return <CellValue {...enteredPercentageViewRules} value={value * 100} />;
          }}
        />
        <TableSectionValuesPerForecast
          title="Discount factor"
          renderSpacer={false}
          renderCell={({ forecast }) => (
            <CellValue {...numberCalculatedViewRules} value={getDiscountFactor(forecast)} />
          )}
        />
        <TableSectionValuesPerForecast
          title="PV of total equity"
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => {
            return <CellValue {...amountRules} value={getDiscountedProceeds(caseItem, forecast)} />;
          }}
        />
        <TableSectionValuesPerCase
          title="Case MV of total equity"
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...amountRules}
                alignment={Alignment.Center}
                value={getWeightedAverageValuePerCase(caseItem, (caseItem, forecast) =>
                  getDiscountedProceeds(caseItem, forecast)
                )}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            MV of total equity (all cases)
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={getWeightedAverageValueForAllCases(
                (caseItem, forecast) => getDiscountedProceeds(caseItem, forecast),
                project.pwermInput.cases
              )}
            />
          </td>
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">Total equity investment</th>
          <td className="table-primary__cell--vertical-separator" />
          <td colSpan={columnsCount - 2} className="table-primary__cell--vertical-separator">
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={totalEquityInvestment}
            />
          </td>
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">
            % variance to Total Equity Value
          </th>
          <td className="table-primary__cell--vertical-separator" />
          <td colSpan={columnsCount - 2} className="table-primary__cell--vertical-separator">
            <CellValue
              {...enteredPercentageViewRules}
              alignment={Alignment.Center}
              value={varianceToCurrentMarketValueInPercent}
            />
          </td>
        </tr>
        <TableSpacerRow />
        <TableSpacerRow />
      </tbody>
    </>
  );
};

export default WaterfallCrossCheckPartialTable;
