import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ToggleWidget } from '@app/shared/enums/expand-collapse';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { DistributionChart } from '@app/modules/projects/dashboard/opm-charts/DistributionChart';
import {
  ChartTitles,
  ChartZoomMode,
  SimulatedOutcomesChartTypes,
  ViewOption,
} from '@app/modules/projects/dashboard/opm-charts/opm-enums';
import { StackedDistributionChart } from '@app/modules/projects/dashboard/opm-charts/stacked-distribution-chart/StackedDistributionChart';
import { SelectOption } from '@app/shared/models/option';
import { opmInstrumentByTypeOrDefaultSortFn } from '@app/shared/helpers';
import {
  setOpmDashboardRiskNeutralChartIsExpanded,
  setOpmDashboardRiskNeutralChartSelectedInstrument,
  setOpmDashboardRiskNeutralChartSelectedYear,
  setOpmDashboardRiskNeutralChartSelectedZoom,
  setRealWorldDistributionsRealWorldByInstrumentChartSelectedInstrument,
  setRealWorldDistributionsRealWorldByInstrumentChartSelectedYear,
  setRealWorldDistributionsRealWorldByInstrumentChartSelectedZoom,
  setRealWorldDistributionsRealWorldChartSelectedInstrument,
  setRealWorldDistributionsRealWorldChartSelectedYear,
  setRealWorldDistributionsRealWorldChartSelectedZoom,
  setRealWorldDistributionsRealWorldEvChartSelectedYear,
  setRealWorldDistributionsRealWorldEvChartSelectedZoom,
  setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedYear,
  setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedZoom,
} from '@app/core/store/ui-values-slice';
import { useLocalStateCollapse } from '@app/core/hooks/useLocalStateCollapse';

interface DistributionSetupProps {
  chartType: SimulatedOutcomesChartTypes;
  chartTitle: ChartTitles;
}

export const DistributionSetup: FC<DistributionSetupProps> = ({
  chartType,
  chartTitle,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const results = useAppSelector((state) => state.opmCalculation);
  const valuedInstruments = useAppSelector(
    (state) => state.opmCalculation.riskFreeValues.valuationConclusion || []
  );
  const { l } = useLocale();
  const isSimulatedOutcomeChart =
    chartType === SimulatedOutcomesChartTypes.RealWorldOutcome ||
    chartType === SimulatedOutcomesChartTypes.RiskFreeOutcome;

  const storedExpandedState = useAppSelector(
    (state) => state.uiValues.userSelections.opmDashboard.riskNeutralChart.isExpanded
  );

  // Get Selected Instrument
  const storedRiskFreeOutcomeInstrument = useAppSelector(
    (state) => state.uiValues.userSelections.opmDashboard.riskNeutralChart.selectedInstrument
  );
  const storedRealWorldOutcomeInstrument = useAppSelector(
    (state) =>
      state.uiValues.userSelections.realWorldDistributions.realWorldChart.selectedInstrument
  );
  const storedInstrumentsRealWorldDistributionInstrument = useAppSelector(
    (state) =>
      state.uiValues.userSelections.realWorldDistributions.realWorldByInstrumentChart
        .selectedInstrument
  );

  // Get Selected Year
  const storedRiskFreeOutcomeYear = useAppSelector(
    (state) => state.uiValues.userSelections.opmDashboard.riskNeutralChart.selectedYear
  );
  const storedRealWorldOutcomeYear = useAppSelector(
    (state) => state.uiValues.userSelections.realWorldDistributions.realWorldChart.selectedYear
  );
  const storedEVRealWorldDistributionYear = useAppSelector(
    (state) => state.uiValues.userSelections.realWorldDistributions.realWorldEvChart.selectedYear
  );
  const storedEquityRealWorldDistributionYear = useAppSelector(
    (state) =>
      state.uiValues.userSelections.realWorldDistributions.realWorldTotalAttributableEquityChart
        .selectedYear
  );
  const storedInstrumentsRealWorldDistributionYear = useAppSelector(
    (state) =>
      state.uiValues.userSelections.realWorldDistributions.realWorldByInstrumentChart.selectedYear
  );

  // Get Selected Zoom
  const storedRiskFreeOutcomeZoom = useAppSelector(
    (state) => state.uiValues.userSelections.opmDashboard.riskNeutralChart.selectedZoom
  );
  const storedRealWorldOutcomeZoom = useAppSelector(
    (state) => state.uiValues.userSelections.realWorldDistributions.realWorldChart.selectedZoom
  );
  const storedEVRealWorldDistributionZoom = useAppSelector(
    (state) => state.uiValues.userSelections.realWorldDistributions.realWorldEvChart.selectedZoom
  );
  const storedEquityRealWorldDistributionZoom = useAppSelector(
    (state) =>
      state.uiValues.userSelections.realWorldDistributions.realWorldTotalAttributableEquityChart
        .selectedZoom
  );
  const storedInstrumentsRealWorldDistributionZoom = useAppSelector(
    (state) =>
      state.uiValues.userSelections.realWorldDistributions.realWorldByInstrumentChart.selectedZoom
  );

  const defaultExpandedState = storedExpandedState ?? false;

  const [isExpanded, setIsExpanded] = useState(defaultExpandedState);

  useLocalStateCollapse(isExpanded, setOpmDashboardRiskNeutralChartIsExpanded, chartType);

  const sortedValuedInstruments = useMemo(
    () => [...valuedInstruments].sort(opmInstrumentByTypeOrDefaultSortFn),
    [valuedInstruments]
  );

  const plotData =
    chartType === SimulatedOutcomesChartTypes.RealWorldOutcome
      ? results.realWorldValues?.simulatedOutcomesDistributionPlot
      : results.riskFreeValues?.simulatedOutcomesDistributionPlot;

  // False if simulation mode is Equity or if it's EV but the EV plot data is null
  const isEnterpriseValuePlotDataAvailable =
    plotData !== undefined && plotData.enterpriseValue && plotData.enterpriseValue !== undefined;

  const yearOptions = useMemo(() => {
    if (plotData) {
      const years = Object.keys(
        isEnterpriseValuePlotDataAvailable ? plotData.enterpriseValue : plotData.equity
      );
      const updatedYears = years.filter((year) => year !== 'WeightedAverage');
      updatedYears.push('WeightedAverage');

      return updatedYears.map((year) => ({
        value: year,
        viewValue: year === 'WeightedAverage' ? l('_ProbabilityWeighted') : year,
      }));
    }
    return [];
  }, [l, plotData, isEnterpriseValuePlotDataAvailable]);

  const viewOptions = useMemo(() => {
    let baseArray: SelectOption[];
    if (plotData) {
      if (chartType === SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution) {
        baseArray = [{ value: ViewOption.AllInstruments, viewValue: l('_AllInstruments') }];
      } else {
        baseArray = isEnterpriseValuePlotDataAvailable
          ? [
              { value: ViewOption.EV, viewValue: l('_EnterpriseValue') },
              { value: ViewOption.Equity, viewValue: l('_Equity') },
            ]
          : [{ value: ViewOption.Equity, viewValue: l('_Equity') }];
      }

      return [
        ...baseArray,
        ...sortedValuedInstruments.map((instrument) => ({
          value: instrument.instrumentId,
          viewValue: instrument.instrumentNarrative,
        })),
      ];
    }
    return [];
  }, [chartType, isEnterpriseValuePlotDataAvailable, l, plotData, sortedValuedInstruments]);

  let defaultYearOption: string,
    defaultViewOption: string | ViewOption | undefined,
    defaultZoomMinimumOption: number | undefined,
    defaultZoomMaximumOption: number | undefined,
    defaultZoomModeOption: ChartZoomMode | undefined;

  switch (chartType) {
    case SimulatedOutcomesChartTypes.RealWorldOutcome:
      defaultYearOption =
        storedRealWorldOutcomeYear ?? (yearOptions.length > 0 ? yearOptions[0].value : '');
      defaultViewOption = storedRealWorldOutcomeInstrument ?? viewOptions[0].value;
      defaultZoomMinimumOption = storedRealWorldOutcomeZoom.selectedZoomMinimum ?? undefined;
      defaultZoomMaximumOption = storedRealWorldOutcomeZoom.selectedZoomMaximum ?? undefined;
      defaultZoomModeOption = storedRealWorldOutcomeZoom.selectedZoomMode;
      break;
    case SimulatedOutcomesChartTypes.RiskFreeOutcome:
      defaultYearOption =
        storedRiskFreeOutcomeYear ?? (yearOptions.length > 0 ? yearOptions[0].value : '');
      defaultViewOption = storedRiskFreeOutcomeInstrument ?? viewOptions[0].value;
      defaultZoomMinimumOption = storedRiskFreeOutcomeZoom.selectedZoomMinimum ?? undefined;
      defaultZoomMaximumOption = storedRiskFreeOutcomeZoom.selectedZoomMaximum ?? undefined;
      defaultZoomModeOption = storedRiskFreeOutcomeZoom.selectedZoomMode;
      break;
    case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
      defaultYearOption =
        storedEVRealWorldDistributionYear ?? (yearOptions.length > 0 ? yearOptions[0].value : '');
      defaultZoomMinimumOption = storedEVRealWorldDistributionZoom.selectedZoomMinimum ?? undefined;
      defaultZoomMaximumOption = storedEVRealWorldDistributionZoom.selectedZoomMaximum ?? undefined;
      defaultZoomModeOption = storedEVRealWorldDistributionZoom.selectedZoomMode;
      break;
    case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
      defaultYearOption =
        storedEquityRealWorldDistributionYear ??
        (yearOptions.length > 0 ? yearOptions[0].value : '');
      defaultZoomMinimumOption =
        storedEquityRealWorldDistributionZoom.selectedZoomMinimum ?? undefined;
      defaultZoomMaximumOption =
        storedEquityRealWorldDistributionZoom.selectedZoomMaximum ?? undefined;
      defaultZoomModeOption = storedEquityRealWorldDistributionZoom.selectedZoomMode;
      break;
    case SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution:
      defaultYearOption =
        storedInstrumentsRealWorldDistributionYear ??
        (yearOptions.length > 0 ? yearOptions[0].value : '');
      defaultViewOption = storedInstrumentsRealWorldDistributionInstrument;
      defaultZoomMinimumOption =
        storedInstrumentsRealWorldDistributionZoom.selectedZoomMinimum ?? undefined;
      defaultZoomMaximumOption =
        storedInstrumentsRealWorldDistributionZoom.selectedZoomMaximum ?? undefined;
      defaultZoomModeOption = storedInstrumentsRealWorldDistributionZoom.selectedZoomMode;
      break;
    default:
      defaultYearOption = yearOptions.length > 0 ? yearOptions[0].value : '';
      defaultViewOption = viewOptions[0].value;
      defaultZoomMinimumOption = undefined;
      defaultZoomMaximumOption = undefined;
      defaultZoomModeOption = ChartZoomMode.DefaultView;
      break;
  }

  const zoomModeOption = defaultZoomModeOption ?? ChartZoomMode.DefaultView;
  const [zoomMin, setZoomMin] = useState<number | undefined>(defaultZoomMinimumOption);
  const [zoomMax, setZoomMax] = useState<number | undefined>(defaultZoomMaximumOption);
  const [zoomMode, setZoomMode] = useState<ChartZoomMode>(zoomModeOption);
  const zoomMinRef = useRef(defaultZoomMinimumOption);
  const zoomMaxRef = useRef(defaultZoomMaximumOption);
  const zoomModeRef = useRef(zoomMode);

  useEffect(() => {
    zoomMinRef.current = zoomMin;
  }, [zoomMin]);

  useEffect(() => {
    zoomMaxRef.current = zoomMax;
  }, [zoomMax]);

  useEffect(() => {
    zoomModeRef.current = zoomMode;
  }, [zoomMode]);

  const isDefaultYearOptionValid = yearOptions.some((option) => option.value === defaultYearOption);
  const isDefaultViewOptionValid = viewOptions.some((option) => option.value === defaultViewOption);

  defaultYearOption = isDefaultYearOptionValid
    ? defaultYearOption
    : yearOptions.length > 0
    ? yearOptions[0].value
    : '';

  defaultViewOption = isDefaultViewOptionValid ? defaultViewOption : viewOptions[0].value;

  const formMethods = useForm<{
    viewOption: ViewOption | string;
    yearOption: string;
  }>({
    ...formConfigBase,
    defaultValues: {
      viewOption: defaultViewOption,
      yearOption: defaultYearOption,
    },
  });

  const { watch, setValue } = formMethods;

  const selectedView = watch('viewOption');
  const selectedYear = watch('yearOption');

  const selectedViewRef = useRef(selectedView);
  const selectedYearRef = useRef(selectedYear);

  useEffect(() => {
    selectedViewRef.current = selectedView;
    selectedYearRef.current = selectedYear;
  }, [selectedYear, selectedView]);

  useEffect(
    () => () => {
      const selectedZoom = {
        selectedZoomMinimum: zoomMinRef.current ?? undefined,
        selectedZoomMaximum: zoomMaxRef.current ?? undefined,
        selectedZoomMode: zoomModeRef.current ?? undefined,
      };
      switch (chartType) {
        case SimulatedOutcomesChartTypes.RealWorldOutcome:
          dispatch(setRealWorldDistributionsRealWorldChartSelectedYear(selectedYearRef.current));
          dispatch(
            setRealWorldDistributionsRealWorldChartSelectedInstrument(selectedViewRef.current)
          );
          dispatch(setRealWorldDistributionsRealWorldChartSelectedZoom(selectedZoom));
          break;
        case SimulatedOutcomesChartTypes.RiskFreeOutcome:
          dispatch(setOpmDashboardRiskNeutralChartSelectedYear(selectedYearRef.current));
          dispatch(setOpmDashboardRiskNeutralChartSelectedInstrument(selectedViewRef.current));
          dispatch(setOpmDashboardRiskNeutralChartSelectedZoom(selectedZoom));
          break;
        case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
          dispatch(setRealWorldDistributionsRealWorldEvChartSelectedYear(selectedYearRef.current));
          dispatch(setRealWorldDistributionsRealWorldEvChartSelectedZoom(selectedZoom));
          break;
        case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
          dispatch(
            setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedYear(
              selectedYearRef.current
            )
          );
          dispatch(
            setRealWorldDistributionsRealWorldTotalAttributableEquityChartSelectedZoom(selectedZoom)
          );
          break;
        case SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution:
          dispatch(
            setRealWorldDistributionsRealWorldByInstrumentChartSelectedYear(selectedYearRef.current)
          );
          dispatch(
            setRealWorldDistributionsRealWorldByInstrumentChartSelectedInstrument(
              selectedViewRef.current
            )
          );
          dispatch(setRealWorldDistributionsRealWorldByInstrumentChartSelectedZoom(selectedZoom));
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // measure taken to prevent the charts from breaking if selectedYear
  // is removed, and the user re-runs the calc whilst viewing the charts
  if (!yearOptions.find((y) => y.value === selectedYear)) {
    setValue('yearOption', yearOptions[0].value ?? 0);
  }

  // measure taken to prevent the charts from breaking if selectedView
  // is removed, and the user re-runs the calc whilst viewing the charts
  if (!viewOptions.find((v) => v.value === selectedView)) {
    setValue('viewOption', viewOptions[0].value ?? 0);
  }

  const getChartTitle = (
    selectedView: ViewOption | string,
    chartType: SimulatedOutcomesChartTypes
  ) => {
    switch (chartType) {
      case SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution:
        return selectedView === ViewOption.AllInstruments
          ? l(ChartTitles.SimulatedDistributionRealWorldInstruments)
          : l(ChartTitles.SimulatedDistributionRealWorldByInstruments);
      default:
        return l(chartTitle);
    }
  };

  const zoomButtonText =
    (chartType === SimulatedOutcomesChartTypes.RiskFreeOutcome ||
      chartType === SimulatedOutcomesChartTypes.RealWorldOutcome) &&
    (zoomMode === ChartZoomMode.AllData || zoomMode === ChartZoomMode.Zoomed)
      ? l('_DefaultView')
      : l('_ShowAllData');

  const handleZoom = () => {
    if (isSimulatedOutcomeChart) {
      setZoomMode(
        zoomMode === ChartZoomMode.DefaultView ? ChartZoomMode.AllData : ChartZoomMode.DefaultView
      );
    } else {
      setZoomMode(ChartZoomMode.AllData);
    }
  };

  const handleManualZoom = (min: number | undefined, max: number | undefined) => {
    setZoomMode(ChartZoomMode.Zoomed);
    setZoomMin(min);
    setZoomMax(max);
  };

  const isZoomButtonDisabled = !isSimulatedOutcomeChart && zoomMode !== ChartZoomMode.Zoomed;

  return (
    <DashboardWidgetContainer
      title={getChartTitle(selectedView, chartType)}
      isExpanded={chartTitle === ChartTitles.SimulatedDistributionRiskNeutral ? isExpanded : true}
      opm
      actions={
        plotData ? (
          <FormProvider {...formMethods}>
            <form className="dashboard-widget-controls-opm">
              <Button
                appearance={ButtonAppearance.DEFAULT_SECONDARY}
                onClick={handleZoom}
                className="dashboard-widget-controls-opm__button"
                disabled={isZoomButtonDisabled}>
                {zoomButtonText}
              </Button>
              {chartTitle === ChartTitles.SimulatedDistributionRiskNeutral && (
                <Button
                  appearance={ButtonAppearance.DEFAULT_SECONDARY}
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="dashboard-widget-controls-opm__button"
                  ignoreReadOnly>
                  {isExpanded ? ToggleWidget.Collapse : ToggleWidget.Expand}
                </Button>
              )}
              {chartType === SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution && (
                <div className="dashboard-widget-controls-opm__item">
                  <FormSelect
                    ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartView') })}
                    name="viewOption"
                    required
                    options={viewOptions}
                    isGapless
                    ignoreReadOnly
                  />
                </div>
              )}
              {chartType !== SimulatedOutcomesChartTypes.EVRealWorldDistribution &&
                chartType !== SimulatedOutcomesChartTypes.EquityRealWorldDistribution &&
                chartType !== SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution && (
                  <div className="dashboard-widget-controls-opm__item">
                    <FormSelect
                      ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartView') })}
                      name="viewOption"
                      required
                      options={viewOptions}
                      isGapless
                      ignoreReadOnly
                    />
                  </div>
                )}
              <div className="dashboard-widget-controls-opm__item">
                <FormSelect
                  ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartYear') })}
                  name="yearOption"
                  required
                  options={yearOptions}
                  isGapless
                  ignoreReadOnly
                />
              </div>
            </form>
          </FormProvider>
        ) : undefined
      }>
      {chartType === SimulatedOutcomesChartTypes.RiskFreeOutcome ||
      chartType === SimulatedOutcomesChartTypes.RealWorldOutcome ? (
        <DistributionChart
          isEvSimulation={isEnterpriseValuePlotDataAvailable}
          selectedYear={selectedYear}
          selectedView={selectedView}
          zoomMode={zoomMode}
          zoomMinimum={zoomMin}
          zoomMaximum={zoomMax}
          chartDistribution={plotData}
          isRiskNeutralChart={chartType === SimulatedOutcomesChartTypes.RiskFreeOutcome}
          handleManualZoom={handleManualZoom}
        />
      ) : (
        <StackedDistributionChart
          selectedYear={selectedYear}
          selectedView={selectedView}
          chartType={chartType}
          zoomMode={zoomMode}
          handleManualZoom={handleManualZoom}
          zoomMinimum={zoomMin}
          zoomMaximum={zoomMax}
        />
      )}
    </DashboardWidgetContainer>
  );
};
