import { FC } from 'react';
import { SUPPORT_EMAIL } from '@app/shared/constants/emails';

const NoAccessContent: FC = (): JSX.Element => {
  return (
    <>
      <h1 className="heading-2">You don&apos;t have access to Equity Reward Analytics</h1>
      <p>
        To get access, please&#160;
        <a href={`mailto:${SUPPORT_EMAIL}`}>contact the Deal Reward team.</a>
      </p>
    </>
  );
};

export default NoAccessContent;
