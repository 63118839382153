import { FC } from 'react';
import { ChartTitles, SimulatedOutcomesChartTypes } from '../../opm-charts/opm-enums';
import { DistributionSetup } from '../../opm-charts/DistributionSetup';

interface SimulatedOutcomesProps {
  chartType: SimulatedOutcomesChartTypes;
}

export const SimulatedOutcomes: FC<SimulatedOutcomesProps> = ({ chartType }): JSX.Element => {
  let chartTitle: ChartTitles;
  switch (chartType) {
    case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
      chartTitle = ChartTitles.SimulatedDistributionByComponentsRealWorldEV;
      break;
    case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
      chartTitle = ChartTitles.SimulatedDistributionByComponentsRealWorldTotalAttributableEquity;
      break;
    case SimulatedOutcomesChartTypes.RealWorldOutcome:
      chartTitle = ChartTitles.SimulatedDistributionRealWorld;
      break;
    case SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution:
      chartTitle = ChartTitles.SimulatedDistributionRealWorldInstruments;
      break;
    default:
      chartTitle = ChartTitles.SimulatedDistributionRiskNeutral;
      break;
  }

  return <DistributionSetup chartType={chartType} chartTitle={chartTitle} />;
};
