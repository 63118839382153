import { FC } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { RouteConstants } from '../../RouteConstants';

export const CapitalStructurePage: FC = (): JSX.Element => {
  const storedCapitalStructureTab = useAppSelector(
    (state) => state.uiValues.userSelections.capitalStructure.selectedTab
  );
  const { generatePath } = usePwcNavigate();

  const selectedTab = storedCapitalStructureTab ?? RouteConstants.BuildStructure;

  return <PwcNavigate route={generatePath(selectedTab)} />;
};
