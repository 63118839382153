export const SignalrMessageTypes = {
  MessageReceived: 'messageReceived',
  RunOpmCalculation: 'runOpmCalculation',
  RunOpmExport: 'runOpmExport',
  SaveProject: 'saveProject',
  OpmCalculationRunOnSave: 'opmCalculationRunOnSave',
  OpmCalculationComplete: 'opmCalculationComplete',
  OpmExportComplete: 'opmExportComplete',
  SaveComplete: 'saveComplete',
  OperationProgressUpdate: 'operationProgressUpdate',
  OperationError: 'operationError',
  ValidationError: 'validationError',
  SystemSettingsUpdate: 'systemSettingsUpdate',
  RunClientScheduleExport: 'runClientScheduleExport',
  ClientScheduleExportComplete: 'clientScheduleExportComplete',
};
