export const DEFAULT_EMPTY_BENCHMARKING = {
  benchmarkingOnly: false,
  dealOverview: {
    dealEvent: null,
  },
  dealMetrics: {
    investmentMetric: null,
    entryOfTarget: null,
    debtMaturityDate: null,
    thirdPartyLeveragePercentOfEV: null,
    investmentHorizonYears: null,
    investorMonitoringExitFees: null,
  },
  sponsorInvestment: {
    sponsorInvestmentStructureStrip: null,
    percentageOfFixedReturnInstrumentWithinStrip: null,
    couponOnPreferredCapitalInstrumentPercentage: null,
  },
  managementParticipation: {
    rolloverOfManagementNetProceeds: null,
    numberOfParticipantsInTheMIP: null,
    sweetSubscriptionCostPlusFMVLoans: null,
    ceoPercentOfSweetEquityParticipation: null,
    cfoPercentOfSweetEquityParticipation: null,
    chairmanPercentOfSweetEquityParticipation: null,
    managementStripPercentage: null,
    managementStripTotalSubscriptionCost: null,
  },
  sweetEquityDetails: {
    natureOfSweetEquityParticipationReturn: null,
    basisOfHurdle: null,
    hurdleRateMoM: null,
    hurdleRateIRR: null,
    structureOfRatchetMechanism: null,
    detailsOfAdditionalRatchets: null,
    sweetEquityPercentOfTotalOrdinaryEquity: null,
    maxSweetEquityPercentPostRatchet: null,
  },
  vestingAndLiquidity: {
    vestingCondition: null,
    doesVestingAccelerateOnExitOrChangeOfControl: null,
    anyLiquidityProvisions: null,
    dateOfLiquidityProvisions: null,
    esgTermsAttachedToSweetEquity: null,
    esgTermsInherentOrVestingRelated: null,
  },
  taxValuation: {
    thirdPartyTaxValuationUndertakenOnEntry: null,
    taxValuationMethodologyAdopted: null,
    exchangeRateAtValuationDateGBP: null,
  },
  schemeDesignAndManagementAdvice: {
    mipDesign: null,
    mipDesignComments: null,
    didManagementReceiveTaxAdvice: null,
    didManagementReceiveCommercialAdvice: null,
    didManagementReceiveValuationAdvice: null,
    broaderComments: null,
  },
  returnsAnalysis: {
    low: {
      impliedSponsorIRR: null,
      sweetReturnAbsolute: null,
      sweetReturnAsPercentOfTotalIncreaseInAttributableEquity: null,
      totalManagementIRR: null,
      managementIRRSweetOnly: null,
      managementMoMSweetOnly: null,
      managementStripReturn: null,
      totalManagementMom: null,
      envyRatio: null,
      envyRatioSweetOnly: null,
    },
    mid: {
      impliedSponsorIRR: null,
      sweetReturnAbsolute: null,
      sweetReturnAsPercentOfTotalIncreaseInAttributableEquity: null,
      totalManagementIRR: null,
      managementIRRSweetOnly: null,
      managementMoMSweetOnly: null,
      managementStripReturn: null,
      totalManagementMom: null,
      envyRatio: null,
      envyRatioSweetOnly: null,
    },
    high: {
      impliedSponsorIRR: null,
      sweetReturnAbsolute: null,
      sweetReturnAsPercentOfTotalIncreaseInAttributableEquity: null,
      totalManagementIRR: null,
      managementIRRSweetOnly: null,
      managementMoMSweetOnly: null,
      managementStripReturn: null,
      totalManagementMom: null,
      envyRatio: null,
      envyRatioSweetOnly: null,
    },
  },
};
