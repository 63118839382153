import { SVGProps } from 'react';
const SvgUserAccess = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M7.7 9c2.43 0 4.4-2.015 4.4-4.5S10.13 0 7.7 0C5.27 0 3.3 2.015 3.3 4.5S5.27 9 7.7 9Zm0-7.313c1.516 0 2.75 1.262 2.75 2.813 0 1.55-1.234 2.813-2.75 2.813S4.95 6.05 4.95 4.5c0-1.55 1.234-2.813 2.75-2.813Zm1.743 9H5.957C2.667 10.688 0 13.416 0 16.78 0 17.455.533 18 1.191 18h13.016c.66 0 1.193-.545 1.193-1.22 0-3.364-2.668-6.093-5.957-6.093Zm-7.769 5.626c.23-2.212 2.063-3.938 4.283-3.938h3.486c2.22 0 4.025 1.727 4.283 3.938H1.674ZM16.47 11.25H13.93a7.185 7.185 0 0 1 2.57 5.53c0 .45-.13.865-.344 1.22H20.9c.608 0 1.1-.506 1.1-1.157 0-3.076-2.461-5.593-5.53-5.593ZM14.85 9c2.128 0 3.85-1.761 3.85-3.938 0-2.176-1.722-3.937-3.85-3.937-.863 0-1.651.3-2.293.791.396.778.643 1.649.643 2.584 0 1.249-.41 2.396-1.086 3.33A3.796 3.796 0 0 0 14.85 9Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUserAccess;
