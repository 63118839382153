import { FC } from 'react';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import DragArea from '@app/shared/components/drag-area/DragArea';
import styles from './build-structure.module.scss';
import InstrumentTile from './InstrumentTile';
import CapitalStructure from './CapitalStructure';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { isInstrumentOwnerAmountEntered, stringArrayToList } from '@app/shared/helpers';
import { MAX_INSTRUMENT_COUNT } from '@app/shared/constants/capital-structure';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { setCapitalStructureSelectedTab } from '@app/core/store/ui-values-slice';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';

const PARENT_CLASSNAME = 'build-structure';

const BuildStructureContent: FC = (): JSX.Element => {
  useSetSelectedTab(RouteConstants.BuildStructure, setCapitalStructureSelectedTab);

  const project = useAppSelector((state) => state.project.projectDraft);

  const isReadOnly = useIsReadOnly();

  const { equityInstruments } = project;

  const instrumentsWithNoAmount = equityInstruments
    .filter((instrument) => !isInstrumentOwnerAmountEntered(instrument))
    .map((instrument) => instrument.instrumentNarrative || EmptyValues.EnDash);

  const missingAmountMessage = instrumentsWithNoAmount.length
    ? `Add Amount invested to ${stringArrayToList(instrumentsWithNoAmount)}`
    : undefined;

  const shouldDisableTiles = project.equityInstruments.length >= MAX_INSTRUMENT_COUNT || isReadOnly;

  return (
    <div className={styles[`${PARENT_CLASSNAME}`]}>
      <div className={styles[`${PARENT_CLASSNAME}__actions`]}>
        {Object.entries(InstrumentType).map(([key, value]) => (
          <InstrumentTile key={key} instrumentType={value} isDisabled={shouldDisableTiles} />
        ))}
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__area`]}>
        <DragArea notificationMessage={missingAmountMessage}>
          <CapitalStructure />
        </DragArea>
      </div>
    </div>
  );
};

export default BuildStructureContent;
