import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '@core/hooks/redux-hooks';

export const DealThesisSpacerRow2: FC = (): JSX.Element => {
  const { caseId } = useParams();
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedInputs);
  const numberOfResultsForecastYears = Object.keys(results.cases[caseId!].forecastDates).length;
  const caseData = project.pwermInput.cases.find((caseItem) => caseItem.caseId === caseId);
  // projectDraft data sliced such that an increase in forecast years is rendered once results are available
  const caseForecasts = caseData?.forecasts.slice(0, numberOfResultsForecastYears);
  return (
    <tr>
      <th className="table-primary__cell--vertical-separator table-primary__cell--section-separator" />
      <td className="table-primary__cell--section-separator" />
      {caseForecasts?.map((_, index) => (
        <td key={index + 'spacer-row'} className="table-primary__cell--section-separator" />
      ))}
      <td className="table-primary__cell--section-separator" />
    </tr>
  );
};
