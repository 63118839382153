export const returnFormattedInputValue = (fieldValue: any, valueAsNumber?: boolean) => {
  return valueAsNumber
    ? fieldValue || fieldValue === '0' || fieldValue === 0
      ? Number(fieldValue)
      : null
    : fieldValue;
};
export const scientificToString = (num: number): string => {
  if (num.toString().includes('e')) {
    const exponent = parseInt(num.toString().split('e')[1], 10);
    const decimalPlaces = Math.abs(exponent);
    return num.toFixed(decimalPlaces + 1);
  } else {
    return num.toString();
  }
};
