import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

const DropdownSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 25" {...props}>
      <path
        d="M6.49377 9.5L12.4938 15.5L18.4938 9.5"
        strokeWidth="2"
        strokeLinecap="round"
        fill="transparent"
        strokeLinejoin="round"
      />

      <rect
        width="23.2533"
        height="24"
        stroke="transparent"
        fill="transparent"
        transform="translate(0.746704 0.5)"
      />
    </SvgIcon>
  );
};

export default memo(DropdownSvg);
