import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { DUAL_EVENT_OPTIONS } from '@app/shared/constants/dropdown-values/benchmark/dual-event-options';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  Country,
  Currency,
  Denomination,
  ProjectStatus,
  RegionOfOperations,
  Sector,
  SubSector,
} from '@app/shared/models/contracts/enums/shared-enums';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { shortDateViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { requiredValidator } from '@core/validations/hook-forms/validators';
import { enumKeyByValue } from '@app/shared/helpers';

const DealOverview: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);

  const { status, storeBenchmarkingData } = project;

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>Deal Overview</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue label="PE House/Sponsor" value={project.details.sponsor} isGapless />
        </div>
        <div className="form-row__column">
          <FormValue
            label="Region of Operations"
            value={
              project.details.regionOfOperations
                ? RegionOfOperations[project.details.regionOfOperations]
                : ''
            }
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label="Denomination"
            value={Denomination[project.details.denomination]}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue label="Target Trade Name" value={project.details.targetTradeName} isGapless />
        </div>
        <div className="form-row__column">
          <FormValue
            label="Country of Main Operations"
            value={
              project.details.countryOfMainOperations
                ? Country[project.details.countryOfMainOperations]
                : ''
            }
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue label="Currency" value={Currency[project.details.currency]} isGapless />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label="Sector"
            value={project.details.sector ? Sector[project.details.sector] : ''}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="dealOverview.dealEvent"
            label="Deal Event"
            options={DUAL_EVENT_OPTIONS}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
        <div className="form-row__column" />
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label="Sub Sector"
            value={project.details.subSector ? SubSector[project.details.subSector] : ''}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label="Date of Completion"
            value={project.investmentDate}
            isGapless
            {...shortDateViewRules}
          />
        </div>
        <div className="form-row__column" />
      </div>
    </Card>
  );
};

export default DealOverview;
