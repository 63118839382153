import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { selectMaintenanceModeSettings } from '@app/core/store/ui-values-slice-selectors';
import { dateFormatter } from '@app/shared/formatters';
import styles from './under-maintenance.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  calculateTimeDifferenceInHours,
  isTimeInPast,
} from '@app/shared/helpers/maintenance/date-time-calculations';

const PARENT_CLASSNAME = 'maintenance-banner';

const MaintenanceBanner: React.FC = () => {
  const maintenanceModeSettings = useAppSelector(selectMaintenanceModeSettings);
  const { l } = useLocale();
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const formattedStartTime = maintenanceModeSettings.start
    ? dateFormatter(new Date(maintenanceModeSettings.start), {
        timeStyle: 'long',
      })
    : l('_NotAvailable');

  const shouldNotRenderMaintenanceNotice =
    maintenanceModeSettings.start === null ||
    maintenanceModeSettings.end === null ||
    isTimeInPast(currentDateTime, new Date(maintenanceModeSettings.start)) ||
    isTimeInPast(currentDateTime, new Date(maintenanceModeSettings.end));

  useEffect(() => {
    if (
      shouldNotRenderMaintenanceNotice ||
      (maintenanceModeSettings.start !== null &&
        calculateTimeDifferenceInHours(currentDateTime, new Date(maintenanceModeSettings.start)) <=
          2)
    ) {
      return;
    }

    const interval = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000); // update every minute

    return () => clearInterval(interval);
  }, [shouldNotRenderMaintenanceNotice, maintenanceModeSettings.start, currentDateTime]);

  if (
    shouldNotRenderMaintenanceNotice ||
    maintenanceModeSettings.start === null ||
    calculateTimeDifferenceInHours(currentDateTime, new Date(maintenanceModeSettings.start)) >= 2
  ) {
    return null;
  }
  return (
    <div className={styles[`${PARENT_CLASSNAME}__banner`]}>
      <div className={styles['text-content']}>
        <p>{l('_MaintenanceBannerMessage', { formattedStartTime: formattedStartTime })}</p>
        {maintenanceModeSettings.message && <p>{maintenanceModeSettings.message}</p>}
      </div>
    </div>
  );
};

export default MaintenanceBanner;
