import { FC, Fragment } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';

interface PayoutStatementsProps {
  trancheIndex: number;
  formSubmitHandler: () => void;
  activeEquityInstrument: EquityInstrumentDto | undefined;
}

const PARENT_CLASSNAME = 'payout-logic';
import styles from './../payout-logic.module.scss';
import classNames from 'classnames';
import { PayoutDriver, PayoutValue } from '@app/shared/models/contracts/enums/shared-enums';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { enumKeyByValue, enumToOptions } from '@app/shared/helpers';
import { PAYOUT_VALUE_OPTIONS } from '@app/shared/constants/dropdown-values/payout/payout-value-options';
import {
  DEFAULT_TRANCHE_PAYOUT_CALCULATION_CUSTOM_VALUE,
  DEFAULT_TRANCHE_PAYOUT_CALCULATION_VALUE,
} from '@app/shared/helpers/create-tranche';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { requiredValidator } from '@core/validations/hook-forms/validators';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import PayoutConditions from '@app/modules/projects/inputs/capital-structure/tabs/payout-logic/payout-conditions/PayoutConditions';
import { ALTERNATIVE_STATEMENT_CONDITION_OPTIONS } from '@app/shared/constants/dropdown-values/payout/alternative-statement-condition-options';
import { useLocale } from '@app/core/hooks/useLocale';

const PayoutStatements: FC<PayoutStatementsProps> = ({
  trancheIndex,
  formSubmitHandler,
}): JSX.Element => {
  const { l } = useLocale();
  const { control, getValues, setValue, clearErrors } = useFormContext<EquityInstrumentDto>();
  const PAYOUT_DRIVER_OPTIONS = enumToOptions(PayoutDriver);

  const { fields: statementFields } = useFieldArray({
    name: `tranches.${trancheIndex}.statements`,
    keyName: 'statementKey',
    control: control,
  });

  const watchTranches = getValues().tranches;

  const resetCustomValueField = (trancheIndex: number, statementIndex: number) => {
    setValue(
      `tranches.${trancheIndex}.statements.${statementIndex}.payoutCalculation.customValue`,
      DEFAULT_TRANCHE_PAYOUT_CALCULATION_CUSTOM_VALUE
    );
    clearErrors(
      `tranches.${trancheIndex}.statements.${statementIndex}.payoutCalculation.customValue`
    );
  };

  const handlePayoutDriverChange = async (trancheIndex: number, statementIndex: number) => {
    setValue(
      `tranches.${trancheIndex}.statements.${statementIndex}.payoutCalculation.payoutValue`,
      DEFAULT_TRANCHE_PAYOUT_CALCULATION_VALUE
    );
    changeAllPayoutDriverValues();
    resetCustomValueField(trancheIndex, statementIndex);
    await formSubmitHandler();
  };

  const changeAllPayoutDriverValues = () => {
    getValues().tranches?.map((trancheField, trancheIndex) => {
      trancheField.statements.map((statementField, statementIndex) => {
        setValue(
          `tranches.${trancheIndex}.statements.${statementIndex}.payoutCalculation.payoutDriver`,
          getValues(`tranches.0.statements.0.payoutCalculation.payoutDriver`)
        );
      });
    });
  };

  const handlePayoutValueChange = async (trancheIndex: number, statementIndex: number) => {
    resetCustomValueField(trancheIndex, statementIndex);
    await formSubmitHandler();
  };

  const renderCustomValueField = (trancheIndex: number, statementIndex: number) => {
    return (
      <div className={styles[`${PARENT_CLASSNAME}__statement-custom-value`]}>
        <FormField
          name={`tranches.${trancheIndex}.statements.${statementIndex}.payoutCalculation.customValue`}
          required
          rules={{
            ...requiredValidator,
          }}
          isGapless
          {...percentageFieldFormattingProps}
        />
      </div>
    );
  };

  return (
    <>
      {statementFields.map((statementItem, statementIndex) => {
        return (
          <Fragment key={statementItem.statementKey}>
            <div
              key={statementItem.statementKey + 'container' + statementItem?.order}
              className={styles[`${PARENT_CLASSNAME}__statement-container`]}>
              <h5 className={styles[`${PARENT_CLASSNAME}__item-condition`]}>
                {statementIndex >= 1 ? 'ELSE IF' : 'IF'}
              </h5>
              <div className={classNames(styles[`${PARENT_CLASSNAME}__item`])}>
                <div className={styles[`${PARENT_CLASSNAME}__statement`]}>
                  <PayoutConditions
                    trancheIndex={trancheIndex}
                    statementIndex={statementIndex}
                    formSubmitHandler={formSubmitHandler}
                  />
                </div>
                <div className={styles[`${PARENT_CLASSNAME}__condition`]}>
                  <h5 className={styles[`${PARENT_CLASSNAME}__condition-item`]}>Then</h5>
                  <div
                    className={classNames(styles[`${PARENT_CLASSNAME}__condition-item-controls`])}>
                    <div className={styles[`${PARENT_CLASSNAME}__statement-control-1`]}>
                      <FormSelect
                        ariaLabel={l('_SelectorAriaLabel', { label: l('_PayoutDriver') })}
                        disabled={statementIndex > 0 || trancheIndex > 0}
                        name={`tranches.${trancheIndex}.statements.${statementIndex}.payoutCalculation.payoutDriver`}
                        required
                        options={PAYOUT_DRIVER_OPTIONS}
                        onChange={() => handlePayoutDriverChange(trancheIndex, statementIndex)}
                        onBlur={(event) => event.stopPropagation()}
                        isGapless
                      />
                    </div>
                    <span className={styles[`${PARENT_CLASSNAME}__multiplier-item`]}>*</span>
                    <div className={styles[`${PARENT_CLASSNAME}__statement-control-2`]}>
                      <FormSelect
                        ariaLabel={l('_SelectorAriaLabel', { label: l('_PayoutValue') })}
                        name={`tranches.${trancheIndex}.statements.${statementIndex}.payoutCalculation.payoutValue`}
                        required
                        placeholder="0"
                        onBlur={(event) => event.stopPropagation()}
                        onChange={() => handlePayoutValueChange(trancheIndex, statementIndex)}
                        options={PAYOUT_VALUE_OPTIONS}
                        isGapless
                      />
                      {watchTranches?.[trancheIndex]?.statements[statementIndex]?.payoutCalculation
                        .payoutValue === enumKeyByValue(PayoutValue, PayoutValue.Custom) &&
                        renderCustomValueField(trancheIndex, statementIndex)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames([
                  styles[`${PARENT_CLASSNAME}__item`],
                  styles[`${PARENT_CLASSNAME}__item--secondary`],
                ])}>
                <h5 className={styles[`${PARENT_CLASSNAME}__item-condition`]}>Else</h5>
                <div className={styles[`${PARENT_CLASSNAME}__condition-control-1`]}>
                  <FormSelect
                    ariaLabel={l('_SelectorAriaLabel', {
                      label: l('_AlternativeStatementCondition'),
                    })}
                    disabled={trancheIndex > 0}
                    name={`tranches.${trancheIndex}.else`}
                    required
                    onBlur={(event) => event.stopPropagation()}
                    onChange={formSubmitHandler}
                    options={ALTERNATIVE_STATEMENT_CONDITION_OPTIONS}
                    isGapless
                  />
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export default PayoutStatements;
