import styleVariables from '@styles/core/_exported-variables.module.scss';
import { ShapeOptions } from '@mui/system';

const shape: ShapeOptions = {
  borderRadius4: styleVariables.radiusPrimary,
  borderRadius8: styleVariables.radiusSecondary,
  borderRadius15: styleVariables.radiusTertiary,
  borderRadiusRound: styleVariables.radiusRound,
};

export const focusOutline = styleVariables.focusOutline;

declare module '@mui/system/createTheme/shape' {
  interface Shape {
    borderRadius4: string;
    borderRadius8: string;
    borderRadius15: string;
    borderRadiusRound: string;
  }
}

export default shape;
