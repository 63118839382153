import { useAppSelector } from '@app/core/hooks/redux-hooks';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import { Alignment } from '@app/shared/enums/alignment.enum';
import SvgChevron from '@app/shared/icons/Chevron';
import { FC, useMemo } from 'react';
import styles from '../../../opm-inputs.module.scss';
import useLocalStorageCollapseState from '@app/core/hooks/useLocalStoreCollapse';
import { useLocale } from '@app/core/hooks/useLocale';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  numericBaseFieldFormattingProps,
  textFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { requiredValidator } from '@app/core/validations/hook-forms/validators';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import PlusSvg from '@app/shared/icons/Plus';
import MinusRoundedSvg from '@app/shared/icons/MinusRounded';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  DebtItemDto,
  NetDebtItemValueDto,
  PerYearValuesDto,
} from '@app/shared/models/contracts/project-dto';
import { v4 as uuidv4 } from 'uuid';
import { getOpmNetDebtIndex } from '@app/core/store/project-slice-selectors';
import { CalcMethod, OpmInputDataSource } from '@app/shared/models/contracts/enums/shared-enums';
import { OpmInputFormModel } from '../../../OpmInputs';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import SvgRevert from '@app/shared/icons/Revert';
import {
  getInitialTotalNetDebt,
  getTotalNetDebtValues,
} from '@app/core/inputs/getOpmInputCalculatedValues';

interface OpmNetDebtSectionProps {
  submitData: () => void;
}

const OpmNetDebtSection: FC<OpmNetDebtSectionProps> = ({ submitData }): JSX.Element => {
  const { control, setValue } = useFormContext<OpmInputFormModel>();
  const project = useAppSelector((state) => state.project.projectDraft);
  const shouldGetValuesFromPwerm = project.details.calcMethod !== CalcMethod.OPM;
  const { l } = useLocale();

  const [expanded, setExpanded] = useLocalStorageCollapseState('expanded-net-debt', project.id);

  const { replace: replaceNetDebtField } = useFieldArray({
    name: 'perYearInputs',
    control: control,
  });

  const { append: appendNetDebtField, remove: removeDebtField } = useFieldArray({
    name: 'netDebtItems',
    control: control,
  });

  const opmInputs = project.opmInput;
  const netDebtItems = opmInputs.netDebtItems ?? [];
  const perYearInputs = opmInputs.perYearInputs;
  const forecastDates = perYearInputs.map((yi) => yi.forecastDate);
  const pwermEnabled = project.details.calcMethod !== CalcMethod.OPM;

  const totalNetDebtValues = useMemo(
    () => getTotalNetDebtValues(project, forecastDates),
    [forecastDates, project]
  );

  const initialTotalNetDebt = useMemo(() => getInitialTotalNetDebt(project), [project]);

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  const maxColspanCount =
    project.opmInput.forecastYears !== null
      ? project.opmInput.forecastYears + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
      : 0;

  const addNetDebt = () => {
    const netDebtItemId = uuidv4();
    appendNetDebtField(
      {
        netDebtItemId,
        historicalValue: null,
        narrative: '',
      } as DebtItemDto,
      {
        shouldFocus: false,
      }
    );

    replaceNetDebtField([
      ...(perYearInputs.map((forecast: PerYearValuesDto) => {
        return {
          ...forecast,
          netDebtItems: [
            ...(forecast.netDebtItems ?? []),
            { netDebtItemId, value: 0 } as NetDebtItemValueDto,
          ],
        };
      }) ?? []),
    ]);

    submitData();
  };

  const removeNetDebtItem =
    (netDebtItemToDelete: DebtItemDto, netDebtIndex: number) => (): void => {
      removeDebtField(netDebtIndex);
      replaceNetDebtField([
        ...(perYearInputs.map((forecast: PerYearValuesDto) => {
          return {
            ...forecast,
            netDebtItems: [
              ...forecast.netDebtItems.filter(
                (netDebtItem) => netDebtItem.netDebtItemId !== netDebtItemToDelete.netDebtItemId
              ),
            ],
          };
        }) ?? []),
      ]);

      submitData();
    };

  const totalNetDebtSource = perYearInputs.some(
    (pyi) => pyi.totalNetDebtSource === OpmInputDataSource.Override
  );
  const initialTotalNetDebtSource =
    opmInputs.initialTotalNetDebtSource === OpmInputDataSource.Override;
  const displayOverride =
    shouldGetValuesFromPwerm && (totalNetDebtSource || initialTotalNetDebtSource);

  const handleRevert = () => {
    const initialTotalNetDebtValueToSet = getInitialTotalNetDebt(project);
    setValue('initialTotalNetDebt', initialTotalNetDebtValueToSet);
    const totalNetDebtValuesToSet = getTotalNetDebtValues(project, forecastDates);
    opmInputs.perYearInputs.forEach((yi, index) => {
      setValue(`perYearInputs.${index}.totalNetDebt`, totalNetDebtValuesToSet[index]);
    });
    submitData();
  };

  return (
    <>
      <tbody>
        <tr>
          <th className={`table-primary__cell--header-tertiary ${styles[`opm-header`]}`}>
            <div className={styles['opm-collapsable-header']}>
              <button
                type="button"
                aria-label={l('_ToggleSectionCollapse', {
                  section: l('_NetDebt'),
                })}
                aria-expanded={expanded}
                style={{ border: 'none', backgroundColor: 'none', background: 'none' }}
                onClick={toggleCollapse}
                data-testid="nd-collapse-button">
                {!expanded ? (
                  <SvgChevron
                    onClick={toggleCollapse}
                    className={styles[`opm-chevron-collapsed`]}
                  />
                ) : (
                  <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-expanded`]} />
                )}
              </button>
              {l('_NetDebt')}
            </div>
          </th>
          <th
            className={`table-primary__cell--header-tertiary ${styles[`opm-header-badge`]}`}
            colSpan={maxColspanCount - 1}>
            {project.details.calcMethod !== CalcMethod.OPM && (
              <div className={styles['opm-collapsable-header-badge']} data-testid="nd-badge">
                <Pill
                  size={PillSize.Xsmall}
                  noTextTransform
                  theme={displayOverride ? PillTheme.Orange : PillTheme.White}>
                  {displayOverride ? l('_Overridden') : l('_AutoPopulatedFromPWERM')}
                </Pill>
              </div>
            )}
          </th>
        </tr>
        {expanded && (
          <>
            {!pwermEnabled && (
              <>
                <tr>
                  <th
                    className={`table-primary__cell--vertical-separator ${
                      styles[`opm-table-rows`]
                    }`}>
                    {l('_CashNegative')}
                  </th>
                  <td>
                    <GridTextField
                      name="initialCashValue"
                      data-testid="initialCashValue"
                      {...numericBaseFieldFormattingProps}
                      placeholder="0"
                      alignment={Alignment.Right}
                    />
                  </td>
                  {perYearInputs.map((_, index) => (
                    <td key={index + 'cashValue'}>
                      <GridTextField
                        name={`perYearInputs[${index}].cashValue`}
                        data-testid={`perYearInputs[${index}].cashValue`}
                        {...numericBaseFieldFormattingProps}
                        placeholder="0"
                        rules={{ ...requiredValidator }}
                        alignment={Alignment.Right}
                      />
                    </td>
                  ))}
                  <td />
                </tr>
                {netDebtItems.map((netDebtItem, netDebtItemIndex) => (
                  <tr key={netDebtItem.netDebtItemId + 'debt-item'}>
                    <th
                      className={`table-primary__cell--vertical-separator ${
                        styles[`opm-table-rows`]
                      }`}>
                      <GridTextField
                        name={`netDebtItems[${netDebtItemIndex}].narrative`}
                        data-testid={`netDebtItems[${netDebtItemIndex}].narrative`}
                        rules={{ ...requiredValidator }}
                        placeholder="Add narrative"
                        {...textFieldFormattingProps}
                      />
                    </th>
                    <td>
                      <GridTextField
                        name={`netDebtItems[${netDebtItemIndex}].historicalValue`}
                        data-testid={`netDebtItems[${netDebtItemIndex}].historicalValue`}
                        {...numericBaseFieldFormattingProps}
                        placeholder="0"
                        alignment={Alignment.Right}
                      />
                    </td>
                    {perYearInputs.map((yearInputs, index) => (
                      <td key={index + 'debt-item-values'}>
                        <GridTextField
                          name={`perYearInputs[${index}].netDebtItems[${getOpmNetDebtIndex(
                            yearInputs,
                            netDebtItem
                          )}].value`}
                          data-testid={`perYearInputs[${index}].netDebtItems[${getOpmNetDebtIndex(
                            yearInputs,
                            netDebtItem
                          )}].value`}
                          {...numericBaseFieldFormattingProps}
                          placeholder="0"
                          rules={{
                            ...requiredValidator,
                          }}
                          alignment={Alignment.Right}
                        />
                      </td>
                    ))}
                    <td className="table-primary__cell--actions">
                      <Button
                        appearance={ButtonAppearance.CLEAN}
                        size={ButtonSize.TABLE_ACTION}
                        title={l('_DeleteItem')}
                        onClick={removeNetDebtItem(netDebtItem, netDebtItemIndex)}>
                        <MinusRoundedSvg />
                      </Button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td
                    className={`table-primary__cell--vertical-separator ${
                      styles[`opm-table-rows`]
                    }`}>
                    <div>
                      <Button
                        appearance={ButtonAppearance.DEFAULT_TERTIARY}
                        size={ButtonSize.TABLE_ACTION}
                        startIcon={<PlusSvg />}
                        onClick={() => addNetDebt()}>
                        {l('_AddItem')}
                      </Button>
                    </div>
                  </td>
                  <td colSpan={maxColspanCount - 1} />
                </tr>
              </>
            )}
            <tr data-testid="nd-row">
              <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
                {pwermEnabled ? l('_TotalNetDebt') : <strong>{l('_TotalNetDebt')}</strong>}
                {displayOverride && (
                  <SvgRevert
                    // stop the onBlur event occurring so we can control when the data is submitted
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => handleRevert()}
                    className={styles['revert-button']}
                  />
                )}
              </th>
              <td data-testid="initialTotalNetDebt">
                {pwermEnabled ? (
                  <GridTextField
                    className={[
                      shouldGetValuesFromPwerm &&
                      opmInputs.initialTotalNetDebtSource === OpmInputDataSource.Override
                        ? styles['revertable-value']
                        : '',
                    ]}
                    name="initialTotalNetDebt"
                    data-testid="initialTotalNetDebt.value"
                    {...numericBaseFieldFormattingProps}
                    alignment={Alignment.Right}
                  />
                ) : (
                  <CellValue
                    dataTestId="initialTotalNetDebt.value"
                    {...amountRules}
                    strong={!pwermEnabled}
                    value={initialTotalNetDebt}
                  />
                )}
              </td>
              {perYearInputs.map((_, index) => (
                <td
                  key={index + 'totalNetDebt'}
                  data-testid={`perYearInputs[${index}].totalNetDebt`}
                  className={index === 0 ? styles[`padding-right-index0`] : ''}>
                  {pwermEnabled ? (
                    <GridTextField
                      className={[
                        shouldGetValuesFromPwerm &&
                        perYearInputs[index].totalNetDebtSource === OpmInputDataSource.Override
                          ? styles['revertable-value']
                          : '',
                      ]}
                      name={`perYearInputs.${index}.totalNetDebt`}
                      data-testid={`perYearInputs.${index}.totalNetDebt.value`}
                      {...numericBaseFieldFormattingProps}
                      alignment={Alignment.Right}
                    />
                  ) : (
                    <CellValue
                      dataTestId={`perYearInputs.${index}.totalNetDebt`}
                      {...amountRules}
                      strong={!pwermEnabled}
                      value={totalNetDebtValues[index]}
                    />
                  )}
                </td>
              ))}
              <td />
            </tr>
          </>
        )}
      </tbody>
    </>
  );
};

export default OpmNetDebtSection;
