import {
  PwermCalculatedResultDto,
  PwermCalculatedResultKeys,
} from '@app/shared/models/contracts/pwerm-calculation-results-dto';

export function getCalculatedProjectValuesArray(
  name: keyof typeof PwermCalculatedResultKeys,
  calculatedProjectResults: PwermCalculatedResultDto[],
  selectBy: {
    caseId?: string;
    forecastId?: string;
    instrumentId?: string;
    trancheId?: string;
    trancheId2?: string;
    multipleId?: string;
  }
) {
  return calculatedProjectResults.filter(
    (r) =>
      r.name === name &&
      (!r.caseId || r.caseId === selectBy.caseId) &&
      (!r.forecastId || r.forecastId === selectBy.forecastId) &&
      (!r.instrumentId || r.instrumentId === selectBy.instrumentId) &&
      (!r.trancheId || r.trancheId === selectBy.trancheId) &&
      (!r.trancheId2 || r.trancheId2 === selectBy.trancheId2) &&
      (!r.multipleId || r.multipleId === selectBy.multipleId)
  );
}
