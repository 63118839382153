import { RouteObject } from 'react-router-dom';
import Users from '@app/modules/users/Users';

const usersRoutes: RouteObject[] = [
  {
    index: true,
    element: <Users />,
  },
];

export default usersRoutes;
