import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import * as exportActions from '@app/core/store/export-slice';

export const ClientScheduleExport = () => {
  const { l } = useLocale();
  const dispatch = useAppDispatch();
  const { projectDraft } = useAppSelector((state) => state.project);

  const handleExport = () => {
    dispatch(exportActions.exportClientSchedule({ projectId: projectDraft.id }));
  };

  return (
    <Button
      data-testid="client-schedule-export"
      onClick={() => handleExport()}
      appearance={ButtonAppearance.DEFAULT_PRIMARY}
      size={ButtonSize.SMALL}>
      {l('_ClientSchedule')}
    </Button>
  );
};
