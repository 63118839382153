import { FC, useEffect, useMemo, useRef, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import { useAppSelector } from '@core/hooks/redux-hooks';
import {
  abbreviatedValueFormatter,
  calculatedMultipleValueFormatterProps,
  dateFormatter,
  numberValueFormatter,
} from '@app/shared/formatters';
import { groupBy } from '@app/shared/helpers';
import { DefaultDashboardDropdownValues } from '@app/modules/projects/dashboard/widgets/default-dashboard-dropdown-values-enum';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { UseFormSetValue } from 'react-hook-form';
import { useCSSVariables } from '@core/hooks/useCSSVariables';
import styles from '../../widgets/ev-evolution/ev-evolution-chart.module.scss';
import { getCaseWithMostForecasts } from '@app/shared/helpers/getCasesWithMostForecasts';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { useLocale } from '@app/core/hooks/useLocale';
import { DenominationMultiplier } from '@app/shared/models/contracts/enums/shared-enums';
import { axisLabelFormatter } from '../../opm-charts/format-helpers';

HighchartsMore(Highcharts);

interface EVEvolutionChartProps {
  selectedCase?: CaseDto;
  selectedViewOption: string;
  setSelectedViewOption: UseFormSetValue<{ viewOption: string }>;
  isExpanded: boolean;
}

interface EVChartTooltipData {
  exitValueDriverName: string;
  currentEV?: string;
  exitValueDriver: number;
  weightedMultipleAverage: number;
  lowEV: number;
  highEV: number;
}

interface CustomHCPoint<T> extends Highcharts.Point {
  custom: T;
}

export const Pwerm2EVEvolutionChart: FC<EVEvolutionChartProps> = ({
  selectedCase,
  selectedViewOption,
  setSelectedViewOption,
  isExpanded,
}): JSX.Element => {
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const multiplier = DenominationMultiplier[denomination];
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.evolution
  );
  const { l } = useLocale();

  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const sortedProjectByCaseWithNonZeroProbability = getCasesWithNonZeroProbability(
    sortedProjectByCase.pwermInput.cases
  );

  const isSpecificCaseSelected = !(
    selectedViewOption === DefaultDashboardDropdownValues.AllCases ||
    selectedViewOption === DefaultDashboardDropdownValues.WeightedAverage
  );

  const exitValueDriverName = results.valueDriverName;

  const {
    colorList: colors,
    font: { fontFamilyPrimary },
  } = useCSSVariables();
  const [
    colorPrimary,
    colorSecondary,
    colorTertiary,
    colorAccents1,
    colorAccents2,
    colorAccents3,
    colorAccents4,
    colorAccents5,
    colorNeutralsB50,
    colorNeutralsB80,
    colorNeutralsB90,
  ] = colors;

  const chartColors = [
    colorPrimary,
    colorSecondary,
    colorTertiary,
    colorAccents1,
    colorAccents2,
    colorAccents4,
  ];

  const categories = [
    l('_Current'),
    ...getCaseWithMostForecasts(sortedProjectByCaseWithNonZeroProbability).forecasts.map(
      (forecast) => dateFormatter(forecast.forecastYear, { year: 'numeric' })
    ),
  ];

  const legendBase: Highcharts.LegendOptions = {
    align: 'left',
    alignColumns: false,
    verticalAlign: 'top',
    margin: 44,
    itemMarginTop: 16,
    symbolPadding: 8,
    symbolHeight: 16,
    symbolWidth: 16,
    symbolRadius: 8,
    itemDistance: 32,
    itemStyle: {
      fontSize: '1.4rem',
      fontWeight: '500',
    },
    x: -10,
  };

  function tooltipFormatter(this: Highcharts.TooltipFormatterContextObject) {
    const { custom: data } = this.point as CustomHCPoint<EVChartTooltipData>;
    const formattedData = {
      exitValueDriverName: exitValueDriverName ?? EmptyValues.EnDash,
      exitValueDriver: Number.isFinite(data.exitValueDriver)
        ? abbreviatedValueFormatter({
            value: data.exitValueDriver,
            fraction: 3,
          })
        : EmptyValues.EnDash,
      weightedMultipleAverage: Number.isFinite(data.weightedMultipleAverage)
        ? numberValueFormatter({
            value: data.weightedMultipleAverage,
            ...calculatedMultipleValueFormatterProps,
          })
        : EmptyValues.EnDash,
      currentEV: data.currentEV
        ? abbreviatedValueFormatter({
            value: Number(data.currentEV),
            fraction: 3,
          })
        : EmptyValues.EnDash,
      lowEV: Number.isFinite(data.lowEV)
        ? abbreviatedValueFormatter({
            value: data.lowEV,
            fraction: 3,
          })
        : EmptyValues.EnDash,
      highEV: Number.isFinite(data.highEV)
        ? abbreviatedValueFormatter({
            value: data.highEV,
            fraction: 3,
          })
        : EmptyValues.EnDash,
    };
    return `
          <div class=${styles['ev-evolution-point']}>
           <dl class=${styles['ev-evolution-point__list']}>
             <dt class=${styles['ev-evolution-point__label']}>
               ${formattedData?.exitValueDriverName}
             </dt>
             <dd class=${styles['ev-evolution-point__value']}>
               ${formattedData?.exitValueDriver}
             </dd>
             <dt
               class="${styles['ev-evolution-point__label']} ${
      styles['ev-evolution-point__label--spacer']
    }">
               ${l('_Multiple')}
             </dt>
             <dd class=${styles['ev-evolution-point__value']}>
               ${formattedData?.weightedMultipleAverage}
             </dd>
             ${
               formattedData.currentEV !== EmptyValues.EnDash
                 ? `
                 <dt class="${styles['ev-evolution-point__label']} ${
                     styles['ev-evolution-point__label--spacer']
                   }">
                    ${l('_CurrentEV')}
                  </dt>
                  <dd class=${styles['ev-evolution-point__value']}>
                    ${formattedData?.currentEV}
                  </dd>
                `
                 : ''
             }
             ${
               formattedData.lowEV !== EmptyValues.EnDash &&
               formattedData.highEV !== EmptyValues.EnDash
                 ? `
                  <dt class="${styles['ev-evolution-point__label']} ${
                     styles['ev-evolution-point__label--spacer']
                   }">
                    ${l('_EVSpread')}
                  <dt/>
                  <dd class=${styles['ev-evolution-point__value']}>${l('_Low')}: <span>${
                     formattedData?.lowEV
                   }</span></dd>
                  <dd class=${styles['ev-evolution-point__value']}>${l('_High')}: <span>${
                     formattedData?.highEV
                   }</span></dd>
                `
                 : ''
             }
           </dl>
          </div>
        `;
  }

  const tooltipBase: Highcharts.TooltipOptions = {
    useHTML: true,
    formatter: tooltipFormatter,
    backgroundColor: '#fff',
    borderColor: '#fff',
    borderRadius: 8,
    shadow: false,
    padding: 0,
  };

  const [previousLegendItemId, setPreviousLegendItemId] = useState<string>();

  const allForecasts = sortedProjectByCaseWithNonZeroProbability.reduce((acc, caseItem) => {
    return [
      ...acc,
      ...caseItem.forecasts.map((forecast) => {
        const caseForecastResults =
          results.cases[caseItem.caseId]?.chartYears[forecast.forecastYear];
        return {
          caseId: caseItem.caseId,
          forecastYear: forecast.forecastYear,
          y: caseForecastResults.exitEvAverage * multiplier,
          custom: {
            exitValueDriverName: exitValueDriverName,
            exitValueDriver: caseForecastResults.valueDriver * multiplier,
            weightedMultipleAverage: caseForecastResults.valueDriverMultiple ?? NaN,
            lowEV: caseForecastResults.exitEvLow * multiplier,
            highEV: caseForecastResults.exitEvHigh * multiplier,
          },
        };
      }),
    ];
  }, [] as { caseId: string; forecastYear: string; y: number; custom: EVChartTooltipData }[]);

  const allForecastsByForecastYear = groupBy(allForecasts, (forecast) => forecast.forecastYear);
  const allForecastsByCaseId = groupBy(allForecasts, (forecast) => forecast.caseId);

  const selectedResults = isSpecificCaseSelected
    ? results.cases[selectedCase!.caseId]
    : results.weighted;

  const startingPoint: Highcharts.PointOptionsObject = {
    y: selectedResults.chartCurrent.exitEvAverage * multiplier,
    custom: {
      exitValueDriverName: exitValueDriverName,
      exitValueDriver: selectedResults.chartCurrent.valueDriver * multiplier,
      weightedMultipleAverage: selectedResults.chartCurrent.valueDriverMultiple,
      currentEV: selectedResults.chartCurrent.exitEvAverage * multiplier,
    },
  };

  const weightedAverageData = Object.values(allForecastsByForecastYear).map((items) => {
    const forecastYear = items[0].forecastYear;
    return {
      y: results.weighted.chartYears[forecastYear].exitEvAverage * multiplier,
      custom: {
        exitValueDriverName: exitValueDriverName,
        exitValueDriver: results.weighted.chartYears[forecastYear].valueDriver * multiplier,
        weightedMultipleAverage: results.weighted.chartYears[forecastYear].valueDriverMultiple,
        lowEV: results.weighted.chartYears[forecastYear].exitEvLow * multiplier,
        highEV: results.weighted.chartYears[forecastYear].exitEvHigh * multiplier,
      },
    };
  });

  const weightedAverageRanges = weightedAverageData.map((item) => [
    item.custom?.lowEV,
    item.custom?.highEV,
  ]);

  const rangeConfigBase: Highcharts.SeriesOptionsType = {
    type: 'arearange',
    enableMouseTracking: false,
    linkedTo: ':previous',
    lineWidth: 0,
    opacity: 0.2,
    zIndex: 0,
    showInLegend: false,
    marker: {
      enabled: false,
    },
  };

  const allSeries: Highcharts.SeriesOptionsType[] = [
    ...sortedProjectByCaseWithNonZeroProbability.flatMap(
      (caseItem, index): Highcharts.SeriesOptionsType[] => {
        return [
          {
            id: caseItem.caseId,
            name: caseItem.narrative,
            type: 'line',
            data: [
              startingPoint,
              ...allForecastsByCaseId[caseItem.caseId].map((forecastData) => ({
                y: forecastData.y,
                custom: {
                  ...forecastData.custom,
                },
              })),
            ],
            color: chartColors[index],
          },
          {
            ...rangeConfigBase,
            id: `${caseItem.caseId}-range`,
            name: `${caseItem.narrative} EV range`,
            data: [
              [results.cases[caseItem.caseId].initialEv, results.cases[caseItem.caseId].initialEv],
              ...allForecastsByCaseId[caseItem.caseId].map((forecastData) => [
                forecastData.custom.lowEV,
                forecastData.custom.highEV,
              ]),
            ],
            color: chartColors[index],
            visible: false,
          },
        ];
      }
    ),
    {
      id: DefaultDashboardDropdownValues.WeightedAverage,
      name: l('_WeightedAverage'),
      type: 'line',
      dashStyle: 'LongDash',
      color: colorNeutralsB50,
      data: [startingPoint, ...weightedAverageData],
    },
    {
      ...rangeConfigBase,
      id: `${DefaultDashboardDropdownValues.WeightedAverage}-range`,
      name: 'Weighted average range',
      color: colorNeutralsB50,
      data: [[results.weighted.initialEv, results.weighted.initialEv], ...weightedAverageRanges],
      visible: false,
    },
  ];

  const onLegendItemClick: Highcharts.SeriesLegendItemClickCallbackFunction = (
    event: Highcharts.SeriesLegendItemClickEventObject
  ) => {
    setPreviousLegendItemId(event.target.userOptions.id);
    if (event.target.chart.series.every((item) => !item.visible)) {
      setSelectedViewOption('viewOption', DefaultDashboardDropdownValues.AllCases);
      return false;
    }
    if (previousLegendItemId === event.target.userOptions.id) {
      setSelectedViewOption('viewOption', DefaultDashboardDropdownValues.AllCases);
      setPreviousLegendItemId(undefined);
      return false;
    }
    event.target.chart.series.forEach((item) => {
      if (item.userOptions.id === event.target.userOptions.id) {
        setSelectedViewOption(
          'viewOption',
          event.target.userOptions.id ?? DefaultDashboardDropdownValues.AllCases
        );
        return false;
      }
    });
    return false;
  };

  const chartComponent = useRef<HighchartsReact.RefObject>(null);
  const chart = chartComponent?.current?.chart;
  const chartOptions = useMemo<Highcharts.Options>(
    () => ({
      accessibility: { enabled: false },
      chart: {
        animation: true,
        reflow: true,
        styledMode: false,
        style: { fontFamily: fontFamilyPrimary },
        spacingTop: 22,
        events: {
          load: function () {
            const maxSeriesDataCount = this.series.reduce((acc, item) => {
              if (item.data.length >= acc) {
                acc = item.data.length - 1;
              }

              return acc;
            }, 0);

            this.series.forEach((item) => item.xAxis.setExtremes(0, maxSeriesDataCount));
          },
        },
      },
      credits: {
        enabled: false,
      },
      title: {
        text: undefined,
      },
      legend: {
        ...legendBase,
        itemStyle: {
          ...legendBase.itemStyle,
          color: colorNeutralsB90,
        },
      },
      colors: [...chartColors, colorAccents3, colorAccents5, colorNeutralsB50],
      xAxis: {
        title: {
          text: l('_ForecastYears'),
          margin: 16,
          style: {
            color: '#7d7d7d',
            fontSize: '1.4rem',
          },
        },
        categories: categories,
        crosshair: {
          width: 1,
          color: colorPrimary,
        },
        labels: {
          style: {
            color: colorNeutralsB80,
            fontSize: '1.4rem',
            fontWeight: '500',
          },
          y: 30,
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          formatter: axisLabelFormatter,
          style: {
            color: colorNeutralsB80,
            fontSize: '1.4rem',
            fontWeight: '500',
          },
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: onLegendItemClick,
          },
        },
        line: {
          marker: {
            symbol: 'circle',
            radius: 8,
            states: {
              hover: {
                fillColor: colorPrimary,
              },
            },
          },
          lineWidth: 2,
        },
      },
      tooltip: { ...tooltipBase },
      series: allSeries,
    }),
    // Added only essential variables, which can affect actual rendering
    [previousLegendItemId, categories, allSeries] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setTimeout(() => {
      chart?.reflow();
    }, 150);
  }, [chart, isExpanded]);

  useEffect(() => {
    const chartSeriesIds = chart?.series?.map((item) => item.options.id);

    switch (selectedViewOption) {
      case DefaultDashboardDropdownValues.AllCases:
        chart?.series?.forEach((item) => {
          if (item.type === 'arearange') {
            item.setVisible(false);
            return;
          }
          item.setVisible(true);
        });

        break;
      case DefaultDashboardDropdownValues.WeightedAverage:
        chartSeriesIds?.forEach((seriesId) => {
          if (!seriesId) {
            return;
          }

          const series = chart?.get(seriesId) as Highcharts.Series;
          if (
            seriesId === DefaultDashboardDropdownValues.WeightedAverage ||
            seriesId === `${DefaultDashboardDropdownValues.WeightedAverage}-range`
          ) {
            series.setVisible(true);
            return;
          }
          series.setVisible(false);
        });

        break;
      default:
        chartSeriesIds?.forEach((seriesId) => {
          if (!seriesId) {
            return;
          }

          const series = chart?.get(seriesId) as Highcharts.Series;
          if (seriesId === selectedViewOption || seriesId === `${selectedViewOption}-range`) {
            series.setVisible(true);
            return;
          }
          series.setVisible(false);
        });
    }
  }, [chart, selectedViewOption]);

  return <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={chartOptions} />;
};
