import { FC } from 'react';
import styles from './sidebar-collapse.module.scss';
import SvgChevronSidebar from '@app/shared/icons/ChevronSidebar';
import { useLocale } from '@app/core/hooks/useLocale';

interface SidebarButtonProps {
  toggleSidebar: () => void;
  isSidebarCollapsed: boolean;
}

const SidebarCollapseButton: FC<SidebarButtonProps> = ({
  toggleSidebar,
  isSidebarCollapsed,
}): JSX.Element => {
  const { l } = useLocale();
  const value = isSidebarCollapsed ? 'collapsed' : 'expanded';

  return (
    <button
      data-testid="sidebar-collapsable-button"
      className={`${styles['sidebar__collapsable-panel']}`}
      onClick={toggleSidebar}
      data-value={value}
      aria-label={l('_ToggleSectionCollapse', { section: l('_Sidebar') })}
      aria-expanded={!isSidebarCollapsed}>
      {!isSidebarCollapsed ? (
        <SvgChevronSidebar className={styles[`sidebar-chevron-expanded`]} />
      ) : (
        <SvgChevronSidebar className={styles[`sidebar-chevron-collapsed`]} />
      )}
    </button>
  );
};

export default SidebarCollapseButton;
