import { useCSSVariables } from '@app/core/hooks/useCSSVariables';
import { useLocale } from '@app/core/hooks/useLocale';
import { cloneDeep } from '@app/shared/helpers';
import Highcharts from 'highcharts';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { abbreviatedValueFormatter, toPercentageUsingFormatter } from '@app/shared/formatters';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { OpmDistributionPlotDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import { ChartZoomMode, ViewOption } from './opm-enums';
import styles from './opm-charts.module.scss';
import { axisLabelFormatter, formatNumber, percentageAxisLabelFormatter } from './format-helpers';
import '@app/shared/helpers/charts/HighchartWrapper';
import { handleClashingLabels } from '@app/shared/helpers/charts/HandleClashingLabels';
import './chart-initialisation';
import { updateDistributionChartData } from './data-helpers';
import { useAppSelector } from '@app/core/hooks/redux-hooks';

interface DistributionChartProps {
  selectedYear: string;
  selectedView: ViewOption | string;
  zoomMode: ChartZoomMode;
  zoomMinimum: number | undefined;
  zoomMaximum: number | undefined;
  chartDistribution: OpmDistributionPlotDto;
  isRiskNeutralChart: boolean;
  isEvSimulation: boolean;
  handleManualZoom: (min: number | undefined, max: number | undefined) => void;
}

export const DistributionChart: FC<DistributionChartProps> = ({
  selectedYear,
  selectedView,
  zoomMode,
  zoomMinimum,
  zoomMaximum,
  chartDistribution,
  isRiskNeutralChart,
  isEvSimulation,
  handleManualZoom,
}): JSX.Element => {
  const { l } = useLocale();
  const [chartRef, setChartRef] = useState<Highcharts.Chart | undefined>(undefined);
  const isInstrumentView = selectedView !== ViewOption.EV && selectedView !== ViewOption.Equity;
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const currentPlotData = useMemo(() => {
    const chartData = cloneDeep(chartDistribution);
    if (isInstrumentView) {
      if (chartData.instruments[selectedView]) {
        chartData.instruments[selectedView] = updateDistributionChartData(
          chartData.instruments[selectedView],
          denomination
        );
      }
      return chartData?.instruments[selectedView] ?? [];
    }

    const data = selectedView === ViewOption.EV ? chartData?.enterpriseValue : chartData?.equity;
    return updateDistributionChartData(data, denomination) ?? [];
  }, [chartDistribution, isInstrumentView, selectedView, denomination]);

  const {
    colors: { colorSecondary, colorNeutralsB80, colorGrey400, colorNeutralsB90 },
    font: { fontFamilyPrimary },
  } = useCSSVariables();

  const getXaxisLabel = () => {
    if (isInstrumentView) {
      return isRiskNeutralChart ? l('_PVOfInstrument') : l('_InstrumentProceeds');
    }

    return selectedView === ViewOption.EV ? l('_EV') : l('_TotalAttributableEquity');
  };

  const getMean = (): { label: string; value: number; formattedValue: string } => {
    let mean = 0;
    const meanValues = currentPlotData[selectedYear].meanValue;
    switch (selectedView) {
      case ViewOption.EV:
        mean = Number(meanValues.simulatedValue);
        break;

      case ViewOption.Equity:
        mean = Number(isEvSimulation ? meanValues.totalEquityProceeds : meanValues.simulatedValue);
        break;

      default:
        mean = Number(
          isRiskNeutralChart ? meanValues.instrumentPresentValue : meanValues.instrumentProceeds
        );
        break;
    }

    return {
      label: isInstrumentView && isRiskNeutralChart ? l('_MPV') : l('_M'),
      value: mean,
      formattedValue: abbreviatedValueFormatter({ value: mean, fraction: 1 }),
    };
  };

  const getLowerQuartile = (): { label: string; value: number; formattedValue: string } => {
    const q1 = Number(currentPlotData[selectedYear].firstQuartile);
    return {
      label: isInstrumentView && isRiskNeutralChart ? l('_Q1PV') : l('_Q1'),
      value: q1,
      formattedValue: abbreviatedValueFormatter({ value: q1, fraction: 1 }),
    };
  };

  const getUpperQuartile = (): { label: string; value: number; formattedValue: string } => {
    const q3 = Number(currentPlotData[selectedYear].thirdQuartile);
    return {
      label: isInstrumentView && isRiskNeutralChart ? l('_Q3PV') : l('_Q3'),
      value: q3,
      formattedValue: abbreviatedValueFormatter({ value: q3, fraction: 1 }),
    };
  };

  const labelY = -30;
  const overlayColor = 'rgba(175, 175, 175, 0.5)';

  function tooltipFormatter(this: Highcharts.TooltipFormatterContextObject) {
    const category = this.point.category;
    const data = currentPlotData[selectedYear].dataPoints;
    const point = data.find((tt) => tt.x === category);
    const shouldShowInstrumentMoM = !isRiskNeutralChart && point?.instrumentMoM !== null;
    const shouldShowInstrumentIRR = !isRiskNeutralChart && point?.instrumentIRR !== null;
    const shouldShowInstrumentPresentValue = isInstrumentView;
    const formattedData = {
      simulatedValue: formatNumber(point?.simulatedValue, 0, true),
      totalEquity: formatNumber(point?.totalEquity, 0, true),
      totalShareholderDebt: formatNumber(point?.totalShareholderDebt, 0, true),
      totalOrdinaryEquity: formatNumber(point?.totalOrdinaryEquity, 0, true),
      institutionMoM: formatNumber(point?.institutionMoM),
      institutionIRR: toPercentageUsingFormatter(point?.institutionIRR ?? 0),
      instrumentMoM: formatNumber(point?.instrumentMoM),
      instrumentIRR: toPercentageUsingFormatter(point?.instrumentIRR ?? 0),
      instrumentPresentValue: formatNumber(point?.instrumentPresentValue, 0, true),
      instrumentProceeds: formatNumber(point?.instrumentProceeds, 0, true),
    };

    return `
    <div>
      <div class="${styles['flex']}">
      ${
        isEvSimulation
          ? `
          <div>
            <div class="${styles['w-150']}">${l('_EV')}</div>
            <div>
              <span><b>${formattedData?.simulatedValue}</b></span>
            </div>
          </div>
          `
          : ''
      }
        <div>
          <div class="${styles['w-150']}">${l('_TotalAttributableEquity')}</div>
          <div><b>${
            isEvSimulation ? formattedData?.totalEquity : formattedData?.simulatedValue
          }</b></div>
        </div>
      </div>
      <br/>
      <div class="${styles['flex']}">
        <div>
          <div class="${styles['w-150']}">${l('_ShareholderDebtTitle')}</div>
          <div><b>${formattedData?.totalShareholderDebt}</b></div>
        </div>
        <div>
          <div class="${styles['w-100']}">${l('_OrdinaryEquityTitle')}</div>
          <div><b>${formattedData?.totalOrdinaryEquity}</b></div>
        </div>
      </div>
      ${
        shouldShowInstrumentPresentValue
          ? `
          <br/>
          <div>
            <div class="${styles['w-100']}">${
              isRiskNeutralChart ? l('_InstrumentPV') : l('_InstrumentProceeds')
            }</div>
            <div><b>${
              isRiskNeutralChart
                ? formattedData?.instrumentPresentValue
                : formattedData?.instrumentProceeds
            }</b></div>             
          </div>
        `
          : ''
      }
      ${
        !isRiskNeutralChart
          ? `
          <br/>
          <div class="${styles['flex']}">
            <div>
              <div class="${styles['w-150']}">${l('_SponsorMoM')}</div>
              <div><b>${formattedData?.institutionMoM}x</b></div>        
            </div>
            <div>
              <div class="${styles['w-100']}">${l('_SponsorIRR')}</div>
              <div><b>${formattedData?.institutionIRR}</b></div>            
            </div>
          </div>`
          : ''
      }
      ${
        shouldShowInstrumentMoM || shouldShowInstrumentIRR
          ? `
            <br/>
            <div class="${styles['flex']}">
              ${
                shouldShowInstrumentMoM
                  ? `
                    <div>
                      <div class="${styles['w-150']}">${l('_InstrumentMoM')}</div>
                      <div><b>${formattedData?.instrumentMoM}x</b></div>             
                    </div>
                  `
                  : ''
              }
              ${
                shouldShowInstrumentIRR
                  ? `
                    <div>
                      <div class="${styles['w-100']}">${l('_InstrumentIRR')}</div>
                      <div><b>${formattedData?.instrumentIRR}</b></div>             
                    </div>
                  `
                  : ''
              }
            </div>`
          : ''
      }
    </div>
  `;
  }

  const tooltipConfig: Highcharts.TooltipOptions = {
    useHTML: true,
    formatter: tooltipFormatter,
    backgroundColor: '#fff',
    borderColor: '#fff',
    borderRadius: 8,
    shadow: false,
    padding: 20,
    shared: true,
    outside: true,
    shape: 'square',
    positioner: function (labelWidth, labelHeight, point) {
      const chart = this.chart;
      const plotX = point.plotX + chart.plotLeft;
      const plotY = point.plotY;

      let tooltipX;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const barW = (this.chart.series[0] as any).barW;

      if (chart.series[0].data.length <= 1) {
        if (plotX < chart.plotWidth / 2) {
          tooltipX = plotX;
        } else {
          tooltipX = Math.min(plotX - labelWidth, chart.plotLeft + chart.plotWidth - labelWidth);
        }
        return {
          x: tooltipX,
          y: plotY,
        };
      }

      if (plotX < chart.plotWidth / 2) {
        tooltipX = Math.max(plotX + barW / 2, chart.plotLeft);
      } else {
        tooltipX = Math.min(
          plotX - labelWidth - barW / 2,
          chart.plotLeft + chart.plotWidth - labelWidth
        );
      }

      tooltipX = Math.min(tooltipX, chart.plotLeft + chart.plotWidth - labelWidth);

      tooltipX = Math.max(tooltipX, chart.plotLeft);

      const tooltipY = Math.max(
        Math.min(plotY - labelHeight + 40, chart.plotTop + chart.plotHeight - labelHeight),
        chart.plotTop + 5
      );

      return {
        x: tooltipX,
        y: tooltipY,
      };
    },
  };

  const displayMeanTooltip = (x: number, y: number) => {
    if (tooltipElement.current) {
      tooltipElement.current.destroy();
    }
    const meanValues = currentPlotData[selectedYear].meanValue;
    const shouldShowInstrumentMoM = !isRiskNeutralChart && meanValues.instrumentMoM !== null;
    const shouldShowInstrumentIRR = !isRiskNeutralChart && meanValues.instrumentIRR !== null;
    const shouldShowInstrumentPresentValue =
      isInstrumentView && meanValues.instrumentPresentValue !== null;
    const formattedValues = {
      simulatedValue: formatNumber(Number(meanValues.simulatedValue), 0, true),
      totalEquity: formatNumber(Number(meanValues.totalEquityProceeds), 0, true),
      totalShareholderDebt: formatNumber(Number(meanValues.totalShareholderDebt), 0, true),
      totalOrdinaryEquity: formatNumber(Number(meanValues.totalOrdinaryEquity), 0, true),
      institutionMoM: formatNumber(Number(meanValues.institutionMoM), 2),
      institutionIRR: toPercentageUsingFormatter(Number(meanValues.institutionIRR)),
      instrumentMoM: formatNumber(Number(meanValues.instrumentMoM), 2),
      instrumentIRR: toPercentageUsingFormatter(Number(meanValues.instrumentIRR)),
      instrumentPresentValue: formatNumber(Number(meanValues.instrumentPresentValue), 0, true),
    };

    tooltipElement.current = chartRef?.renderer
      .label(
        `<div style="white-space: nowrap;">
          <div class="${styles['flex']}">
          ${
            isEvSimulation
              ? `
              <div>
                <div class="${styles['w-200']}">${l('_MeanEV')}</div>
                <div>
                  <b>${formattedValues.simulatedValue}</b>
                </div>
              </div>`
              : ''
          }
              <div>
                <div class="${styles['w-200']}">${l('_MeanTotalAttributableEquity')}</div>
                <div>
                  <b>${
                    isEvSimulation ? formattedValues.totalEquity : formattedValues.simulatedValue
                  }</b>
                </div>
              </div>
          </div>
          <br/>
          <div class="${styles['flex']}">
            <div>
              <div class="${styles['w-200']}">${l('_MeanShareholderDebt')}</div>
              <div>
                <b>${formattedValues.totalShareholderDebt}</b>
              </div>
            </div>
            <div>
              <div class="${styles['w-200']}">${l('_MeanOrdinaryEquity')}</div>
              <div>
                <b>${formattedValues?.totalOrdinaryEquity}</b>
              </div>
            </div>
          </div>
          ${
            shouldShowInstrumentPresentValue
              ? `
                <br/> 
                  <div class="${styles['w-150']}">${
                  isRiskNeutralChart ? l('_MeanInstrumentPV') : l('_MeanInstrumentProceeds')
                }</div>
                  <div>
                    <b>${formattedValues.instrumentPresentValue}</b>
                  </div>
                  `
              : ''
          }
          ${
            !isRiskNeutralChart
              ? `
                <br/>
                <div class="${styles['flex']}">
                  <div>
                    <div class="${styles['w-200']}">${l('_MeanSponsorMoM')}</div>
                    <div>
                      <b>${formattedValues.institutionMoM}x</b>
                    </div>
                  </div>
                  <div>
                    <div class="${styles['w-150']}">${l('_MeanSponsorIRR')}</div>
                    <div>
                      <b>${formattedValues.institutionIRR}</b>
                    </div>
                  </div>
                </div>`
              : ''
          }
          ${
            shouldShowInstrumentMoM || shouldShowInstrumentIRR
              ? `
                <br/>
                <div class="${styles['flex']}">
                ${
                  shouldShowInstrumentMoM
                    ? `
                      <div>
                        <div class="${styles['w-200']}">${l('_MeanInstrumentMoM')}</div>
                        <div>
                          <b>${formattedValues.instrumentMoM}x</b>
                        </div>
                      </div>
                      `
                    : ''
                }
                ${
                  shouldShowInstrumentIRR
                    ? `
                      <div class="${styles['w-150']}">${l('_MeanInstrumentIRR')}
                        <div>
                          <b>${formattedValues.instrumentIRR}</b>
                        <div>
                      </div>
                          `
                    : ''
                }
                </div>
                `
              : ''
          }
        </div>
      `,
        x,
        y,
        'callout',
        0,
        0,
        true
      )
      .shadow({
        color: 'rgba(0, 0, 0, 0.1)',
        opacity: 0.3,
        width: 15,
      })
      .attr({
        fill: '#fff',
        borderColor: '#fff',
        zIndex: 20,
        padding: 20,
        r: 8,
      })
      .css({
        color: '#000000',
        fontSize: '0.8em',
        fontFamily: 'Helvetica Neue", Helvetica, Arial, sans-serif',
        fontWeight: '400',
        transition: 'opacity 0.5s',
      })
      .add();
  };

  const addTooltipEventListener = () => {
    if (eventListener.current) {
      document.removeEventListener('mousemove', eventListener.current);
    }
    eventListener.current = (event: Event) => {
      const target = event.target as HTMLElement;
      const mouseEvent = event as MouseEvent;
      const x = mouseEvent.offsetX - 135;
      const y = mouseEvent.offsetY + 10;
      if (target && target.id === 'mean-tooltip') {
        displayMeanTooltip(x, y);
      } else if (tooltipElement.current) {
        tooltipElement.current.destroy();
        tooltipElement.current = undefined;
      }
    };
    document.addEventListener('mousemove', eventListener.current);
  };

  const xAxisMinimum =
    zoomMode === ChartZoomMode.Zoomed
      ? zoomMinimum
      : Number(currentPlotData[selectedYear].defaultViewMinimum);
  const xAxisMaximum =
    zoomMode === ChartZoomMode.Zoomed
      ? zoomMaximum
      : Number(currentPlotData[selectedYear].defaultViewMaximum);

  const tooltipElement = useRef<Highcharts.SVGElement | undefined>(undefined);
  const eventListener = useRef<null | EventListener>(null);
  const chartOptions = useMemo<Highcharts.Options>(
    () => {
      return {
        chart: {
          events: {
            load: function () {
              handleClashingLabels(this.xAxis);
            },
            redraw: function () {
              handleClashingLabels(this.xAxis);
            },
          },
          marginTop: 60,
          marginBottom: 100,
          marginRight: 52,
          animation: true,
          type: 'column',
          zooming: {
            type: 'x',
          },
          reflow: true,
          style: { fontFamily: fontFamilyPrimary },
        },
        tooltip: tooltipConfig,
        credits: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        xAxis: {
          tickLength: 0,
          title: {
            text: getXaxisLabel(),
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '700',
            },
            y: 30,
          },
          min: 0,
          endOnTick: true,
          labels: {
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '500',
            },
            formatter: axisLabelFormatter,
            y: 30,
          },
          events: {
            setExtremes: function (e) {
              if (e.trigger === 'zoom') {
                handleManualZoom(e.min, e.max);
              }
            },
          },
          plotBands:
            Object.keys(currentPlotData)?.length > 0 && isRiskNeutralChart
              ? [
                  {
                    id: 'collaringLow',
                    from: 0,
                    to: currentPlotData[selectedYear].collaringFloor ?? undefined,
                    color: overlayColor,
                    zIndex: 1,
                    label: {
                      align: 'left',
                      y: labelY,
                      rotation: 0,
                      formatter: () => {
                        return `<tspan dy="0" style="color: ${colorGrey400}; font-size: 14px">${l(
                          '_Floor'
                        )}</tspan><br><tspan dy="2" style="color: ${colorNeutralsB90}; font-size: 14px">${toPercentageUsingFormatter(
                          currentPlotData[selectedYear].collaringPercentage
                        )}</tspan>`;
                      },
                    },
                  },
                  {
                    id: 'collaringHigh',
                    from: currentPlotData[selectedYear].collaringCeiling ?? undefined,
                    to: Number.MAX_VALUE,
                    color: overlayColor,
                    zIndex: 1,
                    label: {
                      align: 'right',
                      y: labelY,
                      rotation: 0,
                      formatter: () => {
                        return `<tspan dy="0" style="color: ${colorGrey400}; font-size: 14px">${l(
                          '_Cap'
                        )}</tspan><br><tspan dy="2" style="color: ${colorNeutralsB90}; font-size: 14px">${toPercentageUsingFormatter(
                          currentPlotData[selectedYear].collaringPercentage
                        )}</tspan>`;
                      },
                    },
                  },
                ]
              : [],
          plotLines:
            Object.keys(currentPlotData).length > 0
              ? [
                  {
                    id: 'mean',
                    label: {
                      id: 'mean-tooltip',
                      align: 'center',
                      y: labelY,
                      rotation: 0,
                      formatter: () => {
                        return `<div id="mean-tooltip"><tspan dy="0" id="mean-tooltip" style="color: ${colorNeutralsB80}; font-size: 14px;">${
                          getMean().label
                        }</tspan><br><tspan dy="2" id="mean-tooltip" style="color: ${colorNeutralsB90}; font-size: 14px;">${
                          getMean().formattedValue
                        }</tspan></div>`;
                      },
                    },
                    value: getMean().value,
                    zIndex: 1,
                    width: 2,
                    color: colorGrey400,
                  },
                  {
                    id: 'firstQuartile',
                    label: {
                      align: 'center',
                      y: labelY,
                      x: 0,
                      rotation: 0,
                      formatter: () => {
                        return `<tspan dy="0" style="color: ${colorNeutralsB80}; font-size: 14px;">${
                          getLowerQuartile().label
                        }</tspan><br><tspan dy="2" style="color: ${colorNeutralsB90}; font-size: 14px;">${
                          getLowerQuartile().formattedValue
                        }</tspan>`;
                      },
                    },
                    value: getLowerQuartile().value,
                    zIndex: 1,
                    width: 2,
                    color: colorGrey400,
                    style: {
                      overflow: 'none',
                      crop: false,
                    },
                  },
                  {
                    id: 'thirdQuartile',
                    label: {
                      align: 'center',
                      y: labelY,
                      x: 0,
                      rotation: 0,
                      formatter: () => {
                        return `<tspan dy="0" style="color: ${colorNeutralsB80}; font-size: 14px;">${
                          getUpperQuartile().label
                        }</tspan><br><tspan dy="2" style="color: ${colorNeutralsB90}; font-size: 14px;">${
                          getUpperQuartile().formattedValue
                        }</tspan>`;
                      },
                    },
                    value: getUpperQuartile().value,
                    zIndex: 1,
                    width: 2,
                    color: colorGrey400,
                  },
                ]
              : [],
        },
        yAxis: {
          ceiling: 100,
          title: {
            text: l('_PercentageSimulations'),
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '700',
            },
            x: -10,
          },
          labels: {
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '500',
            },
            formatter: percentageAxisLabelFormatter,
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 1,
            groupPadding: 0,
            stickyTracking: true,
            shadow: false,
            states: {
              hover: {
                brightness: 0.3,
              },
            },
          },
        },
        series: [
          {
            id: `${selectedView}-${selectedYear}`,
            type: 'column',
            data:
              Object.keys(currentPlotData).length > 0
                ? currentPlotData[selectedYear].dataPoints.map((item) => ({
                    ...item,
                    color: item.instrumentPresentValue === 0 ? colorNeutralsB80 : colorSecondary,
                  }))
                : [],
            pointPlacement: 'between',
          },
        ],
        legend: {
          enabled: false,
        },
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPlotData, selectedYear, selectedView]
  );

  useEffect(() => {
    // handle zoom
    if (chartRef && chartRef.xAxis) {
      const min = zoomMode === ChartZoomMode.AllData ? 0 : xAxisMinimum;
      const max = zoomMode === ChartZoomMode.AllData ? undefined : xAxisMaximum;
      chartRef.xAxis[0].setExtremes(min, max);
    }
  }, [chartRef, zoomMode, xAxisMaximum, xAxisMinimum]);

  useEffect(() => {
    // handle mean tooltip - attach mean event listener
    if (chartRef?.renderer) {
      addTooltipEventListener();
    }

    return () => {
      if (eventListener.current) {
        document.removeEventListener('mousemove', eventListener.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef?.renderer, selectedView, selectedYear, currentPlotData]);

  const chartRefCallback = useCallback((chartContainer: HighchartsReactRefObject) => {
    if (chartContainer != null) {
      setChartRef(chartContainer.chart);
    }
  }, []);

  return (
    <>
      {selectedYear && currentPlotData && (
        <HighchartsReact ref={chartRefCallback} highcharts={Highcharts} options={chartOptions} />
      )}
    </>
  );
};
