import { Currency, Denomination } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from './enum-key-by-value';
import i18n from 'i18next';

export const currencyDenominationPair = (
  currency: keyof typeof Currency,
  denomination: keyof typeof Denomination
): string => {
  const currencyValue = Currency[currency];
  const denominationValue =
    denomination === enumKeyByValue(Denomination, Denomination.Units)
      ? ''
      : Denomination[denomination];

  return denominationValue === ''
    ? currencyValue
    : i18n.t('_CurrencyDenomination', {
        currency: currencyValue,
        denomination: denominationValue,
      });
};
