import styles from './secondary-navigation.module.scss';
import classNames from 'classnames';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { SecondaryNavigationVariation } from '@app/shared/components/secondary-navigation/secondary-navigation-enums';
import { SecondaryNavigationItem } from './SecondaryNavigationItem';
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd';
import { StrictModeDroppable } from '@app/shared/helpers/drag-and-drop/StrictModeDroppable';
import { ContainerTestId } from '@app/shared/models/contracts/enums/shared-enums';
import { useEffect, useRef } from 'react';

interface SecondaryNavigationProps {
  navigationItems: NavigationItem[];
  variation?: SecondaryNavigationVariation;
  updateCaseOrder: (navigationItems: NavigationItem[]) => void;
}

const reorder = (cases: NavigationItem[], startIndex: number, endIndex: number) => {
  const caseArray = Array.from(cases);
  const [removed] = caseArray.splice(startIndex, 1);
  caseArray.splice(endIndex, 0, removed);
  caseArray.forEach((item, index) => {
    item.order = index;
  });

  return caseArray;
};

export const DragAndDropSecondaryNavigation = ({
  navigationItems,
  variation,
  updateCaseOrder,
}: SecondaryNavigationProps) => {
  const mainElement = useRef<Element | null>(null);

  useEffect(() => {
    mainElement.current = document.querySelector(`[data-testid=${ContainerTestId.Main}]`);
  }, []);

  const onDragEnd = (result: DropResult) => {
    mainElement?.current?.setAttribute('style', 'overflow: auto; scrollbar-gutter: auto');
    if (!result.destination) {
      return;
    }

    const reorderedNavItems = reorder(
      navigationItems,
      result.source.index,
      result.destination.index
    );

    updateCaseOrder(reorderedNavItems);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="secondary-navigation-tabs" direction="horizontal">
          {(provided) => (
            <ul
              onMouseDown={() => {
                mainElement?.current?.setAttribute(
                  'style',
                  'overflow: hidden; scrollbar-gutter: stable'
                );
                const activeElement = document.activeElement as HTMLElement;
                activeElement.blur();
              }}
              onMouseUp={() => {
                mainElement?.current?.setAttribute(
                  'style',
                  'overflow: auto; scrollbar-gutter: auto'
                );
              }}
              ref={provided.innerRef}
              {...provided.droppableProps}
              data-testid="dnd-secondary-navigation"
              className={classNames(styles['secondary-navigation'], {
                [styles[`secondary-navigation--${variation}`]]: variation,
              })}>
              {navigationItems.map((item, index) => (
                <Draggable
                  key={item.params!.caseId}
                  draggableId={item.params!.caseId}
                  index={index}>
                  {(provided) => (
                    <li
                      className={styles['secondary-navigation__item-container']}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      data-testid={`draggable-item-${item.label
                        .toLowerCase()
                        .replaceAll(' ', '-')}`}>
                      <SecondaryNavigationItem
                        route={item.route}
                        params={item.params}
                        additionalData={item.additionalData}
                        variation={item.variation}>
                        {item.label}
                      </SecondaryNavigationItem>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};
