export const sumBy = <TItem>(
  items: TItem[],
  by: (item: TItem) => number | null | undefined
): number => {
  return items.reduce((sum, i) => {
    const value = by(i);

    if (value === undefined || value === null) {
      return NaN;
    }

    return sum + value;
  }, 0);
};
