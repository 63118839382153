import env from '@environment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@server/api-config';
import { RootState, AppDispatch } from './store';
import {
  BuildStructureCalculationResultDto,
  CapitalStructureCalculationResultDto,
  CapitalStructureEventSetDto,
  CapitalStructureValuesDto,
} from '@app/shared/models/contracts/capital-structure-debt-instrument-dto';
import { WithValidationResult } from '@app/shared/interfaces/with-validation-result';
import { cloneDeep, enumKeyByValue } from '@app/shared/helpers';
import { getDuplicateGroups } from './capital-structure-slice-selectors';
import { projectSlice, updateProjectDraft } from './project-slice';
import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import {
  CalcMethod,
  CapitalStructureEventSeverity,
  EventKey,
} from '@app/shared/models/contracts/enums/shared-enums';
import { createOpmOnlyEventSet } from '../inputs/updateProjectCapitalStructures';

export interface CapitalStructureState {
  values: CapitalStructureValuesDto;
  erfValues: CapitalStructureValuesDto;
}

export const calculateCapitalStructure = createAsyncThunk<
  { capitalStructure: CapitalStructureCalculationResultDto },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculateCapitalStructure', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;
  const capitalStructureResults = await api.post<
    WithValidationResult<CapitalStructureCalculationResultDto>
  >(`${env.apiUrl}/calculate/capitalstructure`, JSON.stringify(project));
  return { capitalStructure: capitalStructureResults.data.result };
});

export const calculateCapitalStructureErf = createAsyncThunk<
  { capitalStructure: CapitalStructureCalculationResultDto },
  void,
  { state: RootState; dispatch: AppDispatch }
>('calculateCapitalStructureErf', async (_, thunkAPI) => {
  const project = thunkAPI.getState().project.projectDraft;
  const capitalStructureResults = await api.post<
    WithValidationResult<CapitalStructureCalculationResultDto>
  >(`${env.apiUrl}/calculate/capitalstructure?useErf=true`, JSON.stringify(project));
  return { capitalStructure: capitalStructureResults.data.result };
});

export const calculateBuildStructure = createAsyncThunk<
  { hasErrors: boolean; buildStructure: BuildStructureCalculationResultDto },
  { project?: ProjectDto; ignoreErrors?: boolean; updateInternally?: boolean },
  { state: RootState; dispatch: AppDispatch }
>(
  'calculateBuildStructure',
  async ({ project, ignoreErrors, updateInternally = false }, thunkAPI) => {
    const projectDraft = project ?? thunkAPI.getState().project.projectDraft;
    const buildResults = await api.post<WithValidationResult<BuildStructureCalculationResultDto>>(
      `${env.apiUrl}/calculate/buildstructure`,
      JSON.stringify(projectDraft)
    );

    const capitalStructureId = projectDraft.pwermInput.cases[0].capitalStructureId;

    const buildStructure = buildResults.data.result.buildStructures[capitalStructureId];

    // don't update the project draft if we have any errors which are scoped to the entire build structure, and not to a specific event
    if (
      !ignoreErrors &&
      buildStructure.faults.some(
        (f) => f.severity === CapitalStructureEventSeverity.Error && f.event === null
      )
    ) {
      return { hasErrors: true, buildStructure: buildResults.data.result };
    }

    // identify if there are any duplicates
    const isDuplicateEventSetFound = Object.entries(buildStructure.eventSets).some(
      ([key, eventSet]) =>
        key !== EventKey.OpmOnly &&
        eventSet.duplicates.length > 0 &&
        !eventSet.duplicates.every((dup) => dup === EventKey.OpmOnly)
    );

    // identify if there are any orphaned events, i.e. events that exist after their eventSet has been removed
    const projectEvents = Object.keys(projectDraft.capitalStructures[capitalStructureId].events);
    const resultEvents = Object.values(buildStructure.eventSets).reduce(
      (acc, eventSet) => acc.concat(eventSet.events.map((e) => e.id)),
      [] as string[]
    );
    const isOrphanEventFound = projectEvents.some((event) => !resultEvents.includes(event));

    // identify if there are any case.eventSetId's that do not correlate to any eventSets from the result
    const isInvalidCaseEventSetIdFound = projectDraft.pwermInput.cases.some(
      (c) => c.eventSetId && !Object.keys(buildStructure.eventSets).includes(c.eventSetId)
    );

    // identify if there are any eventSets that are not used by any cases
    const isUnusedEventSetFound = Object.keys(buildStructure.eventSets)
      .filter(
        (eventSetId) => eventSetId !== EventKey.EmptyEventSet && eventSetId !== EventKey.OpmOnly
      )
      .some(
        (eventSetId) => !projectDraft.pwermInput.cases.some((c) => c.eventSetId === eventSetId)
      );

    let updatedProject = cloneDeep(projectDraft);

    if (
      isDuplicateEventSetFound ||
      isOrphanEventFound ||
      isInvalidCaseEventSetIdFound ||
      isUnusedEventSetFound
    ) {
      // regroup cases, so that they share a single eventSetId if they are duplicates
      const identifiedDuplicates = Object.entries(buildStructure.eventSets).filter(
        ([key, eventSet]) =>
          key !== EventKey.OpmOnly &&
          eventSet.duplicates.length > 0 &&
          !eventSet.duplicates.every((dup) => dup === EventKey.OpmOnly)
      );
      const duplicatesWithoutOpmOnly: [string, CapitalStructureEventSetDto][] =
        identifiedDuplicates.map(([key, eventSet]) => [
          key,
          {
            ...eventSet,
            duplicates: eventSet.duplicates.filter((dup) => dup !== EventKey.OpmOnly),
          },
        ]);
      const duplicateGroups = getDuplicateGroups(duplicatesWithoutOpmOnly);
      duplicateGroups.forEach((group) => {
        updatedProject.pwermInput.cases = updatedProject.pwermInput.cases.map((c) => {
          if (group.includes(c.eventSetId ?? '')) {
            return { ...c, eventSetId: group[0] };
          }
          return c;
        });
        updatedProject.capitalStructures[capitalStructureId].eventSets = Object.fromEntries(
          Object.entries(updatedProject.capitalStructures[capitalStructureId].eventSets).filter(
            ([key]) =>
              duplicateGroups.some((g) => g[0] === key) ||
              !duplicateGroups.some((g) => g.includes(key))
          )
        );
      });

      // removing unused eventSets
      updatedProject.capitalStructures[capitalStructureId].eventSets = Object.fromEntries(
        Object.entries(updatedProject.capitalStructures[capitalStructureId].eventSets).filter(
          ([key]) =>
            updatedProject.pwermInput.cases.some(
              (c) => c.eventSetId === key || key === EventKey.OpmOnly
            )
        )
      );

      // removing events if they are no longer part of any eventSet
      const eventsArray = Object.keys(updatedProject.capitalStructures[capitalStructureId].events);
      const eventsToRemove = eventsArray.filter(
        (eventId) =>
          !Object.values(updatedProject.capitalStructures[capitalStructureId].eventSets).some(
            (eventSet) => eventSet.events.includes(eventId)
          )
      );
      eventsToRemove.forEach((eventId) => {
        delete updatedProject.capitalStructures[capitalStructureId].events[eventId];
      });

      // updating case eventSetId to null if the eventSetId is no longer part of capitalStructures
      updatedProject.pwermInput.cases = updatedProject.pwermInput.cases.map((c) => {
        if (
          !Object.keys(updatedProject.capitalStructures[capitalStructureId].eventSets).includes(
            c.eventSetId ?? ''
          )
        ) {
          return { ...c, eventSetId: null };
        }
        return c;
      });
    }

    const isErfBuildStructure = buildResults.data.result.buildStructures[capitalStructureId].isErf;

    // removing from opmInput selectedCaseId and selectedEventSetId if the build structure is not ERF
    if (!isErfBuildStructure || updatedProject.details.calcMethod === CalcMethod.PWERM) {
      updatedProject.opmInput.selectedCaseId = null;
      updatedProject.opmInput.selectedEventSetId = null;
    }

    if (
      isErfBuildStructure &&
      updatedProject.details.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)
    ) {
      // assign the selectedCaseId if it is not already assigned
      if (!updatedProject.opmInput.selectedCaseId) {
        updatedProject.opmInput.selectedCaseId = updatedProject.pwermInput.cases[0].caseId;
      }
      // apply the most recent eventSetId for the selectedCaseId, following potential updated to eventSets
      updatedProject.opmInput.selectedEventSetId =
        updatedProject.pwermInput.cases.find(
          (x) => x.caseId === updatedProject.opmInput.selectedCaseId
        )?.eventSetId ?? EventKey.EmptyEventSet;
    } else if (isErfBuildStructure && updatedProject.details.calcMethod === CalcMethod.OPM) {
      // if OPM only, but without an eventSet, create the OPM Only eventSet
      // this would be applicable for projects where the calc method is already OPM, but no events have been created
      if (!updatedProject.opmInput.selectedCaseId) {
        updatedProject = createOpmOnlyEventSet(updatedProject);
      }
    }

    if (updateInternally) {
      thunkAPI.dispatch(projectSlice.actions.updateProjectDraftInternal(updatedProject));
    } else {
      thunkAPI.dispatch(
        updateProjectDraft({ project: updatedProject, shouldRunBuildStructure: false })
      );
    }
    return { hasErrors: false, buildStructure: buildResults.data.result };
  }
);

export const capitalStructureSlice = createSlice({
  name: 'capitalStructure',
  initialState: {
    values: {},
    erfValues: {},
  } as CapitalStructureState,
  reducers: {
    resetCapitalStructureResults: (state) => {
      state.values = {} as CapitalStructureValuesDto;
      state.erfValues = {} as CapitalStructureValuesDto;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(calculateCapitalStructure.fulfilled, (state, action) => {
      state.values = { ...state.values, ...action.payload.capitalStructure };
    });
    builder.addCase(calculateCapitalStructureErf.fulfilled, (state, action) => {
      state.erfValues = { ...state.erfValues, ...action.payload.capitalStructure };
    });
    builder.addCase(calculateBuildStructure.fulfilled, (state, action) => {
      if (!action.payload.hasErrors) {
        state.values = { ...state.values, ...action.payload.buildStructure };
      }
    });
  },
});

export const { resetCapitalStructureResults } = capitalStructureSlice.actions;

export default capitalStructureSlice.reducer;
