import React, { FC } from 'react';
import {
  amountValueFormatterProps,
  numberValueFormatter,
  percentageValueFormatterProps,
} from '@app/shared/formatters';
import { InstrumentType, OwnerType } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { InstrumentBlock } from '@app/modules/projects/inputs/capital-structure/capital-structure-types';
import { useLocale } from '@app/core/hooks/useLocale';

interface InstrumentTooltipProps {
  instrument: InstrumentBlock<EquityInstrumentDto>;
  forecastIndex?: number;
  isHovering: boolean;
}

const Pwerm2InstrumentTooltip: FC<InstrumentTooltipProps> = ({
  instrument,
  forecastIndex,
  isHovering,
}): JSX.Element => {
  const { l } = useLocale();
  if (!isHovering) {
    return <></>;
  }

  const totalStructureAmount = instrument.amount;

  const getTotalsByType = (ownerType: OwnerType) => {
    const ownerKey = enumKeyByValue(OwnerType, ownerType);

    const ownerValue = instrument.proceedsByOwner[ownerKey] ?? 0;

    return ownerValue === 0
      ? 0
      : numberValueFormatter({ value: ownerValue, ...amountValueFormatterProps });
  };

  const institutionOwnershipTotal = getTotalsByType(OwnerType.Institution);
  const coInvestorOwnershipTotal = getTotalsByType(OwnerType.CoInvestor);
  const managementOwnershipTotal = getTotalsByType(OwnerType.Management);
  const otherOwnershipTotal = getTotalsByType(OwnerType.Other);

  const percentageForValueType = instrument.proportionOfType;

  return (
    <div key={'instrument-tooltip-' + forecastIndex}>
      <h2 className="heading-2 heading-2--alternate">{instrument.instrumentNarrative}</h2>
      <dl className="definition-list">
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>{l('_PercentageOf', { name: InstrumentType[instrument.type] })} </dt>
            <dd>
              {numberValueFormatter({
                value: getCalculatedProjectValueInPercent(percentageForValueType) ?? 0,
                ...percentageValueFormatterProps,
              })}
            </dd>
          </div>
          {institutionOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_Sponsor')}</dt>
              <dd>{institutionOwnershipTotal}</dd>
            </div>
          )}
          {coInvestorOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_CoInvestor')}</dt>
              <dd>{coInvestorOwnershipTotal}</dd>
            </div>
          )}
        </div>
        <div className="definition-list__row">
          <div className="definition-list__row-item">
            <dt>{forecastIndex || forecastIndex === 0 ? 'Total proceeds' : 'Initial Amount'}</dt>
            <dd>
              {numberValueFormatter({
                value: Number(totalStructureAmount),
                ...amountValueFormatterProps,
              })}
            </dd>
          </div>
          {managementOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_Management')}</dt>
              <dd>{managementOwnershipTotal}</dd>
            </div>
          )}
          {otherOwnershipTotal !== 0 && (
            <div className="definition-list__row-item">
              <dt>{l('_Other')}</dt>
              <dd>{otherOwnershipTotal}</dd>
            </div>
          )}
        </div>
      </dl>
    </div>
  );
};

export default React.memo(Pwerm2InstrumentTooltip);
