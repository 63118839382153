import { FC } from 'react';
import TableSectionValuesPerForecast from '../../../components/TableSectionValuesPerForecast';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  getCalculatedPayoutValue,
  getSlnAndPrefSharesForecast,
  selectPrefShareInstruments,
  selectSlnAndPrefSharesInstruments,
  selectSlnInstruments,
} from '@app/core/store/project-slice-selectors';
import TableSectionValuesPerMultiple from '../../../components/TableSectionValuesPerMultiple';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  selectCalculatedPayoutsResults,
  selectSlnAndPrefSharesForecasts,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { numberValueFormatter, percentageValueFormatterProps } from '@app/shared/formatters';
import { EmptyValues } from '@app/shared/constants/empty-values';
import {
  enumKeyByValue,
  instrumentsSortFn,
  isInstrumentOwnerAmountEntered,
  sumBy,
} from '@app/shared/helpers';
import { OwnerType, WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';

export const WaterfallShareholderDebtDistributionsPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedPayouts = useAppSelector(selectCalculatedPayoutsResults);

  const slnInstruments = useAppSelector(selectSlnInstruments);
  const prefShareInstruments = useAppSelector(selectPrefShareInstruments);
  const slnAndPrefSharesInstruments = useAppSelector(selectSlnAndPrefSharesInstruments);
  const slnAndPrefSharesForecasts = useAppSelector(selectSlnAndPrefSharesForecasts);

  const filteredSlnAndPrefSharesInstruments = slnAndPrefSharesInstruments.filter(
    isInstrumentOwnerAmountEntered
  );

  const renderDebtDistributionInstrumentSection = ({
    instrumentNarrative,
    instrumentId,
    couponValue,
  }: EquityInstrumentDto) => (
    <TableSectionValuesPerForecast
      title={`${instrumentNarrative} [${
        couponValue || couponValue === 0
          ? numberValueFormatter({
              value: couponValue,
              ...percentageValueFormatterProps,
            })
          : EmptyValues.EnDash
      }]`}
      key={instrumentId}
      renderSpacer={false}
      renderCell={({ forecast, caseItem }) => {
        return (
          <CellValue
            {...amountRules}
            value={
              slnAndPrefSharesForecasts?.[caseItem.caseId]?.[instrumentId]?.[forecast.forecastYear]
                ?.closingBalance || undefined
            }
          />
        );
      }}
      additionalTitleClassNames="table-primary__cell--indentation"
    />
  );

  const renderShareholderDebtInstrumentSection = ({
    instrumentNarrative,
    instrumentId,
  }: EquityInstrumentDto) => (
    <TableSectionValuesPerMultiple
      key={instrumentId}
      title={instrumentNarrative}
      renderCell={({ caseItem, forecast, multiple }) => (
        <CellValue
          {...amountRules}
          value={
            getCalculatedPayoutValue(
              project,
              calculatedPayouts,
              caseItem.caseId,
              forecast.forecastId,
              multiple,
              instrumentId
            )?.payout
          }
        />
      )}
    />
  );

  const renderShareholderDebtDistributionsByOwnerType = (item: {
    rowTitle: string;
    ownerType: OwnerType;
  }) => (
    <TableSectionValuesPerMultiple
      key={item.ownerType}
      title={item.rowTitle}
      renderCell={({ caseItem, forecast, multiple }) => {
        const total = filteredSlnAndPrefSharesInstruments.length
          ? sumBy(filteredSlnAndPrefSharesInstruments, (instrument) =>
              Number(
                getCalculatedPayoutValue(
                  project,
                  calculatedPayouts,
                  caseItem.caseId,
                  forecast.forecastId,
                  multiple,
                  instrument.instrumentId
                )?.perOwner[enumKeyByValue(OwnerType, item.ownerType)]
              )
            )
          : undefined;

        return <CellValue {...amountRules} value={total} />;
      }}
    />
  );

  const shareholderDebtDistributionOwnerSectionsConfig = [
    {
      rowTitle: 'Sponsor',
      ownerType: OwnerType.Institution,
    },
    {
      rowTitle: 'Management',
      ownerType: OwnerType.Management,
    },
  ];

  const totalShareholderDebtDistributionsByOwnerType =
    shareholderDebtDistributionOwnerSectionsConfig.map(
      renderShareholderDebtDistributionsByOwnerType
    );
  const [isShareholderDebtDistributionsOpen, setIsShareholderDebtDistributionsOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title="Shareholder Debt Distributions"
          id={enumKeyByValue(WaterfallSections, WaterfallSections.ShareholderDebtDistributions)}
          isOpen={isShareholderDebtDistributionsOpen}
          setIsOpen={setIsShareholderDebtDistributionsOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isShareholderDebtDistributionsOpen,
        })}>
        {(slnInstruments.length > 0 || prefShareInstruments.length > 0) && (
          <TableSectionValuesPerForecast
            title="Contractual carrying values"
            renderSpacer={false}
            renderCell={() => <></>}
          />
        )}
        {slnAndPrefSharesInstruments
          .sort(instrumentsSortFn)
          .map((instrument) => renderDebtDistributionInstrumentSection(instrument))}
        <TableSectionValuesPerForecast
          title="Total contractual shareholder debt"
          renderCell={({ forecast, caseItem }) => {
            return (
              <CellValue
                {...amountRules}
                value={
                  slnAndPrefSharesInstruments.length > 0
                    ? sumBy(
                        slnAndPrefSharesInstruments,
                        (instrument) =>
                          getSlnAndPrefSharesForecast(
                            project,
                            slnAndPrefSharesForecasts,
                            caseItem.caseId,
                            instrument.instrumentId,
                            forecast.forecastId
                          )?.closingBalance ?? 0
                      )
                    : undefined
                }
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--section-start-separator table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--header-tertiary">Split of Contractual Debt</th>
          <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
        </tr>
        {slnInstruments.length > 0 && (
          <TableSectionValuesPerForecast
            renderSpacer={false}
            title="Shareholder loan notes"
            renderCell={({ forecast, caseItem }) => {
              return (
                <CellValue
                  {...amountRules}
                  value={sumBy(
                    slnInstruments,
                    (i) =>
                      getSlnAndPrefSharesForecast(
                        project,
                        slnAndPrefSharesForecasts,
                        caseItem.caseId,
                        i.instrumentId,
                        forecast.forecastId
                      )?.closingBalance ?? 0
                  )}
                />
              );
            }}
          />
        )}
        {prefShareInstruments.length > 0 && (
          <TableSectionValuesPerForecast
            title="Preference shares"
            renderSpacer={false}
            renderCell={({ forecast, caseItem }) => {
              return (
                <CellValue
                  {...amountRules}
                  value={sumBy(
                    prefShareInstruments,
                    (i) =>
                      getSlnAndPrefSharesForecast(
                        project,
                        slnAndPrefSharesForecasts,
                        caseItem.caseId,
                        i.instrumentId,
                        forecast.forecastId
                      )?.closingBalance ?? 0
                  )}
                />
              );
            }}
          />
        )}
        <TableSectionValuesPerForecast
          title="Sponsor"
          renderSpacer={false}
          renderCell={({ forecast, caseItem }) => {
            const value =
              slnInstruments.length > 0 || prefShareInstruments.length > 0
                ? sumBy(
                    slnAndPrefSharesInstruments,
                    (i) =>
                      getSlnAndPrefSharesForecast(
                        project,
                        slnAndPrefSharesForecasts,
                        caseItem.caseId,
                        i.instrumentId,
                        forecast.forecastId
                      )?.perOwner[enumKeyByValue(OwnerType, OwnerType.Institution)] ?? 0
                  )
                : undefined;

            return <CellValue {...amountRules} value={value} />;
          }}
        />
        <TableSectionValuesPerForecast
          title="Management"
          renderCell={({ forecast, caseItem }) => {
            const value =
              slnInstruments.length > 0 || prefShareInstruments.length > 0
                ? sumBy(
                    slnAndPrefSharesInstruments,
                    (i) =>
                      getSlnAndPrefSharesForecast(
                        project,
                        slnAndPrefSharesForecasts,
                        caseItem.caseId,
                        i.instrumentId,
                        forecast.forecastId
                      )?.perOwner[enumKeyByValue(OwnerType, OwnerType.Management)] ?? 0
                  )
                : undefined;

            return <CellValue {...amountRules} value={value} />;
          }}
        />
        {Boolean(filteredSlnAndPrefSharesInstruments.length) && (
          <>
            <tr>
              <th className="table-primary__cell--header-tertiary">
                Shareholder Debt Distributions
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
            </tr>
            {filteredSlnAndPrefSharesInstruments
              .sort(instrumentsSortFn)
              .map((instrument) => renderShareholderDebtInstrumentSection(instrument))}
            <tr>
              <th className="table-primary__cell--header-tertiary">
                Total Shareholder Debt Distributions
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
            </tr>
            <TableSectionValuesPerMultiple
              title="Total distributions"
              renderCell={({ caseItem, forecast, multiple }) => {
                const total = sumBy(slnAndPrefSharesInstruments, (instrument) => {
                  return Number(
                    getCalculatedPayoutValue(
                      project,
                      calculatedPayouts,
                      caseItem.caseId,
                      forecast.forecastId,
                      multiple,
                      instrument.instrumentId
                    )?.payout
                  );
                });

                return <CellValue {...amountRules} value={total} />;
              }}
            />
            <TableSectionValuesPerMultiple
              title="Shortfall"
              renderCell={({ caseItem, forecast, multiple }) => {
                const contractualDebtTotal = sumBy(
                  slnAndPrefSharesInstruments,
                  (instrument) =>
                    getSlnAndPrefSharesForecast(
                      project,
                      slnAndPrefSharesForecasts,
                      caseItem.caseId,
                      instrument.instrumentId,
                      forecast.forecastId
                    )?.closingBalance
                );

                const totalShareholderDebtDistributions = sumBy(
                  slnAndPrefSharesInstruments,
                  (instrument) => {
                    return Number(
                      getCalculatedPayoutValue(
                        project,
                        calculatedPayouts,
                        caseItem.caseId,
                        forecast.forecastId,
                        multiple,
                        instrument.instrumentId
                      )?.payout
                    );
                  }
                );

                return (
                  <CellValue
                    {...amountRules}
                    value={
                      !Number.isNaN(contractualDebtTotal) &&
                      !Number.isNaN(totalShareholderDebtDistributions)
                        ? contractualDebtTotal > totalShareholderDebtDistributions
                          ? totalShareholderDebtDistributions - contractualDebtTotal
                          : 0
                        : undefined
                    }
                  />
                );
              }}
            />
            <tr>
              <th className="table-primary__cell--header-tertiary">
                Split of Shareholder Debt Distributions
              </th>
              <th colSpan={columnsCount - 1} className="table-primary__cell--header-tertiary" />
            </tr>
            {totalShareholderDebtDistributionsByOwnerType}
          </>
        )}
      </tbody>
    </>
  );
};

export default WaterfallShareholderDebtDistributionsPartialTable;
