import { FC } from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import FieldValidationMessage from '@app/shared/components/form-controls/fidl-validation-message/FieldValidationMessage';
import { PARENT_CLASSNAME } from '@app/shared/components/form-controls/form-field/FormField';
import FieldLabel from '@app/shared/components/form-controls/field-label/FieldLabel';
import { FormControlPropsBase } from '@app/shared/components/form-controls/shared-types';
import styles from './form-field-react-select.module.scss';
import { SelectOption } from '@app/shared/models/option';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import Select from 'react-select';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

interface FormSelectProps extends FormControlPropsBase, StateManagerProps {
  options: SelectOption[];
  name: string;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
}

export const FormSelectAdvanced: FC<FormSelectProps> = ({
  name,
  defaultValue,
  label,
  labelIcon,
  options,
  rules,
  required,
  isGapless = false,
  ignoreReadOnly = false,
  disabled = false,
  ...rest
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, defaultValue });

  const isReadOnly = useIsReadOnly();
  const selectOptions = options.map((option) => {
    return {
      value: option.value,
      label: option.viewValue,
    };
  });

  // @ts-ignore
  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--gapless`]]: isGapless,
      })}>
      {label && (
        <div className={classNames(styles[`${PARENT_CLASSNAME}__label`])}>
          <FieldLabel name={name} required={required ?? (rules && 'required' in rules)}>
            {labelIcon}
            {label}
          </FieldLabel>
        </div>
      )}
      <div
        className={classNames(styles[`${PARENT_CLASSNAME}__container`], {
          [`react-select__container--error`]: error,
          [`react-select__container--filled`]: field.value,
        })}>
        <Select
          {...field}
          {...rest}
          /* @ts-ignore: temporary disable until types will be adjusted */
          classNamePrefix="react-select"
          isClearable
          options={selectOptions}
          isDisabled={disabled || (!ignoreReadOnly && isReadOnly)}
        />
      </div>
      {error && (
        <div className={classNames(styles[`${PARENT_CLASSNAME}__validation-message`])}>
          <FieldValidationMessage error={error} />
        </div>
      )}
    </div>
  );
};
