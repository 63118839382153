import { useCallback } from 'react';
import { ProjectFormModel } from '@app/modules/projects/inputs/project-admin/project-form/project-form-types';
import { enumKeyByValue } from '@app/shared/helpers';
import { UseFormReturn } from 'react-hook-form';
import styles from './tabTitle.module.scss';

export type Props = {
  title: string;
  index: number;
  setSelectedTab: (index: number) => void;
  isActive?: boolean;
  formSubmitHandler: () => void;
  formMethods: UseFormReturn<ProjectFormModel, any, undefined>;
  setName: any;
  enumName: Record<string, string>;
};

const TabTitle = (props: Props): JSX.Element => {
  const {
    title,
    setSelectedTab,
    index,
    isActive,
    formSubmitHandler,
    formMethods,
    setName,
    enumName,
  } = props;

  const handleOnClick = useCallback(
    (e: any) => {
      e.preventDefault();
      setSelectedTab(index);
      formMethods.setValue(setName, enumKeyByValue(enumName, title));
      formSubmitHandler();
    },
    [setSelectedTab, index, formMethods, setName, enumName, title, formSubmitHandler]
  );

  const handleKeyPress = useCallback(
    (e: any) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        handleOnClick(e);
      }
    },
    [handleOnClick]
  );

  return (
    <li
      className={`${styles.title} ${isActive ? 'active' : ''}`}
      data-testid={title}
      onClick={handleOnClick}
      onKeyPress={handleKeyPress}
      tabIndex={0}>
      {title}
    </li>
  );
};

export default TabTitle;
