import { FC, useRef } from 'react';
import TableScreenshotAction from '@app/shared/components/table/TableScreenshotAction';
import { removeFileIllegalCharacters } from '@app/shared/helpers/remove-file-illegal-characters';
import styles from '../../deal-thesis/deal-thesis-page.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useParams } from 'react-router-dom';
import { enumKeyByValue } from '@app/shared/helpers';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { DealThesisCaseTableHeader2 } from './DealThesisCaseTableHeader2';
import ExitValueDriverSection2 from './exit-value-driver-2/ExitValueDriver2';
import DealThesisCaseExitMultiplesSection2 from './exit-multiples-2/DealThesisCaseExitMultiplesSection2';
import SlnAndPrefSharesForecastSummarySection2 from './sln-and-pref-share-2/SlnAndPrefSharesForecastSummarySection2';
import ExitCostsPartialTable2 from './exit-costs-2/ExitCostsPartialTable2';
import NetDebtPartialTable2 from './net-debt-2/NetDebtPartialTable2';
import KeyFinancialMetrics from './key-financial-metrics/KeyFinancialMetrics';
import OrdinaryEquityAdditionRedemptionSection from './ordinary-equity-add-redemption/OrdinaryEquityAdditionRedemptionSection';
import { DealThesisCaseSlnAndPrefShareSectionERF } from './sln-and-pref-share-2/DealThesisCasesSlnAndPrefShareSectionERF';
import { isDevFeatureEnabled, DevFeature } from '@app/core/utils/dev-feature';
import { DealThesisCaseSlnAndPrefShareSection2 } from './sln-and-pref-share-2/DealThesisCasesSlnAndPrefShareSection2';
import OperationalFreeCashFlowSection2 from './operation-free-cash-flow-2/OperationFreeCashFlowSection2';

interface DealThesisCaseTableProps {
  handleDataSubmit: () => void;
}

export const DealThesisCaseTable2: FC<DealThesisCaseTableProps> = ({
  handleDataSubmit,
}): JSX.Element => {
  const tableRef = useRef<HTMLTableElement>(null);
  const project = useAppSelector((state) => state.project);
  const isDealThesisErfEnabled = isDevFeatureEnabled(DevFeature.DealThesisERF);
  const { caseId } = useParams();
  const activeCase = project.projectDraft.pwermInput.cases.find(
    (caseItem) => caseItem.caseId === caseId
  );
  const PARENT_CLASSNAME = 'deal-thesis-table';
  const isOpm =
    project.projectDraft.details.calcMethod !== enumKeyByValue(CalcMethod, CalcMethod.PWERM);
  const isEv = project.projectDraft.opmInput.opmSimulatedValue === 'EnterpriseValue';

  return (
    <div className="main-container-padding-remove">
      <div className={styles[`${PARENT_CLASSNAME}__screenshot-action`]}>
        <TableScreenshotAction
          tableRef={tableRef}
          testIdString="save-deal-thesis-image"
          filePrefix={`Deal_thesis_${removeFileIllegalCharacters(
            `${activeCase?.narrative}_${project.projectDraft?.name}`
          )}_`}
        />
      </div>
      <table
        className="table-primary table-primary--zebra table-primary--framed table-primary--reserve-space-bottom"
        ref={tableRef}>
        <DealThesisCaseTableHeader2 handleDataSubmit={handleDataSubmit} />
        <KeyFinancialMetrics handleDataSubmit={handleDataSubmit} />
        <ExitValueDriverSection2 />
        <DealThesisCaseExitMultiplesSection2 onRowsChange={handleDataSubmit} />
        <ExitCostsPartialTable2 handleDataSubmit={handleDataSubmit} />
        {isOpm && isEv && <OperationalFreeCashFlowSection2 />}
        <NetDebtPartialTable2 onRowsChange={handleDataSubmit} />
        <SlnAndPrefSharesForecastSummarySection2 />
        {!isDealThesisErfEnabled && (
          <DealThesisCaseSlnAndPrefShareSection2 handleDataSubmit={handleDataSubmit} />
        )}
        {isDealThesisErfEnabled && (
          <DealThesisCaseSlnAndPrefShareSectionERF handleDataSubmit={handleDataSubmit} />
        )}
        {isDealThesisErfEnabled && <OrdinaryEquityAdditionRedemptionSection />}
      </table>
    </div>
  );
};
