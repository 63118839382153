import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { SPONSOR_INVESTMENT_OPTIONS } from '@app/shared/constants/dropdown-values/sponsor-investmenet-options';
import { percentageValidator, requiredValidator } from '@core/validations/hook-forms/validators';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { enumKeyByValue, useGetCalculatedProjectValue } from '@app/shared/helpers';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';

const SponsorInvestment: FC = (): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const {
    storeBenchmarkingData,
    status,
    benchmarking: { benchmarkingOnly },
  } = useAppSelector((state) => state.project.projectDraft);

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  return (
    <Card>
      <h3>Sponsor Investment</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="sponsorInvestment.sponsorInvestmentStructureStrip"
            label="Sponsor Investment Structure (Strip)"
            options={SPONSOR_INVESTMENT_OPTIONS}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label="Percentage of Fixed Return Instrument Within the Strip (%)"
              value={getCalculatedProjectValueInPercent(
                getCalculatedProjectValue('PercentageOfFixedReturnInstrumentWithinStrip')
              )}
              {...enteredPercentageViewRules}
              isGapless
            />
          ) : (
            <FormField
              name="sponsorInvestment.percentageOfFixedReturnInstrumentWithinStrip"
              label="Percentage of Fixed Return Instrument Within the Strip (%)"
              defaultValue={
                getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('PercentageOfFixedReturnInstrumentWithinStrip')
                ) as number | undefined
              }
              rules={{
                ...(shouldFieldsBeRequired && requiredValidator),
                ...percentageValidator,
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label="Coupon on Preferred Capital Instrument (%) (Volume Weighted)"
              value={getCalculatedProjectValueInPercent(
                getCalculatedProjectValue('CouponOnPreferredCapitalInstrumentPercentage')
              )}
              {...enteredPercentageViewRules}
              isGapless
            />
          ) : (
            <FormField
              name="sponsorInvestment.couponOnPreferredCapitalInstrumentPercentage"
              label="Coupon on Preferred Capital Instrument (%) (Volume Weighted)"
              defaultValue={
                getCalculatedProjectValueInPercent(
                  getCalculatedProjectValue('CouponOnPreferredCapitalInstrumentPercentage')
                ) as number | undefined
              }
              rules={{
                ...(shouldFieldsBeRequired && requiredValidator),
                ...percentageValidator,
              }}
              {...percentageFieldFormattingProps}
              isGapless
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default SponsorInvestment;
