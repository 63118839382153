import { FC } from 'react';
import OpmTotalEquity from '../../dashboard/widgets/total-equity/opm/OpmTotalEquity';
import { SimulatedOutcomes } from '../../dashboard/widgets/simulated-outcomes/SimulatedOutcomes';
import { SimulatedOutcomesChartTypes } from '../../dashboard/opm-charts/opm-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { Navigate } from 'react-router-dom';
import { RouteConstants } from '../../RouteConstants';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import { OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';
import { enumKeyByValue } from '@app/shared/helpers';

const RealWorldDistributionsOpm: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const results = useAppSelector((state) => state.opmCalculation.realWorldValues);
  const { generatePath } = usePwcNavigate();

  // redirect the user if there is no data
  if (!results || Object.keys(results).length === 0) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }

  const hasInstrumentResults =
    Object.keys(results.simulatedOutcomesDistributionPlot.instruments).length > 0;

  const isEvCalculation =
    project.opmInput.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue);

  return (
    <div>
      <SimulatedOutcomes chartType={SimulatedOutcomesChartTypes.RealWorldOutcome} />
      {isEvCalculation && (
        <SimulatedOutcomes chartType={SimulatedOutcomesChartTypes.EVRealWorldDistribution} />
      )}
      <SimulatedOutcomes chartType={SimulatedOutcomesChartTypes.EquityRealWorldDistribution} />
      {hasInstrumentResults && (
        <SimulatedOutcomes
          chartType={SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution}
        />
      )}
      <OpmTotalEquity />
    </div>
  );
};

export default RealWorldDistributionsOpm;
