import { SVGProps } from 'react';
const SvgEventDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M3.67991 14.356L3.50391 5.54H2.4959L2.68791 14.372C2.68791 14.756 2.78924 15.108 2.99191 15.428C3.18391 15.7587 3.44524 16.02 3.77591 16.212C4.10657 16.404 4.46391 16.5 4.84791 16.5H12.0799C12.4639 16.5 12.8212 16.404 13.1519 16.212C13.4826 16.02 13.7492 15.7587 13.9519 15.428C14.1439 15.108 14.2399 14.756 14.2399 14.372L14.4159 5.54H13.4239L13.2479 14.356C13.2372 14.6653 13.1199 14.932 12.8959 15.156C12.6612 15.3907 12.3892 15.508 12.0799 15.508H4.84791C4.53857 15.508 4.27191 15.3907 4.04791 15.156C3.81324 14.932 3.69057 14.6653 3.67991 14.356ZM14.9919 2.5H1.99991C1.86124 2.5 1.74391 2.548 1.64791 2.644C1.55191 2.74 1.50391 2.85733 1.50391 2.996C1.50391 3.13467 1.55191 3.252 1.64791 3.348C1.74391 3.444 1.86124 3.492 1.99991 3.492H14.9919C15.1306 3.492 15.2532 3.444 15.3599 3.348C15.4559 3.252 15.5039 3.13467 15.5039 2.996C15.5039 2.85733 15.4559 2.74 15.3599 2.644C15.2532 2.548 15.1306 2.5 14.9919 2.5ZM5.50391 1.492H11.5039C11.6426 1.492 11.7599 1.444 11.8559 1.348C11.9519 1.252 11.9999 1.13467 11.9999 0.995999C11.9999 0.857333 11.9519 0.74 11.8559 0.644C11.7599 0.548 11.6426 0.5 11.5039 0.5H5.50391C5.36524 0.5 5.24791 0.548 5.15191 0.644C5.04524 0.74 4.99191 0.857333 4.99191 0.995999C4.99191 1.13467 5.04524 1.252 5.15191 1.348C5.24791 1.444 5.36524 1.492 5.50391 1.492Z"
      fill="#D04A02"
    />
  </svg>
);
export default SvgEventDelete;
