import { FC, InputHTMLAttributes } from 'react';
import styles from './grid-datepicker-field.module.scss';
import classNames from 'classnames';
import { useController } from 'react-hook-form';
import ExclamationMarkCircled from '@app/shared/icons/ExclamationMarkCircled';
import { Tooltip } from '@app/shared/components/tooltip/Tooltip';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { FormControlPropsBase } from '@app/shared/components/form-controls/shared-types';
import { Datepicker } from '@app/shared/components/datepicker/Datepicker';
import { Input, InputProps } from '@app/shared/components/input/Input';

import tooltipStyles from 'src/app/shared/components/tooltip/tooltip.module.scss';
import { GridFieldVariation } from '@app/shared/components/grid-controls/grid-field-options'; // TODO this will be solved with error message tooltip update
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import SvgCalendar from '@app/shared/icons/Calendar';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { invalidDateValidator } from '@core/validations/hook-forms/validators';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';

interface GridDatepickerFieldProps
  extends InputHTMLAttributes<HTMLInputElement>,
    FormControlPropsBase {
  name: string;
  alignment?: Alignment;
  variation?: GridFieldVariation;
  formSubmitHandler: () => void;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  customIconCSS?: boolean;
}

const PARENT_CLASSNAME = 'grid-field';

export const GridDatepickerField: FC<GridDatepickerFieldProps> = ({
  alignment,
  defaultValue,
  label,
  labelIcon,
  name,
  rules,
  variation,
  formSubmitHandler,
  ignoreReadOnly = false,
  disabled = false,
  customIconCSS,
  ...rest
}): JSX.Element => {
  const { l } = useLocale();
  const {
    field: { value: fieldValue },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      ...rules,
      validate: {
        invalidDate: invalidDateValidator().validate,
        ...rules?.validate,
      },
    },
    defaultValue,
  });

  const inputProps: InputProps = {
    title: fieldValue?.toString(),
    hasError: Boolean(error?.message),
    onBlur: formSubmitHandler,
    alignment,
    variation,
    ...rest,
  };

  const isReadOnly = useIsReadOnly();
  const shouldHighlight = useAppSelector((state) => state.uiValues.highlightEditableCells);

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--error`]]: error?.message,
        [styles[`${PARENT_CLASSNAME}--${alignment}`]]: alignment,
        [styles[`${PARENT_CLASSNAME}--highlight`]]: shouldHighlight,
      })}>
      {label && (
        <label className={styles[`${PARENT_CLASSNAME}__label`]}>
          {labelIcon}
          {label}
        </label>
      )}
      <div className={styles[`${PARENT_CLASSNAME}__container`]}>
        <ExclamationMarkCircled className={styles[`${PARENT_CLASSNAME}__icon`]} />
        <Tooltip content={error?.message} className={styles[`${PARENT_CLASSNAME}__tooltip`]}>
          <Datepicker
            name={name}
            renderInput={({ inputRef, setIsCalendarOpen, inputProps: datepickerInputProps }) => (
              <div className={tooltipStyles['tooltip__trigger']}>
                <Input
                  {...datepickerInputProps}
                  {...inputProps}
                  className={customIconCSS ? styles[`${PARENT_CLASSNAME}__calendar-icon`] : ''}
                  suffix={() => (
                    <Button
                      size={ButtonSize.FLUID}
                      aria-label={l('_OpenCalendar')}
                      appearance={ButtonAppearance.CLEAN}
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => setIsCalendarOpen(true)}>
                      <SvgCalendar className={styles[`${PARENT_CLASSNAME}__input-suffix-icon`]} />
                    </Button>
                  )}
                  ref={inputRef}
                  disabled={disabled || (!ignoreReadOnly && isReadOnly)}
                />
              </div>
            )}
            formSubmitHandler={formSubmitHandler}
          />
        </Tooltip>
      </div>
    </div>
  );
};
