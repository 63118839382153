export enum ButtonSize {
  FLUID = 'fluid',
  SMALL = 'small',
  FULL_WIDTH = 'full-width',
  FULL_HEIGHT = 'full-height',
  TABLE_ACTION = 'table-action',
}

export enum ButtonAppearance {
  DEFAULT_PRIMARY = 'primary',
  DEFAULT_SECONDARY = 'secondary',
  DEFAULT_TERTIARY = 'tertiary',
  DANGER_TERTIARY = 'danger-tertiary',
  ACCORDION_TAB = 'accordion-tab',
  CLEAN = 'clean',
  CLEAN_ACTION = 'clean-action',
  ROUND = 'round',
  LINK = 'link',
  DEFAULT_INACTIVE = 'inactive',
}
