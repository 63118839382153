import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { textFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { YES_NO_UNKNOWN_OPTIONS } from '@app/shared/constants/dropdown-values/yes-no-unknown-options';
import { MIP_DESIGN_OPTIONS } from '@app/shared/constants/dropdown-values/mip-design-options';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';

const SchemeDesignAndManagementAdvice: FC = (): JSX.Element => {
  const { dealsTaxProjectLead, valuationProjectLead } = useAppSelector(
    (state) => state.project.projectDraft.details
  );
  return (
    <Card>
      <h3>Scheme Design &amp; Management Advice</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.mipDesign"
            label="MIP Design"
            options={MIP_DESIGN_OPTIONS}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.didManagementReceiveCommercialAdvice"
            label="Did Management Receive Commercial Advice?"
            options={YES_NO_UNKNOWN_OPTIONS}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue label="Deals Tax Project Lead" value={dealsTaxProjectLead} isGapless />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name="schemeDesignAndManagementAdvice.mipDesignComments"
            label="MIP Design Comments"
            placeholder="Add details"
            {...textFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.didManagementReceiveValuationAdvice"
            label="Did Management Receive Valuation Advice?"
            options={YES_NO_UNKNOWN_OPTIONS}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name="schemeDesignAndManagementAdvice.broaderComments"
            label="Broader Comments"
            placeholder="Add details"
            {...textFieldFormattingProps}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormSelect
            name="schemeDesignAndManagementAdvice.didManagementReceiveTaxAdvice"
            label="Did Management Receive Tax Advice?"
            options={YES_NO_UNKNOWN_OPTIONS}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue label="Valuation Project Lead" value={valuationProjectLead} isGapless />
        </div>
        <div className="form-row__column" />
      </div>
    </Card>
  );
};

export default SchemeDesignAndManagementAdvice;
