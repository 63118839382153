import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { InstrumentDefinitionDto } from '@app/shared/models/contracts/project-dto';

interface PayoutStatementProps {
  trancheIndex: number;
  formSubmitHandler: () => void;
  activeEquityInstrument: InstrumentDefinitionDto | undefined;
}

const PARENT_CLASSNAME = 'payout-logic';
import styles from './../payout-logic.module.scss';
import classNames from 'classnames';
import { PayoutDriver, PayoutValue } from '@app/shared/models/contracts/enums/shared-enums';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { enumKeyByValue, enumToOptions } from '@app/shared/helpers';
import { PAYOUT_VALUE_OPTIONS } from '@app/shared/constants/dropdown-values/payout/payout-value-options';
import {
  DEFAULT_TRANCHE_PAYOUT_CALCULATION_CUSTOM_VALUE,
  DEFAULT_TRANCHE_PAYOUT_CALCULATION_VALUE,
} from '@app/shared/helpers/create-tranche';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import { requiredValidator } from '@core/validations/hook-forms/validators';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { ALTERNATIVE_STATEMENT_CONDITION_OPTIONS } from '@app/shared/constants/dropdown-values/payout/alternative-statement-condition-options';
import { useLocale } from '@app/core/hooks/useLocale';
import PayoutConditionsErf from '../payout-conditions/PayoutConditionsErf';

const PayoutStatementsErf: FC<PayoutStatementProps> = ({
  trancheIndex,
  formSubmitHandler,
}): JSX.Element => {
  const { l } = useLocale();
  const { getValues, setValue, clearErrors } = useFormContext<InstrumentDefinitionDto>();
  const PAYOUT_DRIVER_OPTIONS = enumToOptions(PayoutDriver);

  const watchTranches = getValues().payoutLogic;

  const resetCustomValueField = (trancheIndex: number) => {
    setValue(
      `payoutLogic.${trancheIndex}.payoutCalculation.customValue`,
      DEFAULT_TRANCHE_PAYOUT_CALCULATION_CUSTOM_VALUE
    );
    clearErrors(`payoutLogic.${trancheIndex}.payoutCalculation.customValue`);
  };

  const handlePayoutDriverChange = async (trancheIndex: number) => {
    setValue(
      `payoutLogic.${trancheIndex}.payoutCalculation.payoutValue`,
      DEFAULT_TRANCHE_PAYOUT_CALCULATION_VALUE
    );
    changeAllPayoutDriverValues();
    resetCustomValueField(trancheIndex);
    await formSubmitHandler();
  };

  const changeAllPayoutDriverValues = () => {
    getValues().payoutLogic?.map((_, trancheIndex) => {
      setValue(
        `payoutLogic.${trancheIndex}.payoutCalculation.payoutDriver`,
        getValues(`payoutLogic.0.payoutCalculation.payoutDriver`)
      );
    });
  };

  const handlePayoutValueChange = async (trancheIndex: number) => {
    resetCustomValueField(trancheIndex);
    await formSubmitHandler();
  };

  const renderCustomValueField = (trancheIndex: number) => {
    return (
      <div className={styles[`${PARENT_CLASSNAME}__statement-custom-value`]}>
        <FormField
          name={`payoutLogic.${trancheIndex}.payoutCalculation.customValue`}
          required
          rules={{
            ...requiredValidator,
          }}
          isGapless
          {...percentageFieldFormattingProps}
        />
      </div>
    );
  };

  return (
    <div className={styles[`${PARENT_CLASSNAME}__statement-container`]}>
      <h5 className={styles[`${PARENT_CLASSNAME}__item-condition`]}>{l('_If')}</h5>
      <div className={classNames(styles[`${PARENT_CLASSNAME}__item`])}>
        <div className={styles[`${PARENT_CLASSNAME}__statement`]}>
          <PayoutConditionsErf trancheIndex={trancheIndex} formSubmitHandler={formSubmitHandler} />
        </div>
        <div className={styles[`${PARENT_CLASSNAME}__condition`]}>
          <h5 className={styles[`${PARENT_CLASSNAME}__condition-item`]}>{l('_Then')}</h5>
          <div className={classNames(styles[`${PARENT_CLASSNAME}__condition-item-controls`])}>
            <div className={styles[`${PARENT_CLASSNAME}__statement-control-1`]}>
              <FormSelect
                ariaLabel={l('_SelectorAriaLabel', { label: l('_PayoutDriver') })}
                disabled={trancheIndex > 0}
                name={`payoutLogic.${trancheIndex}.payoutCalculation.payoutDriver`}
                required
                options={PAYOUT_DRIVER_OPTIONS}
                onChange={() => handlePayoutDriverChange(trancheIndex)}
                onBlur={(event) => event.stopPropagation()}
                isGapless
              />
            </div>
            <span className={styles[`${PARENT_CLASSNAME}__multiplier-item`]}>*</span>
            <div className={styles[`${PARENT_CLASSNAME}__statement-control-2`]}>
              <FormSelect
                ariaLabel={l('_SelectorAriaLabel', { label: l('_PayoutValue') })}
                name={`payoutLogic.${trancheIndex}.payoutCalculation.payoutValue`}
                required
                placeholder="0"
                onBlur={(event) => event.stopPropagation()}
                onChange={() => handlePayoutValueChange(trancheIndex)}
                options={PAYOUT_VALUE_OPTIONS}
                isGapless
              />
              {watchTranches?.[trancheIndex]?.payoutCalculation.payoutValue ===
                enumKeyByValue(PayoutValue, PayoutValue.Custom) &&
                renderCustomValueField(trancheIndex)}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames([
          styles[`${PARENT_CLASSNAME}__item`],
          styles[`${PARENT_CLASSNAME}__item--secondary`],
        ])}>
        <h5 className={styles[`${PARENT_CLASSNAME}__item-condition`]}>{l('_Else')}</h5>
        <div className={styles[`${PARENT_CLASSNAME}__condition-control-1`]}>
          <FormSelect
            ariaLabel={l('_SelectorAriaLabel', {
              label: l('_AlternativeStatementCondition'),
            })}
            disabled={trancheIndex > 0}
            name={`payoutLogic.${trancheIndex}.else`}
            required
            onBlur={(event) => event.stopPropagation()}
            onChange={formSubmitHandler}
            options={ALTERNATIVE_STATEMENT_CONDITION_OPTIONS}
            isGapless
          />
        </div>
      </div>
    </div>
  );
};

export default PayoutStatementsErf;
