import { useAppSelector } from '../hooks/redux-hooks';
import { RootState } from './store';

const getOpmCalculation = (state: RootState) => state.opmCalculation.realWorldValues;

export const useGetOpmForecasts = () => {
  const calculationResults = useAppSelector((state) => getOpmCalculation(state));
  const forecasts = calculationResults.forecasts;

  return {
    getOpmForecasts: () => forecasts ?? [],
    getOpmForecastByYear: (forecastYear: string) =>
      forecasts.find((forecast) => forecast.forecastDate === forecastYear),
  };
};
