import { FC } from 'react';
import styles from './dashboard.module.scss';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { Navigate, generatePath } from 'react-router';
import { RouteConstants } from '../RouteConstants';
import { Pwerm2ValuationEstimatesPwerm } from './pwerm2-widgets/valuation-estimates/Pwerm2ValuationEstimatesPwerm';
import { Pwerm2ValuationEstimatesByInstrument } from './pwerm2-widgets/valuation-estimates-by-instrument/Pwerm2ValuationEstimatesByInstrument';
import { ValuationEstimatesByInstrument } from './widgets/valuation-estimates-by-instrument/ValuationEstimatesByInstrument';
import { ValuationEstimatesPwerm } from './widgets/valuation-estimates/ValuationEstimatesPwerm';
import { KeyMetrics } from './widgets/key-metrics/KeyMetrics';
import { Pwerm2KeyMetrics } from './pwerm2-widgets/key-metrics/Pwerm2KeyMetrics';
import { EvEvolution } from './widgets/ev-evolution/EvEvolution';
import { Pwerm2EvEvolution } from './pwerm2-widgets/ev-evolution/Pwerm2EvEvolution';
import TotalEquityPwerm from './widgets/total-equity/TotalEquityPwerm';
import Pwerm2TotalEquityPwerm from './pwerm2-widgets/total-equity/Pwerm2TotalEquityPwerm';

const PARENT_CLASSNAME = 'dashboard-grid';
const Pwerm2Dashboard: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);

  if (project.details.calcMethod === CalcMethod.OPM) {
    const newPath = generatePath(`../${RouteConstants.ProjectDetails}`);
    return <Navigate to={newPath} />;
  }
  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <ValuationEstimatesPwerm />
      <Pwerm2ValuationEstimatesPwerm />
      <ValuationEstimatesByInstrument />
      <Pwerm2ValuationEstimatesByInstrument />
      <KeyMetrics />
      <Pwerm2KeyMetrics />
      <EvEvolution />
      <Pwerm2EvEvolution />
      <TotalEquityPwerm />
      <Pwerm2TotalEquityPwerm />
    </div>
  );
};

export default Pwerm2Dashboard;
