import { useAppSelector } from '@core/hooks/redux-hooks';
import { selectTimesToExit } from '@app/core/store/pwerm-calculation-slice-selectors';
import { sumBy } from '@app/shared/helpers';
import {
  getCaseProbabilityWeightedValue,
  getForecastProbabilityWeightedValue,
} from '@app/shared/helpers/get-weighted-values';
import { CaseDto, EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { useGetCalculatedValue } from '@app/core/store/pwerm-calculated-values-selectors';

/**
 * Returns a proportions for each value agains total
 * @param items items to iterate over
 * @param key item key selector that will be used in a result as a map key
 * @param value calculated value based on which proportions will be calculated
 * @returns a map of proportions: Record<key(item), value(item) / total>
 */
const getValueProportions = <TItem>(
  items: TItem[],
  key: (item: TItem) => string | number,
  value: (item: TItem) => number
) => {
  const vals = items.reduce((acc, item) => {
    acc[key(item)] = value(item);
    return acc;
  }, {} as Record<string, number>);

  const total = sumBy(Object.values(vals), (x) => x);

  Object.keys(vals).forEach((k) => {
    vals[k] = vals[k] / total;
  });

  return vals;
};

const useCostOfEquityCalculations = ({
  selectedInstrument,
}: {
  selectedInstrument: EquityInstrumentDto;
}) => {
  const { getCalculatedValue, calculationsLoaded } = useGetCalculatedValue();
  const project = useAppSelector((state) => state.project.projectDraft);
  const calculatedTimesToExit = useAppSelector(selectTimesToExit);

  const getForecastWeightedTimeToExitWeightedProceeds = (caseItem: CaseDto) => {
    const exitYearDistributions = getValueProportions(
      caseItem.forecasts,
      (x) => x.forecastYear,
      (x) =>
        getCalculatedValue('NetExitWeightedProceedsPerInstrument', [], {
          caseId: caseItem.caseId,
          forecastYear: x.forecastYear,
          instrumentId: selectedInstrument?.instrumentId,
        })[0]?.value * (x.probability ?? NaN)
    );

    const totalDistributions = sumBy(Object.values(exitYearDistributions), (x) => x);

    if (!totalDistributions) {
      return NaN;
    }

    return sumBy(caseItem.forecasts, (forecast) => {
      const weightedProceeds = exitYearDistributions[forecast.forecastYear] / totalDistributions;
      const timeToExit = calculatedTimesToExit?.[forecast.forecastYear];
      return weightedProceeds * timeToExit;
    });
  };

  const getCaseCostOfEquityCalculatedPerTranches = (caseItem: CaseDto) => {
    const tranchesCount = selectedInstrument?.tranches?.length ?? 0;

    if (tranchesCount === 1) {
      return getCalculatedValue('CostOfEquityCumulative', ['forecastYear'], {
        caseId: caseItem.caseId,
        instrumentId: selectedInstrument.instrumentId,
        trancheId: selectedInstrument.tranches![0].id,
      })[0]?.value;
    }

    if (tranchesCount > 1) {
      const weightedTrancheCostofEquities = getValueProportions(
        selectedInstrument.tranches ?? [],
        (x) => x.id,
        (x) => {
          const trancheValue = (name: Parameters<typeof getCalculatedValue>[0]) =>
            getCalculatedValue(name, [], {
              caseId: caseItem.caseId,
              instrumentId: selectedInstrument?.instrumentId,
              trancheId: x.id,
            })[0]?.value;

          return trancheValue('TrancheDistributionsPaid') * trancheValue('CostOfEquityCumulative');
        }
      );

      const instrumentCostOfEquity = sumBy(
        selectedInstrument.tranches ?? [],
        (x) => weightedTrancheCostofEquities[x.id]
      );

      if (!instrumentCostOfEquity) {
        const rez = getForecastProbabilityWeightedValue({
          caseItem,
          getValue: ({ forecast }) => {
            const trancheCostOfEquities = selectedInstrument.tranches?.map(
              (tranche) =>
                getCalculatedValue('CostOfEquityCumulative', [], {
                  caseId: caseItem.caseId,
                  instrumentId: selectedInstrument?.instrumentId,
                  forecastYear: forecast.forecastYear,
                  trancheId: tranche.id,
                })[0]?.value
            );

            const average =
              (trancheCostOfEquities?.reduce((p, n) => p + n, 0) ?? 0) /
              trancheCostOfEquities!.length;

            return average;
          },
        });
        return rez;
      }

      return instrumentCostOfEquity;
    }

    return NaN;
  };

  const getCaseCostOfEquity = (caseItem: CaseDto) => {
    if (!calculationsLoaded) {
      return NaN;
    }

    const forecastWeightedPV = getCalculatedValue(
      'ValuationPresentValuePerInstrument',
      ['forecastYear'],
      {
        caseId: caseItem.caseId,
        instrumentId: selectedInstrument?.instrumentId,
      }
    )[0]?.value;

    const forecastWeightedNetExitDistributions = getCalculatedValue(
      'NetExitWeightedProceedsPerInstrument',
      ['forecastYear'],
      {
        caseId: caseItem.caseId,
        instrumentId: selectedInstrument?.instrumentId,
      }
    )[0]?.value;

    const forecastWeightedTimeToExitWeightedProceeds =
      getForecastWeightedTimeToExitWeightedProceeds(caseItem);

    if (!forecastWeightedNetExitDistributions || !forecastWeightedTimeToExitWeightedProceeds) {
      // When instrument did not receive any distributions, we need a different approach of calculating.
      // Instead of calculating CoE for instrument using distributions - we go through instrumnet tranche
      // cost of equities
      return getCaseCostOfEquityCalculatedPerTranches(caseItem);
    }

    const exponent = 1 / forecastWeightedTimeToExitWeightedProceeds;
    return Math.pow(1 / (forecastWeightedPV / forecastWeightedNetExitDistributions), exponent) - 1;
  };

  const getCaseWeightedAverageCostOfEquity = () => {
    if (!calculationsLoaded) {
      return NaN;
    }

    return getCaseProbabilityWeightedValue({
      project,
      getValue: ({ caseItem }) => getCaseCostOfEquity(caseItem),
      convertNanToZero: true,
    });
  };

  return {
    getCaseWeightedAverageCostOfEquity,
    getCaseCostOfEquity,
  };
};

export default useCostOfEquityCalculations;
