/* eslint-disable no-console */
import React from 'react';
import WaterfallTable from '../pwerm-summary-waterfall/waterfall-table/WaterfallTable';
import { Pwerm2WaterfallTable } from './Pwerm2WaterfallTable';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import './pwerm-comparator-module.scss';

const PwermComparator: React.FC = () => {
  const HIGHLIGHT_CLASS_NAME = 'difference-highlight';
  const compareTables = (caseSensitive: boolean) => {
    resetComparison();
    let textDifferences = 0;
    let tooltipDifferences = 0;
    const pwermDiv = document.getElementById('pwerm-waterfall-table');
    const pwerm2Div = document.getElementById('pwerm2-waterfall-table');
    const pwermTables = pwermDiv!.getElementsByClassName('table-primary--waterfall');
    const pwerm2Tables = pwerm2Div!.getElementsByClassName('table-primary--waterfall');
    for (let tableIndex = 0; tableIndex < pwermTables.length; tableIndex++) {
      const pwermTable = pwermTables[tableIndex] as HTMLTableElement;
      const pwerm2Table = pwerm2Tables[tableIndex] as HTMLTableElement;
      const pwermRows = pwermTable.rows;
      const pwerm2Rows = pwerm2Table.rows;
      for (let rowIndex = 0; rowIndex < pwermRows.length; rowIndex++) {
        const pwermRow = pwermRows[rowIndex];
        const pwerm2Row = pwerm2Rows[rowIndex];
        const pwermCells = pwermRow.cells;
        const pwerm2Cells = pwerm2Row.cells;
        for (let cellIndex = 0; cellIndex < pwermCells.length; cellIndex++) {
          const pwermCell = pwermCells[cellIndex];
          const pwerm2Cell = pwerm2Cells[cellIndex];
          const pwermCellSpans = pwermCell.getElementsByTagName('span');
          const pwerm2CellSpans = pwerm2Cell.getElementsByTagName('span');
          const pwermCellSpan = pwermCellSpans.length > 0 ? pwermCellSpans[0] : null;
          const pwerm2CellSpan = pwerm2CellSpans.length > 0 ? pwerm2CellSpans[0] : null;
          const pwermCellText = pwermCell.innerText;
          const pwerm2CellText = pwerm2Cell.innerText;
          const pwermCellSpanText = pwermCellSpan?.attributes.getNamedItem('title')?.value;
          const pwerm2CellSpanText = pwerm2CellSpan?.attributes.getNamedItem('title')?.value;
          const diff = caseSensitive
            ? pwermCellText !== pwerm2CellText || pwermCellSpanText !== pwerm2CellSpanText
            : pwermCellText.toLowerCase() !== pwerm2CellText.toLowerCase() ||
              pwermCellSpanText?.toLowerCase() !== pwerm2CellSpanText?.toLowerCase();
          if (diff) {
            pwermCell.classList.add(HIGHLIGHT_CLASS_NAME);
            pwerm2Cell.classList.add(HIGHLIGHT_CLASS_NAME);

            if (pwermCellText !== pwerm2CellText) {
              textDifferences++;
              console.log(
                `Cell [${rowIndex + 1},${
                  cellIndex + 1
                }] is different: '${pwermCellText}' vs '${pwerm2CellText}'`
              );
            }

            if (pwermCellSpanText !== pwerm2CellSpanText) {
              tooltipDifferences++;
              console.log(
                `Cell [${rowIndex + 1},${
                  cellIndex + 1
                }] has different tooltip: '${pwermCellSpanText}' vs '${pwerm2CellSpanText}'`
              );
            }
          }
        }
      }
    }

    console.log(`Text differences: ${textDifferences}`);
    console.log(`Tooltip differences: ${tooltipDifferences}`);
  };

  const resetComparison = () => {
    // this updates dynamically, so we need to do this in a while loop
    const elements = document.getElementsByClassName(HIGHLIGHT_CLASS_NAME);
    while (elements.length > 0) {
      const element = elements[0] as HTMLElement;
      element.classList.remove(HIGHLIGHT_CLASS_NAME);
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: '50px' }} id="pwerm-waterfall-table">
        <WaterfallTable removeStickyColumns shouldRenderInnerContent />
      </div>
      <div style={{ marginRight: '50px' }}>
        <Button appearance={ButtonAppearance.DEFAULT_SECONDARY} onClick={() => compareTables(true)}>
          <>
            Compare Tables
            <br />
            (Case sensitive)
          </>
        </Button>
        <br />
        <Button
          appearance={ButtonAppearance.DEFAULT_SECONDARY}
          onClick={() => compareTables(false)}>
          <>
            Compare Tables
            <br />
            (Case insensitive)
          </>
        </Button>
        <br />
        <Button appearance={ButtonAppearance.DEFAULT_SECONDARY} onClick={() => resetComparison()}>
          Reset Comparison
        </Button>
      </div>
      <div id="pwerm2-waterfall-table">
        <Pwerm2WaterfallTable />
      </div>
    </div>
  );
};

export default PwermComparator;
