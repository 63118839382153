import { FC } from 'react';
import { LoaderType, useLoader } from '@core/hooks/useLoader';
import styles from './global-loader.module.scss';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation } from '@app/shared/components/card/card-enums';
import { SpinnerLoader } from '@app/shared/components/loader/SpinnerLoader';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { ProgressLoader } from '@app/shared/components/loader/ProgressLoader';

const PARENT_CLASSNAME = 'loader-overlay';
export const GlobalLoader: FC = () => {
  const operationStatus = useAppSelector((state) => state.uiValues.operationStatus);
  const isProgressVisible = operationStatus.isExportRunning || operationStatus.isOpmCalcRunning;
  const isLoaderVisible = useLoader(LoaderType.Blocking);

  return (
    <>
      {isLoaderVisible && (
        <div className={styles[PARENT_CLASSNAME]}>
          <Card hasShadow cornersVariation={CardCornersVariation.Primary} shouldFitContent>
            <SpinnerLoader />
          </Card>
        </div>
      )}
      {isProgressVisible && (
        <div className={styles[PARENT_CLASSNAME]}>
          <Card
            hasShadow
            cornersVariation={CardCornersVariation.Primary}
            shouldFitContent
            className={styles['progress-card']}>
            <ProgressLoader />
          </Card>
        </div>
      )}
    </>
  );
};
