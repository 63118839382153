import { Components, Theme } from '@mui/material';
import { RemSpacing } from '@styles/theme/spacing';

export const SNACKBAR_AUTOHIDE_TIME = 6000;

export enum CloseReason {
  clickAway = 'clickaway',
  escapeKeyDown = 'escapeKeyDown',
  timeout = 'timeout',
}

const snackbarComponent: Components<Theme> = {
  MuiSnackbar: {
    styleOverrides: {
      root: {
        maxWidth: '350px',
        '&.MuiSnackbar--static': {
          bottom: 0,
          justifyContent: 'flex-start',
          left: 0,
          marginBottom: RemSpacing.spacer24,
          position: 'static',
          right: 0,
          top: 0,
          transform: 'none',
        },
        '&.MuiSnackbar--no-gutter': {
          marginBottom: 0,
        },
        '&.MuiSnackbar--fit-content': {
          maxWidth: 'fit-content',
        },
      },
    },
  },
};

export default snackbarComponent;
