import formControlLabel from '@app/shared/mui-components/form-controls/form-control-label/form-control-label-types';
import palette from './palette/palette';
import shape from '@styles/theme/shape';
import snackbarComponent from '@app/shared/mui-components/snackbar/snackbar-types';
import svgIconComponent from '@styles/theme/svg-icon';
import textFieldComponent from '@app/shared/mui-components/form-controls/textfield/textfield-types';
import typography from './typography';
import { createTheme, Theme } from '@mui/material';
import datePickerComponent from '@app/shared/components/datepicker/datepicker-theme-config';
import legacyDatePickerComponent from '@app/shared/mui-components/form-controls/datepicker/datepicker-types';

const globalTheme: Theme = createTheme({
  palette: {
    ...palette,
  },
  typography: {
    ...typography,
  },
  shape: {
    ...shape,
  },
  components: {
    ...legacyDatePickerComponent,
    ...datePickerComponent,
    ...formControlLabel,
    ...snackbarComponent,
    ...svgIconComponent,
    ...textFieldComponent,
  },
});

export default globalTheme;
