import { renderToString } from 'react-dom/server';
import { Context, ContextTypes } from '../interfaces/context';

const acceptTypes = ['string', 'number', 'boolean'];

export function format(s: string, c: Context) {
  return s.replace(/{(\w+)}/g, (_: string, p: ContextTypes) => c[p]);
}

export const fileMimeType = 'application/vnd.ms-excel';
export const template =
  '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-mic' +
  'rosoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta cha' +
  'rset="UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:Exce' +
  'lWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/>' +
  '</x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></' +
  'xml><![endif]--></head><body>{table}</body></html>';

export interface ITablePayload {
  header: string[];
  body: Array<{ [key: string]: string | number | boolean }> | Array<(string | number | boolean)[]>;
}

export function GenerateTable({ header, body }: ITablePayload) {
  const headers = (
    <tr>
      {header.map((head) => (
        <th key={head}>{head}</th>
      ))}
    </tr>
  );

  const bodies = body.map((val, i) => {
    if (Array.isArray(val)) {
      return (
        <tr key={i}>
          {val.map((value, i) => (
            <th key={i}> {value} </th>
          ))}
        </tr>
      );
    }

    if (val !== null && typeof val === 'object') {
      return (
        <tr key={i}>
          {Object.entries(val).map(([key, value], i) => {
            if (typeof value === 'object') {
              // eslint-disable-next-line no-console
              console.error(`typeof ${key} is incorrect, only accept ${acceptTypes.join(', ')} `);

              return <th key={i} />;
            }

            return (
              <th key={i}>
                <>{value}</>
              </th>
            );
          })}
        </tr>
      );
    }

    // eslint-disable-next-line no-console
    console.error(
      `
       data structure is incorrect,
       data structure type ->
       " type data = Array<{ [key: string]: string | number | boolean }>
                         or
        type data = Array<(string | number | boolean)[]>"
      `
    );

    return null;
  });

  return renderToString(
    <table>
      <tbody>
        {headers}
        {bodies}
      </tbody>
    </table>
  );
}
