import { SVGProps } from 'react';
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M6.466 9.305A.647.647 0 0 1 6 9.5a.647.647 0 0 1-.466-.195L1.89 5.555a.612.612 0 0 1-.123-.677.645.645 0 0 1 .59-.378h1.928V1.167c0-.461.383-.834.857-.834h1.714c.474 0 .857.373.857.834V4.5h1.929c.257 0 .487.149.59.378a.612.612 0 0 1-.124.677l-3.643 3.75ZM1.714 9.5v1.667c0 .46.383.833.857.833H9.43a.845.845 0 0 0 .857-.833V9.5c0-.46.383-.833.857-.833S12 9.039 12 9.5v1.667c0 1.38-1.152 2.5-2.571 2.5H2.57c-1.42 0-2.571-1.12-2.571-2.5V9.5c0-.46.383-.833.857-.833s.857.372.857.833Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgDownload;
