import { SVGProps } from 'react';
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <rect opacity={0.6} x={11} y={5} width={2} height={14} rx={1} fill="currentColor" />
    <path
      d="M6.707 12.707a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.383-.03l6 5.5a1 1 0 0 1-1.352 1.474L12.03 7.384l-5.323 5.323Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowUp;
