import { FC, useEffect, useState } from 'react';
import styles from './header.module.scss';
import { useAppSelector } from '@core/hooks/redux-hooks';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import SvgLogo from '@app/shared/icons/Logo';
import { Breadcrumbs } from '@app/shared/components/breadcrumbs/Breadcrumbs';
import { useLocation, useParams } from 'react-router-dom';
import PwcLink from '@app/shared/routing/PwcLink';
import WaterfallTable from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTable';
import { Avatar } from '@mui/material';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import env from '@environment';
import SvgLogout from '@app/shared/icons/Logout';
import { SaveProjectAction } from '@app/modules/projects/inputs/project-admin/SaveProjectAction';
import { SpinnerLoader } from '@app/shared/components/loader/SpinnerLoader';
import { LoaderSize } from '@app/shared/components/loader/loader-enums';
import { LoaderType, useLoader } from '@core/hooks/useLoader';
import { CurrentActiveUsersIndicator } from '@app/shared/components/active-users/CurrentActiveUsersIndicator';
import { ParticipationTableExcelExport } from '@app/modules/projects/inputs/capital-structure/tabs/participation-table/ParticipationTableExcelExport';
import { RunOpmButtonAndDropdown } from '@app/shared/components/run-opm-button/RunOpmButtonAndDropdown';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import { ExportButtonDropdown } from './ExportButtonDropdown';
import { Pwerm2WaterfallTable } from '@app/modules/projects/outputs/pwerm2-summary-waterfall/Pwerm2WaterfallTable';
import { DevFeature, useDevFeatures } from '@app/core/utils/dev-feature';
import { ParticipationTableExcelExport2 } from '@app/modules/projects/inputs/capital-structure/tabs/participation-table-2/ParticipationTableExcelExport2';
import { ParticipationTableExcelExportErf } from '@app/modules/projects/inputs/capital-structure/tabs/participation-table-erf/ParticipationTableExcelExportErf';
import { ClientScheduleExport } from './ClientScheduleExport';

const PARENT_CLASSNAME = 'header';

const Header: FC = (): JSX.Element => {
  const location = useLocation();
  const { projectId } = useParams();
  const calculatedResults = useAppSelector((state) => state.pwermCalculation);
  const isAuthenticated = useAppSelector((state) => state.uiValues.isAuthenticated);
  const projectIdRegex = /^\/projects\/\d+/;
  const isSaveButtonVisible = projectIdRegex.test(location?.pathname) && isAuthenticated;
  const versionTag = useAppSelector((state) => state.uiValues.versionTag);
  const project = useAppSelector((state) => state.project);
  const [isExportTableRendered, setIsExportTableRendered] = useState<boolean>(false);
  const calcMethod = project?.projectDraft?.details?.calcMethod;
  const isOpmEnabled = calcMethod && calcMethod !== CalcMethod.PWERM;
  const isRunOpmButtonVisible = projectIdRegex.test(location?.pathname) && isOpmEnabled;
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const projectIdString = project?.projectDraft?.id?.toString();
  const { isDevFeatureEnabled } = useDevFeatures();
  const isPWERM2Enabled = isDevFeatureEnabled(DevFeature.PWERM2);
  const isErfEnabled = isDevFeatureEnabled(DevFeature.ERF);
  const isClientScheduleEnabled = isDevFeatureEnabled(DevFeature.ClientSchedule);

  const extractUserInitials = (userName: string) =>
    userName
      .split(' ')
      .map((element) => element[0])
      .join('');

  const downloadExcel = () => {
    setIsExportTableRendered(true);
    // resolve concurrency issues, as there are no other possible hooks
    setTimeout(() => {
      setIsExportTableRendered(false);
    }, 0);
  };

  const [state, setState] = useState(() => ({
    userInitials: '',
    userEmail: '',
    userName: '',
  }));

  useEffect(() => {
    setState({
      userInitials: extractUserInitials(userInfo.fullUserName),
      userEmail: userInfo.email,
      userName: userInfo.fullUserName,
    });
  }, [userInfo]);

  const isLoading = useLoader(LoaderType.NonBlocking);

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div className={styles[`${PARENT_CLASSNAME}__primary-container`]}>
        <PwcLink route="/" aria-label="Home">
          <SvgLogo className={styles[`${PARENT_CLASSNAME}__logo`]} aria-hidden />
        </PwcLink>
        <span className={styles[`${PARENT_CLASSNAME}__app-name`]}>Equity Reward Analytics</span>
        <Breadcrumbs />
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__middle-container`]}>
        <CurrentActiveUsersIndicator />
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__secondary-container`]}>
        {isLoading && (
          <div className={styles[`${PARENT_CLASSNAME}__loader-container`]}>
            <div className={styles[`${PARENT_CLASSNAME}__loader-icon`]}>
              <SpinnerLoader size={LoaderSize.Small} />
            </div>
            Working...
          </div>
        )}
        {isRunOpmButtonVisible && <RunOpmButtonAndDropdown />}
        {isSaveButtonVisible && isClientScheduleEnabled && <ClientScheduleExport />}
        {isSaveButtonVisible && <ExportButtonDropdown pwermExcelExport={downloadExcel} />}
        {isSaveButtonVisible && <SaveProjectAction />}
        <DropdownMenu
          ariaLabel="User Profile"
          dropdownContent={
            <Avatar className={styles[`${PARENT_CLASSNAME}__avatar`]}>{state.userInitials}</Avatar>
          }
          ignoreReadOnly>
          <h5 className={styles[`${PARENT_CLASSNAME}__header`]}> {state.userName}</h5>
          <p title={state.userEmail} className={styles[`${PARENT_CLASSNAME}__sub-header`]}>
            {state.userEmail}
          </p>
          <div className={styles[`${PARENT_CLASSNAME}__feedback-link-container`]}>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSekOERw6QLHmJthG2tQ2vTINB74z3f13BWyJgb2vH0davmOnA/viewform?usp=sf_link"
              rel="noreferrer"
              target="_blank"
              className={styles[`${PARENT_CLASSNAME}__feedback-link`]}>
              Give feedback
            </a>
          </div>
          <Button
            to={`${env.apiUrl}/account/logout?redirectUri=${document.URL}`}
            className={styles[`${PARENT_CLASSNAME}__logout`]}
            startIcon={<SvgLogout width={16} />}
            appearance={ButtonAppearance.DEFAULT_SECONDARY}
            ignoreReadOnly>
            Log Out
          </Button>
          {versionTag && (
            <div className={styles[`${PARENT_CLASSNAME}__version-tag`]}>
              Equity Reward Analytics {versionTag}
            </div>
          )}
        </DropdownMenu>
      </div>
      <div className={styles[`${PARENT_CLASSNAME}__exportable-table-container`]}>
        {projectId === projectIdString &&
          Boolean(calculatedResults?.values?.calculatedVariables?.result) && (
            <>
              <WaterfallTable shouldRenderInnerContent={isExportTableRendered} excelExportEnabled />
              {isPWERM2Enabled && (
                <Pwerm2WaterfallTable
                  shouldRenderInnerContent={isExportTableRendered}
                  excelExportEnabled
                />
              )}
              <ParticipationTableExcelExport
                shouldRenderInnerContent={isExportTableRendered}
                excelExportEnabled
              />
              {isPWERM2Enabled && (
                <ParticipationTableExcelExport2
                  shouldRenderInnerContent={isExportTableRendered}
                  excelExportEnabled
                />
              )}
              {isErfEnabled && (
                <ParticipationTableExcelExportErf
                  shouldRenderInnerContent={isExportTableRendered}
                  excelExportEnabled
                />
              )}
            </>
          )}
      </div>
    </div>
  );
};

export default Header;
