import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC, Fragment } from 'react';
import {
  Denomination,
  InstrumentType,
  OwnerType,
} from '@app/shared/models/contracts/enums/shared-enums';
import {
  currencyDenominationPair,
  enumKeyByValue,
  instrumentsByTypeSortFn,
} from '@app/shared/helpers';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  decimalCalculatedViewRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import styles from './cap-table.module.scss';
import {
  calculatePercentage,
  getTotalOfEquityByInstrumentTypeAndOwnership,
} from '@app/core/store/project-slice-selectors';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { getEquityInstrumentCountByType } from '@app/shared/helpers/equity-instruments/get-equity-instrument-count-by-type';
import classNames from 'classnames';
import useSetSelectedTab from '@app/core/hooks/useSetSelectedTab';
import { RouteConstants } from '@app/modules/projects/RouteConstants';
import { setCapitalStructureSelectedTab } from '@app/core/store/ui-values-slice';

const PARENT_CLASSNAME = 'cap-table';
const CapTable: FC = () => {
  useSetSelectedTab(RouteConstants.CapTable, setCapitalStructureSelectedTab);

  const equityItems = useAppSelector((state) => state.project.projectDraft.equityInstruments);
  const {
    details: { currency, denomination },
  } = useAppSelector((state) => state.project.projectDraft);

  const sortedEquityItems = equityItems?.slice().sort(instrumentsByTypeSortFn) || [];
  const consistsShareHolderLoanNotes = getEquityInstrumentCountByType(
    equityItems,
    InstrumentType.ShareholderLoanNotes
  );
  const consistsPreferenceShares = getEquityInstrumentCountByType(
    equityItems,
    InstrumentType.PreferredShares
  );
  const consistsOrdinaryEquities = getEquityInstrumentCountByType(
    equityItems,
    InstrumentType.OrdinaryEquity
  );

  const countColSpan = () => {
    return (
      (consistsOrdinaryEquities ? 1 : 0) +
      (consistsPreferenceShares ? 1 : 0) +
      (consistsShareHolderLoanNotes ? 1 : 0)
    );
  };

  return (
    <div className={styles[PARENT_CLASSNAME]}>
      <div className="main-container-padding-remove">
        <table className="table-primary table-primary--zebra table-primary--framed-bottom table-primary--framed">
          <colgroup>
            <col className={styles[`${PARENT_CLASSNAME}__labels-col`]} />
            <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
            {Boolean(consistsShareHolderLoanNotes) && (
              <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
            )}
            {Boolean(consistsPreferenceShares) && (
              <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
            )}
            {Boolean(consistsOrdinaryEquities) && (
              <col className={styles[`${PARENT_CLASSNAME}__summary-col`]} />
            )}
            {(Object.keys(OwnerType) as Array<keyof typeof OwnerType>).map((key) => {
              return <col key={`cap-table-col-${key}`} />;
            })}
          </colgroup>
          <thead className="table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
            <tr className="table-primary__row--secondary">
              <th className="table-primary__cell--header-secondary table-primary__cell--vertical-separator table-primary__cell--section-end-separator table-primary__cell--center table-primary__cell--align-bottom">
                <strong>Owner</strong>
              </th>
              <th
                className="table-primary__cell--header-secondary table-primary__cell--center table-primary__cell--section-end-separator table-primary__cell--align-bottom"
                colSpan={1 + countColSpan()}>
                <strong>Summary ({currencyDenominationPair(currency, denomination)})</strong>
              </th>
              {sortedEquityItems.map((field, index) => (
                <Fragment key={field.instrumentNarrative + index + 'headers'}>
                  <th
                    className={classNames(
                      'table-primary__cell--header-secondary table-primary__cell--vertical-separator-start table-primary__cell--section-end-separator table-primary__cell--center table-primary__cell--align-bottom',
                      {
                        [styles[`${PARENT_CLASSNAME}__instrument-col`]]: true,
                      }
                    )}
                    colSpan={2}>
                    <strong>{field.instrumentNarrative}</strong>
                  </th>
                </Fragment>
              ))}
            </tr>
            <tr className="table-primary__row--secondary">
              <th className="table-primary__cell--header-secondary table-primary__cell--vertical-separator" />
              <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                <strong>Investment</strong>
              </th>
              {Boolean(consistsShareHolderLoanNotes) && (
                <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                  <strong>SHLs</strong>
                </th>
              )}
              {Boolean(consistsPreferenceShares) && (
                <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                  <strong>Pref Shares</strong>
                </th>
              )}
              {Boolean(consistsOrdinaryEquities) && (
                <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                  <strong>Ordinary Equity</strong>
                </th>
              )}
              {sortedEquityItems.map((field, index) => (
                <Fragment key={field.instrumentNarrative + index + 'headers'}>
                  <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--vertical-separator-start table-primary__cell--nowrap">
                    <CellValue
                      strong
                      alignment={Alignment.Right}
                      prefix={
                        denomination === enumKeyByValue(Denomination, Denomination.Units)
                          ? ''
                          : '# '
                      }
                      value={
                        denomination === enumKeyByValue(Denomination, Denomination.Units)
                          ? '#'
                          : Denomination[denomination]
                      }
                    />
                  </th>
                  <th className="table-primary__cell--header-secondary table-primary__cell--right table-primary__cell--nowrap">
                    <CellValue
                      strong
                      alignment={Alignment.Right}
                      value={currencyDenominationPair(currency, denomination)}
                    />
                  </th>
                </Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            <>
              {(Object.keys(OwnerType) as Array<keyof typeof OwnerType>).map((key) => {
                {
                  return (
                    getTotalOfEquityByInstrumentTypeAndOwnership(
                      equityItems,
                      undefined,
                      enumKeyByValue(OwnerType, OwnerType[key])
                    ) !== null && (
                      <Fragment key={`cap-item-${key}`}>
                        <tr className="table-primary__row--secondary">
                          <th className="table-primary__cell--vertical-separator">
                            <strong>{OwnerType[key]}</strong>
                          </th>
                          <td>
                            <CellValue
                              {...amountRules}
                              value={getTotalOfEquityByInstrumentTypeAndOwnership(
                                equityItems,
                                undefined,
                                enumKeyByValue(OwnerType, OwnerType[key])
                              )}
                            />
                          </td>
                          {Boolean(consistsShareHolderLoanNotes) && (
                            <td>
                              <CellValue
                                {...amountRules}
                                value={getTotalOfEquityByInstrumentTypeAndOwnership(
                                  equityItems,
                                  enumKeyByValue(
                                    InstrumentType,
                                    InstrumentType.ShareholderLoanNotes
                                  ),
                                  enumKeyByValue(OwnerType, OwnerType[key])
                                )}
                              />
                            </td>
                          )}
                          {Boolean(consistsPreferenceShares) && (
                            <td>
                              <CellValue
                                {...amountRules}
                                value={getTotalOfEquityByInstrumentTypeAndOwnership(
                                  equityItems,
                                  enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
                                  enumKeyByValue(OwnerType, OwnerType[key])
                                )}
                              />
                            </td>
                          )}
                          {Boolean(consistsOrdinaryEquities) && (
                            <td>
                              <CellValue
                                {...amountRules}
                                value={getTotalOfEquityByInstrumentTypeAndOwnership(
                                  equityItems,
                                  enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity),
                                  enumKeyByValue(OwnerType, OwnerType[key])
                                )}
                              />
                            </td>
                          )}
                          {sortedEquityItems.map((field, index) => (
                            <Fragment key={field.instrumentNarrative + index + OwnerType[key]}>
                              <td className="table-primary__cell--vertical-separator-start">
                                <CellValue
                                  {...decimalCalculatedViewRules}
                                  value={getTotalOfEquityByInstrumentTypeAndOwnership(
                                    [field],
                                    undefined,
                                    enumKeyByValue(OwnerType, OwnerType[key]),
                                    'numberOfShares'
                                  )}
                                />
                              </td>
                              <td>
                                <CellValue
                                  {...amountRules}
                                  value={getTotalOfEquityByInstrumentTypeAndOwnership(
                                    [field],
                                    undefined,
                                    enumKeyByValue(OwnerType, OwnerType[key])
                                  )}
                                />
                              </td>
                            </Fragment>
                          ))}
                        </tr>
                        <tr className="table-primary__row--plain">
                          <th className="table-primary__cell--vertical-separator">
                            % Shareholding
                          </th>
                          <td>
                            <CellValue
                              {...enteredPercentageViewRules}
                              value={calculatePercentage(
                                getTotalOfEquityByInstrumentTypeAndOwnership(equityItems),
                                getTotalOfEquityByInstrumentTypeAndOwnership(
                                  equityItems,
                                  undefined,
                                  enumKeyByValue(OwnerType, OwnerType[key])
                                )
                              )}
                            />
                          </td>
                          {Boolean(consistsShareHolderLoanNotes) && (
                            <td>
                              <CellValue
                                {...enteredPercentageViewRules}
                                value={calculatePercentage(
                                  getTotalOfEquityByInstrumentTypeAndOwnership(
                                    equityItems,
                                    enumKeyByValue(
                                      InstrumentType,
                                      InstrumentType.ShareholderLoanNotes
                                    )
                                  ),
                                  getTotalOfEquityByInstrumentTypeAndOwnership(
                                    equityItems,
                                    enumKeyByValue(
                                      InstrumentType,
                                      InstrumentType.ShareholderLoanNotes
                                    ),
                                    enumKeyByValue(OwnerType, OwnerType[key])
                                  )
                                )}
                              />
                            </td>
                          )}
                          {Boolean(consistsPreferenceShares) && (
                            <td>
                              <CellValue
                                {...enteredPercentageViewRules}
                                value={calculatePercentage(
                                  getTotalOfEquityByInstrumentTypeAndOwnership(
                                    equityItems,
                                    enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
                                  ),
                                  getTotalOfEquityByInstrumentTypeAndOwnership(
                                    equityItems,
                                    enumKeyByValue(InstrumentType, InstrumentType.PreferredShares),
                                    enumKeyByValue(OwnerType, OwnerType[key])
                                  )
                                )}
                              />
                            </td>
                          )}
                          {Boolean(consistsOrdinaryEquities) && (
                            <td>
                              <CellValue
                                {...enteredPercentageViewRules}
                                value={calculatePercentage(
                                  getTotalOfEquityByInstrumentTypeAndOwnership(
                                    equityItems,
                                    enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
                                  ),
                                  getTotalOfEquityByInstrumentTypeAndOwnership(
                                    equityItems,
                                    enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity),
                                    enumKeyByValue(OwnerType, OwnerType[key])
                                  )
                                )}
                              />
                            </td>
                          )}
                          {sortedEquityItems.map((field, index) => (
                            <Fragment key={field.instrumentNarrative + index + OwnerType[key]}>
                              <td className="table-primary__cell--vertical-separator-start">
                                <CellValue
                                  {...enteredPercentageViewRules}
                                  value={calculatePercentage(
                                    getTotalOfEquityByInstrumentTypeAndOwnership(
                                      [field],
                                      undefined,
                                      undefined,
                                      'numberOfShares'
                                    ),
                                    getTotalOfEquityByInstrumentTypeAndOwnership(
                                      [field],
                                      undefined,
                                      enumKeyByValue(OwnerType, OwnerType[key]),
                                      'numberOfShares'
                                    )
                                  )}
                                />
                              </td>
                              <td>
                                <CellValue
                                  {...enteredPercentageViewRules}
                                  value={calculatePercentage(
                                    getTotalOfEquityByInstrumentTypeAndOwnership([field]),
                                    getTotalOfEquityByInstrumentTypeAndOwnership(
                                      [field],
                                      undefined,
                                      enumKeyByValue(OwnerType, OwnerType[key])
                                    )
                                  )}
                                />
                              </td>
                            </Fragment>
                          ))}
                        </tr>
                        <tr className="table-primary__row--plain">
                          <td className="table-primary__cell--vertical-separator  table-primary__cell--section-separator" />
                          <td
                            className="table-primary__cell--section-separator"
                            colSpan={1 + countColSpan()}
                          />
                          {sortedEquityItems.map((field, index) => (
                            <td
                              key={'spacing-row' + index}
                              colSpan={2}
                              className="table-primary__cell--empty-spacer table-primary__cell--vertical-separator-start  table-primary__cell--section-separator"
                            />
                          ))}
                        </tr>
                      </Fragment>
                    )
                  );
                }
              })}
            </>
            <tr>
              <th className="table-primary__cell--vertical-separator table-primary__cell--highlight table-primary__cell--section-start-separator">
                <CellValue value="Total" strong />
              </th>
              <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                <CellValue
                  {...amountRules}
                  value={getTotalOfEquityByInstrumentTypeAndOwnership(equityItems)}
                />
              </td>
              {Boolean(consistsShareHolderLoanNotes) && (
                <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                  <CellValue
                    {...amountRules}
                    value={getTotalOfEquityByInstrumentTypeAndOwnership(
                      equityItems,
                      enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
                    )}
                  />
                </td>
              )}
              {Boolean(consistsPreferenceShares) && (
                <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                  <CellValue
                    {...amountRules}
                    value={getTotalOfEquityByInstrumentTypeAndOwnership(
                      equityItems,
                      enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
                    )}
                  />
                </td>
              )}
              {Boolean(consistsOrdinaryEquities) && (
                <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                  <CellValue
                    {...amountRules}
                    value={getTotalOfEquityByInstrumentTypeAndOwnership(
                      equityItems,
                      enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
                    )}
                  />
                </td>
              )}
              {sortedEquityItems.map((field, index) => (
                <Fragment key={field.instrumentNarrative + index + 'numOfShares'}>
                  <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator table-primary__cell--vertical-separator-start">
                    <CellValue
                      {...decimalCalculatedViewRules}
                      value={getTotalOfEquityByInstrumentTypeAndOwnership(
                        [field],
                        undefined,
                        undefined,
                        'numberOfShares'
                      )}
                    />
                  </td>
                  <td className="table-primary__cell--highlight table-primary__cell--strong table-primary__cell--section-start-separator">
                    <CellValue
                      {...amountRules}
                      value={getTotalOfEquityByInstrumentTypeAndOwnership([field])}
                    />
                  </td>
                </Fragment>
              ))}
            </tr>
            <tr>
              <th className="table-primary__cell--vertical-separator" />
              <td />
              {Boolean(consistsShareHolderLoanNotes) && (
                <td className="table-primary__cell--strong">
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={calculatePercentage(
                      getTotalOfEquityByInstrumentTypeAndOwnership(equityItems),
                      getTotalOfEquityByInstrumentTypeAndOwnership(
                        equityItems,
                        enumKeyByValue(InstrumentType, InstrumentType.ShareholderLoanNotes)
                      )
                    )}
                  />
                </td>
              )}
              {Boolean(consistsPreferenceShares) && (
                <td className="table-primary__cell--strong">
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={calculatePercentage(
                      getTotalOfEquityByInstrumentTypeAndOwnership(equityItems),
                      getTotalOfEquityByInstrumentTypeAndOwnership(
                        equityItems,
                        enumKeyByValue(InstrumentType, InstrumentType.PreferredShares)
                      )
                    )}
                  />
                </td>
              )}
              {Boolean(consistsOrdinaryEquities) && (
                <td className="table-primary__cell--strong">
                  <CellValue
                    {...enteredPercentageViewRules}
                    value={calculatePercentage(
                      getTotalOfEquityByInstrumentTypeAndOwnership(equityItems),
                      getTotalOfEquityByInstrumentTypeAndOwnership(
                        equityItems,
                        enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity)
                      )
                    )}
                  />
                </td>
              )}
              {sortedEquityItems.map((field, index) => (
                <Fragment key={field.instrumentNarrative + index + InstrumentType[field.type]}>
                  <td className="table-primary__cell--strong table-primary__cell--vertical-separator-start">
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={calculatePercentage(
                        getTotalOfEquityByInstrumentTypeAndOwnership(
                          equityItems,
                          enumKeyByValue(InstrumentType, InstrumentType[field.type]),
                          undefined,
                          'numberOfShares'
                        ),
                        getTotalOfEquityByInstrumentTypeAndOwnership(
                          [field],
                          enumKeyByValue(InstrumentType, InstrumentType[field.type]),
                          undefined,
                          'numberOfShares'
                        )
                      )}
                    />
                  </td>
                  <td className="table-primary__cell--strong table-primary--section-end-separator">
                    <CellValue
                      {...enteredPercentageViewRules}
                      value={calculatePercentage(
                        getTotalOfEquityByInstrumentTypeAndOwnership(
                          equityItems,
                          enumKeyByValue(InstrumentType, InstrumentType[field.type])
                        ),
                        getTotalOfEquityByInstrumentTypeAndOwnership(
                          [field],
                          enumKeyByValue(InstrumentType, InstrumentType[field.type])
                        )
                      )}
                    />
                  </td>
                </Fragment>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CapTable;
