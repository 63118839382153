import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { FC, useState } from 'react';
import { DeleteUserConfirmModal } from './DeleteUserConfirmModal';
import SvgUserRemove from '@app/shared/icons/UserRemove';

interface DeleteUserActionProps {
  email: string;
}

export const DeleteUserAction: FC<DeleteUserActionProps> = ({ email }): JSX.Element => {
  const [isConfirmDeleteProjectModalOpen, setIsConfirmDeleteUserModalOpen] = useState(false);
  return (
    <>
      <Button
        data-testid={`${email}-remove-action-button`}
        startIcon={<SvgUserRemove />}
        onClick={() => setIsConfirmDeleteUserModalOpen(true)}
        appearance={ButtonAppearance.DANGER_TERTIARY}>
        Remove From Platform
      </Button>
      {isConfirmDeleteProjectModalOpen && (
        <DeleteUserConfirmModal
          setIsOpen={setIsConfirmDeleteUserModalOpen}
          isOpen={isConfirmDeleteProjectModalOpen}
          email={email}
        />
      )}
    </>
  );
};
