import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import { useEffect } from 'react';
import { ButtonAppearance } from '../button/button-enums';
import ChevronWhite from '@app/shared/icons/ChevronWhite';
import { Toggle } from '@app/shared/components/toggle/Toggle';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import styles from './runOpmButton.module.scss';
import classNames from 'classnames';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import * as projectActions from '@core/store/project-slice';
import Chevron from '@app/shared/icons/Chevron';
import { ProjectFormModel } from '@app/modules/projects/inputs/project-admin/project-form/project-form-types';
import { cloneDeep } from '@app/shared/helpers';
import ChevronInactive from '@app/shared/icons/ChevronInactive';
import { RunOpmButton } from '@app/shared/components/run-opm-button/RunOpmButton';
import { Tooltip } from '@app/shared/components/tooltip/Tooltip';
import { RUN_OPM_INACTIVE_DROPDOWN_TOOLTIP_WIDTH } from '../tooltip/constants';

const PARENT_CLASSNAME = 'run-opm-button';

export const RunOpmButtonAndDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const { l } = useLocale();
  const {
    projectDraft: projectDraft,
    opmNeedsRecalculated: opmNeedsRecalculated,
    isOpmValid: isOpmValid,
  } = useAppSelector((state) => state.project);

  const errorMessageList = useAppSelector((state) => state.notification.opmErrorMessageList);

  const formMethods = useForm<ProjectFormModel>({
    ...formConfigBase,
    defaultValues: {
      randomiserActive: projectDraft.opmInput.randomiserActive ?? false,
    },
  });

  useEffect(() => {
    formMethods.setValue('randomiserActive', projectDraft.opmInput.randomiserActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectDraft.opmInput.randomiserActive]);

  const submitData = async () => {
    const { ...updatedOpmInput } = {
      ...projectDraft.opmInput,
      ...formMethods.getValues(),
    };
    await dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...projectDraft,
          opmInput: {
            ...cloneDeep(updatedOpmInput),
          },
        },
      })
    ).unwrap();
  };

  const ErrorList = () => {
    return errorMessageList ? (
      <ul>
        {errorMessageList.map((error, index) => (
          <li key={index} data-testid={`run-opm-button-tooltip-${index}`}>
            {error}
          </li>
        ))}
      </ul>
    ) : (
      <p>{l('_RunOpmButtonTooltip')}</p>
    );
  };

  return (
    <div className={classNames(styles[`${PARENT_CLASSNAME}__button-wrapper`])}>
      {!isOpmValid ? (
        <Tooltip
          content={<ErrorList />}
          width={RUN_OPM_INACTIVE_DROPDOWN_TOOLTIP_WIDTH}
          style={{ zIndex: 5 }}
          data-testid="run-opm-button-tooltip">
          <div>
            <RunOpmButton />
          </div>
        </Tooltip>
      ) : (
        <RunOpmButton />
      )}
      <DropdownMenu
        data-testid="run-opm-button-dropdown"
        ariaLabel={l('_RunOpmDropdownMenu')}
        buttonClassName={classNames(styles[`${PARENT_CLASSNAME}__run-opm-button-dropdown`])}
        menuDropdownClassName="__menu-run-opm"
        dropdownTriggerIcon={
          !isOpmValid ? <ChevronInactive /> : opmNeedsRecalculated ? <ChevronWhite /> : <Chevron />
        }
        useDefaultSvgColour
        appearance={
          !isOpmValid
            ? ButtonAppearance.DEFAULT_INACTIVE
            : opmNeedsRecalculated
            ? ButtonAppearance.DEFAULT_PRIMARY
            : ButtonAppearance.DEFAULT_SECONDARY
        }>
        <div className={classNames(styles[`${PARENT_CLASSNAME}__menu-dropdown-item`])}>
          <p>{l('_Randomiser')}</p>
          <div className={classNames(styles[`${PARENT_CLASSNAME}__menu-dropdown-toggle`])}>
            <FormProvider {...formMethods}>
              <Toggle
                name="randomiserActive"
                forceChecked={projectDraft.opmInput.randomiserActive}
                onChange={submitData}
                data-testid="randomiser-active-toggle"
              />
            </FormProvider>
            <p
              className={classNames(styles[`${PARENT_CLASSNAME}__menu-dropdown-label`])}
              data-testid="randomiser-active-text">
              {projectDraft.opmInput.randomiserActive ? l('_Active') : l('_Inactive')}
            </p>
          </div>
        </div>
        <div className={classNames(styles[`${PARENT_CLASSNAME}__menu-dropdown-item`])}>
          <p>{l('_NumberOfSimulations')}</p>
          <p className={classNames(styles[`${PARENT_CLASSNAME}__menu-dropdown-label`])}>
            {projectDraft.opmInput.iterationCount?.toLocaleString()}
          </p>
        </div>
      </DropdownMenu>
    </div>
  );
};
