import { useCSSVariables } from '@app/core/hooks/useCSSVariables';
import { useLocale } from '@app/core/hooks/useLocale';
import { abbreviatedValueFormatter, toPercentageUsingFormatter } from '@app/shared/formatters';
import Highcharts from 'highcharts';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChartZoomMode,
  SimulatedOutcomesChartTypes,
  ViewOption,
} from '@app/modules/projects/dashboard/opm-charts/opm-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import styles from '@app/modules/projects/dashboard/opm-charts/opm-charts.module.scss';
import {
  axisLabelFormatter,
  formatNumber,
} from '@app/modules/projects/dashboard/opm-charts/format-helpers';
import { OpmStackedDistributionPlotDataPointDto } from '@app/shared/models/contracts/opm-calculation-results-dto';
import { ExitEquityModal } from './ExitEquityModal';
import {
  cloneDeep,
  enumKeyByValue,
  plotInstrumentByTypeOrDefaultSortFn,
} from '@app/shared/helpers';
import { handleClashingLabels } from '@app/shared/helpers/charts/HandleClashingLabels';
import '../chart-initialisation';
import { updateStackedDistributionChartData } from '../data-helpers';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';

interface StackedDistributionChartProps {
  selectedYear: string;
  selectedView: ViewOption | string;
  chartType: SimulatedOutcomesChartTypes;
  zoomMode: ChartZoomMode;
  handleManualZoom: (min: number | undefined, max: number | undefined) => void;
  zoomMinimum: number | undefined;
  zoomMaximum: number | undefined;
}

export const StackedDistributionChart: FC<StackedDistributionChartProps> = ({
  selectedYear,
  selectedView,
  chartType,
  zoomMode,
  handleManualZoom,
  zoomMinimum,
  zoomMaximum,
}): JSX.Element => {
  const stateChartData = useAppSelector(
    (state) =>
      state.opmCalculation.realWorldValues.simulatedOutcomesStackedDistributionPlot.plotData
  );
  const denomination = useAppSelector((state) => state.project.projectDraft.details.denomination);
  const plotData = useMemo(() => {
    const data = cloneDeep(stateChartData);
    return updateStackedDistributionChartData(data, denomination);
  }, [stateChartData, denomination]);
  const { l } = useLocale();
  const [chartRef, setChartRef] = useState<Highcharts.Chart | undefined>(undefined);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedPoint, setSelectedPoint] = useState<
    OpmStackedDistributionPlotDataPointDto | undefined
  >(undefined);
  const {
    colors: { colorNeutralsB80, colorGrey400, colorNeutralsB90 },
    chartPalette,
    font: { fontFamilyPrimary },
  } = useCSSVariables();

  const getYaxisLabel = () =>
    chartType === SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution
      ? l('_InstrumentProceeds')
      : chartType === SimulatedOutcomesChartTypes.EVRealWorldDistribution
      ? l('_EV')
      : l('_TotalAttributableEquity');

  const getMeanPosition = () => {
    switch (chartType) {
      case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
        return plotData[selectedYear].meanPosition.enterpriseValue;

      case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
        return plotData[selectedYear].meanPosition.equity;

      default:
        return selectedView === ViewOption.AllInstruments
          ? plotData[selectedYear].meanPosition.totalValuedInstrumentProceeds
          : plotData[selectedYear].meanPosition.instruments[selectedView];
    }
  };

  const getMeanValue = () => {
    switch (chartType) {
      case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
        return plotData[selectedYear].meanValue.enterpriseValue;

      case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
        return plotData[selectedYear].meanValue.equity;

      default:
        return selectedView === ViewOption.AllInstruments
          ? plotData[selectedYear].meanValue.totalValuedInstrumentProceeds
          : plotData[selectedYear].meanValue.instruments[selectedView];
    }
  };

  const getFirstQuartile = () => {
    switch (chartType) {
      case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
        return plotData[selectedYear].firstQuartile.enterpriseValue;

      case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
        return plotData[selectedYear].firstQuartile.equity;

      default:
        return selectedView === ViewOption.AllInstruments
          ? plotData[selectedYear].firstQuartile.totalValuedInstrumentProceeds
          : plotData[selectedYear].firstQuartile.instruments[selectedView];
    }
  };

  const getFirstQuartilePosition = () => {
    switch (chartType) {
      case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
        return plotData[selectedYear].firstQuartilePosition.enterpriseValue;

      case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
        return plotData[selectedYear].firstQuartilePosition.equity;

      default:
        return selectedView === ViewOption.AllInstruments
          ? plotData[selectedYear].firstQuartilePosition.totalValuedInstrumentProceeds
          : plotData[selectedYear].firstQuartilePosition.instruments[selectedView];
    }
  };

  const getThirdQuartile = () => {
    switch (chartType) {
      case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
        return plotData[selectedYear].thirdQuartile.enterpriseValue;

      case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
        return plotData[selectedYear].thirdQuartile.equity;

      default:
        return selectedView === ViewOption.AllInstruments
          ? plotData[selectedYear].thirdQuartile.totalValuedInstrumentProceeds
          : plotData[selectedYear].thirdQuartile.instruments[selectedView];
    }
  };

  const getThirdQuartilePosition = () => {
    switch (chartType) {
      case SimulatedOutcomesChartTypes.EVRealWorldDistribution:
        return plotData[selectedYear].thirdQuartilePosition.enterpriseValue;

      case SimulatedOutcomesChartTypes.EquityRealWorldDistribution:
        return plotData[selectedYear].thirdQuartilePosition.equity;

      default:
        return selectedView === ViewOption.AllInstruments
          ? plotData[selectedYear].thirdQuartilePosition.totalValuedInstrumentProceeds
          : plotData[selectedYear].thirdQuartilePosition.instruments[selectedView];
    }
  };

  function tooltipFormatter(this: Highcharts.TooltipFormatterContextObject) {
    const category = this.point.category;
    const data = plotData[selectedYear].dataPoints;
    const point = data.find((tt) => tt.x === category);
    let instrumentInfo = '';
    let realWorldInfo = '';
    let numColumns = 2;
    if (point?.instrumentDistributions) {
      const valuedInstruments = point.instrumentDistributions
        .filter((id) => id.instrumentIsValued)
        .sort(plotInstrumentByTypeOrDefaultSortFn);

      numColumns = valuedInstruments.length > 8 ? 3 : 2;

      instrumentInfo += `
        <div class="${styles['fs-12']} ${styles['w-125']}">
          ${l('_SponsorMoM')}
          <br />
          <span>
            <b>${formatNumber(point?.institutionMoM)}x</b>
          </span>
        </div>
        <div class="${styles['fs-12']}">
          ${l('_SponsorIRR')}
          <br />
          <span>
            <b>${toPercentageUsingFormatter(point?.institutionIRR ?? 0)}</b>
          </span>
        </div>
        ${numColumns === 2 ? '' : '<br />'}`;

      valuedInstruments.forEach((instrument) => {
        const { instrumentNarrative, distributionAmount } = instrument;
        instrumentInfo += `<div class="${styles['fs-12']} ${styles['instrument-name']} ${
          styles['w-125']
        }">${instrumentNarrative}<br/><span><b>${formatNumber(
          distributionAmount,
          0,
          true
        )}</b></span></div>`;
      });
    }

    if (chartType === SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution) {
      if (selectedView === ViewOption.AllInstruments) {
        realWorldInfo = `
          <div class="${styles['flex']}">
            <div class="${styles['w-150']}">
              ${l('_EV')}<br/>
              <span><b>${formatNumber(point?.enterpriseValue, 0, true)}</b></span>
            </div>
            <div class="${styles['w-150']}">
              ${l('_TotalAttributableEquity')}<br/>
              <span><b>${formatNumber(point?.totalEquityProceeds, 0, true)}</b></span>
            </div>
          </div>
          <br/>
          <div class="${styles['flex']}">
            <div class="${styles['w-150']}">
              ${l('_SponsorMoM')}<br/>
              <span><b>${formatNumber(point?.institutionMoM)}x</b></span>
            </div>
            <div class="${styles['w-150']}">
              ${l('_SponsorIRR')}<br/>
              <span><b>${toPercentageUsingFormatter(point?.institutionIRR ?? 0)}</b></span>
            </div>
          </div>
          </br>`;
      } else {
        const selectedInstrument = point?.instrumentDistributions.find(
          (item) => item.instrumentId === selectedView
        );

        realWorldInfo = `
            <div class="${styles['w-150']}">
              ${l('_EV')}<br/>
              <span><b>${formatNumber(point?.enterpriseValue, 0, true)}</b></span>
            </div>
            <br/>
            <div class="${styles['flex']}">
              <div class="${styles['w-150']}">
                ${l('_TotalAttributableEquity')}<br/>
                <span><b>${formatNumber(point?.totalEquityProceeds, 0, true)}</b></span>
              </div>
              <div class="${styles['w-150']}">
                ${l('_InstrumentProceeds')}<br/>
                <span><b>${formatNumber(selectedInstrument?.distributionAmount, 0, true)}</b></span>
              </div>
            </div>
            <br/>
            <div class="${styles['flex']}">
              <div class="${styles['w-150']}">
                ${l('_SponsorMoM')}<br/>
                <span><b>${formatNumber(point?.institutionMoM)}x</b></span>
              </div>
              <div class="${styles['w-150']}">
                ${l('_SponsorIRR')}<br/>
                <span><b>${toPercentageUsingFormatter(point?.institutionIRR ?? 0)}</b></span>
              </div>
            </div>
            <br/>
            <div class="${styles['flex']}">
              <div class="${styles['w-150']}">
                ${l('_InstrumentMoM')}<br/>
                <span><b>${formatNumber(selectedInstrument?.instrumentMoM)}x</b></span>
              </div>
              <div class="${styles['w-150']}">
                ${l('_InstrumentIRR')}<br/>
                <span><b>${toPercentageUsingFormatter(
                  selectedInstrument?.instrumentIRR ?? 0
                )}</b></span>
              </div>
            </div>
            <br/>`;
      }
    }

    return `
    <div style="display: flex; gap: 1rem; flex-direction: column;">
      <div class="${
        chartType !== SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution
          ? styles['columns']
          : ''
      }" style="grid-template-columns: repeat(${numColumns}, 1fr);">
      ${
        chartType === SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution
          ? realWorldInfo
          : instrumentInfo
      }
      </div>
      <div class="${styles['text-wrap']}" style="text-align: center">
        <b>${l('_ClickToEnlarge')}</b>
      </div>
      </div>
      `;
  }

  const tooltipConfig: Highcharts.TooltipOptions = {
    useHTML: true,
    formatter: tooltipFormatter,
    backgroundColor: '#fff',
    borderColor: '#fff',
    borderRadius: 8,
    shadow: false,
    padding: 20,
    shared: true,
    outside: true,
    shape: 'callout',
    positioner: function (labelWidth, labelHeight, point) {
      const chart = this.chart;
      const plotX = point.plotX + chart.plotLeft;
      const plotY = point.plotY;

      const selectedInstrumentName = plotData[
        selectedYear
      ].dataPoints[0].instrumentDistributions.find(
        (id) => id.instrumentId === selectedView
      )?.instrumentNarrative;
      const selectedInstrumentSeries =
        chart.series.find((series) => series.name === selectedInstrumentName) ?? chart.series[0];

      let tooltipX;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const barW = (selectedInstrumentSeries as any).barW;

      if (selectedInstrumentSeries.data.length <= 1) {
        if (plotX < chart.plotWidth / 2) {
          tooltipX = plotX;
        } else {
          tooltipX = Math.min(plotX - labelWidth, chart.plotLeft + chart.plotWidth - labelWidth);
        }
        return {
          x: tooltipX,
          y: plotY,
        };
      }

      if (plotX < chart.plotWidth / 2) {
        tooltipX = Math.max(plotX + barW / 2, chart.plotLeft);
      } else {
        tooltipX = Math.min(
          plotX - labelWidth - barW / 2,
          chart.plotLeft + chart.plotWidth - labelWidth
        );
      }

      tooltipX = Math.min(tooltipX, chart.plotLeft + chart.plotWidth - labelWidth);

      tooltipX = Math.max(tooltipX, chart.plotLeft);

      const tooltipY = Math.max(
        Math.min(plotY - labelHeight + 40, chart.plotTop + chart.plotHeight - labelHeight),
        chart.plotTop + 5
      );

      return {
        x: tooltipX,
        y: tooltipY,
      };
    },
  };

  const chartOptions = useMemo<Highcharts.Options>(
    (): Highcharts.Options => {
      const getSeries = (): Highcharts.SeriesColumnOptions[] => {
        if (chartType === SimulatedOutcomesChartTypes.InstrumentsRealWorldDistribution) {
          const valuedInstruments = plotData[
            selectedYear
          ].dataPoints[0].instrumentDistributions.filter((id) => id.instrumentIsValued);
          return valuedInstruments.map((instrument, index) => {
            return {
              index: valuedInstruments.length - index,
              type: 'column',
              name: instrument.instrumentNarrative ?? '',
              data:
                Object.keys(plotData).length > 0
                  ? plotData[selectedYear].dataPoints.map((pd) => [
                      pd.x,
                      pd.instrumentDistributions.find(
                        (id) => id.instrumentId === instrument.instrumentId
                      )?.distributionAmount,
                    ])
                  : [],
              pointPlacement: 'on',
              color: selectedView === ViewOption.AllInstruments ? chartPalette[index] : '#1b8786',
              showInLegend:
                selectedView === ViewOption.AllInstruments && valuedInstruments.length > 1,
              visible:
                selectedView === ViewOption.AllInstruments ||
                selectedView === instrument.instrumentId,
              states: {
                inactive: {
                  enabled: false,
                },
              },
            };
          });
        }

        const showInstrumentLegend = (instrumentType: InstrumentType) => {
          const filteredInstruments = plotData[
            selectedYear
          ].dataPoints[0].instrumentDistributions.filter((id) => {
            return instrumentType === InstrumentType.OrdinaryEquity
              ? id.instrumentType ===
                  enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) &&
                  !id.instrumentIsSweetEquity
              : id.instrumentType === enumKeyByValue(InstrumentType, instrumentType);
          });

          return (filteredInstruments && filteredInstruments.length > 0) ?? false;
        };

        const showNetDebtLegend = () => {
          return plotData[selectedYear].dataPoints[0].netDebt !== 0;
        };

        const showSweetEquityLegend = () => {
          const filteredInstruments = plotData[
            selectedYear
          ].dataPoints[0].instrumentDistributions.filter((id) => id.instrumentIsSweetEquity);
          return (filteredInstruments && filteredInstruments.length > 0) ?? false;
        };

        // EV / Equity chart
        return [
          {
            index: 4,
            type: 'column',
            name: plotData[selectedYear].dataPoints[0].netDebt > 0 ? l('_NetDebt') : l('_NetCash'),
            visible: chartType === SimulatedOutcomesChartTypes.EVRealWorldDistribution,
            showInLegend:
              chartType === SimulatedOutcomesChartTypes.EVRealWorldDistribution &&
              showNetDebtLegend(),
            data:
              Object.keys(plotData).length > 0
                ? plotData[selectedYear].dataPoints.map((pd) => [pd.x, pd.netDebt])
                : [],
            pointPlacement: 'on',
            color: '#5577ff',
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
          {
            index: 3,
            type: 'column',
            name: l('_ShareholderLoanNotes'),
            showInLegend: showInstrumentLegend(InstrumentType.ShareholderLoanNotes),
            data:
              Object.keys(plotData).length > 0
                ? plotData[selectedYear].dataPoints.map((pd) => [pd.x, pd.shareholderLoanNotes])
                : [],
            pointPlacement: 'on',
            color: '#21496d',
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
          {
            index: 2,
            type: 'column',
            name: l('_PreferenceShares'),
            showInLegend: showInstrumentLegend(InstrumentType.PreferredShares),
            data:
              Object.keys(plotData).length > 0
                ? plotData[selectedYear].dataPoints.map((pd) => [pd.x, pd.preferenceShares])
                : [],
            pointPlacement: 'on',
            color: '#d04a02',
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
          {
            index: 1,
            type: 'column',
            name: l('_OrdinaryEquityTitle'),
            showInLegend: showInstrumentLegend(InstrumentType.OrdinaryEquity),
            data:
              Object.keys(plotData).length > 0
                ? plotData[selectedYear].dataPoints.map((pd) => [pd.x, pd.ordinaryEquity])
                : [],
            pointPlacement: 'on',
            color: '#f38100',
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
          {
            index: 0,
            type: 'column',
            name: l('_SweetEquityTitle'),
            showInLegend: showSweetEquityLegend(),
            data:
              Object.keys(plotData).length > 0
                ? plotData[selectedYear].dataPoints.map((pd) => [pd.x, pd.sweetEquity])
                : [],
            pointPlacement: 'on',
            color: '#1b8786',
            states: {
              inactive: {
                enabled: false,
              },
            },
          },
        ];
      };

      return {
        chart: {
          animation: true,
          events: {
            load: function () {
              handleClashingLabels(this.xAxis);
            },
            redraw: function () {
              handleClashingLabels(this.xAxis);
            },
          },
          marginTop: 80,
          marginBottom: 100,
          marginRight: 52,
          type: 'column',
          zooming: {
            type: 'x',
          },
          reflow: true,
          style: { fontFamily: fontFamilyPrimary },
        },
        tooltip: tooltipConfig,
        legend: {
          reversed: true,
          align: 'left',
          verticalAlign: 'top',
          y: -15,
          itemStyle: {
            fontSize: '1.4rem',
          },
        },
        credits: {
          enabled: false,
        },
        title: {
          text: undefined,
        },
        xAxis: {
          tickLength: 0,
          title: {
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '500',
            },
            y: 30,
          },
          // setting the min to be half of the increment to center the first bar
          min: (plotData[selectedYear].dataPoints[1].x / 2) * -1,
          endOnTick: true,
          labels: {
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '500',
            },
            formatter: axisLabelFormatter,
            y: 30,
          },
          events: {
            setExtremes: function (e) {
              if (e.trigger === 'zoom') {
                handleManualZoom(e.min, e.max);
              }
            },
          },
          plotLines:
            Object.keys(plotData).length > 0
              ? [
                  {
                    id: 'mean',
                    label: {
                      text: l('_M'),
                      x: 0,
                      y: -30,
                      align: 'center',
                      rotation: 0,
                      style: {
                        width: 120,
                        textOverflow: 'wrap',
                      },
                      formatter: () => {
                        return `<tspan dy="0" style="color: ${colorNeutralsB80}; font-size: 14px;">${l(
                          '_M'
                        )}</tspan><br><tspan dy="2" style="color: ${colorNeutralsB90}; font-size: 14px;">${abbreviatedValueFormatter(
                          { value: getMeanValue(), fraction: 1 }
                        )}</tspan>`;
                      },
                    },
                    value: getMeanPosition(),
                    zIndex: 7,
                    width: 2,
                    color: colorGrey400,
                  },
                  {
                    id: 'firstQuartile',
                    label: {
                      x: 0,
                      y: -30,
                      align: 'center',
                      rotation: 0,
                      formatter: () => {
                        return `<tspan dy="0" style="color: ${colorNeutralsB80}; font-size: 14px;">${l(
                          '_Q1'
                        )}</tspan><br><tspan dy="2" style="color: ${colorNeutralsB90}; font-size: 14px;">${abbreviatedValueFormatter(
                          { value: getFirstQuartile(), fraction: 1 }
                        )}</tspan>`;
                      },
                    },
                    value: getFirstQuartilePosition(),
                    zIndex: 7,
                    width: 2,
                    color: colorGrey400,
                  },
                  {
                    id: 'thirdQuartile',
                    label: {
                      x: 0,
                      y: -30,
                      align: 'center',
                      rotation: 0,
                      formatter: () => {
                        return `<tspan dy="0" style="color: ${colorNeutralsB80}; font-size: 14px;">${l(
                          '_Q3'
                        )}</tspan><br><tspan dy="2" style="color: ${colorNeutralsB90}; font-size: 14px;">${abbreviatedValueFormatter(
                          { value: getThirdQuartile(), fraction: 1 }
                        )}</tspan>`;
                      },
                    },
                    value: getThirdQuartilePosition(),
                    zIndex: 7,
                    width: 2,
                    color: colorGrey400,
                  },
                ]
              : [],
        },
        yAxis: {
          title: {
            text: getYaxisLabel(),
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '500',
            },
            x: -10,
          },
          labels: {
            style: {
              color: colorNeutralsB80,
              fontSize: '1.4rem',
              fontWeight: '500',
            },
            formatter: axisLabelFormatter,
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0,
            borderWidth: 1,
            groupPadding: 0,
            shadow: false,
            stacking: 'normal',
            stickyTracking: true,
            animationLimit: Infinity,
            events: {
              click: function (event: Highcharts.SeriesClickEventObject) {
                if (event.point) {
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                  }
                  setSelectedPoint(plotData[selectedYear].dataPoints[event.point.index]);
                  setShowDetailModal(true);
                }
              },
            },
            states: {
              hover: {
                brightness: 0.3,
              },
            },
          },
        },
        series: getSeries(),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plotData, selectedYear, selectedView]
  );

  useEffect(() => {
    // handle zoom
    if (chartRef && chartRef.xAxis) {
      const zoomMin =
        zoomMode === ChartZoomMode.Zoomed
          ? zoomMinimum
          : (plotData[selectedYear].dataPoints[1].x / 2) * -1;
      const zoomMax = zoomMode === ChartZoomMode.Zoomed ? zoomMaximum : undefined;
      chartRef.xAxis[0].setExtremes(zoomMin, zoomMax);
    }
  }, [chartRef, plotData, selectedYear, zoomMode, zoomMinimum, zoomMaximum]);

  const chartRefCallback = useCallback((chartContainer: HighchartsReactRefObject) => {
    if (chartContainer != null) {
      setChartRef(chartContainer.chart);
    }
  }, []);

  return (
    <>
      {selectedYear && plotData && (
        <HighchartsReact
          key={selectedYear + selectedView}
          ref={chartRefCallback}
          highcharts={Highcharts}
          options={chartOptions}
        />
      )}
      <ExitEquityModal
        selectedPoint={selectedPoint}
        isOpen={showDetailModal}
        onClose={() => setShowDetailModal(false)}
      />
    </>
  );
};
