import { ForecastDto } from '../../models/contracts/project-dto';
import { v4 as uuidv4 } from 'uuid';

export const cloneForecast = (forecastToClone: ForecastDto): ForecastDto => {
  return {
    ...forecastToClone,
    forecastId: uuidv4(),
    inputs: { ...forecastToClone.inputs, inputId: uuidv4() },
  };
};
