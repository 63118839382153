import classNames from 'classnames';
import { FC, InputHTMLAttributes } from 'react';
import { FormControlPropsBase } from '../shared-types';
import styles from '../form-field.module.scss';
import FieldLabel from '@app/shared/components/form-controls/field-label/FieldLabel';
import { useController } from 'react-hook-form';
import FieldValidationMessage from '@app/shared/components/form-controls/fidl-validation-message/FieldValidationMessage';
import { Datepicker } from '@app/shared/components/datepicker/Datepicker';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import SvgCalendar from '@app/shared/icons/Calendar';
import { invalidDateValidator } from '@core/validations/hook-forms/validators';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';
import { format, parseISO } from 'date-fns';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';

interface FormDatePickerProps extends InputHTMLAttributes<HTMLInputElement>, FormControlPropsBase {
  name: string;
  staticPositionedCell?: boolean;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  inlineLabel?: boolean;
  isModalHeaderField?: boolean;
  formSubmitHandler?: () => Promise<void>;
}

export const PARENT_CLASSNAME = 'form-field';
const FormDatePicker: FC<FormDatePickerProps> = ({
  defaultValue,
  rightAlign,
  labelIcon,
  rules,
  name,
  label,
  inlineLabel = false,
  isModalHeaderField = false,
  staticPositionedCell,
  required,
  isGapless = false,
  ignoreReadOnly = false,
  disabled = false,
  formSubmitHandler,
  ...rest
}): JSX.Element => {
  const { l } = useLocale();
  const {
    fieldState: { error },
  } = useController({
    name,
    rules: {
      ...rules,
      validate: {
        invalidDate: invalidDateValidator().validate,
        ...rules?.validate,
      },
    },
    defaultValue,
  });

  const isReadOnly = useIsReadOnly();

  const valuationDate = useAppSelector((state) => state.project.projectDraft.valuationDate);

  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--relative`]]: staticPositionedCell,
        [styles[`${PARENT_CLASSNAME}--gapless`]]: isGapless,
        [styles[`${PARENT_CLASSNAME}--inline-label`]]: inlineLabel,
      })}>
      {label && (
        <div
          className={classNames({
            [styles[`${PARENT_CLASSNAME}__label`]]: !inlineLabel,
          })}>
          <FieldLabel
            name={name}
            required={required ?? (rules && 'required' in rules)}
            inlineLabel={inlineLabel}
            isModalHeaderField={isModalHeaderField}>
            {labelIcon}
            {label}
          </FieldLabel>
        </div>
      )}
      <div
        className={classNames(
          styles[`${PARENT_CLASSNAME}__container`],
          styles[`${PARENT_CLASSNAME}__container--has-suffix`],
          {
            [styles[`${PARENT_CLASSNAME}__container--inline`]]: inlineLabel,
          }
        )}>
        <Datepicker
          name={name}
          renderInput={({ inputRef, inputProps: datepickerInputProps, setIsCalendarOpen }) => (
            <>
              <input
                {...datepickerInputProps}
                {...rest}
                ref={inputRef}
                className={classNames(styles[`${PARENT_CLASSNAME}__control`], {
                  [styles[`${PARENT_CLASSNAME}__control--right`]]: rightAlign,
                  [styles[`${PARENT_CLASSNAME}__control--error`]]: error?.message,
                })}
                disabled={disabled || (!ignoreReadOnly && isReadOnly)}
                onBlur={() => {
                  const formattedDate = format(parseISO(valuationDate), 'dd/MM/yyyy');
                  if (formattedDate !== datepickerInputProps?.value) {
                    formSubmitHandler && formSubmitHandler();
                  }
                }}
              />
              <div className={styles[`${PARENT_CLASSNAME}__control-suffix`]}>
                <Button
                  aria-label={l('_OpenCalendar')}
                  appearance={ButtonAppearance.CLEAN}
                  size={ButtonSize.FLUID}
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => setIsCalendarOpen(true)}
                  disabled={disabled || (!ignoreReadOnly && isReadOnly)}>
                  <SvgCalendar className={styles[`${PARENT_CLASSNAME}__control-suffix-icon`]} />
                </Button>
              </div>
            </>
          )}
          formSubmitHandler={formSubmitHandler}
        />
        {error &&
          (!isModalHeaderField ? (
            <div className={classNames(styles[`${PARENT_CLASSNAME}__validation-message`])}>
              <FieldValidationMessage error={error} />
            </div>
          ) : (
            <div className={styles[`${PARENT_CLASSNAME}__validation-message--inline`]}>
              <FieldValidationMessage error={error} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default FormDatePicker;
