import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import SvgTrash from '@app/shared/icons/Trash';
import { FC, useState } from 'react';
import { DeleteProjectConfirmModal } from '@app/modules/projects/inputs/project-admin/DeleteProjectConfirmModal';

interface DeleteProjectActionProps {
  projectName?: string;
  projectId?: number;
  shouldRedirectAfter?: boolean;
  disabled?: boolean;
}

export const DeleteProjectAction: FC<DeleteProjectActionProps> = ({
  projectName,
  projectId,
  shouldRedirectAfter = true,
  disabled = false,
}): JSX.Element => {
  const [isConfirmDeleteProjectModalOpen, setIsConfirmDeleteProjectModalOpen] = useState(false);

  return (
    <>
      <>
        <Button
          disabled={disabled}
          startIcon={<SvgTrash />}
          onClick={() => setIsConfirmDeleteProjectModalOpen(true)}
          appearance={
            shouldRedirectAfter ? ButtonAppearance.DANGER_TERTIARY : ButtonAppearance.CLEAN
          }>
          Delete Project
        </Button>
      </>
      {isConfirmDeleteProjectModalOpen && (
        <DeleteProjectConfirmModal
          setIsOpen={setIsConfirmDeleteProjectModalOpen}
          isOpen={isConfirmDeleteProjectModalOpen}
          projectName={projectName}
          projectId={projectId}
          shouldRedirectAfter={shouldRedirectAfter}
        />
      )}
    </>
  );
};
