import { FC, useState } from 'react';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { FormSelect } from '@app/shared/components/form-controls/form-select/FormSelect';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';

import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';

import { Alignment } from '@app/shared/enums/alignment.enum';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { DefaultDashboardDropdownValues } from '@app/modules/projects/dashboard/widgets/default-dashboard-dropdown-values-enum';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import { MAX_COLLAPSED_ITEMS } from '@app/modules/projects/dashboard/widgets/total-equity/constants';
import { ToggleWidget } from '@app/shared/enums/expand-collapse';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import Pwerm2TotalEquityChartPwerm from './Pwerm2TotalEquityChartPwerm';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';

const Pwerm2TotalEquityPwerm: FC = (): JSX.Element => {
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.totalEquity
  );
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const sortedProjectByCaseWithNonZeroProbability = getCasesWithNonZeroProbability(
    sortedProjectByCase.pwermInput.cases
  );

  const caseOptions = [
    { value: DefaultDashboardDropdownValues.AllCases, viewValue: l('_AllCases') },
    ...sortedProjectByCaseWithNonZeroProbability.map((caseItem) => ({
      value: caseItem.caseId,
      viewValue: caseItem.narrative,
    })),
  ];
  const formMethods = useForm<{ caseId: string }>({
    ...formConfigBase,
    defaultValues: {
      caseId: DefaultDashboardDropdownValues.AllCases,
    },
  });
  const { watch } = formMethods;
  const [isExpanded, setIsExpanded] = useState(false);
  const watchCaseId = watch('caseId');
  const filteredCase = sortedProjectByCaseWithNonZeroProbability.find(
    (caseItem) => caseItem.caseId === watchCaseId
  );
  const forecastAxisData =
    watchCaseId === DefaultDashboardDropdownValues.AllCases
      ? sortedProjectByCaseWithNonZeroProbability.reduce((prev, current) =>
          prev.forecasts.length > current.forecasts.length ? prev : current
        )?.forecasts
      : sortedProjectByCaseWithNonZeroProbability.filter(
          (projectCase) => projectCase.caseId === watchCaseId
        )[0].forecasts;
  const exceedsMaxForecasts = forecastAxisData.length > MAX_COLLAPSED_ITEMS;

  const isSpecificCaseSelected = filteredCase;
  const caseResults = isSpecificCaseSelected
    ? results.cases[filteredCase!.caseId]
    : results.weighted;

  const entryTotalEquityValue = caseResults.entryTotalEquity;
  const equityProceedsWeightedValue = caseResults.exitTotalEquity;
  const exitSlnDebtProceeds = caseResults.shareholderDebtProceeds;
  const exitOrdinaryEquityProceeds = caseResults.ordinaryEquityProceeds;
  const sweetEquityPercentageOfOrdinaryEquityProceeds = caseResults.sweetOrdinaryProceedsProportion;

  return (
    <DashboardWidgetContainer
      title={l('_TotalEquityTitle') + ' v2'}
      isExpanded={isExpanded}
      actions={
        <FormProvider {...formMethods}>
          <form className="dashboard-widget-controls">
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              onClick={() => setIsExpanded(!isExpanded)}
              className="dashboard-widget-controls__button"
              ignoreReadOnly>
              {isExpanded
                ? ToggleWidget.Collapse
                : exceedsMaxForecasts
                ? l('_SeeAllYears')
                : ToggleWidget.Expand}
            </Button>
            <div className="dashboard-widget-controls__item">
              <FormSelect
                name="caseId"
                ariaLabel={l('_SelectorAriaLabel', { label: l('_ChartView') })}
                required
                options={caseOptions}
                isGapless
                ignoreReadOnly
              />
            </div>
          </form>
        </FormProvider>
      }
      secondaryContent={
        <table className="table-primary table-primary--fixed table-primary--secondary-theme">
          <thead>
            <tr>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '120px' }}>
                {l('_EntryTotalEquityValue')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '120px' }}>
                {l('_ExitTotalEquityValue')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '120px' }}>
                {l('_ExitShareholderDebtProceeds')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '140px' }}>
                {l('_ExitOrdinaryEquityProceeds')}
              </th>
              <th className="table-primary__cell--header-quinary" style={{ minWidth: '167px' }}>
                {l('_SweetEquityPercentageOfOrdinaryEquityProceeds')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <CellValue
                  {...amountRules}
                  value={entryTotalEquityValue}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...amountRules}
                  value={equityProceedsWeightedValue}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...amountRules}
                  value={exitSlnDebtProceeds}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...amountRules}
                  value={exitOrdinaryEquityProceeds}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
              <td>
                <CellValue
                  {...enteredPercentageViewRules}
                  value={getCalculatedProjectValueInPercent(
                    sweetEquityPercentageOfOrdinaryEquityProceeds
                  )}
                  className="heading-2 heading-2--alternate"
                  alignment={Alignment.Left}
                />
              </td>
            </tr>
          </tbody>
        </table>
      }>
      {(Boolean(entryTotalEquityValue) || entryTotalEquityValue === 0) &&
        (!Number.isNaN(equityProceedsWeightedValue) || equityProceedsWeightedValue === 0) &&
        (Boolean(exitSlnDebtProceeds) || exitSlnDebtProceeds === 0) &&
        (Boolean(exitOrdinaryEquityProceeds) || exitOrdinaryEquityProceeds === 0) && (
          <Pwerm2TotalEquityChartPwerm
            filteredCase={filteredCase}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            forecastData={forecastAxisData}
          />
        )}
    </DashboardWidgetContainer>
  );
};

export default Pwerm2TotalEquityPwerm;
