import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import styles from './secondary-navigation-item.module.scss';
import classNames from 'classnames';
import PwcNavLink from '@app/shared/routing/PwcNavLink';
import { SecondaryNavigationItemVariation } from '@app/shared/components/secondary-navigation/secondary-navigation-enums';

interface SecondaryNavigationItemProps
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  isActive?: boolean;
  isHighlighted?: boolean;
  route?: string;
  params?: Record<string, string>;
  additionalData?: string | number;
  variation?: SecondaryNavigationItemVariation;
}

export const SecondaryNavigationItem = ({
  children,
  isActive = false,
  isHighlighted = false,
  route,
  additionalData,
  params,
  variation,
  ...rest
}: SecondaryNavigationItemProps) => {
  const PARENT_CLASSNAME = 'navigation-item';

  const getTabClasses = (isLinkActive: boolean | undefined = undefined) => {
    return classNames(styles[PARENT_CLASSNAME], {
      [styles[`${PARENT_CLASSNAME}--active`]]: isActive || isLinkActive,
      [styles[`${PARENT_CLASSNAME}--highlighted`]]: isHighlighted,
      [styles[`${PARENT_CLASSNAME}--${variation}`]]: variation,
    });
  };

  const content = (
    <>
      <span className={styles[`${PARENT_CLASSNAME}__additional-data`]}>{additionalData}</span>{' '}
      {children}
    </>
  );

  return route ? (
    <PwcNavLink route={route} params={params} className={({ isActive }) => getTabClasses(isActive)}>
      {content}
    </PwcNavLink>
  ) : (
    <button type="button" className={getTabClasses()} {...rest}>
      {content}
    </button>
  );
};
