export const labelTopValue = (maxEquity: number) => {
  const roundingValue = 5;
  const labelValue = maxEquity.toString().split('.')[0].length;
  const eqValue = Math.pow(10, labelValue - 2);
  const eqValue2 = Math.pow(10, labelValue - 3);
  return maxEquity / (roundingValue * eqValue) < 1
    ? Math.floor(maxEquity / (roundingValue * eqValue)) * (roundingValue * eqValue) +
        roundingValue * eqValue2
    : Math.ceil(maxEquity / (roundingValue * eqValue)) * (roundingValue * eqValue);
};
