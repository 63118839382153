import { useLocale } from '@app/core/hooks/useLocale';
import BaseModal from '@app/shared/components/modal/BaseModal';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import { enumKeyByValue } from '@app/shared/helpers';
import { ReduceYearsType } from '@app/shared/models/contracts/enums/shared-enums';

interface ReduceYearsModalProps {
  onCloseButtonClick: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  type: ReduceYearsType;
}

export const ReduceYearsModal = ({
  onCloseButtonClick,
  onCancel,
  onConfirm,
  isOpen,
  type,
}: ReduceYearsModalProps) => {
  const { l } = useLocale();
  const isForecast = type === ReduceYearsType.Forecast;

  return (
    <BaseModal
      size={ModalSize.Small}
      title={
        l('_DeleteTypeYears', {
          type: isForecast
            ? enumKeyByValue(ReduceYearsType, ReduceYearsType.Forecast)
            : enumKeyByValue(ReduceYearsType, ReduceYearsType.Historic),
        }) + '?'
      }
      buttonConfirmLabel={l('_DeleteTypeYears', {
        type: isForecast
          ? enumKeyByValue(ReduceYearsType, ReduceYearsType.Forecast)
          : enumKeyByValue(ReduceYearsType, ReduceYearsType.Historic),
      })}
      buttonCancelLabel="Cancel"
      onCloseButtonClick={onCloseButtonClick}
      onCancel={onCancel}
      onConfirm={onConfirm}
      isOpen={isOpen}>
      {l('_LoweringYearsWarning', {
        capitalisedType: isForecast
          ? enumKeyByValue(ReduceYearsType, ReduceYearsType.Forecast)
          : enumKeyByValue(ReduceYearsType, ReduceYearsType.Historic),
        type: isForecast
          ? enumKeyByValue(ReduceYearsType, ReduceYearsType.Forecast).toLowerCase()
          : enumKeyByValue(ReduceYearsType, ReduceYearsType.Historic).toLowerCase(),
      })}
    </BaseModal>
  );
};
