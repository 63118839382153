import { SVGProps } from 'react';
const SvgEventUnlink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.2315 7.74751L9.49547 8.48351L12.8875 11.8435L9.27147 15.4755L5.89547 12.0675L5.17547 12.8035L8.55147 16.1955C8.7328 16.3875 8.9568 16.4888 9.22347 16.4995C9.49013 16.5102 9.7248 16.4355 9.92747 16.2755L13.6235 12.5635C13.8155 12.3822 13.9168 12.1635 13.9275 11.9075C13.9381 11.6408 13.8635 11.4062 13.7035 11.2035L10.2315 7.74751ZM7.89547 9.71551L7.15947 10.4515L9.27147 12.5635L9.99147 11.8435L7.89547 9.71551ZM6.00747 0.803512L2.37547 4.43551C2.1728 4.63818 2.07147 4.87818 2.07147 5.15551C2.07147 5.43284 2.1728 5.67284 2.37547 5.87551L5.78347 9.33151L6.51947 8.61151L3.09547 5.17151L6.72747 1.52351L10.1835 4.93151L10.9195 4.19551L7.46347 0.803512C7.2608 0.600845 7.0208 0.499512 6.74347 0.499512C6.45547 0.499512 6.21013 0.600845 6.00747 0.803512ZM6.72747 4.43551L6.00747 5.15551L8.13547 7.29951L8.85547 6.57951L6.72747 4.43551Z"
      fill="#D04A02"
    />
  </svg>
);
export default SvgEventUnlink;
