import CalendarSvg from '@app/shared/icons/legacy/CalendarSvg';
import palette from '@styles/theme/palette/palette';
import shape from '@styles/theme/shape';

const legacyDatePickerComponent = {
  MuiPickersDay: {
    styleOverrides: {
      root: {
        ':hover, :focus': {
          backgroundColor: palette.transparent.primaryTransparent20,
        },
      },
    },
  },
  MuiDatePicker: {
    defaultProps: {
      OpenPickerButtonProps: {
        disableRipple: true,
        sx: {
          color: palette.text?.text1,
          borderRadius: shape.borderRadius4,
          marginRight: '-2px',
          padding: 0,
          ':focus, :hover': {
            svg: {
              color: palette.text?.text2,
            },
          },
        },
      },
      components: { OpenPickerIcon: CalendarSvg },
    },
  },
};

export default legacyDatePickerComponent;
