import {
  getCaseById,
  getForecastById,
  getMultipleProbability,
} from '@core/store/project-slice-selectors';
import { useAppSelector } from '@core/hooks/redux-hooks';

export function useShouldHighlight() {
  const project = useAppSelector((state) => state.project.projectDraft);

  return function (caseId: string, forecastId?: string, multipleValue?: number) {
    if (multipleValue !== undefined && forecastId !== undefined) {
      return (
        (getMultipleProbability(project, caseId, forecastId, multipleValue)?.probability ?? 0) >
          0 &&
        (getForecastById(project, caseId, forecastId)?.probability ?? 0) > 0 &&
        (getCaseById(project, caseId)?.probability ?? 0) > 0
      );
    }

    if (forecastId !== undefined) {
      return (
        (getForecastById(project, caseId, forecastId)?.probability ?? 0) > 0 &&
        (getCaseById(project, caseId)?.probability ?? 0) > 0
      );
    }

    return (getCaseById(project, caseId)?.probability ?? 0) > 0;
  };
}
