import { ProjectDto } from '@app/shared/models/contracts/project-dto';
import { calculateYearFrac360 } from '../utils/year-frac-360';

export interface OpmTransactionCostsCalculatedValues {
  probabilityofSecondaryTradesale: number;
  impliedEquityRetainedOnIPO: number;
  probabilityWeightedDLOMforPostIPOSaleRestrictions: number;
  ipoDiscountPercentOfEV: number;
  transactionCost: number;
}

export const getOperationalFreeCashFlowYield = (project: ProjectDto, forecastDate: string) => {
  const doesForecastDateExist = project.opmInput.perYearInputs.some(
    (yi) => yi.forecastDate === forecastDate
  );

  if (!doesForecastDateExist) {
    return 0;
  }

  const individualTimeToExit = calculateYearFrac360(
    new Date(project.valuationDate),
    new Date(forecastDate)
  );

  if (typeof individualTimeToExit !== 'number') {
    return 0;
  }

  const startingEv = project.opmInput.evValue ?? 0;
  const currentEv =
    project.opmInput.perYearInputs.find((yi) => yi.forecastDate === forecastDate)?.operationalEv ??
    0;

  const averageEv = (Number(startingEv) + Number(currentEv)) / 2;

  const totalOperationalFreeCashFlow = project.opmInput.perYearInputs.reduce((total, yi) => {
    if (yi.forecastDate && yi.forecastDate.localeCompare(forecastDate) <= 0) {
      return total + (yi.operationalFreeCashFlow ?? 0);
    }
    return total;
  }, 0);

  const averageOperationalFreeCashFlow = totalOperationalFreeCashFlow / individualTimeToExit;

  const operationalFreeCashFlowYield =
    averageEv === 0
      ? 0
      : Number(((averageOperationalFreeCashFlow / averageEv) * 100).toFixed(8)) ?? 0;

  return operationalFreeCashFlowYield;
};

export const getTotalNetDebtAtForecast = (project: ProjectDto, forecastDate: string) => {
  const perYearInputs = project.opmInput.perYearInputs.find(
    (yi) => yi.forecastDate === forecastDate
  );
  const cashValue = perYearInputs?.cashValue ?? 0;
  const netDebtItems = perYearInputs?.netDebtItems ?? [];
  const netDebtItemsValue = netDebtItems.reduce((totalValue, netDebtItem) => {
    return (totalValue += netDebtItem.value ?? 0);
  }, 0);
  return cashValue + netDebtItemsValue;
};

export const getTotalNetDebtValues = (project: ProjectDto, forecastDates: Nullable<string>[]) =>
  forecastDates.map((forecastDate) =>
    forecastDate ? getTotalNetDebtAtForecast(project, forecastDate) : 0
  );

export const getInitialTotalNetDebt = (project: ProjectDto) => {
  const opmInput = project.opmInput;
  const cashValue = opmInput.initialCashValue ?? 0;
  const netDebtItems = opmInput.netDebtItems ?? [];
  const netDebtItemsValue = netDebtItems.reduce((totalValue, netDebtItem) => {
    return (totalValue += netDebtItem.historicalValue ?? 0);
  }, 0);
  return cashValue + netDebtItemsValue;
};

export const getOpmTransactionCostsCalculatedValues = (
  project: ProjectDto,
  forecastDates: Nullable<string>[]
): OpmTransactionCostsCalculatedValues[] =>
  forecastDates.map((forecastDate) => {
    return {
      probabilityofSecondaryTradesale: forecastDate
        ? getProbabilityofSecondaryTradesale(project, forecastDate)
        : 0,
      impliedEquityRetainedOnIPO: forecastDate
        ? getImpliedEquityRetainedOnIPO(project, forecastDate)
        : 0,
      probabilityWeightedDLOMforPostIPOSaleRestrictions: forecastDate
        ? getProbabilityWeightedDLOMforPostIPOSaleRestrictions(project, forecastDate)
        : 0,
      ipoDiscountPercentOfEV: forecastDate ? getIPOdiscountPercentOfEV(project, forecastDate) : 0,
      transactionCost: forecastDate ? getTransactionCost(project, forecastDate) : 0,
    };
  });

const getProbabilityofSecondaryTradesale = (project: ProjectDto, forecastDate: string): number => {
  const perYearInputs = project.opmInput.perYearInputs;
  const ipoProbability =
    perYearInputs.find((f) => f.forecastDate === forecastDate)?.ipoProbability ?? 0;

  return 100 - ipoProbability;
};

const getImpliedEquityRetainedOnIPO = (project: ProjectDto, forecastDate: string): number => {
  const perYearInputs = project.opmInput.perYearInputs;
  const equitySoldInIpo =
    perYearInputs.find((f) => f.forecastDate === forecastDate)?.equitySoldInIpo ?? 0;

  return 100 - equitySoldInIpo;
};

const getIPOdiscountPercentOfEV = (project: ProjectDto, forecastDate: string): number => {
  const perYearInputs = project.opmInput.perYearInputs;

  const yearInputs = perYearInputs.find((f) => f.forecastDate === forecastDate);

  const ipoProbability = (yearInputs?.ipoProbability ?? 0) / 100;

  const ipoDiscount = (yearInputs?.ipoDiscount ?? 0) / 100;

  const hypotheticalDebtWhenIpo = (yearInputs?.hypotheticalDebtWhenIpo ?? 0) / 100;

  const equitySoldInIpo = (yearInputs?.equitySoldInIpo ?? 0) / 100;

  return ipoProbability * ipoDiscount * (1 - hypotheticalDebtWhenIpo) * equitySoldInIpo * 100;
};

const getTransactionCost = (project: ProjectDto, forecastDate: string): number => {
  const perYearInputs = project.opmInput.perYearInputs;

  const yearInputs = perYearInputs.find((f) => f.forecastDate === forecastDate);
  const ipoProbability = (yearInputs?.ipoProbability ?? 0) / 100;

  const ipoTransactionCost = (yearInputs?.ipoCostsOfEv ?? 0) / 100;

  const saleTradeTransactionCost = (yearInputs?.secondarySaleCostsOfEv ?? 0) / 100;

  return (
    (ipoProbability * ipoTransactionCost + (1 - ipoProbability) * saleTradeTransactionCost) * 100
  );
};

const getProbabilityWeightedDLOMforPostIPOSaleRestrictions = (
  project: ProjectDto,
  forecastDate: string
): number => {
  const perYearInputs = project.opmInput.perYearInputs;

  const yearInputs = perYearInputs.find((f) => f.forecastDate === forecastDate);

  const ipoProbability = (yearInputs?.ipoProbability ?? 0) / 100;

  const DLOMforpostIPOsalerestrictions = (yearInputs?.postIpoSaleDlom ?? 0) / 100;

  const equitySoldInIpo = (yearInputs?.equitySoldInIpo ?? 0) / 100;

  return ipoProbability * DLOMforpostIPOsalerestrictions * (1 - equitySoldInIpo) * 100;
};
