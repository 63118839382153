import { FC, useEffect } from 'react';
import { useAppDispatch } from '@app/core/hooks/redux-hooks';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import * as projectListActions from '@app/core/store/project-list-slice';
import {
  MapNewProjectAdminToProjectDto,
  ProjectFormModel,
} from '@app/modules/projects/inputs/project-admin/project-form/project-form-types';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { generatePath } from 'react-router-dom';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import { ModalSize } from '@app/shared/components/modal/base-modal-enums';
import BaseModal from '@app/shared/components/modal/BaseModal';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import NewProjectFormElements from '../NewProjectFormElements';

interface NewProjectFormModalProps {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}

const PARENT_CLASSNAME = 'form';
const NewProjectFormModal: FC<NewProjectFormModalProps> = ({ setIsOpen, isOpen }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { navigate } = usePwcNavigate();

  const formMethods = useForm<ProjectFormModel>({
    ...formConfigBase,
    defaultValues: {
      projectName: '',
      valuationDate: new Date().toISODateString(),
      yearEndDate: null,
    },
  });

  const formSubmitHandler: SubmitHandler<ProjectFormModel> = async () => {
    const data = formMethods.getValues();

    data.investmentDate = data.valuationDate;
    const projectId = await dispatch(
      projectListActions.saveNewProject(MapNewProjectAdminToProjectDto(data))
    ).unwrap();

    navigate(
      generatePath('../projects/:projectId/project-details', { projectId: projectId.toString() })
    );
  };

  useEffect(() => {
    formMethods.reset();
  }, [formMethods, isOpen]);

  return (
    <BaseModal
      size={ModalSize.Small}
      title="New Project"
      isOpen={isOpen}
      onCloseButtonClick={() => setIsOpen(false)}>
      <FormProvider {...formMethods}>
        <form
          data-testid="new-project-form"
          onSubmit={formMethods.handleSubmit(formSubmitHandler)}
          onBlur={() => formMethods.trigger()}>
          <NewProjectFormElements />
          <div className={`${PARENT_CLASSNAME}__actions`}>
            <Button
              appearance={ButtonAppearance.DEFAULT_SECONDARY}
              data-testid="cancel-button"
              onClick={(event) => {
                event.stopPropagation();
                setIsOpen(false);
              }}>
              Cancel
            </Button>
            <Button
              type="submit"
              data-testid="submit-button"
              appearance={ButtonAppearance.DEFAULT_PRIMARY}>
              Create
            </Button>
          </div>
        </form>
      </FormProvider>
    </BaseModal>
  );
};

export default NewProjectFormModal;
