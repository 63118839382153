import { forwardRef, Ref, useEffect } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { usePwcNavigate } from '@core/hooks/routing-hooks';

export type PwcNavLinkProps = Omit<NavLinkProps, 'to'> & {
  route: string;
  disabled?: boolean;
  params?: Record<string, string>;
};

const PwcNavLink = (
  { route, disabled, params, children, ...rest }: PwcNavLinkProps,
  ref?: Ref<HTMLAnchorElement>
) => {
  const { generatePath } = usePwcNavigate();

  const handleClick = (e: any) => {
    if (disabled) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const disabledNavLinks = document.getElementsByClassName('disabled');
    for (const element of disabledNavLinks) {
      element.removeAttribute('href');
    }
  }, [disabled]);

  return (
    <NavLink ref={ref} {...rest} to={generatePath(route, params)} onClick={handleClick}>
      {children}
    </NavLink>
  );
};

export default forwardRef(PwcNavLink);
