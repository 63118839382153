import Button from '@app/shared/components/button/Button';
import Plus from '@app/shared/icons/Plus';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { useState } from 'react';
import NewEventFormModal from './NewEventFormModal';
import { EventKey } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';

export const AddEvent = () => {
  const { l } = useLocale();
  const project = useAppSelector((state) => state.project.projectDraft);
  const buildStructures = useAppSelector((state) => state.capitalStructure.values.buildStructures);
  const maxEventsCount = useAppSelector((state) => state.uiValues.maxRefinancingEvents) ?? 5;
  const capitalStructureKey = project.pwermInput.cases[0].capitalStructureId;
  const projectWithInstruments =
    buildStructures &&
    buildStructures[capitalStructureKey]?.eventSets[EventKey.EmptyEventSet]?.events[0]?.ranks
      .length > 0;

  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);

  return (
    <Button
      appearance={ButtonAppearance.DEFAULT_PRIMARY}
      startIcon={<Plus />}
      onClick={() => setIsCreateEventModalOpen(true)}
      disabled={
        Object.values(project.capitalStructures[capitalStructureKey].events).length ===
          maxEventsCount || !projectWithInstruments
      }>
      {l('_AddEvent')}
      <NewEventFormModal setIsOpen={setIsCreateEventModalOpen} isOpen={isCreateEventModalOpen} />
    </Button>
  );
};
