import { SecondaryNavigation } from '@app/shared/components/secondary-navigation/SecondaryNavigation';
import { NavigationItem } from '@app/shared/components/secondary-navigation/secondary-navigation-types';
import { RouteConstants } from '../../RouteConstants';
import { useLocale } from '@app/core/hooks/useLocale';

export const ValuationInputsNavigation = () => {
  const { l } = useLocale();
  const navigationItems: NavigationItem[] = [
    {
      label: l('_CapmAssumptions'),
      route: `../${RouteConstants.CapmAssumptions}`,
    },
    {
      label: l('_MinorityDiscounts'),
      route: `../${RouteConstants.MinorityDiscounts}`,
    },
  ];

  return <SecondaryNavigation navigationItems={navigationItems} />;
};
