import { useState, useEffect } from 'react';

const useCollapsableSidebarState = (key: string) => {
  const localStorageKey = key;

  const storedValue = localStorage.getItem(localStorageKey);

  if (storedValue === null) {
    localStorage.setItem(localStorageKey, 'false');
  }

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const storedValue = localStorage.getItem(localStorageKey);
    return storedValue !== null ? JSON.parse(storedValue) : false;
  });

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(isCollapsed));
  }, [isCollapsed, localStorageKey]);

  return [isCollapsed, toggleSidebar] as const;
};

export default useCollapsableSidebarState;
