import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { enumKeyByValue } from '@app/shared/helpers';
import { getCalculatedProjectValueInPercent } from '@app/core/store/pwerm-calculation-slice-selectors';
import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import useToggler from '@core/hooks/useToggler';
import { WaterfallTableExpandableRow } from '@app/modules/projects/outputs/pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { WaterfallPartialTableProps } from '../../pwerm-summary-waterfall/waterfall-table/waterfall-shared';
import TableSectionValuesPerCase from '../../components/TableSectionValuesPerCase';
import TableSectionValuesPerForecast from '../../components/TableSectionValuesPerForecast';
import { useLocale } from '@app/core/hooks/useLocale';
import {
  getWaterfallCaseValue,
  getWaterfallCaseYearValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';

export const Pwerm2WaterfallCrossCheckPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();

  const [isTotalEquityDCFCrossCheckOpen, setIsTotalEquityDCFCrossCheckOpen] = useToggler();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_TotalEquityDCFCrossCheck')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.TotalEquityDCFCrossCheck)}
          isOpen={isTotalEquityDCFCrossCheckOpen}
          setIsOpen={setIsTotalEquityDCFCrossCheckOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isTotalEquityDCFCrossCheckOpen,
        })}>
        <TableSectionValuesPerForecast
          title={l('_WeightedTotalEquity')}
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.weightedTotalEquityCrosscheck
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_CostOfTotalEquity')}
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={getCalculatedProjectValueInPercent(
                Number(
                  getWaterfallCaseYearValue(
                    results,
                    caseItem.caseId,
                    forecast.forecastId,
                    (year) => year.cumulativeCostOfEquityPc
                  )
                )
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_DiscountFactor')}
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...numberCalculatedViewRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.cumulativeDiscountFactor
              )}
            />
          )}
        />
        <TableSectionValuesPerForecast
          title={l('_PVOfTotalEquity')}
          highlight
          renderSpacer={false}
          renderCell={({ caseItem, forecast }) => (
            <CellValue
              {...amountRules}
              value={getWaterfallCaseYearValue(
                results,
                caseItem.caseId,
                forecast.forecastId,
                (year) => year.weightedTotalEquityFairValue
              )}
            />
          )}
        />
        <TableSectionValuesPerCase
          title={l('_CaseMVOfTotalEquity')}
          renderSpacer={false}
          renderCell={({ caseItem }) => {
            return (
              <CellValue
                {...amountRules}
                alignment={Alignment.Center}
                value={getWaterfallCaseValue(
                  results,
                  caseItem.caseId,
                  (caseItem) => caseItem.weightedTotalEquityFairValue
                )}
              />
            );
          }}
          additionalTitleClassNames="table-primary__cell--strong"
          additionalCellClassNames="table-primary__cell--strong"
        />
        <tr>
          <th className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            {l('_MVOfTotalEquityAllCases')}
          </th>
          <td className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator" />
          <td
            colSpan={columnsCount - 2}
            className="table-primary__cell--vertical-separator table-primary__cell--section-start-separator table-primary__cell--strong">
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={results.waterfall.weightedTotalEquityFairValue}
            />
          </td>
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">{l('_TotalEquityInvestment')}</th>
          <td className="table-primary__cell--vertical-separator" />
          <td colSpan={columnsCount - 2} className="table-primary__cell--vertical-separator">
            <CellValue
              {...amountRules}
              alignment={Alignment.Center}
              value={results.waterfall.totalInvestment}
            />
          </td>
        </tr>
        <tr>
          <th className="table-primary__cell--vertical-separator">
            {l('_PcVarianceToCurrentMarketValue')}
          </th>
          <td className="table-primary__cell--vertical-separator" />
          <td colSpan={columnsCount - 2} className="table-primary__cell--vertical-separator">
            <CellValue
              {...enteredPercentageViewRules}
              alignment={Alignment.Center}
              value={getCalculatedProjectValueInPercent(
                results.waterfall.totalEquityFairValueVariance
              )}
            />
          </td>
        </tr>
        <TableSpacerRow />
        <TableSpacerRow />
      </tbody>
    </>
  );
};
