import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

const EmptyStateNoListSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon fill="none" stroke="transparent" viewBox="0 0 167 151" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.335 136C118.044 145.409 102.675 151 86 151C45.6832 151 13 118.317 13 78C13 67.3073 15.299 57.1515 19.4296 48H4.5C2.01471 48 0 45.9853 0 43.5C0 41.0147 2.01471 39 4.5 39H24.2799H35.5H42.5C44.9853 39 47 36.9853 47 34.5C47 32.0147 44.9853 30 42.5 30H30.9987H17.5C15.0147 30 13 27.9853 13 25.5C13 23.0147 15.0147 21 17.5 21H40.3887C52.8842 10.9882 68.7428 5 86 5C126.317 5 159 37.6832 159 78C159 89.0838 156.53 99.5906 152.11 109H162.5C164.985 109 167 111.015 167 113.5C167 115.985 164.985 118 162.5 118H147.075H136.5C134.015 118 132 120.015 132 122.5C132 124.985 134.015 127 136.5 127H140.112H147.5C149.985 127 152 129.015 152 131.5C152 133.985 149.985 136 147.5 136H130.335Z"
        fill="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4993 0C41.3277 0 41.9993 0.67563 41.9993 1.50906V3.49094C41.9993 4.32437 41.3277 5 40.4993 5C39.6708 5 38.9993 4.32437 38.9993 3.49094V1.50906C38.9993 0.67563 39.6708 0 40.4993 0Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9993 6.5C33.9993 5.67157 34.6749 5 35.5083 5H37.4902C38.3236 5 38.9993 5.67157 38.9993 6.5C38.9993 7.32843 38.3236 8 37.4902 8H35.5083C34.6749 8 33.9993 7.32843 33.9993 6.5Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.4993 8C41.3277 8 41.9993 8.67563 41.9993 9.50906V11.4909C41.9993 12.3244 41.3277 13 40.4993 13C39.6708 13 38.9993 12.3244 38.9993 11.4909V9.50906C38.9993 8.67563 39.6708 8 40.4993 8Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.9993 6.5C41.9993 5.67157 42.6749 5 43.5083 5H45.4902C46.3236 5 46.9993 5.67157 46.9993 6.5C46.9993 7.32843 46.3236 8 45.4902 8H43.5083C42.6749 8 41.9993 7.32843 41.9993 6.5Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3565 134.179C27.3565 135.383 26.3811 136.358 25.1782 136.358C23.9748 136.358 23 135.383 23 134.179C23 132.976 23.9748 132 25.1782 132C26.3811 132 27.3565 132.976 27.3565 134.179"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M144.483 20.9653C144.197 20.9653 143.965 21.197 143.965 21.4827C143.965 21.7685 144.197 22 144.483 22C144.769 22 145 21.7685 145 21.4827C145 21.197 144.768 20.9653 144.483 20.9653ZM141 21.4827C141 19.5593 142.559 18 144.483 18C146.406 18 147.965 19.5593 147.965 21.4827C147.965 23.4067 146.406 24.9653 144.483 24.9653C142.56 24.9653 141 23.4067 141 21.4827Z"
        fill="#757575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.3888 146.836C35.0987 147.327 33.6549 146.679 33.1641 145.389C32.6732 144.099 33.3208 142.654 34.6115 142.164C35.9011 141.673 37.3449 142.321 37.8357 143.611C38.3271 144.901 37.6789 146.345 36.3888 146.836Z"
        stroke="#757575"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.5 136V136C158.025 136 156 133.975 156 131.5C156 129.025 158.025 127 160.5 127C162.975 127 165 129.025 165 131.5C165 133.975 162.975 136 160.5 136"
        fill="#DDDDDD"
      />
      <g filter="url(#filter0_d_5216_13080)">
        <path
          d="M30 50C30 47.7909 31.7909 46 34 46H134C136.209 46 138 47.7909 138 50V61C138 63.2091 136.209 65 134 65H34C31.7909 65 30 63.2091 30 61V50Z"
          fill="white"
        />
      </g>
      <path
        d="M61.6875 51H50.3125C49.5876 51 49 51.6716 49 52.5C49 53.3284 49.5876 54 50.3125 54H61.6875C62.4124 54 63 53.3284 63 52.5C63 51.6716 62.4124 51 61.6875 51Z"
        fill="#252525"
      />
      <path
        d="M69.68 57H50.32C49.591 57 49 57.6716 49 58.5C49 59.3284 49.591 60 50.32 60H69.68C70.409 60 71 59.3284 71 58.5C71 57.6716 70.409 57 69.68 57Z"
        fill="#252525"
      />
      <rect x="34" y="50" width="11" height="11" rx="2" fill="#252525" />
      <path
        d="M38.7358 57.803H40.1987V59H38.7358V57.803ZM37 54.3365C37 53.9854 37.0582 53.6662 37.1747 53.3789C37.2984 53.0917 37.4731 52.8459 37.6987 52.6416C37.9316 52.4373 38.2082 52.2809 38.5284 52.1724C38.8559 52.0575 39.2198 52 39.6201 52C39.9549 52 40.2642 52.0447 40.548 52.1341C40.8392 52.2171 41.0902 52.3415 41.3013 52.5075C41.5197 52.6671 41.6907 52.8682 41.8144 53.1108C41.9381 53.3534 42 53.6279 42 53.9343C42 54.1578 41.9709 54.3525 41.9127 54.5185C41.8617 54.6781 41.7889 54.8185 41.6943 54.9398C41.607 55.0547 41.5051 55.1601 41.3886 55.2558C41.2722 55.3452 41.1557 55.4346 41.0393 55.5239C40.901 55.6261 40.7737 55.725 40.6572 55.8208C40.5408 55.9166 40.4389 56.0251 40.3515 56.1464C40.2642 56.2613 40.1951 56.3985 40.1441 56.5581C40.1004 56.7177 40.0786 56.9124 40.0786 57.1423H38.8996C38.8996 56.8614 38.9141 56.6252 38.9432 56.4337C38.9796 56.2357 39.0342 56.0634 39.107 55.9166C39.1798 55.7697 39.2671 55.642 39.369 55.5335C39.4782 55.4186 39.6055 55.3069 39.7511 55.1984C39.8675 55.109 39.9767 55.026 40.0786 54.9494C40.1878 54.8728 40.2824 54.7898 40.3624 54.7004C40.4498 54.6047 40.5153 54.4993 40.559 54.3844C40.6099 54.2695 40.6354 54.1322 40.6354 53.9726C40.6354 53.7811 40.5953 53.6183 40.5153 53.4843C40.4425 53.3438 40.3515 53.2321 40.2424 53.1491C40.1332 53.0661 40.0167 53.0055 39.893 52.9672C39.7693 52.9289 39.6601 52.9097 39.5655 52.9097C39.1143 52.9097 38.7795 53.0406 38.5611 53.3023C38.3501 53.5577 38.2445 53.9024 38.2445 54.3365H37Z"
        fill="white"
      />
      <g filter="url(#filter1_d_5216_13080)">
        <path
          d="M30 73C30 70.7909 31.7909 69 34 69H134C136.209 69 138 70.7909 138 73V84C138 86.2091 136.209 88 134 88H34C31.7909 88 30 86.2091 30 84V73Z"
          fill="white"
        />
      </g>
      <path
        d="M61.6875 74H50.3125C49.5876 74 49 74.6716 49 75.5C49 76.3284 49.5876 77 50.3125 77H61.6875C62.4124 77 63 76.3284 63 75.5C63 74.6716 62.4124 74 61.6875 74Z"
        fill="#252525"
      />
      <path
        d="M69.68 80H50.32C49.591 80 49 80.6716 49 81.5C49 82.3284 49.591 83 50.32 83H69.68C70.409 83 71 82.3284 71 81.5C71 80.6716 70.409 80 69.68 80Z"
        fill="#252525"
      />
      <rect x="34" y="73" width="11" height="11" rx="2" fill="#252525" />
      <path
        d="M38.7358 80.803H40.1987V82H38.7358V80.803ZM37 77.3365C37 76.9854 37.0582 76.6662 37.1747 76.3789C37.2984 76.0917 37.4731 75.8459 37.6987 75.6416C37.9316 75.4373 38.2082 75.2809 38.5284 75.1724C38.8559 75.0575 39.2198 75 39.6201 75C39.9549 75 40.2642 75.0447 40.548 75.1341C40.8392 75.2171 41.0902 75.3415 41.3013 75.5075C41.5197 75.6671 41.6907 75.8682 41.8144 76.1108C41.9381 76.3534 42 76.6279 42 76.9343C42 77.1578 41.9709 77.3525 41.9127 77.5185C41.8617 77.6781 41.7889 77.8185 41.6943 77.9398C41.607 78.0547 41.5051 78.1601 41.3886 78.2558C41.2722 78.3452 41.1557 78.4346 41.0393 78.5239C40.901 78.6261 40.7737 78.725 40.6572 78.8208C40.5408 78.9166 40.4389 79.0251 40.3515 79.1464C40.2642 79.2613 40.1951 79.3985 40.1441 79.5581C40.1004 79.7177 40.0786 79.9124 40.0786 80.1423H38.8996C38.8996 79.8614 38.9141 79.6252 38.9432 79.4337C38.9796 79.2357 39.0342 79.0634 39.107 78.9166C39.1798 78.7697 39.2671 78.642 39.369 78.5335C39.4782 78.4186 39.6055 78.3069 39.7511 78.1984C39.8675 78.109 39.9767 78.026 40.0786 77.9494C40.1878 77.8728 40.2824 77.7898 40.3624 77.7004C40.4498 77.6047 40.5153 77.4993 40.559 77.3844C40.6099 77.2695 40.6354 77.1322 40.6354 76.9726C40.6354 76.7811 40.5953 76.6183 40.5153 76.4843C40.4425 76.3438 40.3515 76.2321 40.2424 76.1491C40.1332 76.0661 40.0167 76.0055 39.893 75.9672C39.7693 75.9289 39.6601 75.9097 39.5655 75.9097C39.1143 75.9097 38.7795 76.0406 38.5611 76.3023C38.3501 76.5577 38.2445 76.9024 38.2445 77.3365H37Z"
        fill="white"
      />
      <g filter="url(#filter2_d_5216_13080)">
        <path
          d="M30 96C30 93.7909 31.7909 92 34 92H134C136.209 92 138 93.7909 138 96V107C138 109.209 136.209 111 134 111H34C31.7909 111 30 109.209 30 107V96Z"
          fill="white"
        />
      </g>
      <path
        d="M61.6875 97H50.3125C49.5876 97 49 97.6716 49 98.5C49 99.3284 49.5876 100 50.3125 100H61.6875C62.4124 100 63 99.3284 63 98.5C63 97.6716 62.4124 97 61.6875 97Z"
        fill="#252525"
      />
      <path
        d="M69.68 103H50.32C49.591 103 49 103.672 49 104.5C49 105.328 49.591 106 50.32 106H69.68C70.409 106 71 105.328 71 104.5C71 103.672 70.409 103 69.68 103Z"
        fill="#252525"
      />
      <rect x="34" y="96" width="11" height="11" rx="2" fill="#252525" />
      <path
        d="M38.7358 103.803H40.1987V105H38.7358V103.803ZM37 100.337C37 99.9854 37.0582 99.6662 37.1747 99.3789C37.2984 99.0917 37.4731 98.8459 37.6987 98.6416C37.9316 98.4373 38.2082 98.2809 38.5284 98.1724C38.8559 98.0575 39.2198 98 39.6201 98C39.9549 98 40.2642 98.0447 40.548 98.1341C40.8392 98.2171 41.0902 98.3415 41.3013 98.5075C41.5197 98.6671 41.6907 98.8682 41.8144 99.1108C41.9381 99.3534 42 99.6279 42 99.9343C42 100.158 41.9709 100.352 41.9127 100.518C41.8617 100.678 41.7889 100.819 41.6943 100.94C41.607 101.055 41.5051 101.16 41.3886 101.256C41.2722 101.345 41.1557 101.435 41.0393 101.524C40.901 101.626 40.7737 101.725 40.6572 101.821C40.5408 101.917 40.4389 102.025 40.3515 102.146C40.2642 102.261 40.1951 102.399 40.1441 102.558C40.1004 102.718 40.0786 102.912 40.0786 103.142H38.8996C38.8996 102.861 38.9141 102.625 38.9432 102.434C38.9796 102.236 39.0342 102.063 39.107 101.917C39.1798 101.77 39.2671 101.642 39.369 101.534C39.4782 101.419 39.6055 101.307 39.7511 101.198C39.8675 101.109 39.9767 101.026 40.0786 100.949C40.1878 100.873 40.2824 100.79 40.3624 100.7C40.4498 100.605 40.5153 100.499 40.559 100.384C40.6099 100.269 40.6354 100.132 40.6354 99.9726C40.6354 99.7811 40.5953 99.6183 40.5153 99.4843C40.4425 99.3438 40.3515 99.2321 40.2424 99.1491C40.1332 99.0661 40.0167 99.0055 39.893 98.9672C39.7693 98.9289 39.6601 98.9097 39.5655 98.9097C39.1143 98.9097 38.7795 99.0406 38.5611 99.3023C38.3501 99.5577 38.2445 99.9024 38.2445 100.337H37Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_5216_13080"
          x="21.8786"
          y="39.909"
          width="124.243"
          height="35.2428"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.03035" />
          <feGaussianBlur stdDeviation="4.0607" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5216_13080" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5216_13080"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5216_13080"
          x="21.8786"
          y="62.909"
          width="124.243"
          height="35.2428"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.03035" />
          <feGaussianBlur stdDeviation="4.0607" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5216_13080" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5216_13080"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_5216_13080"
          x="21.8786"
          y="85.909"
          width="124.243"
          height="35.2428"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.03035" />
          <feGaussianBlur stdDeviation="4.0607" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5216_13080" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5216_13080"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};

export default memo(EmptyStateNoListSvg);
