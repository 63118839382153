import classNames from 'classnames';
import Button from '../button/Button';
import { ButtonAppearance } from '../button/button-enums';
import styles from './legend.module.scss';
import { FC } from 'react';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import { useLocale } from '@app/core/hooks/useLocale';

interface LegendProps {
  legendText: string;
  className?: string;
}

export enum LegendType {
  ShareholderLoans = 'sln',
  PreferenceShares = 'pref',
  OrdinaryEquity = 'ord',
  SweetEquity = 'sweet',
}

export const Legend: FC<LegendProps> = ({ legendText, className }): JSX.Element | null => {
  const { l } = useLocale();
  const InstrumentTypeToLegendType: { [key: string]: LegendType } = {
    [InstrumentType.ShareholderLoanNotes]: LegendType.ShareholderLoans,
    [InstrumentType.PreferredShares]: LegendType.PreferenceShares,
    [InstrumentType.OrdinaryEquity]: LegendType.OrdinaryEquity,
    [l('_SweetEquityTitle')]: LegendType.SweetEquity,
  };
  const instrumentType = InstrumentTypeToLegendType[legendText];
  return (
    <>
      <Button
        className={classNames(styles['button'], className)}
        appearance={ButtonAppearance.CLEAN}>
        <span
          className={classNames(styles['icon'], {
            [styles[`icon__${instrumentType}`]]: instrumentType,
          })}
        />
        <span
          className={classNames(styles['text'], {
            [styles[`text__${instrumentType}`]]: instrumentType,
          })}>
          {legendText}
        </span>
      </Button>
    </>
  );
};
