export enum ChartTitles {
  SimulatedDistributionRiskNeutral = '_SimulatedDistributionRiskNeutral',
  SimulatedDistributionRealWorld = '_SimulatedDistributionRealWorld',
  SimulatedDistributionByComponentsRealWorldEV = '_SimulatedDistributionByComponentsRealWorldEV',
  SimulatedDistributionByComponentsRealWorldTotalAttributableEquity = '_SimulatedDistributionByComponentsRealWorldTotalAttributableEquity',
  SimulatedDistributionRealWorldInstruments = '_SimulatedDistributionRealWorldAllInstruments',
  SimulatedDistributionRealWorldByInstruments = '_SimulatedDistributionRealWorldByInstruments',
}

export enum ViewOption {
  EV,
  Equity,
  AllInstruments,
}

export enum SimulatedOutcomesChartTypes {
  RiskFreeOutcome,
  RealWorldOutcome,
  EVRealWorldDistribution,
  EquityRealWorldDistribution,
  InstrumentsRealWorldDistribution,
}

export enum ChartZoomMode {
  DefaultView,
  AllData,
  Zoomed,
}
