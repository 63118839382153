import { RootState } from '@core/store/store';
import { createSelector } from '@reduxjs/toolkit';

const selectCalculation = (state: RootState) => state.pwermCalculation;

export const selectSlnAndPrefSharesForecasts = createSelector(
  [selectCalculation],
  (calculation) => calculation.values.slnAndPrefSharesForecasts?.result || {}
);

export const selectTimesToExit = createSelector(
  [selectCalculation],
  (calculation) => calculation.values.calculatedProject?.timesToExit || {}
);

export const selectTimesSinceInvestment = createSelector(
  [selectCalculation],
  (calculation) => calculation.values.calculatedProject?.timesSinceInvestment || {}
);

export const selectCalculatedVariablesResults = createSelector(
  [selectCalculation],
  (calculation) => calculation.values.calculatedVariables?.result
);

export const selectCalculatedPayoutsResults = createSelector(
  [selectCalculation],
  (calculation) => calculation.values.calculatedPayouts?.result
);

export const getCalculatedProjectResults = createSelector(
  [selectCalculation],
  (calculation) => calculation.values.calculatedProject?.calculatedResults
);

export function getCalculatedProjectValueInPercent(value: number | undefined | null) {
  return value ? parseFloat((value * 100).toFixed(8)) : value;
}
