import { useAppSelector } from '@core/hooks/redux-hooks';
import { PwermCalculatedResultKeys } from '@app/shared/models/contracts/pwerm-calculation-results-dto';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@core/store/store';
import { useCallback } from 'react';

const selectCalculation = (state: RootState) => state.pwermCalculation;
const getCalculatedProjectResultsMap = createSelector([selectCalculation], (calculation) => {
  return new Map(
    calculation.values.calculatedProject.calculatedResults.map((item) => [
      toCompoundCalculatedResultKey(item.name, {
        caseId: item.caseId,
        forecastId: item.forecastId,
        multipleId: item.multipleId,
        instrumentId: item.instrumentId,
        trancheId: item.trancheId,
        trancheId2: item.trancheId2,
      }),
      item,
    ])
  );
});

export function useGetCalculatedProjectValue() {
  const map = useAppSelector(getCalculatedProjectResultsMap);
  const getCalculatedProjectValue = useCallback(
    (
      name: keyof typeof PwermCalculatedResultKeys,
      selectBy?: {
        caseId?: string;
        forecastId?: string;
        instrumentId?: string;
        trancheId?: string;
        trancheId2?: string;
        multipleId?: string;
      }
    ) => {
      return map.get(toCompoundCalculatedResultKey(name, selectBy))?.value;
    },
    [map]
  );
  return {
    getCalculatedProjectValue,
  };
}

const toCompoundCalculatedResultKey = (
  name: string,
  selectBy?: {
    caseId?: Nullable<string>;
    forecastId?: Nullable<string>;
    instrumentId?: Nullable<string>;
    trancheId?: Nullable<string>;
    trancheId2?: Nullable<string>;
    multipleId?: Nullable<string>;
  }
) => {
  let key = name;
  key += `c${selectBy?.caseId ?? ''}`;
  key += `f${selectBy?.forecastId ?? ''}`;
  key += `i${selectBy?.instrumentId ?? ''}`;
  key += `m${selectBy?.multipleId ?? ''}`;
  key += `t${selectBy?.trancheId ?? ''}`;
  key += `t2${selectBy?.trancheId2 ?? ''}`;

  return key;
};
