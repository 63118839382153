import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { TextTransform } from '@app/shared/enums/text-transform.enum';
import TableSectionValuesPerMultiple from '@app/modules/projects/outputs/components/TableSectionValuesPerMultiple';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';
import { WaterfallPartialTableProps } from '../../pwerm-summary-waterfall/waterfall-table/waterfall-shared';
import {
  getWaterfallCaseYearMultipleValue,
  getWaterfallCaseYearValue,
} from '@app/core/store/pwerm2-calculation-slice-selectors';
import { useLocale } from '@app/core/hooks/useLocale';

export const Pwerm2WaterfallTotalPayoutSummaryPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const results = useAppSelector((state) => state.pwerm2Calculation.calculatedResults);
  const { l } = useLocale();

  return (
    <>
      <tr>
        <th className="table-primary__cell--header-tertiary">{l('_TotalPayoutSummary')}</th>
        <th className="table-primary__cell--header-tertiary" colSpan={columnsCount - 1} />
      </tr>
      <TableSectionValuesPerMultiple
        title={l('_TotalDistributions')}
        testId="totalSummary-totalDistributions"
        renderCell={({ caseItem, forecast, multipleId }) => (
          <CellValue
            {...amountRules}
            value={getWaterfallCaseYearMultipleValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              multipleId!,
              (multiple) => multiple.totalDistributions
            )}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title={l('_OrdinaryEquityCheck')}
        testId="ordinary-equity-check"
        renderCell={({ caseItem, forecast }) => (
          <CellValue
            alignment={Alignment.Right}
            textTransform={TextTransform.Uppercase}
            value={getWaterfallCaseYearValue(
              results,
              caseItem.caseId,
              forecast.forecastId,
              (year) => year.allMultipleOrdinaryEquityCheck
            )}
          />
        )}
      />
    </>
  );
};
