import styles from '../../opm-summary-waterfall.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  enteredPercentageViewRules,
  numberCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import {
  OpmFirstLastWaterfallDto,
  FirstLastSummaryCalculations,
  FirstLastSummaryIndexDictionaryDto,
  OpmFirstLastWaterfallDictionaryDto,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import { FC } from 'react';
import { useLocale } from '@app/core/hooks/useLocale';
import { SelectOption } from '@app/shared/models/option';
import { translationKeys } from '@locale/setupI18n';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { enumKeyByValue } from '@app/shared/helpers';
import { OpmSimulatedValue } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';

type ValueKeys = Omit<FirstLastSummaryCalculations, 'instruments'>;

interface SummaryCalculationsProps {
  opmWaterfall: OpmFirstLastWaterfallDictionaryDto;
  selectedYear: string;
  yearOptions: SelectOption[];
}

const SummaryCalculations: FC<SummaryCalculationsProps> = ({
  opmWaterfall,
  selectedYear,
}): JSX.Element => {
  const { l } = useLocale();
  const opmCalc = useAppSelector((state) => state.opmCalculation);
  const waterfallIterations = useAppSelector((state) => state.uiValues.opmWaterfallIterations ?? 5);

  const isEquitySimulation =
    opmCalc?.riskFreeValues?.inputs?.opmSimulatedValue ===
    enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue);

  const exitSimulationTitleKey = isEquitySimulation ? '_ExitEV' : '_ExitEquity';
  const exitSimulationTitle: translationKeys = exitSimulationTitleKey;

  const createRow = (
    label: translationKeys,
    valueKey: keyof ValueKeys,
    isPercentage = false,
    className = '',
    decimalPoint: number | null = null
  ) => {
    return (
      <tr>
        <td
          className={classNames(className, styles['border-right'])}
          data-testid={`${valueKey}-title`}>
          {l(label)}
        </td>
        {(['first', 'last'] as (keyof OpmFirstLastWaterfallDto)[]).map((section, outerIndex) =>
          Object.entries(opmWaterfall[selectedYear][section]).map(([key, _], innerIndex) => {
            const value = (
              opmWaterfall[selectedYear][section] as FirstLastSummaryIndexDictionaryDto
            )[key][valueKey];

            return (
              <td
                key={`${section}-${key}`}
                className={classNames(className, {
                  [styles['border-right']]:
                    outerIndex === 0 && innerIndex + 1 === waterfallIterations,
                })}
                data-testid={`${valueKey}-simulation-${key}`}>
                <CellValue
                  value={isPercentage && typeof value === 'number' ? value * 100 : value}
                  {...(isPercentage ? enteredPercentageViewRules : amountRules)}
                  {...(decimalPoint !== null && numberCalculatedViewRules)}
                  normalize
                />
              </td>
            );
          })
        )}
      </tr>
    );
  };

  return (
    <>
      {createRow(exitSimulationTitle, 'simulatedEv')}
      {createRow('_TransactionCost', 'transactionCostsPc', true)}
      {createRow('_IpoDiscount', 'ipoDiscountPc', true)}
      {createRow('_NetExitProceeds', 'netExitProceeds')}
      {isEquitySimulation && createRow('_NetDebtNegativeOrNetCash', 'totalNetCash')}
      {createRow('_TotalEquityTitle', 'totalEquityProceeds')}
      {createRow('_TotalContractualShareholderDebt', 'totalShareholderDebtDistributions')}
      {createRow('_OrdinaryEquity', 'totalOrdinaryEquityProceeds')}
    </>
  );
};

export default SummaryCalculations;
