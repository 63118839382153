import { FC, PropsWithChildren } from 'react';
import styles from './pill.module.scss';
import classNames from 'classnames';

export enum PillTheme {
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  White = 'white',
  Orange = 'orange',
}

export enum PillSize {
  Small = 'small',
  Xsmall = 'xsmall',
}

interface PillProps {
  theme?: PillTheme;
  size?: PillSize;
  noTextTransform?: boolean;
  suffix?: () => JSX.Element;
}

const PARENT_CLASSNAME = 'pill';

export const Pill: FC<PropsWithChildren<PillProps>> = ({
  children,
  theme,
  size,
  noTextTransform,
  suffix: Suffix,
}) => {
  return (
    <div
      className={classNames(styles[PARENT_CLASSNAME], {
        [styles[`${PARENT_CLASSNAME}--theme-${theme}`]]: theme,
        [styles[`${PARENT_CLASSNAME}--size-${size}`]]: size,
        [styles[`${PARENT_CLASSNAME}--no-text-transform`]]: noTextTransform,
      })}>
      {children}
      {Suffix && (
        <div className={styles[`${PARENT_CLASSNAME}__suffix-container`]}>
          <Suffix />
        </div>
      )}
    </div>
  );
};
