import { FC, Fragment } from 'react';
import { selectCasesStructure } from '@core/store/project-slice-selectors';
import classNames from 'classnames';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { useShouldHighlight } from '../custom-hooks/useShouldHighlight';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import TableSpacerRow from '@app/shared/components/table/TableSpacerRow';
import { useLocale } from '@app/core/hooks/useLocale';
import { translationKeys } from '@locale/setupI18n';
import { resources } from '@locale/translations';

type TableSectionValuesPerForecastProps = {
  title: string;
  renderCell: (props: {
    caseItem: CaseDto;
    caseItemIndex: number;
    forecast: ForecastDto;
    forecastIndex: number;
  }) => JSX.Element;
  renderAdditionalCell?: (props: { caseItem: CaseDto }) => JSX.Element;
  highlight?: boolean;
  additionalTitleClassNames?: string;
  additionalSpacerTitleClassNames?: string;
  additionalCellClassNames?: string;
  renderSpacer?: boolean;
  testId?: string;
};

const TableSectionValuesPerForecast: FC<TableSectionValuesPerForecastProps> = ({
  title,
  renderCell,
  renderAdditionalCell,
  highlight,
  additionalTitleClassNames,
  additionalCellClassNames,
  additionalSpacerTitleClassNames,
  renderSpacer = true,
  testId,
}) => {
  const { l } = useLocale();
  const casesStructure = useAppSelector(selectCasesStructure);

  const isTranslationKey = (key: string): key is translationKeys => {
    return Object.keys(resources.en.translations).includes(key);
  };

  const isTitleTranslation = isTranslationKey(title);

  const shouldHighlightCell = useShouldHighlight();

  return (
    <>
      <tr>
        <th
          className={classNames(
            'table-primary__cell--vertical-separator',
            additionalTitleClassNames
          )}>
          {title && isTitleTranslation ? l(title) : title}
        </th>
        {casesStructure.map((caseItem, caseItemIndex) => (
          <Fragment key={caseItem.caseId}>
            <td
              className={classNames(
                'table-primary__cell--vertical-separator',
                additionalCellClassNames
              )}>
              {renderAdditionalCell !== undefined ? renderAdditionalCell({ caseItem }) : undefined}
            </td>
            {caseItem.forecasts.map((forecast, forecastIndex) => (
              <td
                key={forecastIndex}
                data-testid={testId ? `${testId}-c${caseItemIndex}-f${forecastIndex}` : undefined}
                className={classNames(additionalCellClassNames, {
                  'table-primary__cell--vertical-separator':
                    caseItem.forecasts.length - 1 === forecastIndex,
                  'table-primary__cell--highlight':
                    highlight && shouldHighlightCell(caseItem.caseId, forecast.forecastId),
                })}>
                {renderCell({ caseItem, caseItemIndex, forecast: forecast, forecastIndex })}
              </td>
            ))}
          </Fragment>
        ))}
      </tr>
      {renderSpacer && (
        <TableSpacerRow additionalTitleClassNames={additionalSpacerTitleClassNames} />
      )}
    </>
  );
};

export default TableSectionValuesPerForecast;
