import Button from '@app/shared/components/button/Button';
import Plus from '@app/shared/icons/Plus';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import * as projectActions from '@core/store/project-slice';
import { cloneCase, generateUniqueName } from '@app/shared/helpers';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { MAX_CASES_COUNT } from '@app/shared/constants/deal-thesis';
import { usePwcNavigate } from '@core/hooks/routing-hooks';

export const AddDealThesisCase = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const { navigate, generatePath } = usePwcNavigate();

  const caseNarrative = generateUniqueName(
    'Case',
    new Set(project.pwermInput.cases?.map((caseItem) => caseItem.narrative))
  );

  const clonedCase = cloneCase(project.pwermInput.cases[project.pwermInput.cases.length - 1]);
  const caseId = clonedCase.caseId;
  const handleAddCase = () => {
    dispatch(
      projectActions.updateProjectDraft({
        project: {
          ...project,
          pwermInput: {
            ...project.pwermInput,
            cases: [
              ...project.pwermInput.cases,
              {
                ...clonedCase,
                narrative: caseNarrative,
                probability: 0,
                caseOrder: project.pwermInput.cases.length,
              },
            ],
          },
        },
      })
    );
    navigate(generatePath('../:caseId', { caseId }));
  };

  return (
    <Button
      appearance={ButtonAppearance.DEFAULT_PRIMARY}
      startIcon={<Plus />}
      onClick={handleAddCase}
      disabled={project.pwermInput.cases?.length === MAX_CASES_COUNT}>
      Add Case
    </Button>
  );
};
