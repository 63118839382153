import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC, memo } from 'react';

const PlusSvg: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" {...props}>
      <path d="M8.5 3.54297V13.4596" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.54102 8.5H13.4577" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

export default memo(PlusSvg);
