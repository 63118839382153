import React, { useEffect, useState } from 'react';
import { Toggle, ToggleSize } from '../toggle/Toggle';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import { FormProvider, useForm } from 'react-hook-form';
import styles from './benchmarking-only.module.scss';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import { updateProjectDraft } from '@app/core/store/project-slice';
import { useLocale } from '@app/core/hooks/useLocale';

const BenchmarkingOnly: React.FC = () => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();

  const [benchmarkingOnlyState, setBenchmarkingOnlyState] = useState<boolean>(
    project.benchmarking.benchmarkingOnly ?? false
  );

  useEffect(() => {
    setBenchmarkingOnlyState(project.benchmarking.benchmarkingOnly);
  }, [project]);

  const formMethods = useForm({
    ...formConfigBase,
  });

  const handleChange = async () => {
    const newBenchmarkingOnlyState = !benchmarkingOnlyState;
    setBenchmarkingOnlyState(newBenchmarkingOnlyState);
    const updatedProject = {
      ...project,
      benchmarking: {
        ...project.benchmarking,
        benchmarkingOnly: newBenchmarkingOnlyState,
      },
    };
    await dispatch(updateProjectDraft({ project: updatedProject })).unwrap();
  };

  let forceChecked: boolean | null = null;
  if (benchmarkingOnlyState === undefined || benchmarkingOnlyState === false) {
    forceChecked = false;
  } else {
    forceChecked = true;
  }

  return (
    <FormProvider {...formMethods}>
      <Toggle
        name="benchmarkingOnly"
        label={l('_BenchmarkingOnlyProject')}
        toggleSize={ToggleSize.Medium}
        className={styles['benchmarking-only']}
        onClick={handleChange}
        data-testid="benchmarking-only-toggle"
        forceChecked={forceChecked}
      />
    </FormProvider>
  );
};

export default BenchmarkingOnly;
