import { FC, useEffect, useState } from 'react';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import { Card } from '@app/shared/components/card/Card';
import store from '@core/store/store';
import * as projectActions from '@core/store/project-slice';
import { CHECK_PROJECT_USAGE_TIMING } from '@app/shared/constants/configs/timings';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { CardVariation } from '@app/shared/components/card/card-enums';
import styles from './current-active-users-indicator.module.scss';
import SvgEye from '@app/shared/icons/Eye';
import { ChevronVariation } from '@app/shared/components/dropdown/DropdownEnums';

const PARENT_CLASSNAME = 'availability';

export const CurrentActiveUsersIndicator: FC = (): JSX.Element => {
  const [activeUserList, setActiveUserList] = useState<string[]>([]);
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.userInfo);
  const MAX_ITEMS_IN_LIST = 5;

  const removeCurrentUserFromActiveList = (list: string[]) => {
    return list.filter((email) => email !== userInfo.email);
  };

  const retrieveActiveUserList = () => {
    if (Number(projectId)) {
      dispatch(projectActions.projectHeartBeat({ projectId: Number(projectId) }))
        .unwrap()
        .then((result) => {
          setActiveUserList(removeCurrentUserFromActiveList(result));
        });
    }
  };

  const updateProjectStatus = () => {
    return setInterval(() => {
      retrieveActiveUserList();
    }, CHECK_PROJECT_USAGE_TIMING);
  };

  useEffect(() => {
    if (projectId) {
      retrieveActiveUserList();
      const projectStatusInterval = updateProjectStatus();

      return () => {
        clearInterval(projectStatusInterval);
        store.dispatch(
          projectActions.makeUserInactiveInProjectScope({ projectId: Number(projectId) })
        );
      };
    }
  }, [projectId, userInfo.email]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!activeUserList.length || !userInfo.email || !projectId) {
    return <></>;
  }

  return (
    <DropdownMenu
      chevronVariation={ChevronVariation.Dropdown}
      expandWithContent
      dropdownContent={
        <div className={styles[`${PARENT_CLASSNAME}`]}>
          <span className={styles[`${PARENT_CLASSNAME}__icon`]}>
            <SvgEye />
          </span>
          {activeUserList.length} other active viewers
        </div>
      }>
      <Card variation={CardVariation.NoSpacing} shouldFitContent>
        <ul className={styles[`${PARENT_CLASSNAME}__list`]}>
          {activeUserList
            .slice(
              0,
              activeUserList.length > MAX_ITEMS_IN_LIST ? MAX_ITEMS_IN_LIST - 1 : MAX_ITEMS_IN_LIST
            )
            .map((email, index) => (
              <li key={index} className={styles[`${PARENT_CLASSNAME}__item`]}>
                {email}
              </li>
            ))}
          {activeUserList.length > MAX_ITEMS_IN_LIST && (
            <li className={styles[`${PARENT_CLASSNAME}__item`]}>
              +{activeUserList.length - (MAX_ITEMS_IN_LIST - 1)} other active viewers
            </li>
          )}
        </ul>
      </Card>
    </DropdownMenu>
  );
};
