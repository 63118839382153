import { FC } from 'react';
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import CellValue from '@app/shared/components/cell-value/CellValue';
import {
  amountRules,
  decimalCalculatedViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { selectValuedInstruments } from '@core/store/project-slice-selectors';
import { instrumentByTypeOrTranchesOrDefaultSortFn } from '@app/shared/helpers';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { EmptyValues } from '@app/shared/constants/empty-values';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { useLocale } from '@app/core/hooks/useLocale';

export const Pwerm2ValuationEstimatesPwerm: FC = (): JSX.Element => {
  const results = useAppSelector(
    (state) => state.pwerm2Calculation.calculatedResults.dashboard.valuationInstruments
  );
  const { l } = useLocale();
  const valuedInstruments = useAppSelector(selectValuedInstruments);
  const sortedValuedInstruments = valuedInstruments.sort(instrumentByTypeOrTranchesOrDefaultSortFn);

  const columnsCount = 5;

  const getRowData = (instrument: EquityInstrumentDto) => {
    const instrumentId = instrument.instrumentId;
    const aggMarketValue = results[instrumentId].weightedDiscountedFairValue;

    const aggCost = results[instrumentId].equityInvestment;

    const marketValuePerShare = results[instrumentId].weightedDiscountedFairValuePerShare;

    const subscriptionPricePerShare = results[instrumentId].subscriptionPricePerShare;

    return [
      { value: aggMarketValue, formattingRules: amountRules },
      { value: aggCost, formattingRules: amountRules },
      { value: marketValuePerShare, formattingRules: decimalCalculatedViewRules },
      { value: subscriptionPricePerShare, formattingRules: decimalCalculatedViewRules },
    ];
  };

  return (
    <DashboardWidgetContainer title={l('_ValuationEstimates') + ' v2'}>
      <table className="table-primary table-primary--fixed table-primary--secondary-theme">
        <thead>
          <tr>
            <th className="table-primary__cell--header-quinary">{l('_Instrument')}</th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_AggregateMarketValue')}
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_AggregateCost')}
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_MarketValuePerShare')}
            </th>
            <th className="table-primary__cell--header-quinary table-primary__cell--right">
              {l('_SubscriptionPricePerShare')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedValuedInstruments.length === 0 && (
            <tr>
              <td>{EmptyValues.EnDash}</td>
              {[...Array(columnsCount - 1)].map((_, index) => (
                <td key={index}>
                  <CellValue
                    value={undefined}
                    theme={CellValueTheme.Secondary}
                    alignment={Alignment.Right}
                  />
                </td>
              ))}
            </tr>
          )}
          {sortedValuedInstruments.map((instrument, index) => {
            const rowData = getRowData(instrument);

            return (
              <tr key={index} data-testid={`valuation-estimates-row-${index}`}>
                <td>
                  <span className="heading-2 heading-2--alternate">
                    {instrument.instrumentNarrative}
                  </span>
                </td>
                {rowData.map((item, valueIndex) => (
                  <td key={valueIndex}>
                    <CellValue
                      value={item.value}
                      theme={CellValueTheme.Secondary}
                      {...item.formattingRules}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </DashboardWidgetContainer>
  );
};
