import { SVGProps } from 'react';
const SvgUserRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M10.562 13.688h-3.53C3.703 13.688 1 16.416 1 19.78c0 .675.54 1.22 1.207 1.22h13.182c.666 0 1.206-.545 1.206-1.22 0-3.364-2.701-6.093-6.033-6.093Zm-7.866 5.624c.232-2.21 2.088-3.937 4.337-3.937h3.53c2.248 0 4.076 1.727 4.337 3.938H2.696ZM8.797 12c2.461 0 4.456-2.015 4.456-4.5S11.258 3 8.797 3c-2.46 0-4.455 2.015-4.455 4.5S6.336 12 8.797 12Zm0-7.313c1.536 0 2.785 1.262 2.785 2.813 0 1.55-1.25 2.813-2.785 2.813-1.535 0-2.784-1.263-2.784-2.813 0-1.55 1.25-2.813 2.784-2.813Zm12.32 6.156 1.637-1.653a.85.85 0 0 0 0-1.193.83.83 0 0 0-1.182 0l-1.635 1.686L18.3 8.03a.83.83 0 0 0-1.182 0 .85.85 0 0 0 0 1.193l1.637 1.653-1.637 1.654a.85.85 0 0 0 0 1.193.83.83 0 0 0 1.182 0l1.637-1.656 1.637 1.653a.83.83 0 0 0 1.181 0 .85.85 0 0 0 0-1.193l-1.638-1.684Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgUserRemove;
