import { FC, useEffect, useState } from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { DateFormatType } from '@app/shared/enums/date-format-enum';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker';
import { formatISO, parseISO } from 'date-fns';
import styles from './datepicker.module.scss';
import SvgChevron from '@app/shared/icons/Chevron';
import { TextFieldProps } from '@mui/material';
import { useController } from 'react-hook-form';

interface DatepickerInputProps
  extends Omit<DatePickerProps<string, string>, 'renderInput' | 'onChange' | 'value'> {
  name: string;
  renderInput: (
    props: TextFieldProps & { setIsCalendarOpen: (isCalendarOpen: boolean) => void }
  ) => JSX.Element;
  formSubmitHandler?: () => void;
}
export const Datepicker: FC<DatepickerInputProps> = ({ name, renderInput, formSubmitHandler }) => {
  const {
    field: { ref, value: fieldValue, onChange: fieldOnChange, ...restFieldProps },
  } = useController({ name });

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);

  const handleDateChange = (value: string | Date | null) => {
    if (value === null) {
      fieldOnChange(null);
      return;
    }

    if (value.toString() === 'Invalid Date') {
      fieldOnChange(value.toString());
      return;
    }

    fieldOnChange(formatISO(value as number | Date, { representation: 'date' }));
  };

  useEffect(() => {
    if (isCalendarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isCalendarOpen]);

  return (
    <MuiDatePicker
      onChange={handleDateChange}
      onAccept={(value) => {
        handleDateChange(value);
        formSubmitHandler && formSubmitHandler();
      }}
      onClose={() => setIsCalendarOpen(false)}
      PopperProps={{ className: styles['datepicker'] }}
      views={['year', 'month', 'day']}
      components={{
        SwitchViewIcon: SvgChevron,
        LeftArrowIcon: SvgChevron,
        RightArrowIcon: SvgChevron,
      }}
      componentsProps={{
        switchViewButton: {
          disableRipple: true,
        },
        leftArrowButton: {
          disableRipple: true,
          className: 'left',
        },
        rightArrowButton: {
          disableRipple: true,
          className: 'right',
        },
      }}
      value={fieldValue ? parseISO(fieldValue) : ''}
      inputRef={ref}
      open={isCalendarOpen}
      inputFormat={DateFormatType.enGB}
      renderInput={(params) => (
        <>{renderInput && renderInput({ ...params, ...restFieldProps, setIsCalendarOpen })}</>
      )}
    />
  );
};
