import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import PwcNavLink from '@app/shared/routing/PwcNavLink';
import { CalcMethod } from '@app/shared/models/contracts/enums/shared-enums';
import styles from './project-menu-item.module.scss';
import { enumKeyByValue } from '@app/shared/helpers';
import { RouteConstants } from '../../RouteConstants';

export const PARENT_CLASSNAME = 'menu-item';

interface ProjectMenuItemProps {
  route: string;
  title: string;
}

export const ProjectMenuItem: FC<ProjectMenuItemProps> = ({ route, title }): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const { details: projectDetails } = project;
  const opmRiskFreeResults = useAppSelector((state) => state.opmCalculation.riskFreeValues);
  const opmRealWorldResults = useAppSelector((state) => state.opmCalculation.realWorldValues);

  const Link = () => {
    let disabled = false;

    const pwermRoutes: string[] = [
      RouteConstants.PwermWaterfall,
      RouteConstants.DashboardPwerm,
      RouteConstants.CostOfEquity,
      RouteConstants.DealThesis,
      RouteConstants.DealThesis2,
      RouteConstants.ValuationInputs,
    ];

    const opmRoutes: string[] = [
      RouteConstants.OpmInputs,
      RouteConstants.DashboardOpm,
      RouteConstants.RealWorldDistributions,
      RouteConstants.OpmWaterfall,
    ];

    const pwerm2Routes: string[] = [
      RouteConstants.Pwerm2Waterfall,
      RouteConstants.PwermComparator,
      RouteConstants.DashboardPwerm2,
      RouteConstants.CostOfEquity2,
    ];

    const opmRiskFreeDataExists = Object.keys(opmRiskFreeResults).length > 0;
    const opmRealWorldDataExists = Object.keys(opmRealWorldResults).length > 0;
    const hasValuedInstruments =
      opmRiskFreeResults.valuationConclusion && opmRiskFreeResults.valuationConclusion.length > 0;

    // disable OPM routes in PWERM-only calculation mode
    if (
      (projectDetails.calcMethod === CalcMethod.PWERM || projectDetails.calcMethod === undefined) &&
      opmRoutes.includes(route)
    ) {
      disabled = true;
    }

    // disable PWERM routes in OPM-only calculation mode
    if (
      projectDetails.calcMethod === CalcMethod.OPM &&
      (pwermRoutes.includes(route) || pwerm2Routes.includes(route))
    ) {
      disabled = true;
    }

    // disable the OPM dashboards if they have no data
    if (
      projectDetails.calcMethod === CalcMethod.OPM ||
      projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)
    ) {
      if (route === RouteConstants.DashboardOpm && !opmRiskFreeDataExists) {
        disabled = true;
      }

      if (route === RouteConstants.RealWorldDistributions && !opmRealWorldDataExists) {
        disabled = true;
      }
    }

    // disable the OPM Waterfall if there is no data, or there are no valued instruments
    if (
      projectDetails.calcMethod === CalcMethod.OPM ||
      projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)
    ) {
      if (route === RouteConstants.OpmWaterfall && !opmRiskFreeDataExists) {
        disabled = true;
      }

      if (route === RouteConstants.OpmWaterfall && !hasValuedInstruments) {
        disabled = true;
      }
    }

    // disable PWERM and OPM Overall Conclusion if there is no data
    if (projectDetails.calcMethod === enumKeyByValue(CalcMethod, CalcMethod.PWERM_AND_OPM)) {
      if (route === RouteConstants.PwermOpmConclusions && !opmRiskFreeDataExists) {
        disabled = true;
      }
    }

    const disabledClassName = disabled ? ' disabled' : '';

    return (
      <>
        <PwcNavLink
          className={styles[PARENT_CLASSNAME] + disabledClassName}
          route={route}
          disabled={disabled}
          data-testid={route}>
          {title}
        </PwcNavLink>
      </>
    );
  };

  return <Link />;
};
