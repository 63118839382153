import { getTotalOfEquityByInstrumentTypeAndOwnership } from '@core/store/project-slice-selectors';
import { useAppSelector } from '@core/hooks/redux-hooks';
import { getOpmForecastProbabilityWeightedValue } from '@app/shared/helpers/get-weighted-values';
import { OpmForecastDto } from '@app/shared/models/contracts/opm-calculation-results-dto';

export const useOpmTotalEquityGeneralMetrics = (forecasts: OpmForecastDto[]) => {
  const equityItems = useAppSelector((state) => state.project.projectDraft.equityInstruments);

  const getTotalEquityProceedsWeightedValue = getOpmForecastProbabilityWeightedValue({
    forecasts,
    getValue: ({ forecast }) => forecast.totalEquityProceeds,
  });

  const getExitSlnDebtProceedsWeightedValue = getOpmForecastProbabilityWeightedValue({
    forecasts,
    getValue: ({ forecast }) => forecast.totalShareholderDebtProceeds,
  });

  const getExitOrdinaryProceedsWeightedValue = getOpmForecastProbabilityWeightedValue({
    forecasts,
    getValue: ({ forecast }) => forecast.totalOrdinaryEquityProceeds,
  });

  const getExitSweetOrdinaryProceedsWeightedValue = getOpmForecastProbabilityWeightedValue({
    forecasts,
    getValue: ({ forecast }) => forecast.totalSweetEquityProceeds,
  });

  const entryTotalEquityValue = getTotalOfEquityByInstrumentTypeAndOwnership(equityItems);

  const equityProceedsWeightedValue = getTotalEquityProceedsWeightedValue;

  const exitSlnDebtProceeds = getExitSlnDebtProceedsWeightedValue;

  const exitOrdinaryEquityProceeds = getExitOrdinaryProceedsWeightedValue;

  const sweetEquityProceedsWeightedValue = getExitSweetOrdinaryProceedsWeightedValue;

  const sweetEquityPercentageOfOrdinaryEquityProceeds =
    (sweetEquityProceedsWeightedValue / exitOrdinaryEquityProceeds) * 100;

  return {
    entryTotalEquityValue,
    equityProceedsWeightedValue,
    exitSlnDebtProceeds,
    exitOrdinaryEquityProceeds,
    sweetEquityPercentageOfOrdinaryEquityProceeds,
  };
};
