export const getWholeYearsBetweenDates = (date1: string, date2: string) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);

  if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
    return null;
  }

  let years = d1.getFullYear() - d2.getFullYear();

  if (
    d1.getMonth() < d2.getMonth() ||
    (d1.getMonth() === d2.getMonth() && d1.getDate() < d2.getDate())
  ) {
    years--;
  }

  return years;
};
