export enum RemSpacing {
  spacer8 = '0.8rem',
  spacer16 = '1.6rem',
  spacer24 = '2.4rem',
  spacer32 = '3.2rem',
  spacer40 = '4.0rem',
  spacer48 = '4.8rem',
  spacer56 = '5.6rem',
  spacer64 = '6.4rem',
}

export enum PixelSpacing {
  spacer8 = '8px',
  spacer16 = '16px',
  spacer24 = '24px',
  spacer32 = '32px',
  spacer40 = '40px',
  spacer48 = '48px',
  spacer56 = '56px',
  spacer64 = '64px',
}

export enum UnitlessSize {
  size16 = 16,
  size60 = 60,
}

export const dimensions = {
  headerHeight: '50px',
  drawerWidth330: '330px',
  drawerWidth380: '380px',
  framedButtonSize: '40px',
};

export function convertUnitlessSizeToRem(spacingValue: number): string {
  return `${spacingValue / 10}rem`;
}

export function convertUnitlessSizeToPx(spacingValue: number): string {
  return `${spacingValue}px`;
}
