import styles from './loader.module.scss';
import Spinner from '@app/shared/icons/Spinner';
import { FC } from 'react';
import { LoaderSize } from '@app/shared/components/loader/loader-enums';
import classNames from 'classnames';

const PARENT_CLASSNAME = 'loader';

export const SpinnerLoader: FC<{ size?: LoaderSize }> = ({ size }) => {
  return (
    <>
      <Spinner
        className={classNames(styles[PARENT_CLASSNAME], {
          [styles[`${PARENT_CLASSNAME}--size-${size}`]]: size,
        })}
      />
    </>
  );
};
