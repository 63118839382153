import {
  Currency,
  MultipleType,
  Denomination,
  ProjectStatus,
  CalcMethod,
  OpmSimulatedValue,
  CollaringMode,
  OpmInputDataSource,
} from '@app/shared/models/contracts/enums/shared-enums';
import { DetailsDto, PerYearValuesDto, ProjectDto } from '@app/shared/models/contracts/project-dto';
import { enumKeyByValue } from '@app/shared/helpers';
import { DEFAULT_EMPTY_BENCHMARKING } from './empty-benchmarking';
import { l } from '@locale/setupI18n';
import { v4 as uuidv4 } from 'uuid';

export interface ProjectFormModel extends DetailsDto {
  projectName: string;
  status: keyof typeof ProjectStatus;
  valuationDate: Nullable<string>;
  investmentDate: Nullable<string>;
  yearEndDate: Nullable<string>;
  storeBenchmarkingData: boolean;
  randomiserActive: boolean;
  iterationCount: number;
  opmSimulatedValue: keyof typeof OpmSimulatedValue;
}

const getProjectFormBase = (model: ProjectFormModel) => ({
  name: model.projectName,
  storeBenchmarkingData: model.storeBenchmarkingData,
  valuationDate: model.valuationDate ?? '',
  yearEndDate: model.yearEndDate ?? '',
  investmentDate: model.investmentDate ?? '',
});

export function MapNewProjectAdminToProjectDto(model: ProjectFormModel): ProjectDto {
  const valueDriverMetricId = uuidv4();

  // @ts-ignore
  return {
    ...getProjectFormBase(model),
    status: enumKeyByValue(ProjectStatus, ProjectStatus.Draft),
    id: 0,
    variables: {},
    benchmarking: { ...DEFAULT_EMPTY_BENCHMARKING },
    equityInstruments: [],
    capitalStructures: {},
    sourcesAndUses: {
      equitySources: [],
      otherSources: [],
      uses: [],
      updatedSpotEquity: 0,
    },
    valuationInputs: {
      minorityDiscounts: {
        dloc: null,
        marketVolatility: null,
        peerSetVolatility: null,
      },
    },
    details: {
      currency: enumKeyByValue(Currency, Currency.PoundSterling),
      denomination: enumKeyByValue(Denomination, Denomination.Thousands),
      calcMethod: enumKeyByValue(CalcMethod, CalcMethod.PWERM),
      mipEvent: null,
      dealThesisExitMultipleType: enumKeyByValue(MultipleType, MultipleType.Ltm),
      sector: null,
      subSector: null,
      regionOfOperations: null,
      countryOfMainOperations: null,
      mainTaxJurisdiction: null,
      secondaryTaxJurisdiction: null,
      client: null,
      targetTradeName: null,
      sponsor: null,
      coInvestor: null,
      engagementLetterDate: null,
      valuationProjectLead: null,
      dealsTaxProjectLead: null,
      salesforceId: null,
      qcSignOff: false,
      qvpSignOff: false,
      qcSignOffUsername: null,
      qvpSignOffUsername: null,
    },
    opmInput: {
      evValue: 0,
      evValueSource: OpmInputDataSource.FromCapitalStructure,
      forecastYears: 3,
      forecastYearsSource: OpmInputDataSource.FromPWERM,
      evVolatility: 0,
      randomSeed: Math.floor(Math.random() * 10000),
      iterationCount: null,
      randomiserActive: false,
      selectedEventSetId: null,
      selectedCaseId: null,
      opmSimulatedValue: enumKeyByValue(OpmSimulatedValue, OpmSimulatedValue.EnterpriseValue),
      netDebtItems: [],
      initialCashValue: 0,
      realWorldRate: null,
      controlPremiumForDloc: 0,
      controlPremiumForDlocSource: OpmInputDataSource.FromPWERM,
      ValuationConclusion: [],
      fixedValueCap: null,
      percentBottom: null,
      percentTop: null,
      perYearInputs: [
        createOpmPerYearInput(model.yearEndDate!, 0),
        createOpmPerYearInput(new Date(model.yearEndDate!).addYears(1).toISODateString(), 0),
        createOpmPerYearInput(new Date(model.yearEndDate!).addYears(2).toISODateString(), 100),
      ],
      valuationDateNarrative: '',
      initialTotalNetDebt: 0,
      initialTotalNetDebtSource: OpmInputDataSource.FromPWERM,
      marketVolatility: null,
      peerSetVolatility: null,
    },
    overallConclusions: [],
    pwermInput: {
      metricDefinitions: {
        [valueDriverMetricId]: { narrative: l('_DefaultExitValueDriverNarrative') },
      },
      valueDriverActiveMetric: valueDriverMetricId,
      valuationDateMetrics: {
        [valueDriverMetricId]: 0,
      },
      valuationDateNarrative: l('_DefaultValuationDateNarrative'),
      capmAssumptions: {
        riskFreeRate: 0,
        assetBeta: 0,
        emrp: 0,
        cemra: 0,
        riskFreeRateCountry: null,
        sourceAndRunDate: null,
      },
      cases: [],
      historicForecasts: [],
    },
  };
}

export function MapUpdatedProjectAdminToProjectDto(
  model: ProjectFormModel,
  currentDraftState: ProjectDto
): ProjectDto {
  let historicForecasts = currentDraftState.pwermInput.historicForecasts;

  if (model.investmentDate && currentDraftState.investmentDate !== model.investmentDate) {
    const investmentDate = new Date(model.investmentDate);
    historicForecasts = currentDraftState.pwermInput.historicForecasts.filter(
      (forecast) => new Date(forecast.date) > investmentDate
    );
  }

  const projectDtoBase: ProjectDto = {
    ...currentDraftState,
    ...getProjectFormBase(model),
    status: model.status,
    details: {
      ...currentDraftState.details,
      currency: model.currency,
      denomination: model.denomination,
      mipEvent: model.mipEvent,
      calcMethod: model.calcMethod,
      dealThesisExitMultipleType: model.dealThesisExitMultipleType,
      sector: model.sector,
      subSector: model.subSector,
      regionOfOperations: model.regionOfOperations,
      countryOfMainOperations: model.countryOfMainOperations,
      mainTaxJurisdiction: model.mainTaxJurisdiction,
      secondaryTaxJurisdiction: model.secondaryTaxJurisdiction,
      client: model.client ?? null,
      targetTradeName: model.targetTradeName ?? null,
      sponsor: model.sponsor ?? null,
      coInvestor: model.coInvestor ?? null,
      engagementLetterDate: model.engagementLetterDate ?? null,
      valuationProjectLead: model.valuationProjectLead ?? null,
      dealsTaxProjectLead: model.dealsTaxProjectLead ?? null,
      salesforceId: model.salesforceId === '' ? null : model.salesforceId,
      qcSignOff: model.qcSignOff ?? false,
      qvpSignOff: model.qvpSignOff ?? false,
      qcSignOffUsername: model.qcSignOffUsername ?? null,
      qvpSignOffUsername: model.qvpSignOffUsername ?? null,
    },
    opmInput: {
      ...currentDraftState.opmInput,
      iterationCount: model.iterationCount,
      randomiserActive: model.randomiserActive ?? false,
      opmSimulatedValue: model.opmSimulatedValue,
    },
    pwermInput: {
      ...currentDraftState.pwermInput,
      historicForecasts,
    },
  };

  if (model.yearEndDate && currentDraftState.yearEndDate !== model.yearEndDate) {
    return {
      ...projectDtoBase,
      pwermInput: {
        ...currentDraftState.pwermInput,
        cases: currentDraftState.pwermInput.cases.map((_case) => {
          return {
            ..._case,
            forecasts: [..._case.forecasts]
              .sort((a, b) => a.forecastYear.localeCompare(b.forecastYear))
              .map((forecast, index) => {
                return {
                  ...forecast,
                  forecastYear: new Date(model.yearEndDate!).addYears(index).toISODateString(),
                };
              }),
          };
        }),
      },
    };
  }

  return projectDtoBase;
}

function createOpmPerYearInput(
  yearEndDate: string,
  exitHorizonProbability: number
): PerYearValuesDto {
  return {
    exitHorizonProbability: exitHorizonProbability,
    riskFreeRate: 0,
    forecastDate: yearEndDate,
    equitySoldInIpo: 0,
    equitySoldInIpoSource: OpmInputDataSource.FromPWERM,
    financingCosts: 0,
    financingCostsSource: OpmInputDataSource.FromPWERM,
    hypotheticalDebtWhenIpo: 0,
    hypotheticalDebtWhenIpoSource: OpmInputDataSource.FromPWERM,
    ipoCostsOfEv: 0,
    ipoCostsOfEvSource: OpmInputDataSource.FromPWERM,
    ipoDiscount: 0,
    ipoDiscountSource: OpmInputDataSource.FromPWERM,
    ipoProbability: 0,
    ipoProbabilitySource: OpmInputDataSource.FromPWERM,
    postIpoSaleDlom: 0,
    postIpoSaleDlomSource: OpmInputDataSource.FromPWERM,
    secondarySaleCostsOfEv: 0,
    secondarySaleCostsOfEvSource: OpmInputDataSource.FromPWERM,
    netDebtItems: [],
    marketValueDloms: [],
    cashValue: 0,
    totalNetDebt: 0,
    totalNetDebtSource: OpmInputDataSource.FromPWERM,
    collaring: { inputMode: CollaringMode.Off, percentage: null, fixedValue: null },
    operationalFreeCashFlow: 0,
    operationalFreeCashFlowSource: OpmInputDataSource.FromPWERM,
    operationalEv: 0,
    operationalEvSource: OpmInputDataSource.FromPWERM,
  };
}
