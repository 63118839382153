import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { usePwcNavigate } from '@app/core/hooks/routing-hooks';
import PwcNavigate from '@app/shared/routing/PwcNavigate';
import { FC } from 'react';
import { RouteConstants } from '../../RouteConstants';
import { cloneDeep, instrumentByTypeOrTranchesOrDefaultSortFn } from '@app/shared/helpers';

export const CostOfEquity2Page: FC = (): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const storedCostOfEquityView = useAppSelector(
    (state) => state.uiValues.userSelections.costOfEquity.selectedTab
  );
  const { generatePath } = usePwcNavigate();

  const instrumentTabs = cloneDeep(project.equityInstruments)
    .sort(instrumentByTypeOrTranchesOrDefaultSortFn)
    .filter((instrument) => instrument.shouldBeValued);

  const isStoredCostOfEquityViewInInstruments = instrumentTabs.some(
    (instrument) => instrument.instrumentId === storedCostOfEquityView
  );

  if (
    !storedCostOfEquityView ||
    (!isStoredCostOfEquityViewInInstruments &&
      storedCostOfEquityView !== RouteConstants.TotalEquityCrossCheck)
  ) {
    return <PwcNavigate route={generatePath(RouteConstants.Summary2)} />;
  } else if (
    storedCostOfEquityView &&
    storedCostOfEquityView === RouteConstants.TotalEquityCrossCheck
  ) {
    return <PwcNavigate route={generatePath(RouteConstants.TotalEquityCrossCheck2)} />;
  }

  return (
    <PwcNavigate
      route={generatePath(RouteConstants.InstrumentById2, {
        instrumentId: storedCostOfEquityView,
      })}
    />
  );
};
