import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { currencyDenominationPair } from '@app/shared/helpers';
import { FC } from 'react';
import styles from './currency-label.module.scss';
import { useLocale } from '@app/core/hooks/useLocale';

interface CurrencyLabelProps {
  isChartScaled?: boolean;
}

export const PARENT_CLASSNAME = 'currency-label';
const CurrencyLabel: FC<CurrencyLabelProps> = ({ isChartScaled = false }): JSX.Element => {
  const {
    details: { currency, denomination },
  } = useAppSelector((state) => state.project.projectDraft);
  const { l } = useLocale();

  const label = isChartScaled
    ? l('_AllFiguresShownInValueExcludingCharts', {
        value: currencyDenominationPair(currency, denomination),
        interpolation: { escapeValue: false },
      })
    : l('_AllFiguresShownInValue', {
        value: currencyDenominationPair(currency, denomination),
        interpolation: { escapeValue: false },
      });
  return <span className={styles[`${PARENT_CLASSNAME}`]}>{label}</span>;
};

export default CurrencyLabel;
