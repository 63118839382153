import { SVGProps } from 'react';
const SvgErrorStatusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M11 22c6.076 0 11-4.924 11-11S17.076 0 11 0 0 4.924 0 11s4.924 11 11 11Zm0-16.5c.572 0 1.031.46 1.031 1.031v4.813c0 .571-.46 1.031-1.031 1.031-.572 0-1.031-.46-1.031-1.031V6.53c0-.571.46-1.031 1.031-1.031Zm1.375 9.625c0 .76-.614 1.375-1.375 1.375-.76 0-1.375-.614-1.375-1.375 0-.76.614-1.375 1.375-1.375.76 0 1.375.614 1.375 1.375Z"
      fill="#B53927"
    />
  </svg>
);
export default SvgErrorStatusIcon;
