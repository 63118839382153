import React, { useCallback, useEffect } from 'react';
import { useBeforeUnload, useBlocker, Blocker, BlockerFunction } from 'react-router-dom';
import { Location } from '@remix-run/router/history';

declare interface InitialStateType {
  isActive: boolean;
  location?: Location;

  onConfirm(): void;

  resetConfirmation(): void;
}

function usePrompt(when: boolean | BlockerFunction, bypassUrls?: string[]): Blocker {
  const blocker = useBlocker(when);
  useEffect(() => {
    if (blocker.state === 'blocked' && blocker.location.pathname.startsWith('/projects/')) {
      blocker.proceed();
    }
  }, [blocker, bypassUrls]);
  useEffect(() => {
    // Reset if when is updated to false
    if (blocker.state === 'blocked' && !when) {
      blocker.reset();
    }
  }, [blocker, when, bypassUrls]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (when) {
          event.preventDefault();
          // eslint-disable-next-line no-param-reassign
          event.returnValue = 'Changes that you made may not be saved.';
        }
      },
      [when]
    ),
    { capture: true }
  );
  return blocker;
}

const useConfirm = (when: boolean | BlockerFunction, bypassUrls?: string[]): InitialStateType => {
  const blocker = usePrompt(when, bypassUrls);
  const resetConfirmation = () => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
  };

  const onConfirm = () => {
    if (blocker.state === 'blocked') {
      blocker.proceed();
    }
  };

  return {
    isActive: blocker.state === 'blocked',
    location: blocker.location,
    onConfirm,
    resetConfirmation,
  };
};

type ReactRouterPromptProps = {
  when: boolean | BlockerFunction;
  bypassUrls?: string[];
  children: (data: {
    isActive: boolean;
    location: Location | undefined;
    onCancel(): void;
    onConfirm(): void;
  }) => React.ReactNode;
};

function ReactRouterPrompt({ when, children, bypassUrls }: ReactRouterPromptProps) {
  const { isActive, location, onConfirm, resetConfirmation } = useConfirm(when, bypassUrls);

  if (isActive) {
    return (
      <div>
        {children({
          isActive: true,
          location: location,
          onConfirm,
          onCancel: resetConfirmation,
        })}
      </div>
    );
  }
  return null;
}

export default ReactRouterPrompt;
