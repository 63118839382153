import { FC, useEffect, useState } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { CardCornersVariation, CardVariation } from '@app/shared/components/card/card-enums';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { shortDateViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { Role } from '@app/shared/models/contracts/enums/shared-enums';
import { DropdownMenu } from '@app/shared/components/dropdown/DropdownMenu';
import { DeleteUserAction } from './delete/DeleteUserAction';
import SvgThreeDots from '@app/shared/icons/ThreeDots';
import { moveCurrentUserToTheTopOfTheList } from '@app/shared/helpers/sort/move-current-user-to-the-top';
import { EditUserAction } from '@app/modules/users/user-list/edit/EditUserAction';
import { featureAvailability } from '@core/utils/feature-availability';
import { RootAppRoutes } from '@app/shared/enums/root-app-routes';
import { usePwcNavigate } from '@core/hooks/routing-hooks';
import store from '@core/store/store';
import * as userListActions from '@core/store/user-list-slice';
import TablePagination from '@app/shared/components/table/TablePagination';
import { usePaginationHelper } from '@app/core/hooks/customUsePaginationHelper';
import { PagedResult } from '@app/shared/models/pagination';
import { UserSummaryDto } from '@app/shared/models/contracts/user-sumarry-dto';
import { MAX_ITEMS_PER_LIST } from '@app/shared/constants/configs/pagination';

export const UserList: FC = (): JSX.Element => {
  const { userInfo } = useAppSelector((state) => state.userInfo);
  const userList = useAppSelector((state) => state.userList.values);
  const [pagedUserList, setPagedUserList] = useState<PagedResult<UserSummaryDto>>({
    data: [],
    metadata: { count: 0, total: 0, offset: 0, limit: 0 },
  });

  const { navigate } = usePwcNavigate();
  if (!featureAvailability(['Administrator'], userInfo)) {
    navigate(RootAppRoutes.Forbidden);
  }

  const { searchParams, checkIfItemsAreOutOfRange, initializePagination, getPagedRequestParams } =
    usePaginationHelper();

  const generatePagedUserList = (offset: number, limit: number) => {
    const userListSlice = userList.slice(offset, offset + limit);
    return {
      data: userListSlice,
      metadata: {
        count: userListSlice.length,
        total: userList.length,
        offset: offset,
        limit: limit,
      },
    };
  };

  useEffect(() => {
    initializePagination();
    store.dispatch(userListActions.fetchUserList());
    setPagedUserList(generatePagedUserList(0, MAX_ITEMS_PER_LIST));
    // hook is needed only on initial render
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const { offset, limit } = getPagedRequestParams(pagedUserList);
    setPagedUserList(generatePagedUserList(offset, limit));
    checkIfItemsAreOutOfRange(pagedUserList);
  }, [searchParams, userList]); // eslint-disable-line react-hooks/exhaustive-deps

  const sortedArray = pagedUserList.data
    ? moveCurrentUserToTheTopOfTheList(pagedUserList.data, userInfo.email)
    : [];

  return (
    <Card
      hasShadow
      cornersVariation={CardCornersVariation.Secondary}
      variation={CardVariation.TertiarySpacing}>
      <table className="table-primary table-primary--fixed table-primary--secondary-theme table-primary--list">
        <colgroup>
          <col />
          <col className="table-primary__col--additional-data" />
          <col className="table-primary__col--additional-data" />
          <col className="table-primary__col--additional-data" />
          <col className="table-primary__col--action" />
        </colgroup>
        <thead>
          <tr>
            <th>
              <span className="heading-3 heading--table-header">Email address</span>
            </th>
            <th>
              <span className="heading-3 heading--table-header">Date added</span>
            </th>
            <th>
              <span className="heading-3 heading--table-header">Last signed in</span>
            </th>
            <th>
              <span className="heading-3 heading--table-header">User role</span>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {sortedArray.map((user, index) => (
            <tr key={index}>
              {user && user.createdAt && (
                <>
                  <td title={user.email} className="table-primary__cell--truncate">
                    {user.email}
                    {userInfo.email === user.email && (
                      <span className="secondary-text"> (you)</span>
                    )}
                  </td>
                  <td>
                    <CellValue
                      value={user.createdAt}
                      {...shortDateViewRules}
                      alignment={Alignment.Left}
                    />
                  </td>
                  <td>
                    <CellValue
                      value={user.lastSignedInAt}
                      {...shortDateViewRules}
                      alignment={Alignment.Left}
                    />
                  </td>
                  <td>
                    {/* @ts-ignore: temporary ignore */}
                    <CellValue value={Role[user.role]} alignment={Alignment.Left} />
                  </td>
                  <td
                    onClick={(event) => event.stopPropagation()}
                    className="table-primary__actions">
                    {userInfo.email !== user.email && (
                      <DropdownMenu
                        data-testid={`${user.email}-actions-dropdown`}
                        dropdownTriggerIcon={<SvgThreeDots />}>
                        <li>
                          <EditUserAction email={user.email} role={user.role} />
                        </li>
                        <li>
                          <DeleteUserAction email={user.email} />
                        </li>
                      </DropdownMenu>
                    )}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {pagedUserList.metadata && <TablePagination paginationInfo={pagedUserList.metadata} />}
    </Card>
  );
};
