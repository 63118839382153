import store from '@app/core/store/store';
import { datadogRum } from '@datadog/browser-rum';

export class DatadogClient {
  private static isInitialized = false;

  static initialize() {
    const uiState = store.getState().uiValues;
    const userInfo = store.getState().userInfo.userInfo;
    if (uiState.datadogApplicationId && uiState.datadogClientToken && !this.isInitialized) {
      datadogRum.setUser({
        id: userInfo.email,
        email: userInfo.email,
        name: userInfo.fullUserName,
      });
      datadogRum.init({
        applicationId: uiState.datadogApplicationId,
        clientToken: uiState.datadogClientToken,
        site: 'datadoghq.eu',
        service: 'equity-reward-analytics-project-bumblebee_uk',
        env: uiState.datadogEnvironment,
        version: uiState.versionTag
          ? uiState.versionTag
          : uiState.branchName
          ? uiState.branchName
          : uiState.commitId,
        sessionSampleRate: uiState.sessionSampleRate,
        sessionReplaySampleRate: uiState.sessionReplaySampleRate,
        trackUserInteractions: false,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });
      this.isInitialized = true;
    }
  }
}
