export const calculateYearFrac360 = (startDate: Date, endDate: Date) => {
  let startDay = startDate.getDate();
  const startMonth = startDate.getMonth();
  const startYear = startDate.getFullYear();

  let endDay = endDate.getDate();
  let endMonth = endDate.getMonth();
  let endYear = endDate.getFullYear();

  if (startDay === 31 || (startMonth === 2 && startDay >= 28)) {
    startDay = 30;
  }

  if (endDay === 31 && startDay < 30) {
    endDay = 1;
    endMonth = endMonth === 12 ? 1 : endMonth + 1;
    endYear = endMonth === 1 ? endYear + 1 : endYear;
  } else if (endDay === 31) {
    endDay = 30;
  }

  return ((endYear - startYear) * 360 + (endMonth - startMonth) * 30 + (endDay - startDay)) / 360;
};
