import { TypographyOptions } from '@mui/material/styles/createTypography';
import styleVariables from '@styles/core/_exported-variables.module.scss';

export const typographySize = {
  primary: styleVariables.fontSizePrimary,
  secondary: styleVariables.fontSizeSecondary,
  large: styleVariables.fontSizeLarge,
  xLarge: styleVariables.fontSizeXLarge,
  medium: styleVariables.fontSizeMedium,
  small: styleVariables.fontSizeSmall,
  xSmall: styleVariables.fontSizeXSmall,
};

export const typographyWeight = {
  light: styleVariables.fontWeightLight,
  regular: styleVariables.fontWeightRegular,
  medium: styleVariables.fontWeightMedium,
  bold: styleVariables.fontWeightBold,
};

export const typographyFont = {
  helvetica: styleVariables.fontFamilyHelvetica,
  georgia: styleVariables.fontFamilyGeorgia,
};

const typography: TypographyOptions = {
  fontFamily: typographyFont.helvetica,
  htmlFontSize: 10,
};

export default typography;
