import api from '@server/api-config';
import env from '@environment';
import {
  OpmCalculationResultsDto,
  OpmSampleWaterfallDto,
} from '@app/shared/models/contracts/opm-calculation-results-dto';
import * as UiActions from '@app/core/store/ui-values-slice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from './store';
import { CollaringDto, ProjectDto } from '@app/shared/models/contracts/project-dto';
import { updateProjectDraft } from './project-slice';
import { dateFormatter } from '@app/shared/formatters';
import { CollaringMode } from '@app/shared/models/contracts/enums/shared-enums';
import {
  CalculationResultsDto,
  ProjectResultType,
} from '@app/shared/models/contracts/calculation-results-dto';
import { runOpmCalculation } from '../signalr/signalRHubConnection';
import { WithValidationResult } from '@app/shared/interfaces/with-validation-result';

export interface OpmCalculationState {
  riskFreeValues: OpmCalculationResultsDto;
  realWorldValues: OpmCalculationResultsDto;
}

interface OpmRiskFreeCollaringInputs extends CollaringDto {
  exitDate: Nullable<string>;
}

export enum OpmCalculationType {
  RiskFree = 0,
  RealWorld = 1,
}

const getRandomInt = (max: number) => {
  // eslint-disable-next-line big-number-rules/rounding
  return Math.floor(Math.random() * max);
};

export const loadOpmResults = createAsyncThunk<
  {
    riskFreeCalculations: OpmCalculationResultsDto;
    realWorldCalculation: OpmCalculationResultsDto;
  },
  CalculationResultsDto,
  { state: RootState; dispatch: AppDispatch }
>('loadOpmResults', async (calculationResults, _) => {
  const resultsRiskFree =
    ProjectResultType.OpmRiskFree in calculationResults
      ? calculationResults[ProjectResultType.OpmRiskFree]
      : ({} as OpmCalculationResultsDto);

  const resultsRealWorld =
    ProjectResultType.OpmRealWorld in calculationResults
      ? calculationResults[ProjectResultType.OpmRealWorld]
      : ({} as OpmCalculationResultsDto);

  return {
    riskFreeCalculations: resultsRiskFree,
    realWorldCalculation: resultsRealWorld,
  };
});

export const processOpmRealWorldResults = createAsyncThunk<
  { calculations: OpmCalculationResultsDto },
  OpmCalculationResultsDto,
  { state: RootState; dispatch: AppDispatch }
>('processOpmRealWorldResults', (results, thunkAPI) => {
  const projectId = thunkAPI.getState().project.projectDraft.id;
  const userId = thunkAPI.getState().userInfo.userInfo.id;
  const project: ProjectDto = thunkAPI.getState().project.projectDraft;
  const riskFreeCollaring: (OpmRiskFreeCollaringInputs | null)[] | null = results?.collaringResults
    ?.length
    ? results.collaringResults.map((collaringResult) => {
        return collaringResult.effectivePercentage > 0
          ? {
              inputMode: CollaringMode.Percentage,
              fixedValue: null,
              // eslint-disable-next-line big-number-rules/rounding
              percentage: Math.round(collaringResult.effectivePercentage * 100 * 1000) / 1000,
              exitDate: collaringResult.exitDate,
            }
          : null;
      })
    : null;

  const projectWithRiskFreeCollaring = riskFreeCollaring
    ? {
        ...project,
        opmInput: {
          ...project.opmInput,
          perYearInputs: project.opmInput.perYearInputs.map((perYearInput) => {
            const matchingCollaringResult = riskFreeCollaring.find((collaringResult) => {
              return collaringResult?.exitDate === perYearInput.forecastDate;
            });

            return {
              ...perYearInput,
              collaring: matchingCollaringResult
                ? matchingCollaringResult
                : {
                    inputMode: CollaringMode.Off,
                    fixedValue: null,
                    percentage: null,
                  },
            };
          }),
        },
      }
    : { ...project };

  runOpmCalculation(projectId, userId, projectWithRiskFreeCollaring, OpmCalculationType.RiskFree);
  return { calculations: results };
});

export const processOpmRiskFreeResults = createAsyncThunk<
  {
    calculations: OpmCalculationResultsDto;
  },
  OpmCalculationResultsDto,
  { state: RootState; dispatch: AppDispatch }
>('processOpmRiskFreeResults', async (results, thunkAPI) => {
  thunkAPI.dispatch(UiActions.setDisplayOperationProgress(false));
  return { calculations: results };
});

export const calculateOpm = createAsyncThunk<
  void,
  boolean | undefined,
  { state: RootState; dispatch: AppDispatch }
>('calculateOpm', async (freezeRandomSeed = false, thunkAPI) => {
  const projectId = thunkAPI.getState().project.projectDraft.id;
  const userId = thunkAPI.getState().userInfo.userInfo.id;
  const inputDraft: ProjectDto = thunkAPI.getState().project.projectDraft;
  const randomiserActive = inputDraft.opmInput?.randomiserActive ?? false;
  const randomSeed =
    randomiserActive && !freezeRandomSeed
      ? getRandomInt(10000)
      : inputDraft.opmInput?.randomSeed ?? getRandomInt(10000);

  const project = {
    ...inputDraft,
    opmInput: {
      ...inputDraft.opmInput,
      randomSeed: randomSeed,
      randomiserActive: randomiserActive,
      forecastYears: Number(inputDraft.opmInput.forecastYears),
    },
    valuationDateNarrative:
      inputDraft.opmInput.valuationDateNarrative === ''
        ? dateFormatter(inputDraft.valuationDate.toString(), { dateStyle: 'short' })
        : inputDraft.opmInput.valuationDateNarrative,
  };

  thunkAPI.dispatch(updateProjectDraft({ project }));

  if (project?.opmInput?.realWorldRate !== null) {
    runOpmCalculation(projectId, userId, project, OpmCalculationType.RealWorld);
  } else {
    thunkAPI.dispatch(clearRealWorldResults());
    runOpmCalculation(projectId, userId, project, OpmCalculationType.RiskFree);
  }
});

export const calculateOpmSampleWaterfall = createAsyncThunk<
  {
    calculateOpmSampleWaterfall: OpmSampleWaterfallDto;
  },
  { yearDate: string; instrumentId: string; enterpriseValue: string },
  { state: RootState; dispatch: AppDispatch }
>('calculateOpmSampleWaterfall', async (arg, thunkAPI) => {
  const { yearDate, instrumentId, enterpriseValue } = arg;

  const payload = {
    yearDate: yearDate,
    instrumentId: instrumentId,
    enterpriseValue: parseFloat(enterpriseValue),
    project: thunkAPI.getState().project.projectDraft,
  };

  if (enterpriseValue === null) {
    return { calculateOpmSampleWaterfall: {} as OpmSampleWaterfallDto };
  }

  const results = await api.post<WithValidationResult<OpmSampleWaterfallDto>>(
    `${env.apiUrl}/calculate/exit`,
    payload
  );

  return { calculateOpmSampleWaterfall: results.data.result };
});

export const opmCalculationSlice = createSlice({
  name: 'opmCalculation',
  initialState: {
    riskFreeValues: {} as OpmCalculationResultsDto,
    realWorldValues: {} as OpmCalculationResultsDto,
  } as OpmCalculationState,
  reducers: {
    resetOpmResults: (state) => {
      state.riskFreeValues = {} as OpmCalculationResultsDto;
      state.realWorldValues = {} as OpmCalculationResultsDto;
    },
    clearOpmSampleWaterfallResults: (state) => {
      delete state.riskFreeValues.sampleWaterfall;
    },
    clearRealWorldResults: (state) => {
      state.realWorldValues = {} as OpmCalculationResultsDto;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadOpmResults.fulfilled, (state, action) => {
      state.riskFreeValues = {
        ...(state.riskFreeValues.sampleWaterfall && {
          sampleWaterfall: state.riskFreeValues.sampleWaterfall,
        }),
        ...action.payload.riskFreeCalculations,
      };
      state.realWorldValues = {
        ...action.payload.realWorldCalculation,
      };
    });
    builder.addCase(calculateOpmSampleWaterfall.fulfilled, (state, action) => {
      state.riskFreeValues.sampleWaterfall = {
        ...action.payload.calculateOpmSampleWaterfall,
      };
    });
    builder.addCase(processOpmRiskFreeResults.fulfilled, (state, action) => {
      state.riskFreeValues = { ...action.payload.calculations };
    });
    builder.addCase(processOpmRealWorldResults.fulfilled, (state, action) => {
      state.realWorldValues = { ...action.payload.calculations };
    });
  },
});

export const { clearOpmSampleWaterfallResults, clearRealWorldResults } =
  opmCalculationSlice.actions;

export default opmCalculationSlice.reducer;
