import { FC, useEffect } from 'react';
import { StatusPage } from './StatusPage';
import NotFoundContent from '@app/shared/components/status-pages/status-contents/not-found/NotFoundContent';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootAppRoutes } from '@app/shared/enums/root-app-routes';

const NotFoundPage: FC = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname !== `/${RootAppRoutes.NotFound}`) {
      navigate(RootAppRoutes.NotFound);
    }
  }, [location.pathname, navigate]);

  return (
    <StatusPage>
      <NotFoundContent />
    </StatusPage>
  );
};

export default NotFoundPage;
