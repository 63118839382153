import { FC, InputHTMLAttributes } from 'react';
import styles from './field-radio.module.scss';
import { useController } from 'react-hook-form';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';

const PARENT_CLASSNAME = 'field-radio';

export interface FieldRadioOption {
  viewValue?: number | string;
  value?: number | string;
}

interface FieldRadioProps extends InputHTMLAttributes<HTMLInputElement> {
  option: FieldRadioOption;
  name: string;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
}

const FieldRadio: FC<FieldRadioProps> = ({
  option,
  name,
  onChange,
  ignoreReadOnly = false,
  disabled = false,
  ...rest
}) => {
  const {
    field: { value: fieldValue, onChange: fieldOnChange },
  } = useController({ name });

  const isReadOnly = useIsReadOnly();
  return (
    <fieldset className={styles[`${PARENT_CLASSNAME}`]}>
      <input
        type="radio"
        id={option.value ? option.value?.toString() : 'radioField' + name}
        name={name}
        checked={option.value === fieldValue}
        onChange={(event) => {
          fieldOnChange(event);
          if (onChange) {
            onChange(event);
          }
        }}
        {...rest}
        className={styles[`${PARENT_CLASSNAME}__input`]}
        value={option.value}
        disabled={disabled || (!ignoreReadOnly && isReadOnly)}
      />
      {option.viewValue && (
        <label
          className={styles[`${PARENT_CLASSNAME}__item`]}
          htmlFor={option.value ? option.value?.toString() : 'radioField' + name}>
          {option.viewValue}
        </label>
      )}
    </fieldset>
  );
};

export default FieldRadio;
