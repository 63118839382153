import { v4 as uuidv4 } from 'uuid';
import { enumKeyByValue } from '@app/shared/helpers/enum-key-by-value';
import {
  Else,
  Operator,
  PayoutDriver,
  PayoutValue,
  TargetMetric,
} from '@app/shared/models/contracts/enums/shared-enums';
import { PayoutLogicDto, TrancheDto } from '@app/shared/models/contracts/project-dto';

export const DEFAULT_TRANCHE_CONDITION_TEST_VALUE = 0;
export const DEFAULT_TRANCHE_PAYOUT_CALCULATION_VALUE = enumKeyByValue(
  PayoutValue,
  PayoutValue.Shareholding
);
export const DEFAULT_TRANCHE_PAYOUT_CALCULATION_CUSTOM_VALUE = 0;

export const createTrancheItem = (order = 0, instrumentId?: string): TrancheDto => {
  return {
    id: instrumentId ?? uuidv4(),
    order: order,
    statements: [
      {
        order: 0,
        conditions: [
          {
            targetMetric: enumKeyByValue(TargetMetric, TargetMetric.TotalOrdinaryEquity),
            operator: enumKeyByValue(Operator, Operator.GreaterThanOrEqual),
            targetMetricTestValue: DEFAULT_TRANCHE_CONDITION_TEST_VALUE,
          },
        ],
        conjunction: undefined,
        payoutCalculation: {
          payoutDriver: enumKeyByValue(PayoutDriver, PayoutDriver.UpAndIn),
          payoutValue: DEFAULT_TRANCHE_PAYOUT_CALCULATION_VALUE,
        },
      },
    ],
    else: enumKeyByValue(Else, Else.Zero),
  };
};

export const createPayoutLogicItem = (order = 0, instrumentId?: string): PayoutLogicDto => {
  return {
    id: instrumentId ?? uuidv4(),
    order: order,
    conditions: [
      {
        targetMetric: enumKeyByValue(TargetMetric, TargetMetric.TotalOrdinaryEquity),
        operator: enumKeyByValue(Operator, Operator.GreaterThanOrEqual),
        targetMetricTestValue: DEFAULT_TRANCHE_CONDITION_TEST_VALUE,
      },
    ],
    conjunction: undefined,
    payoutCalculation: {
      payoutDriver: enumKeyByValue(PayoutDriver, PayoutDriver.UpAndIn),
      payoutValue: DEFAULT_TRANCHE_PAYOUT_CALCULATION_VALUE,
    },
    else: enumKeyByValue(Else, Else.Zero),
  };
};
