import { SVGProps } from 'react';
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M2.88 10.256L11.136 1.97598C11.28 1.83198 11.352 1.65598 11.352 1.44798C11.352 1.23998 11.28 1.06398 11.136 0.91998C10.992 0.77598 10.816 0.703979 10.608 0.703979C10.4 0.703979 10.224 0.77598 10.08 0.91998L0 11L10.08 21.08C10.224 21.224 10.4 21.296 10.608 21.296C10.816 21.296 10.992 21.224 11.136 21.08C11.28 20.936 11.352 20.76 11.352 20.552C11.352 20.344 11.28 20.168 11.136 20.024L2.88 11.744H23.256C23.464 11.744 23.64 11.672 23.784 11.528C23.928 11.384 24 11.208 24 11C24 10.792 23.928 10.616 23.784 10.472C23.64 10.328 23.464 10.256 23.256 10.256H2.88Z"
      fill="#D04A02"
    />
  </svg>
);
export default SvgArrowLeft;
