import { FC, Fragment, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@core/hooks/redux-hooks';
import { selectCasesStructure } from '@core/store/project-slice-selectors';
import classNames from 'classnames';
import styles from '@app/modules/projects/outputs/outputs-table.module.scss';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { monthDayCellViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { InstrumentType } from '@app/shared/models/contracts/enums/shared-enums';
import {
  cloneDeep,
  enumKeyByValue,
  equityInstrumentsSortWithTranchesFn,
  sumBy,
} from '@app/shared/helpers';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import {
  getDlomIndexByInstrumentId,
  getDlomIndexByTrancheId,
} from '@app/shared/helpers/equity-instruments/get-equity-instrument-index';
import * as projectActions from '@core/store/project-slice';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import {
  TABLE_COL_WIDTH_ALTERNATIVE,
  TABLE_LABELS_COL_WIDTH_ALTERNATIVE,
} from '@app/shared/constants/table-config';

const MinorityDiscountsTable: FC = () => {
  const projectDraft = useAppSelector((state) => state.project.projectDraft);

  const casesStructure = useAppSelector(selectCasesStructure);
  casesStructure.sort((a, b) => a.caseOrder! - b.caseOrder!);

  const dispatch = useAppDispatch();
  const filteredInstruments = projectDraft.equityInstruments
    .filter((instrument) => instrument.shouldBeValued)
    .sort(equityInstrumentsSortWithTranchesFn);
  const columnsCount =
    sumBy(projectDraft.pwermInput.cases, (caseItem) => caseItem.forecasts.length) + 1;

  const formMethods = useForm({
    ...formConfigBase,
    defaultValues: { cases: projectDraft.pwermInput.cases },
  });

  const { trigger } = formMethods;

  useEffect(() => {
    trigger();
  }, [trigger]);

  const formSubmitHandler = () => {
    const updatedProjectDraft = {
      ...projectDraft,
      pwermInput: {
        ...projectDraft.pwermInput,
        ...cloneDeep(formMethods.getValues()),
      },
    };
    dispatch(projectActions.updateProjectDraft({ project: updatedProjectDraft }));
  };

  const findSortedCaseIndex = (caseId: string) => {
    return projectDraft.pwermInput.cases.findIndex((item) => item.caseId === caseId);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        data-cy="dlom-form"
        onBlur={formMethods.handleSubmit(
          formSubmitHandler as SubmitHandler<{ cases: CaseDto[] }>,
          formSubmitHandler as SubmitErrorHandler<{ cases: CaseDto[] }>
        )}>
        <table
          className="table-primary table-primary--zebra table-primary--fixed table-primary--framed"
          style={{
            width: columnsCount
              ? columnsCount * TABLE_COL_WIDTH_ALTERNATIVE +
                TABLE_LABELS_COL_WIDTH_ALTERNATIVE +
                'px'
              : undefined,
          }}>
          <colgroup>
            <col style={{ width: `${TABLE_LABELS_COL_WIDTH_ALTERNATIVE}px` }} />
            {[...Array(columnsCount - 1)].map((_, index) => (
              <col key={`col-${index}`} style={{ width: `${TABLE_COL_WIDTH_ALTERNATIVE}px` }} />
            ))}
          </colgroup>
          <thead className="table-primary__sticky-section table-primary__sticky-section table-primary__sticky-section--table-single-row-header">
            <tr>
              <th className="table-primary__cell--header-tertiary table-primary__cell--vertical-separator" />
              {casesStructure.map((caseItem, index) => (
                <Fragment key={caseItem.caseId + '-forecast-item-' + index}>
                  <th
                    colSpan={caseItem.forecasts.length}
                    className="table-primary__cell--header-tertiary table-primary__cell--center table-primary__cell--vertical-separator table-primary__cell--group-separator-vertical">
                    <span className={styles['case-name']}>{caseItem.narrative}</span>
                  </th>
                </Fragment>
              ))}
            </tr>

            <tr>
              <th className="table-primary__cell--vertical-separator">Exit date</th>
              {casesStructure.map((caseItem) => {
                return caseItem.forecasts.map((forecast, index) => (
                  <Fragment key={forecast.forecastId + 'forecast-year-value'}>
                    <td
                      className={classNames({
                        'table-primary__cell--vertical-separator':
                          index === caseItem.forecasts.length - 1,
                      })}>
                      <CellValue value={forecast.forecastYear} {...monthDayCellViewRules} />
                    </td>
                  </Fragment>
                ));
              })}
            </tr>
            <tr>
              <th className="table-primary__cell--header" colSpan={columnsCount}>
                DLOM
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredInstruments.map((instrument) => {
              return (
                <Fragment key={`instrument-row-fragment-${instrument.instrumentId}`}>
                  <tr key={'instrument-row-wrapper' + instrument.instrumentId}>
                    <td className="table-primary__cell--vertical-separator">
                      {instrument.instrumentNarrative}
                    </td>
                    {instrument.type !==
                      enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && (
                      <Fragment key={`ordinary-equity-row-${instrument.instrumentId}`}>
                        {casesStructure.map((caseItem) => {
                          return caseItem.forecasts.map((forecast, forecastIndex) => (
                            <td
                              className={classNames({
                                'table-primary__cell--vertical-separator':
                                  caseItem.forecasts.length - 1 === forecastIndex,
                              })}
                              key={forecast.forecastId + 'value'}>
                              <GridTextField
                                name={`cases[${findSortedCaseIndex(
                                  caseItem.caseId
                                )}].forecasts[${forecastIndex}].dlom[${getDlomIndexByInstrumentId(
                                  forecast,
                                  instrument
                                )}].value`}
                                {...percentageFieldFormattingProps}
                              />
                            </td>
                          ));
                        })}
                      </Fragment>
                    )}
                    {instrument.type ===
                      enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) &&
                      casesStructure.map((caseItem) => {
                        return caseItem.forecasts.map((forecast, forecastIndex) => (
                          <td
                            className={classNames({
                              'table-primary__cell--vertical-separator':
                                caseItem.forecasts.length - 1 === forecastIndex,
                            })}
                            key={forecast.forecastId + 'ordinary-equity-value'}>
                            {Boolean(!instrument.tranches?.length) && (
                              <GridTextField
                                name={`cases[${findSortedCaseIndex(
                                  caseItem.caseId
                                )}].forecasts[${forecastIndex}].dlom[${getDlomIndexByInstrumentId(
                                  forecast,
                                  instrument
                                )}].value`}
                                {...percentageFieldFormattingProps}
                              />
                            )}
                          </td>
                        ));
                      })}
                  </tr>
                  {instrument.type ===
                    enumKeyByValue(InstrumentType, InstrumentType.OrdinaryEquity) && (
                    <Fragment key={`ordinary-equity-row-${instrument.instrumentId}`}>
                      {instrument?.tranches?.map((tranche, index) => (
                        <tr key={'instrument-row' + instrument.instrumentId + index}>
                          <Fragment key={tranche.id + 'tranche-row'}>
                            <td className="table-primary__cell--vertical-separator table-primary__cell--indentation">{`Tranche ${
                              index + 1
                            }`}</td>
                            {casesStructure.map((caseItem) => {
                              return caseItem.forecasts.map((forecast, forecastIndex) => (
                                <td
                                  key={forecast.forecastId + 'value'}
                                  className={classNames({
                                    'table-primary__cell--vertical-separator':
                                      caseItem.forecasts.length - 1 === forecastIndex,
                                  })}>
                                  <GridTextField
                                    name={`cases[${findSortedCaseIndex(
                                      caseItem.caseId
                                    )}].forecasts[${forecastIndex}].dlom[${getDlomIndexByTrancheId(
                                      forecast,
                                      tranche
                                    )}].value`}
                                    {...percentageFieldFormattingProps}
                                  />
                                </td>
                              ));
                            })}
                          </Fragment>
                        </tr>
                      ))}
                    </Fragment>
                  )}
                  <tr>
                    <td className="table-primary__cell--vertical-separator" />
                    {casesStructure.map((caseItem) => {
                      return caseItem.forecasts.map((forecast, index) => (
                        <Fragment key={forecast.forecastId + 'forecast-value-secondary'}>
                          <td
                            className={classNames({
                              'table-primary__cell--vertical-separator':
                                index === caseItem.forecasts.length - 1,
                            })}
                          />
                        </Fragment>
                      ));
                    })}
                  </tr>
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </form>
    </FormProvider>
  );
};

export default MinorityDiscountsTable;
