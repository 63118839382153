/* eslint-disable no-console */
import React, { useState } from 'react';
import { diffWords } from 'diff';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { InstrumentLevelData } from '../../cost-of-equity/instrument-level-data/InstrumentLevelData';
import { InstrumentLevelData2 } from '../instrument-level-data/InstrumentLevelData2';

const InstrumentLevelDataComparator: React.FC = () => {
  const [table1Ref, setTable1Ref] = useState<HTMLTableElement | null>(null);
  const [table2Ref, setTable2Ref] = useState<HTMLTableElement | null>(null);

  const getTable1Ref = (ref: HTMLTableElement) => {
    setTable1Ref(ref);
  };

  const getTable2Ref = (ref: HTMLTableElement) => {
    setTable2Ref(ref);
  };

  const compareTables = (withMatches = false) => {
    if (table1Ref && table2Ref) {
      let addedCount = 0;
      let removedCount = 0;
      const html1 = table1Ref.outerHTML;
      const html2 = table2Ref.outerHTML;

      const differences = diffWords(html1, html2);
      differences.forEach((part) => {
        if (part.added) {
          addedCount++;
        } else if (part.removed) {
          removedCount++;
        }

        if (withMatches || part.added || part.removed) {
          const style = part.added
            ? 'color: green;'
            : part.removed
            ? 'color: red;'
            : 'color: grey;';

          console.log('%c%s', style, part.value);
        }
      });
      console.log(`There are ${addedCount} green parts and ${removedCount} red parts.`);
    } else {
      console.log('No tables to compare');
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ marginRight: '50px' }}>
        <InstrumentLevelData getRef={getTable1Ref} />
      </div>
      <div style={{ marginRight: '50px' }}>
        <Button appearance={ButtonAppearance.DEFAULT_SECONDARY} onClick={() => compareTables(true)}>
          <>
            Compare Tables
            <br />
            w/ Matches
          </>
        </Button>
        <br />
        <Button appearance={ButtonAppearance.DEFAULT_SECONDARY} onClick={() => compareTables()}>
          <>
            Compare Tables
            <br />
            w/o Matches
          </>
        </Button>
      </div>
      <div>
        <InstrumentLevelData2 getRef={getTable2Ref} />
      </div>
    </div>
  );
};

export default InstrumentLevelDataComparator;
