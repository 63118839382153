import { FC, InputHTMLAttributes } from 'react';
import { useController } from 'react-hook-form';
import styles from './toggle.module.scss';
import classNames from 'classnames';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  isLabelHidden?: boolean;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
  toggleSize?: ToggleSize;
  forceChecked?: boolean | null;
  labelTextClassName?: string;
}

export enum ToggleSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

const PARENT_CLASSNAME = 'toggle';

export const Toggle: FC<ToggleProps> = ({
  name,
  label,
  isLabelHidden = false,
  onChange,
  ignoreReadOnly = false,
  disabled = false,
  toggleSize = ToggleSize.Large,
  className,
  forceChecked = null,
  labelTextClassName,
  ...rest
}) => {
  const {
    field,
    field: { onChange: fieldOnChange },
  } = useController({ name });

  const isReadOnly = useIsReadOnly();

  const isChecked = forceChecked !== null ? forceChecked : field.value;

  return (
    <label
      className={classNames(styles[PARENT_CLASSNAME], className, {
        [styles[`${PARENT_CLASSNAME}--small`]]: toggleSize === ToggleSize.Small,
        [styles[`${PARENT_CLASSNAME}--medium`]]: toggleSize === ToggleSize.Medium,
      })}>
      {field.value !== null && (
        <input
          {...rest}
          type="checkbox"
          checked={isChecked ?? false}
          {...field}
          onChange={(e) => {
            fieldOnChange(e);

            if (onChange) {
              onChange(e);
            }
          }}
          className={styles[`${PARENT_CLASSNAME}__input`]}
          disabled={disabled || (!ignoreReadOnly && isReadOnly)}
        />
      )}
      <span className={styles[`${PARENT_CLASSNAME}__slider`]} />
      <span
        className={classNames(styles[`${PARENT_CLASSNAME}__label`], labelTextClassName, {
          [styles[`${PARENT_CLASSNAME}__label--hidden`]]: isLabelHidden,
        })}>
        {label}
      </span>
    </label>
  );
};
