import { SVGProps } from 'react';
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="M5.143.5c.711 0 1.286.559 1.286 1.25V3h5.142V1.75c0-.691.575-1.25 1.286-1.25.711 0 1.286.559 1.286 1.25V3h1.928C17.136 3 18 3.84 18 4.875V6.75H0V4.875C0 3.84.864 3 1.929 3h1.928V1.75c0-.691.575-1.25 1.286-1.25ZM0 8h18v10.625c0 1.035-.864 1.875-1.929 1.875H1.93C.864 20.5 0 19.66 0 18.625V8Zm3.214 2.5a.636.636 0 0 0-.643.625v3.75c0 .344.29.625.643.625h3.857a.636.636 0 0 0 .643-.625v-3.75a.636.636 0 0 0-.643-.625H3.214Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgCalendar;
