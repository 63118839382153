import { Components, Theme } from '@mui/material';

export enum SvgIconSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  visual = 'visual',
}

export enum SvgIconFontSize {
  small = '1.6rem',
  medium = '2.4rem',
  large = '2.8rem',
  visual = '15rem',
}

const svgIconComponent: Components<Theme> = {
  MuiSvgIcon: {
    defaultProps: {
      fill: 'currentColor',
      stroke: 'currentColor',
    },
    styleOverrides: {
      root: {
        width: 'auto',
        '&.MuiSvgIcon-fontSizeSmall': {
          fontSize: SvgIconFontSize.small,
        },
        '&.MuiSvgIcon-fontSizeMedium': {
          fontSize: SvgIconFontSize.medium,
        },
        '&.MuiSvgIcon-fontSizeLarge': {
          fontSize: SvgIconFontSize.large,
        },
        '&.MuiSvgIcon-fontSizeVisual': {
          fontSize: SvgIconFontSize.visual,
        },
      },
    },
  },
};

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    visual: true;
  }
}

export default svgIconComponent;
