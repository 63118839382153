import { useState } from 'react';

import styles from './step-selector.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { useLocale } from '@app/core/hooks/useLocale';

interface StepSelectorProps {
  autoFillClick: (step: number) => void;
}

export const StepSelector = (props: StepSelectorProps) => {
  const { l } = useLocale();
  const [step, setStep] = useState(0.5);

  const handleAutoFillClick = (e: any) => {
    e.preventDefault();
    e.target.blur();
    props.autoFillClick(step);
  };

  const handleStepChange = (e: any) => {
    setStep(Number(e.target.value));
  };

  return (
    <div className={styles['container']}>
      <div className={styles['label']}>{l('_Step')}:</div>
      <select
        className={styles['select']}
        value={step}
        onChange={handleStepChange}
        aria-label={l('_SelectorAriaLabel', { label: l('_Step') })}>
        <option value={0.25}>0.25</option>
        <option value={0.5}>0.5</option>
        <option value={0.75}>0.75</option>
        <option value={1}>1</option>
        <option value={2}>2</option>
      </select>
      <Button
        size={ButtonSize.SMALL}
        appearance={ButtonAppearance.DEFAULT_PRIMARY}
        onClick={handleAutoFillClick}>
        {l('_AutoFillTitle')}
      </Button>
    </div>
  );
};
