// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleClashingLabels = (xAxis: any) => {
  // setting to any because of the Highcharts typings not recognising the plotLinesAndBands property
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chart = xAxis[0] as any;
  const distanceThreshold = 60;
  const meanLabel = chart.plotLinesAndBands.find((x: { id: string }) => x.id === 'mean')?.label;
  const meanSvgElem = chart.plotLinesAndBands.find((x: { id: string }) => x.id === 'mean')?.svgElem;
  const q1Label = chart.plotLinesAndBands.find(
    (x: { id: string }) => x.id === 'firstQuartile'
  )?.label;
  const q3Label = chart.plotLinesAndBands.find(
    (x: { id: string }) => x.id === 'thirdQuartile'
  )?.label;
  const q1SvgElem = chart.plotLinesAndBands.find(
    (x: { id: string }) => x.id === 'firstQuartile'
  )?.svgElem;
  const q3SvgElem = chart.plotLinesAndBands.find(
    (x: { id: string }) => x.id === 'thirdQuartile'
  )?.svgElem;
  const collaringLowLabel = chart.plotLinesAndBands.find(
    (x: { id: string }) => x.id === 'collaringLow'
  )?.label;
  const collaringHighLabel = chart.plotLinesAndBands.find(
    (x: { id: string }) => x.id === 'collaringHigh'
  )?.label;

  let q1MeanClash = false;
  let q3MeanClash = false;
  let q1Q3Clash = false;
  let q1CollaringLowClash = false;
  let q1CollaringHighClash = false;
  let q3CollaringLowClash = false;
  let q3CollaringHighClash = false;
  let meanCollaringLowClash = false;
  let meanCollaringHighClash = false;

  if (meanLabel && q1Label) {
    const distanceQ1Mean = Math.abs(meanLabel.alignAttr.x - q1Label.alignAttr.x);
    q1MeanClash = distanceQ1Mean < distanceThreshold;
  }

  if (meanLabel && q3Label) {
    const distanceQ3Mean = Math.abs(q3Label.alignAttr.x - meanLabel.alignAttr.x);
    q3MeanClash = distanceQ3Mean < distanceThreshold;
  }

  if (q1Label && q3Label) {
    const distanceQ1Q3 = Math.abs(q1Label.alignAttr.x - q3Label.alignAttr.x);
    q1Q3Clash = distanceQ1Q3 < distanceThreshold;
  }

  if (q1Label && collaringLowLabel && collaringLowLabel.visibility !== 'hidden') {
    const distanceQ1CollaringLow = Math.abs(q1Label.alignAttr.x - collaringLowLabel.alignAttr.x);
    q1CollaringLowClash = distanceQ1CollaringLow < distanceThreshold;
  }

  if (q1Label && collaringHighLabel && collaringHighLabel.visibility !== 'hidden') {
    const distanceQ1CollaringHigh = Math.abs(q1Label.alignAttr.x - collaringHighLabel.alignAttr.x);
    q1CollaringHighClash = distanceQ1CollaringHigh < distanceThreshold;
  }

  if (q3Label && collaringLowLabel && collaringLowLabel.visibility !== 'hidden') {
    const distanceQ3CollaringLow = Math.abs(q3Label.alignAttr.x - collaringLowLabel.alignAttr.x);
    q3CollaringLowClash = distanceQ3CollaringLow < distanceThreshold;
  }

  if (q3Label && collaringHighLabel && collaringHighLabel.visibility !== 'hidden') {
    const distanceQ3CollaringHigh = Math.abs(q3Label.alignAttr.x - collaringHighLabel.alignAttr.x);
    q3CollaringHighClash = distanceQ3CollaringHigh < distanceThreshold;
  }

  if (meanLabel && collaringLowLabel) {
    const distanceMeanCollaringLow = Math.abs(
      meanLabel.alignAttr.x - collaringLowLabel.alignAttr.x
    );
    meanCollaringLowClash = distanceMeanCollaringLow < distanceThreshold;
  }

  if (meanLabel && collaringHighLabel) {
    const distanceMeanCollaringHigh = Math.abs(
      meanLabel.alignAttr.x - collaringHighLabel.alignAttr.x
    );
    meanCollaringHighClash = distanceMeanCollaringHigh < distanceThreshold;
  }

  if (
    q1MeanClash ||
    q3MeanClash ||
    q1Q3Clash ||
    q1CollaringLowClash ||
    q1CollaringHighClash ||
    q3CollaringLowClash ||
    q3CollaringHighClash
  ) {
    q1Label?.hide();
    q1SvgElem?.hide();
    q3Label?.hide();
    q3SvgElem?.hide();
  } else {
    if (q1Label) {
      q1Label?.show();
      q1SvgElem?.show();
    }

    if (q3Label) {
      q3Label?.show();
      q3SvgElem?.show();
    }
  }

  if (meanCollaringLowClash || meanCollaringHighClash) {
    meanLabel?.hide();
    meanSvgElem?.hide();
  } else {
    meanLabel?.show();
    meanSvgElem?.show();
  }

  if (!meanLabel) {
    meanSvgElem?.hide();
  }
};
