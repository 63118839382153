import { FC } from 'react';
import { OpmInputTableHeader } from '@app/modules/projects/inputs/opm-inputs/opm-input-table/OpmInputTableHeader';
import OpmInputTableBody from '@app/modules/projects/inputs/opm-inputs/opm-input-table/OpmInputTableBody';
import styles from './opm-inputs.module.scss';
import TransactionCostSection from './opm-input-table/sections/TransactionCostsSection';
import MinorityDiscountsSection from './opm-input-table/sections/MinorityDiscountsSection';
import ShareholderDebtSection from './opm-input-table/sections/shareholder-debt/ShareholderDebtSection';
import OpmNetDebtSection from './opm-input-table/sections/shareholder-debt/OpmNetDebtSection';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import MemoOperationalFreeCashFlowSection from './opm-input-table/sections/MemoOperationalFreeCashFlowSection';

interface OpmInputTableProps {
  submitData: () => void;
}

export const OpmInputTable: FC<OpmInputTableProps> = ({ submitData }): JSX.Element => {
  const project = useAppSelector((state) => state.project?.projectDraft);

  const netDebtVisible = project.opmInput.opmSimulatedValue === 'EnterpriseValue';

  return (
    <div className={`main-container-padding-remove ${styles[`table`]}`}>
      <table className={`table-primary table-primary--zebra ${styles[`opm-table-inputs`]}`}>
        <OpmInputTableHeader />
        <OpmInputTableBody submitData={submitData} />
        <TransactionCostSection submitData={submitData} />
        {netDebtVisible && <OpmNetDebtSection submitData={submitData} />}
        <ShareholderDebtSection submitData={submitData} />
        <MinorityDiscountsSection submitData={submitData} />
        {netDebtVisible && <MemoOperationalFreeCashFlowSection submitData={submitData} />}
      </table>
    </div>
  );
};
