import { SVGProps } from 'react';
const SvgArrowDown = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <rect opacity={0.6} x={11} y={5} width={2} height={14} rx={1} fill="currentColor" />
    <path
      d="M6.707 11.293a1 1 0 0 0-1.414 1.414l6 6a1 1 0 0 0 1.383.03l6-5.5a1 1 0 0 0-1.352-1.474l-5.294 4.853-5.323-5.323Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgArrowDown;
