import { SVGProps } from 'react';
const SvgEditUser = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="m18.074 13.382 2.475 2.531-4.307 4.405a.615.615 0 0 1-.316.173l-2.455.503a.313.313 0 0 1-.364-.373l.49-2.51a.638.638 0 0 1 .17-.324l4.307-4.405Zm4.565-1.393-.725-.742a1.218 1.218 0 0 0-1.75 0l-1.311 1.341 2.475 2.532 1.311-1.34a1.29 1.29 0 0 0 0-1.79ZM13.38 21c.02 0 .04-.002.061-.006-.04.008-.078 0-.115-.007.047.002.064.013.053.013ZM4.3 7.5C4.3 5.015 6.27 3 8.67 3c2.398 0 4.4 2.015 4.4 4.5S11.098 12 8.67 12C6.24 12 4.3 9.986 4.3 7.5Zm1.65 0c0 1.55 1.234 2.813 2.75 2.813S11.45 9.05 11.45 7.5c0-1.55-1.234-2.813-2.75-2.813S5.95 5.95 5.95 7.5Zm6.26 11.813H2.684c.272-2.219 2.125-3.938 4.366-3.938h3.3c1.167 0 2.223.474 3.011 1.235l1.172-1.198a5.948 5.948 0 0 0-4.183-1.725h-3.3c-3.341 0-6.05 2.77-6.05 6.188C1 20.496 1.493 21 2.1 21h9.938a1.47 1.47 0 0 1-.01-.6l.182-1.087Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgEditUser;
