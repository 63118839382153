import envConfig, { EnvironmentConfig } from './environment-config';

declare global {
  interface Window {
    _env: {
      current:
        | Environment.Local
        | Environment.Development
        | Environment.Test
        | Environment.UAT
        | Environment.Staging
        | Environment.Production
        | Environment.Dem;
    } & Partial<Partial<EnvironmentConfig>>;
  }
}

export enum Environment {
  Local = 'local',
  Development = 'dev',
  Test = 'test',
  E2E = 'e2e',
  UAT = 'uat',
  Staging = 'staging',
  Production = 'production',
  Dem = 'dem',
}

export default { ...envConfig[window._env.current], ...window._env };
