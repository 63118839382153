import { SVGProps } from 'react';
const SvgMagnifyingGlass = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <path
      d="m19.697 18.398-5.236-5.234c1.133-1.387 1.758-3.133 1.758-5.039A8.126 8.126 0 0 0 8.092 0C3.604 0 0 3.638 0 8.125s3.638 8.125 8.092 8.125a8.08 8.08 0 0 0 5.04-1.76l5.236 5.235c.219.185.461.275.7.275.238 0 .48-.092.663-.275a.913.913 0 0 0-.034-1.327ZM1.876 8.125a6.258 6.258 0 0 1 6.251-6.25 6.258 6.258 0 0 1 6.252 6.25 6.258 6.258 0 0 1-6.252 6.25 6.259 6.259 0 0 1-6.251-6.25Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgMagnifyingGlass;
