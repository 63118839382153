import { OwnershipDto } from './../../models/contracts/project-dto';
import { EquityInstrumentDto } from '@app/shared/models/contracts/project-dto';
import { instrumentOwnersSortFn } from '@app/shared/helpers';

export const getEquityInstrumentOwnerships = (
  instrument: EquityInstrumentDto,
  having: (owner: OwnershipDto) => number | null | undefined
) =>
  instrument.ownership
    .slice()
    .filter((owner) => having(owner))
    .sort((ownerA, ownerB) => instrumentOwnersSortFn(ownerA.owner, ownerB.owner));
