import { Option } from '@app/shared/models/option';
import { FC, InputHTMLAttributes } from 'react';
import styles from './field-radio-group.module.scss';
import { useController } from 'react-hook-form';
import useIsReadOnly from '@app/core/hooks/customUseIsReadOnly';

const PARENT_CLASSNAME = 'field-radio-group';

export interface FieldRadioOption extends Option {
  viewValue: number | string;
  value?: number | string;
}

interface FieldRadioGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  options: FieldRadioOption[];
  name: string;
  ignoreReadOnly?: boolean;
  disabled?: boolean;
}

const FieldRadioGroup: FC<FieldRadioGroupProps> = ({
  options,
  name,
  onChange,
  ignoreReadOnly = false,
  disabled = false,
  ...rest
}) => {
  const {
    field: { value: fieldValue, onChange: fieldOnChange },
  } = useController({ name });

  const isReadOnly = useIsReadOnly();

  return (
    <fieldset className={styles[`${PARENT_CLASSNAME}`]}>
      {options.map((fieldOption, index) => (
        <div key={'field-radio-group' + index + fieldOption.value}>
          <input
            type="radio"
            id={fieldOption.value ? fieldOption.value?.toString() : 'checkboxField' + index + name}
            name={name}
            checked={fieldOption.value === fieldValue}
            onChange={(event) => {
              fieldOnChange(event);
              if (onChange) {
                onChange(event);
              }
            }}
            {...rest}
            className={styles[`${PARENT_CLASSNAME}__input`]}
            value={fieldOption.value}
            disabled={disabled || (!ignoreReadOnly && isReadOnly)}
          />
          <label
            className={styles[`${PARENT_CLASSNAME}__item`]}
            htmlFor={
              fieldOption.value ? fieldOption.value?.toString() : 'checkboxField' + index + name
            }>
            {fieldOption.viewValue}
          </label>
        </div>
      ))}
    </fieldset>
  );
};

export default FieldRadioGroup;
