import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { FC } from 'react';
import {
  getTotalOrdinaryEquityProceeds,
  selectOrdinaryEquityInstruments,
} from '@app/core/store/project-slice-selectors';
import { selectCalculatedPayoutsResults } from '@app/core/store/pwerm-calculation-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { amountRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { TextTransform } from '@app/shared/enums/text-transform.enum';
import { WaterfallPartialTableProps } from '../waterfall-shared';
import { CaseDto, ForecastDto } from '@app/shared/models/contracts/project-dto';
import { sumBy, useGetCalculatedProjectValue } from '@app/shared/helpers';
import TableSectionValuesPerMultiple from '@app/modules/projects/outputs/components/TableSectionValuesPerMultiple';
import TableSectionValuesPerForecast from '@app/modules/projects/outputs/components/TableSectionValuesPerForecast';

export const WaterfallTotalPayoutSummaryPartialTable: FC<WaterfallPartialTableProps> = ({
  columnsCount,
}): JSX.Element => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const project = useAppSelector((state) => state.project.projectDraft);
  const equityInstruments = useAppSelector(selectOrdinaryEquityInstruments);
  const calculatedPayouts = useAppSelector(selectCalculatedPayoutsResults);

  const getEquityInstrumentsDistributionsTotal = (
    caseItem: CaseDto,
    forecast: ForecastDto,
    multipleId: string | undefined
  ) => {
    return sumBy(equityInstruments, (instrument) =>
      getCalculatedProjectValue('InstrumentDistributionsPaid', {
        caseId: caseItem.caseId,
        forecastId: forecast.forecastId,
        multipleId: multipleId,
        instrumentId: instrument.instrumentId,
      })
    );
  };

  return (
    <>
      <tr>
        <th className="table-primary__cell--header-tertiary">Total payout summary</th>
        <th className="table-primary__cell--header-tertiary" colSpan={columnsCount - 1} />
      </tr>
      <TableSectionValuesPerMultiple
        title="Total distributions"
        testId="totalSummary-totalDistributions"
        renderCell={({ caseItem, forecast, multipleId }) => (
          <CellValue
            {...amountRules}
            value={getEquityInstrumentsDistributionsTotal(caseItem, forecast, multipleId)}
          />
        )}
      />
      <TableSectionValuesPerForecast
        title="Ordinary equity check"
        testId="ordinary-equity-check"
        renderCell={({ caseItem, forecast }) => {
          const isProceedsAndDistributionsEqual = caseItem.multiples.every((multipleItem) => {
            const totalOrdinaryEquityProceeds = getTotalOrdinaryEquityProceeds(
              project,
              calculatedPayouts,
              caseItem.caseId,
              forecast.forecastId,
              multipleItem.multiple!
            );

            const totalDistributions = getEquityInstrumentsDistributionsTotal(
              caseItem,
              forecast,
              multipleItem.multipleId
            );

            if (totalOrdinaryEquityProceeds === null || totalDistributions === undefined) {
              return undefined;
            }

            return Math.abs(totalOrdinaryEquityProceeds - totalDistributions) <= 0.00001;
          });

          return (
            <CellValue
              alignment={Alignment.Right}
              textTransform={TextTransform.Uppercase}
              value={isProceedsAndDistributionsEqual}
            />
          );
        }}
      />
    </>
  );
};

export default WaterfallTotalPayoutSummaryPartialTable;
