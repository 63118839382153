import { FC } from 'react';
import { Card } from '@app/shared/components/card/Card';
import { percentageValidator, requiredValidator } from '@core/validations/hook-forms/validators';
import FormField from '@app/shared/components/form-controls/form-field/FormField';
import {
  amountBasicFieldFormattingProps,
  multipleFieldFormattingPropsSecondary,
  percentageFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { useAppSelector } from '@app/core/hooks/redux-hooks';
import {
  currencyDenominationPair,
  enumKeyByValue,
  useGetCalculatedProjectValue,
} from '@app/shared/helpers';
import { FormValue } from '@app/shared/components/form-controls/form-value/FormValue';
import { ProjectStatus } from '@app/shared/models/contracts/enums/shared-enums';
import {
  amountRules,
  calculatedMultiplierRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';

export enum AnalysisType {
  High = 'high',
  Mid = 'mid',
  Low = 'low',
}

interface ReturnAnalysisProps {
  analysisType: AnalysisType;
}

const SponsorMoMPerAnalysisType = {
  [AnalysisType.High]: '3.0x',
  [AnalysisType.Mid]: '2.5x',
  [AnalysisType.Low]: '2.0x',
};

type AnalysisKeySuffix<TStart extends string> = `${TStart}Low` | `${TStart}Mid` | `${TStart}High`;

const ReturnAnalysis: FC<ReturnAnalysisProps> = ({ analysisType }) => {
  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();
  const {
    status,
    storeBenchmarkingData,
    details: { currency, denomination },
    benchmarking: { benchmarkingOnly },
  } = useAppSelector((state) => state.project.projectDraft);

  const shouldFieldsBeRequired =
    status === enumKeyByValue(ProjectStatus, ProjectStatus.Closed) && storeBenchmarkingData;

  const getResultName = <T extends string>(name: T): AnalysisKeySuffix<T> => {
    switch (analysisType) {
      case AnalysisType.Low:
        return `${name}Low`;
      case AnalysisType.Mid:
        return `${name}Mid`;
      case AnalysisType.High:
        return `${name}High`;
    }
  };

  return (
    <Card>
      <h3>Return Analysis: {enumKeyByValue(AnalysisType, analysisType)}</h3>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormValue
            label="Sponsor MoM"
            value={SponsorMoMPerAnalysisType[analysisType]}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisType}.sweetReturnAsPercentOfTotalIncreaseInAttributableEquity`}
            label="Sweet Return as a % of Total Increase in Attributable Equity"
            rules={{
              ...(shouldFieldsBeRequired && requiredValidator),
              ...percentageValidator,
            }}
            {...percentageFieldFormattingProps}
            isGapless
          />
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label="Management MoM (Sweet Only)"
              value={getCalculatedProjectValue(getResultName('ManagementMoMSweetOnly'))}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisType}.managementMoMSweetOnly`}
              label="Management MoM (Sweet Only)"
              defaultValue={getCalculatedProjectValue(getResultName('ManagementMoMSweetOnly'))}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisType}.impliedSponsorIRR`}
            label="Implied Sponsor IRR"
            {...percentageFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormValue
            label="Total Management Return (Absolute)"
            value={getCalculatedProjectValue(getResultName('TotalManagementReturn'))}
            {...amountRules}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisType}.managementIRRSweetOnly`}
            label="Management IRR (Sweet Only)"
            {...percentageFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label={`Management Strip Return (Absolute) (${currencyDenominationPair(
                currency,
                denomination
              )})`}
              value={getCalculatedProjectValue(getResultName('ManagementStripReturn'))}
              {...amountRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisType}.managementStripReturn`}
              label={`Management Strip Return (Absolute) (${currencyDenominationPair(
                currency,
                denomination
              )})`}
              defaultValue={getCalculatedProjectValue(getResultName('ManagementStripReturn'))}
              placeholder="0"
              rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
              {...amountBasicFieldFormattingProps}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label="Total Management MoM"
              value={getCalculatedProjectValue(getResultName('TotalManagementMoM'))}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisType}.totalManagementMom`}
              label="Total Management MoM"
              defaultValue={getCalculatedProjectValue(getResultName('TotalManagementMoM'))}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label="Envy Ratio (Absolute Returns)"
              value={getCalculatedProjectValue(getResultName('EnvyRatio'))}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisType}.envyRatio`}
              label="Envy Ratio (Absolute Returns)"
              defaultValue={getCalculatedProjectValue(getResultName('EnvyRatio'))}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
      </div>
      <div className="form-row form-row--three-column">
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisType}.sweetReturnAbsolute`}
            label={`Sweet Return (Absolute) (${currencyDenominationPair(currency, denomination)})`}
            placeholder="0"
            {...amountBasicFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          <FormField
            name={`returnsAnalysis.${analysisType}.totalManagementIRR`}
            label="Total Management IRR"
            {...percentageFieldFormattingProps}
            rules={{ ...(shouldFieldsBeRequired && requiredValidator) }}
            isGapless
          />
        </div>
        <div className="form-row__column">
          {!benchmarkingOnly ? (
            <FormValue
              label="Envy Ratio (Sweet Only)"
              value={getCalculatedProjectValue(getResultName('EnvyRatioSweetOnly'))}
              {...calculatedMultiplierRules}
              isGapless
            />
          ) : (
            <FormField
              name={`returnsAnalysis.${analysisType}.envyRatioSweetOnly`}
              label="Envy Ratio (Sweet Only)"
              defaultValue={getCalculatedProjectValue(getResultName('EnvyRatioSweetOnly'))}
              {...multipleFieldFormattingPropsSecondary}
              isGapless
            />
          )}
        </div>
      </div>
    </Card>
  );
};

export default ReturnAnalysis;
