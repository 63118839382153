import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useLocale } from '@app/core/hooks/useLocale';
import useToggler from '@app/core/hooks/useToggler';
import { getCaseById, getMultipleProbability } from '@app/core/store/project-slice-selectors';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { enteredPercentageViewRules } from '@app/shared/components/cell-value/CellValueConfigurations';
import { Alignment } from '@app/shared/enums/alignment.enum';
import { enumKeyByValue } from '@app/shared/helpers';
import { WaterfallSections } from '@app/shared/models/contracts/enums/shared-enums';
import classNames from 'classnames';
import { FC } from 'react';
import TableSectionValuesPerCase from '../../components/TableSectionValuesPerCase';
import TableSectionValuesPerMultiple from '../../components/TableSectionValuesPerMultiple';
import { WaterfallTableExpandableRow } from '../../pwerm-summary-waterfall/waterfall-table/WaterfallTableExpandableRow';
import { Pwerm2WaterfallPartialTableProps } from '../pwerm2-waterfall-shared';

export const Pwerm2WaterfallExitMultiplesPartialTable: FC<Pwerm2WaterfallPartialTableProps> = ({
  columnsCount,
}) => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const [isExitMultiplesOpen, setIsExitMultiplesOpen] = useToggler();
  const { l } = useLocale();

  return (
    <>
      <tbody>
        <WaterfallTableExpandableRow
          title={l('_ExitMultiples')}
          id={enumKeyByValue(WaterfallSections, WaterfallSections.ExitMultiples)}
          isOpen={isExitMultiplesOpen}
          setIsOpen={setIsExitMultiplesOpen}
          colCount={columnsCount}
        />
      </tbody>
      <tbody
        className={classNames('table-primary__table-section', {
          'table-primary__table-section--collapsed': !isExitMultiplesOpen,
        })}>
        <TableSectionValuesPerMultiple
          title={l('_MultipleSpread')}
          renderCell={({ caseItem, forecast, multiple }) => (
            <CellValue
              {...enteredPercentageViewRules}
              value={
                getMultipleProbability(project, caseItem.caseId, forecast.forecastId, multiple)
                  ?.probability
              }
            />
          )}
        />
        <TableSectionValuesPerCase
          title={l('_CaseProbability')}
          additionalTitleClassNames="table-primary__cell--strong"
          highlight
          renderCell={({ caseItem }) => (
            <CellValue
              {...enteredPercentageViewRules}
              alignment={Alignment.Center}
              strong
              value={getCaseById(project, caseItem.caseId)?.probability}
            />
          )}
        />
      </tbody>
    </>
  );
};
