import { useAppSelector } from '@app/core/hooks/redux-hooks';
import { useFormContext } from 'react-hook-form';
import { percentageFieldFormattingProps } from '@app/shared/components/form-controls/form-field/form-field-patterns';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT } from '@app/shared/constants/table-config';
import SvgChevron from '@app/shared/icons/Chevron';
import SvgRevert from '@app/shared/icons/Revert';
import { FC, Fragment } from 'react';
import styles from '../../opm-inputs.module.scss';
import useLocalStorageCollapseState from '@app/core/hooks/useLocalStoreCollapse';
import { useLocale } from '@app/core/hooks/useLocale';
import { CalcMethod, OpmInputDataSource } from '@app/shared/models/contracts/enums/shared-enums';
import { useOpmInputsFromPwerm } from '../../hooks/useOpmInputsFromPwerm';
import { OpmInputFormModel } from '../../OpmInputs';
import { equityInstrumentsSortWithTranchesFn } from '@app/shared/helpers';
import {
  getDlomIndexByInstrumentIdForOpm,
  getDlomIndexByTrancheIdForOpm,
} from '@app/shared/helpers/equity-instruments/get-equity-instrument-index';
import { Pill, PillSize, PillTheme } from '@app/shared/components/pill/Pill';
import { OpmInputDto, PerYearValuesDto } from '@app/shared/models/contracts/project-dto';

interface MinorityDiscountsSectionProps {
  submitData: () => void;
}

const MinorityDiscountsSection: FC<MinorityDiscountsSectionProps> = ({
  submitData,
}): JSX.Element => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const isOPMOnly = project.details.calcMethod === CalcMethod.OPM;
  const opmInput = project.opmInput;
  const filteredInstruments = project.equityInstruments
    .filter((instrument) => instrument.shouldBeValued)
    .sort(equityInstrumentsSortWithTranchesFn);
  const { l } = useLocale();
  const formMethods = useFormContext<OpmInputFormModel>();

  const [expanded, setExpanded] = useLocalStorageCollapseState(
    'expanded-minority-discount',
    project.id
  );

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  const perYearInputs = project.opmInput.perYearInputs;
  const opmPwermValues = useOpmInputsFromPwerm();
  const shouldGetValuesFromPwerm = project.details.calcMethod !== CalcMethod.OPM;
  const maxColspanCount =
    project.opmInput.forecastYears !== null
      ? project.opmInput.forecastYears + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
      : 0;

  const controlPremiumForDlocSource = opmInput.controlPremiumForDlocSource;

  const isMinorityDiscountsTrancheOverridden = perYearInputs.some((pyi) => {
    return pyi.marketValueDloms?.some((item) => {
      return (
        item.trancheDloms &&
        item.trancheDloms.some((item1) => {
          return item1.dlomSource === OpmInputDataSource.Override;
        })
      );
    });
  });

  const minorityDiscountsSource = perYearInputs.some((pyi) => {
    return pyi.marketValueDloms?.some((item) => {
      return (
        item.instrumentDlomSource === OpmInputDataSource.Override ||
        isMinorityDiscountsTrancheOverridden
      );
    });
  })
    ? OpmInputDataSource.Override
    : OpmInputDataSource.FromPWERM;

  const displayInstrumentRevertForYear = (yearInputs: PerYearValuesDto, instrumentId: string) => {
    if (shouldGetValuesFromPwerm) {
      const marketValueDloms = yearInputs.marketValueDloms.find(
        (mvd) => mvd.instrumentId === instrumentId
      );
      return marketValueDloms?.instrumentDlomSource === OpmInputDataSource.Override;
    }

    return false;
  };

  const displayInstrumentRevert = (instrumentId: string) => {
    if (shouldGetValuesFromPwerm) {
      return perYearInputs?.some((yi) => {
        return displayInstrumentRevertForYear(yi, instrumentId);
      });
    }
  };

  const displayRevert = (rowSource: keyof OpmInputDto) => {
    if (shouldGetValuesFromPwerm) {
      return opmInput[rowSource] === OpmInputDataSource.Override;
    }
  };

  const handleRevert = () => {
    formMethods.setValue(
      'controlPremiumForDloc',
      opmPwermValues.getControlPremiumForDlocFromPwerm()
    );
    submitData();
  };

  const handleInstrumentRevert = (instrumentId: string) => {
    perYearInputs.forEach((yi, index) => {
      const valueToSet = opmPwermValues
        .getOpmValuesFromPwerm(yi.forecastDate)
        .marketValueDloms.find((item) => item.instrumentId === instrumentId)?.instrumentDlom;
      const valueIndex = opmPwermValues
        .getOpmValuesFromPwerm(yi.forecastDate)
        .marketValueDloms.findIndex((item) => item.instrumentId === instrumentId);
      formMethods.setValue(
        `perYearInputs.${index}.marketValueDloms.${valueIndex}.instrumentDlom`,
        valueToSet ?? 0
      );
    });
    submitData();
  };

  const displayTrancheRevertForYear = (
    yearInputs: PerYearValuesDto,
    instrumentId: string,
    trancheId: string
  ) => {
    if (shouldGetValuesFromPwerm) {
      const marketValueDloms = yearInputs.marketValueDloms.find(
        (mvd) => mvd.instrumentId === instrumentId
      );
      const canRevert =
        marketValueDloms?.trancheDloms.find((td) => td.trancheId === trancheId)?.dlomSource ===
        OpmInputDataSource.Override;
      return canRevert;
    }

    return false;
  };

  const displayTrancheRevert = (instrumentId: string, trancheId: string) => {
    if (shouldGetValuesFromPwerm) {
      return perYearInputs.some((yi) => displayTrancheRevertForYear(yi, instrumentId, trancheId));
    }
  };

  const handleTrancheRevert = (instrumentId: string, trancheId: string) => {
    perYearInputs.forEach((yi, index) => {
      const valueToSet = opmPwermValues
        .getOpmValuesFromPwerm(yi?.forecastDate)
        ?.marketValueDloms.find((item) => item.instrumentId === instrumentId)
        ?.trancheDloms.find((item) => item.trancheId === trancheId)?.dlom;

      const instrumentValueIndex = opmPwermValues
        .getOpmValuesFromPwerm(yi?.forecastDate)
        ?.marketValueDloms.findIndex((item) => item.instrumentId === instrumentId);

      const trancheValueIndex =
        opmPwermValues
          .getOpmValuesFromPwerm(yi?.forecastDate)
          ?.marketValueDloms.find((item) => item?.instrumentId === instrumentId)
          ?.trancheDloms.findIndex((item) => item?.trancheId === trancheId) ?? 0;

      formMethods.setValue(
        `perYearInputs.${index}.marketValueDloms.${instrumentValueIndex}.trancheDloms.${trancheValueIndex}.dlom`,
        valueToSet ?? 0
      );
    });
    submitData();
  };

  const isOverridden = () => {
    return (
      minorityDiscountsSource === OpmInputDataSource.Override ||
      controlPremiumForDlocSource === OpmInputDataSource.Override
    );
  };

  return filteredInstruments.length > 0 ? (
    <>
      <tbody>
        <tr>
          <th className={`table-primary__cell--header-tertiary ${styles[`opm-header`]}`}>
            <div className={styles['opm-collapsable-header']}>
              <button
                type="button"
                data-testid="md-collapse-button"
                aria-label={l('_ToggleSectionCollapse', {
                  section: l('_MinorityDiscounts'),
                })}
                aria-expanded={expanded}
                style={{
                  border: 'none',
                  backgroundColor: 'none',
                  background: 'none',
                }}
                onClick={toggleCollapse}>
                {!expanded ? (
                  <SvgChevron
                    onClick={toggleCollapse}
                    className={styles[`opm-chevron-collapsed`]}
                  />
                ) : (
                  <SvgChevron onClick={toggleCollapse} className={styles[`opm-chevron-expanded`]} />
                )}
              </button>
              {l('_MinorityDiscounts')}
            </div>
          </th>
          <th
            className={`table-primary__cell--header-tertiary ${styles[`opm-header-badge`]}`}
            colSpan={maxColspanCount - 1}>
            {project.details.calcMethod !== CalcMethod.OPM && (
              <div className={styles['opm-collapsable-header-badge']} data-testid="md-badge">
                <Pill
                  size={PillSize.Xsmall}
                  noTextTransform
                  theme={isOverridden() ? PillTheme.Orange : PillTheme.White}>
                  {isOverridden() ? l('_Overridden') : l('_AutoPopulatedFromPWERM')}
                </Pill>
              </div>
            )}
          </th>
        </tr>
        {expanded && (
          <>
            <tr>
              <th className={`table-primary__cell--vertical-separator ${styles[`opm-table-rows`]}`}>
                {l('_ControlPremiumForDloc')}
                {displayRevert('controlPremiumForDlocSource') && (
                  <SvgRevert
                    className={styles['revert-button']}
                    // stop the onBlur event occurring so we can control when the data is submitted
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => handleRevert()}
                    data-testid="dloc-revert-button"
                  />
                )}
              </th>
              <td>
                <GridTextField
                  className={[
                    styles[`padding-right-index0`],
                    displayRevert('controlPremiumForDlocSource') ? styles['revertable-value'] : '',
                  ]}
                  name="controlPremiumForDloc"
                  data-testid="control-premium-for-dloc"
                  {...percentageFieldFormattingProps}
                />
              </td>
              <td colSpan={opmInput.perYearInputs.length + 1} />
            </tr>
            {filteredInstruments.map((instrument) => {
              return (
                <Fragment key={`instrument-row-fragment-${instrument.instrumentId}`}>
                  <tr data-testid="md-row" key={'instrument-row-wrapper' + instrument.instrumentId}>
                    <th
                      className={`table-primary__cell--vertical-separator ${
                        styles[`opm-table-rows`]
                      }`}>
                      {instrument.instrumentNarrative}
                      {displayInstrumentRevert(instrument.instrumentId) && (
                        <SvgRevert
                          // stop the onBlur event occurring so we can control when the data is submitted
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => handleInstrumentRevert(instrument.instrumentId)}
                          className={styles['revert-button']}
                          data-testid={`${instrument.instrumentId}-dlom-revert-button`}
                        />
                      )}
                    </th>
                    <td />
                    <Fragment key={`ordinary-equity-row-${instrument.instrumentId}`}>
                      {opmInput.perYearInputs.map((perYearInput, perYearInputIndex) => (
                        <td key={perYearInputIndex + 'ordinary-equity-value'}>
                          {Boolean(!instrument.tranches?.length) && (
                            <GridTextField
                              name={`perYearInputs.${perYearInputIndex}.marketValueDloms.${getDlomIndexByInstrumentIdForOpm(
                                perYearInput,
                                instrument
                              )}.instrumentDlom`}
                              data-testid={`perYearInputs.${perYearInputIndex}.marketValueDloms.${getDlomIndexByInstrumentIdForOpm(
                                perYearInput,
                                instrument
                              )}.instrumentDlom`}
                              className={[
                                displayInstrumentRevertForYear(
                                  perYearInput,
                                  instrument.instrumentId
                                )
                                  ? styles['revertable-value']
                                  : '',
                              ]}
                              {...percentageFieldFormattingProps}
                            />
                          )}
                        </td>
                      ))}
                    </Fragment>
                    <td />
                  </tr>
                  <Fragment key={`ordinary-equity-row-${instrument.instrumentId}`}>
                    {instrument?.tranches?.map((tranche, index) => (
                      <tr key={'instrument-row' + instrument.instrumentId + index}>
                        <Fragment key={tranche.id + 'tranche-row'}>
                          <td className="table-primary__cell--vertical-separator table-primary__cell--indentation">
                            {`Tranche ${index + 1}`}
                            {displayTrancheRevert(instrument.instrumentId, tranche.id) && (
                              <SvgRevert
                                // stop the onBlur event occurring so we can control when the data is submitted
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() =>
                                  handleTrancheRevert(instrument.instrumentId, tranche.id)
                                }
                                className={styles['revert-button']}
                                data-testid={`${tranche.id}-dlom-revert-button`}
                              />
                            )}
                          </td>
                          <td />
                          {opmInput.perYearInputs.map((perYearInput, perYearInputIndex) => (
                            <td key={tranche.id + perYearInput.forecastDate + 'value'}>
                              <GridTextField
                                name={`perYearInputs.${perYearInputIndex}.marketValueDloms.${getDlomIndexByInstrumentIdForOpm(
                                  perYearInput,
                                  instrument
                                )}.trancheDloms.${getDlomIndexByTrancheIdForOpm(
                                  perYearInput,
                                  tranche,
                                  instrument
                                )}.dlom`}
                                data-testid={`perYearInputs.${perYearInputIndex}.marketValueDloms.${getDlomIndexByInstrumentIdForOpm(
                                  perYearInput,
                                  instrument
                                )}.trancheDloms.${getDlomIndexByTrancheIdForOpm(
                                  perYearInput,
                                  tranche,
                                  instrument
                                )}.dlom`}
                                className={[
                                  displayTrancheRevertForYear(
                                    perYearInput,
                                    instrument.instrumentId,
                                    tranche.id
                                  )
                                    ? styles['revertable-value']
                                    : '',
                                ]}
                                {...percentageFieldFormattingProps}
                              />
                            </td>
                          ))}
                          <td />
                        </Fragment>
                      </tr>
                    ))}
                  </Fragment>
                </Fragment>
              );
            })}
            {isOPMOnly && (
              <>
                <tr className="table-primary__row--secondary">
                  <th
                    colSpan={
                      (project.opmInput.forecastYears ?? 0) + OPM_TABLE_HEADER_ADDITIONAL_COL_COUNT
                    }
                    className="table-primary__cell--strong">
                    {l('_MemoVolatility')}
                  </th>
                </tr>
                <tr>
                  <th
                    className={`table-primary__cell--vertical-separator ${
                      styles[`opm-table-rows`]
                    }`}>
                    {l('_MarketVolatility')}
                  </th>
                  <td>
                    <GridTextField
                      className={[styles[`padding-right-index0`]]}
                      name="marketVolatility"
                      data-testid="marketVolatility"
                      {...percentageFieldFormattingProps}
                    />
                  </td>
                  <td colSpan={opmInput.perYearInputs.length + 1} />
                </tr>
                <tr>
                  <th
                    className={`table-primary__cell--vertical-separator ${
                      styles[`opm-table-rows`]
                    }`}>
                    {l('_PeerSetVolatility')}
                  </th>
                  <td>
                    <GridTextField
                      className={[styles[`padding-right-index0`]]}
                      name="peerSetVolatility"
                      data-testid="peerSetVolatility"
                      {...percentageFieldFormattingProps}
                    />
                  </td>
                  <td colSpan={opmInput.perYearInputs.length + 1} />
                </tr>
              </>
            )}
          </>
        )}
      </tbody>
    </>
  ) : (
    <></>
  );
};

export default MinorityDiscountsSection;
