import { EmptyValues } from '@app/shared/constants/empty-values';
import { abbreviatedValueFormatter, numberValueFormatter } from '@app/shared/formatters';
import Highcharts from 'highcharts';

export const formatNumber = (
  value: Nullable<number> | undefined,
  numberFraction = 2,
  abbreviatedValue = false,
  abbreviatedFraction = 3
): string =>
  value || value === 0
    ? abbreviatedValue
      ? abbreviatedValueFormatter({ value, fraction: abbreviatedFraction })
      : numberValueFormatter({ value, fraction: numberFraction })
    : EmptyValues.EnDash;

export function axisLabelFormatter(this: Highcharts.AxisLabelsFormatterContextObject): string {
  const label = abbreviatedValueFormatter({ value: Number(this.value), fraction: 1 });
  return label;
}

export function percentageAxisLabelFormatter(
  this: Highcharts.AxisLabelsFormatterContextObject
): string {
  const label = this.axis.defaultLabelFormatter.call(this);
  return `${label}%`;
}

export function axisLabelFormatterLegacy(
  this: Highcharts.AxisLabelsFormatterContextObject
): string {
  const label = this.axis.defaultLabelFormatter.call(this);

  // Use thousands separator for four-digit numbers too
  // implementation based on solutions suggested in https://github.com/highcharts/highcharts/issues/3811
  if (/^[0-9]{4}$/.test(label)) {
    return Highcharts.numberFormat(Number(this.value), 0);
  }

  return label;
}

export function percentageAxisLabelFormatterLegacy(
  this: Highcharts.AxisLabelsFormatterContextObject
): string {
  const label = axisLabelFormatterLegacy.call(this);
  return `${label}%`;
}
