/* eslint-disable big-number-rules/rounding */
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'; // Import useState hook
import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import { useLocale } from '@app/core/hooks/useLocale';
import { useAppDispatch, useAppSelector } from '@app/core/hooks/redux-hooks';
import styles from './collar-real-world.module.scss';
import { FormProvider, useForm } from 'react-hook-form';
import { formConfigBase } from '@app/shared/constants/form-config-base';
import GridTextField from '@app/shared/components/grid-controls/grid-text-field/GridTextField';
import { Alignment } from '@app/shared/enums/alignment.enum';
import useIsReadOnly from '@core/hooks/customUseIsReadOnly';
import * as projectActions from '@core/store/project-slice';
import { cloneDeep } from '@app/shared/helpers';
import { CollaringMode } from '@app/shared/models/contracts/enums/shared-enums';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance, ButtonSize } from '@app/shared/components/button/button-enums';
import { calculateOpm } from '@app/core/store/opm-calculation-slice';
import { GridFieldTooltipVariation } from '@app/shared/components/grid-controls/grid-field-options';
import { PerYearValuesDto } from '@app/shared/models/contracts/project-dto';
import { OpmInputFormModel } from '@app/modules/projects/inputs/opm-inputs/OpmInputs';
import { commaFormatter } from '@app/shared/formatters';
import {
  collaringPercentageValidator,
  positiveValueValidator,
} from '@app/core/validations/hook-forms/validators';
import {
  numericBaseFieldFormattingProps,
  numericBasicFieldFormattingProps,
} from '@app/shared/components/form-controls/form-field/form-field-patterns';
import { selectOpmFormValues } from '@app/core/store/project-slice-selectors';
import classNames from 'classnames';

export interface CollarRealWorldModel {
  inputMode: keyof typeof CollaringMode;
  percentage: Nullable<number>;
  fixedValue: Nullable<number>;
}

export const CollarRealWorld: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const project = useAppSelector((state) => state.project.projectDraft);
  const collaringResults = useAppSelector(
    (state) => state.opmCalculation.realWorldValues.collaringResults
  );
  const { l } = useLocale();
  const isReadOnly = useIsReadOnly();
  const [updateButtonDisable, setUpdateButtonDisable] = useState<boolean>(true);
  const opmInput = { ...project.opmInput };
  const [percentageTooltipMsg, setPercentageTooltipMsg] = useState('');
  const [capTooltipMsg, setCapTooltipMsg] = useState('');
  const [hasRealWorldRate, setHasRealWorldRate] = useState<boolean>(false);

  const formValues = useAppSelector(selectOpmFormValues);

  const getFormattedYear = (year: PerYearValuesDto) => {
    const forecastDate = year.forecastDate && new Date(year.forecastDate);
    const formattedYear =
      forecastDate &&
      forecastDate
        .toLocaleDateString('en-US', {
          year: '2-digit',
          month: 'short',
        })
        .replace(' ', '-');

    return formattedYear;
  };

  const handleRunOpmClick = async () => {
    await dispatch(calculateOpm(true));
    setUpdateButtonDisable(true);
  };

  useEffect(() => {
    // below loop required to ensure update button is disabled on first render, if relevant (e.g. user navigates away and returns)
    collaringResults &&
      project.opmInput.perYearInputs.forEach((pyi, index) => {
        const setCollaringInputsToDefault = (index: number) => {
          formMethods.setValue(`perYearInputs.${index}.collaring.percentage`, null);
          formMethods.setValue(`perYearInputs.${index}.collaring.fixedValue`, null);
          formMethods.setValue(`perYearInputs.${index}.collaring.inputMode`, CollaringMode.Off);
        };

        // if there are no collaring results for this year, set to the default values
        if (collaringResults.length < index + 1) {
          setCollaringInputsToDefault(index);
          return;
        }

        if (
          (pyi.collaring?.inputMode === CollaringMode.Percentage &&
            pyi.collaring?.percentage !==
              Math.round(collaringResults?.[index].effectivePercentage * 100)) ||
          (pyi.collaring?.inputMode === CollaringMode.FixedValue &&
            pyi.collaring?.fixedValue !== Math.round(collaringResults?.[index].evCeiling))
        ) {
          setUpdateButtonDisable(false);
        }

        if (collaringResults[index].inputMode === CollaringMode.Off) {
          setCollaringInputsToDefault(index);
        } else {
          formMethods.setValue(
            `perYearInputs.${index}.collaring.percentage`,
            Math.round((collaringResults[index].effectivePercentage ?? 0) * 10000) / 100
          );
          formMethods.setValue(
            `perYearInputs.${index}.collaring.fixedValue`,
            Math.round(collaringResults[index].evCeiling ?? 0)
          );
          formMethods.setValue(
            `perYearInputs.${index}.collaring.inputMode`,
            collaringResults[index].inputMode
          );
        }
      });
    submitData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collaringResults]);

  const handleCollaringChange = (val: number, collaringMode: CollaringMode, index: number) => {
    // required to ensure a minus sign is permitted as the first character
    if (!isNaN(val)) {
      setUpdateButtonDisable(false);
      if (collaringMode === CollaringMode.Percentage) {
        formMethods.setValue(`perYearInputs.${index}.collaring.fixedValue`, null);
        formMethods.setValue(
          `perYearInputs.${index}.collaring.inputMode`,
          val > 0 ? CollaringMode.Percentage : CollaringMode.Off
        );
      } else {
        formMethods.setValue(`perYearInputs.${index}.collaring.percentage`, null);
        formMethods.setValue(
          `perYearInputs.${index}.collaring.inputMode`,
          val > 0 ? CollaringMode.FixedValue : CollaringMode.Off
        );
      }
      const areAllInputsValid = validateAllInputs();
      setUpdateButtonDisable(!areAllInputsValid);
    }
  };

  const formMethods = useForm<OpmInputFormModel>({
    ...formConfigBase,
    defaultValues: formValues,
  });

  const { handleSubmit, getValues } = formMethods;

  const submitData = useCallback(async () => {
    const updatedValues = getValues();
    // removing the filteredSlnAndPrefSharesInstruments key from the updatedValues object, as this
    // is only required in the context of OPM Inputs
    delete updatedValues.filteredSlnAndPrefSharesInstruments;
    // below loop required for instances where state is not updated, due to things like a non-numeric keypress
    updatedValues.perYearInputs.forEach((pyi, index) => {
      const collaringMode = pyi.collaring?.inputMode;
      const collaringPercentage = pyi.collaring?.percentage;
      const collaringFixedValue = pyi.collaring?.fixedValue;
      if (collaringPercentage === null && collaringFixedValue === null) {
        formMethods.setValue(`perYearInputs.${index}.collaring.inputMode`, CollaringMode.Off);
      } else if (collaringPercentage === null && collaringMode === CollaringMode.Percentage) {
        formMethods.setValue(`perYearInputs.${index}.collaring.inputMode`, CollaringMode.Off);
        setUpdateButtonDisable(false);
      } else if (collaringFixedValue === null && collaringMode === CollaringMode.FixedValue) {
        formMethods.setValue(`perYearInputs.${index}.collaring.inputMode`, CollaringMode.Off);
        setUpdateButtonDisable(false);
      }
    });
    const updatedOpmInput = {
      ...project.opmInput,
      ...cloneDeep(updatedValues),
    };

    if (!isReadOnly) {
      await dispatch(
        projectActions.updateProjectDraft({
          project: {
            ...project,
            opmInput: { ...cloneDeep(updatedOpmInput) },
          },
        })
      ).unwrap();
    }
  }, [dispatch, formMethods, getValues, isReadOnly, project]);

  const validateAllInputs = () => {
    const perYearInputs = getValues()?.perYearInputs;

    return perYearInputs?.every((input) => {
      if (input.collaring) {
        if (input.collaring.percentage !== null) {
          return collaringPercentageValidator.validate(Number(input.collaring.percentage)) === true;
        } else if (input.collaring.fixedValue !== null) {
          return positiveValueValidator(true).validate(String(input.collaring.fixedValue)) === true;
        }
      }
      return true;
    });
  };

  useEffect(() => {
    setHasRealWorldRate(project.opmInput.realWorldRate !== null);
  }, [project.opmInput.realWorldRate]);

  return (
    <FormProvider {...formMethods}>
      <form onBlur={handleSubmit(submitData, submitData)}>
        <DashboardWidgetContainer
          title={l('_CollarRealWorld')}
          className={styles['collar-summary']}
          opm
          isActionVisbile={hasRealWorldRate}
          actions={
            <Button
              appearance={ButtonAppearance.DEFAULT_PRIMARY}
              size={ButtonSize.SMALL}
              onClick={handleRunOpmClick}
              disabled={updateButtonDisable}
              data-testid="update-button">
              {l('_Update')}
            </Button>
          }>
          <div style={{ position: 'relative' }}>
            <table
              data-testid="collar-table"
              className={classNames(styles['summary-table'], {
                [styles['summary-table__blurred']]: !hasRealWorldRate,
              })}>
              <thead className={styles['collar-headers']}>
                <tr>
                  <th
                    className="table-primary__cell--header-quinary"
                    style={{ textAlign: 'start' }}>
                    {l('_Year')}
                  </th>
                  <th
                    className="table-primary__cell--header-quinary"
                    style={{
                      paddingRight: '10px',
                    }}>
                    %
                  </th>
                  <th
                    className="table-primary__cell--header-quinary"
                    style={{
                      paddingRight: '10px',
                    }}>
                    {l('_Cap')}
                  </th>
                  <th className="table-primary__cell--header-quinary">{l('_Floor')}</th>
                </tr>
              </thead>
              <tbody>
                {opmInput.perYearInputs.map(
                  (year, index) =>
                    year.exitHorizonProbability !== null &&
                    year.exitHorizonProbability > 0 && (
                      <tr key={getFormattedYear(year)}>
                        <td className={styles['summary-years']}>{getFormattedYear(year)}</td>
                        <td className="table-primary__cell--header-quinary">
                          <GridTextField
                            name={`perYearInputs.${index}.collaring.percentage`}
                            data-testid={`perYearInputs.${index}.collaring.percentage`}
                            {...numericBasicFieldFormattingProps}
                            rules={{
                              ...collaringPercentageValidator,
                            }}
                            decimalPoint={2}
                            alignment={Alignment.Right}
                            tooltipVariation={GridFieldTooltipVariation.Top}
                            tooltipMsg={percentageTooltipMsg}
                            placeholder="-"
                            onChange={(e: ChangeEvent) =>
                              handleCollaringChange(
                                Number((e.target as HTMLInputElement).value),
                                CollaringMode.Percentage,
                                index
                              )
                            }
                            onClick={() =>
                              setPercentageTooltipMsg(l('_ChangesHereOverrideCapInserted'))
                            }
                            disabled={!hasRealWorldRate}
                          />
                        </td>
                        <td className="table-primary__cell--header-quinary">
                          <GridTextField
                            name={`perYearInputs.${index}.collaring.fixedValue`}
                            data-testid={`perYearInputs.${index}.collaring.fixedValue`}
                            {...numericBaseFieldFormattingProps}
                            rules={{
                              ...positiveValueValidator(true),
                            }}
                            alignment={Alignment.Right}
                            tooltipVariation={GridFieldTooltipVariation.Top}
                            tooltipMsg={capTooltipMsg}
                            placeholder="-"
                            onChange={(e: ChangeEvent) =>
                              handleCollaringChange(
                                Number((e.target as HTMLInputElement).value),
                                CollaringMode.FixedValue,
                                index
                              )
                            }
                            onClick={() =>
                              setCapTooltipMsg(l('_ChangesHereOverridePercentageInserted'))
                            }
                            disabled={!hasRealWorldRate}
                          />
                        </td>
                        <td
                          className="table-primary__cell--header-quinary"
                          data-testid={`perYearInputs.${index}.collaring.floorValue`}
                          style={{
                            fontSize: '1.2rem',
                            textAlign: 'right',
                          }}>
                          {collaringResults &&
                          collaringResults.length >= index + 1 &&
                          collaringResults[index].inputMode !== CollaringMode.Off
                            ? commaFormatter(Math.round(collaringResults[index]?.evFloor))
                            : '-'}
                        </td>
                      </tr>
                    )
                )}
              </tbody>
            </table>
            {!hasRealWorldRate && (
              <div className={styles['disabled-collar-textbox']}>
                <p className={styles['disabled-collar-textbox-title']}>
                  {l('_NoRealWorldRateInputted')}
                </p>
                <p
                  data-testid="disabled-collar-table-text"
                  className={styles['disabled-collar-textbox-text']}>
                  {l('_EnterARealWorldRateToEnableTheCollaringFunction')}
                </p>
              </div>
            )}
          </div>
        </DashboardWidgetContainer>
      </form>
    </FormProvider>
  );
};
