import { DashboardWidgetContainer } from '@app/shared/components/dashboard-widget-container/DashboardWidgetContainer';
import CellValue from '@app/shared/components/cell-value/CellValue';
import { CellValueTheme } from '@app/shared/components/cell-value/cell-value-theme';
import { useAppSelector } from '@core/hooks/redux-hooks';
import {
  amountRules,
  calculatedMultiplierRules,
  enteredPercentageViewRules,
  shortDateViewRules,
} from '@app/shared/components/cell-value/CellValueConfigurations';
import { mapAsRecord, sumBy, useGetCalculatedProjectValue } from '@app/shared/helpers';
import { CaseDto } from '@app/shared/models/contracts/project-dto';
import classNames from 'classnames';
import { getCalculatedValueForForecast } from '@core/store/project-slice-selectors';
import {
  getCalculatedProjectValueInPercent,
  selectCalculatedVariablesResults,
} from '@app/core/store/pwerm-calculation-slice-selectors';
import {
  getCaseProbabilityWeightedValue,
  getForecastProbabilityWeightedValue,
} from '@app/shared/helpers/get-weighted-values';
import { getMultipleWeightedTotalEquityProceeds } from '@app/shared/helpers/predefined-variables/get-multiple-weighted-total-equity-proceeds';
import { getWeightedAverageValuePerCase } from '@app/shared/helpers/get-weighted-value-per-case';
import { useKeyMetrics } from '@app/modules/projects/dashboard/widgets/key-metrics/useKeyMetrics';
import styles from './key-metrics.module.scss';
import Button from '@app/shared/components/button/Button';
import { ButtonAppearance } from '@app/shared/components/button/button-enums';
import { ToggleWidget } from '@app/shared/enums/expand-collapse';
import { useState } from 'react';
import MinusIcon from '@app/shared/icons/MinusIcon';
import SvgPlus from '@app/shared/icons/Plus';
import { getCasesWithNonZeroProbability } from '@app/shared/helpers/get-cases-with-non-zero-probability';
import { sortedProjectByCases } from '@app/shared/helpers/sort/sort-project-by-cases';
import { useLocalStateCollapse } from '@app/core/hooks/useLocalStateCollapse';
import { setPwermDashboardKeyMetricsAndCrossChecksTableIsExpanded } from '@app/core/store/ui-values-slice';

const allTableTitlesList = [
  'Case',
  'Case Weighting',
  'Weighted Exit Date',
  'Exit EV',
  'Total Equity Returns',
  'Sponsor MoM',
  'Sponsor IRR',
  'Total Equity DCF Cross Check',
];

const EXPANDED_TABLE_COL_COUNT = 8;
const COLLAPSED_TABLE_COL_COUNT = 5;
const EXPANDER_COLUMN_NUMBER_FROM_ROW_END = 3;

export const KeyMetrics = () => {
  const project = useAppSelector((state) => state.project.projectDraft);
  const sortedProjectByCase = sortedProjectByCases(project);
  const storedExpandedState = useAppSelector(
    (state) => state.uiValues.userSelections.pwermDashboard.keyMetricsAndCrossChecksTable.isExpanded
  );

  const defaultExpandedState = storedExpandedState ?? false;

  const [isExpanded, setIsExpanded] = useState(defaultExpandedState);

  useLocalStateCollapse(isExpanded, setPwermDashboardKeyMetricsAndCrossChecksTableIsExpanded);

  const calculatedVariables = useAppSelector(selectCalculatedVariablesResults);

  const { cases } = sortedProjectByCase.pwermInput;

  const columnCount = isExpanded ? EXPANDED_TABLE_COL_COUNT : COLLAPSED_TABLE_COL_COUNT;

  const {
    getInstitutionalMoneyMultiple,
    getDiscountedProceeds,
    varianceToCurrentMarketValueInPercent,
  } = useKeyMetrics();

  const { getCalculatedProjectValue } = useGetCalculatedProjectValue();

  const getCaseData = (caseItem: CaseDto) => {
    const forecastWeightedExitDate = new Date(
      getForecastProbabilityWeightedValue({
        caseItem,
        getValue: ({ forecast }) => Date.parse(forecast.forecastYear),
      })
    ).toISODateString();

    const forecastWeightedExitEV = getForecastProbabilityWeightedValue({
      caseItem,
      getValue: ({ forecast }) =>
        getCalculatedValueForForecast(
          sortedProjectByCase,
          calculatedVariables,
          caseItem.caseId,
          forecast.forecastId,
          'Weighted EV'
        ),
    });

    const forecastWeightedTotalEquityProceeds = getForecastProbabilityWeightedValue({
      caseItem,
      getValue: ({ forecast }) =>
        getMultipleWeightedTotalEquityProceeds(
          sortedProjectByCase,
          calculatedVariables,
          caseItem,
          forecast
        ),
    });

    const forecastWeightedInstitutionalMoM = getWeightedAverageValuePerCase(
      caseItem,
      (caseItem, forecast, _, multipleId) =>
        Number(getInstitutionalMoneyMultiple(caseItem, forecast, multipleId))
    );

    const forecastWeightedInstitutionalIRR = getCalculatedProjectValueInPercent(
      getCalculatedProjectValue('SponsorCaseIRR', {
        caseId: caseItem.caseId,
      })
    );

    const forecastWeightedMarketValueOfTotalEquity = getWeightedAverageValuePerCase(
      caseItem,
      (caseItem, forecast) => getDiscountedProceeds(caseItem, forecast)
    );

    return [
      {
        value: caseItem.probability,
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: forecastWeightedExitDate,
        formattingRules: shortDateViewRules,
      },
      {
        value: forecastWeightedExitEV,
        formattingRules: amountRules,
      },
      {
        value: forecastWeightedTotalEquityProceeds,
        formattingRules: amountRules,
      },
      {
        value: forecastWeightedInstitutionalMoM,
        formattingRules: calculatedMultiplierRules,
      },
      {
        value: forecastWeightedInstitutionalIRR,
        formattingRules: enteredPercentageViewRules,
      },
      {
        value: forecastWeightedMarketValueOfTotalEquity,
        formattingRules: amountRules,
      },
    ];
  };

  const dataByCaseId = mapAsRecord(
    cases,
    (item) => getCaseData(item),
    (item) => item.caseId
  );

  const weightedAverageData = [
    {
      value: sumBy(cases, (item) => item.probability),
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: new Date(
        getCaseProbabilityWeightedValue({
          project,
          getValue: ({ caseItem }) => Date.parse(dataByCaseId[caseItem.caseId][1].value as string),
        })
      ).toISODateString(),
      formattingRules: shortDateViewRules,
    },
    {
      value: getCaseProbabilityWeightedValue({
        project,
        getValue: ({ caseItem }) => dataByCaseId[caseItem.caseId][2].value as number,
      }),
      formattingRules: amountRules,
    },
    {
      value: getCaseProbabilityWeightedValue({
        project,
        getValue: ({ caseItem }) => dataByCaseId[caseItem.caseId][3].value as number,
      }),
      formattingRules: amountRules,
    },
    {
      value: getCaseProbabilityWeightedValue({
        project,
        getValue: ({ caseItem }) => dataByCaseId[caseItem.caseId][4].value as number,
      }),
      formattingRules: calculatedMultiplierRules,
    },
    {
      value: getCalculatedProjectValueInPercent(getCalculatedProjectValue('WeightedIRRAllCases')),
      formattingRules: enteredPercentageViewRules,
    },
    {
      value: getCaseProbabilityWeightedValue({
        project,
        getValue: ({ caseItem }) => dataByCaseId[caseItem.caseId][6].value as number,
      }),
      formattingRules: amountRules,
    },
  ];

  const weightedAverageRowData = isExpanded
    ? weightedAverageData
    : [
        ...weightedAverageData.slice(0, 1),
        ...weightedAverageData.slice(-EXPANDER_COLUMN_NUMBER_FROM_ROW_END),
      ];

  const tableColumnTitles = isExpanded
    ? allTableTitlesList
    : [
        ...allTableTitlesList.slice(0, 2),
        ...allTableTitlesList.slice(-EXPANDER_COLUMN_NUMBER_FROM_ROW_END),
      ];

  return (
    <DashboardWidgetContainer
      isExpanded={isExpanded}
      title="Key Metrics and Cross Checks"
      actions={
        <div className="dashboard-widget-controls">
          <Button
            data-testid="expansion-toggle"
            appearance={ButtonAppearance.DEFAULT_SECONDARY}
            onClick={() => setIsExpanded(!isExpanded)}
            className="dashboard-widget-controls__button"
            ignoreReadOnly>
            {isExpanded ? ToggleWidget.Collapse : ToggleWidget.Expand}
          </Button>
        </div>
      }>
      <table className="table-primary table-primary--fixed table-primary--secondary-theme">
        <thead>
          <tr>
            {tableColumnTitles.map((title, index) => (
              <th
                key={index}
                className={classNames('table-primary__cell--header-quinary', {
                  'table-primary__cell--right': index !== 0,
                  [styles['cell--expander']]:
                    index === tableColumnTitles.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END,
                })}>
                {index === tableColumnTitles.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END && (
                  <button
                    aria-hidden
                    onClick={() => setIsExpanded(!isExpanded)}
                    className={styles['expander-button']}>
                    {isExpanded ? <MinusIcon /> : <SvgPlus />}
                  </button>
                )}
                {title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getCasesWithNonZeroProbability(cases).map((caseItem, index) => {
            const caseData = isExpanded
              ? dataByCaseId[caseItem.caseId]
              : [
                  ...dataByCaseId[caseItem.caseId].slice(0, 1),
                  ...dataByCaseId[caseItem.caseId].slice(-EXPANDER_COLUMN_NUMBER_FROM_ROW_END),
                ];

            return (
              <tr key={index} data-testid={`key-metrics-row-${index}`}>
                <td>
                  <span className="heading-2 heading-2--alternate">{caseItem.narrative}</span>
                </td>

                {caseData.map((item, valueIndex) => (
                  <td
                    key={valueIndex}
                    className={classNames({
                      [styles['cell--expander']]:
                        valueIndex === caseData.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END,
                    })}>
                    <CellValue
                      value={item.value}
                      theme={CellValueTheme.Secondary}
                      {...item.formattingRules}
                    />
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr data-testid="key-metrics-summary-row">
            <th className="table-primary__cell--header-quinary">Case Weighted Average</th>
            {weightedAverageRowData.map((item, index) => (
              <td
                key={index}
                className={classNames(
                  {
                    [styles['cell--expander']]:
                      index === weightedAverageRowData.length - EXPANDER_COLUMN_NUMBER_FROM_ROW_END,
                  },
                  styles['cell--weighted-value']
                )}>
                <CellValue
                  value={item.value}
                  theme={CellValueTheme.Secondary}
                  {...item.formattingRules}
                />
              </td>
            ))}
          </tr>
          <tr>
            <th className="table-primary__cell--header-quinary">
              % variance to Total Equity Value
            </th>
            <th colSpan={columnCount - 4} />
            <td
              colSpan={EXPANDER_COLUMN_NUMBER_FROM_ROW_END}
              data-testid="variance-to-current-market-value-in-percent"
              className={classNames(styles['cell--expander'], styles['cell--weighted-value'])}>
              <CellValue
                value={varianceToCurrentMarketValueInPercent}
                theme={CellValueTheme.Secondary}
                {...enteredPercentageViewRules}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </DashboardWidgetContainer>
  );
};
