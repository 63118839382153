import { SVGProps } from 'react';
const SvgStatusPageIllustration = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 801 722" fill="none" xmlns="http://www.w3.org/2000/svg" role="img" {...props}>
    <circle cx={520.583} cy={125} r={27} stroke="#F4B940" strokeWidth={16} />
    <circle cx={720.583} cy={145} r={27} stroke="#F4B940" strokeWidth={16} />
    <path
      fill="#F4B940"
      d="m829.202 25.034 11.314 11.314-95.542 95.542-11.314-11.314zM587.477 46.648l11.313 11.314-53.943 53.943-11.314-11.313zM633.724 46.828 622.41 58.142l73.526 73.525 11.314-11.313z"
    />
    <circle cx={230.583} cy={561} r={27} stroke="#F4B940" strokeWidth={16} />
    <circle cx={330.583} cy={661} r={27} stroke="#F4B940" strokeWidth={16} />
    <circle cx={610.583} cy={381} r={27} stroke="#F4B940" strokeWidth={16} />
    <circle cx={720.583} cy={491} r={27} stroke="#F4B940" strokeWidth={16} />
    <path
      fill="#F4B940"
      d="m244.141 585.872 11.314-11.314 64.663 64.663-11.314 11.314zM623.266 404.997l11.314-11.314 72.115 72.115-11.314 11.314zM823.312 377.386l11.314 11.314-90.709 90.708-11.313-11.314zM205.854 574.414l11.314 11.314L11.314 791.58 0 780.268z"
    />
    <circle cx={520.583} cy={298} r={27} stroke="#EB8C00" strokeWidth={16} />
    <circle cx={610.583} cy={208} r={27} stroke="#EB8C00" strokeWidth={16} />
    <circle cx={720.583} cy={318} r={27} stroke="#EB8C00" strokeWidth={16} />
    <path
      fill="#EB8C00"
      d="m824.74 203 11.314 11.314-89.654 89.653-11.313-11.314zM588.951 218.286l11.314 11.314-53.847 53.847-11.314-11.314zM623.07 231.801l11.314-11.314 70.774 70.775-11.314 11.313z"
    />
    <circle cx={610.583} cy={35} r={27} stroke="#C84757" strokeWidth={16} />
    <path fill="#C84757" d="M635.583 43V27h193v16z" />
    <circle cx={610.583} cy={661} r={27} stroke="#F4B940" strokeWidth={16} />
    <path fill="#F4B940" d="M602.583 688h16v76h-16z" />
    <path fill="#C84757" d="M512.583 496h16v268h-16z" />
    <circle cx={520.583} cy={471} r={27} stroke="#C84757" strokeWidth={16} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m598.555 404.318-11.313-11.314-48.233 48.233a35.197 35.197 0 0 1 11.319 11.308l48.227-48.227Zm-96.427 96.427a35.187 35.187 0 0 1-11.308-11.319L344.663 635.583l11.314 11.313 146.151-146.151Z"
      fill="#F4B940"
    />
  </svg>
);
export default SvgStatusPageIllustration;
